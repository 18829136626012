
import { Inject, Injectable, InjectionToken, Injector, Optional, StaticProvider } from '@angular/core';
import { OverridedMixpanel } from 'mixpanel-browser';
import { ReplaySubject } from 'rxjs';

import { AuthenticatedMemberService, KeycloakService } from '../authentication';
import { AuthenticatedUserService, SponsorService } from '../client';
import { AuthenticatedMemberService as AuthenticatedClientService } from '../client/services/authenticated-member.service';
import { Environment } from '../config';
import { MemberClass, Feature, MemberSettings, Sponsor } from '../models';
import { UnleashFlagsService } from '../services/unleash-flags.service';
import { CurrentUser, CommonData, ShellRouter } from '../shell';
import { StoreNames } from '../state-management';

export const SHELL_APPLICATION = new InjectionToken<ShellApplication>('SHELL_APPLICATION');
export const AUTHENTICATED_USER_SERVICE = new InjectionToken<AuthenticatedUserService>('AUTHENTICATED_USER_SERVICE');
export const AUTHENTICATED_MEMBER_SERVICE = new InjectionToken<AuthenticatedClientService>('AUTHENTICATED_MEMBER_SERVICE');

export const SELECTED_SPONSOR_SERVICE_REPLAY_SUBJECT = new InjectionToken<ReplaySubject<Sponsor>>('SELECTED_SPONSOR_SERVICE_REPLAY_SUBJECT');

@Injectable()
export class ShellApplication {

    public authenticatedMember: MemberClass|null = null;
    public authenticatedUser: any;
    public authClient?: any;
    public memberFeatures: Feature[] = [];
    public memberSettings: MemberSettings|null = null;
    public shellAppName: StoreNames;
    public customData: any;
    public isAuthenticatedUserAnAgent = false;
    public sponsorReplaySubject = new ReplaySubject<any>(1);
    public microstrategyUrl = '';
    public shellApplication: ShellApplication;

    constructor(
        @Optional() @Inject('mixpanel') public mixpanel: OverridedMixpanel,
        public environment: Environment,
        private authenticatedMemberService: AuthenticatedMemberService,
        private keycloakService: KeycloakService,
        @Optional() private authenticatedUserService: AuthenticatedUserService | null = null,
        public injector: Injector | null = null
    ) {
        /**
         * It's necessary to create shellApplication property that captures reference to this instance !
         *
         * When shellApplication is passed to singleSpa as startup properties,
         * singleSpa will create clone and original reference will be lost,
         * so here we use shellApplication property to restore reference.
         */
        this.shellApplication = this;

        this.shellAppName = StoreNames.GENESIS_UI;
        this.updateProperties();
    }

    static createFrom(
        mixpanel: OverridedMixpanel,
        environment: Environment,
        authenticatedMemberService: AuthenticatedMemberService,
        authClient: any
    ): ShellApplication {

        /**
         * ShellApplication instance should be created by the "Shell application" !
         *
         * createFrom method is used to provide support for
         * the legacy client and admin shell applications
         */

        // eslint-disable-next-line no-console
        console.warn('ShellApplication application should be provided by the Shell Application');

        return new ShellApplication(
            mixpanel,
            environment,
            authenticatedMemberService,
            KeycloakService.fromClient(authClient)
        );
    }

    updateProperties() {
        this.authenticatedUser = this.authenticatedUserService?.getAuthenticatedUser();
        this.authClient = this.keycloakService.getClient();
        if (this.authenticatedMemberService && this.authenticatedMemberService.getMember()) {
            this.authenticatedMember = this.authenticatedMemberService.getMember();
            this.memberFeatures = this.authenticatedMemberService.getFeatures();
            this.memberSettings = this.authenticatedMemberService.getMemberSettings();
            if ('getGenesisSingleSpaProps' in this.authenticatedMemberService) {
                this.customData = this.authenticatedMemberService.getGenesisSingleSpaProps();
            }
        }
    }

    getProviders(): Array<StaticProvider> {

        if (!this.injector) {
            return [];
        }

        /**
         * Here we can't use InjectionToken as MF and Shell are built independently in
         * that case InjectionTokens will not be the same so obtaining reference to
         * the dependency will fail, that is why strings are used as providers
         */

        const providers = [
            { provide: CurrentUser.Token, useValue: this.injector.get(CurrentUser, null) },
            { provide: CommonData.Token, useValue: this.injector.get(CommonData, null) },
            { provide: ShellRouter.Token, useValue: this.injector.get(ShellRouter, null) },
            { provide: UnleashFlagsService.Token, useValue: this.injector.get(UnleashFlagsService, null) },
            { provide: SponsorService.Token, useValue: this.injector.get(SponsorService, null) }
        ];

        return providers;
    }
}
