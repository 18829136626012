import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Security } from 'filestack-js';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SecurityDataService {
    constructor(
        private httpClient: HttpClient
    ) { }

    getReadImageSecurityData(memberId: number, sponsorId: number): Observable<Security> {
        return this.httpClient.get<Security>(`/api/sponsors/${sponsorId}/members/${memberId}/filestack-security`);
    }
}
