import { NgModule } from '@angular/core';

import { AccessibleDragAndDropModule } from './accessible-drag-and-drop/accessible-drag-and-drop.module';
import { BrightcoveMediaPlayerModule } from './brightcove-media-player/brightcove-media-player.module';
import { BubbleCelebrationModule } from './bubble-celebration/bubble-celebration.module';
import { CardCarouselModule } from './card-carousel/card-carousel.module';
import { ClaimsCardModule } from './claims-card/claims-card.module';
import { CoachingCardModule } from './coaching-card/coaching-card.module';
import { EmptyRouteModule } from './empty-route/empty-route.module';
import { ExternalMediaPlayerModule } from './external-link-video-player/external-media-player.module';
import { ImageErrorModule } from './image-error/image-error.module';
import { LoaderModule } from './loader/loader.module';
import { AgreementModalModule } from './modals/agreement-modal/agreement-modal.module';
import { CalendarModalModule } from './modals/calendar-event-modal/calendar-event-modal.module';
import { ClientAgreementModalModule } from './modals/client-agreement-modal/client-agreement-modal.module';
import { GlobalChallengesBlockerModule } from './modals/global-challenges-blocker-modal/global-challenges-blocker-modal.module';
import { InterestModalModule } from './modals/interest-modal/interest-modal.module';
import { JourneySurveyModalModule } from './modals/journey-survey-modal/journey-survey-modal.module';
import { JourneysCompletedModalModule } from './modals/journeys-completed-modal/journeys-completed-modal.module';
import { MaximumTrackersAddedModule } from './modals/maximum-trackers-added-modal/maximum-trackers-added.module';
import { PersonalChallengeCreateModalModule } from './modals/personal-challenge-create-modal/personal-challenge-create-modal.module';
import { StatsModalModule } from './modals/stats-modal/stats-modal.module';
import { TooManyHabitsModalModule } from './modals/too-many-habits-modal/too-many-habits-modal.module';
import { VideoModalModule } from './modals/video-modal/video-modal.module';
import { NotificationsModule } from './notifications/notifications.module';
import { SponsorDropdownModule } from './sponsor-dropdown/sponsor-dropdown.module';
import { StatGraphModule } from './stat-graph/stat-graph.module';
import { StatsCardsModule } from './stats-cards/stats-cards.module';
import { VpgrooveModule } from '@engineering/vpgroove-angular';
import { GenesisDirectivesModule } from '@genesis-frontend/genesis-utilities';

@NgModule({
    imports: [
        BubbleCelebrationModule,
        EmptyRouteModule,
        StatsCardsModule,
        InterestModalModule,
        AgreementModalModule,
        CardCarouselModule,
        CalendarModalModule,
        MaximumTrackersAddedModule,
        ImageErrorModule,
        NotificationsModule,
        LoaderModule,
        JourneysCompletedModalModule,
        PersonalChallengeCreateModalModule,
        ClientAgreementModalModule,
        SponsorDropdownModule,
        StatsModalModule,
        VpgrooveModule,
        GlobalChallengesBlockerModule,
        StatGraphModule,
        BrightcoveMediaPlayerModule,
        ExternalMediaPlayerModule,
        JourneySurveyModalModule,
        AccessibleDragAndDropModule,
        TooManyHabitsModalModule,
        VideoModalModule,
        GenesisDirectivesModule,
        CoachingCardModule,
        ClaimsCardModule
    ],
    exports: [
        BubbleCelebrationModule,
        EmptyRouteModule,
        StatsCardsModule,
        InterestModalModule,
        AgreementModalModule,
        CardCarouselModule,
        CalendarModalModule,
        MaximumTrackersAddedModule,
        ImageErrorModule,
        NotificationsModule,
        LoaderModule,
        JourneysCompletedModalModule,
        PersonalChallengeCreateModalModule,
        ClientAgreementModalModule,
        SponsorDropdownModule,
        VpgrooveModule,
        GlobalChallengesBlockerModule,
        StatGraphModule,
        BrightcoveMediaPlayerModule,
        ExternalMediaPlayerModule,
        JourneySurveyModalModule,
        AccessibleDragAndDropModule,
        VideoModalModule,
        CoachingCardModule,
        ClaimsCardModule
    ]
})
export class GenesisFeaturesModule { }
