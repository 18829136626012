import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PillarSummaryService } from '@genesis-frontend/genesis-data-access';
import { AuthenticatedMemberService, PillarTopic } from '@genesis-frontend/genesis-utilities';


@Injectable({
    providedIn: 'root'
})
export class MemberTopicsOfInterestsService {
    constructor(
        private pillarSummaryService: PillarSummaryService,
        private memberService: AuthenticatedMemberService,
        private httpClient: HttpClient
    ) {}

    get(): Observable<PillarTopic[]> {
        return this.pillarSummaryService.getTopicInterests(this.memberService.getAuthenticatedMember().id)
            .pipe(map(this.toTopics));
    }

    save(pillarTopics: PillarTopic[]): Observable<PillarTopic[]> {
        const memberId = this.memberService.getAuthenticatedMemberId();
        const selectedTopicInterests = pillarTopics.map((pillarTopic) => pillarTopic.id);

        return this.httpClient.put<PillarTopic[]>(`api/members/${memberId}/topics/interests`, selectedTopicInterests, {
            params: { updateBoards: false }
        });
    }

    private toTopics(interests: any[]): PillarTopic[] {
        const pillarTopics = interests.map((interest) => interest.pillarTopic);

        const uniquePillarTopicMap = new Map<number, PillarTopic>();
        pillarTopics.forEach((topic) => {
            const { id } = topic;
            if (uniquePillarTopicMap.has(id as number)) {
                return;
            }

            uniquePillarTopicMap.set(id as number, topic);
        });

        return Array.from(uniquePillarTopicMap.values());
    }
}
