import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { DateUtils } from '../lib/date-utils';
import { PreviewPage } from '../models';
import { PreviewParameters } from '../models';

@Injectable({
    providedIn: 'root'
})
export class PreviewToolStateService {
    private gameDates = new ReplaySubject<{startDate: string; endDate: string}>(1);
    private pages = new ReplaySubject<PreviewPage[]>(1);
    private params = new ReplaySubject<PreviewParameters>(1);

    private availablePages = [
        new PreviewPage(true, 'My Rewards (Earn)', 'my-rewards.earn'),
        new PreviewPage(true, 'My Rewards (Spend)', 'my-rewards.spend'),
        new PreviewPage(true, 'How To Earn', 'howtoearn'),
        new PreviewPage(true, 'Checklist', 'home'),
        new PreviewPage(true, 'Ways To Earn', 'home')
    ];

    constructor() {
        this.pages.next(this.availablePages.filter((page) => page.display === true));
        this.gameDates.next({
            startDate: DateUtils.format(
                DateUtils.sub(new Date(), 1, 'months'),
                'YYYY-MM-DD'
            ),
            endDate: DateUtils.format(
                DateUtils.add(new Date(), 1, 'years'),
                'YYYY-MM-DD'
            )
        });
    }

    getAvailablePages() {
        return this.availablePages;
    }

    getGameDates() {
        return this.gameDates;
    }

    getPages() {
        return this.pages;
    }

    getPreviewParameters() {
        return this.params;
    }

    updateGameDates(gameDates: {startDate: string; endDate: string}) {
        this.gameDates.next(gameDates);
    }

    updateParameters(newParams: PreviewParameters) {
        this.params.next(newParams);
    }

    updatePageDisplay(name: string, display: boolean) {
        const matchingPages: PreviewPage[] = this.availablePages.filter((page) => {
            return (page.name === name);
        });

        if (matchingPages.length > 0) {
            matchingPages[0].display = display;
            this.pages.next(this.availablePages.filter((page) => page.display === true));
        }
    }
}
