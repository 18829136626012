import { Type } from '@angular/core';
import { Resolve, Route, UrlSegment } from '@angular/router';

import { SingleSpaComponent } from '../../components/single-spa.component';
import { DefaultFrontendResolver } from '../resolvers';
import { SingleSpaConfiguration } from '@genesis-frontend/genesis-utilities';


export function routeMatch(prefix: string) {
    return (url: UrlSegment[]) => {
        const routeSegments = prefix.split('/').map((segment, index) => ({ index, path: segment }));
        const params = routeSegments.filter((segment) => segment.path.startsWith(':'));
        const path = url.map((segment, index) => params.find((param) => param.index === index)?.path || segment.path).join('/');

        if (path.startsWith(prefix)) {
            return { consumed: url };
        }
        return null;
    };
}


export function matchAnyFlag(path: string, resolver: Type<Resolve<SingleSpaConfiguration>>) {
    const route: Route = {
        matcher: routeMatch(path),
        component: SingleSpaComponent,
        resolve: {
            appConfiguration: resolver
        }
    };
    return route;
}

export function mfMatchAny(path: string, options: RouteOptions) {
    const route: Route = {
        matcher: routeMatch(path),
        component: SingleSpaComponent,
        resolve: {
            appConfiguration: DefaultFrontendResolver
        },
        data: {
            options: options
        }
    };
    return route;
}

export function match(path: string, options: RouteOptions) {
    return {
        path: path,
        component: SingleSpaComponent,
        resolve: {
            appConfiguration: DefaultFrontendResolver
        },
        data: {
            options: options
        }
    };
}

export interface RouteOptions {
    module: string,
    matcher: any,
    importModule?: string
}
