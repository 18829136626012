import lodash from 'lodash';

export const isEmpty = lodash.isEmpty;

/** @deprecated Use array map method */
export const map = lodash.map;

/** @deprecated Use array find method */
export const find = lodash.find;

/** @deprecated Use array filter method */
export const filter = lodash.filter;

/** @deprecated Use array filter method (with inverse predicate) */
export const reject = lodash.reject;

export const isNil = lodash.isNil;
export const orderBy = lodash.orderBy;
export const forEach = lodash.forEach;
export const includes = lodash.includes;
export const flatMap = lodash.flatMap;
export const get = lodash.get;
export const isEqual = lodash.isEqual;
export const without = lodash.without;
export const sumBy = lodash.sumBy;
export const trimStart = lodash.trimStart;

/** @deprecated Use array some method */
export const some = lodash.some;
export const sortBy = lodash.sortBy;
export const merge = lodash.merge;
export const replace = lodash.replace;
export const unescape = lodash.unescape;
export const remove = lodash.remove;
export const max = lodash.max;
export const extendWith = lodash.extendWith;
export const range = lodash.range;
export const defer = lodash.defer;
export const flatten = lodash.flatten;
export const flattenDeep = lodash.flattenDeep;
export const groupBy = lodash.groupBy;
export const has = lodash.has;
export const keyBy = lodash.keyBy;
export const lowerCase = lodash.lowerCase;
export const maxBy = lodash.maxBy;
export const minBy = lodash.minBy;
export const differenceWith = lodash.differenceWith;
export const cloneDeep = lodash.cloneDeep;
export const first = lodash.first;
export const findIndex = lodash.findIndex;
export const startCase = lodash.startCase;
export const intersection = lodash.intersection;
export const isUndefined = lodash.isUndefined;
export const assign = lodash.assign;
export const chain = lodash.chain;

/** @deprecated Use array reduce method */
export const reduce = lodash.reduce;

/** @deprecated Use array concat method */
export const concat = lodash.concat;
export const countBy = lodash.countBy;
export const union = lodash.union;
export const unionBy = lodash.unionBy;
export const unique = lodash.uniq;
export const uniqBy = lodash.uniqBy;
export const defaults = lodash.defaults;
export const snakeCase = lodash.snakeCase;

export type Dictionary<T> = Record<string, T>;
