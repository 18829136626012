<button id="{{id}}"
        class="sr-only"
        role="menuitem"
        (click)="onPositionSelect($event)"
        (keydown.enter)="onPositionSelect($event)"
        (keydown.escape)="onPositionSelect($event)"
        (keydown.arrowUp)="onPositionChange($event)"
        (keydown.arrowDown)="onPositionChange($event)"
        (keydown.arrowLeft)="onPositionChange($event)"
        (keydown.arrowRight)="onPositionChange($event)"
>
  {{wcagDnDLabel}}
</button>
