import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';

import {
    StatsTrackerModel
} from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class StatsCardsComponentService {

    constructor(private httpClient: HttpClient) { }

    public getStatisticTrackersForMember(): Observable<StatsTrackerModel[]> {
        const trackerGetOptions = { params: new HttpParams().set('statisticsOnly', 'true') };
        return this.httpClient.get<StatsTrackerModel[]>('/api/trackers', trackerGetOptions);
    }

    public getStatisticsByTracker(memberId: number, filteredTrackerID: number, startDate: string, endDate: string) {
        const statisticsGetParams = new HttpParams()
            .set('date', startDate)
            .set('endDate', endDate);
        return this.httpClient.get<StatsTrackerModel>(`/api/members/${memberId}/trackers/${filteredTrackerID}/statistics`, {
            params: statisticsGetParams
        });
    }
}
