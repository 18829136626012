import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { TranslationsDropdownComponent } from './translations-dropdown/translations-dropdown.component';
import { TranslationsTableComponent } from './translations-table/translations-table.component';
import { DeleteCancelModalModule } from '../modals/delete-cancel-modal/delete-cancel-modal.module';
import { VpgrooveModule } from '@engineering/vpgroove-angular';

@NgModule({
    declarations: [
        TranslationsTableComponent,
        TranslationsDropdownComponent
    ],
    imports: [
        DeleteCancelModalModule,
        CommonModule,
        VpgrooveModule,
        FormsModule,
        TooltipModule
    ],
    exports: [
        TranslationsTableComponent,
        TranslationsDropdownComponent
    ],
    providers: [
    ]
})
export class ClientAdminModule {}
