import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CloseModalService {

    closeOpenedModals(): void {
        const openedModals = document.querySelectorAll('.modal.in');
        if (openedModals && openedModals.length) {
            openedModals.forEach((modal) => {
                const closeButton = modal.querySelector('button.close') as HTMLElement;
                if (closeButton) {
                    closeButton.click();
                }
            });
        }
    }
}
