import { Component, Inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { GlobalChallengeCardCarouselDataModel } from '../../../interface/global-challenges-card-carousel-data.model';
import { VpGoService } from '../../vp-go.service';
import { AuthenticatedMemberService, UnleashFlag, UnleashFlagsService, DateUtils } from '@genesis-frontend/genesis-utilities';

@Component({
    templateUrl: './device-ordering.template.html',
    styleUrls: ['./device-ordering.component.scss']
})
export class GlobalChallengesMaxBuzzOrOwnDevice {
    manualStepsAllowed: any;
    isMaxBuzzEnabled = false;
    isTrackManuallyEnabled = false;
    isMaxBuzzAndTrackManuallyDisabled = false;
    isPersonifyHealthTheme = false;

    constructor(
        public vpGoService: VpGoService,
        @Inject('data') public data: GlobalChallengeCardCarouselDataModel,
        private bsModalRef: BsModalRef,
        private authenticatedMemberService: AuthenticatedMemberService,
        private unleashFlagService: UnleashFlagsService
    ) {}

    ngOnInit() {
        this.isPersonifyHealthTheme = this.unleashFlagService.hasUnleashFlag(UnleashFlag.BrandingUpdatesPhase2);
        this.getContestConfigurationInfo();
    }

    getContestConfigurationInfo() {
        const memberTimezone = this.authenticatedMemberService.getCurrentTimezone();
        const membersCurrentDate = memberTimezone.getMemberDate();
        const freeMaxBuzzOpenDays = this.data.contest.contestSettings.freeMaxBuzzOpenDays || 0;
        const maxBuzzOpenDaysDate = DateUtils.add(memberTimezone.getDateInMemberDate(this.data.contest.startDate), freeMaxBuzzOpenDays, 'days');

        if (DateUtils.isSameOrAfter(membersCurrentDate, maxBuzzOpenDaysDate)) {
            this.data.contest.contestSettings.freeMaxBuzz = false;
        }

        this.isMaxBuzzEnabled = this.data.contest.contestSettings.freeMaxBuzz;
        this.isTrackManuallyEnabled = !this.data.contest.contestSettings.freeMaxBuzz && this.data.contest.allowNonValidatedMetrics;
        this.isMaxBuzzAndTrackManuallyDisabled =
            !this.data.contest.contestSettings.freeMaxBuzz && !this.data.contest.allowNonValidatedMetrics;
        this.manualStepsAllowed = this.data.contest.allowNonValidatedMetrics;
    }

    goToChallengeTeam() {
        this.bsModalRef.hide();
        window.location.href = `${window.location.origin}/#/featuredchallenge/${this.data.contest.contestId}`;
        this.data.finishedCallback();
    }

    goToConnectDeviceBoard() {
        this.data.goToCard(this.vpGoService.VpGoCards.CONNECT_DEVICE_CARD_INDEX);
    }
}
