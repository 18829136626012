export enum LiveServicesCoachingStatus {
    Scheduled = 'Scheduled',
    Completed = 'Completed',
    Rescheduled = 'Rescheduled',
    Canceled = 'Canceled',
    Missed = 'Missed',
    CannotComplete = 'CannotComplete',
    InProgress = 'InProgress',
    Processing = 'Processing'
}
