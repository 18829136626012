import { Inject, Injectable } from '@angular/core';
import { OverridedMixpanel } from 'mixpanel-browser';

// import { ZopimService } from './zopim.service';
import { KeycloakService } from '../authentication';

@Injectable({
    providedIn: 'root'
})
export class LogoutMemberService {
    constructor(
        @Inject('mixpanel') private mixpanel: OverridedMixpanel,
        private keycloakService: KeycloakService
        // private zopimService: ZopimService
    ) {}

    logout(isTimeout: boolean, customLogoutUrl?: string) {
        sessionStorage.clear();
        this.mixpanel.reset();
        // this.zopimService.closeChat();
        const keycloakClient = this.keycloakService.getClient();
        let logoutUrl = window.location.origin + '/logout.html';

        if (isTimeout && customLogoutUrl) {
            logoutUrl = customLogoutUrl;
        }

        keycloakClient.logout({
            redirectUri: logoutUrl
        });
    }
}
