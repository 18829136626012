<link rel="preconnect" [href]="microfrontendUrl"/>
<spa-module [module]="moduleRegistry.modules.blocker"></spa-module>
<trustarc></trustarc>
<div class="app-holder" *ngIf="blockerChainResolved">
    <div class="sticky-responsive-navbar">
        <div [hidden]="hideNavigation">

            <spa-module [module]="moduleRegistry.modules.navigationMenu"></spa-module>
            <genesis-frontend-notifications></genesis-frontend-notifications>


            <div *ngIf="notificationPaneEnabled">
                <div id="new-notification-pane-backdrop" [attr.aria-hidden]="true">
                </div>
                <div id="notification-wrapper"
                    class="responsive-nav-enabled new-notification-wrapper">
                    <spa-module [module]="moduleRegistry.modules.notifications" [delay]="5000" className="new-notif-panel"></spa-module>
                </div>
            </div>
        </div>
    </div>

    <div
        id="page-wrapper"
        class="background-repeater collapsed"
        [ngClass]="{'home-route': isHome,
            'with-new-homepage': (isHome && !hasUnleashFlag) || isClaims,
            'with-rebranding-unleash-flag': isHome && hasUnleashFlag,
            'with-rebranding-unleash-flag-phase2': showNewBackground && !isHome,
            'with-navigation': !hideNavigation,
            'with-transform': isTransformUI}">
        <div class="page-content page-content-ease-in main-text-color">
            <router-outlet></router-outlet>

            <div id="single-spa-application:social-ui"></div>
            <div id="single-spa-application:member-profile"></div>
            <div id="single-spa-application:dashboard-challenges-ui"></div>
            <div id="single-spa-application:dashboard-challenges-genesis-ui"></div>
            <div id="single-spa-application:hh-challenges-ui"></div>
            <div id="single-spa-application:phhc-challenges-ui"></div>
            <div id="single-spa-application:hh-challenges-genesis-ui"></div>
            <div id="single-spa-application:phhc-challenges-genesis-ui"></div>
            <div id="single-spa-application:personal-challenges-ui"></div>
            <div id="single-spa-application:personal-challenges-genesis-ui"></div>
            <div id="single-spa-application:details-challenges-ui"></div>
            <div id="single-spa-application:details-challenges-genesis-ui"></div>
            <div id="single-spa-application:featured-challenges-ui"></div>
            <div id="single-spa-application:featured-challenges-genesis-ui"></div>
            <div id="single-spa-application:goal-challenges-ui"></div>
            <div id="single-spa-application:goal-challenges-genesis-ui"></div>
            <div id="single-spa-application:interrupt-challenges-ui"></div>
            <div id="single-spa-application:interrupt-challenges-genesis-ui"></div>
            <div id="single-spa-application:live-services-ui"></div>
            <div id="single-spa-application:rewards-ui"></div>
            <div id="single-spa-application:partner-genesis-ui"></div>
            <div id="single-spa-application:health-ui"></div>
            <div id="single-spa-application:stats-page"></div>
            <div id="single-spa-application:stats-page-genesis-ui"></div>
            <div id="single-spa-application:genesis-ui"></div>
            <div id="single-spa-application:devices-and-apps"></div>
            <div class="pos-rel" id="single-spa-application:benefits"></div>
            <div id="single-spa-application:media-ui"></div>
            <div id="single-spa-application:journeys-ui"></div>
            <div id="single-spa-application:journeys-genesis-ui"></div>
            <div id="single-spa-application:groups-genesis-ui"></div>
            <div id="single-spa-application:guides-ui"></div>
            <div id="single-spa-application:redeem-credits-genesis-ui"></div>
            <div id="single-spa-application:download-sync-app"></div>
            <div id="single-spa-application:achdeposit-genesis-ui"></div>
            <div id="single-spa-application:missingSteps-genesis-ui"></div>
            <div id="single-spa-application:coaches-corner-ui"></div>
            <div id="single-spa-application:healthy-habits"></div>
            <div id="single-spa-application:calendar-ui"></div>
            <div id="single-spa-application:newsflash-ui"></div>
            <div id="single-spa-application:surveys-ui"></div>
            <div id="single-spa-application:surveys-genesis-ui"></div>
            <div id="single-spa-application:pillars-and-topics-ui"></div>
            <div id="single-spa-application:health-genesis-ui"></div>
            <div id="single-spa-application:transform-ui"></div>
            <div id="single-spa-application:health-risk-assesment-app"></div>
            <div id="single-spa-application:challenges-ui"></div>
            <div id="single-spa-application:preview-boards"></div>
            <div id="single-spa-application:homepage-ui"></div>
            <div class="pos-rel" id="single-spa-application:tpa-resources"></div>
        </div>
    </div>

    <div class="footer-bottom-position">
        <spa-module [module]="moduleRegistry.modules.footer" [delay]="5000"></spa-module>
    </div>
    <trophy-popup></trophy-popup>
</div>
