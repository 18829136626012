<div class="journey-completed-modal-container"
     id="journey-completed-modal-id"
     #journeyCompletedModal>
    <div class="journey-completed-modal-content">
        <div class="journey-completed-modal-journey-title-row">
            <label class="journey-completed-modal-journey-title text-clr-boulder"
                   id="journey-completed-modal-title"
                   role="heading"
                   aria-level="1">
                {{ journeyTitle }}
            </label>
        </div>
        <div class="journey-completed-modal-content-rows">
            <div class="journey-completed-modal-content-row">
                <label id="journeys-panel-header-id"
                       class="journeys-panel-header"
                       [translate]="'YouDidIt'"
                       role="heading"
                       aria-level="2">
                </label>
            </div>
            <div class="journey-completed-modal-content-row">
                <span id="journey-completed-modal-healthier-lifestyle-text"
                      class="journey-completed-modal-keep-tracking"
                      [translate]="'HealthierSustainableLifestyle'">
                    A healthier, more sustainable lifestyle awaits you.
                </span>
            </div>
            <div class="journey-completed-modal-content-row">
                <img [src]="'/img/journey-celebration-big.png'" alt=""/>
            </div>
            <div class="journey-completed-modal-content-row">
                <span *ngIf="!isSurveyCompleted"
                      id="journey-completed-modal-feedback-text"
                      class="journey-completed-modal-keep-tracking"
                      [translate]="'WeWouldLoveFeedback'">
                    We'd love your feedback! Help us improve the Journey experience with this 3-question survey. It should take about 1 minute.
                </span>
                <div *ngIf="isSurveyCompleted"
                     class="journey-step-completed-area">
                    <div class="journey-step-complete-checkmark">
                        <span aria-hidden="true"
                              class="vp-margin-top-10 text-clr-success">
                            <i class="far fa-check-circle fa-4x"></i>
                        </span>
                    </div>
                    <span id="journey-completed-modal-appreciate-feedback-text"
                          class="journey-completed-modal-keep-tracking"
                          [translate]="'WeAppreciateFeedback'">
                        We appreciate your feedback!
                    </span>
                </div>
            </div>
        </div>    
    </div>
    <div class="journey-completed-modal-button-wrapper-override">
        <vpg-button 
            *ngIf="!isSurveyCompleted"
            [id]="'journey-completed-no-thanks-btn'"
            [btnType]="SECONDARY_BUTTON"
            (buttonClicked)="close()"
            [label]="'NoThanks' | translate">
        </vpg-button>
        <vpg-button 
            *ngIf="!isSurveyCompleted"
            [id]="'journey-completed-take-survey-btn'"
            [btnType]="PRIMARY_BUTTON"
            (buttonClicked)="openSurveyModal()"
            [label]="'TakeSurvey' | translate">
        </vpg-button>
        <vpg-button 
            *ngIf="isSurveyCompleted"
            [id]="'journey-completed-done-btn'"
            [btnType]="PRIMARY_BUTTON"
            (buttonClicked)="close()"
            [label]="'Done' | translate">
        </vpg-button>
    </div>
</div>
