import { Member } from '../../models';

export const ADD_AUTHENTICATION = 'ADD_AUTHENTICATION';
export const AddAuthenticatedMember = (member: Member, authClient: any) => {
    return {
        type: ADD_AUTHENTICATION,
        payload: { member, authClient }
    };
};

export const UPDATE_AUTHENTICATED_MEMBER = 'UPDATE_AUTHENTICATED_MEMBER';
export const UpdateAuthenticatedMember = (member: Member) => {
    return {
        type: UPDATE_AUTHENTICATED_MEMBER,
        payload: member
    };
};

export const SEND_NOTIFICATION_AVAILABLE = 'SEND_NOTIFICATION_AVAILABLE';
export const AddNotificationInfo = (notificationAvailable: boolean) => {
    return {
        type: SEND_NOTIFICATION_AVAILABLE,
        payload: notificationAvailable
    };
};

export const UPDATE_MEREDEEMABLE_WALLETS = 'UPDATE_REDEEMABLE_WALLETS';
export const UPDATE_MEMBER_REDEEMABLE_WALLETS = 'UPDATE_MEMBER_REDEEMABLE_WALLETS';
export const UpdateMemberRedeemableWallets = (redeemableWallets: any) => {
    return {
        type: UPDATE_MEMBER_REDEEMABLE_WALLETS,
        payload: redeemableWallets
    };
};

export const UPDATE_METOTAL_SUMMARY = 'UPDATE_TOTAL_SUMMARY';
export const UPDATE_MEMBER_TOTAL_SUMMARY = 'UPDATE_MEMBER_TOTAL_SUMMARY';
export const UpdateMemberTotalSummary = (totalSummary: any) => {
    return {
        type: UPDATE_MEMBER_TOTAL_SUMMARY,
        payload: totalSummary
    };
};

export const UPDATE_MEMBER_REWARDS_OVERVIEW = 'UPDATE_MEMBER_REWARDS_OVERVIEW';
export const UpdateMemberRewardsOverview = (rewardsOverview: any) => {
    return {
        type: UPDATE_MEMBER_REWARDS_OVERVIEW,
        payload: rewardsOverview
    };
};

export const UPDATE_SPONSOR_VP_NAVIGATE_FEATURES = 'UPDATE_SPONSOR_VP_NAVIGATE_FEATURES';
export const UpdateSponsorVpNavigateFeatures = (sponsorVpNavigateFeatures: any) => {
    return {
        type: UPDATE_SPONSOR_VP_NAVIGATE_FEATURES,
        payload: sponsorVpNavigateFeatures
    };
};

export const ADD_MIXPANEL_OBJECT = 'ADD_MIXPANEL_OBJECT';
export const AddMixpanelObject = (mixpanelObject: any) => {
    return {
        type: ADD_MIXPANEL_OBJECT,
        payload: mixpanelObject
    };
};

export const ADD_HISTORICAL_SURVEYS = 'ADD_HISTORICAL_SURVEYS';
export const AddHistoricalSurveys = (historicalSurveys: any) => {
    return {
        type: ADD_HISTORICAL_SURVEYS,
        payload: historicalSurveys
    };
};

export const ADD_MEMBER_FEATURES = 'ADD_MEMBER_FEATURES';
export const AddMemberFeatures = (memberFeatures: any) => {
    return {
        type: ADD_MEMBER_FEATURES,
        payload: memberFeatures
    };
};

export const ADD_MEMBER_FEATURE_TOGGLE = 'ADD_MEMBER_FEATURE_TOGGLE';
export const AddMemberFeatureToggle = (memberFeatureToggle: any) => {
    return {
        type: ADD_MEMBER_FEATURE_TOGGLE,
        payload: memberFeatureToggle
    };
};

export const ADD_SPONSOR_FEATURE_TOGGLE = 'ADD_SPONSOR_FEATURE_TOGGLE';
export const AddSponsorFeatureToggle = (sponsorFeatureToggle: any) => {
    return {
        type: ADD_SPONSOR_FEATURE_TOGGLE,
        payload: sponsorFeatureToggle
    };
};

export const ADD_MEMBER_ALL_PROMOTED_REWARDS = 'ADD_MEMBER_ALL_PROMOTED_REWARDS';
export const AddMemberAllPromotedRewards = (allPromotedRewards: any) => {
    return {
        type: ADD_MEMBER_ALL_PROMOTED_REWARDS,
        payload: allPromotedRewards
    };
};

export const UPDATE_CHALLENGES_DATA = 'UPDATE_CHALLENGES_DATA';
export const UpdateChallengesData = (challengesData: any) => {
    return {
        type: UPDATE_CHALLENGES_DATA,
        payload: challengesData
    };
};

export const ADD_CONFIGURATION = 'ADD_CONFIGURATION';
export const AddConfiguration = (configuration: any) => {
    return {
        type: ADD_CONFIGURATION,
        payload: { configuration }
    };
};

export const ADD_MEMBER_PRODUCTS = 'ADD_MEMBER_PRODUCTS';
export const AddMemberProducts = (memberProducts: any) => {
    return {
        type: ADD_MEMBER_PRODUCTS,
        payload: { memberProducts }
    };
};

export const ADD_MEMBER_COUNTRY = 'ADD_MEMBER_COUNTRY';
export const AddMemberCountry = (memberCountry: any) => {
    return {
        type: ADD_MEMBER_COUNTRY,
        payload: { memberCountry }
    };
};

export const ADD_REWARD_LABELS = 'ADD_REWARD_LABELS';
export const AddRewardLabels = (rewardLabels: any) => {
    return {
        type: ADD_REWARD_LABELS,
        payload: rewardLabels
    };
};

export const ADD_READ_IMAGE_SECURITY_DATA = 'ADD_READ_IMAGE_SECURITY_DATA';
export const AddReadImageSecurityData = (readSecurityData: any) => {
    return {
        type: ADD_READ_IMAGE_SECURITY_DATA,
        payload: readSecurityData
    };
};

export const ADD_PILLAR_SETTINGS = 'ADD_PILLAR_SETTINGS';
export const AddPillarSettings = (pillarSettings: any) => {
    return {
        type: ADD_PILLAR_SETTINGS,
        payload: pillarSettings
    };
};

export const UPDATE_REWARDS_STATEMENT_FILTER_INTERVAL = 'UPDATE_REWARDS_STATEMENT_FILTER_INTERVAL';
export const UpdateRewardsStatementFilterInterval = (rewardsStatementFilterInterval: any) => {
    return {
        type: UPDATE_REWARDS_STATEMENT_FILTER_INTERVAL,
        payload: rewardsStatementFilterInterval
    };
};

export const UPDATE_REWARDS_STATEMENT_MEMBER_PROGRAMS = 'UPDATE_REWARDS_STATEMENT_MEMBER_PROGRAMS';
export const UpdateRewardsStatementMembersPrograms = (rewardsStatementMemberPrograms: any) => {
    return {
        type: UPDATE_REWARDS_STATEMENT_MEMBER_PROGRAMS,
        payload: rewardsStatementMemberPrograms
    };
};

export const genesisMemberStateActions = [
    ADD_AUTHENTICATION,
    SEND_NOTIFICATION_AVAILABLE,
    UPDATE_AUTHENTICATED_MEMBER,
    UPDATE_MEMBER_REDEEMABLE_WALLETS,
    UPDATE_MEMBER_REWARDS_OVERVIEW,
    UPDATE_SPONSOR_VP_NAVIGATE_FEATURES,
    ADD_MIXPANEL_OBJECT,
    ADD_HISTORICAL_SURVEYS,
    ADD_MEMBER_FEATURES,
    ADD_MEMBER_FEATURE_TOGGLE,
    ADD_SPONSOR_FEATURE_TOGGLE,
    ADD_MEMBER_ALL_PROMOTED_REWARDS,
    UPDATE_CHALLENGES_DATA,
    ADD_CONFIGURATION,
    ADD_MEMBER_PRODUCTS,
    ADD_MEMBER_COUNTRY,
    ADD_REWARD_LABELS,
    ADD_READ_IMAGE_SECURITY_DATA,
    ADD_PILLAR_SETTINGS,
    UPDATE_REWARDS_STATEMENT_FILTER_INTERVAL,
    UPDATE_MEMBER_TOTAL_SUMMARY,
    UPDATE_REWARDS_STATEMENT_MEMBER_PROGRAMS
];
export default {
    AddAuthenticatedMember,
    AddNotificationInfo,
    UpdateAuthenticatedMember,
    UpdateMemberRedeemableWallets,
    UpdateMemberRewardsOverview,
    UpdateSponsorVpNavigateFeatures,
    AddMixpanelObject,
    AddHistoricalSurveys,
    AddMemberFeatures,
    AddMemberFeatureToggle,
    AddSponsorFeatureToggle,
    AddMemberAllPromotedRewards,
    UpdateChallengesData,
    AddConfiguration,
    AddMemberProducts,
    AddMemberCountry,
    AddRewardLabels,
    AddReadImageSecurityData,
    AddPillarSettings,
    UpdateRewardsStatementFilterInterval,
    UpdateRewardsStatementMembersPrograms
};
