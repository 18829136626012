import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { AuthenticatedMemberService, FeatureEnum, SingleSpaConfiguration } from '@genesis-frontend/genesis-utilities';

@Injectable({ providedIn: 'root' })
export class ChallengesDashboardResolverService implements Resolve<SingleSpaConfiguration> {
    constructor(private authenticatedMemberService: AuthenticatedMemberService) {}
    resolve(): any {
        const hasFeatureFlag = this.authenticatedMemberService.hasFeature(FeatureEnum.ChallengeRedesign2022)
            || this.authenticatedMemberService.hasFeature(FeatureEnum.SpotlightRedesign2022);
        const appImportPath = hasFeatureFlag ? 'challenges-ui' : 'genesis-ui';
        const appName = hasFeatureFlag ? 'dashboard-challenges-ui' : 'dashboard-challenges-genesis-ui';

        return {
            appName,
            appRegex: new RegExp('^#/challenges/dashboard'),
            isStandAloneApp: false,
            appImportPath
        } as SingleSpaConfiguration;
    }
}
