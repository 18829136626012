import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { SponsorDropdownService } from './sponsor-dropdown.service';

@Component({
    selector: 'sponsor-dropdown',
    templateUrl: 'sponsor-dropdown.component.html',
    styleUrls: ['sponsor-dropdown.component.scss']
})

export class SponsorDropdownComponent {

    @Input() itemType: any;
    @Input() settings?: any = {
        pageSize: 25,
        sortBy: 'name',
        sortDir: 'ASC'
    };
    @Input() isDisabled = false;
    @Output() itemAction: any = new EventEmitter();

    @ViewChild('dropdownListContainer') dropdownListContainer: ElementRef = { nativeElement: {} };

    SEARCH_DELAY_TIME = 700;
    percentScrolledForNextPage = 40;
    currentlyLoadingMessages = false;
    selectedSponsor?: string;

    allItems: any = [];
    itemsPage: any = [];
    canLoadMoreMessages = true;
    itemSearchText = '';
    showMenu = false;
    delayedNgModelChange$ = new Subject();
    isClicked = false;

    constructor(private paginatedDropdownService: SponsorDropdownService) {
        this.delayedNgModelChange$.pipe(
            debounceTime(this.SEARCH_DELAY_TIME)
        ).subscribe(() => {
            this.searchItems();
        });
    }

    toggleMenu() {
        if (!this.isDisabled) {
            this.allItems = [];
            this.initScrollablePaging();
            this.fetchPagedItems();
            this.isClicked = !this.isClicked;
        }
    }
    fetchPagedItems() {
        const queryOptions = this.settings;
        queryOptions.page = 0;

        if (this.itemType === 'Distributor') {
            this.paginatedDropdownService.getDistributors(queryOptions).subscribe((response: any) => {
                this.itemsPage = response.body || response;
                // eslint-disable-next-line prefer-spread
                this.allItems.push.apply(this.allItems, this.itemsPage);
            });
        } else {
            this.paginatedDropdownService.getSponsors(queryOptions).subscribe((response: any) => {
                this.itemsPage = response.body || response;
                // eslint-disable-next-line prefer-spread
                this.allItems.push.apply(this.allItems, this.itemsPage);
            });
        }
    }

    initScrollablePaging() {
        setTimeout(() => {
            this.dropdownListContainer?.nativeElement.addEventListener('scroll', () => {
                const scrollElement = this.dropdownListContainer.nativeElement;
                if (!this.currentlyLoadingMessages && this.canLoadMoreMessages && scrollElement) {
                    const percentScrolled = ((scrollElement.scrollTop + scrollElement.offsetHeight) / scrollElement.scrollHeight) * 100;
                    if (percentScrolled > this.percentScrolledForNextPage) {
                        this.loadNextPagedItems();
                    }
                }
            });
        }, 0);

    }

    loadNextPagedItems() {
        if (this.canLoadMoreMessages) {
            this.currentlyLoadingMessages = true;
            const queryOptions = this.settings;

            queryOptions.page = Math.floor((this.allItems.length) / this.settings.pageSize);

            if (this.itemSearchText) {
                queryOptions.name = this.itemSearchText;
            }

            if (this.itemType === 'Distributor') {
                this.paginatedDropdownService.getDistributors(queryOptions).subscribe((response: any) => {
                    const responseData = response.body || response;
                    const itemsToAdd = responseData.filter((newItem: any) => {
                        const matchingItems = this.allItems.filter((existingItem: any) => {
                            return existingItem.id === newItem.id;
                        });
                        return matchingItems.length === 0;
                    });

                    this.allItems = this.allItems.concat(itemsToAdd);
                    this.canLoadMoreMessages = responseData.length === this.settings.pageSize;
                    this.currentlyLoadingMessages = false;
                });
            } else {
                this.paginatedDropdownService.getSponsors(queryOptions).subscribe((response: any) => {
                    const responseData = response.body || response;
                    const itemsToAdd = responseData.filter((newItem: any) => {
                        const matchingItems = this.allItems.filter((existingItem: any) => {
                            return existingItem.id === newItem.id;
                        });
                        return matchingItems.length === 0;
                    });

                    this.allItems = this.allItems.concat(itemsToAdd);
                    this.canLoadMoreMessages = responseData.length === this.settings.pageSize;
                    this.currentlyLoadingMessages = false;
                });
            }

        }
    }

    itemClicked(item: any) {
        this.selectedSponsor = item.name;
        this.itemAction.emit(item);
        this.isClicked = !this.isClicked;
    }

    searchItems() {
        const queryOptions = this.settings;
        queryOptions.page = 0;
        queryOptions.name = this.itemSearchText;
        if (this.itemType === 'Distributor') {
            this.paginatedDropdownService.getDistributors(queryOptions).subscribe((response: any) => {
                this.itemsPage = response.body || response;
                this.allItems = this.itemsPage;
                this.canLoadMoreMessages = this.itemsPage.length === this.settings.pageSize;
            });
        } else {
            this.paginatedDropdownService.getSponsors(queryOptions).subscribe((response: any) => {
                this.itemsPage = response.body || response;
                this.allItems = this.itemsPage;
                this.canLoadMoreMessages = this.itemsPage.length === this.settings.pageSize;
            });
        }

    }
}
