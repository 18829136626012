import { Injectable } from '@angular/core';

import { MessageCenterPaneClasses } from '../models/enums/message-center-pane-classes.enum';
import { MessageCenterPaneStyles } from '../models/enums/message-center-pane-styles.enum';
import { MessageCenterPaneIds } from '../models/enums/message-center-paneIds.enum';
import { MessageCenterPaneTabIndex } from '../models/enums/message-center-panetab-index.enum';

@Injectable({
    providedIn: 'root'
})
export class MessageCentrePanelService {

    toggleMessageCenterPane(): void {
        this.addMessageCenterPaneOrDrawer();
    }

    addMessageCenterPaneOrDrawer(): void {
        const messageCenterPane = document.getElementById(MessageCenterPaneIds.MessageCenterPane) as HTMLElement;
        const messageDrawer = document.getElementById('message-center-drawer') as HTMLElement;
        if (messageCenterPane) {
            this.setMessageCenterNotificationsFocus(MessageCenterPaneTabIndex.Focused);
            messageCenterPane.classList.add(MessageCenterPaneClasses.NewMessageCenterPanel);
            if (messageDrawer) {
                messageDrawer.classList.add('active');
            }
            if (messageCenterPane.classList.contains(MessageCenterPaneClasses.MessageCenterPanel)) {
                messageCenterPane.classList.remove(MessageCenterPaneClasses.MessageCenterPanel);
                this.setMessageCenterNotificationsFocus(MessageCenterPaneTabIndex.UnFocused);
            } else {
                messageCenterPane.classList.add(MessageCenterPaneClasses.MessageCenterPanel);
                this.setMessageCenterNotificationsFocus(MessageCenterPaneTabIndex.Focused);
                messageCenterPane.classList.remove(MessageCenterPaneClasses.NewMessageCenterPanel);
            }
        }
        if (messageCenterPane?.classList.contains(MessageCenterPaneClasses.NewMessageCenterPanel) && messageDrawer) {
            messageDrawer.classList.remove('active');
        }
    }

    setMessageCenterNotificationsFocus(messageCenterTabIndex: string): void {
        const focusElements: HTMLCollectionOf<Element> = document.getElementsByClassName('notification-element');
        Array.from(focusElements).forEach((focusElement) => {
            const buttons = Array.from(focusElement.getElementsByTagName('button'));
            const links = Array.from(focusElement.getElementsByTagName('a'));

            if (buttons.length) {
                buttons.forEach((button) => {
                    button.setAttribute('tabindex', messageCenterTabIndex);
                });
            } else if (links.length) {
                links.forEach((link) => {
                    link.setAttribute('tabindex', messageCenterTabIndex);
                });
            } else {
                focusElement.setAttribute('tabindex', messageCenterTabIndex);
            }
        });
        this.setModalPropertyForMessageCenter(messageCenterTabIndex);
    }

    closeOnPaneBackdropClickForMessageCenter(): void {
        this.toggleMessageCenterPane();
    }

    turnOffMessageCenterPane(): void {
        this.setMessageCenterNotificationsFocus(MessageCenterPaneTabIndex.UnFocused);
        this.turnOffAndOnScreenReaderForElements('notifications-wcag', MessageCenterPaneTabIndex.UnFocused);
        this.turnOffAndOnScreenReaderForElements('navigation-element', MessageCenterPaneTabIndex.UnFocused);
        this.setMessageCenterNavigationFocus(MessageCenterPaneTabIndex.Focused);
        const messageCenterPane = this.getElementById(MessageCenterPaneIds.MessageCenterPane);
        const messageCenterWrapper = this.getElementById(MessageCenterPaneIds.MessageCenterWrapper);
        if (messageCenterPane) {
            messageCenterPane.classList.remove(MessageCenterPaneClasses.NewMessageCenterPanel);
            if (messageCenterWrapper) {
                messageCenterWrapper.classList.remove(MessageCenterPaneClasses.MessageCenterPaneOpened);
            }
        }
        const siteBody = this.getElementById(MessageCenterPaneIds.SiteBody);
        if (siteBody) {
            siteBody.classList.remove(MessageCenterPaneClasses.VpHideScrollbar);
        }
        this.updateButtonVisibility(MessageCenterPaneIds.SupportBtn);
        this.updateButtonVisibility(MessageCenterPaneIds.ChatBtn);
    }

    private updateButtonVisibility(buttonId: string): void {
        const buttonElement = this.getElementById(buttonId);
        if (buttonElement) {
            buttonElement.style.visibility = MessageCenterPaneStyles.Visible;
            buttonElement.setAttribute('tabindex', MessageCenterPaneTabIndex.Focused);
            buttonElement.setAttribute('aria-hidden', 'false');
        }
    }

    private getElementById(id: string): HTMLElement | null {
        return document.getElementById(id) as HTMLElement | null;
    }


    setModalPropertyForMessageCenter(messageCenterTabIndex: string): void {
        const mainDiv = this.getFirstElementByClassName('main-notification-container');
        if (mainDiv) {
            mainDiv.setAttribute('aria-modal', messageCenterTabIndex === MessageCenterPaneTabIndex.Focused ? 'true' : 'false');
        }
    }

    private getFirstElementByClassName(className: string): Element | null {
        const elements = document.getElementsByClassName(className);
        return elements.length > 0 ? elements[0] : null;
    }

    setMessageCenterNavigationFocus(messageCenterTabIndex: string): void {
        const focusElements: HTMLCollectionOf<Element> = document.getElementsByClassName('navigation-element');
        Array.from(focusElements).forEach((focusElement) => {
            focusElement.setAttribute('tabindex', messageCenterTabIndex);
        });
    }

    turnOffAndOnScreenReaderForElements(className: string, messageCenterStatus: string): void {
        const focusElements: HTMLCollectionOf<Element> = document.getElementsByClassName(className);
        Array.from(focusElements).forEach((focusElement) => {
            if (messageCenterStatus === MessageCenterPaneTabIndex.UnFocused || messageCenterStatus === MessageCenterPaneTabIndex.Focused) {
                focusElement.setAttribute('aria-hidden', 'false');
            }
        });
    }
}
