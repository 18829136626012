import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';

import { VideoModalComponent } from './video-modal.component';
import { VideoModalService } from './video-modal.service';

@NgModule({
    imports: [
        CommonModule,
        ModalModule.forRoot()
    ],
    declarations: [
        VideoModalComponent
    ],
    providers: [
        VideoModalService
    ],
    exports: []
})
export class VideoModalModule {}
