import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { ImageType } from '@engineering/vpgroove-angular';
import { PalMemberPromotedAction, RewardType } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'genesis-frontend-pal-card',
    templateUrl: './pal-card.component.html',
    styleUrls: ['./pal-card.component.scss']
})
export class PalCardComponent implements OnInit {
    @Input() card!: PalMemberPromotedAction;
    @Input() isHomepageCard = true;
    @Input() eventCode = '';

    @Output() palCardClicked: EventEmitter<PalMemberPromotedAction> = new EventEmitter<PalMemberPromotedAction>();

    readonly iconImageType = ImageType.Hero;
    readonly completedCheckmark = '/img/check-mark.svg';

    readonly ZERO_DISPLAY_VALUE = '0';
    role = 'button';
    tabIndex = 0;
    title = '';
    classNames = {};
    reward: { iconSource: string; text: string } | null = null;
    completed = false;
    display = true;

    ngOnInit(): void {
        this.mapCardData();
        this.setReward();
    }

    private mapCardData(): void {
        this.role = this.card.url ? 'button' : 'none';
        this.tabIndex = this.card.url ? 0 : -1;
        this.classNames = { 'homepage-card': this.isHomepageCard, 'dialog-card': !this.isHomepageCard, 'card-with-action': this.card.url };
        this.completed = this.card.completed;
        this.title = this.card.name || '';
        if (this.card.shouldDisplay !== undefined) {
            this.display = this.card.shouldDisplay;
        }
    }

    private getRewardText(): string {
        if (this.card.rewardDisplayValue === this.ZERO_DISPLAY_VALUE) {
            return this.card.rewardTypeDisplay as string;
        }
        const rewardText = `${this.card.rewardDisplayValue} ${this.card.rewardTypeDisplay}`;

        return this.card.rewardType === RewardType.TextOnly ? rewardText : `${rewardText}`;
    }

    private setReward(): void {
        if (this.card.rewardType === RewardType.NoReward || this.card.rewardDisplayValue === this.ZERO_DISPLAY_VALUE) {
            this.reward = null;
        } else {
            const iconSource = this.card.rewardType === RewardType.PulseCash
                ? '/img/rewards-cash-icon.svg'
                : '/img/rewards-star-icon.svg';
            this.reward = {
                iconSource,
                text: this.getRewardText()
            };
        }
    }

    emitCardClicked(): void {
        this.palCardClicked.emit(this.card);
    }
}
