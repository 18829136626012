import { Pipe, PipeTransform } from '@angular/core';

import { Language } from '../models/enums';

@Pipe({
    name: 'localizeNumber',
    pure: true
})
export class LocalizeNumberPipe implements PipeTransform {

    transform(value: number, language: Language): string {
        return value.toLocaleString(language.toString().split('_')[0]);
    }
}
