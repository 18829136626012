import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncateHtml' })
export class TruncateHtmlPipe implements PipeTransform {
    transform(text: string, maxLength: number, indicator: string = '\u2026'): string {
        let html = '';
        const stack = [];
        const voidTags = ['br', 'hr', 'img'];
        const parts = text.split(/(<([/a-zA-Z0-9-]+)[^>]*>)/);
        for (let index = 0; index < parts.length; index += 3) {
            const part = parts[index];
            if ((html + part).length > maxLength) {
                html += part.substring(0, maxLength - html.length) + indicator;
                break;
            }
            html += part;
            if (index + 2 < parts.length) {
                html += parts[index + 1];
                const tag = parts[index + 2];
                if (tag.startsWith('/')) {
                    stack.pop();
                } else if (!voidTags.includes(tag.toLowerCase())) {
                    stack.push(tag);
                }
            }
        }
        const stackReverse = stack.reverse();
        stackReverse.forEach((tag) => html += `</${tag}>`);
        return html;
    }
}
