<p 
    class="text-neutral body-regular my-xs"
    id="goal-content"
    aria-hidden="true"
>
    {{ content }}
</p>
<div
    id="goal-details"
    aria-hidden="true"
    class="coaching-details text-neutral p-md mt-lg"
>
    <div
        class="number-of-goals h5"
        id="number-of-goals"
        aria-hidden="true"
    >{{ goal.title }}</div>
    <div
        class="left-to-complete body-regular"
        id="left-to-complete"
        aria-hidden="true"
    >{{ goal.content }}</div>
    <img
        class="icon"
        vpgImage
        type="avatar"
        size="small"
        [src]="goal.icon"
        alt=""
    />
</div>
<div class="sr-only" aria-labelledby="goal-content number-of-goals left-to-complete"></div>
