import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, ReplaySubject } from 'rxjs';

import { MemberTimezoneService } from './member-timezone.service';
import { PreviewToolService } from './preview-tool.service';
import { AuthenticatedMemberService } from '../authentication';
import { DateUtils } from '../lib/date-utils';
import { RewardType } from '../models';
import { GlobalReduxStore, UpdateMemberRewardsOverview } from '../state-management';


@Injectable({
    providedIn: 'root'
})
export class GenesisRewardsProgressService {
    rewardsOverview: any;
    rewardsSummary: any[] = [];
    rewardsSummaryLoaded = false;
    loadInProgress = false;
    gameCapStatus: any;
    uuid: any;
    rewardsProgressBarSubject = new ReplaySubject<any>();

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private httpClient: HttpClient,
        private globalReduxStore: GlobalReduxStore,
        private memberTimezoneService: MemberTimezoneService,
        private previewToolService: PreviewToolService
    ) {}

    loadRewardsProgressBar(shouldReload: boolean, pusherUuid: any) {
        if ((this.loadInProgress || this.rewardsSummaryLoaded) && !shouldReload) {
            return this.rewardsProgressBarSubject;
        }

        if (pusherUuid !== null && pusherUuid === this.uuid) {
            return this.rewardsProgressBarSubject;
        }

        if (shouldReload && pusherUuid) {
            this.uuid = pusherUuid;
        }

        this.loadInProgress = true;

        const member = this.authenticatedMemberService.getMember();

        const previewToolServiceQuery = this.previewToolService.getQueryParameterObject({
            sponsorId: member.sponsorId,
            memberId: member.id,
            memberFeatures: this.authenticatedMemberService.getRewardableFeatures()
        });
        const params = { memberFeatures : previewToolServiceQuery.memberFeatures };

        const rewardsProgressBarResource = `/api/genesis-rewards/sponsors/${previewToolServiceQuery.sponsorId}/members/${previewToolServiceQuery.memberId}/summary`;
        const rewardsSummaryResource = `/api/genesis-rewards/sponsors/${previewToolServiceQuery.sponsorId}/members/${previewToolServiceQuery.memberId}/statements/rewards-summary`;

        forkJoin({
            rewardsSummaryData:
                this.httpClient.get<any[]>(rewardsSummaryResource, { params }),
            rewardsOverviewData:
                this.httpClient.get<any>(rewardsProgressBarResource, { params })
        }).subscribe((data: any) => {
            this.gameCapStatus = null;
            this.rewardsSummaryLoaded = true;

            this.rewardsOverview = data.rewardsOverviewData;
            this.rewardsSummary = data.rewardsSummaryData;

            const rewardsOverviewHasLevels = this.rewardsOverview.levels.length ? true : false;

            this.rewardsOverview.wallet = this.rewardsSummary;
            this.rewardsOverview.maxPoints
            = rewardsOverviewHasLevels
                    ? this.rewardsOverview.levels[this.rewardsOverview.levels.length - 1].threshold
                    : 0;
            this.rewardsOverview.currentPoints = this.getTotalAmountOfPoints(this.rewardsSummary);
            const today = DateUtils.format(
                this.memberTimezoneService.getMemberDate(this.authenticatedMemberService.getMemberTimezone()),
                'YYYY-MM-DD'
            );
            this.rewardsOverview.daysRemaining
                    = this.rewardsOverview.game !== null
                    ? DateUtils.diff(this.rewardsOverview.game.endDate, today, 'days') + 1 : 0;
            this.rewardsOverview.progressBar = rewardsOverviewHasLevels ? this.calculateCurrentPoints(this.rewardsOverview) : 0;
            this.gameCapStatus = this.rewardsSummary.filter((rewardSummary) => rewardSummary.rewardCapSet === true);
            this.rewardsOverview.gameCapStatus = this.gameCapStatus.length > 0 ? this.gameCapStatus[0] : null;
            this.rewardsOverview.levels.forEach((level: any) => {
                level.rewards = level.rewards.filter((reward: any) => reward.rewardType !== 'NoReward');
            });

            this.globalReduxStore.dispatchAction(UpdateMemberRewardsOverview(this.rewardsOverview));
            this.rewardsProgressBarSubject.next(this.rewardsOverview);
            this.loadInProgress = false;
        }, (error) => {
            this.loadInProgress = false;
            this.rewardsProgressBarSubject.error(error);
        });

        return this.rewardsProgressBarSubject;
    }

    getTotalAmountOfPoints(wallet: any) {
        let totalPoints = 0;
        wallet.forEach((reward: any) => {
            if (reward.rewardType === RewardType.Points) {
                totalPoints += reward.earnedValue;
            }
        });
        return totalPoints;
    }

    calculateCurrentPoints(rewardsOverview: any) {
        let currentLevel: number;
        let completionPercentage: number;
        let milestoneDistance: number;
        let previousThreshold = 0;
        const MAX_PERCENTAGE = 100;
        const percentPerMilestone = MAX_PERCENTAGE / rewardsOverview.levels.length;

        if (rewardsOverview.currentPoints >= rewardsOverview.maxPoints) {
            completionPercentage = MAX_PERCENTAGE;
            currentLevel = rewardsOverview.levels.length;
            return {
                level: currentLevel,
                progress: completionPercentage,
                percentPerLevel: percentPerMilestone
            };
        }

        currentLevel = rewardsOverview.levels.findIndex((level: any) => level.threshold > rewardsOverview.currentPoints);

        if (currentLevel > 0) {
            previousThreshold = rewardsOverview.levels[currentLevel - 1].threshold;
        }

        milestoneDistance = rewardsOverview.levels[currentLevel].threshold - previousThreshold;
        if (milestoneDistance === 0) {
            milestoneDistance = 1;
        }
        const completedMilestoneDistance = rewardsOverview.currentPoints - previousThreshold;
        const currentMilestoneCompletionPercentage = completedMilestoneDistance / milestoneDistance;

        completionPercentage = percentPerMilestone * (currentLevel + currentMilestoneCompletionPercentage);

        return {
            level: currentLevel,
            progress: completionPercentage,
            percentPerLevel: percentPerMilestone
        };
    }

    getSpouseRewardsSummary() {
        const member = this.authenticatedMemberService.getMember();

        return this.httpClient.get<any[]>(
            `api/genesis-rewards/sponsors/${member.sponsorId}/members/${member.id}/statements/spouse-rewards-summary`
        );
    }
}
