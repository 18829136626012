<div id="member-agreement-modal"
     class="member-agreement-modal"
     role="dialog"
     aria-modal="true"
     [attr.aria-labelledby]="'member-agreement-modal-title'"
     modalTrapFocus>
    <div class="member-agreement-modal-header">
        <h1 id="member-agreement-modal-title"
            class="member-agreement-title">
            {{ agreement.title }}
        </h1>
        <div class="close-btn">
            <button
                id="privacy-policy-close-btn-first"
                #closeButtonFirst
                type="button"
                class="close"
                tabindex="0"
                [attr.aria-label]="'Close' | translate"
                (keydown)="manageFocusCloseButtons($event)"
                [wcagClick]="closeModal.bind(this)">
                &times;
            </button>
        </div>
    </div>
    <div class="member-agreement-modal-body">
        <div class="agreement-summary"
             [innerHTML]="agreement.summary">
        </div>
        <div class="agreement-content"
             [innerHTML]="agreement.content">
        </div>
    </div>
    <div class="member-agreement-modal-footer">
        <button
            class="vp-button vp-button-primary member-agreement-close-btn"
            id="privacy-policy-close-btn"
            #closeButton
            [translate]="'Close'"
            [attr.aria-label]="'Close' | translate"
            (keydown)="manageFocusCloseButtons($event)"
            [wcagClick]="closeModal.bind(this)">
        </button>
    </div>
</div>
