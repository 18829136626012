import { environment } from '@environments/environment';

window.NREUM = window.NREUM || {};

window.NREUM.loader_config = {
    accountID: environment.newrelic.accountID,
    trustKey: '310548',
    agentID: environment.newrelic.agentID,
    licenseKey: environment.newrelic.licenseKey,
    applicationID: environment.newrelic.applicationID
};

window.NREUM.info = {
    beacon: 'bam-cell.nr-data.net',
    errorBeacon: 'bam-cell.nr-data.net',
    licenseKey: environment.newrelic.licenseKey,
    applicationID: environment.newrelic.applicationID,
    sa: 1
};
