import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'calculatePercentage' })
export class CalculatePercentagePipe implements PipeTransform {

    transform(actualNumber: number, totalNumber: number, digitNumber: number): any {
        const result = (actualNumber / totalNumber) * 100;
        if (isNaN(result)) {
            return 0;
        }
        return result.toFixed(digitNumber);
    }
}
