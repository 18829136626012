import { Component, Input, OnInit } from '@angular/core';

import { JourneysRetirementService } from '@genesis-frontend/genesis-utilities';


@Component({
    selector: 'journey-retired-label',
    styleUrls: ['journey-retirement-label.component.scss'],
    templateUrl: 'journey-retirement-label.component.html'
})
export class JourneyRetirementLabelComponent implements OnInit {
  @Input() unpublishedDate?: string;
  daysToRetirement?: number;
  constructor(private journeyRetirementService: JourneysRetirementService) {

  }

  ngOnInit(): void {
      this.daysToRetirement = this.journeyRetirementService.calculateDaysToRetirement(this.unpublishedDate);
  }
}
