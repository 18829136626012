import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AppHistoryService {
    private HOME_PATH = '/home';
    private appHistory = [this.HOME_PATH];

    getLocations() {
        return this.appHistory;
    }

    addLocation(path: any) {
        this.appHistory.push(path);
    }

    goToPreSurveyLocation() {
        let fullPath: any;
        while (this.appHistory.length > 0) {
            fullPath = this.appHistory.pop();
            if (fullPath.includes('survey') || fullPath.includes('/surveys-ui/')) {
                window.location.hash = fullPath;
                return;
            }
        }
        window.location.hash = this.HOME_PATH;
    }

    goToPreChallengeInterruptLocation() {
        let fullPath: any;
        while (this.appHistory.length) {
            fullPath = this.appHistory.pop();
            if (fullPath.includes('challenges')) {
                window.location.hash = fullPath;
                return;
            }
        }
        window.location.hash = this.HOME_PATH;
    }
}
