import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';

import { Operator, OperatorType, FeatureEnum } from '../models';
import { FeatureService } from '../services';

/**
 * A structural directive that shows or hides its content based on the feature flags.
 *
 * The feature directive takes a single input `feature` which can be either a single feature flag or an array of feature flags.
 * If the feature flag is an array, it checks if all the flags in the array are enabled based on the `featureOperator` which can be either 'or' or 'and' (default).
 *
 * If the feature flags are enabled, the directive renders the template, otherwise it renders the `featureElse` template if provided, otherwise it clears the view container.
 *
 * @example
 * <div *feature="'flag1'">Content</div>
 * <!-- Shows the content if flag1 is enabled -->
 *
 * <div *feature="['flag1', 'flag2']">Content</div>
 * <!-- Shows the content if both flag1 and flag2 are enabled -->
 *
 * <div *feature="['flag1', 'flag2']" [featureOperator]="'or'">Content</div>
 * <!-- Shows the content if either flag1 or flag2 is enabled -->
 *
 * <div *feature="['flag1', 'flag2']"; operator: 'or'">Content</div>
 * <!-- Shorter version of the above - Recommended -->
 *
 * <div *feature="'flag1'" [featureElse]="fallback">Content</div>
 * <ng-template #fallback>Else content</ng-template>
 * <!-- Shows the else content if flag1 is not enabled -->
 *
 * <div *feature="'flag1'; else fallback">Content</div>
 * <ng-template #fallback>Else content</ng-template>
 * <!-- Shorter version of the above - Recommended -->
 *
 * <div *feature="['flag1', 'flag2']; operator: 'or'; else fallback">Content</div>
 * <ng-template #fallback>Else content</ng-template>
 * <!-- Version with all inputs provided -->
 *
 * @param feature - A single feature flag or an array of feature flags.
 * @param featureOperator - The operator to check if all the flags in the array are enabled, can be either 'or' or 'and'.
 * @param featureElse - An optional template to render if the feature flags are not enabled.
 */
@Directive({
    selector: '[feature]'
})
export class FeatureFlagDirective implements OnInit {
    @Input() feature: FeatureEnum | FeatureEnum[] = [];
    @Input() featureElse?: TemplateRef<HTMLElement> = undefined;
    @Input() featureOperator: OperatorType = Operator.AND;

    constructor(
        private templateRef: TemplateRef<HTMLElement>,
        private viewContainerRef: ViewContainerRef,
        private featureFlagService: FeatureService
    ) {}

    ngOnInit(): void {
        this.renderTemplate();
    }

    private renderTemplate(): void {
        const features = Array.isArray(this.feature)
            ? this.feature
            : [this.feature];

        if (this.hasFeatures(features)) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else if (this.featureElse) {
            this.viewContainerRef.createEmbeddedView(this.featureElse);
        } else {
            this.viewContainerRef.clear();
        }
    }

    private hasFeatures(features: FeatureEnum[]): boolean {
        return this.featureOperator === Operator.OR
            ? this.featureFlagService.hasSomeOfFeatures(features)
            : this.featureFlagService.hasEveryFeature(features);
    }
}

