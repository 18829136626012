import { APP_INITIALIZER, Injectable, ModuleWithProviders, NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule, TranslateService, TranslateParser, MissingTranslationHandlerParams, TranslateModuleConfig } from '@ngx-translate/core';
import { TranslateICUParser } from 'ngx-translate-parser-plural-select';
import { take } from 'rxjs/operators';

import { TRANSLATIONS_ADMIN } from './tokens';
import { TranslateLoaderService } from './translate-loader.service';
import { AuthenticatedMemberService } from '../authentication';
import { ShellApplication } from '../core/shell-application';
import { DateUtils } from '../lib/date-utils';
import { SingleSpaProps, singleSpaPropsSubject } from '../models';
import { GlobalReduxStore } from '../state-management';


const translateModuleConfig: TranslateModuleConfig = {
    parser: {
        provide: TranslateParser,
        useClass: TranslateICUParser
    },
    loader: {
        provide: TranslateLoader,
        useClass: TranslateLoaderService,
        deps: [TRANSLATIONS_ADMIN]
    },
    useDefaultLang: true,
    defaultLanguage: 'en-US'
};

export interface Localization {
    language: string;
    timeZone?: string;
}

export abstract class LocalizationProvider {
    abstract getLocalization(): Promise<Localization>;
}

@Injectable()
export class AuthenticatedMemberLocalizationProvider extends LocalizationProvider {

    constructor(
        private memberService: AuthenticatedMemberService,
        private store: GlobalReduxStore,
        private shellApplication: ShellApplication
    ) {
        super();
    }

    getLocalization(): Promise<Localization> {
        const hasShellUser = this.shellApplication.authenticatedMember || this.shellApplication.authenticatedUser;

        return new Promise<Localization>((resolve) => {
            if (hasShellUser) {
                const isDebugModeActive = false;
                this.store.initMFReduxStore(this.shellApplication.shellAppName, isDebugModeActive);

                if (this.shellApplication.authenticatedUser) {
                    resolve({
                        language: 'en-US',
                        timeZone: undefined
                    });
                    return;
                }

                this.memberService.authenticate().pipe(take(1)).subscribe((props) => {

                    let language = 'en-US';
                    let timezone = null;

                    try {
                        language = props.authenticatedMember.getMemberLanguageCode();
                        timezone = this.memberService.getMemberTimezone();
                    } catch (e) {
                        language = 'en-US';
                        timezone = null;
                    }

                    resolve({
                        language,
                        timeZone: timezone?.javaTimezone
                    });
                });
            } else {
                singleSpaPropsSubject.pipe(
                    take(1)
                ).subscribe((singleSpaProps: SingleSpaProps) => {
                    const isDebugModeActive = false;
                    this.store.initMFReduxStore(singleSpaProps.shellAppName, isDebugModeActive);

                    this.memberService.authenticate().pipe(take(1)).subscribe((props) => {
                        const language = props.authenticatedMember.getMemberLanguageCode();
                        const timeZone = this.memberService.getMemberTimezone();
                        resolve({
                            language,
                            timeZone: timeZone.javaTimezone
                        });

                    });
                });
            }
        });
    }
}

@Injectable()
export class CustomTranslateService extends TranslateService {
    public getParsedResult(translations: any, key: any, interpolateParams?: any): any {

        let res = super.getParsedResult(translations, key, interpolateParams);

        if ((typeof res === 'undefined' || res === null || res === '') &&
            this.defaultLang != null &&
            this.defaultLang !== this.currentLang &&
            translateModuleConfig.useDefaultLang) {
            res = this.parser.interpolate(this.parser.getValue(this.translations[this.defaultLang], key), interpolateParams);
        }

        if (typeof res === 'undefined' || res === null || res === '') {
            const params: MissingTranslationHandlerParams = { key, translateService: this };
            if (typeof interpolateParams !== 'undefined') {
                params.interpolateParams = interpolateParams;
            }
            res = this.missingTranslationHandler.handle(params);
        }

        return res;
    }
}


@Injectable()
export class LocalizationService {

    static DefaultLocalizationKey = 'defaultLocalization';
    static DefaultLocalization: Localization = {
        language: 'en-US',
        timeZone: ''
    };

    constructor(
        private translate: TranslateService,
        private memberService: AuthenticatedMemberService
    ) { }

    saveLocalization(localization: Localization): void {
        localStorage.setItem(LocalizationService.DefaultLocalizationKey, JSON.stringify(localization));
    }

    getDefaultLocalization(): Localization {
        const value = localStorage.getItem(LocalizationService.DefaultLocalizationKey);

        try {
            if (value) {
                const result = JSON.parse(value);
                if (result && result.language) {
                    return result;
                }
            }
        } catch {
            return LocalizationService.DefaultLocalization;
        }
        return LocalizationService.DefaultLocalization;
    }

    setLocalization(localization: Localization) {
        return new Promise<void>((resolve) => {
            DateUtils.setGlobalLocale(localization.language);
            if (localization.timeZone) {
                DateUtils.setDefaultTimeZone(localization.timeZone);
            }

            this.translate.use(localization.language).subscribe(() => {
                resolve();
            });
        });
    }

    getMemberLocalization() {

        if (this.memberService.hasMember()) {
            const language = this.memberService.getMemberLanguageCode();
            const timeZone = this.memberService.getMemberTimezone();
            const result = {
                language,
                timeZone: timeZone.javaTimezone
            };
            return result;
        }

        return this.getDefaultLocalization();
    }
}

@Injectable()
export class DefaultLocalizationProvider extends LocalizationProvider {

    constructor(
        private localizationService: LocalizationService,
        private store: GlobalReduxStore,
        private shellApplication: ShellApplication
    ) {
        super();
    }

    getLocalization(): Promise<Localization> {
        const hasShellUser = this.shellApplication.authenticatedMember || this.shellApplication.authenticatedUser;
        return new Promise<Localization>((resolve) => {
            if (hasShellUser) {
                const isDebugModeActive = false;
                this.store.initMFReduxStore(this.shellApplication.shellAppName, isDebugModeActive);
                const localization = this.localizationService.getDefaultLocalization();
                resolve(localization);
            } else {
                singleSpaPropsSubject.pipe(
                    take(1)
                ).subscribe((singleSpaProps: SingleSpaProps) => {
                    const isDebugModeActive = false;
                    this.store.initMFReduxStore(singleSpaProps.shellAppName, isDebugModeActive);
                    const localization = this.localizationService.getDefaultLocalization();
                    resolve(localization);
                });
            }
        });
    }
}

@NgModule({
    imports: [
        TranslateModule.forRoot(translateModuleConfig)
    ],
    exports: [
        TranslateModule
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initialize,
            deps: [LocalizationProvider, LocalizationService],
            multi: true
        },
        { provide: LocalizationProvider, useClass: AuthenticatedMemberLocalizationProvider },
        { provide: TranslateService, useClass: CustomTranslateService },
        { provide: TRANSLATIONS_ADMIN, useValue: false },
        LocalizationService
    ]
})
export class TranslateLoaderModule {
    public static forChild(): ModuleWithProviders<TranslateModule> {

        const module = TranslateModule.forChild(translateModuleConfig);

        return {
            ngModule: TranslateModule,
            providers: [
                ...(module.providers || []),
                { provide: TranslateService, useClass: CustomTranslateService }
            ]
        };
    }

    public static forAdminChild(): ModuleWithProviders<TranslateModule> {
        const module = TranslateModule.forChild(translateModuleConfig);

        return {
            ngModule: TranslateModule,
            providers: [
                ...(module.providers || []),
                { provide: LocalizationProvider, useClass: DefaultLocalizationProvider },
                { provide: TranslateService, useClass: CustomTranslateService },
                { provide: TRANSLATIONS_ADMIN, useValue: true }
            ]
        };
    }

    public static forAdmin(): ModuleWithProviders<TranslateModule> {
        const module = TranslateModule.forRoot(translateModuleConfig);

        return {
            ngModule: TranslateModule,
            providers: [
                ...(module.providers || []),
                { provide: LocalizationProvider, useClass: DefaultLocalizationProvider },
                { provide: TranslateService, useClass: CustomTranslateService },
                { provide: TRANSLATIONS_ADMIN, useValue: true }
            ]
        };
    }

    public static forNonAuthenticated(): ModuleWithProviders<TranslateModule> {
        const module = TranslateModule.forChild(translateModuleConfig);

        return {
            ngModule: TranslateModule,
            providers: [
                ...(module.providers || []),
                { provide: TranslateService, useClass: CustomTranslateService },
                { provide: LocalizationProvider, useClass: DefaultLocalizationProvider }
            ]
        };
    }
}

export function initialize(
    localizationProvider: LocalizationProvider,
    localizationService: LocalizationService
) {
    return () => {
        return new Promise<void>((resolve) => {
            localizationProvider.getLocalization().then((localization) => {
                localizationService.setLocalization(localization).then(() => {
                    resolve();
                });
            });
        });
    };
}
