import { AfterViewInit, Component, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input-gg';
import { Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { TransformService } from '@genesis-frontend/genesis-data-access';
import {
    AnalyticsTrackingAction,
    AnalyticsTrackingSingleSpaService,
    AppointmentSchedulingService,
    AppointmentService,
    AppointmentSlotVM,
    AuthenticatedMemberService,
    BookingPageType,
    CoachingAppointmentConfirmedEvent,
    CoachingAppointmentVM,
    CoachingScheduleAppointmentViewedEvent,
    EngagementVM,
    FeatureEnum,
    FeatureToggleService,
    groupByCustom,
    LiveServicesCoachingService,
    LiveServicesRoutes,
    OnsiteLocation,
    ProgramMember,
    ScheduleAppointmentRequestVM,
    SingleTopicType,
    stringToLocalDate,
    TimeZoneUtil,
    TopicServiceVM,
    RescheduleAppointmentPayload,
    RescheduleAppointmentRequestVM,
    DateUtils,
    FeatureService
} from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'book-appointment-component',
    templateUrl: './book-appointment.template.html',
    styleUrls: ['./book-appointment.component.scss']
})
export class BookAppointmentComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() topicName = '';
    @Input() data: any;
    @Input() bookingType = BookingPageType.LiveServices;
    topicService = {} as TopicServiceVM;
    private static PNF = PhoneNumberFormat;

    memberEngagement = {} as EngagementVM;
    programData = {} as ProgramMember;

    appointments: CoachingAppointmentVM[] = [];
    topicId = 0;

    coachId: number | undefined;

    singleTopicType = SingleTopicType;
    liveServicesRoutes = LiveServicesRoutes;
    hasCompletedAppointments = false;
    member = this.authenticatedMemberService.getAuthenticatedMember();

    SearchCountryField = SearchCountryField;

    dateTimeslotsMap = new Map();
    cellPhoneError?: string;
    cellPhoneNumber?: string;
    isPhoneNumberValid = false;
    bookingError = false;
    appointmentTakenError = false;
    emptyFieldError = false;
    isActiveInCoaching = false;
    isPackageTopic = false;
    isCoronavirusChecked = false;
    isOnsiteTopic = false;
    countryCode = CountryISO.UnitedStates;
    locations: OnsiteLocation[] = [];
    selectedLocation: OnsiteLocation | undefined;
    groupedLocations = new Map<string, OnsiteLocation[]>();
    noLocations = false;
    preferredCountries = [CountryISO.UnitedStates];
    selectedDate = new Date();
    selectedTimeslot: AppointmentSlotVM | undefined;
    timeslotsForSelectedDate = new Array<AppointmentSlotVM>();
    timeslotsForDisplay = new Array<AppointmentSlotVM>();
    minDate = new Date();
    maxDate = new Date();
    disabledDates: Date[] = [];
    phoneNumber = {
        number: '',
        internationalNumber: '',
        nationalNumber: '',
        countryCode: '',
        dialCode: ''
    };
    confirmAppointmentButtonText = 'Confirm Appointment';
    confirmAppointmentButtonId = 'scheduler-confirm-session-button';
    schedulingTitleId = 'scheduler-schedule-appointment-title';
    showRescheduleConfirmationModal = false;

    phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
    phoneForm = new UntypedFormGroup({
        phone: new UntypedFormControl(undefined, [Validators.required])
    });

    TIMESLOTS_TO_DISPLAY = 7;
    ONE = 1;
    TWO = 2;
    ZERO = 0;
    CONFLICT_RESPONSE_CODE = 409;
    MONTHS_TO_MAX_DATE = 3;
    MAX_ATTEMPTS = 3;
    WEEK_PERIOD_ISO_STRING = 'P6DT23H59M59.999S';

    timeslotDisplayStartIndex = this.ZERO;
    timeslotDisplayEndIndex = this.ZERO;

    loadingAppointmentData = false;

    mixpanelDateClickCount = 0;
    previousSelectedDate: Date | undefined;

    bsDatePickerSubtreeDomObserver: MutationObserver | undefined;

    isLoading = true;
    isLoadingDates = false;
    isLoadingDailySlots = false;
    isReloadingChips = false;
    isLiveServices = true;
    initialMoment = this.timeZoneUtil.now();
    lastRetrievedDate = new Date();
    periodsAdded = 0;
    currentMonthPage = 0;
    loadedMonthPage = 0;
    months: Record<string, string> = {
        'January' : '0',
        'February' : '1',
        'March' : '2',
        'April' : '3',
        'May' : '4',
        'June' : '5',
        'July' : '6',
        'August' : '7',
        'September' : '8',
        'October' : '9',
        'November' : '10',
        'December' : '11'
    };

    constants = {
        PENDING_PATH: 'pending',
        ENGAGED_STATUS: 'Engaged'
    };

    rescheduleId: number | undefined;
    rescheduleDuration: string | undefined;
    routeTopicName: string | undefined;
    isCoachInactive: boolean | undefined;
    timeoutId: null | ReturnType<typeof setTimeout> = null;
    TEN_SECONDS = 10000;
    FIVE_SECONDS = 5000;
    readonly ENROLLED_PENDING_START_DATE: string = 'ENROLLED_PENDING_START_DATE';

    newAppointmentStartDate = new Date();
    newAppointmentEndDate = new Date();

    confirmedAppointmentSub = new Subscription();

    shouldUseMonthlyAvailability = false;
    onGetSlotsSuccessCallback = this.onGetSlotsSuccess.bind(this);
    onGetSingleDaySlotsSuccessCallback = this.onGetSingleDaySlotsSuccess.bind(this);

    constructor(
        private analyticsTrackingService: AnalyticsTrackingSingleSpaService,
        private appointmentService: AppointmentService,
        private authenticatedMemberService: AuthenticatedMemberService,
        public timeZoneUtil: TimeZoneUtil,
        private liveServicesCoachingService: LiveServicesCoachingService,
        private renderer2: Renderer2,
        private featureService: FeatureService,
        private featureToggleService: FeatureToggleService,
        private router: Router,
        private appointmentSchedulingService: AppointmentSchedulingService,
        private transformService: TransformService
    ) {
    }

    ngOnInit() {
        if (this.bookingType === BookingPageType.Transform) {
            this.isLiveServices = false;
            this.initTransformBooking();
        } else {
            this.initLSBooking();
        }
    }

    initLSBooking() {
        this.routeTopicName = this.topicName;
        this.isCoachInactive = this.data['isCoachInactive'];
        this.memberEngagement = this.data['memberEngagement'];
        this.topicService = this.data['topicService'];
        this.rescheduleId = this.data['rescheduleId'];
        this.rescheduleDuration = this.data['rescheduleDuration'];

        this.confirmAppointmentButtonText = this.rescheduleId ? 'Reschedule Session' : 'Confirm Appointment';
        this.confirmAppointmentButtonId = 'scheduler-reschedule-session-button';
        this.schedulingTitleId = this.rescheduleId ? 'scheduler-reschedule-session-title' : 'scheduler-schedule-appointment-title';

        this.setTopicDetails();
        this.verifyBookingEligibility();

        this.sendCoachingScheduleAppointmentViewedEvent();
    }

    initTransformBooking() {
        this.programData = this.data['programMemberData'];

        if (!this.programData) {
            this.goToActivityPage();
        }

        this.initialMoment = this.timeZoneUtil.now();

        this.loadCoachId();
    }

    ngAfterViewInit() {
        this.waitForCalendar().then(() => {
            this.watchDatePickerMonthChange();
        });
        this.setMemberPhone();
    }

    ngOnDestroy() {
        this.bsDatePickerSubtreeDomObserver?.disconnect();
        this.confirmedAppointmentSub.unsubscribe();
    }

    initializeCalendar(startMoment: Date) {
        if (this.isOnsiteTopic) {
            this.isLoadingDates = false;
            this.isLoadingDailySlots = false;
        } else if (this.shouldUseMonthlyAvailability) {
            this.loadMonthlyAvailability(startMoment);
        } else {
            this.getAppointmentSlots(startMoment);
        }
    }

    loadCoachId() {
        if (this.programData?.coachId) {
            this.liveServicesCoachingService.getCoachIdFromSFUserId(this.programData.coachId).subscribe((clinicalTeamMember) => {
                this.coachId = clinicalTeamMember.id;

                this.loadComponentState();
            }, () => {
                this.goToActivityPage();
            });
        } else {
            this.loadComponentState();
        }
    }

    loadComponentState() {
        if (this.bookingType === BookingPageType.LiveServices) {
            this.isActiveInCoaching = this.memberEngagement.isActiveInCoaching;
            this.isPackageTopic = this.topicService.isPackageTopic;
            this.coachId = this.topicService.coachId;

            if (this.topicService.topicInternalName === SingleTopicType.Onsite) {
                this.isOnsiteTopic = true;
                this.getMemberLocations();
            }

            if (this.topicService.topicInternalName === SingleTopicType.TobaccoFree && this.isPackageTopic === false) {
                this.appointmentService.getCompletedAppointments(SingleTopicType.TobaccoFree, this.member)
                    .subscribe((coachingAppointmentList) => {
                        this.hasCompletedAppointments = coachingAppointmentList.length > 0;
                    });
            }
        }

        this.topicId = this.topicService ? this.topicService.topicId : 0;

        this.shouldUseMonthlyAvailability = !(this.coachId || this.isOnsiteTopic)
            && this.featureToggleService.hasFeatureToggle('live-services-monthly-availability');

        this.isLoading = false;

        this.initializeCalendar(this.initialMoment);

        this.minDate = new Date();
        this.selectedDate = new Date();
        const maxDateMoment = DateUtils.add(this.initialMoment, this.MONTHS_TO_MAX_DATE, 'months');
        this.maxDate = maxDateMoment;
        this.disableDates(this.initialMoment, maxDateMoment);
    }

    loadMonthlyAvailability(monthMoment: Date, isMonthChange = false) {
        this.isLoadingDates = true;
        const monthNumber = Number(DateUtils.format(DateUtils.toUtcDate(monthMoment), 'MM'));
        const transformProgram = this.programData ? this.programData.programType : null;
        const programMemberId = this.programData ? this.programData.id : null;
        this.appointmentService.getMonthlyAvailability(this.topicId, monthNumber, DateUtils.yearOf(DateUtils.toUtcDate(monthMoment)), transformProgram, programMemberId).subscribe((response) => {
            const availableDates = [...response].filter(([, value]) => value).map(([key]) => stringToLocalDate(key));
            this.updateMonth(isMonthChange);
            this.enableDates(availableDates, false);
            const minDate = availableDates.length ? availableDates.reduce((a, b) => a < b ? a : b) : undefined;
            this.selectedDate = minDate ? minDate : this.selectedDate;
            this.isLoadingDates = false;
        });
    }

    waitForCalendar() {
        return new Promise((resolve) => {
            if (document.querySelector('#bsDatePicker')) {
                return resolve(document.querySelector('#bsDatePicker'));
            }

            const observer = new MutationObserver(() => {
                if (document.querySelector('#bsDatePicker')) {
                    resolve(document.querySelector('#bsDatePicker'));
                    observer.disconnect();
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }

    watchDatePickerMonthChange() {
        const node = document.querySelector('#bsDatePicker');
        if (!node) {
            return;
        }
        this.bsDatePickerSubtreeDomObserver = new MutationObserver((mutations) => {
            const previousOrNextMonthMutation = mutations.some((mutation) => ['previous', 'next'].includes((mutation.target as Element)['className']));
            if (previousOrNextMonthMutation) {
                this.bsDatePickerSubtreeDomObserver?.disconnect();
                this.addAriaLabelToCalendarArrowControls();
                this.bsDatePickerSubtreeDomObserver?.observe(node, { attributes: true, subtree: true });
            }
            this.bsDatePickerSubtreeDomObserver?.observe(node, { attributes: true, subtree: true });
        });
        this.bsDatePickerSubtreeDomObserver.observe(node, { attributes: true, subtree: true });
    }

    addAriaLabelToCalendarArrowControls() {
        const previous = document.querySelector('#bsDatePicker .previous');
        const next = document.querySelector('#bsDatePicker .next');
        this.renderer2.setAttribute(previous, 'id', 'scheduler-date-picker-back-button');
        this.renderer2.setAttribute(previous, 'aria-label', 'Previous month');
        this.renderer2.setAttribute(next, 'id', 'scheduler-date-picker-next-button');
        this.renderer2.setAttribute(next, 'aria-label', 'Next month');

        if (next) {
            next.addEventListener('click', this.nextMonth.bind(this));
        }
        if (previous) {
            previous.addEventListener('click', this.previousMonth.bind(this));
        }
    }

    nextMonth() {
        this.currentMonthPage++;
        if (this.currentMonthPage > this.loadedMonthPage) {
            this.loadedMonthPage++;
            if (this.shouldUseMonthlyAvailability) {
                this.loadMonthlyAvailability(DateUtils.add(this.initialMoment, this.loadedMonthPage, 'months'), true);
            } else {
                this.getMonthOfAppointmentSlots();
            }
        } else {
            this.onMonthChange();
        }
    }

    previousMonth() {
        this.currentMonthPage--;
        this.onMonthChange();
    }

    updateMonth(isMonthChange: boolean) {
        if (isMonthChange) {
            this.onMonthChange();
        }
    }

    onMonthChange() {
        const currentElements = document.querySelectorAll('#bsDatePicker .current');
        if (currentElements[0]?.firstChild?.textContent && currentElements[1]?.firstChild?.textContent) {
            if (this.periodsAdded > 1) {
                this.periodsAdded--;
                return;
            }

            this.periodsAdded--;

            const month = this.months[currentElements[0].firstChild.textContent];

            const year = currentElements[1].firstChild.textContent;

            const date = new Date(Number(year), Number(month), 1);
            const nextMonth = new Date(Number(year), Number(month) + 1, 1);
            while (date < nextMonth) {
                const openSlots = this.dateTimeslotsMap.get(this.timeZoneUtil.format(date, 'YYYY-MM-DD'));
                if (openSlots !== undefined && openSlots.length) {
                    this.selectedDate = date;
                    break;
                }
                date.setDate(date.getDate() + 1);
            }
            this.periodsAdded--;
            this.isLoadingDates = false;
        }
    }

    verifyBookingEligibility(): void {
        const nextStep = () => this.loadComponentState();

        if (this.topicService.topicId && (this.routeTopicName === this.topicService.topicInternalName)) {
            nextStep();
        } else {
            const packageListNoTransform = this.appointmentService.filterTransformCoachingPackage(this.memberEngagement.allLiveServicesPackageNames);
            this.appointmentService.getAllAppointments(this.member.id, packageListNoTransform).subscribe((appointments) => {
                this.isActiveInCoaching = this.memberEngagement.isActiveInCoaching;

                const upcomingAppointments = this.appointmentService.getUpcomingAppointments(appointments);

                if (upcomingAppointments.length > 0) {
                    this.router.navigate([LiveServicesRoutes.Appointments, upcomingAppointments[0].appointmentId]);
                }

                nextStep();
            }, () => {
                this.goToHome();
            });
        }
    }

    getMemberLocations() {
        this.liveServicesCoachingService.getLocationsForMember(this.member.id).subscribe(
            (response) => {
                if (!response || !response.length) {
                    this.noLocations = true;
                } else {
                    this.locations = response;
                    this.groupedLocations = groupByCustom(this.locations, (location) => location.locationState);
                    const preferredLocation = this.locations.find((location) => location.preferred);

                    if (preferredLocation) {
                        this.selectLocation(preferredLocation);
                    }
                }
            }
        );
    }

    getDailyAppointmentSlots(momentLocal: Date) {
        this.isLoadingDailySlots = true;
        const endOfCurrentDay = DateUtils.endOf(momentLocal, 'day');
        this.getAppointmentSlotsForPeriod(momentLocal, endOfCurrentDay, false, this.onGetSingleDaySlotsSuccessCallback);
    }

    getAppointmentSlots(nowMomentLocal: Date) {
        let weeksAdded = 0;

        const endOfMonth = DateUtils.endOf(nowMomentLocal, 'month');

        const startOfCurrentDay = DateUtils.startOf(nowMomentLocal, 'day');
        const endOfCurrentDay = DateUtils.endOf(nowMomentLocal, 'day');
        this.getAppointmentSlotsForPeriod(nowMomentLocal, endOfCurrentDay);

        const startOfTomorrow = DateUtils.add(startOfCurrentDay, this.ONE, 'days');
        const endOfTomorrow = DateUtils.add(endOfCurrentDay, this.ONE, 'days');
        this.getAppointmentSlotsForPeriod(startOfTomorrow, endOfTomorrow);

        this.lastRetrievedDate = DateUtils.add(startOfCurrentDay, this.ONE, 'days');

        while (this.lastRetrievedDate < endOfMonth) {
            const periodStart = DateUtils.add(
                DateUtils.add(startOfCurrentDay, weeksAdded, 'week'),
                this.TWO,
                'days'
            );
            const periodPlusAWeek = DateUtils.add(periodStart, this.WEEK_PERIOD_ISO_STRING);
            const endDate = DateUtils.min(endOfMonth, periodPlusAWeek);

            this.getAppointmentSlotsForPeriod(periodStart, endDate);
            this.lastRetrievedDate = endDate;

            weeksAdded++;
        }
    }

    getMonthOfAppointmentSlots() {
        const endOfMonth = DateUtils.endOf(
            DateUtils.add(this.lastRetrievedDate, this.ONE, 'days'),
            'month'
        );
        const endDate = DateUtils.min(endOfMonth, this.maxDate);

        this.isLoadingDates = true;
        while (this.lastRetrievedDate < endDate) {
            const periodStart = DateUtils.startOf(
                DateUtils.add(this.lastRetrievedDate, this.ONE, 'days'),
                'day'
            );
            const periodPlusAWeek = DateUtils.add(periodStart, this.WEEK_PERIOD_ISO_STRING);
            const minEndDate = DateUtils.min(periodPlusAWeek, endDate);

            this.getAppointmentSlotsForPeriod(periodStart, minEndDate, true);
            this.periodsAdded++;
            this.lastRetrievedDate = minEndDate;
        }
    }

    getAppointmentSlotsForPeriod(startOfPeriod: Date, endOfPeriod: Date, isMonthChange: boolean = false,
        onGetSlotsCallback: (response: AppointmentSlotVM[], isMonthChange: boolean, startOfPeriod?: Date) => void = this.onGetSlotsSuccessCallback) {
        if (DateUtils.isAfter(startOfPeriod, endOfPeriod)) {
            return;
        }
        const locationSFId = this.selectedLocation ? this.selectedLocation.locationSFId : null;
        const transformProgram = this.programData ? this.programData.programType : null;
        const programMemberId = this.programData ? this.programData.id : null;
        const duration = this.rescheduleDuration ? this.rescheduleDuration : null;
        this.appointmentService.getAvailableTimeslots(this.topicId, this.coachId, startOfPeriod, endOfPeriod, locationSFId, transformProgram, programMemberId, duration).subscribe(
            (response) => {
                onGetSlotsCallback(response, isMonthChange, startOfPeriod);
            },
            () => {
                this.appointmentService.getAvailableTimeslots(this.topicId, this.coachId, startOfPeriod, endOfPeriod, locationSFId, transformProgram, programMemberId, duration)
                    .subscribe(
                        (response) => {
                            onGetSlotsCallback(response, isMonthChange, startOfPeriod);
                        },
                        () => {
                            this.updateMonth(isMonthChange);
                        }
                    );
            }
        );
    }

    onGetSingleDaySlotsSuccess(response: AppointmentSlotVM[], isMonthChange: boolean, startOfPeriod?: Date) {
        if (!response.length) {
            const startTimeMomentAsDate = startOfPeriod ?? new Date();
            const startTimeDateString = this.timeZoneUtil.format(startTimeMomentAsDate, 'YYYY-MM-DD');
            this.dateTimeslotsMap.set(startTimeDateString, []);
        } else {
            this.addTimeslotsToDateTimeslotsMap(response);
        }
        this.updateMonth(isMonthChange);
        if (!isMonthChange) {
            this.selectedDate = new Date(this.selectedDate.getTime());
        }
        this.isLoadingDailySlots = false;
    }

    onGetSlotsSuccess(response: AppointmentSlotVM[], isMonthChange: boolean) {
        this.addTimeslotsToDateTimeslotsMap(response);
        this.updateMonth(isMonthChange);
        const newAvailableDates = response.map((slot) => DateUtils.tz(slot.startTime, DateUtils.guessTimeZone(true)));
        this.enableDates(newAvailableDates, isMonthChange);
    }

    validatePhoneNumber() {
        if (this.phoneNumber && !!this.phoneNumber.nationalNumber && !!this.phoneNumber.countryCode) {
            const number = this.phoneUtil.parseAndKeepRawInput(this.phoneNumber.nationalNumber, this.phoneNumber.countryCode);
            this.isPhoneNumberValid = this.phoneUtil.isValidNumber(number);
            if (!this.isPhoneNumberValid) {
                this.cellPhoneError = 'INVALID_NUMBER';
            } else {
                this.cellPhoneError = '';
                this.formatPhoneInput(number);
            }
        } else {
            this.isPhoneNumberValid = false;
        }
    }

    onDateSelectionChange(value: Date): void {
        if (this.isLoadingDates) {
            return;
        }

        this.selectedTimeslot = undefined;
        this.clearErrors();

        this.selectedDate = value;

        const selectedDateAsString = this.timeZoneUtil.format(value, 'YYYY-MM-DD');
        this.timeslotsForSelectedDate = this.dateTimeslotsMap.get(selectedDateAsString);

        if (!this.previousSelectedDate || this.selectedDate.getDate() !== this.previousSelectedDate.getDate() ||
            this.selectedDate.getMonth() !== this.previousSelectedDate.getMonth()) {
            this.mixpanelDateClickCount++;

            if (this.shouldUseMonthlyAvailability && this.timeslotsForSelectedDate === undefined) {
                const localMoment = DateUtils.startOf(this.timeZoneUtil.dateToTime(value), 'day');
                this.getDailyAppointmentSlots(localMoment);
                this.previousSelectedDate = this.selectedDate;
                return;
            }
        }
        this.previousSelectedDate = this.selectedDate;


        if (this.timeslotsForSelectedDate !== undefined) {
            this.isReloadingChips = true;
            if (this.timeslotsForSelectedDate.length > this.TIMESLOTS_TO_DISPLAY) {
                this.timeslotDisplayStartIndex = this.ZERO;
                this.timeslotDisplayEndIndex = this.timeslotDisplayStartIndex + this.TIMESLOTS_TO_DISPLAY;
                setTimeout(() => {
                    this.parseTimeslotsForDisplay(
                        this.timeslotsForSelectedDate.slice(this.timeslotDisplayStartIndex, this.timeslotDisplayEndIndex));
                });
            } else {
                setTimeout(() => {
                    this.parseTimeslotsForDisplay(this.timeslotsForSelectedDate);
                });
            }
        }
    }

    parseTimeslotsForDisplay(listOfLiveServicesAppointmentSlotVms: AppointmentSlotVM[]) {
        this.timeslotsForDisplay = listOfLiveServicesAppointmentSlotVms;
        this.isReloadingChips = false;
    }

    addTimeslotsToDateTimeslotsMap(arrayOfLiveServicesApptSlotVms: AppointmentSlotVM[]): void {
        for (const liveServicesApptSlotVM of arrayOfLiveServicesApptSlotVms) {
            const startTimeMomentAsDate = liveServicesApptSlotVM.startTime;
            const startTimeDateString = this.timeZoneUtil.format(startTimeMomentAsDate, 'YYYY-MM-DD');
            if (this.dateTimeslotsMap.has(startTimeDateString)) {
                this.dateTimeslotsMap.get(startTimeDateString).push(liveServicesApptSlotVM);
            } else {
                this.dateTimeslotsMap.set(startTimeDateString, new Array(liveServicesApptSlotVM));
            }
        }
    }

    disableDates(start: Date, end: Date): void {
        while (DateUtils.isSameOrBefore(start, end)) {
            this.disabledDates.push(start);
            start = DateUtils.add(start, this.ONE, 'day');
        }
    }

    enableDates(availableDates: Date[], isMonthChange: boolean): void {
        availableDates.forEach((availableDate) => {
            this.disabledDates.forEach((item, index) => {
                if (item.getDate() === availableDate.getDate() &&
                    item.getMonth() === availableDate.getMonth() &&
                    item.getFullYear() === availableDate.getFullYear()) {
                    this.disabledDates.splice(index, 1);
                }
            });
        });

        if (!isMonthChange) {
            this.selectedDate = new Date(this.selectedDate.getTime());
        }
    }

    scrollUpClicked() {
        this.isReloadingChips = true;
        if (this.timeslotDisplayStartIndex >= this.TIMESLOTS_TO_DISPLAY) {
            this.timeslotDisplayStartIndex = this.timeslotDisplayStartIndex - this.TIMESLOTS_TO_DISPLAY;
            this.timeslotDisplayEndIndex = this.timeslotDisplayEndIndex - this.TIMESLOTS_TO_DISPLAY;
        } else {
            this.timeslotDisplayStartIndex = this.ZERO;
            this.timeslotDisplayEndIndex = this.TIMESLOTS_TO_DISPLAY;
        }
        setTimeout(() => {
            this.parseTimeslotsForDisplay(
                this.timeslotsForSelectedDate.slice(this.timeslotDisplayStartIndex, this.timeslotDisplayEndIndex));
        });
    }

    scrollDownClicked() {
        this.isReloadingChips = true;
        if (this.timeslotDisplayEndIndex < (this.timeslotsForSelectedDate.length - this.TIMESLOTS_TO_DISPLAY)) {
            this.timeslotDisplayStartIndex = this.timeslotDisplayStartIndex + this.TIMESLOTS_TO_DISPLAY;
            this.timeslotDisplayEndIndex = this.timeslotDisplayEndIndex + this.TIMESLOTS_TO_DISPLAY;
        } else {
            this.timeslotDisplayStartIndex = this.timeslotsForSelectedDate.length - this.TIMESLOTS_TO_DISPLAY;
            this.timeslotDisplayEndIndex = this.timeslotsForSelectedDate.length;
        }
        setTimeout(() => {
            this.parseTimeslotsForDisplay(
                this.timeslotsForSelectedDate.slice(this.timeslotDisplayStartIndex, this.timeslotDisplayEndIndex));
        });
    }

    selectTimeslot(timeslot: AppointmentSlotVM) {
        this.clearErrors();
        this.selectedTimeslot = timeslot;
    }

    selectLSTimeslot(selected: string) {
        this.selectedTimeslot = this.timeslotsForDisplay[+selected];
    }

    toggleCoronavirus() {
        this.isCoronavirusChecked = !this.isCoronavirusChecked;
    }

    formatMomentForTimeView(someMoment: Date): string {
        return this.timeZoneUtil.formatEnglish(someMoment, 'h:mm a');
    }

    goToLandingPage() {
        if (this.topicService?.topicInternalName === SingleTopicType.NextStepsConsult || !this.hasCompletedAppointments) {
            this.router.navigate([LiveServicesRoutes.SingleTopicPage], { queryParams: {
                topicInternalName: this.topicService?.topicInternalName } });
        } else {
            this.goToHome();
        }
    }

    goToHome() {
        this.router.navigate([LiveServicesRoutes.LegacyHome]);
    }

    goToActivityPage() {
        this.router.navigate(['transform/activity']);
    }

    openModal() {
        this.newAppointmentStartDate = this.selectedTimeslot?.startTime ?? new Date();
        this.newAppointmentEndDate = this.selectedTimeslot?.endTime ?? new Date();
        this.showRescheduleConfirmationModal = true;
    }

    submitAndBookAppointment() {
        this.clearErrors();
        const currentPhoneNumber = this.phoneNumber;
        this.validatePhoneNumber();
        if (!this.selectedTimeslot || !this.isPhoneNumberValid) {
            this.emptyFieldError = true;
        } else {
            this.emptyFieldError = false;

            if (this.rescheduleId) {
                const reschedulePayload: RescheduleAppointmentPayload = {
                    memberId: this.member.id,
                    appointmentId: this.rescheduleId,
                    rescheduleAppointmentRequest: {
                        startDateTime: this.selectedTimeslot.startTime,
                        endDateTime: this.selectedTimeslot.endTime,
                        phone: currentPhoneNumber.internationalNumber,
                        coachId: this.selectedTimeslot.coachId
                    } as RescheduleAppointmentRequestVM
                };

                this.rescheduleAppointment(reschedulePayload);
            } else {
                const liveServicesScheduleApptRequestVM: ScheduleAppointmentRequestVM = {
                    memberId: this.member.id,
                    startTime: this.selectedTimeslot.startTime,
                    endTime: this.selectedTimeslot.endTime,
                    phone: currentPhoneNumber.internationalNumber,
                    coachId: this.selectedTimeslot.coachId,
                    topicId: this.topicService?.topicId,
                    locationSFId: this.selectedLocation?.locationSFId ? this.selectedLocation.locationSFId : null,
                    programMemberId: this.programData?.id ? String(this.programData.id) : null,
                    transformProgram: this.programData?.programType ? this.programData.programType : null
                };

                this.submitAppointment(liveServicesScheduleApptRequestVM);
            }
        }
    }

    modalChange(isOpen: boolean) {
        if (!isOpen) {
            this.closeRescheduleModal();
        }
    }

    private rescheduleAppointment(reschedulePayload: RescheduleAppointmentPayload) {
        this.timeoutId = setTimeout(() => {
            this.checkForRescheduleSuccess(reschedulePayload);
        }, this.TEN_SECONDS);

        this.loadingAppointmentData = true;
        this.confirmedAppointmentSub = this.appointmentSchedulingService.rescheduleAppointment(reschedulePayload).pipe(
            filter((appointment) => !!appointment),
            tap(() => {
                if (this.timeoutId) {
                    clearTimeout(this.timeoutId);
                }
            })
        ).subscribe(
            (confirmedAppointment) => {
                if (confirmedAppointment) {
                    this.bookedAppointment(confirmedAppointment);
                }
            }, () => {
                if (this.timeoutId) {
                    clearTimeout(this.timeoutId);
                }
                this.loadingAppointmentData = false;
                this.bookingError = true;
            }
        );
    }

    private checkForRescheduleSuccess(request: RescheduleAppointmentPayload) {
        this.appointmentService.getAppointmentByAppointmentId(request.appointmentId).subscribe((appointment) => {
            if (appointment.startTime.toString() === request.rescheduleAppointmentRequest.startDateTime.toString()) {
                this.bookedAppointment(appointment);
            } else {
                this.redirectOnTimeout();
            }
        });
    }

    private scheduleAppointmentAsync(liveServicesScheduleApptRequestVM: ScheduleAppointmentRequestVM) {
        this.loadingAppointmentData = true;
        this.confirmedAppointmentSub = this.appointmentSchedulingService.bookAppointment(liveServicesScheduleApptRequestVM).pipe(
            filter((appointment) => !!appointment)
        ).subscribe(
            (confirmedAppointment) => {
                if (confirmedAppointment) {
                    this.bookedAppointment(confirmedAppointment);
                } else {
                    this.redirectToTransform();
                }
            }, () => {
                this.loadingAppointmentData = false;
                this.appointmentTakenError = false;
                this.bookingError = true;
            }
        );
        let attempts = 0;
        const interval = setInterval(
            () => {
                this.transformService.getProgramMembers(this.authenticatedMemberService.getAuthenticatedMemberId()).subscribe(
                    (programMember) => {
                        if (programMember[0].memberStatus === this.ENROLLED_PENDING_START_DATE) {
                            clearInterval(interval);
                            this.redirectOnTimeout();
                            return;
                        }
                        attempts++;
                        if (attempts >= this.MAX_ATTEMPTS) {
                            this.bookingError = true;
                            clearInterval(interval);
                        }
                    }
                );
            }, this.TEN_SECONDS);
    }

    submitAppointment(liveServicesScheduleApptRequestVM: ScheduleAppointmentRequestVM) {
        if (this.featureToggleService.hasAsyncAppointmentScheduleFeatureToggle()) {
            this.scheduleAppointmentAsync(liveServicesScheduleApptRequestVM);
        } else {
            this.scheduleAppointmentSynchronous(liveServicesScheduleApptRequestVM);
        }
    }

    clearErrors() {
        this.appointmentTakenError = false;
        this.bookingError = false;
    }

    selectLocation(location: OnsiteLocation) {
        this.dateTimeslotsMap.clear();
        const nowMomentLocal = this.timeZoneUtil.now();
        const maxDateMoment = DateUtils.add(nowMomentLocal, this.MONTHS_TO_MAX_DATE, 'months');
        this.disableDates(nowMomentLocal, maxDateMoment);
        this.selectedLocation = location;
        this.getAppointmentSlots(nowMomentLocal);
    }

    closeRescheduleModal() {
        this.router.navigate([LiveServicesRoutes.CoachingHub, { fromBooking: true }]);
        this.showRescheduleConfirmationModal = false;
    }

    private bookedAppointment(appointment: CoachingAppointmentVM) {
        if (this.isLiveServices) {
            this.sendCoachingAppointmentConfirmedEvent();
        }
        this.bookingError = false;
        this.appointmentTakenError = false;
        const hasCoachingHubEnabled = this.featureService.hasFeature(FeatureEnum.LiveServicesCoachMessaging) &&
            this.topicService.topicInternalName !== SingleTopicType.NextStepsConsult;

        if (this.rescheduleId) {
            this.isLoading = true;
            this.openModal();
            return;
        }
        if (!this.isLiveServices) {
            this.redirectToTransform();
            return;
        }
        if (hasCoachingHubEnabled) {
            if (this.isCoachInactive) {
                this.router.navigate([LiveServicesRoutes.CoachingHub, { wasCoachInactive: true, fromBooking: true }]);
            } else {
                this.router.navigate([LiveServicesRoutes.CoachingHub, { fromBooking: true }]);
            }
        } else {
            this.router.navigate([LiveServicesRoutes.Appointments, appointment.appointmentId]);
        }
    }

    private scheduleAppointmentSynchronous(scheduleApptRequestVM: ScheduleAppointmentRequestVM) {
        this.loadingAppointmentData = true;
        this.appointmentService.bookAppointment(scheduleApptRequestVM).subscribe(
            (response) => {
                this.bookedAppointment(response);
            }, (response) => {
                this.loadingAppointmentData = false;
                this.appointmentTakenError = false;
                this.bookingError = false;

                if (response.error.statusCode === this.CONFLICT_RESPONSE_CODE) {
                    this.appointmentTakenError = true;
                } else {
                    this.bookingError = true;
                }
            }
        );
    }

    private redirectOnTimeout() {
        if (!this.isLiveServices) {
            this.router.navigate(['transform/activity'], { queryParams: { fromBooking: true, tab: 'messaging' } });
            return;
        }
        const hasCoachingHubEnabled = this.featureService.hasFeature(FeatureEnum.LiveServicesCoachMessaging) &&
            this.topicService.topicInternalName !== SingleTopicType.NextStepsConsult;
        if (hasCoachingHubEnabled) {
            this.router.navigate([LiveServicesRoutes.CoachingHub, { fromBooking: true }]);
        } else {
            this.router.navigate([LiveServicesRoutes.Appointments, this.constants.PENDING_PATH]);
        }
    }

    private sendCoachingScheduleAppointmentViewedEvent() {
        const coachingScheduleAppointmentViewedEvent: CoachingScheduleAppointmentViewedEvent = {
            member_coaching_status: this.memberEngagement.engagementStatus,
            coaching_topic_id: this.topicService.topicId,
            coaching_topic_name: this.topicService.topicName,
            coaching_package_name: this.memberEngagement.liveServicesPackageName
        };
        this.analyticsTrackingService.sendData(AnalyticsTrackingAction.CoachingScheduleAppointmentViewed,
            coachingScheduleAppointmentViewedEvent);
    }

    private sendCoachingAppointmentConfirmedEvent() {
        const coachingAppointmentConfirmedEvent: CoachingAppointmentConfirmedEvent = {
            member_coaching_status: this.memberEngagement.engagementStatus,
            coaching_topic_id: this.topicService.topicId,
            coaching_topic_name: this.topicService.topicName,
            coaching_package_name: this.memberEngagement.liveServicesPackageName,
            date_click_count: this.mixpanelDateClickCount,
            appt_date: this.selectedDate.toISOString()
        };
        this.analyticsTrackingService.sendData(AnalyticsTrackingAction.CoachingAppointmentConfirmed, coachingAppointmentConfirmedEvent);
    }

    private formatPhoneInput(number: PhoneNumber) {
        this.phoneForm.patchValue({
            phone: this.phoneUtil.format(number, BookAppointmentComponent.PNF.NATIONAL)
        });
    }

    private setMemberPhone() {
        if (this.member.phoneNumber) {
            this.phoneForm.patchValue({
                phone: this.member.phoneNumber
            });
            return;
        }
        this.liveServicesCoachingService.getCoachingPhoneNumberForMember(this.member.id).subscribe((response) => {
            this.phoneForm.patchValue({
                phone: response
            });
        });
    }

    private setTopicDetails() {
        if (this.topicService.topicInternalName === SingleTopicType.NextStepsConsult) {
            this.topicService.coachId = undefined;
        } else {
            this.topicService.coachId = this.memberEngagement.primaryCoachId === null ?
                undefined : this.memberEngagement.primaryCoachId;
        }

        if (this.topicService.isPackageTopic !== undefined) {
            return;
        }

        switch (this.topicService.topicInternalName) {
        case SingleTopicType.TobaccoFree:
        case SingleTopicType.Onsite:
        case SingleTopicType.NextStepsConsult:
            this.topicService.isPackageTopic = false;
            break;
        default:
            this.topicService.isPackageTopic = true;
            break;
        }
    }

    redirectToTransform() {
        let attempts = 0;
        const interval = setInterval(
            () => {
                this.transformService.getProgramMembers(this.authenticatedMemberService.getAuthenticatedMemberId()).subscribe(
                    (programMember) => {
                        if (programMember[0].memberStatus === this.ENROLLED_PENDING_START_DATE) {
                            clearInterval(interval);
                            this.router.navigate(['transform/activity'], { queryParams: { fromBooking: true, tab: 'messaging' } });
                            return;
                        }
                        attempts++;
                        if (attempts >= this.MAX_ATTEMPTS) {
                            this.bookingError = true;
                            clearInterval(interval);
                        }
                    });
            }, this.FIVE_SECONDS);
    }
}
