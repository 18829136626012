import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'convertTimestampToDate'
})

export class ConvertTimestampToDate implements PipeTransform {
    transform(timestamp: any): string {
        return new Date(timestamp).toLocaleString('en-US');
    }
}
