import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'translateRedemptionAction'
})
export class TranslateRedemptionAction implements PipeTransform {
    transform(pipeData: any): string {
        if (pipeData === 'RedemptionOrderCreate') {
            return 'Submit Order';
        }

        if (pipeData === 'RedemptionOrderValidate') {
            return 'Validate Order';
        }

        if (pipeData === 'TransactionFundingRequest') {
            return 'Debit Account';
        }

        if (pipeData === 'RedemptionProviderRequest') {
            return 'Issue Tango Card';
        }

        if (pipeData === 'EmailProviderRequest') {
            return 'Send Email';
        }

        if (pipeData === 'RedemptionRefund') {
            return 'Refund';
        }
        return pipeData;
    }
}
