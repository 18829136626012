import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function dateValidator(
    referenceDate: string,
    isFuture: boolean = false,
    offsetMonths: number | undefined = undefined
): ValidatorFn {
    return (control: AbstractControl<string | null>): ValidationErrors | null => {
        const date = control.value;
        if (!date) {
            return null;
        }

        const [year, month, day] = date.split('-').map(Number);
        const [refYear, refMonth, refDay] = referenceDate.split('-').map(Number);
        const targetDate = new Date(refYear, refMonth - 1, refDay);

        if (offsetMonths) {
            targetDate.setMonth(targetDate.getMonth() + (isFuture ? offsetMonths : -offsetMonths));
            targetDate.setDate(targetDate.getDate() - 1);
        }

        const inputDate = new Date(year, month - 1, day);

        if (isFuture) {
            return inputDate > targetDate ? { invalidFutureDate: true } : null;
        }
        return inputDate < targetDate ? { invalidPastDate: true } : null;
    };
}


