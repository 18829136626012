import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'linkyWithHtml' })
export class LinkyWithHTMLPipe implements PipeTransform {
    transform(text: any, changeColor?: boolean): any {
        const urlRegex = /(\b(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}))/ig;
        return text.replace(urlRegex, (url: any) => {
            if (!/^(?:f|ht)tps?:\/\//.test(url)) {
                url = 'https://' + url;
            }
            if (changeColor) {
                return '<a class="linky-text" target="_blank" href="' + url + '">' + url + '</a>';
            }
            return '<a target="_blank" href="' + url + '">' + url + '</a>';
        });
    }
}
