import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FriendRequest } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class FriendRequestApiService {

    constructor(private httpClient: HttpClient) { }

    acceptFriendRequest(memberId: number, friendRequest: FriendRequest): Observable<FriendRequest> {
        return this.httpClient.put<FriendRequest>(this.memberFriendRequestURI(memberId), friendRequest);
    }

    declineFriendRequest(memberId: number, friendRequestId: number): Observable<FriendRequest> {
        return this.httpClient.delete<FriendRequest>(this.memberFriendRequestURI(memberId) + `/${ friendRequestId }`);
    }

    private memberFriendRequestURI = (memberId: number) =>
        `/api/members/${ memberId }/friends/requests`;
}
