export class PreviewPage {
    display: boolean;
    name: string;
    route: string;

    constructor(display: boolean, name: string, route: string) {
        this.display = display;
        this.name = name;
        this.route = route;
    }
}
