import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppHistoryService } from './app-history.service';
import { BlockerResponseModel } from '../models/interfaces/blocker-response.model';
import { ShellRouter } from '../shell/shell-router';

@Injectable({ providedIn: 'root' })
export class ChallengesInterruptService {

    private readonly isMobileDevice = (/Mobi|iPhone|Android/).test(navigator.userAgent);

    constructor(
        private httpClient: HttpClient,
        private appHistoryService: AppHistoryService,
        private shellRouter: ShellRouter) {}

    checkForChallengeInterrupt(memberId: number, onboardingChallengeBlocker: boolean, resolve: any) {
        if (onboardingChallengeBlocker) {
            this.getChallengePromotionInterruptId(memberId).subscribe((response) => {
                if (response) {
                    this.appHistoryService.addLocation(location.href);
                    resolve({ showBlocker: true, response: { contestId: response.contestId } });
                } else {
                    resolve({ showBlocker: false });
                }
            }, () => {
                resolve({ showBlocker: false });
            });
        }
    }

    getTeamStatsForChallenge(memberId: number, contestId: number): Observable<any> {
        return this.httpClient.get<any>(`/featured-challenges-api/members/${memberId}/contests/${contestId}/team-stats`);
    }

    setContestInterruptLastSeenDate(memberId: number, contestId: number): Observable<any> {
        return this.httpClient.put(`/featured-challenges-api/members/${memberId}/contests/${contestId}/interrupt-seen`, {});
    }

    getChallengePromotionInterruptId(memberId: number): Observable<any> {
        return this.httpClient.get<void>(`/featured-challenges-api/members/${ memberId }/contest-interrupt`);
    }

    redirectToContestInterruptPage(blocker: BlockerResponseModel & { response: { contestId?: number } }) {
        return new Promise<void>((resolve) => {
            if (blocker.showBlocker && blocker?.response?.contestId) {
                this.shellRouter.navigate(`challenges/${blocker.response.contestId}/interrupt`);
            }
            resolve();
        });
    }

    checkForChallengeInterruptBlocker = (memberId: number, onboardingChallengeBlocker: boolean) => {
        return new Promise<BlockerResponseModel>((resolve) => {
            if (!this.isMobileDevice) {
                this.checkForChallengeInterrupt(memberId, onboardingChallengeBlocker, resolve);
            } else {
                resolve({ showBlocker: false });
            }
        });
    };
}
