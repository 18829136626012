import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UrlUtil {

    getParameter(name: string): string {
        const PARAMETER_VALUE = 2;
        const url = window.location.href;
        const normalizedName = name.replace(/[[]]/g, '\\$&');
        const regex = new RegExp('[?&]' + normalizedName + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);

        if (!results) {
            return '';
        }

        if (!results[PARAMETER_VALUE]) {
            return '';
        }

        return decodeURIComponent(results[PARAMETER_VALUE].replace(/\+/g, ' '));
    }

    removeParam(key: string, sourceURL: string): string {
        let rtn = sourceURL.split('?')[0];
        let param: string;
        let arrParams: string[] = [];
        const queryString = (sourceURL.includes('?')) ? sourceURL.split('?')[1] : '';
        if (queryString !== '') {
            arrParams = queryString.split('&');
            for (let i = arrParams.length - 1; i >= 0; i -= 1) {
                param = arrParams[i].split('=')[0];
                if (param === key) {
                    arrParams.splice(i, 1);
                }
            }
            if (arrParams.length > 0) {
                rtn = rtn + '?' + arrParams.join('&');
            }
        }
        return rtn;
    }

}
