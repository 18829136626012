import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { setTheme } from 'ngx-bootstrap/utils';

import { VideoDataTypesEnum } from './video-data-types.enum';
import { VideoModalComponent } from './video-modal.component';

@Injectable()
export class VideoModalService {

    videoDataTypesEnum = VideoDataTypesEnum;

    constructor(
        private bsModalService: BsModalService
    ) {
        setTheme('bs4');
    }

    openVideo(videoUrl: string) {
        const videoData = this.getVideoData(videoUrl);
        if (videoData.type !== this.videoDataTypesEnum.Other && videoData.id) {
            this.openModal(videoData.id, videoData.type);
        } else {
            window.open(videoUrl, '_blank');
        }
    }

    openModal(videoId: string, type: string) {
        return this.bsModalService.show(VideoModalComponent, {
            class: 'bn-play-video-modal',
            initialState: {
                videoId,
                type
            },
            animated: false
        });
    }

    private getVideoData(videoUrl: string): { type: string, id: string } {
        const videoData = { type: 'other', id: '' };

        const ytRegex = /(http:|https:)\/\/(www\.)?(youtu(be\.com|\.be|be\.googleapis\.com))\/(watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/;
        const vmRegex = /(http:|https:)\/\/(player\.)?(vimeo)\.com\/(video\/)?([A-Za-z0-9._%-]*)(&\S+)?/;
        if (ytRegex.test(videoUrl)) {
            videoData.type = this.videoDataTypesEnum.YT;
            videoData.id = this.getYoutubeId(videoUrl);
        } else if (vmRegex.test(videoUrl)) {
            videoData.type = this.videoDataTypesEnum.VM;
            videoData.id = this.getVimeoId(videoUrl);
        }
        return videoData;
    }

    private getYoutubeId(videoUrl: string): string {
        let id = '';
        const urlArr = videoUrl.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
        const urlIdIndex = 2;
        if (urlArr[urlIdIndex] !== undefined) {
            // eslint-disable-next-line no-useless-escape
            id = urlArr[urlIdIndex].split(/[^0-9a-z_\-]/i)[0];
        } else {
            id = videoUrl;
        }
        return id;
    }

    private getVimeoId(videoUrl: string): string {
        let id = '';
        const regExp = /vimeo.[^0-9.]*(?:\/|clip_id=|id=)([0-9a-z]*[?h=]*[0-9a-z]*)/i;

        const match = videoUrl.match(regExp);
        const urlIdIndex = 1;

        if (match && (videoUrl.search('event') === -1)) {
            id = match[urlIdIndex];
        }

        return id;
    }

}
