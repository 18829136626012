export interface CacheItem<T=any> {
    time: number;
    value: T;
    tag?: any;
}

export interface CacheInterface<T> {
    setItem(key: string, value: T): void;
    getItem(key: string): CacheItem<T> | null;
    removeItem(key: string): void;
    hasItem(key: string): boolean;
}


export function isCacheExpired(cachedAt: number, maxAgeInSeconds: number) {
    if (cachedAt > 0) {
        const timeNow = (new Date()).getTime();
        const expiresAt = (cachedAt + maxAgeInSeconds * 1000);
        return timeNow > expiresAt;
    }
    return true;
}

export function isCacheValid(item: CacheItem<any> | null, maxAgeInSeconds: number): item is CacheItem<any> {
    return (item != null) && !isCacheExpired(item.time, maxAgeInSeconds);
}
