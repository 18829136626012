import { Component, Inject } from '@angular/core';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

import { GlobalChallengeCardCarouselDataModel } from '../../../interface/global-challenges-card-carousel-data.model';
import { MemberContestOnboardingBlockerService } from '../../member-contest-onboarding-blocker.service';
import {
    AuthenticatedMemberService,
    Country,
    Member,
    SweetAlertService,
    UnleashFlag,
    UnleashFlagsService
} from '@genesis-frontend/genesis-utilities';

@Component({
    templateUrl: './address-confirmation.template.html',
    styleUrls: ['./address-confirmation.component.scss']
})
export class GlobalChallengesMaxBuzzAddressConfirmationCardComponent {
    phoneNumber!: string;
    member: Member | undefined;
    memberCountry: Country | undefined;
    showLeftArrow = true;
    showRightArrow = false;
    savingOrderInProgress = false;
    isPersonifyHealthTheme = false;

    phoneUtil = PhoneNumberUtil.getInstance();

    constructor(
        @Inject('data') public data: GlobalChallengeCardCarouselDataModel,
        private authenticatedMemberService: AuthenticatedMemberService,
        private memberContestOnboardingBlockerService: MemberContestOnboardingBlockerService,
        private sweetAlertService: SweetAlertService,
        private unleashFlagService: UnleashFlagsService
    ) {}

    ngOnInit() {
        this.isPersonifyHealthTheme = this.unleashFlagService.hasUnleashFlag(UnleashFlag.BrandingUpdatesPhase2);
        this.data.isMaxBuzzAddressConfirmationCardVisited = true;
        this.member = this.authenticatedMemberService.getMember();
        this.memberCountry = this.authenticatedMemberService.getCountry();
        if (this.data.orderDetails) {
            const number = this.phoneUtil.parseAndKeepRawInput(
                this.data.orderDetails.phoneNumberData.number,
                this.data.orderDetails.phoneNumberData.countryCode
            );
            this.phoneNumber = this.phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
            this.data.orderDetails.phoneNumber = this.data.orderDetails.phoneNumberData.dialCode + number.getNationalNumber();
        }
    }

    placeOrder() {
        this.savingOrderInProgress = true;
        if (this.member?.id && this.data.orderDetails) {
            this.memberContestOnboardingBlockerService
                .sendOrderDetails(this.data.contest.contestId, this.member.id, this.data.orderDetails)
                .subscribe(
                    () => {
                        this.data.nextCard();
                    },
                    (errorResponse: { error: { message: any } }) => {
                        this.sweetAlertService.showError(errorResponse.error.message);
                        this.savingOrderInProgress = false;
                    }
                );
        }
    }
}
