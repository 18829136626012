import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ContestMember } from '../interface/contest-member.model';
import { ContestStageData } from '../interface/contest-stage-data.model';
import { ContestTeamUnenrolledInvite } from '../interface/contest-team-unenrolled-invite.model';
import { ContestTeam } from '../interface/contest-team.model';
import { EnrolledTeamMember } from '../interface/enrollment-team-member.model';
import { MemberDeviceApplication } from '../interface/member-device-application.model';
import { Country, CountryState, OrderDetails } from '@genesis-frontend/genesis-utilities';

@Injectable()
export class MemberContestOnboardingBlockerService {
    constructor(private httpClient: HttpClient) {}

    getOnboardingChallengeId(memberId: number): Observable<any> {
        return this.httpClient.get<void>(`/featured-challenges-api/members/${memberId}/challenge-onboarding-blocker`);
    }

    getOnboardingChallenge(memberId: number, contestId: number): Observable<any> {
        return this.httpClient.get<any>(`/featured-challenges-api/members/${memberId}/contests/${contestId}`);
    }

    getSuggestedTeamsForContest(memberId: number, contestId: number, page?: number, pageSize?: number): Observable<ContestTeam[]> {
        let params = new HttpParams();
        if (page != null && pageSize != null) {
            params = params.append('page', String(page));
            params = params.append('pageSize', String(pageSize));
        }
        return this.httpClient.get<any>(`/bff/members/${memberId}/contests/${contestId}/teams/suggested/onboarding`, { params });
    }

    getSuggestedTeamsForOrgbasedContest(memberId: number, contestId: number, teamMembersToDisplay: string): Observable<any> {
        return this.httpClient.get<any>(`/bff/members/${memberId}/contests/${contestId}/org-hierarchy-team`, {
            params: {
                membersListSize: teamMembersToDisplay
            }
        });
    }

    searchForTeamsAndFriends(contestId: number, criteria: string): Observable<ContestTeam[]> {
        return this.httpClient.get<any>(`/bff/contests/${contestId}/teams/search`, {
            params: {
                criteria
            }
        });
    }

    getContestMember(contestId: number, memberId: number): Observable<ContestMember> {
        return this.httpClient.get<ContestMember>(`/bff/contests/${contestId}/players/${memberId}`);
    }

    validateInviteEmailAddress(emailAddress: string, contestId: number): Observable<any> {
        return this.httpClient.post<any>(`/featured-challenges-api/contests/${contestId}/unenrolled-invites/validate`, null, {
            params: {
                emailAddress
            }
        });
    }

    sendInvitationEmails(contestId: number, teamId: number, invitedPlayers: string[]): Observable<ContestTeamUnenrolledInvite[]> {
        return this.httpClient.post<ContestTeamUnenrolledInvite[]>(
            `/bff/contests/${contestId}/teams/${teamId}/unenrolled-invites`,
            invitedPlayers
        );
    }

    joinOrgBasedContest(contestId: number, memberId: number): Observable<ContestMember> {
        return this.httpClient.post<any>(`/featured-challenges-api/contests/${contestId}/players/${memberId}`, {});
    }

    joinTeam(contestId: any, teamId: any, memberId: number | null): Observable<ContestMember> {
        return this.httpClient.put<any>(`/featured-challenges-api/contests/${contestId}/teams/${teamId}/players/${memberId}`, {});
    }

    getTeamInvitesForMember(contestId: number, memberId: number): Observable<ContestTeam[]> {
        return this.httpClient.get<ContestTeam[]>(`/bff/members/${memberId}/contests/${contestId}/invites`);
    }

    getAvailableDevicesForMember(memberId: number): Observable<MemberDeviceApplication[]> {
        return this.httpClient.get<MemberDeviceApplication[]>(`/api/members/${memberId}/sync/applications`);
    }

    getApplicationSyncURL(memberId: number, applicationId: number): Observable<string> {
        return this.httpClient.get<string>(`/api/members/${memberId}/sync/applications/${applicationId}/syncURL`, {
            responseType: 'text' as 'json'
        });
    }

    getCountriesEligibleForDeviceOrder(): Observable<Country[]> {
        return this.httpClient.get<Country[]>('/api/countries', {
            params: {
                deviceOrderEligible: String(true)
            }
        });
    }

    getCountryStates(countryId: number): Observable<CountryState[]> {
        return this.httpClient.get<CountryState[]>(`/api/countries/${countryId}/states`);
    }

    sendOrderDetails(contestId: number, memberId: number, orderDetails: OrderDetails): Observable<OrderDetails> {
        return this.httpClient.post<OrderDetails>(`/api/contests/${contestId}/members/${memberId}/device-orders`, orderDetails);
    }

    getMemberDeviceOrders(contestId: number, memberId: number): Observable<OrderDetails[]> {
        return this.httpClient.get<OrderDetails[]>(`/api/contests/${contestId}/members/${memberId}/device-orders`);
    }

    getContestStages(contestId: number): Observable<ContestStageData[]> {
        return this.httpClient.get<ContestStageData[]>(`/featured-challenges-api/contests/${contestId}/stages`);
    }

    getMembersByName(
        sponsorId: number,
        contestId: number,
        criteria: string,
        includeMembersOnTeam: boolean,
        resultSize: number
    ): Observable<EnrolledTeamMember[]> {
        return this.httpClient.get<EnrolledTeamMember[]>(`bff/sponsors/${sponsorId}/members/search`, {
            params: {
                contestId: String(contestId),
                criteria: String(criteria),
                includeMembersOnTeam: String(includeMembersOnTeam),
                resultSize: String(resultSize)
            }
        });
    }

    sendInvitationMembers(contestId: number, teamId: number, memberIds: number[]): Observable<any[]> {
        return this.httpClient.post<any[]>(`/featured-challenges-api/contests/${contestId}/teams/${teamId}/invites`, memberIds);
    }

    revokeEnrolledPlayerInvitation(contestId: number, teamId: number, memberId: number) {
        return this.httpClient.delete(`/featured-challenges-api/contests/${contestId}/teams/${teamId}/players/${memberId}/invites`);
    }

    revokeUnenrolledPlayerInvitation(contestId: number, teamId: number, inviteId: number) {
        return this.httpClient.delete(`/featured-challenges-api/contests/${contestId}/teams/${teamId}/unenrolled-invites/${inviteId}`);
    }

    getRandomTeamFromContest(memberId: number, contestId: number): Observable<ContestTeam[]> {
        return this.httpClient.get<any>(`/bff/members/${memberId}/contests/${contestId}/teams/suggested`, {
            params: {
                page: '0',
                pageSize: '1',
                randomOrder: 'true'
            }
        });
    }

    saveTeamResource(contestId: number, team: any): Observable<any[]> {
        return this.httpClient.post<any[]>(`/featured-challenges-api/contests/${contestId}/teams`, team);
    }
}
