<div class="personal-challenge-confirmation">
    <a class="personal-challenge-choose-again-focused sr-only"
       *ngIf="!displaySingle"
       (click)="completionCallback.emit()"
       [translate]="'GoBackLeft'">
    </a>
    <a class="personal-challenge-choose-again personal-challenge-choose-again-focus vp-text-link"
       *ngIf="!displaySingle"
       (click)="completionCallback.emit()"
       [innerHTML]="'GoBack' | translate">
        &lt;
    </a>
    <div class="clearfix"></div>
    <div class="personal-challenge-image-container centered">
        <img class="large-challenge-image"
             [src]="challengeData.selectedImageUrl"
             alt=""/>
    </div>
    <div>
        <textarea
            rows="3"
            class="challenge-description"
            [placeholder]="placeholderText"
            (focus)="changeVisibility(true)"
            (blur)="changeVisibility(false)"
            [(ngModel)]="challengeData.description"
            [maxLength]="AREA_MAX_LENGTH"
            [maxlength]="'250'"
            [attr.aria-label]="'EnterChallengeDescription' | translate: { numChars : AREA_MAX_LENGTH }">
        </textarea>
        <div class="description-character-count"
             [ngClass]="{ 'warning': challengeData.description.length >= WARNING_LENGTH }"
             aria-hidden="true">
            <span aria-hidden="true"
                  [translate]="'Limit'">
            </span>: {{ AREA_MAX_LENGTH - challengeData.description.length }}
        </div>
        <div aria-live="polite">
            <div class="sr-only"
                 *ngIf="challengeData.description.length < AREA_MAX_LENGTH && counterVisibleSrOnly"
                 [innerHTML]="'RemainingChars' | translate: { numChars : (AREA_MAX_LENGTH - challengeData.description.length) }">
            </div>
            <div class="sr-only"
                 *ngIf="challengeData.description.length >= AREA_MAX_LENGTH && counterVisibleSrOnly"
                 [translate]="'NoRemainingChars'">
            </div>
        </div>
    </div>
    <p class="challenge-start-text"></p>
    <button class="vp-button larger vp-button-primary"
            (click)="submitChallenge()"
            [disabled]="disableSubmitButton"
            *ngIf="!displaySingle"
            [translate]="'StartItUp'">
    </button>
    <button class="btn-default-medium vp-button-primary"
        (click)="editChallenge()"
        *ngIf="displaySingle">
        <span [translate]="'SaveChanges'"></span>
    </button>
    <div class="challenge-rules"
         *ngIf="!displaySingle">
        <a class="vp-text-link"
           tabindex="0"
           (click)="setStep.emit(STEP_TWO)"
           (keydown.enter)="setStep.emit(STEP_TWO)"
           (keydown.space)="setStep.emit(STEP_TWO)"
           [translate]="'ChallengeRules'">
        </a>
    </div>
</div>
