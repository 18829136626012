import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ENVIRONMENT, Environment } from '../config';
import { UrlUtil } from '../services/url-util.service';


declare const window: any;

@Injectable()
export class LoginService {
    private MEMBER_IDENTITY_RESOURCE = `${this.environment.authServer.realmUrl}/identity/member`;

    constructor(
        @Inject(ENVIRONMENT) private environment: Environment,
        private httpClient: HttpClient,
        private urlUtil: UrlUtil
    ) { }

    removeEmailParam(email: string) {
        let isRefreshNeeded = false;
        if (!email) {
            return isRefreshNeeded;
        }
        const hash = this.urlUtil.removeParam('email', window.location.hash);
        const search = this.urlUtil.removeParam('email', window.location.search);

        if (search === window.location.search) {
            window.location.href = window.location.pathname + search + hash;
        } else {
            window.location.href = window.location.pathname + search + hash;
            isRefreshNeeded = true;
        }
        return isRefreshNeeded;
    }

    getMembersIdentity(email = ''): Observable<any> {
        return this.httpClient.post(this.MEMBER_IDENTITY_RESOURCE, {
            usernameOrEmail: email,
            rememberUsername: false
        });
    }

    getEmail(): string {
        return this.urlUtil.getParameter('email');
    }
}
