import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { JourneySurveyModalService } from '../journey-survey-modal/journey-survey-modal.service';
import { ButtonType } from '@engineering/genesis-frontend';
import { WcagService } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'journey-completed-modal',
    templateUrl: './journey-completed-modal.component.html',
    styleUrls: ['./journey-completed-modal.component.scss']
})
export class JourneyCompletedModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() journeyTitle?: any;
    @Input() journeyId?: any;
    @Input() isSurveyCompleted = false;

    readonly PRIMARY_BUTTON = ButtonType.Primary;
    readonly SECONDARY_BUTTON = ButtonType.Secondary;
    readonly CLOSE_MODAL_DELAY = 100;
    readonly TAB = 'Tab';

    private reloadOnClose = false;
    private onHideSubscription?: Subscription;

    @ViewChild('journeyCompletedModal') modal?: ElementRef;

    constructor(
        private bsModalRef: BsModalRef,
        private journeySurveyModalService: JourneySurveyModalService,
        private router: Router,
        private wcagService: WcagService,
        private elementRef: ElementRef
    ) { }

    ngOnInit(): void {
        this.elementRef.nativeElement.addEventListener('keydown', (event: any) => {
            this.wcagService.trapFocusInsideElementsContainer(event, this.elementRef, this.TAB);
        });

        this.onHideSubscription = this.bsModalRef.onHide?.subscribe(() => {
            if (!this.reloadOnClose) {
                return;
            }
            this.navigateToJourneyChecklist();
        });

        this.reloadOnClose = true;
    }

    ngOnDestroy(): void {
        this.onHideSubscription?.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.modal?.nativeElement.focus();
    }

    close() {
        this.bsModalRef.hide();
    }

    private navigateToJourneyChecklist() {
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
        });
    }

    openSurveyModal() {
        this.journeySurveyModalService.showJourneySurveyModal(this.journeyId, this.journeyTitle);
        setTimeout(() => {
            this.reloadOnClose = false;
            this.close();
        }, this.CLOSE_MODAL_DELAY);
    }
}
