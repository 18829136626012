
import { Injectable, NgModule, NgZone, Optional } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { Global } from './global';

type KeysOf<T extends Record<string, any>> = keyof T & string;
type EventsMap = Record<string, any>
type DataType<K extends string, E extends EventsMap> = E[K]['data'];
type DispatchArgs<T> = T extends undefined ? [] : [T]

export type EventHandler<T = any> = (event: T) => void;
export interface Event<T=any, K=string> { type: K, data: T}
export interface AsyncEvent<T=any, K=string> extends Event<T, K> { complete: () => void}

@Injectable()
export class EventBus<TEventsMap extends EventsMap = EventsMap> {

    private eventBus: Subject<Event> = Global.get('sharedEventBus', () => new Subject<Event>());

    constructor(
        @Optional() private zone?: NgZone
    ) {
    }

    private execute(handler: EventHandler) {
        if (!this.zone) {
            return handler;
        }

        const zone = this.zone;
        return (event: Event) => {
            zone.run(() => {
                handler(event);
            });
        };
    }

    on<K extends KeysOf<TEventsMap>>(type: K, handler: EventHandler<TEventsMap[K]>) {
        return this.eventBus
            .pipe(filter((e) => e.type === type))
            .subscribe(this.execute(handler));
    }

    once<K extends KeysOf<TEventsMap>>(type: K, handler: EventHandler<TEventsMap[K]>) {
        this.eventBus
            .pipe(filter((e) => e.type === type))
            .pipe(first())
            .subscribe(this.execute(handler));
    }

    subscribe(handler: EventHandler) {
        return this.eventBus
            .subscribe(this.execute(handler));
    }

    dispatch<K extends KeysOf<TEventsMap>>(type: K, ...args: DispatchArgs<DataType<K, TEventsMap>>): Event {
        const event: Event = { type, data: args[0] } as Event;
        this.eventBus.next(event);
        return event;
    }

    dispatchAsync<K extends KeysOf<TEventsMap>>(type: K, ...args: DispatchArgs<DataType<K, TEventsMap>>) {

        type T = DataType<K, TEventsMap>;

        return new Promise<Event<DataType<K, TEventsMap>, K>>((resolve) => {
            const event: AsyncEvent<T, K> = {
                type,
                data: args[0],
                complete: () => resolve(event)
            };
            this.eventBus.next(event);
        });
    }

    group(): EventBusGroup<TEventsMap> {
        return new EventBusGroup<TEventsMap>(this);
    }
}

@Injectable()
export class EventBusGroup<TEventsMap extends EventsMap> {

    private subscriptions = new Subscription();

    constructor(
        private eventBus: EventBus<TEventsMap>
    ) {}

    on<K extends KeysOf<TEventsMap>>(type: K, handler: EventHandler<TEventsMap[K]>) {
        const sub = this.eventBus.on(type, handler);
        this.subscriptions.add(sub);
    }

    once<K extends KeysOf<TEventsMap>>(type: K, handler: EventHandler<TEventsMap[K]>): void {
        this.eventBus.once(type, handler);
    }

    subscribe(handler: EventHandler): void {
        const sub = this.eventBus.subscribe(handler);
        this.subscriptions.add(sub);
    }

    unsubscribe(): void {
        this.subscriptions.unsubscribe();
    }
}

@NgModule({
    providers: [
        { provide: EventBus, useClass: EventBus },
        { provide: EventBusGroup, useFactory: (eventBus: EventBus) => eventBus.group(), deps: [EventBus] }
    ]
})
export class EventBusModule {}
