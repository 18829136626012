<div class="global-challenges-connect-device-container">
    <div class="card-header-wrapper-connect-device">
        <div
            class="card-title-connect-device text-clr-dark-gray"
            [translate]="'ConnectDevice'">
            Connect a device
        </div>
        <div
            class="card-description-connect-device text-clr-dark-gray"
            [translate]="'SelectYourDevice'">
            Select your device or app to connect your account.
        </div>
    </div>
    <div class="card-middle-wrapper-connect-device">
        <button
            class="device-cell outline-clr-bg-hover no-background no-border border-tile-connect-device"
            role="button"
            [disabled]="!allowedApplications[providerType]?.syncURL"
            *ngFor="let providerType of allowedProviderTypes; index as i; even as isEven; odd as isOdd"
            [ngClass]="{'even-index-cell': isEven, 'odd-index-cell': isOdd }"
            (click)="redirectToConnectPage(allowedApplications[providerType])">
            <img [src]="'../img/vp-go/' + providerTypesLogos[providerType]" />
        </button>
    </div>
    <div class="card-footer-connect-device text-clr-dark-gray">
        <hr class="border-clr-gainsboro" />
        <p
            #goToDevicesAndAppsParent
            [innerHTML]="'DeviceNotListed' | translate">
            Not listed?
            <a
                class="text-clr-vp-teal">
                Go to Devices & Apps for more options.
            </a>
        </p>
        <div class="connect-device-done-button">
            <button
                class="vp-button-primary no-border"
                [translate]="'Done'"
                (click)="goToChallenges()">
                Done
            </button>
        </div>
    </div>
</div>
<div
    class="prev-card-btn background-clr-bg background-dark text-clr-white"
    *ngIf="showLeftArrow"
    (click)="goToPreviousCard()"
    role="button"
    tabindex="0"
    [attr.aria-label]="'previous' | translate">
    <i class="fa fa-angle-left"></i>
</div>
