import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DateUtils } from '../../lib/date-utils';
import {
    AppointmentType,
    LiveServicesCoachingStatus,
    AppointmentSlotVM,
    CoachingAppointmentVM,
    ScheduleAppointmentRequestVM,
    Member,
    SingleTopicType
} from '../../models';
import { TimeZoneUtil } from '../../services';

@Injectable({
    providedIn: 'root'
})
export class AppointmentService {
    BASE_LIVE_SERVICES_COACHING_APPOINTMENTS_URI = '/live-services-api/live-services-coaching/appointments';
    BASE_LIVE_SERVICES_COACHING_MEMBERS_URI = '/live-services-api/live-services-coaching/members';

    ACTIVE_STATUSES = [
        LiveServicesCoachingStatus.InProgress,
        LiveServicesCoachingStatus.Scheduled,
        LiveServicesCoachingStatus.Processing
    ];

    constructor(
        private httpClient: HttpClient,
        private timeZoneUtil: TimeZoneUtil
    ) { }

    getMonthlyAvailability(
        topicId: number | null,
        month: number,
        year: number,
        transformProgram: string | null,
        programMemberId: number | null
    ): Observable<Map<string, boolean>> {
        let params = new HttpParams({
            fromObject: {
                month: String(month),
                year: String(year),
                timeZone: this.timeZoneUtil.timeZoneName
            }
        });

        if (topicId) {
            params = params.append('topicId', String(topicId));
        }

        if (transformProgram) {
            params = params.append('transformProgram', String(transformProgram));
        }

        if (programMemberId) {
            params = params.append('programMemberId', String(programMemberId));
        }

        return this.httpClient.get<Map<string, boolean>>(
            `${this.BASE_LIVE_SERVICES_COACHING_APPOINTMENTS_URI}/monthly-availability`, { params }
        ).pipe(
            map((dates) => new Map<string, boolean>(Object.entries(dates)))
        );
    }

    getAllAppointments(
        memberId: number,
        packageNameList: string | string[] | null = null
    ): Observable<CoachingAppointmentVM[]> {
        let params = new HttpParams();

        if (packageNameList != null) {
            params = params.appendAll({ 'packageNameList': packageNameList });
        }

        return this.httpClient.get<CoachingAppointmentVM[]>(
            `${this.BASE_LIVE_SERVICES_COACHING_MEMBERS_URI}/${memberId}/all-appointments`, { params });
    }

    getMemberAppointments(
        member: Member,
        statusList: string[] | null = null,
        topicInternalName: string | null = null
    ): Observable<CoachingAppointmentVM[]> {

        if (statusList === null && topicInternalName === null) {
            return this.httpClient.get<CoachingAppointmentVM[]>(
                `${this.BASE_LIVE_SERVICES_COACHING_MEMBERS_URI}/${member.id}/appointments`);
        }

        let params = new HttpParams();
        if (statusList !== null) {
            statusList.forEach((status) => {
                params = params.append('status', status);
            });
        }
        if (topicInternalName !== null) {
            params = params.append('topicInternalName', topicInternalName);
        }

        return this.httpClient.get<CoachingAppointmentVM[]>(
            `${this.BASE_LIVE_SERVICES_COACHING_MEMBERS_URI}/${member.id}/appointments`, { params });
    }

    getAvailableTimeslots(topicId: number, coachId: number | undefined, start: Date, end: Date, locationSFId: string | null, transformProgram: string | null, programMemberId: number | null, duration: string | null):
        Observable<AppointmentSlotVM[]> {
        const headers = new HttpHeaders();
        let params = new HttpParams({
            fromObject: {
                startOfTimeWindow: start.toISOString(),
                endOfTimeWindow: end.toISOString()
            }
        });

        if (topicId) {
            params = params.append('topicId', String(topicId));
        }

        if (coachId) {
            params = params.append('coachId', String(coachId));
        }

        if (locationSFId) {
            params = params.append('locationSFId', locationSFId);
        }

        if (transformProgram) {
            params = params.append('transformProgram', transformProgram);
        }

        if (programMemberId) {
            params = params.append('programMemberId', String(programMemberId));
        }

        if (duration) {
            params = params.append('duration', duration);
        }

        return this.httpClient.get<AppointmentSlotVM[]>(
            `${this.BASE_LIVE_SERVICES_COACHING_APPOINTMENTS_URI}/available-slots`, { headers, params }
        );
    }

    bookAppointment(scheduleAppointmentRequestVM: ScheduleAppointmentRequestVM) {
        return this.httpClient.post<CoachingAppointmentVM>(
            `${this.BASE_LIVE_SERVICES_COACHING_MEMBERS_URI}/${scheduleAppointmentRequestVM.memberId}/appointments`,
            scheduleAppointmentRequestVM
        );
    }

    cancelAppointment(appointmentId: number) {
        return this.httpClient.delete(`${this.BASE_LIVE_SERVICES_COACHING_APPOINTMENTS_URI}/${appointmentId}`);
    }

    getAppointmentByAppointmentId(appointmentId: number): Observable<CoachingAppointmentVM> {
        return this.httpClient.get<CoachingAppointmentVM>(
            `${this.BASE_LIVE_SERVICES_COACHING_APPOINTMENTS_URI}/${appointmentId}`, { headers: { 'Skip-Redirect': 'true' } });
    }

    getCompletedAppointments(topicInternalName: string, member: Member): Observable<CoachingAppointmentVM[]> {
        return this.getMemberAppointments(member, [LiveServicesCoachingStatus.Completed], topicInternalName);
    }

    isActive(status: LiveServicesCoachingStatus): boolean {
        return this.ACTIVE_STATUSES.includes(status);
    }

    getUpcomingAppointments(appointments: CoachingAppointmentVM[]): CoachingAppointmentVM[] {
        const filteredAppointments = appointments.
            filter((a) => a.status !== LiveServicesCoachingStatus.Canceled && this.timeZoneUtil.isNowOrAfter(a.endTime));
        return filteredAppointments.sort((a, b) => DateUtils.diff(a.startTime, b.startTime));
    }

    getScheduledAppointments(appointments: CoachingAppointmentVM[]) {
        const filteredAppointments = appointments.filter((appointment) =>
            appointment.status === LiveServicesCoachingStatus.Scheduled ||
            appointment.status === LiveServicesCoachingStatus.Processing ||
            appointment.status === LiveServicesCoachingStatus.InProgress);
        return filteredAppointments.sort((a, b) => DateUtils.diff(a.startTime, b.startTime));
    }

    getPastAppointments(appointments: CoachingAppointmentVM[]): CoachingAppointmentVM[] {
        const filteredAppointments = appointments.
            filter((a) => a.status === LiveServicesCoachingStatus.Canceled || this.timeZoneUtil.isBefore(a.endTime));
        return filteredAppointments.sort((a, b) => DateUtils.diff(b.startTime, a.startTime));
    }

    getPastTelephonicAppointments(appointments: CoachingAppointmentVM[]): CoachingAppointmentVM[] {
        const filteredAppointments = appointments.
            filter((a) => (a.status === LiveServicesCoachingStatus.Canceled || this.timeZoneUtil.isBefore(a.endTime))
            && a.appointmentType !== AppointmentType.Messaging);
        return filteredAppointments.sort((a, b) => DateUtils.diff(b.startTime, a.startTime));
    }

    filterCompletedAppointments(appointments: CoachingAppointmentVM[]): CoachingAppointmentVM[] {
        const filteredAppointments = appointments.filter((a) => a.status === LiveServicesCoachingStatus.Completed);
        return filteredAppointments.sort((a, b) => DateUtils.diff(b.startTime, a.startTime));
    }

    hasScheduledAppointments(appointments: CoachingAppointmentVM[]): boolean {
        const filteredAppointments = appointments.filter((appointment) =>
            appointment.status === LiveServicesCoachingStatus.Scheduled ||
            appointment.status === LiveServicesCoachingStatus.Processing ||
            appointment.status === LiveServicesCoachingStatus.InProgress);

        return filteredAppointments.length > 0;
    }

    hasPastAppointments(appointments: CoachingAppointmentVM[]): boolean {
        if (appointments == null || appointments.length === 0) {
            return false;
        }

        const pastAppointments = this.getPastAppointments(appointments);

        return pastAppointments.length > 0;
    }

    filterTransformCoachingPackage(packageList: string[]): string[] {
        return packageList.filter((packageName) => packageName !== 'Transform');
    }

    filterTransformAndNSCCoachingPackages(packageList: string[]): string[] {
        return packageList.filter((packageName) => packageName !== 'Transform' && packageName !== SingleTopicType.NextStepsConsult);
    }

    formattedTimes(appointment: CoachingAppointmentVM): string {
        const start = this.timeZoneUtil.formatEnglish(appointment.startTime, 'h:mm A');
        const end = this.timeZoneUtil.formatEnglish(appointment.endTime, 'h:mm A z');
        return `${start} to ${end}`;
    }
}
