declare const System: any;

function retry<T>(action: () => T, delay: number = 25, maxRetryCount: number = 50): Promise<T> {
    return new Promise((resolve, reject) => {

        let retryCount = 0;
        let nextDelay = delay;

        const retryHandler = () => {
            try {
                resolve(action());
            } catch (e) {
                retryCount++;
                if (retryCount > maxRetryCount) {
                    reject(e);
                } else {
                    setTimeout(retryHandler, nextDelay);
                    nextDelay += 5;
                }
            }
        };
        retryHandler();
    });
}

export function getResourceUrl(): Promise<string> {
    return retry(() => System.resolve('resources') as string);
}
