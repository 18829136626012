import { GenesisClientAdminState } from './state.model';
import {
    ADD_CONFIGURATION_FOR_CLIENT
} from './store.action';

export const genesisClientAdminStateInitial: GenesisClientAdminState = {
    configuration: {}
};

export const genesisClientAdminStateReducer = (state: GenesisClientAdminState = genesisClientAdminStateInitial, action: any) => {
    switch (action.type) {

    case ADD_CONFIGURATION_FOR_CLIENT: {
        const configuration = action.payload.configuration;
        return {
            ...state,
            configuration
        };
    }

    default: {
        return state;
    }
    }
};
