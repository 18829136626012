import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DateUtils } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'onboarding-flipclock',
    templateUrl: './onboarding-flipclock.component.html',
    styleUrls: ['./onboarding-flipclock.scss']
})
export class OnboardingFlipClockComponent {
    @Input() challengeStartDate!: string;
    @Input() showSeconds!: boolean;
    @Output() challengeStarted = new EventEmitter<boolean>();
    currentDate = new Date();
    date: any;
    dateDifference!: number;
    challengeStartedEmitTimeoutLimit = 1728000000; // 20 * 24 * 60 * 60 * 1000

    ngOnInit() {
        this.challengeCountdown();
    }

    challengeCountdown() {
        if (DateUtils.isAfter(this.currentDate, this.challengeStartDate)) {
            this.dateDifference = 0;
            this.date = new Date();
        } else {
            this.date = DateUtils.convertStringToDate(this.challengeStartDate);

            this.dateDifference = Math.min(
                DateUtils.duration(
                    DateUtils.diff(this.date, this.currentDate),
                    'asMilliseconds'
                ),
                this.challengeStartedEmitTimeoutLimit
            );

            this.date = new Date(this.date);
        }
        this.setChallengeStartedTimeout();
    }

    setChallengeStartedTimeout() {
        setTimeout(() => {
            this.challengeStarted.emit(true);
        }, this.dateDifference);
    }
}
