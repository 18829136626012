import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'href' })
export class HrefPipe implements PipeTransform {
    HREF_PREFIX = '#/';
    ROUTER_LINK_PREFIX = '/';

    transform(url: string, routerLink = false): string {
        if (routerLink) {
            return this.ROUTER_LINK_PREFIX + url;
        }
        return this.HREF_PREFIX + url;
    }
}
