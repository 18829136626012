import { Injectable } from '@angular/core';

import { GlobalChallengesOnboardingBlockerModal } from './global-challenges-onboarding-blocker.component';
import { BootstrapModalWrapperService } from '@genesis-frontend/genesis-utilities';

@Injectable()
export class GlobalChallengesOnboardingBlockerModalService {
    constructor(
        private modalService: BootstrapModalWrapperService
    ) { }

    open(contestId: number, memberId: number) {
        this.modalService.showInBlocker(GlobalChallengesOnboardingBlockerModal, {
            backdrop: false,
            keyboard: false,
            ignoreBackdropClick: true,
            show: true,
            animated: false,
            class: 'challenge-onboarding-blocker-modal modal-md',
            initialState: {
                contestId,
                memberId
            }
        });
    }
}
