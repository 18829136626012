import { ModuleWithProviders, NgModule } from '@angular/core';

import { CommonData } from './common-data';
import { CurrentUser } from './current-user';
import { ShellInitializationService } from './shell-initialization.service';
import { ShellRouter } from './shell-router';
import { SponsorService, SponsorChangeDetection } from '../client/services/sponsor.service';
import { UnleashFlagsService } from '../services/unleash-flags.service';

@NgModule({
    providers: [
        { provide: CommonData, useExisting: CommonData.Token },
        { provide: ShellRouter, useExisting: ShellRouter.Token },
        { provide: CurrentUser, useExisting: CurrentUser.Token },
        { provide: UnleashFlagsService, useExisting: UnleashFlagsService.Token },
        { provide: SponsorService, useExisting: SponsorService.Token },
        SponsorChangeDetection
    ]
})
export class ShellModule {

    static forShell(): ModuleWithProviders<ShellModule> {
        return {
            ngModule: ShellModule,
            providers: [
                CommonData,
                ShellRouter,
                CurrentUser,
                ShellInitializationService,
                UnleashFlagsService,
                SponsorService
            ]
        };
    }
}
