import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[wcagClick]',
    host: { tabindex: '0' }
})
export class WcagClickDirective {

    @Input('wcagClick') wcagClickFn: any;

    @HostListener('keyup.enter', ['$event'])
    @HostListener('keyup.leftArrow', ['$event'])
    @HostListener('keyup.rightArrow', ['$event'])
    @HostListener('click', ['$event'])
    onEvent() {
        this.wcagClickFn();
    }
}
