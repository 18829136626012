import { Component, Input } from '@angular/core';

import { GoalsVM } from '../../models/goals-vm.model';

@Component({
    selector: 'genesis-frontend-goals',
    templateUrl: './goals.component.html',
    styleUrls: ['./goals.component.scss']
})
export class GoalsComponent {
    @Input() goal!: GoalsVM;
    @Input() content = '';
}
