import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { BubbleCelebrationComponent } from '@genesis-frontend/genesis-features';
import { WcagService, ColorsService } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'trophy-modal',
    templateUrl: './trophy-modal.component.html'
})
export class TrophyModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() trophies: any = [];
    @ViewChild('bubbleCelebrationComponent') bubbleCelebrationComponent!: BubbleCelebrationComponent;
    timeLapse = 2000;
    celebrationWidth = 900;
    celebrationHeight = 600;
    containerHeight = 375;
    containerWidth = 530;
    celebrationParticleCount = 50;
    leftMargin = 17;
    topMargin = -22;
    celebrationColors: string[] = [];
    slickSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: true
    };
    hover = false;

    constructor(
        private bsModalRef: BsModalRef,
        private colorsService: ColorsService,
        private elementRef: ElementRef,
        private wcagService: WcagService
    ) {}

    ngOnInit() {
        this.celebrationColors = this.colorsService.getBlueCelebrationBubbleColors();
        this.elementRef.nativeElement.addEventListener('keydown', this.focusTrap);
    }

    ngAfterViewInit() {
        this.bubbleCelebrationComponent.animate();
        const closeButton = document.getElementById('trophy-modal-close-btn');
        if (closeButton) {
            closeButton.focus();
        }
    }

    ngOnDestroy() {
        this.elementRef.nativeElement.removeEventListener('keydown', this.focusTrap);
    }

    focusTrap = (event: KeyboardEvent) => {
        this.wcagService.trapFocusInsideElementsContainer(event, this.elementRef, '');
    };

    closeModal() {
        this.bsModalRef.hide();
    }
}
