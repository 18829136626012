export enum ChallengesNavigationSourceType {
  HealthyHabits = 'HealthyHabits',
  PillarsAndTopics = 'PillarsAndTopics',
  ChallengesDashboard = 'ChallengesDashboard'
}

export interface ChallengesNavigationSource {
  id: ChallengesNavigationSourceType,
  pillarId?: number,
  pillarTopicId?: number
}

