import { Component } from '@angular/core';

@Component({
    selector: 'genesis-frontend-empty-route',
    templateUrl: './empty-route.component.html',
    styleUrls: ['./empty-route.component.scss']
})
export class EmptyRouteComponent {

    constructor() { }

}
