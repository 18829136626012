import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { DialogRef, DialogService } from '@engineering/vpgroove-angular';

@Injectable({
    providedIn: 'root'
})
export class BootstrapModalWrapperService {

    constructor(private dialogService: DialogService, private zone: NgZone) { }

    show(modalComponent: any, options: any): Observable<any> {
        const responseSubject = new Subject<any>();
        this.openModal(modalComponent, options)
            .subscribe((response) => {
                responseSubject.next(response);
                this.dialogService.confirm().close();
            });

        return responseSubject.asObservable();
    }

    openModal(modalComponent: any, options: any): DialogRef {
        return this.dialogService.open(modalComponent, {
            props: { ...options?.initialState }
        });
    }

    /*
     * Ensures that the method is executed within Angular's NgZone,
     * which helps with change detection. This is useful when you need to
     * show a modal component and ensure that the UI updates correctly,
     * even if the operation triggering the modal (such as a promise) is
     * still pending.
     */
    showInBlocker(modalComponent: any, options: any): DialogRef {
        return this.zone.run(() =>
            this.openModal(modalComponent, options)
        );
    }

    closeModal(dialogRef: DialogRef): void {
        this.zone.run(() =>
            dialogRef?.close()
        );
    }
}
