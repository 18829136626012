import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
// @ts-ignore
import brightcovePlayerLoader from '@brightcove/player-loader';

import {
    AnalyticsTrackingAction,
    AnalyticsTrackingCommandsService,
    AuthenticatedMemberService,
    MediaActivityType,
    MediaActivityTypeMap,
    MediaDetailEvent,
    MediaService,
    MemberMedia,
    MemberMediaStatus,
    VideoRatioEnum
} from '@genesis-frontend/genesis-utilities';


@Component({
    selector: 'brightcove-media-player',
    templateUrl: './brightcove-media-player.template.html',
    styleUrls: ['./brightcove-media-player.component.scss']
})
export class BrightcoveMediaPlayer implements OnInit, AfterViewInit, OnDestroy {

    @Input() videoData?: any;
    @Input() selectedSortFilter: string | undefined;
    @Input() selectedTopicId: number | undefined;
    @Input() saveProgress = true;
    @Input() disableRewards = false;
    @Input() analyticsData = true;
    @Input() autoPlay = true;
    @Input() customHeight?: number;
    @Input() viewMode = VideoRatioEnum.Landscape;
    @Output() onVideoPlayed = new EventEmitter();

    @ViewChild('brightcove') brightcovePlayer: ElementRef | undefined;
    @ViewChild('videoContainerElement') videoContainerElement: ElementRef | undefined;

    VIDEO_PERCENTAGE_BUFFER = 4;
    REWARD_TRIGGER_PERCENTAGE = 70;

    memberId = 0;
    errorHeader = 'VideoCurrentlyUnavailable';
    errorDescription = 'WorkingToFix';
    memberMedia: MemberMedia | undefined;
    bcPlayer: any;
    videoStarted = false;
    hasError = false;
    loading = true;
    navigatedFromPage = false;

    aspectRatioHeight?: number;
    readonly LANDSCAPE_RATIO_DIVIDER = 1.775;
    readonly VERTICAL_RATIO_DIVIDER = 0.562;

    mediaActivityTypeMap = new Map<number, MediaActivityTypeMap> ([
        [1, { type: MediaActivityType.MediaViewed, status: MemberMediaStatus.InProgress, isCalled: false }],
        [25, { type: MediaActivityType.MediaEngagement25, status: MemberMediaStatus.InProgress, isCalled: false }],
        [50, { type: MediaActivityType.MediaEngagement50, status: MemberMediaStatus.InProgress, isCalled: false }],
        [70, { type: MediaActivityType.MediaEngagement70, status: MemberMediaStatus.InProgress, isCalled: false }],
        [75, { type: MediaActivityType.MediaEngagement75, status: MemberMediaStatus.InProgress, isCalled: false }],
        [100, { type: MediaActivityType.MediaEngagement100, status: MemberMediaStatus.Completed, isCalled: false }]
    ]);
    videoPercentageKeys: number[] = [];

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private analyticsService: AnalyticsTrackingCommandsService,
        private mediaService: MediaService,
        private changeDetectorRef: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.memberId = this.authenticatedMemberService.getAuthenticatedMemberId();
        this.videoPercentageKeys = Array.of(...this.mediaActivityTypeMap.keys());
        window.onbeforeunload = () => this.ngOnDestroy();
    }

    ngAfterViewInit() {
        this.loadBrightcoveVideo();
        this.setVideoHeight();
        this.changeDetectorRef.detectChanges();
    }

    ngOnDestroy() {
        this.navigatedFromPage = true;

        if (!this.bcPlayer) {
            return;
        }

        this.bcPlayer.pause();
        const currentVideoTime = this.bcPlayer.currentTime() * 1000;

        if (!this.memberMedia || currentVideoTime === 0) {
            return;
        }

        this.memberMedia.progress = currentVideoTime;
        delete this.memberMedia.activityType;

        if (this.saveProgress) {
            this.mediaService.updateMemberMedia(this.memberId, this.memberMedia).subscribe();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.setVideoHeight();
    }

    private loadBrightcoveVideo() {
        brightcovePlayerLoader({
            refNode: this.brightcovePlayer?.nativeElement,
            accountId: this.videoData.accountId,
            videoId: this.videoData.videoId
        })
            .then((success: any) => {
                this.bcPlayer = success.ref;
                this.bcPlayer.bcAnalytics.client.setUser(this.memberId.toString());
                this.bcPlayer.on('loadedmetadata', this.loadMemberLanguageData.bind(this));
                this.bcPlayer.on('error', this.showErrorMessage.bind(this));
                this.bcPlayer.on('play', this.videoPlayed.bind(this));

                if (this.analyticsData) {
                    this.bcPlayer.on('play', this.trackOnPlayEvent.bind(this));
                    this.bcPlayer.on('play', () => {
                        if (this.navigatedFromPage) {
                            this.bcPlayer.pause();
                        }
                    });
                }

                if (this.saveProgress) {
                    this.bcPlayer.on('loadstart', this.setBrightcoveCurrentTime.bind(this));
                }

                if (!this.disableRewards) {
                    this.bcPlayer.on('timeupdate', this.trackMediaAnalytics.bind(this));
                }

                if (this.autoPlay) {
                    this.bcPlayer.play();
                }
            }).catch((error: any) => {
                this.bcPlayer = error;
            });
    }

    private videoPlayed() {
        this.onVideoPlayed.emit();
    }

    private loadMemberLanguageData() {
        this.loading = false;
        const memberLanguage = this.authenticatedMemberService.getMemberLanguageCode();
        const tracks = this.bcPlayer.textTracks().tracks_;

        tracks.forEach((track: any) => {
            if (track.language === memberLanguage) {
                track.mode = 'showing';
                return;
            }
        });

        const audioTracks = this.bcPlayer.audioTracks().tracks_;
        audioTracks.forEach((track:any) => {
            if (track.language === memberLanguage) {
                track.enabled = true;
                return;
            }
        });
    }

    private trackOnPlayEvent(): void {
        if (this.videoStarted) {
            return;
        }

        this.memberMedia = {
            memberId: this.memberId,
            mediaId: this.videoData.id,
            status: MemberMediaStatus.InProgress
        } as MemberMedia;
        const trackingData: MediaDetailEvent = {
            video_id: this.videoData.id,
            video_name: this.videoData.originalName,
            applied_media_topic_id: this.selectedTopicId === undefined ? null : this.selectedTopicId,
            applied_sort: this.selectedSortFilter,
            premium_content: this.videoData.premiumContent
        };
        this.analyticsService.trackMediaEvent(AnalyticsTrackingAction.MediaVideoStarted, trackingData);
        this.videoStarted = true;
    }

    private trackMediaAnalytics(): void {

        this.checkIfVideoIsReplayed(this.bcPlayer.currentTime());

        const videoPercentageWatched = Math.floor((this.bcPlayer.currentTime() / this.bcPlayer.duration()) * 100);

        if (videoPercentageWatched >= this.REWARD_TRIGGER_PERCENTAGE && !this.disableRewards) {
            this.trackVideoActivityOrReward(this.REWARD_TRIGGER_PERCENTAGE);
        }

        const videoPercentageKeyMatch = this.videoPercentageKeys.find((key: number) => {
            const percentageDifference = videoPercentageWatched - key;
            return percentageDifference >= 0 && percentageDifference <= this.VIDEO_PERCENTAGE_BUFFER;
        });

        if (this.disableRewards || !videoPercentageKeyMatch || videoPercentageKeyMatch === this.REWARD_TRIGGER_PERCENTAGE) {
            return;
        }

        this.trackVideoActivityOrReward(videoPercentageKeyMatch);
    }

    private trackVideoActivityOrReward(videoPercentage: number) {

        const activityType = this.mediaActivityTypeMap.get(videoPercentage);

        if (!this.memberMedia || !activityType || activityType.isCalled) {
            return;
        }

        activityType.isCalled = true;

        this.mediaActivityTypeMap.set(videoPercentage, activityType);

        const memberMediaPayload = this.memberMedia;
        memberMediaPayload.progress = this.bcPlayer.currentTime() * 1000;
        memberMediaPayload.activityType = activityType.type;
        memberMediaPayload.status = activityType.status;
        this.mediaService.updateMemberMedia(this.memberId, memberMediaPayload).subscribe();
    }

    private checkIfVideoIsReplayed(currentVideoTime: number) {
        if (currentVideoTime > 1) {
            return;
        }
        this.mediaActivityTypeMap.forEach((activityType: MediaActivityTypeMap) => {
            activityType.isCalled = false;
        });
    }

    private showErrorMessage(): void {
        this.hasError = true;
        this.loading = false;
    }

    private setBrightcoveCurrentTime(): void {
        if (this.videoData.status === 'Completed') {
            this.bcPlayer.currentTime(0);
            return;
        }
        this.bcPlayer.currentTime(this.videoData.memberProgress / 1000);
    }

    private setVideoHeight() {
        if (this.customHeight) {
            this.aspectRatioHeight = this.customHeight;
            return;
        }

        const elementInfo = this.videoContainerElement?.nativeElement.getBoundingClientRect();

        if (this.viewMode === VideoRatioEnum.Vertical) {
            this.aspectRatioHeight = elementInfo.width / this.VERTICAL_RATIO_DIVIDER;
            return;
        }

        this.aspectRatioHeight = elementInfo.width / this.LANDSCAPE_RATIO_DIVIDER;
    }
}
