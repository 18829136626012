import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SocialGroupApiService {

    constructor(private httpClient: HttpClient) { }

    acceptGroupInvite(memberId: number, groupId: number): Observable<any> {
        return this.httpClient.post(this.socialGroupInvitesURI(memberId, groupId) + '/accept', null);
    }

    declineGroupInvite(memberId: number, groupId: number): Observable<any> {
        return this.httpClient.post(this.socialGroupInvitesURI(memberId, groupId) + '/decline', null);
    }

    private socialGroupInvitesURI = (memberId: number, groupId: number) =>
        `api/members/${memberId}/social-groups/${groupId}/invites`;
}
