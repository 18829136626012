import { Pipe, PipeTransform } from '@angular/core';

import { IntervalType } from '../models/enums/interval-type.enum';

@Pipe({ name: 'rewardInterval' })
export class RewardIntervalPipe implements PipeTransform {
    ONE_TIME_INTERVAL = 'One Time';

    transform(interval: string): string {
        if (interval === IntervalType.OneTime) {
            return this.ONE_TIME_INTERVAL;
        }
        return interval;
    }
}
