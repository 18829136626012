import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MeasurementsService {
    constructor(
        private httpClient: HttpClient
    ) { }

    getUnitOfMeasurements(): Observable<any> {
        return this.httpClient.get<any>('/api/unit-of-measurements');
    }
}
