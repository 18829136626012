import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { MemberAgreement, KeyCode } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'genesis-frontend-agreement-modal',
    templateUrl: './agreement-modal.component.html',
    styleUrls: ['./agreement-modal.component.scss']
})
export class AgreementModalComponent {
    @Input() agreement: MemberAgreement = {} as MemberAgreement;
    @ViewChild('closeButton') closeButton: ElementRef = new ElementRef<any>({});
    @ViewChild('closeButtonFirst') closeButtonFirst: ElementRef = new ElementRef<any>({});
    ANIMATION_TIMEOUT = 500;

    constructor(
        private bsModalRef: BsModalRef
    ) { }

    ngAfterViewInit() {
        if (this.closeButtonFirst) {
            setTimeout(() => {
                this.closeButtonFirst.nativeElement.focus();
            }, this.ANIMATION_TIMEOUT);
        }
    }

    closeModal() {
        this.bsModalRef.hide();
    }

    manageFocusCloseButtons(event: any): void {
        if (event.keyCode === KeyCode.enter || event.keyCode === KeyCode.spacebar) {
            event.preventDefault();
            setTimeout(() => {
                event.stopPropagation();
                this.closeModal();
            });
            return;
        }

        const isCloseButton = document.activeElement === this.closeButton.nativeElement;
        const isTabKeyCode = event.keyCode === KeyCode.tab && !event.shiftKey;
        if (isCloseButton && isTabKeyCode) {
            this.closeButtonFirst.nativeElement.focus();
            event.preventDefault();
        }
    }
}
