import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from '@environments/environment';
import { FilestackService } from '@filestack/angular';
import Player from '@vimeo/player';
import mixpanel from 'mixpanel-browser';
import { BsModalRef, ModalModule, BsModalService } from 'ngx-bootstrap/modal';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { TrustarcComponent } from './components/trustarc/trustarc.component';
import { AppInitService } from './core/services/app-init.service';
import { AuthLayoutComponent } from './layouts/auth-layout.component';
import { BasicLayoutComponent } from './layouts/basic-layout.component';
import { MobileAuthLayoutComponent } from './layouts/mobile-auth-layout.component';
import {
    LogoutWarningService,
    SharedStateService,
    ShellLocalizationProvider
} from '@app/core/services';
import { VpgrooveModule } from '@engineering/vpgroove-angular';
import { LoaderModule, NotificationsModule, JourneysCompletedModalModule } from '@genesis-frontend/genesis-features';
import {
    GenesisDirectivesModule,
    GlobalReduxStore,
    LocalizationProvider,
    StoreNames,
    TranslateLoaderModule,
    GenesisAuthenticationModule,
    Environment,
    ENVIRONMENT,
    SharedServiceLocatorModule,
    EventBusModule,
    ShellApplication,
    CustomEventHandlerService,
    RewardsAndSupporterCheckerService,
    ShellModule,
    Angular2PreviewToolRequestInterceptor
} from '@genesis-frontend/genesis-utilities';

Environment.setEnvironment(environment);

(window as any).Vimeo = { Player };

@NgModule({
    declarations: [AppComponent, AuthLayoutComponent, BasicLayoutComponent, MobileAuthLayoutComponent, TrustarcComponent],
    imports: [
        BrowserModule,
        CommonModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ModalModule.forRoot(),
        TranslateLoaderModule,
        GenesisDirectivesModule,
        GenesisAuthenticationModule.forShell(),
        ShellModule.forShell(),
        LoaderModule,
        ComponentsModule,
        NotificationsModule,
        SharedServiceLocatorModule,
        JourneysCompletedModalModule,
        EventBusModule,
        VpgrooveModule
    ],
    providers: [
        { provide: 'Window', useValue: window },
        { provide: 'mixpanel', useValue: mixpanel },
        {
            provide: ENVIRONMENT,
            useValue: Environment.fromGlobal()
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (service: AppInitService) => service.init(),
            deps: [AppInitService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Angular2PreviewToolRequestInterceptor,
            multi: true
        },
        { provide: LocalizationProvider, useClass: ShellLocalizationProvider },
        GlobalReduxStore,
        ShellApplication,
        FilestackService,
        BsModalRef,
        BsModalService,
        AppInitService,
        RewardsAndSupporterCheckerService,
        SharedStateService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(
        private globalReduxStore: GlobalReduxStore,
        private customEventHandlerService: CustomEventHandlerService,
        private logoutWarningService: LogoutWarningService
    ) {
        this.logoutWarningService.start();

        const isDebugModeActive = !environment.production;
        this.globalReduxStore.initReduxStore(StoreNames.GENESIS_UI, isDebugModeActive);

        this.customEventHandlerService.initialize();
    }
}
