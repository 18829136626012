export const RouteOptions = {
    homeRouteOptions: {
        module: 'homepage-ui',
        matcher: '^#/home'
    },
    socialRouteOptions: {
        module: 'social-ui',
        matcher: '^#/social|#/allfriends|#/addfriends|#/invitefriends|#/friends|#/steps-leaderboard|#/recognition|#/groups|#/partnerinvite'
    },
    memberRouteOptions: {
        module: 'member-profile',
        matcher: '^#/member-profile'
    },
    benefitsRouteOptions: {
        module: 'benefits',
        matcher: '^#/benefits'
    },
    rewardsRouteOptions: {
        module: 'rewards-ui',
        matcher: '^#/my-rewards'
    },
    coachesCornerRouteOptions: {
        module: 'coaches-corner-ui',
        matcher: '^#/coaches-corner'
    },
    guidesRouteOptions: {
        module: 'guides-ui',
        matcher: '^#/guide|#/mobile/guide'
    },
    healthRouteOptions: {
        module: 'health-ui',
        matcher: '^#/health-ui|/#health-ui/my-care-checklist|/#health-ui/situations'
    },
    mediaRouteOptions: {
        module: 'media-ui',
        matcher: '^#/media'
    },
    liveServiceRouteOptions: {
        module: 'live-services-ui',
        matcher: '^#/live-services-coaching|#/live-services-guides'
    },
    journeysRouteOptions: {
        module: 'journeys-ui',
        matcher: '^#/journeys'
    },
    healthyHabitsRouteOptions: {
        module: 'healthy-habits',
        matcher: '^#/healthyhabits'
    },
    calendarRouteOptions: {
        module: 'calendar-ui',
        matcher: '^#/calendar'
    },
    newsFlashRouteOptions: {
        module: 'newsflash-ui',
        matcher: '^#/newsFlash'
    },
    surveysRouteOptions: {
        module: 'surveys-ui',
        matcher: '^#/surveys-ui'
    },
    pillarsRouteOptions: {
        module: 'pillars-and-topics-ui',
        matcher: '^#/pillars'
    },
    transformRouteOptions: {
        module: 'transform-ui',
        matcher: '^#/transform'
    },
    newMiniAppsRouteOptions: {
        module: 'new-mini-apps',
        matcher: '^#/welcome.html|^#/logout.html|^#/enrollmentSearch.html'
    },
    webchatRouteOptions: {
        module: 'webchat-ui',
        matcher: '^#/webchat'
    },
    challenges: {
        module: 'challenges-ui',
        matcher: '^#/challenges|#/featuredchallenge|#/goal-challenges|#/holistic-challenges'
    },
    tpaResourcesRouteOptions: {
        module: 'tpa-resources',
        matcher: '^#/tpa-resources'
    },
    statsPage: {
        module: 'stats-page',
        matcher: '^#/stats-page'
    },
    messageCenterRouteOptions: {
        module: 'message-center',
        matcher: '^#/message-center'
    },
    devicesAndAppsRouteOptions: {
        module: 'devices-and-apps',
        matcher: '^#/devices|#/mobile/devices'
    }
};
