import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ClaimsCardComponent } from './claims-card.component';
import { BaseCardComponent } from './components/base-card/base-card.component';
import { ClaimComponent } from './components/claim/claim.component';
import { ErrorComponent } from './components/error-state/error.component';
import { LoaderComponent } from './components/loader/loader.component';
import { OptInCardComponent } from './components/opt-in/opt-in-card.component';
import { ClaimsCardService } from './services/claims-card.service';
import { VpgrooveModule } from '@engineering/vpgroove-angular';
import { GenesisPipesModule } from '@genesis-frontend/genesis-utilities';

@NgModule({
    declarations: [
        ClaimsCardComponent,
        ErrorComponent,
        LoaderComponent,
        BaseCardComponent,
        OptInCardComponent,
        ClaimComponent
    ],
    exports: [
        ClaimsCardComponent,
        ErrorComponent,
        LoaderComponent,
        BaseCardComponent,
        OptInCardComponent,
        ClaimComponent
    ],
    imports: [
        CommonModule,
        VpgrooveModule,
        TranslateModule,
        GenesisPipesModule
    ],
    providers: [
        ClaimsCardService
    ]
})
export class ClaimsCardModule { }
