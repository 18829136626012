import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';

const CLOCK_SKEW = 15; // Buffer on when your last request is considered expired
const AUTO_LOGOUT_MINUTES = 15;
const LOGOUT_WARNING_SECONDS = 60;

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedTimeoutService {

    constructor() {
        window.addEventListener('customResetLastRequestEvent', () => {
            this.resetLastRequest();
        });
    }

    timeoutThreshold = this.getTimeoutThreshold();

    getTimeoutThreshold() {
        return moment.utc()
            .add(AUTO_LOGOUT_MINUTES, 'minutes')
            .subtract(LOGOUT_WARNING_SECONDS, 'seconds')
            .subtract(CLOCK_SKEW, 'seconds');
    }

    lastRequestExpired() {
        return moment.utc().isSameOrAfter(this.timeoutThreshold);
    }

    resetLastRequest() {
        this.timeoutThreshold = this.getTimeoutThreshold();
    }
}
