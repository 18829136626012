import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[wcagCTA]'
})
export class WcagCTADirective {

    @Input('wcagCTA') wcagClickFn: any;

    @HostListener('keyup.enter', ['$event'])
    @HostListener('keyup.space', ['$event'])
    @HostListener('click', ['$event'])
    onEvent() {
        this.wcagClickFn();
    }
}
