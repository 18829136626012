import { Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { Config } from 'pusher-js/types/src/core/config';

import { ConfigurationService } from './configuration.service';
import { AuthenticatedMemberService } from '../authentication';


@Injectable({
    providedIn: 'root'
})
export class WebsocketService {
    pusher: Pusher;

    constructor(private authenticatedMemberService: AuthenticatedMemberService, private configurationService: ConfigurationService) {
        const authClient = this.authenticatedMemberService.getAuthClient();
        const pusherConfig = this.configurationService.getPusher();
        let pusherKey = pusherConfig.key;
        let pusherCluster = pusherConfig.cluster;

        if (pusherConfig.config.wsHost === 'undefined') {
            if (authClient && authClient.token) {
                if (authClient.tokenParsed && authClient.tokenParsed.region === 'EU1') {
                    pusherKey = pusherConfig.keyEU;
                    pusherCluster = pusherConfig.clusterEU;
                }

                this.pusher = new Pusher(pusherKey, {
                    cluster: pusherCluster,
                    authEndpoint: '/api/notifications/auth',
                    auth: {
                        headers: {
                            Authorization: 'Bearer ' + authClient.token
                        }
                    }
                });
            } else {
                this.pusher = new Pusher(pusherKey, {
                    cluster: pusherCluster,
                    authEndpoint: '/api/notifications/auth'
                });
            }
        } else {
            this.pusher = new Pusher(pusherKey, pusherConfig.config as Config);
        }

        this.connect = this.connect.bind(this);
    }

    bindConnectionState() {
        return {
            stateChange: (connectionState: string, callback: any) => {
                this.pusher.connection.bind(connectionState, (data: any) => {
                    callback(data);
                });
            }
        };
    }

    connect(channelName: string): any {
        const channel = this.pusher.subscribe(channelName);
        let enabled = true;
        const queueOn: any[] = [];
        return {
            on: (eventName: string, callback: any) => {
                channel.bind(eventName, (data: any) => {
                    if (enabled) {
                        callback(data);
                    } else {
                        queueOn.push({ callback, notification: data });
                    }
                });
            },
            emit: (eventName: string, data: any, callback: any) => {
                channel.trigger(eventName, data);
                if (callback) {
                    callback();
                }
            },
            disable: () => {
                enabled = false;
            },
            enable: () => {
                enabled = true;
                while (queueOn.length) {
                    const delayedNotification = queueOn.pop();
                    if (!delayedNotification.callback) {
                        continue;
                    }
                    delayedNotification.callback(delayedNotification.notification);
                }
            },
            unsubscribe: () => {
                this.pusher.unsubscribe(channelName);
            }
        };
    }
}
