import { Inject, Injectable } from '@angular/core';
import { interval, noop } from 'rxjs';

import { ENVIRONMENT, Environment } from '../../config/environment';
import { DateUtils } from '../../lib/date-utils';

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedTimeoutService {

    MILLISECONDS_DELAY = 10000; // every ten seconds check
    lastRequest = DateUtils.toUtcDate();
    logoutCallback = noop;

    constructor(@Inject(ENVIRONMENT) private environment: Environment) {
        window.addEventListener('customResetLastRequestEvent', () => {
            this.resetWatchDog();
        });
    }

    resetWatchDog() {
        this.lastRequest = DateUtils.toUtcDate();
    }

    startWatchDogCheck() {
        interval(this.MILLISECONDS_DELAY).subscribe(() => this.checkWatchDog());
    }

    checkWatchDog() {
        if (DateUtils.isBefore(
            this.lastRequest,
            DateUtils.sub(DateUtils.toUtcDate(), this.environment.autoLogoutMinutes, 'minutes')
        )) {
            this.logoutCallback();
        }
    }

    registerLogoutCallback(callback: any) {
        this.logoutCallback = callback;
    }
}
