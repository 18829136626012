import { Injectable } from '@angular/core';

import { AnalyticsTrackingStatItems,
    AnalyticsTrackingDurationTab,
    AnalyticsTrackingManuallyEnteredStatSource,
    AnalyticsTrackingAction,
    AnalyticsTrackingDateChangeModel,
    AnalyticsTrackingManuallyEnteredStatModel,
    AnalyticsTrackingOldNewTabModel,
    StatType
} from '../../models';
import { AnalyticsTrackingSingleSpaService } from '../analytics-tracking-single-spa.service';

@Injectable({
    providedIn: 'root'
})
export class LandingPageAnalyticsTrackingService {
    dateChangeCollectionDuration = 20000;

    backButtonClicks = 0;
    forwardButtonClicks = 0;
    currentlyTrackingClicks = false;
    trackingSourceFilterEvent = true;

    constructor(
      private analyticsTrackingService: AnalyticsTrackingSingleSpaService
    ) {
        this.analyticsTrackingService.init();
    }


    trackDrilldownPageStatSourceUpdated(statType: StatType | undefined, statSource: string) {
        if (this.trackingSourceFilterEvent) {
            this.trackingSourceFilterEvent = false;
            setTimeout(() => {
                this.analyticsTrackingService.sendData(AnalyticsTrackingAction.LandingPageSourceFilterUpdated,
                    {
                        stats_item: statType?.toLowerCase(),
                        new_source_selection: statSource.toLowerCase()
                    });
                this.trackingSourceFilterEvent = true;
            }, 2000);
        }
    }

    trackDrilldownPageDateBackButtonClick(statType: StatType | undefined) {
        if (!this.currentlyTrackingClicks) {
            setTimeout(() => {
                const trackingData: AnalyticsTrackingDateChangeModel = {
                    stats_item: this.getAnalyticsTrackingStatItem(statType, ''),
                    total_clicks_back: this.backButtonClicks,
                    total_clicks_forward: this.forwardButtonClicks
                };
                this.analyticsTrackingService.sendData(AnalyticsTrackingAction.DrilldownPageDateChange, trackingData);
                this.forwardButtonClicks = 0;
                this.backButtonClicks = 0;
                this.currentlyTrackingClicks = false;
            }, this.dateChangeCollectionDuration);
            this.currentlyTrackingClicks = true;
        }
        this.backButtonClicks++;
    }

    trackDrilldownPageDateForwardButtonClick(statType: StatType | undefined) {
        if (!this.currentlyTrackingClicks) {
            setTimeout(() => {
                const trackingData: AnalyticsTrackingDateChangeModel = {
                    stats_item: this.getAnalyticsTrackingStatItem(statType, ''),
                    total_clicks_back: this.backButtonClicks,
                    total_clicks_forward: this.forwardButtonClicks
                };
                this.analyticsTrackingService.sendData(AnalyticsTrackingAction.DrilldownPageDateChange, trackingData);
                this.forwardButtonClicks = 0;
                this.backButtonClicks = 0;
                this.currentlyTrackingClicks = false;
            }, this.dateChangeCollectionDuration);
            this.currentlyTrackingClicks = true;
        }
        this.forwardButtonClicks++;
    }

    trackDurationTabSelected(statType: StatType | undefined, oldTab: AnalyticsTrackingDurationTab | undefined, newTab: AnalyticsTrackingDurationTab | undefined) {
        const trackingData: AnalyticsTrackingOldNewTabModel = {
            stats_item: this.getAnalyticsTrackingStatItem(statType, ''),
            old_selection: oldTab,
            new_selection: newTab
        };
        if (trackingData.old_selection !== trackingData.new_selection) {
            this.analyticsTrackingService.sendData(AnalyticsTrackingAction.StatDurationTabSelected, trackingData);
        }
    }

    trackStatManuallyEntered(statType: StatType, source: AnalyticsTrackingManuallyEnteredStatSource) {
        const trackingData: AnalyticsTrackingManuallyEnteredStatModel = {
            stats_item: this.getAnalyticsTrackingStatItem(statType, ''),
            stat_report_source: source
        };
        this.analyticsTrackingService.sendData(AnalyticsTrackingAction.StatManuallyEntered, trackingData);
    }

    trackStatCardClicked(statType: StatType, statTitle: string) {
        this.analyticsTrackingService.sendData(AnalyticsTrackingAction.StatCardClicked,
            { stats_item: this.getAnalyticsTrackingStatItem(statType, statTitle) });
    }

    trackStatsDashboardLoaded() {
        this.analyticsTrackingService.sendData(AnalyticsTrackingAction.StatsDashboardLoaded);
    }

    trackDevicesAndAppsConnectionClick() {
        this.analyticsTrackingService.sendData(AnalyticsTrackingAction.DevicesAndAppsButtonClicked);
    }

    trackMissingStepsLinkClick() {
        this.analyticsTrackingService.sendData(AnalyticsTrackingAction.MissingStepsLinkClicked);
    }

    trackBetaButtonClick() {
        this.analyticsTrackingService.sendData(AnalyticsTrackingAction.BetaButtonClick, { new_experience_toggle: 'disabled' });
    }

    trackDateBackButtonClick() {
        if (!this.currentlyTrackingClicks) {
            setTimeout(() => {
                this.analyticsTrackingService.sendData(AnalyticsTrackingAction.LandingPageDateChange, {
                    total_clicks_back: this.backButtonClicks,
                    total_clicks_forward: this.forwardButtonClicks
                });
                this.forwardButtonClicks = 0;
                this.backButtonClicks = 0;
                this.currentlyTrackingClicks = false;
            }, this.dateChangeCollectionDuration);
            this.currentlyTrackingClicks = true;
        }
        this.backButtonClicks++;
    }

    trackDateForwardButtonClick() {
        if (!this.currentlyTrackingClicks) {
            setTimeout(() => {
                this.analyticsTrackingService.sendData(AnalyticsTrackingAction.LandingPageDateChange, {
                    total_clicks_back: this.backButtonClicks,
                    total_clicks_forward: this.forwardButtonClicks
                });
                this.forwardButtonClicks = 0;
                this.backButtonClicks = 0;
                this.currentlyTrackingClicks = false;
            }, this.dateChangeCollectionDuration);
            this.currentlyTrackingClicks = true;
        }
        this.forwardButtonClicks++;
    }

    private getAnalyticsTrackingStatItem(statType: StatType | undefined, statTitle: string) {
        let returnVal = null;
        switch (statType) {
        case StatType.A1C:
            returnVal = AnalyticsTrackingStatItems.A1C;
            break;
        case StatType.ActiveMinutes:
            returnVal = AnalyticsTrackingStatItems.ActiveMinutes;
            break;
        case StatType.BloodPressure:
            returnVal = AnalyticsTrackingStatItems.BloodPressure;
            break;
        case StatType.BodyTemp:
            returnVal = AnalyticsTrackingStatItems.BodyTemp;
            break;
        case StatType.CaloriesBurned:
            returnVal = AnalyticsTrackingStatItems.CaloriesBurned;
            break;
        case StatType.CaloriesConsumed:
            returnVal = AnalyticsTrackingStatItems.CaloriesConsumed;
            break;
        case StatType.Cholesterol:
            returnVal = AnalyticsTrackingStatItems.Cholesterol;
            break;
        case StatType.Glucose:
            returnVal = AnalyticsTrackingStatItems.Glucose;
            break;
        case StatType.MindfulMinutes:
            returnVal = AnalyticsTrackingStatItems.MindfulMinutes;
            break;
        case StatType.Sleep:
            returnVal = AnalyticsTrackingStatItems.Sleep;
            break;
        case StatType.Steps:
            returnVal = AnalyticsTrackingStatItems.Steps;
            break;
        case StatType.Weight:
            returnVal = AnalyticsTrackingStatItems.Weight;
            break;
        case StatType.Workout:
            returnVal = AnalyticsTrackingStatItems.Workout;
            break;
        case StatType.WaistHipSize:
            returnVal = AnalyticsTrackingStatItems.WaistSize;
            if (statTitle === 'HipLabel') {
                returnVal = AnalyticsTrackingStatItems.HipSize;
            }
            break;
        }
        return returnVal;
    }
}
