import { HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PreviewParameters } from '../models';

@Injectable({
    providedIn: 'root'
})
export class Angular2PreviewToolRequestInterceptor implements HttpInterceptor {
    previewParams: PreviewParameters | null = null;

    constructor(private activatedRoute: ActivatedRoute) {
        this.activatedRoute.queryParams.subscribe((params) => {
            this.previewParams = new PreviewParameters(
                params['currentPage'],
                params['gameId'],
                params['isRewardsPreview'],
                params['populationId'],
                params['previewDate'],
                params['sponsorId']
            );
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        let reqConfig = {};
        if (this.previewParams && this.previewParams.isRewardsPreview) {
            reqConfig = {
                headers: req.headers.set('is-preview', 'true'),
                params: this.getPreviewParams(req, this.previewParams)
            };
        }
        const modifiedRequest = req.clone(reqConfig);
        return next.handle(modifiedRequest);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getPreviewParams(req: HttpRequest<any>, previewParams: PreviewParameters): HttpParams {
        let params = req.params;
        if (!req.params.has('gameId')) {
            params = params.append('gameId', `${previewParams.gameId}`);
        }
        if (!req.params.has('sponsorId')) {
            params = params.append('sponsorId', `${previewParams.sponsorId}`);
        }
        if (!req.params.has('rewardPopulationId') && previewParams.populationId) {
            params = params.append('rewardPopulationId', `${previewParams.populationId}`);
        }
        if (!req.params.has('previewDate')) {
            params = params.append('previewDate', `${previewParams.previewDate}`);
        }
        return params;
    }
}
