import { Pipe, PipeTransform } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Pipe({ name: 'translateBoldArgs' })
export class TranslateBoldArgsPipe extends TranslatePipe implements PipeTransform {
    // eslint-disable-next-line @typescript-eslint/ban-types
    transform(value: string, args: object): string {
        const newArgs = {};
        for (const key in args) {
            // @ts-ignore
            newArgs[key] = `<b>${args[key]}</b>`;
        }
        return super.transform(value, newArgs);
    }
}
