import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';

import { SingleSpaConfiguration, UnleashFlagsService, UnleashFlag } from '@genesis-frontend/genesis-utilities';

@Injectable({ providedIn: 'root' })
export class DownloadSyncResolverService implements Resolve<SingleSpaConfiguration> {
    constructor(
        private unleashFlagsService: UnleashFlagsService,
        private router: Router
    ) {}
    resolve(): any {
        if (this.unleashFlagsService.hasUnleashFlag(UnleashFlag.BrandingUpdatesPhase2)) {
            this.router.navigateByUrl('devices/download-sync');
        }
        return {
            appName: 'download-sync-app',
            appRegex: /^#(\/download-sync)/,
            isStandAloneApp: false,
            appImportPath: 'genesis-ui'
        } as SingleSpaConfiguration;
    }
}
