import { CacheInterface, CacheItem } from './cache';

export class ScopedLocalStorage<T = any> implements CacheInterface<T> {

    constructor(private scope: string) { }

    getItem(key: string): CacheItem<T> | null {
        const scopedKey = this.getScopedKey(key);
        const value = localStorage.getItem(scopedKey);
        const item = this.parseJson(value);

        if (item && ('time' in item) && ('value' in item) && (item.value != null)) {
            return item;
        }
        return null;
    }

    hasItem(key: string): boolean {
        const item = this.getItem(key);
        return (item != null) && (item.time > 0) && (item.value != null);
    }

    setItem(key: string, value: T): void {
        const scopedKey = this.getScopedKey(key);

        const item: CacheItem<T> = {
            time: (new Date()).getTime(),
            value: value
        };

        if (value != null) {
            localStorage.setItem(scopedKey, JSON.stringify(item));
        } else {
            localStorage.removeItem(scopedKey);
        }
    }

    removeItem(key: string): void {
        const scopedKey = this.getScopedKey(key);
        localStorage.removeItem(scopedKey);
    }

    private getScopedKey(key: string): string {
        return `${this.scope}:${key}`;
    }

    private parseJson(value: any): CacheItem<T> | null {
        if (typeof value !== 'string') {
            return null;
        }
        try {
            return JSON.parse(value);
        } catch (e) {
            return null;
        }
    }
}
