export interface MedicalPlanAccumulatorPayload {
    data: MedicalPlanAccumulator;
    createdDate: Date;
}

export interface MedicalPlanAccumulator {
    accumulators: MedicalPlanAccumulatorNetwork;
}

export interface MedicalPlanAccumulatorNetwork {
    deductible: MedicalPlanAccumulatorDeductible;
    outOfPocket: MedicalPlanAccumulatorTier;
}

export interface MedicalPlanAccumulatorDeductible {
    tiers: MedicalPlanAccumulatorTier[];
    num_tiers: number;
}

export interface MedicalPlanAccumulatorTier {
    family: MedicalPlanAccumulatorNetworkBreakdown | null;
    individual: MedicalPlanAccumulatorNetworkBreakdown | null;
    display_name: string | null;
}

export interface MedicalPlanAccumulatorNetworkBreakdown {
    in_network: MedicalPlanAccumulatorAmounts | null;
    out_of_network: MedicalPlanAccumulatorAmounts | null;
}

export interface MedicalPlanAccumulatorAmounts {
    limit: MedicalPlanAccumulatorValue | null;
    applied: MedicalPlanAccumulatorValue | null;
    remaining: MedicalPlanAccumulatorValue | null;
}

export interface MedicalPlanAccumulatorValue {
    amount: number;
    currency: string;
}

export interface AccumulatorAmountVM {
    limit: number | null;
    applied: number | null;
    remaining: number | null;
}

export interface AccumulatorProgressBarVM {
    progress: number;
    limit: string;
    applied: string;
    remaining: string;
}

export interface AccumulatorNetworkStructure<AmountType> {
    deductible: AmountType;
    yearlyMaximum: AmountType;
}

export type AccumulatorNetworkInput = AccumulatorNetworkStructure<MedicalPlanAccumulatorAmounts>;
export type AccumulatorNetworkVM = AccumulatorNetworkStructure<AccumulatorAmountVM>;
