import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'image-error',
    templateUrl: './image-error.component.html',
    styleUrls: ['./image-error.component.scss']
})
export class ImageErrorComponent implements OnInit {
    @Input() imageSource = '';
    imagePath = '';
    defaultImagePath = '/common-ui-images/navigation/default_avatar_small.png';

    ngOnInit(): void {
        this.imagePath = this.imageSource;
    }

    changeSource(): void {
        this.imagePath = this.defaultImagePath;
    }
}
