import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { PersonalChallenge } from '@genesis-frontend/genesis-utilities';
import {
    AuthenticatedMemberService,
    FeatureEnum,
    PersonalChallengeDetailsDataService,
    PersonalChallengeSharedDataService,
    ReplayChallengeDataService
} from '@genesis-frontend/genesis-utilities';

@Component({
    templateUrl: './personal-challenge-create-modal.component.html',
    styleUrls: ['./personal-challenge-create-modal.component.scss']
})
export class PersonalChallengeCreateModalComponent implements OnInit, OnDestroy {
    @Input() challengeType = '';
    @Input() selected = {} as PersonalChallenge;
    @Input() isCreateMode = false;
    @Output() closeCallback: EventEmitter<void> = new EventEmitter<void>();
    @ViewChild('personalChallengeCreateModalClose') personalChallengeCreateModalClose: ElementRef = { nativeElement: {} };

    PERSONAL_CHALLENGE_TYPE = 'PersonalChallenge';
    MODAL_BACKGROUND = '/img/burst.png';
    STEPS = {
        One: 'one',
        Two: 'two',
        Three: 'three'
    };
    MAX_TITLE_LENGTH = 30;
    WARNING_TITLE_LENGTH = 25;
    TIME_OUT = 100;
    ONE = 1;
    ZERO = 0;

    challengeData = this.personalChallengeSharedDataService;
    steps = ['one', 'two', 'three'];
    step = 0;
    isSingle = false;
    isCreateChallenge = true;
    hasPersonalStepChallenges = false;

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private personalChallengeDetailsDataService: PersonalChallengeDetailsDataService,
        private personalChallengeSharedDataService: PersonalChallengeSharedDataService,
        private replayChallengeData: ReplayChallengeDataService,
        private bsModalRef: BsModalRef
    ) {
        this.closeCallback.subscribe(() => {
            this.bsModalRef.hide();
        });
    }

    ngOnInit() {
        setTimeout(() => {
            this.personalChallengeCreateModalClose.nativeElement.focus();
        }, this.TIME_OUT);

        const memberFeatures = this.authenticatedMemberService.getMemberfeatures();
        const memberFeatureNames = memberFeatures.map((item) => item.name);
        this.hasPersonalStepChallenges = memberFeatureNames.includes(FeatureEnum.PersonalStepChallenges);
        this.setInitialValues();
    }

    ngOnDestroy() {
        this.personalChallengeDetailsDataService.newChallenge = {};
        this.personalChallengeDetailsDataService.personalChallengeData = {};
        this.replayChallengeData.personalChallengeData = {};
    }

    closeModal() {
        this.closeCallback.emit();
    }

    setCurrentStep(step: number) {
        this.step = step;
    }

    setInitialValues() {

        if (Object.keys(this.replayChallengeData.personalChallengeData).length) {
            this.setCurrentStep(this.ONE);
            this.challengeData.stepChallengeData = this.replayChallengeData.personalChallengeData;
            this.challengeData.stepChallengeData.selectedImageUrl = this.replayChallengeData.personalChallengeData.template.imageUrl;
            this.isCreateChallenge = false;
        }

        if (this.challengeType === this.PERSONAL_CHALLENGE_TYPE) {
            this.isCreateChallenge = false;
            this.challengeData.stepChallengeData = this.personalChallengeDetailsDataService.personalChallengeData;
            this.setCurrentStep(this.ONE);
            this.isSingle = !this.isCreateMode;
        }

        if (this.isCreateChallenge) {
            this.setCurrentStep(this.ZERO);
        }

        if (this.selected) {
            if (this.challengeType !== this.PERSONAL_CHALLENGE_TYPE) {
                return;
            }
            this.challengeData.setNewStepChallenge(this.selected);
        }
    }
}
