import { Pipe, PipeTransform } from '@angular/core';


@Pipe({ name: 'filterBy' })
export class FilterByPipe implements PipeTransform {
    transform(input: any, props: string[] | string, search: any = '') {
        if (!input) {
            return [];
        }
        if (
            !Array.isArray(input) ||
      (!Array.isArray(search) && typeof search !== 'string' && !this.isNumberFinite(search) && typeof search !== 'boolean')
        ) {
            return input;
        }

        const terms = String(search)
            .toLowerCase()
            .split(',');

        const properties = String(props).split(',');

        return this.filter(input, properties, terms);
    }

    private filter(input: any[], properties: string[], terms: string[]) {
        return input.filter((obj) => {
            return properties.some((prop) => {
                return terms.some((term) => {
                    const value = this.extractDeepPropertyByMapKey(obj, prop);
                    const { props, tail } = this.extractDeepPropertyByParentMapKey(obj, prop);
                    if (value === 'undefined' && props !== 'undefined' && Array.isArray(props)) {
                        return props.some((parent) => {
                            return term === String(parent[tail]).toLowerCase();
                        });
                    }

                    if (value === 'undefined') {
                        return false;
                    }

                    if (value && term === '') {
                        return true;
                    }
                    return term === String(value).toLowerCase();
                });
            });
        });
    }

    private isNumberFinite(value: any) {
        return (typeof value === 'number') && isFinite(value);
    }

    private extractDeepPropertyByMapKey(obj: any, map: string): any | undefined {
        const keys = map.split('.');
        const head = keys.shift();

        return keys.reduce((prop: any, key: string) => {
            if (Array.isArray(prop)) {
                const result = prop.find((element) => element[key] !== undefined);
                return result ? result[key] : undefined;
            }

            return prop !== 'undefined' && prop !== null && prop[key] !== 'undefined' ? prop[key] : undefined;
        }, obj[head || '']);
    }

    private extractDeepPropertyByParentMapKey(obj: any, map: string): any {
        const keys = map.split('.');
        const tail = keys.pop();
        const props = this.extractDeepPropertyByMapKey(obj, keys.join('.'));

        return { props, tail };
    }
}
