import { Injectable, OnDestroy, Optional } from '@angular/core';
import { ReplaySubject, Subscription, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { MemberAuthService, MicroFrontendAuthService } from './member-auth.service';
import { DateUtils } from '../lib/date-utils';
import {
    Language,
    Member,
    MemberSettings,
    MemberType,
    PlanType,
    Sponsor,
    SponsorSetting,
    Timezone,
    Feature,
    FeatureEnum,
    MemberClass,
    MemberTimezone,
    MemberProduct,
    Product,
    MemberTheme
} from '../models';
import { singleSpaPropsSubject } from '../models/single-spa-props';
import { LanguageService } from '../services/language.service';
import { GlobalReduxStore } from '../state-management';


const DEFAULT_PROFILE_IMAGE_URL = '/img/navigation/DefaultAvatar.png';

@Injectable({ providedIn: 'root' })
export class AuthenticatedMemberService implements OnDestroy {

    private authenticatedMember!: MemberClass;
    private authClient: any;
    private mixpanel: any;
    private isAuthenticatedUserAnAgent = false;
    private timezone: Timezone = {
        timezoneId: NaN,
        javaTimezone: '',
        longDescription: ''
    };
    private excludeMixpanelTracking = false;
    private memberTimezone!: Timezone;
    private memberFeatures: Feature[] = [];
    private memberSettings!: MemberSettings;
    private componentSub = new Subscription();

    private country: any;
    private languages: any;
    private theme: MemberTheme | null = null;
    private memberProducts: MemberProduct[] = [];
    private timezones: Timezone[] = [];

    private spouseMemberTypes = [
        MemberType.Spouse,
        MemberType.DomesticPartner
    ];

    private rewardsAllowedMemberTypes = [
        MemberType.Primary,
        MemberType.Spouse,
        MemberType.Dependent,
        MemberType.DomesticPartner,
        MemberType.Other
    ];

    private currentMemberTimezone = new MemberTimezone();

    private member$ = new ReplaySubject<MemberClass>();

    constructor(
        @Optional() private mfReduxStore: GlobalReduxStore,
        @Optional() private memberAuthService: MemberAuthService,
        @Optional() private languageService: LanguageService
    ) {
        if (!this.memberAuthService || this.memberAuthService.constructor.name === 'MemberAuthService') {
            this.memberAuthService = new MicroFrontendAuthService();
        }

        this.memberAuthService.setSingleSpa(singleSpaPropsSubject);
    }

    subscribe(observer: (result: MemberClass) => void) {
        this.member$.pipe(take(1)).subscribe(observer);
    }

    authenticate() {
        return this.memberAuthService.authenticate().pipe(map((response) => {
            if (this.languageService) {
                this.languageService.setLanguageCookie(response.authenticatedMember.language);
            }
            this.authenticatedMember = response.authenticatedMember;
            this.authClient = response.authClient;
            this.isAuthenticatedUserAnAgent = response.isAuthenticatedUserAnAgent;
            this.timezone = response.authenticatedMember?.timezone;
            this.excludeMixpanelTracking = response.authenticatedMember?.sponsor.settings.excludeMixpanelTracking;
            this.memberFeatures = response.memberFeatures;
            this.memberTimezone = response.authenticatedMember?.timezone;
            this.mixpanel = response.mixpanel;
            this.memberSettings = response.memberSettings;
            this.timezones = response.timezones || [];
            this.memberProducts = response.memberProducts;
            this.theme = response.theme;
            this.country = response.country;
            this.languages = response.languages;
            this.createAuthenticationSubscription();
            return response;
        }));
    }

    private createAuthenticationSubscription() {
        this.componentSub.add(
            this.mfReduxStore.createSelector('authentication')
                .subscribe((authentication: any) => {
                    this.authenticatedMember = Object.assign(this.authenticatedMember, authentication.authenticatedMember);
                })
        );
    }

    hasMember() {
        return !!this.authenticatedMember;
    }

    getMember(): MemberClass {
        return this.authenticatedMember;
    }

    getMemberCountry(): string {
        return this.authenticatedMember.countryId;
    }

    getAuthenticatedMember(): Member {
        return this.authenticatedMember;
    }

    getAuthenticatedMemberId() {
        return this.authenticatedMember.id;
    }

    getAuthenticatedMemberPromise() {
        return of(this.authenticatedMember).toPromise();
    }

    getMemberfeatures() {
        return this.memberFeatures;
    }

    hasFeature(feature: FeatureEnum): boolean {
        return this.memberFeatures.some((f) => f.name === feature);
    }

    getMemberLanguageCode() {
        if (this.authenticatedMember.language) {
            const languageNameArray = this.authenticatedMember.language.split('_');
            languageNameArray[1] = languageNameArray[1].toUpperCase();
            return languageNameArray.join('-');
        }

        return Language.en_us;
    }

    getExcludeMixpanelTracking() {
        return this.excludeMixpanelTracking;
    }

    getTimeZone(): string {
        return this.timezone.javaTimezone;
    }

    getAuthClient(): any {
        return this.authClient;
    }

    getTimeForMemberNow(): Date {
        return DateUtils.tz(DateUtils.toUtcDate(), this.getTimeZone());
    }

    getMemberTimezone(): Timezone {
        return this.memberTimezone;
    }

    /**
     * @deprecated Reference to mix panel should be acquired using props
     */
    getMixpanel(): any {
        return this.mixpanel;
    }

    getSponsor(): Sponsor {
        return this.authenticatedMember.sponsor;
    }

    getSponsorSettings(): SponsorSetting {
        return this.authenticatedMember.sponsor.settings;
    }

    getMemberSettings(): MemberSettings {
        return this.memberSettings;
    }

    ngOnDestroy() {
        this.componentSub.unsubscribe();
    }

    getLogoUrl() {
        return this.authenticatedMember.logoUrl;
    }

    getMemberStatus() {
        return this.authenticatedMember.status;
    }

    getMemberType() {
        return this.authenticatedMember.memberType;
    }

    getMemberTheme(): MemberTheme|null {
        return this.theme;
    }

    isImpersonating() {
        if (this.authClient) {
            const token = this.authClient.tokenParsed;
            return token.auth_method === 'impersonate';
        }
        return false;
    }

    isSpouseMember() {
        return this.spouseMemberTypes.includes(this.getMemberType());
    }

    isPrimaryMember() {
        return this.getMemberType() === MemberType.Primary;
    }

    isUserAnAgent() {
        return this.isAuthenticatedUserAnAgent;
    }

    getFeatures(): Feature[] {
        return this.memberFeatures;
    }

    getRewardableFeatures(): string[] {
        return this.memberFeatures
            .filter((features) => features.rewarding)
            .map((feature) => feature.name);
    }

    isCore() {
        return this.authenticatedMember.sponsor.planType === PlanType.Core;
    }

    getProfileImage() {
        return this.authenticatedMember.profilepicture ? this.authenticatedMember.profilepicture : DEFAULT_PROFILE_IMAGE_URL;
    }

    hasProfileImage() {
        return !(this.authenticatedMember.profilepicture == null || this.authenticatedMember.profilepicture.includes(DEFAULT_PROFILE_IMAGE_URL));
    }

    canAccessRewards() {
        return this.rewardsAllowedMemberTypes.includes(this.getMemberType());
    }

    getCurrentTimezone() {
        this.currentMemberTimezone.timezone = this.timezone;
        return this.currentMemberTimezone;
    }

    getHomeMemberProduct(): MemberProduct {
        const products = this.getTermsAndConditionProducts();

        if (products.length === 1) {
            return products[0];
        }

        const firstEnabledProduct = products.find((p) => p.enabled);
        if (firstEnabledProduct) {
            return firstEnabledProduct;
        }

        return products[0];
    }

    getTermsAndConditionProducts(): MemberProduct[] {
        const productsSupportingDisplayOfTCs: Product[] = [
            Product.BasePlatform,
            Product.Wellness
        ];

        const productPriority = (product: Product) => {
            switch (product) {
            case Product.BasePlatform:
                return 0;
            case Product.Wellness:
                return 1;
            default:
                return 0;
            }
        };

        const supportedProducts = this.memberProducts
            .filter((mp: MemberProduct) => productsSupportingDisplayOfTCs.includes(mp.product))
            .sort((mp1: MemberProduct, mp2: MemberProduct) => productPriority(mp1.product) - productPriority(mp2.product));

        const hasBasePlatform = supportedProducts.some((mp: MemberProduct) => mp.product === Product.BasePlatform);
        const hasWellness = supportedProducts.some((mp: MemberProduct) => mp.product === Product.Wellness);

        if (hasBasePlatform && hasWellness) {
            supportedProducts.shift();
        }

        if (supportedProducts.length === 0) {
            supportedProducts.push({
                id: 0,
                product: Product.Wellness,
                enabled: false,
                memberId: this.authenticatedMember.id
            });
        }

        return supportedProducts;
    }

    getMemberProducts(): MemberProduct[] {
        return this.memberProducts || [];
    }

    hasProduct(productNames: Product[]): boolean {
        return this.memberProducts.some((mp) => productNames.includes(mp.product));
    }

    /**
     * @deprecated Should be removed
     */
    getGenesisSingleSpaProps() {
        return {
            memberCountry: this.country,
            memberSettings: this.memberSettings,
            sponsor: this.getSponsor(),
            sponsorSettings: this.getSponsorSettings(),
            theme: this.theme,
            timezones: this.timezones,
            memberProducts: this.memberProducts
        };
    }

    getCountry() {
        return this.country;
    }

    getLanguages() {
        return this.languages;
    }

    getHraProviderType(): string {
        return this.getSponsorSettings().hraProviderType;
    }

    setPhoneNumber(phoneNumber: string) {
        this.authenticatedMember.phoneNumber = phoneNumber;
    }
}
