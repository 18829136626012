import { CacheItem, CacheInterface } from './cache';
import { Global } from './global';

export class SessionCache<T = any> implements CacheInterface<T> {

    constructor(private scope: string) { }

    setItem(key: string, value: T): void {
        const scope = this.getScope();
        const item: CacheItem<T> = {
            time: (new Date()).getTime(),
            value: value
        };
        scope[key] = item;
    }

    getItem(key: string): CacheItem<T> | null {
        const item = this.getScope()[key];
        return item ?? null;
    }

    removeItem(key: string): void {
        const scope = this.getScope();
        delete scope[key];
    }

    hasItem(key: string): boolean {
        return this.getItem(key) != null;
    }

    getScope(): Record<string, CacheItem<T>> {
        return Global.get<Record<string, CacheItem<T>>>(this.scope, () => ({}));
    }
}
