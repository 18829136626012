import { Pipe, PipeTransform } from '@angular/core';

import { EarningsAndSpending } from '../models/interfaces/earnings-and-spending.model';

@Pipe({
    name: 'rewardTypesSameForDay'
})
export class RewardTypesSameForDayPipe implements PipeTransform {

    transform(date: string, earningsAndSpending: EarningsAndSpending | null): boolean {
        if (!earningsAndSpending || !earningsAndSpending.summary || !earningsAndSpending.earnedRewards) {
            return false;
        }
        let isSameRewardType = true;
        const statementRewardType = earningsAndSpending.summary.rewardType;
        const earnedRewardsForDay = earningsAndSpending.earnedRewards.get(date)?.rewards;

        if (earningsAndSpending?.earnedRewards.get(date)?.rewards !== null) {
            isSameRewardType = !earnedRewardsForDay?.some((reward: any) => {
                return statementRewardType !== reward.reward.rewardType;
            });
        }
        return isSameRewardType;
    }
}
