export enum RewardableActionType {
    Coaching = 'Coaching',
    CompleteACard = 'CompleteACard',
    CompleteACoachingGoal = 'CompleteACoachingGoal',
    CreatePersonalChallenge = 'CreatePersonalChallenge',
    HealthCheckSurvey = 'HealthCheckSurvey',
    JoinCompetition = 'JoinCompetition',
    JoinPersonalChallenge = 'JoinPersonalChallenge',
    JourneyScene = 'JourneyScene',
    SelfEnteredTrackerData = 'SelfEnteredTrackerData',
    Shoutout = 'Shoutout',
    Interests = 'Interests',
    ProfilePicture = 'ProfilePicture',
    CompleteChecklist = 'CompleteChecklist',
    CultureCheckSurvey = 'CultureCheckSurvey',
    GenericSurveyCompleted = 'GenericSurveyCompleted',
    CustomSurvey = 'CustomSurvey'
}
