import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { PreviewToolService } from './preview-tool.service';
import { AuthenticatedMemberService } from '../authentication';
import { GlobalReduxStore, UpdateMemberRedeemableWallets } from '../state-management';


@Injectable({
    providedIn: 'root'
})
export class RedeemableWalletsService {
    redeemableWallets: any;
    redeemableWalletsLoaded = false;
    membersRedeemableWalletsSubject = new ReplaySubject<any>();

    constructor(
        private httpClient: HttpClient,
        private globalReduxStore: GlobalReduxStore,
        private authenticatedMemberService: AuthenticatedMemberService,
        private previewToolService: PreviewToolService
    ) {}

    getRedeemableWalletsUrl(sponsorId: number, memberId: number) {
        return `rewards-api/public/sponsors/${sponsorId}/members/${memberId}/redeemable-wallets`;
    }

    loadMemberRedeemableWallets(shouldReload: boolean) {
        if (this.redeemableWalletsLoaded && !shouldReload) {
            return this.membersRedeemableWalletsSubject;
        }
        const member = this.authenticatedMemberService.getMember();

        const previewToolServiceQuery = this.previewToolService.getQueryParameterObject({
            sponsorId: member.sponsorId,
            memberId: member.id
        });

        this.httpClient.get(this.getRedeemableWalletsUrl(previewToolServiceQuery.sponsorId, previewToolServiceQuery.memberId)).subscribe(
            (data: any) => {
                this.redeemableWalletsLoaded = true;
                this.redeemableWallets = data;
                this.globalReduxStore.dispatchAction(UpdateMemberRedeemableWallets(this.redeemableWallets));
                this.membersRedeemableWalletsSubject.next(this.redeemableWallets);
            },
            (error) => {
                this.membersRedeemableWalletsSubject.error(error);
            }
        );

        return this.membersRedeemableWalletsSubject;
    }
}
