<div class="modal-header">
    <h1 id="hey-happy-tracker-modal-header"
        class="modal-title"
        [translate]="'HeyHappyTracker'">
        Hey, Happy tracker!
    </h1>
    <div class="close-modal-btn"
         tabindex="0"
         (click)="bsModalRef.hide()"
         role="button"
         [attr.aria-label]="'Close' | translate">
        <span aria-hidden="true">&times;</span>
    </div>
</div>

<div class="modal-body modal-body-wrapper">
    <p [translate]="'YouCanOnlyHave20TrackersGoingAtOnce'">
        You can only have 20 trackers going at
        once. Remove a tracker to add this shiny new one.</p>
    <div class="modal-buttons">
        <button class="modal-got-it-btn vp-button-primary"
                id="modal-got-it-btn"
                type="button"
                (click)="bsModalRef.hide()"
                [translate]="'GotIt'"
                [attr.aria-label]="'GotIt' | translate">
            Got it!
        </button>
    </div>
</div>
