export enum IntervalType {
    Daily = 'Daily',
    Weekly = 'Weekly',
    Monthly = 'Monthly',
    Quarterly = 'Quarterly',
    Annually = 'Annually',
    Game = 'Game',
    Program = 'Program',
    OneTime = 'OneTime',
    PerEntity = 'PerEntity'
}
