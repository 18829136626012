export enum CurrencyCode {
    AED = 'AED',
    AUD = 'AUD',
    CAD = 'CAD',
    CHF = 'CHF',
    CNY = 'CNY',
    EUR = 'EUR',
    GBP = 'GBP',
    HKD = 'HKD',
    ILS = 'ILS',
    MXN = 'MXN',
    MYR = 'MYR',
    NOK = 'NOK',
    NZD = 'NZD',
    RUP = 'RUP',
    SEK = 'SEK',
    SGD = 'SGD',
    TWD = 'TWD',
    USD = 'USD',
    YEN = 'YEN',
    JPY = 'JPY'
}
