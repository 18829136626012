import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'delete-cancel-modal',
    templateUrl: './delete-cancel-modal.component.html'
})
export class DeleteCancelModalComponent {

    @Input() modalOpen = false;
    @Input() modalTitle = '';
    @Input() modalText = '';
    @Output() openChangeOutput: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() deleteOutput: EventEmitter<any> = new EventEmitter<any>();
}
