import { EventEmitter, Injectable, Output } from '@angular/core';

import { DateChangeEvent, StatModalResult } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class StatsPageEventService {
    @Output()
        dateChangeEvent: EventEmitter<DateChangeEvent> = new EventEmitter<DateChangeEvent>();

    @Output()
        modalCloseEvent: EventEmitter<StatModalResult> = new EventEmitter<StatModalResult>();

    @Output()
        goalModalCloseEvent: EventEmitter<void> = new EventEmitter<void>();

    @Output()
        stepsCorrectionSubmitEvent: EventEmitter<Date> = new EventEmitter<Date>();

    @Output()
        stepsCorrectionSelectEvent: EventEmitter<Date> = new EventEmitter<Date>();

    @Output()
        dateChangeSelectEvent: EventEmitter<Date> = new EventEmitter<Date>();

    @Output()
        focusOnElementEvent: EventEmitter<string> = new EventEmitter<string>();

    emitDatePickerNextDay() {
        this.dateChangeEvent.emit(DateChangeEvent.ForwardOneDay);
    }

    emitDatePickerPreviousDay() {
        this.dateChangeEvent.emit(DateChangeEvent.BackOneDay);
    }

    emitModalClose(modalResult: StatModalResult) {
        this.modalCloseEvent.emit(modalResult);
    }

    emitGoalModalClose() {
        this.goalModalCloseEvent.emit();
    }

    emitStepsCorrectionModalClose(activityDate: Date) {
        this.stepsCorrectionSubmitEvent.emit(activityDate);
    }

    emitStepsCorrectionSelect(activityDate: Date) {
        this.stepsCorrectionSelectEvent.emit(activityDate);
    }

    emitDateChangeSelectEvent(activityDate: Date) {
        this.dateChangeSelectEvent.emit(activityDate);
    }

    emitFocusOnElementEvent(elementId: string) {
        this.focusOnElementEvent.emit(elementId);
    }
}
