<div class="global-challenges-order-confirmation-container">
    <div class="challenge-onboarding-blocker-modal-content">
        <div
            class="order-confirmation-card-title text-clr-oxford-blue"
            [translate]="'YouAreAllSet'">
            You're All Set!
        </div>
        <div
            class="order-confirmation-card-title text-clr-oxford-blue"
            [translate]="'TrackerIsOnWay'">
            Your activity tracker is on its way.
        </div>
    </div>
    <div class="card-middle-order-confirmation">
        <img
            class="confirm-vp-go-player-image popover-trigger border-clr-bg"
            [src]="'/img/activity-tracker-device.svg'" 
            alt="Activity Tracker"/>
    </div>
    <div class="card-footer-order-confirmation text-clr-oxford-blue">
        <div class="global-challenge-onboarding-button">
            <button
                class="vp-button vp-button-primary vp-width-200-px larger bordered"
                (click)="goNext()">
                <span
                    *ngIf="!data.isMaxBuzzOrdered"
                    [translate]="'Done'">
                    Done
                </span>
                <span
                    *ngIf="data.isMaxBuzzOrdered"
                    [translate]="'Okay'">
                    Okay
                </span>
            </button>
        </div>
    </div>
</div>
