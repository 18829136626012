import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'paginationPipe' })
export class PaginationPipe implements PipeTransform {
    transform(value: any[], currentPage: number, perpage: number): any {
        const result = value.filter((_, index) => {
            return index >= (currentPage - 1) * perpage && index < currentPage * perpage;
        });
        return result;
    }
}
