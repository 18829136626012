import { Injectable } from '@angular/core';

import { AuthenticatedMemberService, UnleashFlag, UnleashFlagsService } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class BrandingThemeControlService {
    readonly personifyTheme = 'Personify Health Theme - Official';
    unleashRebrandingControlActive = false;

    constructor(
        private unleashFlagsService: UnleashFlagsService,
        private authenticatedMemberService: AuthenticatedMemberService
    ) { }

    setRebrangingFlag(): void {
        if (this.unleashFlagsService.hasUnleashFlag(UnleashFlag.BrandingUpdatesPhase1) &&
    (this.authenticatedMemberService.getMemberTheme()?.id === 0 || (this.authenticatedMemberService.getMemberTheme()?.name === this.personifyTheme))
        ) {
            this.unleashRebrandingControlActive = true;
        } else {
            this.unleashRebrandingControlActive = false;
        }
    }
}
