<ng-container *ngIf="showReward">
    <img
        class="reward-ribbon-icon"
        [src]="rewardIcon"
        alt=""
    />
    <h2
        class="reward-ribbon-text h6">
        {{ rewardText }}
    </h2>
</ng-container>
