import { Component, HostBinding, Input } from '@angular/core';

import { ClaimsState } from '../../models/claim-state.model';
import { AnalyticsTrackingAction, AnalyticsTrackingSingleSpaService } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'genesis-frontend-opt-in-card',
    templateUrl: './opt-in-card.component.html',
    styleUrl: './opt-in-card.component.scss'
})
export class OptInCardComponent {
    @Input() claimsState: ClaimsState = ClaimsState.noClaims;

    @HostBinding('class')
    get classNames(): string {
        return 'flex align-items-center justify-content-center';
    }

    constructor(
        private readonly analyticsService: AnalyticsTrackingSingleSpaService
    ) {
    }

    readonly errors = {
        errorTitleId: 'claims-error-title',
        errorTitle: 'ClaimsDataNotAvailable'
    };
    readonly translations = {
        VIEW_DETAILS: 'ViewDetails',
        RECENT_CLAIMS: 'RecentClaims'
    };

    sendAnalytics() {
        this.analyticsService.sendData(AnalyticsTrackingAction.HomepageWidgetTable, {
            Widget_name: 'Claims Widget',
            Claims_state: this.claimsState,
            Claims_clicks: 'View details',
            Claims_navigation: 'Navigate to Claims Landing Page'
        });
    }
}
