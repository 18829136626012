import { Inject, Injectable } from '@angular/core';
import { isEmpty } from 'lodash';

import { MemberHraService } from '@genesis-frontend/genesis-data-access';
import { AuthenticatedMemberService, Environment, ENVIRONMENT } from '@genesis-frontend/genesis-utilities';

@Injectable()
export class HraStartService {
    constructor(
    private hraService: MemberHraService,
    private authenticatedMemberService: AuthenticatedMemberService,
    @Inject(ENVIRONMENT) private environment: Environment
    ) {}

    queryHraUrl() {
        return new Promise<any>((resolve) => {
            const member = this.authenticatedMemberService.getAuthenticatedMember();
            this.hraService
                .getProviderInfo(member.id)
                .subscribe((providerInfo: any) => {
                    const fallbackUrl = `https://${this.environment.genesis.baseUrl}/#${providerInfo.path || ''}`;
                    let startUrl = providerInfo.startUrl.trim();
                    let ssoUrl = providerInfo.ssoUrl.trim();
                    startUrl = !isEmpty(startUrl) ? startUrl : fallbackUrl;
                    ssoUrl = !isEmpty(ssoUrl) ? ssoUrl : fallbackUrl;
                    const resolveData = {
                        hraConsentRequired: providerInfo.consentRequired,
                        hraUrl: startUrl,
                        ssoUrl
                    };
                    this.hraService.updateHRA(member.id).subscribe(
                        () => {
                            resolve(resolveData);
                        },
                        () => {
                            resolve(resolveData);
                        }
                    );
                });
        });
    }

    redirectToSSO() {
        this.queryHraUrl().then((data: any) => {
            window.location.href = data.ssoUrl.toString();
        });
    }
}
