import { Component, OnInit } from '@angular/core';

import { ZendeskAuth, ZendeskService } from '@genesis-frontend/genesis-utilities';

@Component({
    template: '<div #zendeskSupport></div>'
})
export class ZendeskSupportComponent implements OnInit {
    constructor(private zendeskService: ZendeskService) {}

    ngOnInit() {
        this.zendeskService.getSupportUrl().subscribe((response) => {
            if (response.token == null) {
                window.location.assign(response.returnTo);
            } else {
                this.createFormForZendeskAndAuthenticate(response);
            }
        });
    }

    createFormForZendeskAndAuthenticate(response: ZendeskAuth) {
        const jwtForm = document.createElement('form');
        jwtForm.method = 'post';
        jwtForm.action = `${response.url}`;
        const token = document.createElement('input');
        token.name = 'jwt';
        token.value = `${response.token}`;
        jwtForm.appendChild(token);
        const returnToUrl = document.createElement('input');
        returnToUrl.name = 'return_to';
        returnToUrl.value = `${response.returnTo}`;
        jwtForm.appendChild(returnToUrl);
        document.body.appendChild(jwtForm);
        jwtForm.submit();
    }
}
