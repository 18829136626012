import { Component } from '@angular/core';

@Component({
    selector: 'genesis-frontend-error',
    templateUrl: './error.component.html',
    styleUrl: './error.component.scss'
})
export class ErrorComponent {
    errorTitleId = 'claims-error-title';
    errorTitle = 'UnableToLoadClaimsData';
    errorMessageId = 'claims-error-message';
    errorMessage = 'TryRefreshingScreen';
}
