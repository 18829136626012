import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { ApplicationCache } from '../core';
import { Product } from '../models/enums/product.enum';
import { MemberAgreement } from '../models/interfaces/member-agreement.model';

@Injectable({
    providedIn: 'root'
})
export class TermsAndConditionsService {
    agreementsCache = {};

    constructor(
        private readonly cache: ApplicationCache,
        private httpClient: HttpClient
    ) { }

    getAgreements(memberId: number, unaccepted: boolean, products?: Product[]) {
        let params = new HttpParams();
        params = params.append('unaccepted', String(unaccepted));
        if (products) {
            params = params.append('products', String(products));
        }
        const cacheKey = `TermsAndConditionsService.getAgreements.${memberId}.${params}`;
        return this.cache.getOrFetch(cacheKey, () => {
            return this.httpClient.get<MemberAgreement[]>(
                `/agreements-api/members/${memberId}/agreements`,
                { params }
            );
        });
    }

    saveAgreements(memberId: number, agreements: MemberAgreement[]) {
        const ids = agreements.map((agreement) => {
            return agreement.id;
        });
        return of(this.httpClient.post<MemberAgreement[]>(`/agreements-api/members/${memberId}/agreements`, ids)
            .subscribe(() => {
                // this.authenticatedMemberService.enableProduct(product);
            }));
    }
}
