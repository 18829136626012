import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

import { BubbleCelebrationParticle } from './bubble-celebration-particle';

type CreateJS = typeof import('createjs-module');

const HALF = 0.5;
const DOUBLE = 2;
const DEFAULT_WIDTH = 50;
const DEFAULT_HEIGHT = 50;
const DEFAULT_PARTICLE_COUNT = 25;
const DEFAULT_ANIMATION_DURATION = 1000;
const DEFAULT_COLORS = [
    '#fecb45',
    '#fdb62b',
    '#fd9c27',
    '#fd7422',
    '#1692aa',
    '#64c4c7'
];

declare const System: any;

@Component({
    selector: 'bubble-celebration',
    templateUrl: './bubble-celebration.template.html',
    styleUrls: ['./bubble-celebration.scss']
})
export class BubbleCelebrationComponent implements AfterViewInit {
    @Input() width = 0;
    @Input() height = 0;
    @Input() celebrationWidth: number = DEFAULT_WIDTH;
    @Input() celebrationHeight: number = DEFAULT_HEIGHT;
    @Input() particleCount: number = DEFAULT_PARTICLE_COUNT;
    @Input() colors: string[] = DEFAULT_COLORS;
    @Input() animationDuration: number = DEFAULT_ANIMATION_DURATION;
    @Input() containerHeight = 0;
    @Input() containerWidth = 0;
    @Input() leftOffset: string | undefined | number;
    @Input() topOffset: string | undefined | number;
    @Input() ariaLabelledBy = '';

    @ViewChild('bubbleAnimationArea') bubbleAnimationArea: ElementRef | undefined;
    @ViewChild('bubbleContentArea') bubbleContentArea: ElementRef | undefined;

    public canvasWidth = 0;
    public canvasHeight = 0;
    private privateWidth = 0;
    private privateHeight = 0;
    private stage: any;
    private hasAnimated: boolean | undefined;
    private celebrationParticles: BubbleCelebrationParticle[] = [];

    async ngAfterViewInit() {

        const createjs = await this.getCreateJs();

        if (this.bubbleAnimationArea) {
            this.stage = new createjs.Stage(this.bubbleAnimationArea.nativeElement);
        }
    }

    private async getCreateJs(): Promise<CreateJS> {
        await System.import('createjs');
        return (window as any).createjs as CreateJS;
    }

    public async animate() {
        const createjs = await this.getCreateJs();
        this.initAnimation();

        const minAnimationTimeMultiplier = 0.25;
        const alpha = 0.5;
        const fadeDuration = 50;

        const minAnimationTime = this.animationDuration * minAnimationTimeMultiplier;

        if (!this.hasAnimated) {
            createjs.Ticker.addEventListener('tick', this.stage);
            this.hasAnimated = true;
        }

        this.celebrationParticles.forEach((particle) => {
            this.initializeParticlePosition(particle);
            if (particle.element) {
                createjs.Tween.get(particle.element)
                    .to({
                        x: particle.x,
                        y: particle.y,
                        alpha,
                        scaleX: 1,
                        scaleY: 1
                    }, this.random(minAnimationTime, this.animationDuration), createjs.Ease.sineOut)
                    .to({ alpha: 0 }, fadeDuration, createjs.Ease.linear);
            }
        });
    }

    private initAnimation() {
        if (this.bubbleContentArea) {
            this.privateWidth = this.containerWidth || this.bubbleContentArea.nativeElement.clientWidth;
            this.privateHeight = this.containerHeight || this.bubbleContentArea.nativeElement.clientHeight;
            this.canvasWidth = this.width || (this.privateWidth + this.celebrationWidth * DOUBLE);
            this.canvasHeight = this.height || (this.privateHeight + this.celebrationHeight * DOUBLE);
            this.leftOffset = this.leftOffset || (((this.privateWidth - this.canvasWidth) * HALF) + 'px');
            this.topOffset = this.topOffset || (((this.privateHeight - this.canvasHeight) * HALF) + 'px');

            this.createParticles();
        }
    }

    private random(min: number, max: number) {
        return Math.random() * (max - min) + min;
    }

    private createParticles() {
        const FRAMES_PER_SECOND = 32;
        const MIN_OPACITY = 0.9;
        const MAX_OPACITY = 0.99;
        const MIN_SIZE_MULTIPLIER = 0.5;
        const MAX_SIZE_MULTIPLIER = 1.25;
        const MIN_DIAMETER = 7;

        const heightCelebrationRegion = ((this.canvasHeight - this.privateHeight) * HALF) + 1;
        const widthCelebrationRegion = ((this.canvasWidth - this.privateWidth) * HALF) + 1;

        const perimeter = (this.canvasHeight + this.canvasWidth) * DOUBLE;
        const increment = perimeter / (this.particleCount);

        this.celebrationParticles = [];

        for (let i = 0; i < this.particleCount; i++) {
            const particle: BubbleCelebrationParticle = new BubbleCelebrationParticle(
                this.random(MIN_DIAMETER, this.privateHeight * HALF),
                this.random(MIN_OPACITY, MAX_OPACITY),
                this.random(MAX_SIZE_MULTIPLIER, MIN_SIZE_MULTIPLIER),
                this.colors[i % this.colors.length]
            );

            const perimeterLocation: number = increment * i;

            // set particle ending location
            if (perimeterLocation < this.canvasWidth) {
                particle.x = perimeterLocation;
                particle.y = this.random(0, heightCelebrationRegion) + (particle.radius);
                if (particle.x > this.canvasWidth - particle.diameter) {
                    particle.x = this.canvasWidth - particle.diameter;
                }
            } else if (perimeterLocation < (this.canvasWidth + this.canvasHeight)) {
                particle.x = this.canvasWidth - this.random(0, widthCelebrationRegion) - (particle.radius);
                particle.y = perimeterLocation - this.canvasWidth;
                if (this.canvasHeight - particle.diameter < particle.y) {
                    particle.y = this.canvasHeight - particle.diameter;
                }
            } else if (perimeterLocation < ((this.canvasWidth * DOUBLE) + this.canvasHeight)) {
                particle.x = (this.canvasWidth * DOUBLE) + this.canvasHeight - perimeterLocation;
                particle.y = this.canvasHeight - this.random(0, heightCelebrationRegion) - (particle.radius);
                if (particle.x > this.canvasWidth - particle.diameter) {
                    particle.x = this.canvasWidth - particle.diameter;
                }
            } else {
                particle.x = this.random(0, widthCelebrationRegion) + (particle.radius);
                particle.y = perimeter - perimeterLocation;
                if (this.canvasHeight - particle.diameter < particle.y) {
                    particle.y = this.canvasHeight - particle.diameter;
                }
            }

            particle.element = new createjs.Shape();

            particle.element.alpha = particle.opacity;
            particle.element.scaleX = particle.scale;
            particle.element.scaleY = particle.scale;
            particle.element.graphics.beginFill(particle.color).drawCircle(0, 0, particle.radius);

            this.celebrationParticles.push(particle);
            this.stage.addChild(particle.element);
        }

        createjs.Ticker.setFPS(FRAMES_PER_SECOND);
    }

    private initializeParticlePosition(particle: BubbleCelebrationParticle) {
        if (particle.element) {
            particle.element.x = this.canvasWidth * HALF;
            particle.element.y = this.canvasHeight * HALF;
        }
    }
}
