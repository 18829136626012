<div class="bubble-celebration-container">
  <canvas
    class="bubble-celebration-canvas"
    #bubbleAnimationArea
    [width]="canvasWidth"
    [height]="canvasHeight"
    [style.top]="topOffset"
    [style.left]="leftOffset"
  ></canvas>
  <div class="bubble-celebration-content"
       #bubbleContentArea
       [attr.aria-labelledby]="ariaLabelledBy">
    <ng-content></ng-content>
  </div>
</div>
