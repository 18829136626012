import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { interval, Subscription } from 'rxjs';

import { LogoutWarningModalComponent } from '../../components/logout-warning-modal/logout-warning-modal.component';
import { AuthenticatedMemberService, AuthenticatedTimeoutService, LogoutMemberService } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class LogoutWarningService {
    watchdogTimer: Subscription | undefined;

    MILLISECONDS_DELAY = 10000;
    IGNORED_REASON = 'DIALOG_IGNORED';
    LOGOUT_HTML = 'logout.html';

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private authenticatedTimeoutService: AuthenticatedTimeoutService,
        private logoutService: LogoutMemberService,
        private modalService: BsModalService,
        private router: Router
    ) {}

    start() {
        this.startWatchDogCheck();
    }

    private open() {
        const closeSubscription = this.modalService.onHidden.subscribe(() => {
            if (modal.content?.dismissReason === this.IGNORED_REASON) {
                const logoutUrl = this.authenticatedMemberService.getMember().sponsor?.customLogoutUrl || undefined;
                this.logoutService.logout(true, logoutUrl);
            } else {
                this.keepAlive();
            }
            closeSubscription.unsubscribe();
        });
        const modal = this.modalService.show(LogoutWarningModalComponent,
            {
                backdrop: 'static',
                class: 'logout-warning-modal',
                animated: false
            });
        if (modal.content?.ignoreReason) {
            modal.content.ignoreReason = this.IGNORED_REASON;
        }
    }

    private keepAlive() {
        this.startWatchDogCheck();
        this.refreshSession();
        window.dispatchEvent(new CustomEvent('customResetLastRequestEvent'));
    }

    private refreshSession() {
        this.authenticatedMemberService.getMember();
    }

    private startWatchDogCheck() {
        this.watchdogTimer = interval(this.MILLISECONDS_DELAY).subscribe(() => this.checkWatchDog());
    }

    private checkWatchDog() {
        const isLogoutPageActive = this.router.url.includes(this.LOGOUT_HTML);
        if (this.authenticatedTimeoutService.lastRequestExpired() && !isLogoutPageActive) {
            this.watchdogTimer?.unsubscribe();
            this.open();
        }
    }
}
