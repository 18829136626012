import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'numberCounterAnimation' })
export class NumberCounterAnimationPipe implements PipeTransform {
    transform(endValue: number, durationMs: number, element: HTMLLabelElement, isPercentage: boolean): any {

        const steps = 12;
        const stepCount = Math.abs(durationMs / steps);
        const valueIncrement = endValue / stepCount;
        const sinValueIncrement = Math.PI / stepCount;

        let currentValue = 0;
        let currentSinValue = 0;

        function step() {
            currentSinValue += sinValueIncrement;
            currentValue += valueIncrement * Math.sin(currentSinValue) ** 2 * 2;
            element.textContent = Math.abs(currentValue).toLocaleString('en', {
                maximumFractionDigits: 1,
                minimumFractionDigits: isPercentage ? 1 : 0
            });

            if (currentSinValue < Math.PI) {
                window.requestAnimationFrame(step);
            }
        }

        step();
    }
}
