import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    templateUrl: './resending-mfa-code-warning-modal.component.html',
    styleUrls: ['./resending-mfa-code-warning-modal.component.scss']
})
export class ResendingMfaCodeWarningModalComponent {
    @Output() closeCallback: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private bsModalRef: BsModalRef
    ) {
        this.closeCallback.subscribe(() => {
            this.bsModalRef.hide();
        });
    }
}
