import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { DateUtils } from '../lib/date-utils';
import { SurveyStatus } from '../models/enums/survey-status.enum';
import { ScheduledSurvey } from '../models/interfaces/scheduled-survey.model';

@Injectable({ providedIn: 'root' })
export class SurveyService {

    private readonly isMobileDevice = (/Mobi|iPhone|Android/).test(navigator.userAgent);

    constructor(private httpClient: HttpClient, private router: Router) {}

    checkForSurveyInterrupt(surveys: ScheduledSurvey[], memberId: string): void {
        const currDate = DateUtils.format(new Date(), 'MM/DD/YYYY');
        const key = `${memberId}_${currDate}`;
        surveys.forEach((survey) => {
            const shouldInterrupt = survey.interrupt
                && (!sessionStorage.getItem(key) && (survey.status === SurveyStatus.Available
                    || survey.status === SurveyStatus.Started
                    || survey.status === SurveyStatus.Scoreable));
            if (shouldInterrupt) {
                sessionStorage[key] = true;
                this.goToScheduledSurvey(survey);
            }
        });
    }

    goToScheduledSurvey(survey: ScheduledSurvey): void {
        setTimeout(() => {
            this.router.navigateByUrl(`surveys${survey.uiType === 'V2' ? `-ui/surveys/${survey.scheduledSurveyId}/intro` : `/${survey.scheduledSurveyId}`}`);
        }, 0);
    }

    checkIfShouldRedirectToSurvey(memberId: string): void {
        if (!location.search) {
            this.httpClient.get(`/api/members/${ memberId }/surveys`).subscribe((surveys) => {
                this.checkForSurveyInterrupt(surveys as ScheduledSurvey[], memberId);
            });
        }
    }

    resolveSurveyInterrupt = (memberId: string) => {
        return new Promise<void>((resolve) => {
            if (!this.isMobileDevice) {
                this.checkIfShouldRedirectToSurvey(memberId);
            }
            resolve();
        });
    };

}
