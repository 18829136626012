<div class="challenge-onboarding-device-order-container">
    <div class="challenge-onboarding-blocker-modal-title text-clr-oxford-blue">
        <span
            class="device-order-card text-clr-oxford-blue"
            [translate]="'YouAreIn'">
            You're in the challenge!
        </span>
    </div>
    <div class="card-middle">
        <div
            class="text-clr-oxford-blue"
            [ngClass]="isMaxBuzzAndTrackManuallyDisabled ? 'old-device-order-subtitle' : 'device-order-subtitle'">
            <div
                *ngIf="!isMaxBuzzAndTrackManuallyDisabled"
                [translate]="'SyncYourActivity'">
                Now sync your activity to make those steps count.
            </div>
            <ng-container *ngIf="isMaxBuzzAndTrackManuallyDisabled">
                <div
                    class="font-weight-bold"
                    [translate]="'AlreadyTrackYourSteps'">
                    Already track your steps?
                </div>
                <div [translate]="'SelectAppAndSyncSteps'">Select your app or device and sync your steps.</div>
            </ng-container>
        </div>
        <div class="devices-image">
            <img [src]="'/img/vp-go/how-to-track-device-icons.png'" />
        </div>
        <div
            class="device-option-content"
            *ngIf="!isMaxBuzzAndTrackManuallyDisabled">
            <div class="content-wrapper pull-left">
                <div
                    class="wrapper-text font-weight-bold"
                    [translate]="'ConnectDeviceApp'">
                    Connect a device or app
                </div>
                <div
                    class="wrapper-text"
                    [translate]="'UploadActivity'">
                    It's the easiest way to upload your activity.
                </div>
                <button
                    class="vp-button vp-button-primary-inverse bordered device-order-button"
                    [translate]="'ConnectADevice'"
                    (click)="goToConnectDeviceBoard()">
                    Connect a Device
                </button>
            </div>
            <div class="content-wrapper pull-right">
                <ng-container *ngIf="isMaxBuzzEnabled">
                    <div
                        class="wrapper-text font-weight-bold"
                        [translate]="'DoNotHaveActivityTracker'">
                        Don't have an activity tracker?
                    </div>
                    <div
                        class="wrapper-text"
                        [translate]="'OrderComplementaryToday'">
                        Order a complementary one today.
                    </div>
                    <button
                        class="vp-button vp-button-primary-inverse bordered device-order-button"
                        [translate]="'OrderTracker'"
                        (click)="data.goToCard(vpGoService.VpGoCards.MAX_BUZZ_ADDRESS_COLLECTION_CARD_INDEX)">
                        Order Tracker
                    </button>
                    <div
                        class="disclaimer-text"
                        [translate]="isPersonifyHealthTheme ? 'TrackerUsedWithPH' : 'TrackerUsedOnlyVP'">
                    </div>
                </ng-container>

                <ng-container *ngIf="isTrackManuallyEnabled">
                    <div
                        class="wrapper-text font-weight-bold"
                        [translate]="'TrackManually'">
                        Track manually
                    </div>
                    <div
                        class="wrapper-text"
                        [translate]="'NoDeviceNoProblem'">
                        No device? No problem. Enter your activity as you go.
                    </div>
                    <button
                        [translate]="'TrackManually'"
                        class="vp-button vp-button-primary-inverse bordered device-order-button"
                        (click)="goToChallengeTeam()">
                        TRACK MANUALLY
                    </button>
                </ng-container>
            </div>
        </div>

        <div
            class="device-order-subtitle"
            *ngIf="isMaxBuzzAndTrackManuallyDisabled">
            <div
                class="font-weight-bold"
                [translate]="'WantToUseYourPhone'">
                Want to use your phone?
            </div>
            <div [translate]="'WhenDownloadApp'">
                When you download the Virgin Pulse mobile app, you can set up your phone to track steps.
            </div>
        </div>
        <div
            class="buttons-section"
            *ngIf="isMaxBuzzAndTrackManuallyDisabled">
            <button
                class="vp-button vp-button-primary-inverse bordered old-device-order-button"
                [translate]="'ConnectADevice'"
                (click)="goToConnectDeviceBoard()"></button>
            <button
                [translate]="'Done'"
                class="vp-button vp-button-primary-inverse bordered old-device-order-button"
                (click)="goToChallengeTeam()">
                DONE
            </button>
        </div>
    </div>
</div>
