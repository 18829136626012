<div role="list"
    class="booking"
    [ngClass]="{ 'live-services-booking': isLiveServices, 'transform-booking': !isLiveServices }"
    *ngIf="!isLoading">
    <div *ngIf="isLiveServices"
         class="back-button">
        <a *ngIf="(topicService.topicInternalName === singleTopicType.NextStepsConsult ||
                   topicService.topicInternalName === singleTopicType.TobaccoFree ||
                   topicService.topicInternalName === singleTopicType.Onsite) && !isPackageTopic"
           id="scheduler-back-button"
           (click)="goToLandingPage()"
           href="javascript: void(0)"
           class="vp-text-link">
            <em class="fa fa-chevron-left"></em> Back
        </a>
        <a *ngIf="isPackageTopic && !isActiveInCoaching"
           href="{{ liveServicesRoutes.Topics | href }}"
           class="vp-text-link">
            <em class="fa fa-chevron-left"></em> Back to Topic
        </a>
        <a *ngIf="isPackageTopic && isActiveInCoaching"
           href="{{ liveServicesRoutes.LegacyHome | href }}"
           class="vp-text-link">
            <em class="fa fa-chevron-left"></em> Back
        </a>
    </div>
    <div class="schedule-appointment-title">
        <div class="center-content font-24"
             id="{{ schedulingTitleId }}">
             {{ rescheduleId ? 'Reschedule Session' : 'Schedule Appointment' }}
            </div>
    </div>
    <div *ngIf="isLiveServices">
        <div *ngIf="!isActiveInCoaching || (rescheduleId && memberEngagement.engagementStatus !== constants.ENGAGED_STATUS)"
             class="live-services-section">
            <div class="section-header-n topic-header">
                <div class="topic-name">{{ topicService.topicName }}</div>
                <a *ngIf="isPackageTopic && !rescheduleId"
                   id="scheduler-change-topic-link"
                   class="change-topic vp-text-link"
                   href="{{ liveServicesRoutes.Topics | href }}">
                    Change Topic
                </a>
            </div>
            <div *ngIf="topicService.description"
                 class="topic-content">
                <img class="topic-image"
                     [src]="topicService.imageUrl"
                     alt="{{ topicService.topicName }}"/>
                <div class="topic-description" [innerHTML]="topicService.description"></div>
            </div>
        </div>

        <div *ngIf="topicService.topicInternalName === singleTopicType.Onsite"
             class="live-services-section">
            <div class="section-header-n">
                Location of Appointment

            </div>
            <p class="section-description note">This is where your appointment will be held. Video and phone calls
                are also available. Once you schedule, your coach will reach out for your personal preference.</p>
            <div class="dropdown">
                <button class="toggle-dropdown"
                        type="button"
                        id="scheduler-onsite-location-button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true">
                    <span *ngIf="!selectedLocation"
                          class="dropdown-default">
                        Select a location convenient for you
                    </span>
                    <span *ngIf="selectedLocation"
                          id="scheduler-onsite-{{ selectedLocation.locationName | lowercaseWithHyphens }}-link">{{ selectedLocation.locationName }}</span>
                    <em class="fa fa-caret-down"></em>
                </button>
                <ul class="dropdown-menu background-clr-white"
                    aria-labelledby="locationDropdown">
                    <div *ngFor="let state of groupedLocations | keyvalue">
                        <li class="dropdown-header text-clr-vp-dark-gray">{{ state.key }}</li>
                        <li role="separator"
                            class="divider">
                        </li>
                        <li *ngFor="let location of state.value; index as i"
                            class="dropdown-item">
                            <a [id]="'scheduler-onsite-' + (location.locationName | lowercaseWithHyphens) + '-link'"
                               href="javascript: void(0)"
                               class="text-clr-vp-dark-gray"
                               (click)="selectLocation(location)">
                                {{ location.locationName }}
                            </a>
                        </li>
                    </div>
                </ul>
            </div>
            <p *ngIf="noLocations"
               class="text-clr-red error">
                <strong>No Locations Available</strong><br />
                We are unable to find any available locations. Please try again later. If the issue persists, please contact Member Services for assistance.</p>
        </div>

        <div *ngIf="topicService.topicInternalName === singleTopicType.NextStepsConsult">
            <div class="row border-bottom-primary vp-padding-top-20 padding-bottom-10">
                <div class="col-md-12">
                    <strong class="font-18 padding-right-30">Discussion Topics</strong>
                </div>
            </div>
            <div class="discussion-checkbox">
                <div class="checkbox-row">
                    <div class="checkbox-container"
                         role="checkbox"
                         (click)="toggleCoronavirus()"
                         [ngClass]="{ 'background-clr-success checked': isCoronavirusChecked }">
                        <em class="text-clr-white">✓</em>
                    </div>
                    <span class="discussion-text"> I'd like to discuss <strong>coronavirus (COVID-19)</strong> on the call.</span>
                </div>
            </div>
        </div>
    </div>

    <div class="live-services-section">
        <div class="section-header-n">
            Choose a Date and Time
        </div>
        <p class="section-description">
            <span>Times shown in your local time zone.</span>
        </p>
    </div>

    <div class="time-picker-row">
        <div class="calendar-flex">
            <div class="pr-3 pb-3">
                <div *ngIf="isLiveServices && isOnsiteTopic && !selectedLocation"
                     class="calendar-blocker">
                    <p>Choose your location to see available times</p>
                </div>
                <p [hidden]="true">Clicking on the following date buttons will update the content below</p>
                <bs-datepicker-inline
                    #bsDatePicker
                    id="bsDatePicker"
                    [bsConfig]="{ containerClass: (isLiveServices ? 'live-services' : 'transform') + '-date-picker', showWeekNumbers: false }"
                    [bsValue]="selectedDate"
                    [minDate]="minDate"
                    [maxDate]="maxDate"
                    [datesDisabled]="disabledDates"
                    (bsValueChange)="onDateSelectionChange($event)"
                    [ngClass]="{'blur': isOnsiteTopic && !selectedLocation}"
                    [hidden]="isLoadingDates"
                    tabindex="0"
                    [attr.aria-label]="'Clicking on the following date buttons will update the content below'">
                </bs-datepicker-inline>
            </div>
        </div>

        <div class="time-flex">
            <ng-template #slotLoader>
                <div>Loading...</div>
                <div class="loading-gif-centered">
                    <img [src]="'img/loader3.svg' | assetUrl"
                         alt="" />
                </div>
            </ng-template>

            <div *ngIf="!isLoadingDailySlots && !isLoadingDates; else slotLoader"
                 class="slot-list">
                <div *ngIf="selectedDate && timeslotsForSelectedDate !== undefined"
                     class="section-description">
                    {{ timeZoneUtil.formatEnglish(selectedDate, 'dddd, MMMM Do') }}
                </div>
                <div *ngIf="timeslotsForSelectedDate !== undefined"
                     class="center-content">
                    <button *ngIf="timeslotsForSelectedDate.length > TIMESLOTS_TO_DISPLAY"
                            id="scheduler-time-picker-back-button"
                            [ngClass]="{'not-active': timeslotDisplayStartIndex === ZERO}"
                            [disabled]="timeslotDisplayStartIndex === ZERO"
                            class="padding-bottom-5 calendar-button"
                            title="Earlier times"
                            (click)="scrollUpClicked()">
                        <em class="fa fa-chevron-up"></em>
                    </button>
                    <vpg-filter-chips-group
                        *ngIf="!isReloadingChips && isLiveServices"
                        (selected)="selectLSTimeslot($event)">
                        <vpg-filter-chip
                            id="timeslot"
                            *ngFor="let timeslot of timeslotsForDisplay; let i = index"
                            [id]="'scheduler-time-picker-slot-' + i + '-button'"
                            [value]="i.toString()"
                            [label]="formatMomentForTimeView(timeslot.startTime)">
                        </vpg-filter-chip>
                    </vpg-filter-chips-group>
                    <div *ngIf="!isLiveServices">
                        <div [ngClass]="{'selected-timeslot': selectedTimeslot === timeslot}"
                            class="timeslots"
                            *ngFor="let timeslot of timeslotsForDisplay; let i = index"
                            [attr.aria-label]="selectedTimeslot === timeslot ? 'selected' : ''">
                            <button id="scheduler-time-picker-slot-{{ i }}-button"
                                    class="timeslot calendar-button"
                                    (click)="selectTimeslot(timeslot)">
                                {{ formatMomentForTimeView(timeslot.startTime) }}
                            </button>
                        </div>
                    </div>
                    <button [ngClass]="{'not-active': timeslotDisplayEndIndex === timeslotsForSelectedDate.length}"
                            id="scheduler-time-picker-next-button"
                            [disabled]="timeslotDisplayEndIndex === timeslotsForSelectedDate.length"
                            class="calendar-button"
                            title="Later times"
                            *ngIf="timeslotsForSelectedDate.length > TIMESLOTS_TO_DISPLAY"
                            (click)="scrollDownClicked()">
                        <em class="fa fa-chevron-down"></em>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="live-services-section">
        <div *ngIf="isOnsiteTopic"
             class="section-header-n">
            Contact Information
        </div>
        <p *ngIf="isOnsiteTopic"
           class="section-description">
            Provide your phone number in case there are last minute updates to your appointment.
        </p>
        <div *ngIf="!isOnsiteTopic"
             class="section-header-n">
            Phone Number
        </div>
        <p *ngIf="!isOnsiteTopic"
           class="section-description">
            This is where your coach will call you.
        </p>
    </div>

    <div class="border-bottom-primary phone-input">
        <div class="live-services-book-appointment-phone">
            <form #f="ngForm"
                  [formGroup]="phoneForm"
                  class="display-inline">
                <ngx-intl-tel-input class="book-appointment-phone display-inline"
                                    [cssClass]="'{\'ng-invalid\': !isPhoneNumberValid'"
                                    [preferredCountries]="preferredCountries"
                                    [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="false"
                                    [selectFirstCountry]="true"
                                    [selectedCountryISO]="countryCode"
                                    [maxLength]="30"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [phoneValidation]="true"
                                    id="bookAppointment-cellPhoneNumber"
                                    title="Phone Number"
                                    name="phone"
                                    formControlName="phone"
                                    [(ngModel)]="phoneNumber"
                                    (keyup)="validatePhoneNumber()">
                </ngx-intl-tel-input>
            </form>
            <div *ngIf="!!cellPhoneError"
                 class="clearfix"
                 aria-live="polite">
                <p class="error-container">
                <span class="invalid-input"
                      tabIndex="0">
                    Invalid Number
                </span>
                </p>
            </div>
            <div *ngIf="emptyFieldError"
                 class="clearfix"
                 aria-live="polite">
                <p class="error-container">
                <span class="invalid-input"
                      tabindex="0">
                    Please select a date and time, and enter your phone number.
                </span>
                </p>
            </div>
        </div>
    </div>
    <div class="live-services-book-appointment-button">
        <vpg-button
            [id]="confirmAppointmentButtonId"
            class="book-appointment-button"
            (click)="submitAndBookAppointment()"
            [isDisabled]="loadingAppointmentData || !selectedTimeslot"
            [label]="confirmAppointmentButtonText">
        </vpg-button>

        <div *ngIf="loadingAppointmentData"
             class="loading-message-container">
            <div class="loading-spinner"></div>
            <p class="loading-message">
                Confirming Appointment...</p>
        </div>

        <div *ngIf="bookingError"
             class="clearfix"
             aria-live="polite">
            <p class="error-container">
            <span class="invalid-input"
                  tabindex="0">
                &#9432; Oops, there looks like there was an error confirming your appointment.
                Please wait a moment and try again.
            </span>
            </p>
        </div>
        <div *ngIf="appointmentTakenError"
             class="clearfix"
             aria-live="polite">
            <p class="error-container">
            <span class="invalid-input"
                  tabindex="0">
                Looks like someone else just booked this time slot.
                Please choose another time that works for you.
            </span>
            </p>
        </div>
    </div>
</div>

<div *ngIf="isLoading"
     class="loading-gif-centered">
    <img [src]="'img/loader3.svg' | assetUrl"
         alt=""/>
</div>

<vpg-dialog [(open)]="showRescheduleConfirmationModal"
    (openChange)="modalChange($event)">
    <vpg-dialog-header  closeButtonAriaLabel="Close modal">
        <p  class="reschedule-confirmation-modal-header"
            id="reschedule-confirmation-modal-title">
            New Session
        </p>
    </vpg-dialog-header>
    <vpg-dialog-body
        class="reschedule-confirmation-modal-body"
        [id]="'reschedule-confirmation-modal-content'">
        <p class="body-large"
            id="session-changed-text">
            Your session has been changed to:
        </p>
        <p class="content-appointment-date"
            id="date=and-time-of-appointment">
            <strong> {{ timeZoneUtil.formatEnglish(newAppointmentStartDate, 'dddd, MMMM Do [at] h:mm A') }} -
                 {{ timeZoneUtil.formatEnglish(newAppointmentEndDate, 'h:mm A') }} </strong>
        </p>
    </vpg-dialog-body>
    <vpg-dialog-actions class="reschedule-confirmation-modal-actions">
        <button
            id="reschedule-confirmation-modal-confirm-button"
            vpg-button
            variant="primary"
            size="medium"
            (click)="closeRescheduleModal()">
            Okay
        </button>
    </vpg-dialog-actions>
</vpg-dialog>



