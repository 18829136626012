import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ImageErrorComponent } from './image-error.component';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ImageErrorComponent
    ],
    exports: [
        ImageErrorComponent
    ]
})
export class ImageErrorModule { }
