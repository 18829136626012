import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

import { MaximumTrackersAddedModalService } from '@genesis-frontend/genesis-features';
import { HttpResponse, Participant, TrackerData, AuthenticatedMemberService } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class HealthyHabitsService {
    trackerParticipants: Participant[] = [];
    trackerIdMap: TrackerData[] = [];
    topicHabits: TrackerData[] = [];

    constructor(
        private httpClient: HttpClient,
        private authenticatedMemberService: AuthenticatedMemberService,
        private maximumTrackersAddedModalService: MaximumTrackersAddedModalService,
        private translateService: TranslateService
    ) {}

    getMemberTrackers(): TrackerData[] {
        return this.trackerIdMap;
    }

    addHealthyHabitByTrackerId(trackerId: number) {
        const memberId = this.authenticatedMemberService.getAuthenticatedMemberId();

        return this.httpClient.put(this.MEMBER_TRACKER_API_ENDPOINT(memberId, trackerId), {}).subscribe(
            (response: any) => {
                this.reorderTrackerFrom(0);
                const newTracker = response.tracker;
                newTracker.memberTrackerId = response.id;
                newTracker.pillarColor = response.pillarColor;
                newTracker.orderIndex = 0;
                newTracker.expiryDatePassed = null;
                newTracker.partnerTrackers = response.partnerTrackers;
                newTracker.memberEnabled = true;
                this.trackerIdMap.push(newTracker);
                this.setMemberHabit(trackerId, true);
                this.mapParticipantCountToTracker(trackerId);
                return newTracker;
            },
            (error) => {
                if (error.status === HttpResponse.NotAcceptable) {
                    this.maximumTrackersAddedModalService.openModal();
                } else {
                    this.showError(error);
                }
                const mappedTracker = this.trackerIdMap.find((t) => t.id === trackerId);
                if (mappedTracker) {
                    mappedTracker.memberEnabled = false;
                }
                return mappedTracker;
            }
        );
    }
    reorderTrackerFrom(orderIndex: number): void {
        let increment = false;
        let i = 0;

        for (i; i < this.trackerIdMap.length; i++) {
            const tracker = this.trackerIdMap[i];
            if (tracker.orderIndex && tracker.orderIndex === orderIndex) {
                increment = true;
                break;
            }
        }

        this.trackerIdMap.forEach((tracker) => {
            if (tracker.orderIndex && tracker.orderIndex >= orderIndex) {
                if (increment) {
                    tracker.orderIndex++;
                } else {
                    tracker.orderIndex--;
                }
            }
        });
    }

    setMemberHabit(habitId: number, isMemberEnabled: boolean) {
        if (!this.topicHabits) {
            return;
        }

        const memberHabit = this.topicHabits.find((t) => t.id === habitId);

        if (memberHabit) {
            memberHabit.memberEnabled = isMemberEnabled;
        }

        return memberHabit;
    }

    mapParticipantCountToTracker(trackerId: number) {
        if (!this.trackerParticipants) {
            return;
        }

        const habitParticipant = this.trackerParticipants.find((participants) => trackerId === participants.trackerId);
        if (habitParticipant) {
            const mappedTracker = this.trackerIdMap.find((t) => t.id === trackerId);
            if (mappedTracker) {
                mappedTracker.participantCount = habitParticipant.participantsCount;
            }
        }
    }

    showError(errorMessage: string) {
        return Swal.fire({
            titleText: this.translateService.instant('Oops'),
            html: '<div aria-hidden="true">' + this.translateService.instant(errorMessage) + '</div>',
            icon: 'error',
            buttonsStyling: false,
            confirmButtonAriaLabel: this.translateService.instant(errorMessage) + ' ' + this.translateService.instant('OkayButton'),
            customClass: {
                confirmButton: 'vp-button vp-button-primary'
            }
        });
    }

    private MEMBER_TRACKER_API_ENDPOINT = (memberId: number, trackerId: number) => {
        return `/api/members/${memberId}/trackers/${trackerId}`;
    };
}
