import type { Shape } from 'createjs-module';

export class BubbleCelebrationParticle {
    public radius = 0;
    public x = 0;
    public y = 0;
    public element?: Shape;

    constructor(
      public diameter: number,
      public opacity: number,
      public scale: number,
      public color: string
    ) {
        const HALF = 0.5;
        this.radius = diameter * HALF;
    }
}
