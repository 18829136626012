import { Component } from '@angular/core';

@Component({
    selector: 'genesis-frontend-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
    errorTitle = 'UnableToLoadCoaching';
    errorMessage = 'TryRefreshingScreen';
}
