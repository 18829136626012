import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { SponsorProgram } from '../models/interfaces/sponsor-program.model';

@Injectable({
    providedIn: 'root'
})
export class HraService {

    constructor(
        private httpClient: HttpClient
    ) { }

    updateHRA(memberId: number) {
        return this.httpClient.put(
            `api/members/${memberId}/HRA`,
            {}
        );
    }

    getProviderInfo(memberId: number): Observable<any> {
        return this.httpClient.get(`/api/members/${memberId}/HRA/provider`);
    }

    getMemberHRA(memberId: number): Observable<any> {
        return this.httpClient.get(`/api/members/${memberId}/HRA`);
    }

    getProgram(sponsorId: number): Observable<SponsorProgram> {
        return this.httpClient.get<SponsorProgram>(`/api/sponsors/${sponsorId}/rewards-programs/current`);
    }
}
