<vpg-dialog-header class="align-items-center mb-lg">
    <div class="text-transform-none">
        {{ calendarEvent.title }}
    </div>
</vpg-dialog-header>
<vpg-dialog-body>
    <div class="mb-lg">
        <h3 class="font-weight-bold text-transform-none mb-sm body-large">{{ date }}</h3>
        <div class="body-large">
            <p class="mb-sm"> {{ calendarEvent.location }} </p>
            <p class="mb-sm">{{ calendarEvent.address }}</p>
        </div>
        <div class="body-large">
            <p class="mb-lg" *ngIf="calendarEvent.contactPerson">
                Contact Person: {{ calendarEvent.contactPerson }}
                <a *ngIf="calendarEvent.contactEmail"
                   href="mailto: {{calendarEvent.contactEmail}}">
                    ({{ calendarEvent.contactEmail }})
                </a>
        </div>
        <div *ngIf="calendarEvent.allowRsvp && rsvpInfoLoaded">
            <p class="body-large font-weight-bold mb-lg"> {{ TRANSLATIONS.ARE_YOU_GOING | translate }}</p>
            <div class="checkbox-rsvp">
                <vpg-checkbox
                    [ngModel]="memberRsvp"
                    id="sales"
                    class="mb-md"
                    size="medium"
                    [label]="TRANSLATIONS.YES_I_AM_IN | translate "
                    (change)="setRsvp()"
                    >
                    {{ (memberRsvp ? TRANSLATIONS.YES_I_AM_IN : TRANSLATIONS.I_AM_GOING) | translate }}
                </vpg-checkbox>
            </div>
        </div>
        <calendar-event-export
            [calendarEvent]="calendarEvent">
        </calendar-event-export>
    </div>
    <vpg-divider-line class="mb-lg"></vpg-divider-line>
    <div class="body-large" [innerHTML]="calendarEvent.description"></div>
</vpg-dialog-body>
