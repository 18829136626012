import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { isEmpty } from '../../../src';
import { AudienceEntities } from '../interfaces/audience-entities.model';
import { AudienceObjectEntities } from '../interfaces/audience-object-entities.model';

@Injectable()
export class AudienceSelectorService {
    constants = {
        ALL_AUDIENCE_TYPE: 0,
        COMPANY_AUDIENCE_TYPE: 1,
        BU_AUDIENCE_TYPE: 2,
        OFFICE_AUDIENCE_TYPE: 3,
        REWARD_POPULATION_AUDIENCE_TYPE: 4,
        AUDIENCE_BU_PROPERTY: 'businessUnitId',
        AUDIENCE_OFFICE_PROPERTY: 'officeId',
        AUDIENCE_COMPANY_PROPERTY: 'companyId',
        AUDIENCE_SPONSOR_PROPERTY: 'sponsorId',
        ENTITY_CONTEST: 'Contest',
        ENTITY_ANNOUNCEMENT: 'Announcement',
        ENTITY_SURVEY: 'Survey',
        ENTITY_CALENDAR_EVENT: 'CalendarEvent',
        ENTITY_WIDGET: 'Widget'
    };

    constructor(
        private http: HttpClient,
        private translateService: TranslateService
    ) {}

    getSelectedNavItem(audience: any[]) {
        if (!audience.length && !audience[0]) {
            return this.constants.ALL_AUDIENCE_TYPE;
        }

        if (audience[0].officeId) {
            return this.constants.OFFICE_AUDIENCE_TYPE;
        }

        if (audience[0].businessUnitId) {
            return this.constants.BU_AUDIENCE_TYPE;
        }

        if (audience[0].companyId) {
            return this.constants.COMPANY_AUDIENCE_TYPE;
        }

        if (audience[0].currencyCode || audience[0].rewardPopulationId) {
            return this.constants.REWARD_POPULATION_AUDIENCE_TYPE;
        }

        return this.constants.ALL_AUDIENCE_TYPE;
    }

    getAudienceListFromSelection(
        selectedAudienceNavItem: number,
        entityId: number,
        entityType: string,
        selectedCompanies: object,
        selectedBusinessUnits: object,
        selectedOffices: object,
        selectedSponsors?: object
    ) {
        switch (selectedAudienceNavItem) {
        case this.constants.OFFICE_AUDIENCE_TYPE:
            return this.getAudienceForEntity({
                audienceType: this.constants.AUDIENCE_OFFICE_PROPERTY,
                entityId: entityId,
                entityType: entityType,
                selectedAudienceList: selectedOffices,
                selectedSponsors: selectedSponsors
            });
        case this.constants.BU_AUDIENCE_TYPE:
            return this.getAudienceForEntity({
                audienceType: this.constants.AUDIENCE_BU_PROPERTY,
                entityId: entityId,
                entityType: entityType,
                selectedAudienceList: selectedBusinessUnits,
                selectedSponsors: selectedSponsors
            });
        case this.constants.COMPANY_AUDIENCE_TYPE:
            return this.getAudienceForEntity({
                audienceType: this.constants.AUDIENCE_COMPANY_PROPERTY,
                entityId: entityId,
                entityType: entityType,
                selectedAudienceList: selectedCompanies,
                selectedSponsors: selectedSponsors
            });
        default:
            return selectedSponsors
                ? this.getAudienceForEntity({
                    selectedSponsors: selectedSponsors,
                    audienceType: this.constants.AUDIENCE_SPONSOR_PROPERTY,
                    entityId: entityId,
                    entityType: entityType
                }) : null;
        }
    }

    getAudienceForEntity(audienceEntities: AudienceEntities): any[] {
        const audience: any = [];
        audienceEntities.selectedAudienceList.forEach((selectedAudience: any) => {
            audience.push(this.getAudienceObjectForEntity({
                audienceType: audienceEntities.audienceType,
                entityId: audienceEntities.entityId,
                entityType: audienceEntities.entityType,
                selectedAudience: selectedAudience,
                selectedSponsors: audienceEntities.selectedSponsors
            }));
        });
        return audience;
    }

    getAudienceObjectForEntity(audienceEntities: AudienceEntities) {
        const audienceObject: AudienceObjectEntities = {
            companyId: audienceEntities.selectedAudience.companyId
        };

        if (audienceEntities.audienceType === this.constants.AUDIENCE_OFFICE_PROPERTY) {
            audienceObject.officeId = audienceEntities.selectedAudience.id;
        } else if (audienceEntities.audienceType === this.constants.AUDIENCE_BU_PROPERTY) {
            audienceObject.businessUnitId = audienceEntities.selectedAudience.id;
        } else if (audienceEntities.audienceType === this.constants.AUDIENCE_COMPANY_PROPERTY) {
            audienceObject.companyId = audienceEntities.selectedAudience.id;
        }

        switch (audienceEntities.entityType) {
        case this.constants.ENTITY_CALENDAR_EVENT:
            audienceObject.calendarEventId = audienceEntities.entityId || null;
            break;
        case this.constants.ENTITY_CONTEST:
            audienceObject.contestId = audienceEntities.entityId || null;
            if (audienceEntities.selectedSponsors) {
                audienceObject.sponsorId = audienceEntities.selectedSponsors[0].id || null;
            }
            break;
        case this.constants.ENTITY_SURVEY:
            audienceObject.surveyId = audienceEntities.entityId || null;
            break;
        case this.constants.ENTITY_WIDGET:
            audienceObject.widgetId = audienceEntities.entityId || null;
            break;
        case this.constants.ENTITY_ANNOUNCEMENT:
            audienceObject.announcementId = audienceEntities.entityId || null;
        }
        return audienceObject;
    }

    isAudienceSelected(selectedAudienceItem:any, selectedCompanies:any, selectedBusinessUnits:any, selectedOfficeLocations:any) {
        switch (selectedAudienceItem) {
        case this.constants.ALL_AUDIENCE_TYPE:
            return true;
        case this.constants.COMPANY_AUDIENCE_TYPE:
            return !isEmpty(selectedCompanies);
        case this.constants.BU_AUDIENCE_TYPE:
            return !isEmpty(selectedCompanies) && !isEmpty(selectedBusinessUnits);
        case this.constants.OFFICE_AUDIENCE_TYPE:
            return !isEmpty(selectedCompanies) && !isEmpty(selectedOfficeLocations);
        default:
            return false;
        }
    }

    updateSelectedAudienceSummary(selectedOfficeLocations: any[], selectedCompanies: any[], selectedBusinessUnits: any[], sponsor:any) {
        let locationsList:any[] = [];
        let totalEligibleMembers = 0;
        let totalEntities = 0;
        let selectedLocations:any[] = [];
        let entityName = '';
        let eligibleMemberString = '';
        if (!isEmpty(selectedOfficeLocations)) {
            locationsList = selectedOfficeLocations.filter((obj) => {
                if (obj.selected) {
                    return obj;
                }
            });
            if (locationsList.length === 1) {
                entityName = 'Office Location';
            } else {
                entityName = 'Office Locations';
            }
        } else if (!isEmpty(selectedBusinessUnits)) {
            locationsList = selectedBusinessUnits.filter((obj) => {
                if (obj.selected) {
                    return obj;
                }
            });
            if (locationsList.length === 1) {
                entityName = 'Business Unit';
            } else {
                entityName = 'Business Units';
            }
        } else if (!isEmpty(selectedCompanies)) {
            locationsList = selectedCompanies.filter((obj) => {
                if (obj.selected) {
                    return obj;
                }
            });
            if (locationsList.length === 1) {
                entityName = 'Company';
            } else {
                entityName = 'Companies';
            }
        } else {
            return new Observable((subscriber) => {
                this.getSponsorParticipantsCount(sponsor.id)
                    .subscribe((membersCount: any) => {
                        eligibleMemberString = this.translateService.instant('AllOfSponsorMembers',
                            { sponsorName: sponsor.name, count: membersCount.count });
                        subscriber.next(eligibleMemberString);
                    });
            });
        }

        selectedLocations = locationsList.filter((obj) => {
            if (obj.selected) {
                return obj;
            }
        });
        selectedLocations.forEach((obj) => {
            totalEligibleMembers += obj.totalMembers;
        });
        totalEntities += selectedLocations.length;
        eligibleMemberString = this.translateService.instant('TotalMembersCompaniesBUsOfficeLocations',
            { entities: totalEntities, name: entityName, totalMembers: totalEligibleMembers });
        return of({ eligibleMemberString, totalEligibleMembers });
    }

    getSponsorParticipantsCount(sponsorId: number): Observable<any> {
        return this.http.get(`/api/client-admin/sponsors/${sponsorId}/members/count`)
            .pipe(map((response: any) => {
                return {
                    count: response
                };
            }));

    }
}
