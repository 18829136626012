import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'limitNumberPipe' })
export class LimitNumberPipe implements PipeTransform {
    transform(value: number): string {
        if (value > 10) {
            return '10+';
        }
        return value.toString();
    }
}
