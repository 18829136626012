import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AuthenticatedMemberService } from '../../authentication';
import {
    BookAppointmentResponse,
    CoachingAppointmentVM,
    ScheduleAppointmentRequestVM,
    RescheduleAppointmentPayload,
    AppointmentRequestTelephonicVM,
    AppointmentRequestMessagingVM
} from '../../models';
import { WebsocketService } from '../../services';

@Injectable({
    providedIn: 'root'
})
export class AppointmentSchedulingService {
    BASE_LIVE_SERVICES_COACHING_MEMBERS_URI = '/api/live-services-coaching/members';
    BASE_MICROSERVICE_MEMBERS_URI = '/live-services-api/live-services-coaching/members';

    private appointmentSubject = new BehaviorSubject<CoachingAppointmentVM | undefined>(undefined);
    appointmentConfirmation$ = this.appointmentSubject.asObservable();

    private appointmentConfirmingSubject = new BehaviorSubject<boolean>(false);
    isConfirmationPending$ = this.appointmentConfirmingSubject.asObservable();

    private bookAppointmentChannel: any;

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private httpClient: HttpClient,
        private webSocketService: WebsocketService
    ) {
        this.onBookAppointment = this.onBookAppointment.bind(this);
        this.onBookAppointmentFailure = this.onBookAppointmentFailure.bind(this);
    }

    private connectPusher() {
        const member = this.authenticatedMemberService.getAuthenticatedMember();

        const bookAppointmentChannel = this.webSocketService.connect('live-services-booking-' + member.id);

        bookAppointmentChannel.on('scheduleAppointmentSuccess', this.onBookAppointment);
        bookAppointmentChannel.on('scheduleAppointmentFailure', this.onBookAppointmentFailure);

        this.bookAppointmentChannel = bookAppointmentChannel;
    }

    private resetAppointmentSubject() {
        this.appointmentSubject = new BehaviorSubject<CoachingAppointmentVM | undefined>(undefined);
        this.appointmentConfirmation$ = this.appointmentSubject.asObservable();
    }

    bookAppointment(scheduleAppointmentRequestVM: ScheduleAppointmentRequestVM) {
        this.resetAppointmentSubject();

        this.connectPusher();
        this.httpClient.post<CoachingAppointmentVM>(
            `${this.BASE_LIVE_SERVICES_COACHING_MEMBERS_URI}/${scheduleAppointmentRequestVM.memberId}/appointments`,
            scheduleAppointmentRequestVM
        ).pipe(
            tap(() => this.appointmentConfirmingSubject.next(true)),
            catchError((error) => {
                this.appointmentSubject.error('Error in submitting scheduling request: ' + error);
                return error;
            })
        ).subscribe();

        return this.appointmentConfirmation$;
    }

    rescheduleAppointment(reschedulePayload: RescheduleAppointmentPayload) {
        this.resetAppointmentSubject();

        this.connectPusher();
        this.httpClient.put<AppointmentRequestTelephonicVM>(
            `${this.BASE_MICROSERVICE_MEMBERS_URI}/${reschedulePayload.memberId}/appointments/${reschedulePayload.appointmentId}`,
            reschedulePayload.rescheduleAppointmentRequest
        ).pipe(
            tap(() => this.appointmentConfirmingSubject.next(true)),
            catchError((error) => {
                this.appointmentSubject.error('Error in submitting rescheduling request: ' + error);
                return error;
            })
        ).subscribe();

        return this.appointmentConfirmation$;
    }

    requestCoachMessaging(topicInternalName: string): Observable<AppointmentRequestMessagingVM> {
        const memberId = this.authenticatedMemberService.getAuthenticatedMemberId();
        return this.httpClient.post<AppointmentRequestMessagingVM>(
            `${this.BASE_MICROSERVICE_MEMBERS_URI}/${memberId}/appointment-request-messaging?topicInternalName=${topicInternalName}`,
            {}
        );
    }

    cancelCoachMessaging(openRequest: AppointmentRequestMessagingVM): Observable<AppointmentRequestMessagingVM> {
        return this.httpClient.patch<AppointmentRequestMessagingVM>(
            `${this.BASE_MICROSERVICE_MEMBERS_URI}/${openRequest.memberId}/appointment-request-messaging/${openRequest.id}/cancel`,
            {}
        );
    }

    private onBookAppointment(response: BookAppointmentResponse) {
        if (response.coachingAppointment !== undefined) {
            this.appointmentSubject.next(response.coachingAppointment);
            this.appointmentConfirmingSubject.next(false);
        }

        this.bookAppointmentChannel.unsubscribe();
        this.appointmentSubject.complete();
    }

    private onBookAppointmentFailure(error: any) {
        this.appointmentSubject.error('Error scheduling appointment: ' + error.errorMessage);
        this.appointmentConfirmingSubject.next(false);

        this.bookAppointmentChannel.unsubscribe();
    }
}
