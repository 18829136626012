export enum MemberUpdateAction {
    Accepted = 'Accepted',
    Commented = 'Commented',
    Invited = 'Invited',
    Joined = 'Joined',
    Posted = 'Posted',
    Replied = 'Replied',
    Won = 'Won',
    CommentRemoved = 'CommentRemoved',
    Requested = 'Requested',
    Earned = 'Earned',
    Reminded = 'Reminded'
}
