import { HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { ENVIRONMENT, Environment } from '../config';

type Castle = typeof import('@castleio/castle-js');
declare const System: any;

@Injectable({
    providedIn: 'root'
})
export class CastleService {
    CASTLE_REQUEST_TOKEN = 'Castle-Request-Token';

    constructor(
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
    }

    private async getCastle(): Promise<Castle> {
        const castle = await System.import('@castleio/castle-js');
        const publishableKey = this.environment.castle.publishableKey;
        castle.configure({ pk: publishableKey });
        return castle;
    }

    private async getCastleRequestToken(): Promise<string> {
        const castle = await this.getCastle();
        const token = await castle.createRequestToken();
        return token;
    }

    getCastleHeader(): Promise<HttpHeaders> {
        return this.getCastleRequestToken().then((token) => {
            const headers = new HttpHeaders();
            return headers.append(this.CASTLE_REQUEST_TOKEN, token);
        }).catch(() => new HttpHeaders());
    }
}
