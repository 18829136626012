import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { JourneyCompletedModalComponent } from './journey-completed-modal.component';


@Injectable({ providedIn: 'root' })
export class JourneysCompletedModalService {

    constructor(private modalService: BsModalService) { }

    showJourneyCompletedModal(data: any, isSurveyCompleted: boolean) {
        const { journeyTitle, journeyId } = data;
        const initialState = { journeyTitle, journeyId, isSurveyCompleted };
        return this.modalService.show(JourneyCompletedModalComponent, { initialState, class: 'journey-completed-modal', animated: false });
    }

    close() {
        this.modalService.hide();
    }
}
