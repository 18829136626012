import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { authGuard } from './core/guards/auth.guard';
import { RouteOptions } from './core/resolvers/route-options';
import { match, matchAnyFlag, mfMatchAny } from './core/routing/routing';
import { AuthLayoutComponent } from './layouts/auth-layout.component';
import { BasicLayoutComponent } from './layouts/basic-layout.component';
import { MobileAuthLayoutComponent } from './layouts/mobile-auth-layout.component';
import {
    DevicesMicroFrontendResolver,
    ChallengesPersonalDetailsResolverService,
    ChallengesDashboardResolverService,
    ChallengesPersonalCreateResolverService,
    ChallengesHhResolverService,
    FeaturedChallengesResolverService,
    ChallengesContestInterruptResolverService,
    DownloadSyncResolverService
} from '@app/core/resolvers';
import { EmptyRouteComponent } from '@genesis-frontend/genesis-features';

const authRoutes: Routes = [
    match('redeemcredits', RouteOptions.redeemCreditsRouteOptions),
    match('missingSteps', RouteOptions.missingStepsRouteOptions),
    match('achdeposit', RouteOptions.achdepositRouteOptions),
    matchAnyFlag('download-sync', DownloadSyncResolverService),
    matchAnyFlag('devices', DevicesMicroFrontendResolver),
    matchAnyFlag('mobile/devices', DevicesMicroFrontendResolver),
    matchAnyFlag('challenges/dashboard', ChallengesDashboardResolverService),
    matchAnyFlag('challenges/personal/create', ChallengesPersonalCreateResolverService),
    matchAnyFlag('featuredchallenge', FeaturedChallengesResolverService),
    matchAnyFlag('challenges/healthyhabits', ChallengesHhResolverService),
    matchAnyFlag('challenges/personal/details', ChallengesPersonalDetailsResolverService),
    matchAnyFlag('challenges/:contestId/interrupt', ChallengesContestInterruptResolverService),
    mfMatchAny('stats-page', RouteOptions.statsPage),
    mfMatchAny('mystats', RouteOptions.oldStatsPage),
    mfMatchAny('home', RouteOptions.homeRouteOptions),
    mfMatchAny('member-profile', RouteOptions.memberRouteOptions),
    mfMatchAny('benefits', RouteOptions.benefitsRouteOptions),
    mfMatchAny('my-rewards', RouteOptions.rewardsRouteOptions),
    mfMatchAny('partnerinvite', RouteOptions.partnerInvite),
    mfMatchAny('social', RouteOptions.socialRouteOptions),
    mfMatchAny('coaches-corner', RouteOptions.coachesCornerRouteOptions),
    mfMatchAny('guide', RouteOptions.guidesRouteOptions),
    mfMatchAny('mobile/guide', RouteOptions.guidesRouteOptions),
    mfMatchAny('health-ui', RouteOptions.healthRouteOptions),
    mfMatchAny('media', RouteOptions.mediaRouteOptions),
    mfMatchAny('live-services-coaching', RouteOptions.liveServiceRouteOptions),
    mfMatchAny('live-services-guides', RouteOptions.liveServiceRouteOptions),
    mfMatchAny('journeys', RouteOptions.journeysRouteOptions),
    mfMatchAny('friends', RouteOptions.socialRouteOptions),
    mfMatchAny('allfriends', RouteOptions.socialRouteOptions),
    mfMatchAny('addfriends', RouteOptions.socialRouteOptions),
    mfMatchAny('invitefriends', RouteOptions.socialRouteOptions),
    mfMatchAny('healthyhabits', RouteOptions.healthyHabitsRouteOptions),
    mfMatchAny('groups', RouteOptions.socialRouteOptions),
    mfMatchAny('recognition', RouteOptions.socialRouteOptions),
    mfMatchAny('calendar', RouteOptions.calendarRouteOptions),
    mfMatchAny('newsFlash', RouteOptions.newsFlashRouteOptions),
    mfMatchAny('myfriends', RouteOptions.myFriendsRouteOptions),
    mfMatchAny('vendor', RouteOptions.vendorRouteOptions),
    mfMatchAny('7day-leaderboard', RouteOptions.sevenDayLeaderboardRouteOptions),
    mfMatchAny('surveys-ui', RouteOptions.surveysRouteOptions),
    mfMatchAny('pillars', RouteOptions.pillarsRouteOptions),
    mfMatchAny('steps-leaderboard', RouteOptions.socialRouteOptions),
    mfMatchAny('transform', RouteOptions.transformRouteOptions),
    mfMatchAny('hra/start', RouteOptions.healthRiskAssesment),
    mfMatchAny('holistic-challenges', RouteOptions.holisticChallenges),
    mfMatchAny('preview/boards', RouteOptions.previewBoardsRouteOptions),
    mfMatchAny('tpa-resources', RouteOptions.tpaResourcesRouteOptions),
    mfMatchAny('goal-challenges', RouteOptions.goalChallenges),
    {
        path: 'rewards/creditRedemptionStore',
        redirectTo: '/my-rewards/creditRedemptionStore',
        pathMatch: 'full'
    },
    {
        path: '',
        loadChildren: () => import('./components/components.module').then((m) => m.ComponentsModule)
    },
    {
        path: '**',
        component: EmptyRouteComponent
    }
];

const freeRoutes: Routes = [
    mfMatchAny('welcome.html', RouteOptions.newMiniAppsRouteOptions),
    mfMatchAny('logout.html', RouteOptions.newMiniAppsRouteOptions),
    mfMatchAny('enrollmentSearch.html', RouteOptions.newMiniAppsRouteOptions)
];

const mobileRoutes: Routes = [
    mfMatchAny('webchat', RouteOptions.webchatRouteOptions)
];

const routes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'state',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: '',
        canActivateChild: [authGuard],
        component: MobileAuthLayoutComponent,
        children: mobileRoutes
    },
    {
        path: '',
        component: BasicLayoutComponent,
        children: freeRoutes
    },
    {
        path: '',
        canActivateChild: [authGuard],
        component: AuthLayoutComponent,
        children: authRoutes
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [
        RouterModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppRoutingModule {}
