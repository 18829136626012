import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HealthFormSubmissionsComponent } from './forms-submission/health-form-submissions.component';
import { AttestationFormSubmissionsComponent } from './forms-submission-attestation/attestation-form-submissions.component';
import { PcpFormComponent } from './forms-submission-pcp/pcp-form.component';
import { HraStartComponent } from './hra-start/hra-start.component';
import { NoProductComponent } from './no-product/no-product.component';
import { ZendeskSupportComponent } from './zendesk-support/zendesk-support.component';

const routes: Routes = [
    {
        path: 'zendesk/support',
        component: ZendeskSupportComponent
    },
    {
        path: 'no-product',
        component: NoProductComponent
    },
    {
        path: 'pcp-form/:formName',
        component: PcpFormComponent
    },
    {
        path: 'survey/:formCode',
        component: HealthFormSubmissionsComponent
    },
    {
        path: 'survey-form/:formName',
        component: AttestationFormSubmissionsComponent
    },
    {
        path: 'hra/start',
        component: HraStartComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ComponentsRoutingModule {}
