/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';

import { WebsocketService } from '@app/core/services/websocket.service';
import { AuthenticatedMemberService, MemberProductService } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class PusherConnectionService {
    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private websocketService: WebsocketService,
        private memberProductService: MemberProductService
    ) { }

    getConnection() {
        return new Promise((resolve) => {
            const member = this.authenticatedMemberService.getMember();
            if (this.memberProductService.isWellnessMember()) {
                const connection = this.websocketService.connect('private-notifications-user-' + member.id);
                resolve(connection);
            } else {
                resolve({
                    disable: () => { },
                    enable: () => { },
                    on: () => { },
                    emit: () => { }
                });
            }
        });
    }
}
