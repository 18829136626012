import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as singleSpa from 'single-spa';

import {
    ModuleRegistry,
    PusherConnectionService,
    SynchronizationNotificationService,
    MemberInitializationService,
    LogoutOnTabCloseService,
    ZendeskInitService,
    WalkmeService
} from '@app/core/services';
import { BrandingThemeControlService, HolisticChallengesNotificationsService } from '@genesis-frontend/genesis-data-access';
import { NotificationsMessengerService } from '@genesis-frontend/genesis-features';
import {
    AuthenticatedMemberService,
    FeatureEnum,
    NotificationPaneService,
    SharedServiceLocator,
    Product,
    BlockerChainService,
    CloseModalService,
    RedeemableWalletsService,
    GenesisRewardsProgressService,
    MemberThemeService,
    SurveyService,
    ChallengesInterruptService,
    Environment,
    MemberStatus,
    RewardsTotalSummaryService,
    UnleashFeature,
    UnleashFlagsService,
    UnleashFlag
} from '@genesis-frontend/genesis-utilities';

@Component({
    templateUrl: './auth-layout.component.html'
})
export class AuthLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
    member = this.authenticatedMemberService.getMember();
    notificationPaneEnabled = this.authenticatedMemberService.hasFeature(FeatureEnum.NotificationPane);
    welcomeEnabledGlobal = false;
    disableNotificationsGlobal = false;
    hasUnleashFlag = false;
    isHome = false;
    isClaims = false;
    claimsSummaryRegex = /benefits\/claims\/\d+/;
    isTransformUI = false;
    hideNavigation = false;
    noNavigationUrlRegex =
        '^/no-product|^/surveys-ui/surveys/|^/hra/start|^/preview/boards|^/surveys/|^/survey-readout/|^/challenges/.*/interrupt|^/mobile';
    homeProduct = this.authenticatedMemberService.getHomeMemberProduct();
    blockerOn = true;
    componentSub = new Subscription();
    blockerChainResolved = false;
    microfrontendUrl: SafeUrl = '';
    showNewBackground = false;
    private timeout: any;

    constructor(
        public moduleRegistry: ModuleRegistry,
        private genesisRewardsProgressService: GenesisRewardsProgressService,
        private redeemableWalletsService: RedeemableWalletsService,
        private authenticatedMemberService: AuthenticatedMemberService,
        private memberInitializationService: MemberInitializationService,
        private memberThemeService: MemberThemeService,
        private notificationPaneService: NotificationPaneService,
        private logoutOnTabCloseService: LogoutOnTabCloseService,
        private closeModals: CloseModalService,
        private pusherConnectionService: PusherConnectionService,
        private notificationMessengerService: NotificationsMessengerService,
        private synchronizationNotificationService: SynchronizationNotificationService,
        private router: Router,
        private sharedServiceLocator: SharedServiceLocator,
        private blockerChainService: BlockerChainService,
        private zendeskInitService: ZendeskInitService,
        private walkmeService: WalkmeService,
        private surveyService: SurveyService,
        private challengesInterruptService: ChallengesInterruptService,
        private environment: Environment,
        private domSanitizer: DomSanitizer,
        private rewardsTotalSummaryService: RewardsTotalSummaryService,
        private holisticChallengesNotificationsService: HolisticChallengesNotificationsService,
        private brandingThemeControlService: BrandingThemeControlService,
        private unleashFlagsService: UnleashFlagsService
    ) {
        this.logoutOnTabCloseService.subscribe();
        this.componentSub.add(
            router.events.subscribe((event) => {
                if (event instanceof NavigationStart) {
                    const appNames = ['phhc-challenges-ui', 'phhc-challenges-genesis-ui', 'hh-challenges-ui', 'hh-challenges-genesis-ui', 'personal-challenges-ui', 'personal-challenges-genesis-ui', 'details-challenges-ui', 'details-challenges-genesis-ui', 'challenges-ui'];
                    singleSpa.getAppNames().forEach((app) => {
                        appNames.forEach((regApp) => {
                            if (app === regApp) {
                                singleSpa.unregisterApplication(regApp);
                            }
                        });
                    });
                    this.closeModals.closeOpenedModals();
                    if (this.notificationPaneEnabled) {
                        this.notificationPaneService.turnOffNotificationPane();
                    }
                }
                if (event instanceof NavigationEnd) {
                    this.isHome = event.urlAfterRedirects === '/home' || event.urlAfterRedirects === '/home?openMyInterests=true';
                    if (event.urlAfterRedirects.match('/v2/')) {
                        this.router.navigate([event.urlAfterRedirects.replace('v2', 'surveys-ui')]);
                    }
                    this.isClaims = event.urlAfterRedirects === '/benefits/claims' || this.claimsSummaryRegex.test(event.urlAfterRedirects);
                    this.hideNavigation = !!event.urlAfterRedirects.match(this.noNavigationUrlRegex);
                    this.isTransformUI = event.urlAfterRedirects.startsWith('/transform');
                }
            })
        );
    }

    ngOnInit() {
        this.fetchUnleashFlags();
        window.newShellApp = true;
        this.microfrontendUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.environment.microfrontendCdn.url);
        this.memberThemeService.setMemberTheme();
        this.memberInitializationService.initialCall();
        this.genesisRewardsProgressService.loadRewardsProgressBar(false, null).subscribe();

        if (this.member.status === MemberStatus.Active) {
            this.redeemableWalletsService.loadMemberRedeemableWallets(false).subscribe();
        } else if (this.member.status === MemberStatus.PendingCancel) {
            this.rewardsTotalSummaryService.loadMemberTotalSummary(false).subscribe();
        }

        const sponsorSettings = this.authenticatedMemberService.getSponsorSettings();
        localStorage.setItem('logout_tab', sponsorSettings.logoutAfterClosingTab.toString());
        this.setNewRelicMemberId();

        this.sharedServiceLocator.getAsync('dialogService').then((dialogService) => {
            this.blockerChainService.addPromiseReturningFunction(dialogService.availableTermsBlocker, [this.homeProduct, false]);
            this.blockerChainService.addPromiseReturningFunction(dialogService.passwordExpirationBlocker);
            this.blockerChainService.addPromiseReturningFunction(dialogService.securityQuestionsBlocker);
            this.blockerChainService.addPromiseReturningFunction(dialogService.checkMemberEmailOptIn);
            this.blockerChainService.addPromiseReturningFunction(dialogService.cellPhoneNumberBlocker, [false]);
            this.blockerChainService.addPromiseReturningFunction(dialogService.pillarsSelectionBlocker);

            if (this.homeProduct.product === Product.BasePlatform || this.homeProduct.product === Product.Wellness) {
                this.blockerChainService.addPromiseReturningFunction(dialogService.onboardingChallengesBlocker);
                this.blockerChainService.addPromiseReturningFunction(this.surveyService.resolveSurveyInterrupt, [this.member.id]);
                this.blockerChainService.addPromiseReturningFunction(this.challengesInterruptService.resolveChallengeInterrupt, [this.member.id, sponsorSettings?.onboardingChallengeBlocker]);
                this.blockerChainService.addPromiseReturningFunction(dialogService.rewardsSpouseConsentBlocker);
            }
            this.blockerChainService.executeChain().then((result: boolean) => {
                this.blockerChainResolved = result;
                document.getElementById('loaded')?.remove();
                document.querySelector('body')?.classList.remove('modal-open');
                document.querySelector('body')?.removeAttribute('style');
                document.querySelector('.modal-backdrop')?.remove();
            });
        });
    }

    ngAfterViewInit() {
        this.timeout = setTimeout(() => {
            this.walkmeService.init();
            this.zendeskInitService.init();
            this.getPusherConnections();
            this.setupNotificationWatcher();
        }, 8000);
    }

    fetchUnleashFlags() {
        this.unleashFlagsService.fetchUnleashFlags().subscribe((response: UnleashFeature[]) => {
            this.unleashFlagsService.setUnleashFlags(response);
            this.brandingThemeControlService.setRebrangingFlag();
            this.hasUnleashFlag = this.brandingThemeControlService.unleashRebrandingControlActive;
            this.showNewBackground = this.unleashFlagsService.hasUnleashFlag(UnleashFlag.BrandingUpdatesPhase2);
        });
    }

    getPusherConnections() {
        this.pusherConnectionService.getConnection().then((pusher) => {
            // @ts-ignore
            pusher.on('notify', (notification) => {
                const notificationType = notification.type;
                const notificationMessage = JSON.stringify(notification.message);
                if (notificationType === 'PointsAwarded' || notificationType === 'RewardsEarned') {
                    this.notificationMessengerService.showNotification(notificationMessage);
                }

                if (notificationType === 'HolisticChallengeRivalsChanged') {
                    this.holisticChallengesNotificationsService.addRivalNotification(notification.message);
                }

                if (notificationType === 'HolisticChallengeProgressUpdated') {
                    this.holisticChallengesNotificationsService.addTeamTotalTokensNotification(notification.message);
                }
            });
        });
    }

    setupNotificationWatcher() {
        this.synchronizationNotificationService.init(this.member);
        this.synchronizationNotificationService.subscribe((notification) => {
            if (notification.scope !== 'Member' || notification.status !== 'Complete') {
                return;
            }

            this.authenticatedMemberService.subscribe((newData) => {
                this.member.title = newData.title;
                this.member.location = newData.location;
                this.member.department = newData.department;
                this.member.displayName = newData.displayName;
                this.member.firstname = newData.firstname;
                this.member.lastname = newData.lastname;
                this.member.setProfileImage(newData.profilepicture);
            });
        });
    }

    setNewRelicMemberId() {
        const newRelic = (window as any).newrelic;
        if (newRelic && newRelic.setCustomAttribute) {
            newRelic.setCustomAttribute('genesisMemberId', this.member.id);
        }
    }

    ngOnDestroy() {
        this.componentSub.unsubscribe();
        this.logoutOnTabCloseService.unsubscribe();
        clearTimeout(this.timeout);
    }
}
