import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { StatsCardsComponent } from './stats-cards.component';
import { StatsCardsComponentService } from './stats-cards.component.service';
import { VpgrooveModule } from '@engineering/vpgroove-angular';

@NgModule({
    imports: [
        VpgrooveModule,
        CommonModule
    ],
    declarations: [
        StatsCardsComponent
    ],
    providers: [
        StatsCardsComponentService
    ],
    exports: [
        StatsCardsComponent
    ]
})
export class StatsCardsModule { }
