import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CurrentUser } from './current-user';
import { ProfileQueryGQL, tryCast, Profile } from '../graphql';

@Injectable({ providedIn: 'root' })
export class CurrentUserService {

    private profileQuery = inject(ProfileQueryGQL);
    private currentUser = inject(CurrentUser);

    getProfile(): Observable<CurrentUser> {
        return this.profileQuery.fetch().pipe(
            map((response) => {
                const profile = tryCast<Profile>(response.data.profile, 'Profile');

                if (profile) {
                    this.currentUser.assignProfile(profile);
                }

                return this.currentUser;
            })
        );
    }
}
