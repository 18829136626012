type Am4Core = typeof import('@amcharts/amcharts4/core');
type Am4Charts = typeof import('@amcharts/amcharts4/charts');
type Am4ThemesAnimated = typeof import('@amcharts/amcharts4/themes/animated');
type Am4ThemesAnimatedDefault = typeof import('@amcharts/amcharts4/themes/animated').default;

import { Inject, Injectable } from '@angular/core';

import { Environment, ENVIRONMENT } from '../../config';

declare const System: any;

@Injectable({
    providedIn: 'root'
})
export class AmChartsService {

    am4core?: Am4Core;
    am4charts?: Am4Charts;
    am4themes_animated?: Am4ThemesAnimatedDefault;

    constructor(
        @Inject(ENVIRONMENT) private environment: Environment
    ) {
    }

    async generateV4Chart(chartId: string, chartData: any, chartType: any) {

        const am4core = await this.getAm4CoreReference();
        return am4core.createFromConfig(chartData, chartId, chartType);
    }

    async getAm4CoreReference(): Promise<Am4Core> {
        const am4core = await System.import('@amcharts/amcharts4/core');

        am4core.addLicense(this.environment.amCharts.license);
        this.am4core = am4core;
        return am4core;
    }

    getAm4Charts(): Promise<Am4Charts> {
        return System.import('@amcharts/amcharts4/charts').then((module: Am4Charts) => {
            this.am4charts = module;
            return module;
        });
    }

    getAm4ThemeAnimated(): Promise<Am4ThemesAnimatedDefault> {
        return System.import('@amcharts/amcharts4/themes/animated').then((module: Am4ThemesAnimated) => {
            this.am4themes_animated = module.default;
            return module.default;
        });
    }
}
