<div class="personal-challenge-rules">
    <div [innerHTML]="challengeTemplate.rules"></div>
    <div class="challenge-back-confirm">
        <a *ngIf="!displaySingle"
           class="vp-text-link"
           (click)="setStepEmit(ONE)"
           [translate]="'BackToMyChallenge'">
        </a>
    </div>
</div>
