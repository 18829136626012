import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';

import { TooManyHabitsModalService } from './too-many-habits-modal.service';
import { AuthenticatedMemberService, HealthyHabitsMemberTracker } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'too-many-habits-modal',
    templateUrl: './too-many-habits-modal.component.html',
    styleUrls: ['./too-many-habits-modal.component.scss']
})
export class TooManyHabitsModalComponent implements OnInit {
    authenticatedMember = this.authenticatedMemberService.getAuthenticatedMember();
    currentTrackers: HealthyHabitsMemberTracker[] | undefined;
    hasChanged = false;
    readonly MODAL_HEADER = this.translateService.instant('ReachedTrackerLimit');
    @Output() closeDialog: EventEmitter<HealthyHabitsMemberTracker[]> = new EventEmitter<HealthyHabitsMemberTracker[]>();

    constructor(
        private tooManyHabitsModalService: TooManyHabitsModalService,
        private authenticatedMemberService: AuthenticatedMemberService,
        private translateService: TranslateService
    ) { }

    ngOnInit() {
        forkJoin([
            this.tooManyHabitsModalService.getMemberTrackers(this.authenticatedMember.id),
            this.tooManyHabitsModalService.getTrackerChallenges(this.authenticatedMember.id),
            this.tooManyHabitsModalService.getPromotedTrackerChallenges(this.authenticatedMember.id)
        ])
            .subscribe(([memberTrackers, trackerChallenges, promotedTrackerChallenges]) => {
                this.currentTrackers = memberTrackers.map((tracker) => ({ ...tracker, isActive: true, hasActiveChallenge: (trackerChallenges.some((challenge) => challenge.tracker.id === tracker.tracker.id)) || (promotedTrackerChallenges.some((challenge) => challenge.trackerId === tracker.tracker.id)) }));
            });
    }

    onToggle(isActive: boolean, tracker: HealthyHabitsMemberTracker) {
        if (isActive === tracker.isActive) {
            return;
        }
        if (isActive) {
            this.tooManyHabitsModalService.addTracker(this.authenticatedMember.id, tracker.trackerId).subscribe(() => {
                tracker.isActive = isActive;
                this.setHasChanged();
            });
        } else {
            this.tooManyHabitsModalService.deleteTracker(this.authenticatedMember.id, tracker.trackerId).subscribe(() => {
                tracker.isActive = isActive;
                this.setHasChanged();
            }, (error: HttpErrorResponse) => {
                if (Number(error.error.statusCode) !== HttpStatusCode.NotAcceptable) {
                    return;
                }

                const trackerUsedInActiveHolisticOrSpotlightChallenge = this.currentTrackers?.find((currentTracker) => Number(currentTracker.id) === Number(tracker.id));
                if (trackerUsedInActiveHolisticOrSpotlightChallenge) {
                    trackerUsedInActiveHolisticOrSpotlightChallenge.isActive = true;
                    trackerUsedInActiveHolisticOrSpotlightChallenge.hasActiveChallenge = true;
                }
            });
        }
    }

    confirm() {
        this.closeDialog.emit(this.currentTrackers);
    }

    setHasChanged() {
        this.hasChanged = this.currentTrackers?.some((tracker) => !tracker.isActive) || false;
    }
}
