import { Injectable } from '@angular/core';

import { CustomCache } from './custom-cache';
import { SessionCache } from './session-cache';
import { CurrentModuleService } from '../services/current-module.service';

@Injectable({
    providedIn: 'root'
})
export class ApplicationCache extends CustomCache {
    protected cache = new SessionCache('@app.global');
    constructor(module: CurrentModuleService) {
        super(module);
    }
}
