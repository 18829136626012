import { Injectable } from '@angular/core';

import { GlobalChallengesOnboardingBlockerModalService } from './global-challenges-blocker/global-challenges-onboarding-blocker.service';
import { MemberContestOnboardingBlockerService } from './member-contest-onboarding-blocker.service';
import { AuthenticatedMemberService, SponsorSetting } from '@genesis-frontend/genesis-utilities';

@Injectable()
export class GlobalChallengesBlockerService {
    private shouldShowOnboardingChallengeBlocker = false;
    private isMobileWebView = false;
    private sponsorSettings!: SponsorSetting;
    private contestId: any;

    constructor(
        private memberContestOnboardingBlockerService: MemberContestOnboardingBlockerService,
        private authenticatedMemberService: AuthenticatedMemberService,
        private globalChallengesOnboardingBlockerModalService: GlobalChallengesOnboardingBlockerModalService
    ) {
        this.showBlocker = this.showBlocker.bind(this);
    }

    showBlocker(previousResolveCallback?: () => void) {
        this.sponsorSettings = this.authenticatedMemberService.getSponsorSettings();
        this.isMobileWebView = /VirginPulseWebView/.test(navigator.userAgent);
        this.shouldShowOnboardingChallengeBlocker = this.sponsorSettings.onboardingChallengeBlocker && !this.isMobileWebView;

        return new Promise<void>((resolve) => {
            const resolveCallback = () => {
                resolve();
                if (previousResolveCallback) {
                    previousResolveCallback();
                }
            };

            if (this.shouldShowOnboardingChallengeBlocker && !this.hasOnboardingBlockerBeenShown()) {
                this.memberContestOnboardingBlockerService
                    .getOnboardingChallengeId(this.authenticatedMemberService.getAuthenticatedMember().id)
                    .subscribe((response: { contestId: any }) => {
                        if (response) {
                            sessionStorage.onboardingBlockerShown = true;
                            this.contestId = response.contestId;
                            this.globalChallengesOnboardingBlockerModalService.open(
                                resolveCallback,
                                this.contestId,
                                this.authenticatedMemberService.getAuthenticatedMember().id
                            );
                        } else {
                            resolve();
                        }
                    }, resolve);
            } else {
                resolve();
            }
        });
    }

    hasOnboardingBlockerBeenShown() {
        return !!sessionStorage.onboardingBlockerShown;
    }

    getFreeSlotsNumberToDisplay(teamLength: number, itemsToDisplay: number) {
        return teamLength >= itemsToDisplay ? 0 : itemsToDisplay - teamLength;
    }

    getNumberOfNotDisplayedTeamMembers(teamLength: number, itemsToDisplay: number) {
        return teamLength >= itemsToDisplay ? teamLength - itemsToDisplay : 0;
    }

    generateArrayOfFreeSlots(freeSlotsToDisplay: number) {
        return Array(freeSlotsToDisplay);
    }
}
