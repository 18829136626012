import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'genesis-frontend-loader',
    templateUrl: './loader.component.html',
    styleUrl: './loader.component.scss'
})
export class LoaderComponent {
    translations = {
        RECENT_CLAIMS: 'RecentClaims'
    };

    @HostBinding('class')
    get classNames(): string {
        return 'flex-column align-items-center justify-content-center';
    }
}
