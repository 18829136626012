/**
 * Convenience methods for handling formatting and parsing of number instances.
 */
export class NumberUtils {
    private static readonly DECIMAL_BASE = 10;

    /**
     * Parses the given text and returns a NaN on invalid input, or successfully parsed value on valid input.
     * @param text string containing the number to be parsed.
     */
    static parseInt(text?: string) {
        return text ? parseInt(text, NumberUtils.DECIMAL_BASE) : Number.NaN;
    }
}
