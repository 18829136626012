<vpg-card-next-header class="claims-module-opt-in-header mb-lg">
    <vpg-card-next-title>{{ translations.RECENT_CLAIMS | translate }}</vpg-card-next-title>
</vpg-card-next-header>
<p
    [id]="errors.errorTitleId"
    class="error-message-title h4 text-center"
>
    {{ errors.errorTitle | translate }}
</p>

<a
    id="view-details"
    vpg-button
    size="medium"
    class="mb-lg"
    variant="secondary"
    href="#/benefits/claims"
    (click)="sendAnalytics()"
>
    {{ translations.VIEW_DETAILS | translate }}
</a>
