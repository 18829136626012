import { Component, EventEmitter, Input, Output } from '@angular/core';

import { LanguageModel } from '@genesis-frontend/genesis-utilities';
@Component({
    selector: 'translations-dropdown',
    templateUrl: './translations-dropdown.component.html'
})
export class TranslationsDropdownComponent {
    @Input() title = '';
    @Input() text = '';
    @Input() languages: LanguageModel[] = [];
    @Input() selectedLanguage?: LanguageModel = undefined;

    @Output() selectedLanguageOutput = new EventEmitter<LanguageModel>();

    setLanguage(englishNative: string) {
        this.selectedLanguage = this.languages.find((language) => language.englishNative === englishNative);
        this.selectedLanguageOutput.emit(this.selectedLanguage);
    }
}
