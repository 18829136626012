import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

import { AuthenticatedMemberService, MemberClass, PcpFormData, HealthFormSubmissionsDataService } from '@genesis-frontend/genesis-utilities';

@Component({
    templateUrl: './pcp-form.template.html'
})
export class PcpFormComponent implements OnInit {
    @ViewChild('pcpForm') pcpForm!: ElementRef;

    member = {} as MemberClass;
    pcpFormData = {} as PcpFormData;
    rewardPopulation = '';
    rewardPopulationId = 0;
    formName = '';

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private changeDetector: ChangeDetectorRef,
        private healthFormSubmissionsDataService: HealthFormSubmissionsDataService,
        private route: ActivatedRoute
    ) { }

    ngOnInit(): void {
        this.formName = this.route.snapshot.params.formName;
        this.setFormDataAndSubmitForm();
    }

    setFormDataAndSubmitForm() {
        this.member = this.authenticatedMemberService.getMember();

        forkJoin({
            formData: this.healthFormSubmissionsDataService.getFormData(this.member, this.formName),
            rewardPopulation: this.healthFormSubmissionsDataService.getRewardPopulation()
        }).subscribe((response) => {
            this.pcpFormData = response.formData;
            this.rewardPopulation = response.rewardPopulation.rewardsSegment;
            this.rewardPopulationId = response.rewardPopulation.rewardPopulationId;
            this.changeDetector.detectChanges();

            if (!this.pcpFormData) {
                return;
            }

            setTimeout(() => {
                this.pcpForm.nativeElement.submit();
            }, this.healthFormSubmissionsDataService.TIMEOUT);
        });
    }
}
