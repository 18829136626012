import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
    transform(value: string, maxLength: number | any[] = 50, suffix: string = '...'): string {
        if (!value) {
            return '';
        }

        let valueLength = 50;
        if (Array.isArray(maxLength)) {
            valueLength = maxLength[0];
        } else {
            valueLength = maxLength;
        }

        if (value.length <= valueLength) {
            return value;
        }

        return value.substring(0, valueLength).trimRight() + suffix;
    }
}
