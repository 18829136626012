import { Injectable } from '@angular/core';

import { GlobalChallengeRulesModal } from './global-challenges-rules.component';
import { BootstrapModalWrapperService } from '@genesis-frontend/genesis-utilities';


@Injectable()
export class GlobalChallengeRulesModalService {
    constructor(
        private modalService: BootstrapModalWrapperService
    ) {}

    openModal(rulesHtml: string) {
        return this.modalService.show(GlobalChallengeRulesModal, {
            class: 'global-challenge-rules-modal',
            initialState: {
                rulesHtml
            },
            animated: false
        });
    }
}
