import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AccessLevel } from '../interfaces/access-level.model';

@Injectable({
    providedIn: 'root'
})
export class MemberPermissionsService {

    contextTypes = {
        CALENDAR_EVENT: 'CalendarEvent',
        CONTEST: 'Contest',
        PILLAR: 'Pillar',
        SURVEY: 'Survey',
        TRACKER: 'Tracker',
        PROMOTED_TRACKER_CHALLENGE: 'PromotedTrackerChallengeSponsor',
        HABIT: 'Tracker',
        CARD: 'Widget',
        BRANDING_LOGO: 'BrandingLogo',
        BRANDING_COLORS: 'BrandingColors'
    };

    constructor(private httpClient: HttpClient) {}


    saveMemberRoleAccessLevels(params: any) {
        return this.httpClient.post(`/api/client-admin/members/${params.memberId}/roles/${params.roleId}/access-levels`, params);
    }

    deleteMemberRoleAccessLevels(memberId: number, roleId: number, memberRoleAccessLevelId: number) {
        return this.httpClient.delete(`/api/client-admin/members/${memberId}/roles/${roleId}/access-levels/${memberRoleAccessLevelId}`);
    }

    deleteMemberRolesByRoleId(memberId: number, roleId: number) {
        return this.httpClient.delete(`/api/client-admin/members/${memberId}/roles/${roleId}`);
    }

    deleteAllMemberRoles(memberId: number) {
        return this.httpClient.delete(`/api/client-admin/members/${memberId}/roles`);
    }

    getCalendarMemberAccessLevels(memberId: number) {
        return this.getMemberAccessLevels(memberId, this.contextTypes.CALENDAR_EVENT);
    }

    getContestMemberAccessLevels(memberId: number) {
        return this.getMemberAccessLevels(memberId, this.contextTypes.CONTEST);
    }

    getPillarMemberAccessLevels(memberId: number) {
        return this.getMemberAccessLevels(memberId, this.contextTypes.PILLAR);
    }

    getSurveyMemberAccessLevels(memberId: number) {
        return this.getMemberAccessLevels(memberId, this.contextTypes.SURVEY);
    }

    getTrackerMemberAccessLevels(memberId: number) {
        return this.getMemberAccessLevels(memberId, this.contextTypes.TRACKER);
    }

    getTrackerChallengeMemberAccessLevels(memberId: number) {
        return this.getMemberAccessLevels(memberId, this.contextTypes.PROMOTED_TRACKER_CHALLENGE);
    }

    getHabitMemberAccessLevels(memberId: number) {
        return this.getMemberAccessLevels(memberId, this.contextTypes.HABIT);
    }

    getWidgetMemberAccessLevels(memberId: number) {
        return this.getMemberAccessLevels(memberId, this.contextTypes.CARD);
    }

    getBrandingLogoMemberAccessLevels(memberId: number): Observable<AccessLevel[]> {
        return this.getMemberAccessLevels(memberId, this.contextTypes.BRANDING_LOGO);
    }

    getBrandingColorsMemberAccessLevels(memberId: number): Observable<AccessLevel[]> {
        return this.getMemberAccessLevels(memberId, this.contextTypes.BRANDING_COLORS);
    }

    getMemberAccessLevels(memberId: number, context?: string): Observable<AccessLevel[]> {
        let params = new HttpParams();
        if (context) {
            params = params.append('context', context);
        }
        return this.httpClient.get<AccessLevel[]>(`/api/client-admin/members/${memberId}/access-levels`, { params });
    }

    getMemberRoleAccessLevels(memberId: number, roleId: number): Observable<AccessLevel[]> {
        return this.httpClient.get<AccessLevel[]>(`/api/client-admin/members/${memberId}/access-levels?roleId=${roleId}`);
    }

}
