export enum GameDetailsActions {
    View = 'View',
    Edit = 'Edit',
    Delete = 'Delete',
    AddAction = 'Add Action',
    Clone = 'Clone',
    Replace = 'Replace',
    WinCondition = 'Win Condition',
    RewardRules = 'Reward Rules'
}
