import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { BlockerExecutionService } from '../core/services/blocker-execution.service';
import {
    ModuleRegistry,
    PusherConnectionService,
    SynchronizationNotificationService,
    MemberInitializationService,
    LogoutOnTabCloseService,
    ZendeskInitService
} from '@app/core/services';
import { HolisticChallengesNotificationsService } from '@genesis-frontend/genesis-data-access';
import { NotificationsMessengerService } from '@genesis-frontend/genesis-features';
import {
    AuthenticatedMemberService,
    FeatureEnum,
    NotificationPaneService,
    CloseModalService,
    RedeemableWalletsService,
    GenesisRewardsProgressService,
    MemberThemeService,
    Environment,
    MemberStatus,
    RewardsTotalSummaryService,
    UnleashFeature,
    UnleashFlagsService,
    MessageCentrePanelService,
    BrazeService,
    UnleashFlag
} from '@genesis-frontend/genesis-utilities';

@Component({
    templateUrl: './auth-layout.component.html'
})
export class AuthLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
    member = this.authenticatedMemberService.getMember();
    notificationPaneEnabled = this.authenticatedMemberService.hasFeature(FeatureEnum.NotificationPane);
    messageCenterPaneEnabled = this.authenticatedMemberService.hasFeature(FeatureEnum.MessageCenter);
    welcomeEnabledGlobal = false;
    disableNotificationsGlobal = false;
    hasUnleashFlag = false;
    isClaims = false;
    claimsSummaryRegex = /benefits\/claims\/\d+/;
    isTransformUI = false;
    hideNavigation = false;
    noNavigationUrlRegex =
        '^/no-product|^/surveys-ui/surveys/|^/hra/start|^/surveys/|^/survey-readout/|^/challenges/.*/interrupt|^/mobile';
    homeProduct = this.authenticatedMemberService.getHomeMemberProduct();
    blockerOn = true;
    componentSub = new Subscription();
    blockerChainResolved = false;
    microfrontendUrl: SafeUrl = '';
    private timeout: any;

    constructor(
        public moduleRegistry: ModuleRegistry,
        private genesisRewardsProgressService: GenesisRewardsProgressService,
        private redeemableWalletsService: RedeemableWalletsService,
        private authenticatedMemberService: AuthenticatedMemberService,
        private memberInitializationService: MemberInitializationService,
        private memberThemeService: MemberThemeService,
        private notificationPaneService: NotificationPaneService,
        private readonly messageCentrePanelService: MessageCentrePanelService,
        private logoutOnTabCloseService: LogoutOnTabCloseService,
        private closeModals: CloseModalService,
        private pusherConnectionService: PusherConnectionService,
        private notificationMessengerService: NotificationsMessengerService,
        private synchronizationNotificationService: SynchronizationNotificationService,
        private router: Router,
        private zendeskInitService: ZendeskInitService,
        private environment: Environment,
        private domSanitizer: DomSanitizer,
        private rewardsTotalSummaryService: RewardsTotalSummaryService,
        private holisticChallengesNotificationsService: HolisticChallengesNotificationsService,
        private unleashFlagsService: UnleashFlagsService,
        private blockerExecutionService: BlockerExecutionService,
        private readonly braze: BrazeService
    ) {
        this.logoutOnTabCloseService.subscribe();
        this.componentSub.add(
            router.events.subscribe((event) => {
                if (event instanceof NavigationStart) {
                    this.closeModals.closeOpenedModals();
                    if (this.notificationPaneEnabled) {
                        this.notificationPaneService.turnOffNotificationPane();
                    }
                    if (this.messageCenterPaneEnabled) {
                        this.messageCentrePanelService.turnOffMessageCenterPane();
                    }
                }
                if (event instanceof NavigationEnd) {
                    if (event.urlAfterRedirects.match('/v2/')) {
                        this.router.navigate([event.urlAfterRedirects.replace('v2', 'surveys-ui')]);
                    }

                    this.isClaims = event.urlAfterRedirects === '/benefits/claims' || this.claimsSummaryRegex.test(event.urlAfterRedirects);
                    this.hideNavigation = !!event.urlAfterRedirects.match(this.noNavigationUrlRegex);
                    this.isTransformUI = event.urlAfterRedirects.startsWith('/transform');
                }
            })
        );
    }

    ngOnInit() {
        this.fetchUnleashFlags();
        window.newShellApp = true;
        this.microfrontendUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.environment.microfrontendCdn.url);
        this.memberThemeService.setMemberTheme();
        this.memberInitializationService.initialCall();
        this.genesisRewardsProgressService.loadRewardsProgressBar(false, null).subscribe();

        if (this.member.status === MemberStatus.Active) {
            this.redeemableWalletsService.loadMemberRedeemableWallets(false).subscribe();
        } else if (this.member.status === MemberStatus.PendingCancel) {
            this.rewardsTotalSummaryService.loadMemberTotalSummary(false).subscribe();
        }

        const sponsorSettings = this.authenticatedMemberService.getSponsorSettings();
        localStorage.setItem('logout_tab', sponsorSettings.logoutAfterClosingTab.toString());
        this.setNewRelicMemberId();

        this.blockerExecutionService.executeBlockerQueue(sponsorSettings, this.homeProduct, this.member).then((response) => {
            this.blockerChainResolved = response;
        });
    }

    ngAfterViewInit() {
        this.timeout = setTimeout(() => {
            this.zendeskInitService.init();
            this.getPusherConnections();
            this.setupNotificationWatcher();
            this.initializeBraze();
        }, 8000);
    }

    fetchUnleashFlags() {
        this.unleashFlagsService.fetchUnleashFlags().subscribe((response: UnleashFeature[]) => {
            this.unleashFlagsService.setUnleashFlags(response);
        });
    }

    getPusherConnections() {
        this.pusherConnectionService.getConnection().then((pusher) => {
            // @ts-ignore
            pusher.on('notify', (notification) => {
                const notificationType = notification.type;
                const notificationMessage = JSON.stringify(notification.message);
                if (notificationType === 'PointsAwarded' || notificationType === 'RewardsEarned') {
                    this.notificationMessengerService.showNotification(notificationMessage);
                }

                if (notificationType === 'HolisticChallengeRivalsChanged') {
                    this.holisticChallengesNotificationsService.addRivalNotification(notification.message);
                }

                if (notificationType === 'HolisticChallengeProgressUpdated') {
                    this.holisticChallengesNotificationsService.addTeamTotalTokensNotification(notification.message);
                }
            });
        });
    }

    setupNotificationWatcher() {
        this.synchronizationNotificationService.init(this.member);
        this.synchronizationNotificationService.subscribe((notification) => {
            if (notification.scope !== 'Member' || notification.status !== 'Complete') {
                return;
            }

            this.authenticatedMemberService.subscribe((newData) => {
                this.member.title = newData.title;
                this.member.location = newData.location;
                this.member.department = newData.department;
                this.member.displayName = newData.displayName;
                this.member.firstname = newData.firstname;
                this.member.lastname = newData.lastname;
                this.member.setProfileImage(newData.profilepicture);
            });
        });
    }

    setNewRelicMemberId() {
        const newRelic = (window as any).newrelic;
        if (newRelic && newRelic.setCustomAttribute) {
            newRelic.setCustomAttribute('genesisMemberId', this.member.id);
        }
    }

    private initializeBraze(): void {
        if (this.unleashFlagsService.hasUnleashFlag(UnleashFlag.Braze)) {
            this.braze.initialize();
        }
    }

    private unsubscribeFromBraze(): void {
        if (this.unleashFlagsService.hasUnleashFlag(UnleashFlag.Braze)) {
            this.braze.unsubscribe();
        }
    }

    ngOnDestroy() {
        this.componentSub.unsubscribe();
        this.logoutOnTabCloseService.unsubscribe();
        this.unsubscribeFromBraze();
        clearTimeout(this.timeout);
    }
}
