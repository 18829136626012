import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';

import { BubbleCelebrationComponent } from '../../../../../bubble-celebration/bubble-celebration.component';
import { GlobalChallengeRulesModalService } from '../../../global-challenge-rules-modal/global-challenges-rules.service';
import { GlobalChallengeCardCarouselDataModel } from '../../../interface/global-challenges-card-carousel-data.model';
import { VpGoService } from '../../vp-go.service';
import { DateUtils } from '@genesis-frontend/genesis-utilities';

@Component({
    templateUrl: './join-a-challenge.component.html',
    styleUrls: ['./join-a-challenge.component.scss']
})
export class GlobalChallengesJoinAChallengeCardComponent implements AfterViewInit {
    contest: any;
    challengeStarted = false;
    currentDate = new Date();
    bubbleParticleCount = 100;
    countdownClockFace = 'DayCounter';
    showCountdownSeconds = true;
    @ViewChild('bubbleCelebrationComponent') bubbleCelebrationComponent!: BubbleCelebrationComponent;

    constructor(
        private vpGoService: VpGoService,
        @Inject('data') public data: GlobalChallengeCardCarouselDataModel,
        private globalChallengeRulesModalService: GlobalChallengeRulesModalService,
        private cd: ChangeDetectorRef
    ) {}

    ngAfterViewInit() {
        if (DateUtils.isAfter(this.currentDate, this.data.contest.startDate)) {
            this.bubbleCelebrationComponent.animate();
            this.runChangeDetection();
        }
    }

    challengeHasStarted(started: boolean) {
        this.challengeStarted = started;
        this.runChangeDetection();
    }

    openRulesModal() {
        this.globalChallengeRulesModalService.openModal(this.data.contest.rulesHtmlContent);
    }

    nextScreen() {
        if (this.data?.teams) {
            if (this.data.contest.contestType === this.vpGoService.FeaturedChallenges.ChallengeTypes.Organizational) {
                this.data.viewTeamDetails(this.data.teams);
            } else {
                const index =
                    this.data.teams.length || this.data.teamInvites.length
                        ? this.vpGoService.VpGoCards.JOIN_A_TEAM_CARD_INDEX
                        : this.vpGoService.VpGoCards.CREATE_A_TEAM_CARD_INDEX;

                this.data.goToCard(index);
            }
            this.runChangeDetection();
        }
    }

    private runChangeDetection() {
        this.cd.detectChanges();
    }
}
