export enum LeaderboardType {
    Team = 'Team',
    Player = 'Player',
    BusinessUnit = 'BusinessUnit',
    TeamByBusinessUnit = 'TeamByBusinessUnit',
    Company = 'Company',
    Office = 'Office',
    HeadToHead = 'HeadToHead',
    TeamBySponsor = 'TeamBySponsor',
    PlayerBySponsor = 'PlayerBySponsor',
    PlayerAverageByDay = 'PlayerAverageByDay',
    TeamAverageByDay = 'TeamAverageByDay',
    PlayerBySponsorAverageByDay = 'PlayerBySponsorAverageByDay',
    TeamBySponsorAverageByDay = 'TeamBySponsorAverageByDay',
    TeamByBusinessUnitAverageByDay = 'TeamByBusinessUnitAverageByDay',
    TeamByOfficeAverageByDay = 'TeamByOfficeAverageByDay',
    TeamByCompanyAverageByDay = 'TeamByCompanyAverageByDay',
    Rivals = 'Rivals',
    PersonalChallengePlayer = 'PersonalChallengePlayer'
}
