import moment, { Moment } from 'moment-timezone';
export type Base = (
    'year' | 'years' | 'y' |
    'month' | 'months' | 'M' |
    'week' | 'weeks' | 'w' |
    'day' | 'days' | 'd' |
    'hour' | 'hours' | 'h' |
    'minute' | 'minutes' | 'm' |
    'second' | 'seconds' | 's' |
    'millisecond' | 'milliseconds' | 'ms'
    );

type Quarter = 'quarter' | 'quarters' | 'Q';
type longDateFormatKey = 'LTS' | 'LT' | 'L' | 'LL' | 'LLL' | 'LLLL' | 'lts' | 'lt' | 'l' | 'll' | 'lll' | 'llll';
type inclusivityUnit = '()' | '[)' | '(]' | '[]';
type DateOrNumber<T> = T extends number ? Date : number;

export type DurationUnit = Base | Quarter;
export type DateValue = Date | string;
export type DateInput = DateValue | number;
export type DurationAmount = string | number | null | undefined;
export type DurationMethods = 'hours' | 'asHours' | 'minutes' | 'asMinutes' | 'milliseconds' | 'asMilliseconds';

export class DateUtils {
    private static globalLocale = moment.locale();
    static getGlobalLocale = (): string => this.globalLocale;
    static setGlobalLocale(language?: string): string {
        this.globalLocale = moment.locale(language);
        return this.globalLocale;
    }
    static guessTimeZone(ignoreCache?: boolean): string {
        return moment.tz.guess(ignoreCache);
    }
    static setDefaultTimeZone(timezone?: string): boolean {
        return !!moment.tz.setDefault(timezone);
    }
    static convertStringToDate(dateString?: string): Date {
        // Check if the string already includes a timezone offset
        if (dateString?.includes('T')) {
            return new Date(dateString);
        }

        // If no timezone is specified, get the timezone offset from localization
        return moment(dateString).toDate();
    }
    static parseDate(date? : DateInput, format? : string, strict? : boolean): Date {
        return moment(date, format, strict).toDate();
    }
    static toUtcDate(date?: DateInput): Date {
        return moment(date).utc().toDate();
    }
    static dateInUtc(date?: DateInput): Date {
        return moment.utc(date).toDate();
    }
    static dateinTimezone(date: DateInput, timezone: string): Date {
        return moment.tz(date, timezone).toDate();
    }
    static set(date: DateValue, values: object, utcOffset?: number): Date {
        const momentDate = utcOffset
            ? moment(date).utcOffset(utcOffset)
            : moment(date);

        return momentDate.set({
            ...values
        }).toDate();
    }
    static add(date: DateValue, amount?: DurationAmount, unit?: DurationUnit): Date {
        const momentDate = moment(date);
        return momentDate.add(amount, unit).toDate();
    }
    static sub(date: DateValue, amount?: DurationAmount, unit?: DurationUnit): Date {
        const momentDate = moment(date);
        return momentDate.subtract(amount, unit).toDate();
    }
    static format(date: DateInput, formatString?: string, locale?: string, timezone?: string): string {
        locale = locale || this.globalLocale;
        const formattedDate = timezone
            ? moment.tz(date, timezone).locale(locale)
            : moment(date).locale(locale);

        return formattedDate.format(formatString);
    }
    static parseDateStringToDate(date: string, formatString: string, locale?: string): Date {
        locale = locale || this.globalLocale;
        return moment(date, formatString).locale(locale).toDate();
    }
    static diff(date: DateInput, diffDate: DateInput, unit? : DurationUnit, precise? : boolean): number {
        return moment(date).diff(diffDate, unit, precise);
    }
    static tz(date: DateInput, timezone: string, formatString?: string): Date {
        return formatString && typeof date === 'string' ?
            moment.tz(date, formatString, timezone).toDate()
            : moment.tz(date, timezone).toDate();
    }
    static fromNow(date: DateValue, withoutSuffix?: boolean): string {
        return moment(date).fromNow(withoutSuffix);
    }
    static isValid(date: DateValue): boolean {
        return moment(date).isValid();
    }
    static weekday(date: DateValue, day: number, timezone?: string): Date {
        const momentDate = timezone ? moment(date).tz(timezone) : moment(date);
        return momentDate.weekday(day).toDate();
    }
    static day<T extends number | undefined>(date: DateValue, day?: T): DateOrNumber<T> {
        const momentDate = moment(date);
        return (day === undefined ? momentDate.day() : momentDate.day(day).toDate()) as DateOrNumber<T>;
    }
    static dateOf(date: DateInput): number {
        return moment(date).date();
    }
    static dateWithDay(date: DateValue, day: number, timezone?: string): Date {
        const momentDate = timezone ? moment(date).tz(timezone) : moment(date);
        return momentDate.date(day).toDate();
    }
    static dayOfYear(date: DateValue, day: number, timezone?: string): Date {
        const momentDate = timezone ? moment(date).tz(timezone) : moment(date);
        return momentDate.dayOfYear(day).toDate();
    }
    static getDayOfYear(date?: DateValue): number {
        return moment(date).dayOfYear();
    }
    static endOf(date: DateValue, unit: DurationUnit): Date {
        return moment(date).endOf(unit).toDate();
    }
    static startOf(date: DateInput, unit: DurationUnit): Date {
        return moment(date).startOf(unit).toDate();
    }
    static longDateFormat(key: longDateFormatKey, locale?: string): string {
        locale = locale || this.globalLocale;
        return moment.localeData(locale).longDateFormat(key);
    }
    static toISOString(date?: DateInput): string {
        return moment(date).toISOString();
    }
    static hours<T extends number | undefined | null>(date: DateInput, hours?: T, timezone?: string): DateOrNumber<T> {
        const momentDate = timezone ? moment.tz(date, timezone) : moment(date);
        return (hours === null || hours === undefined ? momentDate.hours() : momentDate.hours(hours).toDate()) as DateOrNumber<T>;
    }

    static hour<T extends number | undefined | null>(date: DateInput, hour?: T, timezone?: string): DateOrNumber<T> {
        return this.hours(date, hour, timezone);
    }
    static minutes<T extends number | undefined | null>(date: DateInput, minutes?: T, timezone?: string): DateOrNumber<T> {
        const momentDate = timezone ? moment.tz(date, timezone) : moment(date);
        return (minutes === null || minutes === undefined ? momentDate.minutes() : momentDate.minutes(minutes).toDate()) as DateOrNumber<T>;
    }
    static minute<T extends number | undefined | null>(date: DateInput, minute?: T, timezone?: string): DateOrNumber<T> {
        return this.minutes(date, minute, timezone);
    }
    static seconds<T extends number | undefined>(date: DateValue, seconds?: T): DateOrNumber<T> {
        const momentDate = moment(date);
        return (seconds === undefined ? momentDate.seconds() : momentDate.seconds(seconds).toDate()) as DateOrNumber<T>;
    }
    static second<T extends number | undefined>(date: DateValue, second?: T): DateOrNumber<T> {
        return this.seconds(date, second);
    }
    static valueOf(date?: DateInput): number {
        return moment(date).valueOf();
    }
    private static durationMethods = new Map<DurationMethods, (duration: moment.Duration) => number>([
        ['hours', (duration) => duration.hours()],
        ['asHours', (duration) => duration.asHours()],
        ['minutes', (duration) => duration.minutes()],
        ['asMinutes', (duration) => duration.asMinutes()],
        ['milliseconds', (duration) => duration.milliseconds()],
        ['asMilliseconds', (duration) => duration.asMilliseconds()]
    ]);
    static duration(amount: DurationAmount, method: DurationMethods = 'hours', unit?: DurationUnit): number {
        const momentDuration = moment.duration(amount, unit);
        const getMethod = this.durationMethods.get(method);
        if (!getMethod) {
            throw new Error('Invalid method passed for Duration');
        }
        return getMethod(momentDuration);
    }
    static unix(date?: DateValue): number {
        return moment(date).unix();
    }
    static isSame(date: DateInput, compareDate?: DateInput, unit?: DurationUnit): boolean {
        return moment(date).isSame(compareDate, unit);
    }
    static isSameOrBefore(date: DateValue, compareDate?: DateValue, unit?: DurationUnit): boolean {
        return moment(date).isSameOrBefore(compareDate, unit);
    }
    static isSameOrAfter(date: DateValue, compareDate?: DateValue, unit?: DurationUnit): boolean {
        return moment(date).isSameOrAfter(compareDate, unit);
    }
    static isBefore(date: DateInput, compareDate?: DateInput, unit?: DurationUnit): boolean {
        return moment(date).isBefore(compareDate, unit);
    }
    static isAfter(date: DateInput, compareDate?: DateInput, unit?: DurationUnit): boolean {
        return moment(date).isAfter(compareDate, unit);
    }
    static isBetween(date: DateValue, startDate: DateValue, endDate: DateValue,
        unit?: DurationUnit, inclusivity? : inclusivityUnit): boolean {
        return moment(date).isBetween(startDate, endDate, unit, inclusivity);
    }
    static min(...dates: DateValue[]): Date {
        const datesMoment: Moment[] = [];
        dates.forEach((date) => {
            datesMoment.push(moment(date));
        });
        return moment.min(datesMoment).toDate();
    }
    static dateWithYear(date: DateValue, year: number): Date {
        return moment(date).year(year).toDate();
    }
    static dateWithQuarter(date: DateValue, quarter: number): Date {
        return moment(date).quarter(quarter).toDate();
    }
    static yearOf(date?: DateValue): number {
        return moment(date).year();
    }
    static quarterOf(date?: DateValue): number {
        return moment(date).quarter();
    }
    static calendar(date: DateValue): string {
        return moment(date).calendar();
    }
    static monthOf(date: DateValue): number {
        return moment(date).month();
    }
    static dateWithMonth(date: DateValue, month: number): Date {
        return moment(date).month(month).toDate();
    }
}
