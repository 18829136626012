<div class="challenge-onboarding-address-collection-content">
    <div
        class="address-collection-card-title text-clr-oxford-blue"
        [translate]="'EnterAddress'">
        Enter your address
    </div>
    <div
        class="address-collection-subtitle text-clr-oxford-blue"
        [translate]="'WhereToSendTracker'">
        Where should we send your activity tracker?
    </div>
    <hr class="border-clr-gainsboro" />
</div>
<div class="card-middle-address-collection">
    <form
        class="address-collection-form"
        [formGroup]="addressCollectionForm">
        <div class="address-collection-help-text text-clr-oxford-blue">
            <span [translate]="'PleaseUseEnglishCharactersWhereverPossible'">Please use English characters wherever possible</span>
        </div>
        <div class="business-name-checkbox">
            <div class="checkbox-row">
                <div
                    class="checkbox-container"
                    role="checkbox"
                    aria-checked="businessAddressOptionChecked"
                    (click)="toggleBusinessName()"
                    [ngClass]="{ 'background-clr-success checked': businessAddressOptionChecked }">
                    <i
                        class="text-clr-white"
                        *ngIf="businessAddressOptionChecked"
                        aria-hidden="true">
                        ✓
                    </i>
                </div>
                <span
                    class="checkbox-text-business-name text-clr-flint"
                    [translate]="'BusinessAddress'">
                    Business Address
                </span>
            </div>
        </div>
        <div class="form-group address-collection-country">
            <label
                for="country"
                class="address-collection-label text-uppercase vp-padding-left-20 text-clr-flint"
                [translate]="'Country'">
                COUNTRY
            </label>
            <select
                id="country"
                formControlName="country"
                name="country"
                class="form-control address-collection-input-style"
                required
                (ngModelChange)="onCountryChange($event)">
                <option
                    *ngFor="let country of countries"
                    class="text-clr-mine-shaft"
                    [ngValue]="country.id">
                    {{country.name}}
                </option>
            </select>
        </div>
        <div
            class="form-group address-collection-input-div"
            [hidden]="!businessAddressOptionChecked">
            <label
                class="address-collection-label text-uppercase vp-padding-left-20 text-clr-flint"
                [translate]="'BusinessOrCompanyName'">
                BUSINESS OR COMPANY NAME
            </label>
            <input
                type="text"
                formControlName="businessName"
                name="businessName"
                class="form-control address-collection-input-style text-clr-mine-shaft"
                [ngClass]="{'border-clr-red': addressCollectionForm.controls.businessName.invalid &&
                   (addressCollectionForm.controls.businessName.dirty || addressCollectionForm.controls.businessName.touched)}"
                [placeholder]="'BusinessOrCompanyName' | translate"
                aria-labelledby="address-collection-business-name-label"
                aria-required="true"
                aria-invalid="false"
                minlength="1"
                maxlength="40" />
        </div>
        <div class="form-group address-collection-input-div">
            <label
                class="address-collection-label text-uppercase vp-padding-left-20 text-clr-flint"
                [translate]="'StreetAddress'">
                STREET ADDRESS
            </label>
            <input
                type="text"
                formControlName="streetAddress"
                name="streetAddress"
                class="form-control address-collection-input-style text-clr-mine-shaft"
                [ngClass]="{'border-clr-red': addressCollectionForm.controls.streetAddress.invalid && (addressCollectionForm.controls.streetAddress.dirty || addressCollectionForm.controls.streetAddress.touched)}"
                [placeholder]="'AddressLineOne' | translate"
                aria-labelledby="address-collection-address-label"
                aria-required="true"
                aria-invalid="false"
                minlength="1"
                maxlength="40"
                required />
        </div>
        <div class="form-group address-collection-input-div">
            <label
                class="address-collection-label text-uppercase vp-padding-left-20 text-clr-flint"
                [translate]="'OptionalStreetAddress'">
                STREET ADDRESS (OPTIONAL)
            </label>
            <input
                type="text"
                formControlName="streetAddressOptional"
                name="streetAddressOptional"
                class="form-control input-group-lg address-collection-input-style text-clr-mine-shaft"
                [ngClass]="{'border-clr-red': addressCollectionForm.controls.streetAddressOptional.invalid && (addressCollectionForm.controls.streetAddressOptional.dirty || addressCollectionForm.controls.streetAddressOptional.touched)}"
                [placeholder]="'AddressLineTwo' | translate"
                aria-labelledby="address-collection-address-optional-label"
                aria-required="true"
                aria-invalid="false"
                maxlength="40" />
        </div>
        <div class="form-group address-collection-input-div">
            <label
                class="address-collection-label text-uppercase vp-padding-left-20 text-clr-flint"
                [translate]="'City'">
                CITY
            </label>
            <input
                type="text"
                formControlName="city"
                name="city"
                class="form-control input-group-lg address-collection-input-style text-clr-mine-shaft"
                [ngClass]="{'border-clr-red': addressCollectionForm.controls.city.invalid && (addressCollectionForm.controls.city.dirty || addressCollectionForm.controls.city.touched)}"
                [placeholder]="'City' | translate"
                aria-labelledby="address-collection-address-city-label"
                aria-required="true"
                aria-invalid="false"
                minlength="1"
                maxlength="25"
                required />
        </div>
        <div class="form-group col-xs-6 vp-padding-left-20 address-collection-input-div">
            <label
                *ngIf="selectedCountry && selectedCountry.countryCode !== CANADA_COUNTRY_CODE"
                class="address-collection-label text-uppercase text-clr-flint"
                [translate]="'StateCountyRegion'">
                STATE/COUNTY/REGION
            </label>
            <label
                *ngIf="selectedCountry && selectedCountry.countryCode === CANADA_COUNTRY_CODE"
                class="address-collection-label text-uppercase text-clr-flint"
                [translate]="'Province'">
                PROVINCE
            </label>
            <input
                *ngIf="!countryHasStates"
                type="text"
                formControlName="state"
                name="state"
                class="form-control input-group-lg address-collection-input-style-smaller text-clr-mine-shaft"
                [ngClass]="{'border-clr-red': addressCollectionForm.controls.state.invalid && (addressCollectionForm.controls.state.dirty || addressCollectionForm.controls.state.touched)}"
                [placeholder]="'StateCountyRegion' | translate"
                aria-labelledby="address-collection-state-label"
                aria-required="true"
                aria-invalid="false"
                minlength="1"
                maxlength="35"
                required />

            <select
                *ngIf="countryHasStates"
                id="countryStates"
                formControlName="state"
                name="state"
                class="form-control col-xs-6 address-collection-input-style-smaller"
                required>
                <option
                    class="text-clr-mine-shaft"
                    [value]="null"
                    disabled
                    [translate]="'PleaseSelect'">
                    -- please select --
                </option>
                <option
                    *ngFor="let countryState of countryStates"
                    class="text-clr-mine-shaft"
                    [ngValue]="countryState">
                    {{countryState.name}}
                </option>
            </select>
        </div>
        <div class="form-group col-xs-6 vp-padding-right-20 address-collection-input-div">
            <label
                *ngIf="selectedCountry && selectedCountry.countryCode !== USA_COUNTRY_CODE"
                class="address-collection-label text-uppercase text-clr-flint"
                [translate]="'PostalCode'">
                POSTAL CODE
            </label>
            <label
                *ngIf="selectedCountry && selectedCountry.countryCode === USA_COUNTRY_CODE"
                class="address-collection-label text-uppercase text-clr-flint"
                [translate]="'ZipCode'">
                ZIP CODE
            </label>
            <input
                type="text"
                formControlName="postalCode"
                name="postalCode"
                class="form-control input-group-lg address-collection-input-style-smaller text-clr-mine-shaft"
                [ngClass]="{'border-clr-red': addressCollectionForm.controls.postalCode.invalid && (addressCollectionForm.controls.postalCode.dirty || addressCollectionForm.controls.postalCode.touched)}"
                [placeholder]="'PostalCode' | translate"
                aria-labelledby="address-collection-postal-code-label"
                aria-required="true"
                aria-invalid="false"
                minlength="1"
                maxlength="12"
                required />
        </div>
        <div class="form-group address-collection-input-div vp-margin-left-20 vp-margin-bottom-30">
            <label
                class="address-collection-label text-uppercase text-clr-flint"
                [translate]="'PhoneNumber'">
                Phone number
            </label>
            <input
                (countryChange)="countryChange($event)"
                (intlTelInputObject)="telInputObject($event)"
                (ngModelChange)="getPhoneNumberData()"
                (keydown.space)="$event.preventDefault();"
                [intlTelInputOptions]="intlTelInputOptions"
                [ngClass]="{
                        'border-clr-red': !isPhoneNumberValid,
                        'fixed-padding': !defaultCountryDialNumberChanged
                    }"
                class="form-control input-group-lg address-collection-input-style text-clr-mine-shaft phone-input-style"
                formControlName="phoneNumber"
                id="maxBuzzOrderPhoneNumber"
                maxlength="17"
                minlength="1"
                name="phoneNumber"
                intlTelInput
                required
                type="text" />
            <div class="address-collection-help-text vp-padding-left-0 text-clr-oxford-blue">
                <span [translate]="'PhoneNumberDisclaimer'">
                    Your phone number is required so that we have a point of contact in case there are any issues with the delivery
                </span>
            </div>
        </div>
    </form>
    <div class="fog-container-bottom"></div>
</div>

<div class="card-footer-address-collection text-clr-oxford-blue">
    <hr class="border-clr-gainsboro" />
    <button
        class="vp-button vp-button-primary larger bordered"
        [disabled]="!addressCollectionForm.valid || !isPhoneNumberValid"
        (click)="goToNextCard()">
        <span [translate]="'Next'">Next</span>
    </button>
</div>
<div
    class="prev-card-btn background-clr-bg background-dark text-clr-white"
    *ngIf="showLeftArrow && !data.getMaxBuzzModal"
    (click)="goToPreviousCard()"
    role="button"
    tabindex="0"
    [attr.aria-label]="'previous' | translate">
    <i class="fa fa-angle-left"></i>
</div>
<div
    class="next-card-btn background-clr-bg background-dark text-clr-white"
    *ngIf="showRightArrow"
    (click)="goToNextCard()"
    role="button"
    tabindex="0"
    [attr.aria-label]="'next' | translate">
    <i class="fa fa-angle-right"></i>
</div>
