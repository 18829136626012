import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DistributorService {
    constructor(private httpClient: HttpClient) {}

    getDistributors(page: number, pageSize: number) {
        const fromObject = {
            page,
            pageSize
        };
        const params = new HttpParams({ fromObject });
        return this.httpClient.get('/api/client-admin/distributors', { params });
    }

    searchDistributorMembers(distributorId: number, name: any, page: number, pageSize: number) {
        const fromObject = {
            name,
            page,
            pageSize
        };
        const params = new HttpParams({ fromObject });
        return this.httpClient.get(`/api/client-admin/distributors/${distributorId}/members`, { params });
    }

    isDistributorAdmin(memberId: number) {
        return this.httpClient.get(`/api/client-admin/members/${memberId}/distributor-admin`);
    }
}
