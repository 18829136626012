import { Injectable } from '@angular/core';

import { KeyCode } from '@engineering/vpgroove-angular';
import {
    KeycloakService
} from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class LogoutOnTabCloseService {

    private enabled = localStorage.getItem('logout_tab') === 'true';
    private offload = false;
    private validNavigation = false;

    constructor(private keycloakService: KeycloakService) { }

    public subscribe(): void {
        window.addEventListener('beforeunload', (event) => this.checkIfEnabledAndPrompt(event), false);
        window.addEventListener('pagehide', () => this.checkIfEnabledAndLogout(), false);
        window.addEventListener('keydown', this.keyDownEvent);
    }
    public unsubscribe(): void {
        window.removeEventListener('beforeunload', (event) => this.checkIfEnabledAndPrompt(event), false);
        window.removeEventListener('pagehide', () => this.checkIfEnabledAndLogout(), false);
        window.removeEventListener('keydown', this.keyDownEvent);
    }

    private checkIfEnabledAndPrompt(event: BeforeUnloadEvent) {
        if (this.enabled && !this.validNavigation) {
            this.offload = true;
            event.preventDefault();
            return 'Logging off...';
        }
    }

    private checkIfEnabledAndLogout() {
        if (this.enabled && this.offload && !this.validNavigation) {
            this.executeLogout();
        }
    }

    private keyDownEvent = (event: KeyboardEvent) => {
        if ((event && ((event.ctrlKey || event.metaKey) && event.keyCode === KeyCode.r) || event.key === 'F5')) {
            this.validNavigation = true;
        }
    };

    private executeLogout() {
        sessionStorage.clear();
        const imgForLogout = new Image();
        const logoutUrl = window.location.origin + '/logout.html';
        const authServerUrl = this.keycloakService.getClient().authServerUrl;
        imgForLogout.src = `${authServerUrl}/realms/virginpulse/protocol/openid-connect/logout?client_id=genesis-ui&post_logout_redirect_uri=${logoutUrl}&id_token_hint=${this.keycloakService.getClient().idToken}`;
    }
}
