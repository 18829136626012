import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { AppHistoryService } from './app-history.service';
import { EventBusGroup, Events } from '../core';

@Injectable({
    providedIn: 'root'
})
export class CustomEventHandlerService implements OnDestroy {

    constructor(
        private router: Router,
        private eventBus: EventBusGroup<Events>,
        private appHistoryService: AppHistoryService
    ) { }

    initialize() {
        this.eventBus.on('navigate', (event) => {
            const url = this.getRouterUrl(event.data.path, event.data.params);
            this.router.navigate([url]);
        });

        this.eventBus.on('appHistory', (event) => {
            this.appHistoryService.addLocation(event.data.path);
        });
    }

    ngOnDestroy() {
        this.eventBus.unsubscribe();
    }

    getRouterUrl(url: string, params?: Record<string, any>) {
        switch (url) {
        case 'healthyhabits.list':
            return '/healthyhabits';
        case 'rewards-microfrontend.earn':
            return '/my-rewards/earn';
        case 'guides-microfrontend.nutrition.home':
            return `/guide/nutrition/${params?.id}`;
        case 'featuredchallenge':
            return `/featuredchallenge/${params?.id}`;
        case 'guides-microfrontend.sleep.home':
            return `/guide/sleep/${params?.id}`;
        case 'challenges-microfrontend.dashboard':
            return '/challenges/dashboard';
        case 'groups.mygroups':
            return '/groups';
        case 'calendar':
            return '/calendar';
        case 'trophycase':
            return '/my-rewards/trophycase';
        case 'personalChallenge.Dashboard':
            return '/challenges/dashboard';
        case 'live-services-microfrontend.home':
            return '/live-services-coaching';
        case 'benefits.findCare':
            return '/benefits/find-care';
        case 'benefits.wallet':
            return '/benefits/digital-wallet';
        case 'benefits.programDetails':
            return `/benefits/programs/${params?.id}`;
        case 'benefits.myFinance':
            return '/benefits/my-finance';
        case 'pillars.list':
            return '/pillars';
        case 'health.checklist':
            return '/health/checklist';
        case 'coaches-corner.home':
            return '/coaches-corner';
        case 'transform.landing-page':
            return '/transform/landing-page';
        case 'calendar.home':
            return '/calendar';
        case 'sponsor-performance':
            return '/enhanced-analytics/sponsor-performance';
        case 'claims-dashboard':
            return '/enhanced-analytics/claims';
        case 'navigate-dashboard':
            return '/enhanced-analytics/navigate';
        case 'extract-catalog':
            return '/enhanced-analytics/extract-catalog';
        case 'pepsico-challenge-dashboard':
            return '/enhanced-analytics/pepsico';
        case 'email-campaigns':
            return '/email/email-campaigns';
        case 'systemContent.list':
            return '/system-content/list';
        case 'analyticusers.list':
            return '/analyticusers';
        case 'reportArchive':
            return '/report-archive';
        case 'agent-admin-eligibles':
            return '/agent-admin/eligibles';
        case 'announcement.list':
            return '/announcements';
        case 'systemContent.pendingList':
            return '/system-content/pending-list';
        case 'surveys-microfrontend.surveys-intro':
            return `surveys-ui/surveys/${params?.id}/intro`;
        case 'surveys-microfrontend.surveys-body':
            return `surveys-ui/surveys/${params?.id}`;
        case 'health-ui.my-care-checklist':
            return '/health-ui/my-care-checklist';
        case 'webchat':
            return '/webchat';
        case 'tpa-resources':
            return '/tpa-resources';
        default:
            return `/${url}`;
        }
    }
}
