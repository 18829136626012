import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

import { AuthenticatedMemberService, HealthFormDataModel, MemberClass, PcpFormData, HealthFormSubmissionsDataService } from '@genesis-frontend/genesis-utilities';

@Component({
    templateUrl: './attestation-form-submissions.template.html'
})
export class AttestationFormSubmissionsComponent implements OnInit {
    @ViewChild('attestationForm') attestationForm!: ElementRef;

    member = {} as MemberClass;
    pcpFormData = {} as PcpFormData;
    healthFormData = {} as HealthFormDataModel;
    formName = '';

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private changeDetector: ChangeDetectorRef,
        private healthFormSubmissionsService: HealthFormSubmissionsDataService,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.formName = this.route.snapshot.params.formName;
        this.setFormDataAndSubmitForm();
    }

    setFormDataAndSubmitForm() {
        this.member = this.authenticatedMemberService.getMember();

        forkJoin({
            formData: this.healthFormSubmissionsService.getFormData(this.member, this.formName),
            healthFormData: this.healthFormSubmissionsService.getHealthFormData(this.member.id)
        }).subscribe((response) => {
            this.pcpFormData = response.formData;
            this.healthFormData = response.healthFormData;
            this.pcpFormData.formUrl = this.healthFormSubmissionsService.getFormUrl(`${this.member.sponsorId}/${this.formName}`);

            if (!this.pcpFormData) {
                return;
            }

            this.healthFormData.bearerV2 = this.pcpFormData.bearerV2;
            this.changeDetector.detectChanges();

            setTimeout(() => {
                this.attestationForm.nativeElement.submit();
            }, this.healthFormSubmissionsService.TIMEOUT);
        });
    }
}
