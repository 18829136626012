import { Pipe, PipeTransform } from '@angular/core';

import { isEqual } from '../lib/lodash';
import { GameDetailsFilters } from '../models/interfaces/game-details-filters.model';

@Pipe({ name: 'searchFilter' })
export class SearchFilterPipe implements PipeTransform {
    transform(pointRewards: any[], searchTerm: GameDetailsFilters) {
        if (!pointRewards?.length) {
            return [];
        }

        const emptyFilter = isEqual(searchTerm, { viewValue: 'all', populationIds: [], searchValue: '' });
        if (emptyFilter) {
            return pointRewards;
        }

        const shouldSearchByName = !!searchTerm?.searchValue;
        const isPopulationFilterEnabled = !!searchTerm?.populationIds.length;

        return pointRewards.filter((item) => {
            const filterView = this.enabledFilter(item, searchTerm.viewValue);

            const name = item.rewardableAction?.name || item.name;
            const filterName = shouldSearchByName
                ? name.toLowerCase().contains(searchTerm.searchValue.toLowerCase())
                : true;

            const filterPopulation = isPopulationFilterEnabled
                ? this.populationFilter({ ...item }, searchTerm.populationIds)
                : true;

            return filterView && filterName && filterPopulation;
        });
    }

    enabledFilter(entity: any, enabledSelect: string) {
        return (enabledSelect === 'all')
            || (!entity.disabled && enabledSelect === 'enabled')
            || (entity.disabled && enabledSelect === 'disabled');
    }

    populationFilter(entity: any, selectedPopulations: (number | null)[]) {
        return selectedPopulations.includes(entity.rewardPopulationId);
    }
}
