<div class="modal-header">
    <button
        type="button"
        class="close"
        (click)="close()"
        [attr.aria-label]="'Close' | translate">
        &times;
    </button>
    <h3 class="rule-modal-title">
        <span
            class="text-clr-tertiary"
            [translate]="'ChallengeRules'">
            Challenge rules
        </span>
    </h3>
</div>
<div
    class="modal-body"
    [innerHTML]="rulesHtml | safeHtml">
</div>
