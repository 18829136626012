import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
    AccessibleDragAndDropEvent
} from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'accessible-drag-and-drop',
    templateUrl: './accessible-drag-and-drop.template.html'
})
export class AccessibleDragAndDropComponent implements OnInit {
    @Input() id?: string;
    @Input() dropTargetRect?: DOMRect;
    @Input() wcagDnDLabel?: string;
    @Input() scrollIncrementX?: number;
    @Input() scrollIncrementY?: number;

    @Output() onDrop: EventEmitter<AccessibleDragAndDropEvent> = new EventEmitter<AccessibleDragAndDropEvent>();

    readonly DEFAULT_SCROLL_INCREMENT = 5;

    private positionX = 0;
    private positionY = 0;

    private incrementX = this.DEFAULT_SCROLL_INCREMENT;
    private incrementY = this.DEFAULT_SCROLL_INCREMENT;

    ngOnInit(): void {
        if (!this.dropTargetRect) {
            this.dropTargetRect = new DOMRect(0, 0, 0, 0);
        }

        this.positionX = this.dropTargetRect.x;
        this.positionY = this.dropTargetRect.y;

        this.incrementX = this.scrollIncrementX || this.DEFAULT_SCROLL_INCREMENT;
        this.incrementY = this.scrollIncrementY || this.DEFAULT_SCROLL_INCREMENT;
    }

    onPositionChange(event: Event) {
        const { key } = event as KeyboardEvent;

        if (key === 'ArrowUp') {
            this.positionY += this.incrementY;
            return;
        }

        if (key === 'ArrowDown') {
            this.positionY -= this.incrementY;
            return;
        }

        if (key === 'ArrowLeft') {
            this.positionX -= this.incrementX;
            return;
        }

        if (key === 'ArrowRight') {
            this.positionX += this.incrementX;
            return;
        }
    }

    onPositionSelect(event: Event): void {
        const { key } = event as KeyboardEvent;
        const selected = key === 'Escape';

        const dragEvent = {
            clientX: this.positionX,
            clientY: this.positionY,
            selected
        };

        this.positionX = 0;
        this.positionY = 0;

        this.onDrop?.emit(dragEvent);
    }
}
