/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChildFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { mergeMap, first } from 'rxjs/operators';

import { MemberInitializationService, SharedStateService } from '../services';
import {
    AnalyticsTrackingService,
    AuthenticatedMemberService,
    AuthSetup,
    FilePickerService,
    LocalizationService,
    MemberService,
    RewardsAndSupporterCheckerService
} from '@genesis-frontend/genesis-utilities';

export const authGuard: CanActivateChildFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

    const authenticatedMemberService = inject(AuthenticatedMemberService);
    const memberInitializationService = inject(MemberInitializationService);

    return new Promise<boolean | UrlTree>((resolve) => {
        if (authenticatedMemberService.hasMember()) {
            setTimeout(() => {
                resolve(memberInitializationService.checkAccess(state));
            }, 0);
        } else {
            userAuthentication(state).then((response) => {
                resolve(response);
            });
        }
    });
};

function userAuthentication(state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    const router = inject(Router);
    const memberService = inject(MemberService);
    const authSetup = inject(AuthSetup);
    const analyticsTrackingService = inject(AnalyticsTrackingService);
    const rewardsAndTokenHelperService = inject(RewardsAndSupporterCheckerService);
    const localizationService = inject(LocalizationService);
    const sharedState = inject(SharedStateService);
    const authenticatedMemberService = inject(AuthenticatedMemberService);
    const memberInitializationService = inject(MemberInitializationService);
    const httpClient = inject(HttpClient);

    return new Promise<boolean | UrlTree>((resolve) => {
        const options = {
            redirectUri: getRedirectUrl(state)
        };

        authSetup
            .keycloakBootstrap(options)
            .pipe(mergeMap(() => memberService.authenticate()))
            .pipe(mergeMap(() => authenticatedMemberService.authenticate()))
            .subscribe((member) => {
                analyticsTrackingService.init(member.authenticatedMember);
                rewardsAndTokenHelperService.checkStatus();
                FilePickerService.getFilePickerReadOnlySecurityData(member.authenticatedMember.id, httpClient);

                const localization = localizationService.getMemberLocalization();

                localizationService.setLocalization(localization).then(() => {
                    sharedState
                        .updateStore()
                        .pipe(first())
                        .subscribe(() => {
                            if (memberInitializationService.checkAccess(state)) {
                                resolve(router.parseUrl(getRedirectPath(state)));
                            }
                            resolve(false);
                        });
                });
            });
    });
}

function getRedirectUrl(state: RouterStateSnapshot): string {
    return window.location.origin + '/#' + getRedirectPath(state);
}

function getRedirectPath(state: RouterStateSnapshot): string {
    const params = window.location.search;
    let path = state.url;
    if (path.includes('&state')) {
        path = path.split('&state')[0];
    } else if (path.startsWith('/state')) {
        path = '/home';
    }
    return path + params;
}
