import { Component, Input } from '@angular/core';
import { Subscription, timer } from 'rxjs';

@Component({
    selector: 'vp-flipclock',
    templateUrl: './flip-clock.template.html',
    styleUrls: ['./flip-clock.scss']
})
export class FlipClockComponent {
    @Input() date!: Date;
    @Input() showSeconds!: boolean;

    dayTens = 0;
    dayOnes = 0;

    hourTens = 0;
    hourOnes = 0;

    minuteTens = 0;
    minuteOnes = 0;

    secondTens = 0;
    secondOnes = 0;

    countDown!: Subscription;
    isReady = false;

    ngOnInit() {
        this.startCountdown();
    }

    startCountdown() {

        const countDownDate = this.date?.getTime();
        this.countDown = timer(0, 1000).subscribe(() => {

            const now = new Date().getTime();
            if (new Date() <= this.date) {
                const distance = countDownDate - now;

                // Time calculations for days, hours, minutes and seconds
                const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                const remainingDays = ('00' + days).slice(-2);
                const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                const remainingHours = ('00' + hours).slice(-2);
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const remainingMinutes = ('00' + minutes).slice(-2);
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);
                const remainingSeconds = ('00' + seconds).slice(-2);

                this.dayTens = parseInt(remainingDays.charAt(0), 9);
                this.dayOnes = parseInt(remainingDays.charAt(1), 9);

                this.hourTens = parseInt(remainingHours.charAt(0), 9);
                this.hourOnes = parseInt(remainingHours.charAt(1), 9);

                this.minuteTens = parseInt(remainingMinutes.charAt(0), 6);
                this.minuteOnes = parseInt(remainingMinutes.charAt(1), 9);

                if (this.showSeconds) {
                    this.secondTens = parseInt(remainingSeconds.charAt(0), 6);
                    this.secondOnes = parseInt(remainingSeconds.charAt(1), 9);
                }
            }
            if (!this.isReady) {
                this.isReady = true;
            }

        });

    }

    ngOnDestroy() {
        this.countDown.unsubscribe();
    }


}
