import { Injectable } from '@angular/core';

import { DateUtils } from '../lib/date-utils';
import { Timezone } from '../models';

@Injectable({
    providedIn: 'root'
})
export class MemberTimezoneService {

    getTimeForMemberNow(timezone: Timezone): Date {
        return DateUtils.tz(DateUtils.toUtcDate(), timezone.javaTimezone);
    }

    getDateTimeInMemberDateTime(dateToConvert: any, timezone: Timezone): Date {
        return DateUtils.tz(dateToConvert, timezone.javaTimezone);
    }

    getMemberDate(timezone: Timezone): Date {
        return DateUtils.startOf(
            DateUtils.tz(DateUtils.toUtcDate(), timezone.javaTimezone),
            'day'
        );
    }

    getDateInMemberDate(dateToConvert: any, timezone: Timezone): Date {
        return DateUtils.startOf(
            DateUtils.tz(dateToConvert, timezone.javaTimezone),
            'day'
        );
    }

    getTimezoneString(timezone: Timezone): string {
        return timezone.javaTimezone;
    }
}
