<span class="sr-only">{{ screenreaderText }}</span>
<div class="date h5" id="appointment-title" aria-hidden="true">{{ appointment.title }}</div>
<div class="time body-regular" id="appointment-time" aria-hidden="true">{{ appointment.content }}</div>
<div class="coach body-regular" id="appointment-coach" aria-hidden="true">{{ appointment.coachFirstName }}</div>   
<vpg-icon-next
    *ngIf="appointment.icon && !appointment.completed; else checkmark"
    class="graphic-element icon"
    [icon]="appointment.icon"
    id="appointment-icon"
    size="3x"
></vpg-icon-next>
<ng-template #checkmark>
    <vpg-checkmark
        class="graphic-element"
        id="appointment-checkmark"
    />
</ng-template>