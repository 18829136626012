import { Component, OnInit } from '@angular/core';

import { HraStartService } from '../../core/services/hra-start.service';

@Component({
    templateUrl: './hra-start.component.html',
    styleUrl: './hra-start.component.scss'
})
export class HraStartComponent implements OnInit {

    loading = false;

    constructor(
      private hraStartService: HraStartService
    ) { }

    ngOnInit(): void {
        this.loading = true;
        this.hraStartService.redirectToSSO();
    }
}
