import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { MaximumTrackersAddedModalComponent } from './maximum-trackers-added.component';

@Injectable()
export class MaximumTrackersAddedModalService {
    constructor(
        private bsModalService: BsModalService
    ) {}

    openModal() {
        return this.bsModalService.show(MaximumTrackersAddedModalComponent, {
            class: 'maximum-trackers-added-modal',
            animated: false
        });
    }
}
