import { Injectable } from '@angular/core';

import { MemberProduct, Product } from '../models';
import { GlobalReduxStore } from '../state-management';

@Injectable({
    providedIn: 'root'
})
export class MemberProductService {
    private memberProducts: MemberProduct[] = [];
    private wellnessMemberProductTypes = [Product.Wellness, Product.BasePlatform];

    constructor(private globalReduxStore: GlobalReduxStore) {
        this.initialize();
    }

    initialize() {
        const initialState = this.globalReduxStore.getInitialState();
        if (!initialState.memberProducts) {
            return;
        }
        this.memberProducts = initialState.memberProducts as MemberProduct[];
    }

    isWellnessMember(): boolean {
        return this.memberProducts.some((product) => {
            return product.enabled && this.wellnessMemberProductTypes.includes(product.product);
        });
    }
}
