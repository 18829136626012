import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

import { CoachingModuleService } from './coaching-module.service';
import { CoachingModuleState } from '../enums/coaching-module-state.enum';
import { CoachingCardVM } from '../models/coaching-card-vm.model';
import { CoachingModuleProgramModel } from '../models/coaching-module-program.model';
import { CoachingPlansSummary } from '../models/coaching-plans-summary.model';
import { UIState } from '../models/ui-state.model';
import { AuthenticatedMemberService, Member } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class CoachingService {
    member: Member;
    MEMBER_APPOINTMENT_URL = '/api/live-services-coaching/members/';
    MEMBER_UNREAD_MESSAGES = '/api/live-services-coaching/notifications/member';
    MEMBER_GOALS = 'care-pathways-api/members/';
    LIVE_SERVICES_URL = {
        BASE: '#/live-services-coaching',
        COACHING_HUB_TAB_BASE: '#/live-services-coaching/coaching-hub?activeTab='
    };
    COACHING_HUB_TABS = {
        MY_GOALS: 'MyGoals',
        MY_MESSAGES: 'MyMessages'
    };


    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private coachingModuleService: CoachingModuleService,
        private httpClient: HttpClient
    ) {
        this.member = this.authenticatedMemberService.getAuthenticatedMember();
    }

    getCoachingModuleProgram(): Observable<CoachingModuleProgramModel> {
        const url = `${this.MEMBER_APPOINTMENT_URL}${this.member.id}/coaching-module-program`;
        return this.httpClient.get<CoachingModuleProgramModel>(url).pipe();
    }

    getCoachingPlansSummary(): Observable<CoachingPlansSummary> {
        const url = `${this.MEMBER_GOALS}${this.member.id}/coaching-plans/summary`;
        return this.httpClient.get<CoachingPlansSummary>(url);
    }

    fetch(): Observable<UIState<CoachingCardVM>> {
        return forkJoin({
            moduleProgram: this.getCoachingModuleProgram(),
            plansSummary: this.getCoachingPlansSummary()
        }).pipe(
            map((response: any) => ({
                data: this.coachingModuleService.getCoachingModuleState(response.moduleProgram, response.plansSummary),
                loading: false
            })),
            catchError((error: Error) => of({
                loading: false,
                error
            })),
            startWith({
                loading: true
            })
        );
    }

    getHrefPath(state: CoachingModuleState): string {
        const activeTab = this.getRouteFromCoachingModuleState(state);

        if (activeTab) {
            return `${this.LIVE_SERVICES_URL.COACHING_HUB_TAB_BASE}${activeTab}`;
        }
        return this.LIVE_SERVICES_URL.BASE;

    }

    private getRouteFromCoachingModuleState(state: CoachingModuleState): string {
        if (state === CoachingModuleState.Message || state === CoachingModuleState.EmptyTask) {
            return this.COACHING_HUB_TABS.MY_MESSAGES;
        }

        if (state === CoachingModuleState.Task) {
            return this.COACHING_HUB_TABS.MY_GOALS;
        }

        return '';
    }
}
