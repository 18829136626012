<div *ngIf="healthFormData && member && pcpFormData">
    <form #healthForm
          name="healthForm"
          method="POST"
          [action]="pcpFormData.formUrl"
          class="hidden">
        <input type="hidden"
               name="firstName"
               [value]="healthFormData.firstName" />
        <input type="hidden"
               name="lastName"
               [value]="healthFormData.lastName" />
        <input type="hidden"
               name="memberId"
               [value]="healthFormData.memberId" />
        <input type="hidden"
               name="employeeId"
               [value]="member.employeeId" />
        <input type="hidden"
               name="vhm_legacy_id"
               [value]="healthFormData.vhm_legacy_id" />
        <input type="hidden"
               name="isV2"
               [value]="pcpFormData.isV2" />
        <input type="hidden"
               name="bearer"
               [value]="healthFormData.jwtToken" />
        <input type="hidden"
               name="bearerV2"
               [value]="healthFormData.bearerV2" />
        <input type="hidden"
               name="langId"
               [value]="member.language" />
        <input type="hidden"
               name="sponsorId"
               [value]="member.sponsorId" />
        <input type="hidden"
               name="emailAddress"
               [value]="member.emailaddress" />
        <input type="hidden"
               name="startProgramYear"
               [value]="pcpFormData.startProgramYear"/>
        <input type="hidden"
               name="endProgramYear"
               [value]="pcpFormData.endProgramYear"/>
        <input type="hidden"
               name="rewardPopulation"
               [value]="healthFormData.rewardPopulation"/>
        <input type="hidden"
               name="rewardPopulationId"
               [value]="healthFormData.rewardPopulationId"/>
        <input type="hidden"
               name="dateOfBirth"
               [value]="member.dateofbirth" />
    </form>
</div>
