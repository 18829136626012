<div class="wrap" [ngStyle]="{'transform' : scale3d}">
    <div class="loader">
        <div class="load-inner">
            <div class="ballcolor ball_1">&nbsp;</div>
        </div>
        <div class="load-inner">
            <div class="ballcolor ball_2">&nbsp;</div>
        </div>
        <div class="load-inner">
            <div class="ballcolor ball_3">&nbsp;</div>
        </div>
        <div class="load-inner">
            <div class="ballcolor ball_4">&nbsp;</div>
        </div>
    </div>
    <div class="below" [innerHTML]="messageKey | translate"></div>
</div>
