import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Member, PillarTopic } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class SuggestedPillarTopicService {
    constructor(private httpClient: HttpClient) { }

    getSuggestedForMember(member: Member): Observable<PillarTopic[]> {
        return this.httpClient.get<PillarTopic[]>(`/api/members/${member.id}/suggestions/topics`);
    }
}
