<div class="topics-of-interest-modal">

  <div class="topics-of-interest-header-container">
    <h3>{{'TopicsOfInterest' | translate}}</h3>
  </div>

  <div class="topics-of-interest-content">
    <pillar-recommended-topics-list [value]="selected"
                                    [recommendedTopics]="recommendedTopics"
                                    (recommendedTopicSelect)="onTopicSelect($event)">
    </pillar-recommended-topics-list>
    <div class="pillars-accordion-list">
      <div class="pillar-selection-label">{{'PersonalizeExperience' | translate}}</div>
      <pillar-accordion *ngFor="let pillar of pillars"
                        [value]="selected"
                        [active]="activePillar && activePillar.id === pillar.id"
                        [pillar]="pillar"
                        (onAccordionClick)="onAccordionClick($event)"
                        (onPillarTopicSelection)="onTopicSelect($event)">
      </pillar-accordion>
    </div>

    <div>
      <button id="pillar-privacy-protection-button"
              class="privacy-protection-button"
              (click)="openPrivacyProtectionModal($event)"
              (keyup.enter)="openPrivacyProtectionModal($event)"
      >
        {{'ProtectedPrivacy' | translate}}
      </button>
    </div>
  </div>

  <div class="topics-of-interest-actions">
    <button id="topics-of-interest-cancel-btn"
            vpg-button
            variant="secondary"
            size="medium"
            (click)="close()">
      {{'Cancel' | translate}}
    </button>
    <button id="topics-of-interest-done-btn"
            vpg-button
            variant="primary"
            size="medium"
            [disabled]="!isValid"
            (click)="save()">
      {{'Done' | translate}}
    </button>
  </div>
</div>