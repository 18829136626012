import { Injectable } from '@angular/core';

import { Language } from '../models/enums';

@Injectable({
    providedIn: 'root'
})
export class LocaleService {

    mapMemberLanguageToKeycloakLocale(memberLanguage: Language) {
        if (Language[memberLanguage]) {
            return Language[memberLanguage];
        }
        return null;
    }
}
