import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { AuthenticatedMemberService, FeatureEnum, SingleSpaConfiguration } from '@genesis-frontend/genesis-utilities';

@Injectable({ providedIn: 'root' })
export class ChallengesPersonalCreateResolverService implements Resolve<SingleSpaConfiguration> {
    constructor(private authenticatedMemberService: AuthenticatedMemberService) {}
    resolve(): any {
        const hasFeatureFlag = (this.authenticatedMemberService.hasFeature(FeatureEnum.ChallengeRedesign2022)
            || this.authenticatedMemberService.hasFeature(FeatureEnum.SpotlightRedesign2022))
            && this.authenticatedMemberService.hasFeature(FeatureEnum.PersonalStepChallenges)
            || this.authenticatedMemberService.hasFeature(FeatureEnum.HealthyHabitChallenges);
        const appImportPath = hasFeatureFlag ? 'challenges-ui' : 'genesis-ui';
        const appName = hasFeatureFlag ? 'personal-challenges-ui' : 'personal-challenges-genesis-ui';

        return {
            appName,
            appRegex: new RegExp('^#/challenges/personal/create'),
            isStandAloneApp: false,
            appImportPath
        } as SingleSpaConfiguration;
    }
}
