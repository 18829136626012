import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'genesis-frontend-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
    errorTitle = this.translateService.instant('UnableToLoadCoaching');
    errorMessage = this.translateService.instant('TryRefreshingScreen');

    constructor(private translateService: TranslateService) {}
}
