import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MccJourneySummaryVM, TopicJourneysVM } from '@genesis-frontend/genesis-utilities';


@Injectable({ providedIn: 'root' })
export class JourneysAPIService {

    constructor(
        private httpClient: HttpClient
    ) {}

    getAllJourneysForTopic(sponsorId: number, topicId: number): Observable<TopicJourneysVM> {
        return this.httpClient.get<TopicJourneysVM>(this.baseSponsorPillarTopicJourneysURI(sponsorId, topicId));
    }

    private baseSponsorPillarTopicJourneysURI = (sponsorId: number, topicId: number): string =>
        `/journeys-api/sponsors/${sponsorId}/topics/${topicId}/journeys`;

    getAllJourneysForTopics(sponsorId: number, topicIds: number[]): Observable<MccJourneySummaryVM[]> {
        const url = `/journeys-api/sponsors/${sponsorId}/topics/journeys`;
        let params = new HttpParams();

        topicIds.forEach((topicId) => {
            params = params.append('topicIds', topicId.toString());
        });
        return this.httpClient.get<MccJourneySummaryVM[]>(url, {
            params
        });
    }
}
