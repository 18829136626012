<div class="global-challenge-join-a-challenge-container">
    <div class="challenge-onboarding-blocker-modal-content-join-challenge">
        <div class="challenge-onboarding-blocker-modal-title text-clr-oxford-blue">
            <span
                *ngIf="challengeStarted else elseBlock"
                class="challenge-title"
                [innerHTML]="'ChallengeStarted' | translate: { challengeName: data.contest.title }">
                Challenge started on
            </span>
            <ng-template #elseBlock>
                <span
                    class="challenge-title"
                    [innerHTML]="'ChallengeStarts' | translate: { challengeName: data.contest.title }">
                    Challenge starts
                </span>
            </ng-template>
        </div>
        <div class="challenge-onboarding-start-date text-clr-oxford-blue">{{data.contest.startDate | date:'MMMM dd'}}</div>
    </div>
    <div class="card-middle">
        <div class="global-challenge-countdown onboardingClock">
            <bubble-celebration
                #bubbleCelebrationComponent
                [particleCount]="bubbleParticleCount">
                <onboarding-flipclock
                    [showSeconds]="showCountdownSeconds"
                    [challengeStartDate]="data.contest.startDate"
                    (challengeStarted)="challengeHasStarted($event)"></onboarding-flipclock>
            </bubble-celebration>
            <div class="global-challenge-countdown-labels text-clr-oxford-blue">
                <span
                    class="countdown-label"
                    [translate]="'Days'">
                    Days
                </span>
                <span
                    class="countdown-label"
                    [translate]="'Hours'">
                    Hours
                </span>
                <span
                    class="countdown-label"
                    [translate]="'Minutes'">
                    Minutes
                </span>
                <span
                    class="countdown-label"
                    [translate]="'Seconds'">
                    Seconds
                </span>
            </div>
        </div>
        <div class="global-challenge-description text-clr-oxford-blue">{{data.contest.longDescription}}</div>
        <div
            class="global-challenge-lets-get-you-on-team text-clr-oxford-blue"
            [translate]="'JoinTeamHeading'">
            Let's get you on a team for the challenge!
        </div>
        <ng-container></ng-container>
    </div>

    <div class="card-footer">
        <div>
            <button
                class="vp-button larger vp-button-primary"
                [translate]="'Join'"
                (click)="nextScreen()"></button>
        </div>
        <div class="global-challenge-rules-link-wrapper">
            <a
                class="global-challenge-rules-link vp-text-link"
                [translate]="'ChallengeRules'"
                (click)="openRulesModal()">
                Challenge Rules
            </a>
        </div>
    </div>
</div>
