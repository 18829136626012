import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest
} from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';

import { AuthenticatedMemberService } from './authenticated-member.service';
import { KeycloakService } from './keycloak.service';
import { Environment, ENVIRONMENT } from '../config';

const MIN_SEC_TOKEN_TTL = 30;
const UNAUTHORIZED_STATUS_CODE = 401;

@Injectable({
    providedIn: 'root'
})
export class HttpRequestInterceptor implements HttpInterceptor {

    private localDevelopmentUrls = ['localhost', 'app.local.personifyhealth.com'];

    constructor(
        @Optional() private keycloakService: KeycloakService,
        private authenticatedMemberService: AuthenticatedMemberService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) { }

    private getAuthClient(): any {
        return this.keycloakService.getClient() ||
               this.authenticatedMemberService.getAuthClient();
    }

    private isFormData(value: any) {
        return typeof FormData !== 'undefined' && value instanceof FormData;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const client = this.getAuthClient();
        let token = this.getToken(client);

        const headers = this.isFormData(request.body)
            ? request.headers
            : request.headers.set('Content-Type', 'application/json; charset=utf-8');

        let modifiedUrl = request.url;
        if (!/^https?:\/\//i.test(request.url) && !this.localDevelopmentUrls.includes(window.location.hostname)) {
            // Adjust the URL to the new base URL
            modifiedUrl = `${this.environment.genesis.baseApiUrl}/${request.url.replace(/^\//, '')}`;
        }

        let authRequest = request.clone({
            headers,
            withCredentials: true,
            url: modifiedUrl
        });

        window.dispatchEvent(new CustomEvent('customResetLastRequestEvent'));

        if (!token) {
            return next.handle(authRequest).pipe(
                catchError((error) => this.handleError(error))
            );
        }

        authRequest = authRequest.clone({
            headers: authRequest.headers.set('Authorization', `Bearer ${token}`)
        });

        if (client.isTokenExpired(MIN_SEC_TOKEN_TTL)) {
            const tokenRefreshSubject = new Subject<HttpRequest<any>>();

            client.updateToken(MIN_SEC_TOKEN_TTL).then(() => {
                token = this.getToken(client);
                authRequest = authRequest.clone({
                    headers: authRequest.headers.set('Authorization', `Bearer ${token}`)
                });
                tokenRefreshSubject.next(authRequest);
                tokenRefreshSubject.complete();
            });

            return tokenRefreshSubject.pipe(
                flatMap((data) => {
                    return next.handle(data);
                }),
                catchError((error) => this.handleError(error))
            );
        }

        return next.handle(authRequest);
    }

    private handleError(error: HttpErrorResponse) {
        const client = this.getAuthClient();

        if (error.status === UNAUTHORIZED_STATUS_CODE) {
            if (client) {
                client.clearToken();
            }
        }

        return throwError(error);
    }

    private getToken(client: any) {

        const keycloakClient = client;

        if (keycloakClient && keycloakClient.token) {
            return keycloakClient.token;
        }

        return false;

    }
}
