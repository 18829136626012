<div class="journey-survey-modal-container"
     id="journey-survey-modal-id"
     #journeySurveyModal>
    <div class="journey-survey-modal-content"
         [formGroup]="form">
        <div *ngFor="let question of surveyQuestions; last as isLast; index as i"
             class="journey-survey-question-container">
            <span *ngIf="question.surveyAnswers.length === LIKERT_SCALE_OPTIONS_SIZE"
                  class="journey-survey-question-helper-text"
                  [translate]="'AgreeOrDisagree'">
                How much do you agree or disagree with the following statement?
            </span>
            <vpg-radio-button-group 
                [legend]="i+1 + '. ' + question.text"                        
                [id]="'journey-survey-question' + i">
                <div class="journey-survey-answer-container">
                    <vpg-radio-button 
                        *ngFor="let answer of question.surveyAnswers; index as j"
                        [id]="'journey-survey-question-' + i + '-answer-' + j"
                        [formControlName]="'question' + i"
                        [optionValue]="answer.id">
                        {{ answer.text }}
                    </vpg-radio-button>
                </div>
            </vpg-radio-button-group>
            <vpg-divider-line 
                *ngIf="!isLast"
                class="journey-survey-divider-line">
            </vpg-divider-line>
        </div>
    </div>
    <div class="journey-completed-modal-button-wrapper-override">
        <vpg-button
            [id]="'journey-survey-cancel-btn'"
            [btnType]="SECONDARY_BUTTON"
            (buttonClicked)="close(false)"
            [label]="'Cancel' | translate">
        </vpg-button>
        <vpg-button
            [id]="'journey-survey-submit-btn'"
            [btnType]="PRIMARY_BUTTON"
            (buttonClicked)="saveSurveyResponse()"
            [isDisabled]="form.invalid"
            [label]="'Submit' | translate">
        </vpg-button>
    </div>
</div>
