import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';

import {
    AuthenticatedMemberService,
    FeatureEnum,
    SingleSpaConfiguration,
    SweetAlertService
} from '@genesis-frontend/genesis-utilities';

@Injectable({ providedIn: 'root' })
export class ChallengesHhResolverService implements Resolve<SingleSpaConfiguration> {

    constants = {
        NOT_FOUND_ERROR: 404
    };
    trackerChallenge?: any;

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private httpClient: HttpClient,
        private sweetAlertService: SweetAlertService,
        private router: Router
    ) {}
    async resolve(route: ActivatedRouteSnapshot): Promise<any> {
        this.trackerChallenge = await this.validateTrackerChallengeType(this.authenticatedMemberService.getAuthenticatedMemberId(), Number(route.url[2].path));
        if (this.trackerChallenge?.promotedTrackerChallenge) {
            const appName = this.authenticatedMemberService.hasFeature(FeatureEnum.PHHCRedesign2023) ? 'phhc-challenges-ui' : 'phhc-challenges-genesis-ui';
            const appImportPath = this.authenticatedMemberService.hasFeature(FeatureEnum.PHHCRedesign2023) ? 'challenges-ui' : 'genesis-ui';
            return {
                appName: appName,
                appRegex: new RegExp('^#/challenges/healthyhabits'),
                isStandAloneApp: false,
                appImportPath: appImportPath
            } as SingleSpaConfiguration;
        }

        const appName = this.authenticatedMemberService.hasFeature(FeatureEnum.PersonalChallengesRedesign2023) ? 'hh-challenges-ui' : 'hh-challenges-genesis-ui';
        const appImportPath = this.authenticatedMemberService.hasFeature(FeatureEnum.PersonalChallengesRedesign2023) ? 'challenges-ui' : 'genesis-ui';

        return {
            appName: appName,
            appRegex: new RegExp('^#/challenges/healthyhabits'),
            isStandAloneApp: false,
            appImportPath: appImportPath
        } as SingleSpaConfiguration;
    }

    validateTrackerChallengeType(memberId: number, trackerId: number) {
        return new Promise((resolve, reject) => {
            this.httpClient.get<any>(`/api/members/${memberId}/tracker-challenges/${trackerId}/details`)
                .subscribe((response) => {
                    resolve(response);
                }, (error) => {
                    this.displayChallengeErrorMessage(error.status);
                    reject(null);
                });
        });
    }

    displayChallengeErrorMessage(statusCode: number) {
        if (statusCode === this.constants.NOT_FOUND_ERROR) {
            this.sweetAlertService.showNotAbleToJoinChallengeModal().then(() => {
                this.router.navigate(['challenges/dashboard']);
            });
        }
    }
}
