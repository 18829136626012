import { Injectable, InjectionToken } from '@angular/core';

declare global {
    interface Window {
        appEnvironment: IEnvironment | undefined;
    }
}

export const ENVIRONMENT = new InjectionToken<Environment>('Environment');

interface IPusher {
    key: string,
    keyEU?: string,
    cluster?: string,
    clusterEU?: string,
    wsHost?: string,
    wsPort?: string,
    wssPort?: string,
    config: {
        wsHost?: string,
        enabledTransports?: string[],
        disabledTransports?: string[],
        authEndpoint? : string,
        forceTLS?: false,
        encrypted?: false
    }
}

export type DomainEnvironment = Record<string, Partial<IEnvironment>>;

export interface IEnvironment {
    production: boolean,
    pusher: IPusher,
    euEnrollment: {
        baseUrl: string
    },
    naEnrollment: {
        baseUrl: string
    },
    ecommerce: {
        baseUrl: string
    },
    genesis: {
        baseUrl: string,
        baseApiUrl: string,
        cookieDomain: string
    },
    env: {
        env: string
    },
    newrelic: {
        licenseKey: string,
        applicationID: string,
        accountID: string,
        agentID: string
    },
    s3: {
        basePath: string
    },
    autoLogoutMinutes: number,
    logoutWarningSeconds: number,
    authenticationProvider: string,
    authServer: {
        agentRealm: string,
        baseUrl: string,
        realmUrl: string,
        realm: string,
        clientId: string
    },
    mixpanel: {
        token: string
    },
    filepicker: {
        apiKey: string,
        virusDetectWorkflowId: string,
        container: string
    },
    securedFilepicker: {
        apiKey: string,
        virusDetectWorkflowId: string,
        container: string
    }
    zendesk: {
        url: string,
        widgetSrc: string
    },
    cognigy: {
        url: string,
        phaTechnicalSupportUrl: string,
        phaPersonalSupportUrl: string
    }
    castle: {
        publishableKey: string
    }
    amCharts: {
        license: string
    },
    wellsource: {
        adminCenterUrl: string
    },
    tinyMCE: {
        apiKey: string
    },
    microfrontendCdn: {
        url: string
    },
    mixpanelClient: {
        token: string
    },
    electron: {
        healthCenter: {
            repo: string,
            channel: string
        }
    },
    healthComp: {
        base: string
    },
    braze: {
        us: {
            apiKey: string
            sdkEndpoint: string
        },
        eu: {
            apiKey: string
            sdkEndpoint: string
        }
    }
}

function isObject(value: any) {
    return value != null && typeof value === 'object';
}

function deepMerge(target: Record<string, any>, source: Record<string, any>) {
    const keys = Object.keys(target);
    if (!source) {
        return;
    }
    for (const key of keys) {
        if (isObject(target[key])) {
            deepMerge(target[key], source[key]);
        } else if (key in source) {
            target[key] = source[key];
        }
    }
}

export function mergeEnvironment(env: IEnvironment, domainEnv: DomainEnvironment): IEnvironment {
    const result = { ... env };
    deepMerge(result, domainEnv[window.location.host]);
    return result;
}

@Injectable({ providedIn: 'root' })
export class Environment implements IEnvironment {

    readonly production = false;
    readonly pusher: IPusher = {
        key: '',
        config: {}
    };

    readonly euEnrollment = {
        baseUrl: ''
    };

    readonly naEnrollment = {
        baseUrl: ''
    };

    readonly ecommerce = {
        baseUrl: ''
    };

    readonly genesis = {
        baseUrl: '',
        baseApiUrl: '',
        cookieDomain: ''
    };

    readonly env = {
        env: ''
    };

    readonly newrelic = {
        licenseKey: '',
        applicationID: '',
        accountID: '',
        agentID: ''
    };

    readonly s3 = {
        basePath: ''
    };

    readonly autoLogoutMinutes = 0;
    readonly logoutWarningSeconds = 0;
    readonly authenticationProvider = '';
    readonly authServer = {
        agentRealm: '',
        baseUrl: '',
        realmUrl: '',
        realm: '',
        clientId: ''
    };

    readonly mixpanel = {
        token: ''
    };

    readonly filepicker = {
        apiKey: '',
        virusDetectWorkflowId: '',
        container: ''
    };

    readonly securedFilepicker = {
        apiKey: '',
        virusDetectWorkflowId: '',
        container: ''
    };

    readonly zendesk = {
        url: '',
        widgetSrc: ''
    };

    readonly cognigy = {
        url: '',
        phaTechnicalSupportUrl: '',
        phaPersonalSupportUrl: ''
    };

    readonly castle = {
        publishableKey: ''
    };

    readonly amCharts = {
        license: ''
    };

    readonly wellsource = {
        adminCenterUrl: ''
    };

    readonly tinyMCE = {
        apiKey: ''
    };

    readonly microfrontendCdn = {
        url: ''
    };

    readonly mixpanelClient = {
        token: ''
    };

    readonly electron = {
        healthCenter: {
            repo: '',
            channel: ''
        }
    };

    readonly healthComp = {
        base: ''
    };

    readonly braze = {
        us: {
            apiKey: '',
            sdkEndpoint: ''
        },
        eu: {
            apiKey: '',
            sdkEndpoint: ''
        }
    };

    static from(data: IEnvironment): Environment {
        const result = new Environment();
        return Object.assign(result, data);
    }

    static setEnvironment(environment: IEnvironment) {
        window.appEnvironment = environment;
    }

    static fromGlobal(): Environment {
        if (!window.appEnvironment) {
            // eslint-disable-next-line no-console
            console.warn ('Environment is not defined');
            return Environment.from({} as any);
        }
        return Environment.from(window.appEnvironment);
    }
}
