import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SponsorGenderIdentitiesService {
    constructor(
        private httpClient: HttpClient
    ) { }

    getSponsorGenderIdentities(sponsorId: number): Observable<any> {
        return this.httpClient.get<any>(`/api/sponsors/${sponsorId}/gender-identities`);
    }
}
