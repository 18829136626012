import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { AppointmentVM } from '../../models/appointment-vm.model';
import { LiveServicesCoachingStatus, RewardableActionType } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'genesis-frontend-appointment',
    templateUrl: './appointment.component.html',
    styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnChanges {
    @Input() screenreaderText?: string;
    @Input() appointment!: AppointmentVM;

    readonly rewardableActionType = RewardableActionType.Coaching;
    showReward = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.appointment) {
            this.showReward = this.appointment.status === LiveServicesCoachingStatus.Scheduled;
        }
    }
}
