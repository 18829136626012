import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class KeycloakService {
    private client: any;

    static fromClient(client: any): KeycloakService {
        const service = new KeycloakService();
        service.setClient(client);
        return service;
    }

    setClient(client: any) {
        this.client = client;
    }

    getClient() {
        return this.client;
    }

    isUsingKeycloak() {
        return this.client && this.client.$vpProvider === 'keycloak';
    }
}
