import { Pipe, PipeTransform } from '@angular/core';

import { Units } from '../models/enums/units.enum';
import { ConversionService } from '../services/conversion.service';

@Pipe({ name: 'weightLabel' })
export class WeightLabelPipe implements PipeTransform {
    constructor(private conversionService: ConversionService) { }

    transform(value: any, fromUnit: any, toUnit: any, precision: any, withStyle: boolean, isHTMLSyntax: boolean): string | null {
        if (value == null) {
            return null;
        }
        if (toUnit === Units.UkImperialWeight) {
            return this.stones(value, fromUnit, precision, withStyle, isHTMLSyntax);
        }

        const result = this.conversionService
            .convert(value, fromUnit, toUnit, null)
            .toFixed(precision);

        if (withStyle) {
            const syntaxType = isHTMLSyntax ? `<strong>${result}</strong>` : `[bold]${result}[/]`;
            return `${syntaxType} ${toUnit}`;
        }
        return `${result} ${toUnit}`;
    }

    private stones(weight: any, fromUnit: any, precision: any, withStyle: boolean, isHTMLSyntax: boolean) {
        const STONES_CONVERSION_FACTOR = 14;
        let stones = this.conversionService.convert(weight, fromUnit, Units.UkImperialWeight, null);

        const precisionNum = this.getPrecision(precision);

        if (!stones) {
            return null;
        }

        let lbs = parseFloat(
            ((stones % 1) * STONES_CONVERSION_FACTOR).toFixed(precisionNum)
        );

        if (lbs === STONES_CONVERSION_FACTOR) {
            stones = stones + 1;
            lbs = 0;
        }

        stones = Math.floor(stones);

        if (withStyle) {
            return isHTMLSyntax ? `<strong>${stones}</strong> st <strong>${lbs}</strong> lbs` : `[bold]${stones}[/] st [bold]${lbs}[/] lbs`;
        }

        return `${stones} st ${lbs} lbs`;
    }

    private getPrecision(value: any): number {
        const num = Number(value);
        return num > 0 ? num : 0;
    }
}
