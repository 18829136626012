<div class="pillar-topic-selection-container">
  <div class="flex-centered-content">
    <a
      vpg-link
      id="pillars-recommended-topic-link"
      size="large"
      tabindex="0"
      [href]="topicUrl"
      target="_blank"
    >
      {{ topic.data.name }}
    </a>
  </div>

  <div>
    <input id="pillar-topic-selection-checkbox-{{topic.data.id}}"
           class="pillar-option-checkmark"
           type="checkbox"
           [attr.aria-label]="topic.data.name"
           [checked]="(topic.selected)"
           (change)="onTopicSelection($event, topic)"
           (keyup.enter)="onTopicSelection($event, topic)">
  </div>
</div>