export interface GenesisMemberState {
    authentication: any;
    notificationAvailable: boolean;
    redeemableWallets: any;
    rewardsOverview: any;
    sponsorVpNavigateFeatures: any;
    mixpanelObject: any;
    historicalSurveys: any;
    memberFeatures: any;
    challengesData: any;
    rewardLabels: any;
    memberFeatureToggle: any;
    readSecurityData: any;
    rewardsStatementFilterInterval: any;
    totalSummary: any;
    rewardsStatementMemberPrograms: any;
    homepageModulesRewards: any;
}
