import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { UnleashFeature } from '../models';
import { UnleashFlag } from '../models/enums';

@Injectable({
    providedIn: 'root'
})
export class UnleashFlagsService {
    static Token = 'UnleashFlags';

    unleashFlags = new Map<string, UnleashFeature>();

    constructor(private httpClient: HttpClient) {}

    fetchUnleashFlags(): Observable<UnleashFeature[]> {
        return this.httpClient.get<UnleashFeature[]>('/bff/feature-toggles');
    }

    setUnleashFlags(unleashFeatures: UnleashFeature[]): void {
        this.unleashFlags = new Map(unleashFeatures.map((unleashFeature) => [unleashFeature.name, unleashFeature]));
    }

    hasUnleashFlag(flag: UnleashFlag): boolean {
        return this.unleashFlags.has(flag);
    }

    hasAllUnleashFlags(flags: UnleashFlag[]): boolean {
        return flags.every((flag) => this.hasUnleashFlag(flag));
    }

    hasSomeUnleashFlags(flags: UnleashFlag[]): boolean {
        return flags.some((flag) => this.hasUnleashFlag(flag));
    }
}
