import { CacheInterface, CacheItem, isCacheExpired, isCacheValid } from './cache';
import { ScopedLocalStorage } from './scoped-local-storage';
import { SessionCache } from './session-cache';

export class PersistentCache<T = any> implements CacheInterface<T> {

    private scopedLocalStorage: ScopedLocalStorage<T>;
    private sessionCache: SessionCache<T>;

    constructor(scope: string, private maxAgeInSeconds: number) {
        this.scopedLocalStorage = new ScopedLocalStorage<T>(scope);
        this.sessionCache = new SessionCache<T>(scope);
    }

    getItem(key: string): CacheItem<T> | null {

        if (this.sessionCache.hasItem(key)) {
            const item = this.sessionCache.getItem(key);
            if (this.isValid(item)) {
                return item;
            }
        }

        if (this.scopedLocalStorage.hasItem(key)) {
            const item = this.scopedLocalStorage.getItem(key);
            if (this.isValid(item)) {
                this.sessionCache.setItem(key, item.value);
                return item;
            }
        }

        return null;
    }

    hasItem(key: string): boolean {
        const item = this.getItem(key);
        return !!item;
    }

    setItem(key: string, value: T): void {
        this.sessionCache.setItem(key, value);
        this.scopedLocalStorage.setItem(key, value);
    }

    removeItem(key: string): void {
        this.sessionCache.removeItem(key);
        this.scopedLocalStorage.removeItem(key);
    }

    async getOrFetch(key: string, action: () => Promise<T>): Promise<T> {

        const fetchAndCache = () => action().then((response) => {
            this.setItem(key, response);
            return response;
        });

        const item = this.getItem(key);
        if (item && item.time > 0) {
            if (this.isExpired(item.time)) {
                fetchAndCache();
            }
            return item.value;
        }

        const result = await fetchAndCache();
        return result;
    }

    private isExpired(cachedAt: number) {
        return isCacheExpired(cachedAt, this.maxAgeInSeconds);
    }

    private isValid(item: CacheItem<T> | null): item is CacheItem<T> {
        return isCacheValid(item, this.maxAgeInSeconds) && (item.value != null);
    }
}
