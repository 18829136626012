import { MemberType, UnitOfMeasurement } from '../enums';
import { Member, MemberSettings } from '../interfaces';

// UPDATE PATH for DEFAULT AVATAR
const DEFAULT_PROFILE_IMAGE_URL = '/img/navigation/DefaultAvatar.png';

export class MemberClass implements Member {
    id: any;
    logoUrl: any;
    firstname: any;
    lastname: any;
    gender: any;
    genderIdentity: any;
    displayName: any;
    title: any;
    department: any;
    location: any;
    dateofbirth: any;
    emailaddress: any;
    countryId: any;
    is_deleted: any;
    isWizardFormCompleted: any;
    vhm_legacy_id: any;
    sponsorId: any;
    officeId: any;
    businessUnitId: any;
    memberSettingsId: any;
    timezoneId: any;
    memberType: any;
    myBio: any;
    wordsToLiveBy: any;
    backgroundImage: any;
    profilepicture: any;
    language: any;
    unitOfMeasurement: any;
    status: any;
    hireDate: any;
    originalHireDate: any;
    employeeId!: string;
    division: any;
    createdDate: any;
    updatedDate: any;
    enrollmentDate: any;
    companyId: any;
    userName: any;
    phoneNumber: any;
    phoneNumberVerified: any;
    homePhoneNumber: any;
    businessUnit: any;
    office: any;
    externalId: any;
    timezone: any;
    sponsor: any;
    personIdString: any;
    profileIdString: any;
    phoneNumberConsent: any;
    memberSettings!: MemberSettings;

    static getFirstAndLastName(member: any): string {
        return member.firstName + ' ' + member.lastName;
    }

    getLegacyMemberId() {
        return this.vhm_legacy_id;
    }

    getMemberLanguageCode() {
        let languageCode = 'en-US';

        if (this.language) {
            const languageNameArray = this.language.split('_');
            languageNameArray[1] = languageNameArray[1].toUpperCase();
            languageCode = languageNameArray.join('-');
        }

        return languageCode;
    }

    getId() {
        return this.id;
    }

    getLogoUrl() {
        return this.logoUrl;
    }

    getSponsorId() {
        return this.sponsorId;
    }

    isSupporter() {
        return this.memberType === MemberType.Supporter;
    }

    isPrimary() {
        return this.memberType === MemberType.Primary
            || this.memberType === MemberType.Spouse
            || this.memberType === MemberType.DomesticPartner
            || this.memberType === MemberType.Dependent
            || this.memberType === MemberType.Other;
    }

    getLanguage() {
        return this.language;
    }

    setLanguage(language: any) {
        this.language = language;
    }

    getUnitOfMeasurement() {
        return this.unitOfMeasurement;
    }

    setUnitOfMeasurement(unitOfMeasurement: UnitOfMeasurement) {
        this.unitOfMeasurement = unitOfMeasurement;
    }

    setHomeBackgroundImage(backgroundImage: string) {
        this.backgroundImage = backgroundImage;
    }

    getProfileImage() {
        return this.profilepicture ? this.profilepicture : DEFAULT_PROFILE_IMAGE_URL;
    }

    hasProfileImage() {
        return !(this.profilepicture == null || this.profilepicture.includes(DEFAULT_PROFILE_IMAGE_URL));
    }

    setPhoneNumber(phoneNumber?: string) {
        this.phoneNumber = phoneNumber;
    }

    setPhoneNumberVerified(phoneNumberVerified: boolean) {
        this.phoneNumberVerified = phoneNumberVerified;
    }

    setProfileImage(profileImageUrl: string) {
        this.profilepicture = profileImageUrl;
    }

    setTutorialCompleted(completed: boolean) {
        this.isWizardFormCompleted = completed;
    }

    getOfficeId() {
        return this.officeId;
    }

    getBusinessUnitId() {
        return this.businessUnitId;
    }

    getCompanyId() {
        return this.companyId;
    }

    getCountryId() {
        return this.countryId;
    }

    getCreatedDate() {
        return this.createdDate;
    }

    getExternalId() {
        return this.externalId;
    }
}
