import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';

import { ExternalLinkVideoPlayerComponent } from './external-link-video-player.component';


@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        BrowserModule,
        ModalModule.forRoot()
    ],
    declarations: [
        ExternalLinkVideoPlayerComponent
    ],
    exports: [
        ExternalLinkVideoPlayerComponent
    ]
})
export class ExternalMediaPlayerModule { }
