import { Injectable, NgZone } from '@angular/core';
import { getAppStatus, registerApplication, setMountMaxTime, setUnloadMaxTime, setUnmountMaxTime, start } from 'single-spa';

import { ShellApplication } from '../core';
import { MicrofrontendNames, SingleSpaConfiguration } from '../models';

declare const System: any;
declare const window: Window & Record<string, any>;
@Injectable({
    providedIn: 'root'
})
export class SingleSpaConfigurationService {
    spaPublicPathVarName: Record<string, string> = { 'admin-ui': 'clientSingleSpaPublicPath' };
    spaBundleVarName: Record<string, string> = { 'admin-ui': 'adminSingleSpa' };

    constructor(private zone: NgZone) { }

    activateModule(module: SingleSpaConfiguration, props: ShellApplication) {

        const appStatus = getAppStatus(module.appName);

        if (appStatus === null) {
            this.registerApp(module, props);
        }

        if (module.isStandAloneApp) {
            module.active = true;
        }

        start();
    }

    deactivateModule(module: SingleSpaConfiguration) {
        module.active = false;
        start();
    }

    private registerApp(module: SingleSpaConfiguration, props: ShellApplication) {
        props.updateProperties();
        if ([MicrofrontendNames.GenesisMicroFrontend.toString(),
            MicrofrontendNames.ClientAdminMicroFrontend.toString()].includes(module.appImportPath)) {
            this.registerGenesisMicroFrontend(module, props);
        } else {
            this.registerMicroFrontend(module, props);
        }
    }

    private registerMicroFrontend(module: SingleSpaConfiguration, props: any) {
        registerApplication(
            module.appName,
            () => System.import(module.appImportPath || module.appName),
            this.getActivityFunction(module),
            props
        );
    }

    private registerGenesisMicroFrontend(module: SingleSpaConfiguration, props: any) {

        setMountMaxTime(30000, false);
        setUnmountMaxTime(30000, false);
        setUnloadMaxTime(30000, false);

        window[this.spaPublicPathVarName[module.appImportPath]] = this.getApplicationPublicPath(module.appImportPath);

        System.import('createjs').then(() => {
            registerApplication(
                module.appName,
                () => System.import(module.appImportPath).then(() => {
                    const app: any = window[this.spaBundleVarName[module.appImportPath]];

                    if (module.appImportPath === MicrofrontendNames.GenesisMicroFrontend) {
                        app.zone = this.zone;
                    }
                    app.setPublicPath(this.getApplicationPublicPath(module.appImportPath));
                    return app;
                }),
                this.getActivityFunction(module),
                props
            );
        });
    }

    private getApplicationPublicPath(appImportPath: string) {
        let url = (window as any).System.resolve(appImportPath);
        url = url.substring(0, url.lastIndexOf('/') + 1);
        return url;
    }

    private getActivityFunction(module: SingleSpaConfiguration) {
        return (location: Location) => {
            if (module.isStandAloneApp && module.active !== undefined) {
                return module.active;
            }
            return module.isStandAloneApp || (module.appRegex !== null && !!location.hash.match(module.appRegex));
        };
    }
}
