<div class="stats-modal"
    *ngIf="activitySuggestions$ | async as activitySuggestions"
    [cdkTrapFocus]="true"
    [cdkTrapFocusAutoCapture]="true">
    <vpg-dialog-header [closeButtonAriaLabel]="closeButtonAriaLabel">
        {{ 'SelfTrack' | translate }} {{ title | translate }}
    </vpg-dialog-header>
    <vpg-dialog-body>
        <div class="self-enter-form-body activity-steps input-with-error-wrapper"
            *ngIf="!isStatType(StatType.Workout)">
            <vpg-input-field [value]="DateUtils.format(workoutDate, 'YYYY-MM-DD')"
                             (input)="onChangeDate($event)"
                             [name]="'self-enter-date'"
                             [label]="'SelectDate' | translate"
                             type="date"
                             [max]="maxDateString">
            </vpg-input-field>
        </div>
        <div class="self-enter-form-body activity-steps input-with-error-wrapper"
             *ngIf="isStatType(StatType.Steps)">
            <vpg-input-field [(ngModel)]="steps"
                             [name]="'self-enter-steps-input'"
                             [label]="'EnterTotal' | translate"
                             (input)="onChange($event)"
                             onpaste="return false"
                             [placeholder]="'EnterNumberOfSteps' | translate"
                             type="number"
                             aria-describedby="self-enter-steps-error"
                             [disabled]="disableModalInput">
            </vpg-input-field>
            <div *ngIf="showError"
                 role="alert"
                 id="self-enter-steps-error"
                 class="self-enter-form-error"
                 [translate]="'CannotExceed30000TotalSteps'">
            </div>
        </div>
        <div class="self-enter-form-body activity-mindful-minutes input-with-error-wrapper"
            *ngIf="isStatType(StatType.MindfulMinutes)">
            <vpg-input-field [name]="'self-enter-mindful-minutes-input'"
                             [label]="'EnterTotal' | translate"
                             type="number"
                             aria-describedby="self-enter-mindful-minutes-error"
                             [placeholder]="'EnterDaysTotalMindfulMinutes' | translate"
                             (input)="onChange($event)"
                             [(ngModel)]="mindfulMinutes"
                             [disabled]="disableModalInput">
            </vpg-input-field>
            <div *ngIf="showError"
                 role="alert"
                 id="self-enter-mindful-minutes-error"
                 class="self-enter-form-error">
                Cannot exceed 1440 mindful minutes per day
            </div>
        </div>
        <div
            class="self-enter-form-body activity-steps input-with-error-wrapper"
            *ngIf="isStatType(StatType.Sleep)">
            <vpg-input-field [ngModel]="sleep"
                             (ngModelChange)="sleep = $event"
                             [label]="'EnterTotal' | translate"
                             [name]="'self-enter-sleep-input'"
                             (input)="onChange($event)"
                             onpaste="return false"
                             [placeholder]="'EnterHoursOfSleep' | translate"
                             type="number"
                             aria-describedby="self-enter-sleep-error"
                             [disabled]="disableModalInput">
            </vpg-input-field>
            <div *ngIf="showError"
                 role="alert"
                 id="self-enter-sleep-error"
                 class="self-enter-form-error">
                Please enter a valid sleep count
            </div>
        </div>
        <div class="self-enter-form-body activity-weight"
             *ngIf="isStatType(StatType.Weight)">
            <div class="self-enter-weight-input-container">
                <div *ngIf="isUnitOfMeasurement(unitsOfMeasurement.Imperial)">
                    <vpg-input-field [name]="'self-enter-weight-input-value-imperial'"
                                     [ngClass]="{ 'self-enter-input-invalid': showError }"
                                     maxlength="6"
                                     [label]="'EnterTotal' | translate"
                                     (input)="onChange($event)"
                                     type="number"
                                     aria-describedby="self-enter-weight-error"
                                     [ariaLabel]="'Enter your weight'"
                                     [placeholder]="'EnterWeightInPounds' | translate"
                                     [(ngModel)]="weight.value"
                                     [tooltip]="translations.valueOutOfRange(45, 999)"
                                     tooltipClass="self-enter-tooltip"
                                     tooltipPlacement="bottom"
                                     tooltipTrigger="hover focus"
                                     [disabled]="disableModalInput">
                    </vpg-input-field>
                    <div *ngIf="showError"
                         role="alert"
                         id="self-enter-weight-imperial-error"
                         class="self-enter-form-error">
                        {{ translations.valueOutOfRange(45, 999) }}
                    </div>
                </div>
                <div *ngIf="isUnitOfMeasurement(unitsOfMeasurement.Metric)">
                    <vpg-input-field [label]="'EnterTotal' | translate"
                                     [name]="'self-enter-weight-input-value-metric'"
                                     [ngClass]="{ 'self-enter-input-invalid': showError }"
                                     maxlength="6"
                                     (input)="onChange($event)"
                                     step="0.1"
                                     type="number"
                                     aria-describedby="self-enter-weight-error"
                                     [ariaLabel]="'Enter your weight'"
                                     [placeholder]="'Enter your weight'"
                                     [(ngModel)]="weight.value"
                                     [tooltip]="translations.valueOutOfRange(20, 453)"
                                     [disabled]="disableModalInput">
                    </vpg-input-field>
                    <div *ngIf="showError"
                         role="alert"
                         id="self-enter-weight-metric-error"
                         class="self-enter-form-error">
                        {{ translations.valueOutOfRange(20, 453) }}
                    </div>
                </div>
                <div *ngIf="isUnitOfMeasurement(unitsOfMeasurement.UK_Imperial)"
                    class="self-enter-weight-uk">
                    <div>
                        <vpg-input-field [name]="'self-enter-weight-input-stone'"
                                         [label]="'MeasureUnitStone' | translate"
                                         [ngClass]="{ 'self-enter-input-invalid': showError }"
                                         maxlength="6"
                                         step="1"
                                         (input)="onChange($event)"
                                         type="number"
                                         aria-describedby="self-enter-weight-error"
                                         [ariaLabel]="'EnterWeightInStone' | translate"
                                         placeholder="0"
                                         [(ngModel)]="weight.stone"
                                         [tooltip]="translations.valueOutOfRange(4, 71)"
                                         [disabled]="disableModalInput">
                        </vpg-input-field>
                        <div *ngIf="showError"
                             role="alert"
                             id="self-enter-weight-uk-stone-error"
                             class="self-enter-form-error">
                            {{ translations.valueOutOfRange(4, 71) }}
                        </div>
                    </div>
                    <div>
                        <vpg-input-field [name]="'self-enter-weight-input-pounds'"
                                         [label]="'MeasureUnitPounds' | translate"
                                         [ngClass]="{ 'self-enter-input-invalid': showAdditionalError }"
                                         maxlength="6"
                                         (input)="onChange($event)"
                                         step="0.1"
                                         type="number"
                                         aria-describedby="self-enter-weight-error"
                                         [ariaLabel]="'EnterWeightInPounds' | translate"
                                         placeholder="0"
                                         [(ngModel)]="weight.pounds"
                                         [tooltip]="translations.valueOutOfRange(0, 13.9)"
                                         [disabled]="disableModalInput">
                        </vpg-input-field>
                        <div *ngIf="showAdditionalError"
                             role="alert"
                             id="self-enter-weight-uk-pounds-error"
                             class="self-enter-form-error">
                            {{ translations.valueOutOfRange(0, 13.9) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="self-enter-form-body activity-blood-pressure"
            *ngIf="isStatType(StatType.BloodPressure)">
            <div class="blood-pressure-input-container">
                <vpg-input-field class="blood-pressure-input"
                                 [label]="'SystolicBloodPressure' | translate"
                                 (input)="onChange($event)"
                                 [name]="'bloodPressureSystolic'"
                                 [(ngModel)]="bloodPressureSystolic"
                                 ng-change="systolicChanged=true"
                                 [ngClass]="
                                 showError
                                 ? 'self-enter-input self-enter-input-blood-pressure error bloodpressure'
                                 : 'self-enter-input self-enter-input-blood-pressure bloodpressure'"
                                 type="number"
                                 aria-describedby="invalidSystolic"
                                 [tooltip]="translations.valueOutOfRange(90, 225)"
                                 [disabled]="disableModalInput">
                </vpg-input-field>
                <span class="bp-mmHg">{{ 'MeasureUnitmmHg' | translate }}</span>
                <div *ngIf="showError"
                     role="alert"
                     id="self-enter-systolic-blood-pressure-error"
                     class="self-enter-form-error">
                    {{ translations.valueOutOfRange(90, 225) }}
                </div>
            </div>
            <div class="blood-pressure-input-container">
                <vpg-input-field class="blood-pressure-input"
                                 label="{{ 'DiastolicBloodPressure' | translate }}"
                                 (input)="onChange($event)"
                                 [name]="'bloodPressureDiastolic'"
                                 [(ngModel)]="bloodPressureDiastolic"
                                 ng-change="diastolicChanged=true"
                                 [ngClass]="
                                 showAdditionalError
                                 ? 'self-enter-input self-enter-input-blood-pressure error bloodpressure'
                                 : 'self-enter-input self-enter-input-blood-pressure bloodpressure'"
                                 type="number"
                                 aria-describedby="invalidDiastolic"
                                 [tooltip]="translations.valueOutOfRange(40, 140)"
                                 [disabled]="disableModalInput">
                </vpg-input-field>
                <span class="bp-mmHg">{{ 'MeasureUnitmmHg' | translate }}</span>
                <div *ngIf="showAdditionalError"
                     role="alert"
                     id="self-enter-diastolic-blood-pressure-error"
                     class="self-enter-form-error">
                    {{ translations.valueOutOfRange(40, 140) }}
                </div>
            </div>
        </div>
        <div class="self-enter-form-body activity-workout"
            *ngIf="isStatType(StatType.Workout)">
            <vpg-tabs id="demo"
                      [selectedTabIndex]="selectedTabIndex"
                      (selectedTabIndexChange)="onTabIndexChange($event)">
                <vpg-tab [label]="tabsLabels[0]">
                    <div class="activity-dropdown-minutes-input-wrapper vpg-tabs-stats-modal-wrapper">
                        <div class="dropdown-step-calculation-wrapper">
                            <vpg-input-field [value]="DateUtils.format(workoutDate, 'YYYY-MM-DD')"
                                             (input)="onChangeDate($event)"
                                             [name]="'self-enter-date-workout'"
                                             [label]="'SelectDate' | translate"
                                             type="date"
                                             [max]="maxDateString">
                            </vpg-input-field>
                            <vpg-dropdown-next
                                [options]="dropDownItems"
                                [optionLabel]="'text'"
                                [id] = "'activity-dropdown'"
                                [label]="'WhichActivity' | translate"
                                [placeholder]="'SelectAnActivity' | translate"
                                (valueChange)="selectActivityFromDropdown($event)">
                            </vpg-dropdown-next>
                            <div aria-live="polite">
                                <div *ngIf="selectedActivity && !isMainInputInvalid">
                                    <div>
                                        {{ mainMinutesEntry ? mainMinutesEntry : 0 }} {{ 'Minutes' | translate }} =
                                        <strong>
                                            {{ mainMinutesEntry ? mainMinutesEntry * mainInputStepConstant : (0 | number) }}
                                            {{ 'Steps' | translate }}
                                        </strong>
                                    </div>
                                    <div>(1 {{ 'shortMinute' | translate }} = {{ mainInputStepConstant }} {{ 'Steps' | translate }})</div>
                                </div>
                            </div>
                        </div>
                        <div class="minutes-input-wrapper">
                            <div class="minutes-input">
                                <vpg-input-field class="activity-minutes"
                                                 [name]="'minutes'"
                                                 [type]="'number'"
                                                 [label]="'Minutes' | translate"
                                                 [placeholder]="'EnterMinutesButton' | translate"
                                                 (input)="onChange($event)"
                                                 [(ngModel)]="mainMinutesEntry">
                                </vpg-input-field>
                            </div>
                            <div *ngIf="showError"
                                 id="workout-minutes-error"
                                 role="alert"
                                 class="minutes-error-label self-enter-form-error"
                                 translate="CannotExceed480TotalMinutes">
                            </div>
                        </div>
                    </div>
                </vpg-tab>
                <vpg-tab [label]="tabsLabels[1]">
                    <accordion class="view-activity vpg-tabs-stats-modal-wrapper"
                               [isAnimated]="true"
                               [closeOthers]="true">
                        <vpg-input-field [value]="DateUtils.format(workoutDate, 'YYYY-MM-DD')"
                                         (input)="onChangeDate($event)"
                                         [name]="'self-enter-date-activity-tab'"
                                         [label]="'SelectDate' | translate"
                                         type="date"
                                         [max]="maxDateString">
                        </vpg-input-field>
                        <ng-container *ngFor="let activity of activities; index as i">
                            <accordion-group
                                *ngIf="activity.steps !== 0 || activity.duration !== 0"
                                [panelClass]="'activityEdit'"
                                [isDisabled]="activity.activityType !== 'ManuallyEnteredDurationActivity'"
                                (isOpenChange)="setStepConversionConstantAndInput(activity, i)">
                                <button
                                    accordion-heading
                                    class="activity-info"
                                    *ngIf="activity.steps !== 0 || activity.duration !== 0">
                                    <div class="row workout-info-row">
                                        <a
                                            vpg-link
                                            class="edit-link"
                                            [size]="'large'"
                                            [bold]="true"></a>
                                        <div class="description-container">
                                            <div class="description-row">
                                                <em class="fas fa-sm fa-edit fa-pointer edit-icon"></em>
                                                <span class="activity-description">
                                                    {{ activity.activityDescription }}
                                                </span>
                                            </div>
                                            <span class="duration-text">{{ activity.duration }} {{ 'MinutesChallenges' | translate }}</span>
                                        </div>
                                        <span class="steps-text">
                                            <span class="steps-number">{{ activity.steps | number }}</span>
                                            &nbsp;
                                            <span class="steps-label">{{ 'Steps' | translate }}</span>
                                        </span>
                                    </div>
                                </button>
                                <div class="activity-edit-wrapper">
                                    <div class="update-minutes">{{ 'PleaseEnterUpdatedMinutes' | translate }}</div>
                                    <div class="edit-minutes-wrapper">
                                        <vpg-input-field [name]="'edit-minutes-' + i"
                                                         type="number"
                                                         [label]="'Minutes' | translate"
                                                         (input)="onChange($event)"
                                                         [(ngModel)]="editMinutesEntry">
                                        </vpg-input-field>
                                    </div>
                                    <div *ngIf="showEditError"
                                         role="alert"
                                         id="workout-edit-minutes-error"
                                         class="minutes-error-label self-enter-form-error"
                                         translate="CannotExceed480TotalMinutes">
                                    </div>
                                    <div class="edit-conversion-text">
                                        {{ editMinutesEntry ? editMinutesEntry : 0 }}
                                        {{ 'Minutes' | translate }} =
                                        {{ editMinutesEntry ? editMinutesEntry * stepConstant : (0 | number) }} {{ 'Steps' | translate }} (1
                                        {{ 'shortMinute' | translate }} = {{ stepConstant }} {{ 'Steps' | translate }})
                                    </div>
                                    <vpg-dialog-actions class="activity-edit-buttons">
                                        <button
                                            vpg-button
                                            id="workout-delete-button"
                                            variant="secondary"
                                            (click)="deleteActivity(activity)"
                                        >
                                            {{ 'Delete' | translate }}
                                        </button>
                                        <div aria-live="polite">
                                            <span id="delete-status"
                                                  class="sr-only">
                                            {{ deleteStatusMessage | translate }}
                                            </span>
                                        </div>
                                        <button
                                            vpg-button
                                            id="workout-edit-save-button"
                                            variant="primary"
                                            [disabled]="isUpdateDisabled"
                                            (click)="saveActivity(activity)"
                                        >
                                            {{ 'Save' | translate }}
                                        </button>
                                        <div aria-live="polite">
                                            <span id="update-status"
                                                  class="sr-only">
                                            {{ updateStatusMessage | translate }}
                                        </span>
                                        </div>
                                    </vpg-dialog-actions>
                                </div>
                            </accordion-group>
                        </ng-container>
                        <accordion-group
                            [panelClass]="'total-steps'"
                            [isDisabled]="true">
                            <div
                                class="workout-total-steps-container"
                                accordion-heading>
                                <span class="total">
                                    <strong>{{ 'TotalHeader' | translate }}</strong>
                                </span>
                                <span>{{ totalNumberOfSteps | number }} {{ 'Steps' | translate }}</span>
                            </div>
                        </accordion-group>
                    </accordion>
                </vpg-tab>
            </vpg-tabs>
        </div>
    </vpg-dialog-body>
    <vpg-dialog-actions>
        <button
            vpg-button
            id="activity-cancel-button"
            variant="secondary"
            (click)="closeStatsWidget('')"
        >
            {{ 'Cancel' | translate }}
        </button>
        <button
            vpg-button
            *ngIf="selectedTabIndex !== 1"
            id="activity-save-btn"
            variant="primary"
            [disabled]="isSaveDisabled"
            (click)="addActivity()"
        >
            {{ 'Save' | translate }}
        </button>
        <div aria-live="polite">
            <span id="save-status"
                  class="sr-only">
                {{ saveStatusMessage | translate }}
            </span>
        </div>
    </vpg-dialog-actions>
</div>
