<span tabindex="0"
      [attr.aria-label]="isEmptyGraph ? 'No data on the ' + routeType + ' graph.' : ''">
    <ng-container *ngIf="!isEmptyGraph">
      <span *ngFor="let data of graphData"
            [attr.aria-label]="getChartElementAria(data)">
      </span>
    </ng-container>
</span>
<div *ngIf="!isStatsPage && !graphLoaded" class="stat-graph-loading-spinner-container">
    <div class="stat-graph-loading-spinner"></div>
</div>
<div class="graph-box"
     *ngIf="isStatsPage || graphLoaded"
     [ngClass]="isStatsPage ? 'box-shadow-effect' : 'transform-stat-graph'">
    <div class="stat-graph" [id]="routeType + '-stat-graph'"></div>
    <div *ngIf="!legendDisabled" [id]="routeType + '-legend'"></div>
</div>
<div class="graph-box box-shadow-effect"
     [ngClass]="showAdditionalGraph && isStatsPage ? 'show-graph' : 'hide-graph'">
    <div id="stat-graph-addnl"></div>
    <div *ngIf="!legendAdditionalDisabled" id="legend-addnl"></div>
</div>
<div class="vpg-dropdown-wrapper"
     *ngIf="isStatsPage">
    <div class="filter-source-dropdown"
        *ngIf="filterSource.enabled">
        <vpg-dropdown-next
            [options]="filterSource.options"
            [(ngModel)]="filterSource.selected"
            [optionLabel]="'text'"
            [id] = "'filter-source-dropdown'"
            [label]="'FilterBySource' | translate"
            (valueChange)="handleUpdatedFilterSource($event)">
        </vpg-dropdown-next>
    </div>
</div>
