import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { StatsModalComponent } from './stats-modal.component';
import { VpgrooveModule } from '@engineering/vpgroove-angular';
import { GenesisDirectivesModule } from '@genesis-frontend/genesis-utilities';


@NgModule({
    imports: [
        TranslateModule,
        CommonModule,
        AccordionModule.forRoot(),
        VpgrooveModule,
        FormsModule,
        A11yModule,
        GenesisDirectivesModule,
        TooltipModule
    ],
    declarations: [
        StatsModalComponent
    ],
    providers: [],
    exports: [
        StatsModalComponent
    ]
})
export class StatsModalModule { }
