export enum RewardType {
  AchieversPoints = 'AchieversPoints',
  ActivityIncentive = 'ActivityIncentive',
  AED = 'AED',
  AirMiles = 'AirMiles',
  Badges = 'Badges',
  CashIncentive = 'CashIncentive',
  Certificate = 'Certificate',
  CompletedTask = 'CompletedTask',
  Entries = 'Entries',
  GiftCard = 'GiftCard',
  HealthCash = 'HealthCash',
  HealthMiles = 'HealthMiles',
  HealthProgressPoints = 'HealthProgressPoints',
  HRA = 'HRA',
  HSA = 'HSA',
  HSAHRA = 'HSAHRA',
  LMHealthFund = 'LMHealthFund',
  MaritzCredits = 'MaritzCredits',
  MXN = 'MXN',
  NoReward = 'NoReward',
  OCTannerPoints = 'OCTannerPoints',
  PerQs = 'PerQs',
  PremiumCredit = 'PremiumCredit',
  PremiumDiscount = 'PremiumDiscount',
  PulseCash = 'PulseCash',
  Points = 'Points',
  RedTagPoints = 'RedTagPoints',
  TenXtraPoint = 'TenXtraPoint',
  TextOnly = 'TextOnly',
  WellnessPoints = 'WellnessPoints'
}
