import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class MemberCellPhoneNumberBlockerService {
    constructor(
        private httpClient: HttpClient
    ) { }

    shouldShowPhoneNumberBlocker(memberId: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`api/members/${ memberId }/phone-number-blocker`);
    }

    skipCellPhoneNumberBlocker(memberId: number): Observable<void> {
        return this.httpClient.put<void>(`api/members/${ memberId }/skip-phone-number-blocker`, {});
    }
}
