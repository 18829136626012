import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { PersonalChallengeSharedDataService } from './personal-challenge-shared-data.service';
import { AuthenticatedMemberService } from '../../authentication/authenticated-member.service';
import { LeaderboardType } from '../../models/enums/leaderboard-type.enum';
import { PersonalChallengeMemberStatus } from '../../models/enums/personal-challenge-member-status.enum';
import { ChallengeDashboard } from '../../models/interfaces/challenge-dashboard.model';
import { ChallengeInvite } from '../../models/interfaces/challenge-invite.model';
import { PersonalChallengeDates } from '../../models/interfaces/personal-challenge-dates.model';
import { PersonalChallengeLeaderboard } from '../../models/interfaces/personal-challenge-leaderboard.model';
import { PersonalChallengePillar } from '../../models/interfaces/personal-challenge-pillar.model';
import { PersonalChallengePlayerLeaderboardEntry } from '../../models/interfaces/personal-challenge-player-leaderboard-entry.model';
import { PersonalChallengePlayer } from '../../models/interfaces/personal-challenge-player.model';
import { PersonalChallengeTemplate } from '../../models/interfaces/personal-challenge-template.model';
import { PersonalChallengeTracker } from '../../models/interfaces/personal-challenge-tracker.model';
import { PersonalChallenge } from '../../models/interfaces/personal-challenge.model';
import { ValidateBulkInvite } from '../../models/interfaces/validate-bulk-invite.model';


@Injectable({
    providedIn: 'root'
})
export class PersonalChallengeService {
    STEP_TEMPLATES_RESOURCE = '/api/personal-challenge-templates/step-templates';

    constructor(
        private httpClient: HttpClient,
        private personalChallengeSharedDataService: PersonalChallengeSharedDataService,
        private authenticatedMemberService: AuthenticatedMemberService
    ) {}

    getPersonalStepChallenges(memberId: number) {
        return this.httpClient.get<ChallengeDashboard[]>(`/api/members/${memberId}/personal-challenges/dashboard`);
    }

    replyOnPersonalChallengeInvite(personalChallengeId: number, challengeMemberId: number, memberId: number, status: PersonalChallengeMemberStatus) {
        const challengeMember = {
            id: challengeMemberId,
            personalChallengeId,
            status: status,
            memberId
        };

        return this.httpClient.put(`/api/personal-challenges/${personalChallengeId}/players/${challengeMemberId}`,
            challengeMember);
    }

    getChallengeTemplates() {
        return this.httpClient.get<PersonalChallengeTemplate[]>(this.STEP_TEMPLATES_RESOURCE);
    }

    getPillarsAndTopicsForDropdown(memberId: number) {
        return this.httpClient.get<PersonalChallengePillar[]>(`/api/members/${memberId}/pillars/habits-summary`);
    }

    getMemberActiveTrackers(memberId: number) {
        return this.httpClient.get<PersonalChallengeTracker[]>(`/api/members/${memberId}/tracker-challenges/active-trackers`);
    }

    getTrackersByTopic(memberId: number, topicId: number) {
        return this.httpClient.get<PersonalChallengeTracker[]>(`/api/members/${memberId}/tracker-challenges/topic/${topicId}/trackers`);
    }

    getPersonalChallengeInvites(memberId: number) {
        return this.httpClient.get<ChallengeInvite>(`/api/members/${memberId}/personal-challenges/dashboard/invites`);
    }

    getPersonalChallengeDatesForTemplate(templateId: number) {
        return this.httpClient.get<PersonalChallengeDates>(`/api/personal-challenge-templates/${templateId}/dates`);
    }

    getPersonalChallengeDates(memberId: number, personalChallengeId?: number) {
        return this.httpClient.get<PersonalChallengeDates>(`/api/members/${memberId}/personal-challenges/${personalChallengeId}/template-dates`);
    }

    createPersonalChallenge(memberId: number, challenge: PersonalChallenge) {
        if (!challenge.templateId) {
            challenge.templateId = challenge.id;
        }

        let params = new HttpParams();
        params = params.append('validateProfanity', 'true');

        return this.httpClient.post<PersonalChallenge>(`/api/members/${memberId}/personal-challenges`, challenge, { params });
    }

    replayPersonalChallenge(memberId: number, challenge: PersonalChallenge, personalChallengeId?: number) {
        let params = new HttpParams();
        params = params.append('validateProfanity', 'true');

        return this.httpClient.put<PersonalChallenge>(`/api/members/${memberId}/personal-challenges/${personalChallengeId}/replay`,
            challenge, { params });
    }

    getPersonalChallengeInvitesForMember(memberId: number, includePlayers: boolean) {
        let params = new HttpParams();
        params = params.append('includePlayers', includePlayers);
        return this.httpClient.get<PersonalChallenge[]>(`/api/members/${memberId}/personal-challenges/invites`, { params });
    }

    editPersonalChallengeMember(personalChallengeMember: PersonalChallengePlayer) {
        return this.httpClient.put<PersonalChallengePlayer>(
            `/api/personal-challenges/${personalChallengeMember.personalChallengeId}/players/${personalChallengeMember.id}`,
            personalChallengeMember
        );
    }

    getPersonalChallenge(challengeId: number) {
        return this.httpClient.get<PersonalChallenge>(`/api/personal-challenges/${challengeId}`);
    }

    getPersonalChallengeLeaderboardPlayerEntries(challengeId: number, startIndex: number, pageSize: number, queryNear: boolean = false) {
        return this.getPersonalChallengeLeaderboards(challengeId).pipe(
            mergeMap((leaderboards) => leaderboards),
            mergeMap((leaderboard: PersonalChallengeLeaderboard) => {
                if (leaderboard.type === LeaderboardType.PersonalChallengePlayer) {
                    return this.getPersonalChallengeLeaderboard(challengeId, leaderboard.id, startIndex, pageSize, queryNear);
                }
                return of(null);
            }, (_leaderboard, entries) => entries)
        );
    }

    getPersonalChallengeLeaderboards(challengeId: number) {
        return this.httpClient.get<PersonalChallengeLeaderboard[]>(`/api/personal-challenges/${challengeId}/leaderboards`);
    }

    getPersonalChallengeLeaderboard(challengeId: number, leaderboardId: number, startIndex: number, pageSize: number, queryNear: boolean) {
        let params = new HttpParams();
        params = params.append('pageSize', pageSize);

        if (!queryNear) {
            params = params.append('startIndex', startIndex);
        }

        return this.httpClient.get<PersonalChallengePlayerLeaderboardEntry[]>(`/api/personal-challenges/${challengeId}/leaderboards/${leaderboardId}`, {
            observe: 'response',
            params
        }).pipe(map((response) => {
            const headers = response.headers;
            const data = response.body;
            this.personalChallengeSharedDataService.setLeaderboardTotalPlayers(Number(headers.get('content-items')));
            return {
                leaderboard: data,
                contentItems: headers.get('content-items'),
                currentPage: headers.get('current-page'),
                pageSize: headers.get('page-size'),
                totalPages: headers.get('total-pages')
            };
        }));
    }

    getPersonalChallengePlayers(challengeId: number, playerStatus?: PersonalChallengeMemberStatus, page?: number, pageSize?: number, sortByFirstName?: boolean): Observable<any> {
        const PAGE_SIZE = 25;
        const CURRENT_PAGE = 0;
        let params = new HttpParams();
        params = params.append('page', page || CURRENT_PAGE)
            .append('pageSize', pageSize || PAGE_SIZE);

        if (playerStatus) {
            params = params.append('status', playerStatus);
        }

        if (sortByFirstName) {
            params = params.append('sortByFirstName', sortByFirstName);
        }

        return this.httpClient.get<PersonalChallengePlayer[]>(`/api/personal-challenges/${challengeId}/players`, {
            observe: 'response',
            params
        }).pipe(map((response) => ({
            players: response.body,
            contentItems: response.headers.get('content-items'),
            currentPage: response.headers.get('current-page'),
            totalPages: response.headers.get('total-pages')
        })));
    }

    getPersonalChallengeFriends(challengeId: number, page: number, pageSize: number): Observable<any> {
        const memberId = this.authenticatedMemberService.getAuthenticatedMemberId();
        return this.httpClient.get(`/api/members/${memberId}/friends/personal-challenge`, {
            observe: 'response',
            params: {
                personalChallengeId: challengeId,
                page,
                pageSize
            }
        }).pipe(map((response) => ({
            players: response.body,
            contentItems: response.headers.get('content-items'),
            currentPage: response.headers.get('current-page'),
            totalPages: response.headers.get('total-pages')
        })));
    }

    createPersonalChallengePlayer(challengeId: number, memberId: number) {
        return this.httpClient.post<PersonalChallengePlayer>(`/api/personal-challenges/${challengeId}/players/${memberId}`, {});
    }

    personalChallengeInviteGroup(personalChallengeId: number, groupId: number) {
        return this.httpClient.post(`/api/personal-challenges/${personalChallengeId}/groups/${groupId}`, {});
    }

    validatePersonalChallengeBulkInvite(challengeId: number, emailArray: RegExpMatchArray): Observable<ValidateBulkInvite[]> {
        return this.httpClient.post<ValidateBulkInvite[]>(`/api/personal-challenges/${challengeId}/bulk-invite/validate`, {
            emails: emailArray
        });
    }

    personalChallengeBulkInvite(challengeId: number, emailArray: RegExpMatchArray) {
        return this.httpClient.post<PersonalChallengePlayer[]>(`/api/personal-challenges/${challengeId}/bulk-invite`, {
            emails: emailArray
        });
    }

    editPersonalChallenge(memberId: number, challenge: PersonalChallenge) {
        challenge.challengeId = challenge.id;
        return this.httpClient.put<PersonalChallenge>(`/api/members/${memberId}/personal-challenges/${challenge.id}`, challenge);
    }
}
