export enum FeatureEnum {
    Announcements = 'Announcements',
    Benefits = 'Benefits',
    BetaTesting = 'BetaTesting',
    Calendar = 'Calendar',
    CalendarEvents = 'CalendarEvents',
    CashRewards = 'CashRewards',
    ClassicTheme = 'ClassicTheme',
    Coaching = 'Coaching',
    CoachingPointRewards = 'CoachingPointRewards',
    ConditionManagement = 'ConditionManagement',
    ContributionsOfferingTypeEnabled = 'ContributionsOfferingTypeEnabled',
    CorporateChallenges = 'CorporateChallenges',
    CostTransparency = 'CostTransparency',
    DailyCards = 'DailyCards',
    DedicatedClaimsPage = 'DedicatedClaimsPage',
    DevicesAndApps = 'DevicesAndApps',
    DigitalTherapeutics = 'DigitalTherapeutics',
    DigitalWallet = 'DigitalWallet',
    DigitalWalletV2 = 'DigitalWalletV2',
    DisallowEmails = 'DisallowEmails',
    EnableChallengeChat = 'EnableChallengeChat',
    EnableChatSupport = 'EnableChatSupport',
    EnableIdeasLabTab = 'EnableIdeasLabTab',
    EnablePhotoUpload = 'EnablePhotoUpload',
    FindCareDoctor = 'FindCareDoctor',
    FindCareOnlineDoctor = 'FindCareOnlineDoctor',
    FindCareProcedure = 'FindCareProcedure',
    Friends = 'Friends',
    FriendsLeaderboard = 'FriendsLeaderboard',
    GenesisRewards = 'GenesisRewards',
    GiftCardsEnabled = 'GiftCardsEnabled',
    GoalSettings = 'GoalSettings',
    GroupsV2 = 'GroupsV2',
    Guides = 'Guides',
    HAC = 'HAC',
    HealthyHabitChallenges = 'HealthyHabitChallenges',
    HealthyHabitListing = 'HealthyHabitListing',
    HealthyHabitRecommendationList = 'HealthyHabitRecommendationList',
    HideChallenges = 'HideChallenges',
    HolisticChallenges = 'HolisticChallenge',
    InboundCoaching = 'InboundCoaching',
    Initiatives = 'Initiatives',
    Interests = 'Interests',
    InviteColleagues = 'InviteColleagues',
    Journeys = 'Journeys',
    JourneyRecommendations = 'JourneyRecommendations',
    LifestyleManagement = 'LifestyleManagement',
    LiveServicesBenefitsGuides = 'LiveServicesBenefitsGuides',
    LiveServicesCoachMessaging = 'LiveServicesCoachMessaging',
    LiveServicesCoachingPlans = 'CoachingPlans',
    LiveServicesGuidesMessaging = 'LiveServicesGuidesMessaging',
    LiveServicesHealthGuides = 'LiveServicesHealthGuides',
    LiveServicesMessagingRewards = 'LiveServicesMessagingRewards',
    MCCCoachingRecommendations = 'MCCCoachingRecommendations',
    MCCJourneyRecommendations = 'MCCJourneyRecommendations',
    MCCEditGapsInCare = 'MCCEditGapsInCare',
    MCCHP = 'MCCHP',
    MCC2025Rebrand = 'MCC2025Rebrand',
    MediaLibrary = 'MediaLibrary',
    MedicalPlan = 'MedicalPlan',
    MentalHealthCoaching = 'MentalHealthCoaching',
    MyCareChecklist = 'MyCareChecklist',
    MyFinances = 'MyFinances',
    NavigateExperience = 'NavigateExperience',
    NextBestNudge = 'NextBestNudge',
    NextStepsConsult = 'NextStepsConsult',
    NewsFlash = 'NewsFlash',
    NicotineFreeAgreement = 'NicotineFreeAgreement',
    NotificationPane = 'NotificationPane',
    MessageCenter = 'MessageCenter',
    OnboardingTutorial = 'OnboardingTutorial',
    OnPlatformRedemptionDetails = 'OnPlatformRedemptionDetails',
    OffPlatformRedemptionEnabled = 'OffPlatformRedemptionEnabled',
    OnsiteCoaching = 'Onsite',
    PatientSatisfaction = 'PatientSatisfaction',
    PAL = 'PAL',
    PersonalStepChallenges = 'PersonalStepChallenges',
    PillarAndTopicPages = 'PillarAndTopicPages',
    PointsRewards = 'PointsRewards',
    PopulationMessaging = 'PopulationMessaging',
    Pregnancy = 'Pregnancy',
    PersonalHealthAdvocates = 'PersonalHealthAdvocates',
    Recognition = 'Recognition',
    RequiredByPartner = 'RequiredByPartner',
    RewardsAndProgram = 'RewardsAndProgram',
    SpotlightRedesign2022 = 'SpotlightRedesign2022',
    Statistics = 'Statistics',
    StatsDashboardBeta = 'StatsDashboardBeta',
    StatsDashboard = 'StatsDashboard',
    StopFeaturedChallengeEmails = 'StopFeaturedChallengeEmails',
    Store = 'Store',
    SupportKnowledgebase = 'SupportKnowledgebase',
    Supporters = 'Supporters',
    SocialPage2021 = 'SocialPage2021',
    Tobacco = 'Tobacco',
    TobaccoDedicated = 'TobaccoDedicated',
    TotalPopulationHealth = 'TotalPopulationHealth',
    TransformRelease23 = 'TransformRelease23',
    TransformRelease23AdminPreviewProgramContent = 'TransformRelease23AdminPreviewProgramContent',
    QualityTransparency = 'QualityTransparency',
    Trophies = 'Trophies',
    Vouchers = 'Vouchers',
    VpIq = 'VPIQ',
    VpNavigate = 'VpNavigate',
    YDYGRewards = 'YDYGRewards',
    Zipongo = 'Zipongo',
    ResponsiveMCCExperience = 'ResponsiveMCCExperience',
    FindCare = 'FindCare',
    DevicesAndAppsV2 = 'DevicesAndAppsV2',
    SleepNutritionGuides2022 = 'SleepNutritionGuides2022',
    Surveys = 'Surveys',
    HideSensitiveDataCollection = 'HideSensitiveDataCollection',
    SupportBanner = 'SupportBanner'
}
