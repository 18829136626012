import { Component, Input, OnInit } from '@angular/core';

import { CoachingCardVM } from '../../models/coaching-card-vm.model';
import { CoachingService } from '../../services/coaching.service';
import { AnalyticsTrackingAction, AnalyticsTrackingSingleSpaService } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'genesis-frontend-empty-state',
    templateUrl: './empty-state.component.html',
    styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent implements OnInit {
   @Input() card!: CoachingCardVM;
   hrefPath = '';

   constructor(
      private coachingService: CoachingService,
      private readonly analyticsTrackingService: AnalyticsTrackingSingleSpaService
   ) {}
   ngOnInit(): void {
       this.hrefPath = this.coachingService.getHrefPath(this.card.state);
   }

   sendAnalytics(): void {
       this.analyticsTrackingService.sendData(AnalyticsTrackingAction.HomepageWidgetTable, this.card.analyticsTrackingData);
   }
}
