import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ClientAgreementModalComponent } from './client-agreement-modal.component';

@Injectable({
    providedIn: 'root'
})
export class ClientAgreementModalService {

    constructor(private bsModalService: BsModalService) {}

    openModal(agreement: any) {
        this.bsModalService.show(ClientAgreementModalComponent, {
            class: 'agreement-modal',
            ariaLabelledBy: 'agreement-modal-header',
            backdrop: true,
            keyboard: false,
            animated: false,
            initialState: {
                agreement
            }
        });
    }
}
