import { Injectable, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';

import { MemberFeatureToggle } from '../models/interfaces/member-feature-toggle.model';
import { GlobalReduxStore } from '../state-management';

@Injectable({
    providedIn: 'root'
})
export class FeatureToggleService implements OnDestroy {
    private memberFeatureToggles: MemberFeatureToggle[] = [];
    private componentSub = new Subscription();
    public serviceLoaded = new BehaviorSubject(false);

    constructor(private globalReduxStore: GlobalReduxStore) {
        const initialState = this.globalReduxStore.getInitialState();
        this.memberFeatureToggles = initialState.memberFeatureToggle;

        if (this.memberFeatureToggles.length) {
            this.serviceLoaded.next(true);
        } else {
            this.componentSub.add(
                this.globalReduxStore
                    .createSelector('memberFeatureToggle')
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .subscribe((memberFeatureToggle: any) => {
                        this.memberFeatureToggles = memberFeatureToggle;
                        this.serviceLoaded.next(true);
                    })
            );
        }
    }

    getMemberFeatureToggles(): MemberFeatureToggle[] {
        return this.memberFeatureToggles;
    }

    hasFeatureToggle(featureToggle: string): boolean {
        const matchedFeature = this.memberFeatureToggles.filter((memberFeatureToggle) => memberFeatureToggle.key === featureToggle);
        return matchedFeature.length > 0;
    }

    hasAsyncAppointmentScheduleFeatureToggle(): boolean {
        return this.hasFeatureToggle('live-services-schedule-appointment-async');
    }

    hasCoachingHubNewFeatureToggle(): boolean {
        return this.hasFeatureToggle('live-services-coaching-hub-new');
    }

    hasRedirectToNewStatisticsEndpoint(): boolean {
        return this.hasFeatureToggle('redirect-to-new-statistics-endpoint');
    }

    hasMultimodalFeatureToggle(): boolean {
        return this.hasFeatureToggle('live-services-truly-multimodal');
    }

    ngOnDestroy() {
        this.componentSub.unsubscribe();
    }
}
