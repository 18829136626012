import { Component, Input, OnInit } from '@angular/core';

@Component({
    templateUrl: './loader.component.html',
    selector: 'loader'
})
export class LoaderComponent implements OnInit {
    @Input() scale?: number;
    @Input() messageTranslationKey: string | undefined;
    scale3d?: string;
    messageKey = '';
    DEFAULT_KEY = 'Loading';

    ngOnInit() {
        this.scale3d = `scale3d(${this.scale},${this.scale},1)`;
        this.messageKey = this.messageTranslationKey ? this.messageTranslationKey : this.DEFAULT_KEY;
    }
}
