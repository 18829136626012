import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { EventBus, Event } from '@genesis-frontend/genesis-utilities';

@Injectable({ providedIn: 'root' })
export class HolisticChallengesNotificationsService {
    rivalsSubscriptions: Subscription[] = [];
    teamTotalTokensSubscriptions: Subscription[] = [];

    constructor(private eventBus: EventBus) {}

    addRivalNotification(notification: string) {
        this.eventBus.dispatch('updateRivalsData', notification);
    }

    addTeamTotalTokensNotification(notification: string) {
        this.eventBus.dispatch('updateTeamTotalTokensData', notification);
    }

    subscribeOnRivalChange(handler: (notification: string) => void) {
        const subscription = this.eventBus.on('updateRivalsData', (notificationEvent: Event) => {
            handler(notificationEvent.data);
        });

        this.rivalsSubscriptions.push(subscription);

        return subscription;
    }

    subscribeOnTeamTotalTokensChange(handler: (notification: string) => void) {
        const subscription = this.eventBus.on('updateTeamTotalTokensData', (notificationEvent: Event) => {
            handler(notificationEvent.data);
        });

        this.rivalsSubscriptions.push(subscription);

        return subscription;
    }

    ngOnDestroy(): void {
        this.rivalsSubscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });

        this.teamTotalTokensSubscriptions.forEach((subscription) => {
            subscription.unsubscribe();
        });
    }
}
