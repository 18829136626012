import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticatedTimeoutService } from './authenticated-timeout.service';
import { KeycloakService } from '../../authentication/keycloak.service';
import { Agent } from '../interfaces';

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedAgentService {

    private userIsAgent = false;
    private authenticatedAgent: Agent | undefined;
    Authenticated = false;
    userId: number | undefined;

    constructor(
        private keycloak: KeycloakService,
        private httpClient: HttpClient,
        private authenticatedTimeoutService: AuthenticatedTimeoutService
    ) {
    }

    isUserAgent() {
        this.checkClientAndRole();
        return this.userIsAgent;
    }

    checkClientAndRole() {
        const checkRoleAndClient = this.keycloak.getClient() && this.keycloak.getClient().hasRealmRole('agent');
        if (checkRoleAndClient) {
            this.userIsAgent = true;
        }
    }

    ready(): Observable<Agent> {
        if (this.authenticatedAgent) {
            return new Observable<Agent>((subscriber) => {
                subscriber.next(this.authenticatedAgent);
                subscriber.complete();
            });
        }

        return this.getAuthenticatedAgent();
    }

    logout() {
        this.Authenticated = false;
        this.authenticatedAgent = undefined;
        window.location.href = '/logout.html';
    }

    agentBootstrappingCall() {
        this.getAuthenticatedAgent().subscribe((agent: Agent) => {
            this.authenticatedAgent = agent;
            this.Authenticated = true;
            this.userId = this.authenticatedAgent.id;
            // this.$rootScope.$broadcast('userLoggedIn');
            this.authenticatedTimeoutService.registerLogoutCallback(this.logout());
        }, (error) => {
            // eslint-disable-next-line no-console
            console.error(error);
        });
    }

    getAuthenticatedAgent(): Observable<Agent> {
        return this.httpClient.get<Agent>('/api/agents/authenticated');
        // return from<Agent[]>(this.$resource('/api/agents/authenticated').get().$promise);
    }
}
