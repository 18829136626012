import { Injectable } from '@angular/core';

import { FormatHtmlContentService } from './format-html-content.service';
import { DateUtils, DateValue } from '../lib/date-utils';

declare const System: any;

@Injectable({
    providedIn: 'root'
})
export class IcalConverterService {
    constructor(
        private formatHtmlContentService: FormatHtmlContentService
    ) { }

    async convertCalendarEventToIcal(calendarEvent: { startDateTime: DateValue; endDateTime: DateValue; title: any; description: string; allDayEvent: any; location: any; }): Promise<string> {

        const ical: any = await System.import('ical');

        const calendar = new ical.Component(['vcalendar', [], []]);
        const vevent = new ical.Component('vevent');
        const event = new ical.Event(vevent);
        const tzProperty = new ical.Property(['tzid', { tzid: ical.Timezone.utcTimezone }, 'text', ical.Timezone.utcTimezone]);
        const startDateTime = DateUtils.toUtcDate(calendarEvent.startDateTime);
        const endDateTime = DateUtils.toUtcDate(calendarEvent.endDateTime);

        event.summary = calendarEvent.title;
        const formattedDescription = this.formatHtmlContentService.separateLinks(calendarEvent.description);
        event.description = this.formatHtmlContentService.removeHtml(formattedDescription.innerHTML);

        if (calendarEvent.allDayEvent) {
            event.startDate = ical.Time.fromDateString(DateUtils.format(startDateTime, 'YYYY-MM-DD'));
            event.endDate = ical.Time.fromDateString(DateUtils.format(
                DateUtils.add(endDateTime, 1, 'days'),
                'YYYY-MM-DD'));
        } else {
            event.startDate = ical.Time.fromDateTimeString(startDateTime.toISOString(), tzProperty);
            event.endDate = ical.Time.fromDateTimeString(endDateTime.toISOString(), tzProperty);
        }

        if (calendarEvent.location) {
            event.location = calendarEvent.location;
        }

        calendar.addSubcomponent(vevent);

        return calendar.toString();
    }
}
