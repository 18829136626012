import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { KeycloakService } from '../authentication';
import { environment } from '../config/environments/environment';
import { DateUtils } from '../lib/date-utils';
import { HealthFormDataModel, MemberProfileModel, PcpFormData } from '../models';


const VP_STAGE_URL = 'https://survey.stage.virginpulse.com/';
const VP_PRODUCTION_URL = 'https://survey.virginpulse.com/';
const SUBDOMAIN = 'survey';

@Injectable({
    providedIn: 'root'
})
export class HealthFormSubmissionsDataService {

    TIMEOUT = 500;

    sponsorHasV2Rewards: any = null;
    currentProgramV2: any = null;
    currentProgramV1: any = null;

    formData: PcpFormData = {
        formUrl: '',
        isV2: true,
        startProgramYear: '',
        endProgramYear: '',
        bearerV2: null
    };

    healthFormData!: HealthFormDataModel;

    constructor(
        private http: HttpClient,
        private keycloakService: KeycloakService
    ) {}

    checkIfSponsorHasV2Rewards(memberId: number) {
        return this.http.get(`/api/members/${ memberId }/features/has-genesis-rewards`);
    }

    getCurrentProgramV1(sponsorId: number) {
        return this.http.get(`/api/sponsors/${ sponsorId }/rewards-programs/current`);
    }

    getCurrentProgramV2(sponsorId: number, memberId: number) {
        return this.http.get(`/api/genesis-rewards/sponsors/${ sponsorId }/members/${ memberId }/programs`);
    }

    getFormUrl(formName: string) {
        return environment.env.env !== 'production' ? VP_STAGE_URL + formName : VP_PRODUCTION_URL + formName;
    }

    getHealthFormData(memberId: number): Observable<HealthFormDataModel> {
        return this.http.get<HealthFormDataModel>(`/api/members/${ memberId }/health-form-submissions`, {
            params: {
                subdomain: SUBDOMAIN
            }
        });
    }

    getRewardPopulation(): Observable<MemberProfileModel> {
        return this.http.get<MemberProfileModel>('/api/members/profile');
    }

    getFormData(memberData: any, formName: string): Observable<PcpFormData> {

        return new Observable((observer) => {
            this.checkIfSponsorHasV2Rewards(memberData.id).subscribe((resolve: any) => {
                this.sponsorHasV2Rewards = resolve;

                this.formData.formUrl = this.getFormUrl(formName);
                this.formData.isV2 = this.sponsorHasV2Rewards.value;

                if (this.sponsorHasV2Rewards.value) {
                    this.formData.bearerV2 = this.getBearerV2();
                    this.getCurrentProgramV2(memberData.sponsorId, memberData.id).subscribe((data) => {
                        this.currentProgramV2 = data;

                        if (this.currentProgramV2) {
                            this.formData.startProgramYear = this.currentProgramV2.startDate;
                            this.formData.endProgramYear = this.currentProgramV2.endDate;
                        }

                        observer.next(this.formData);
                        observer.complete();
                    });
                } else {
                    this.getCurrentProgramV1(memberData.sponsorId).subscribe((data) => {
                        this.currentProgramV1 = data;

                        if (this.currentProgramV1) {
                            const startYear = DateUtils.format(this.currentProgramV1.startDate, 'YYYY-MM-DD');
                            const endYear = DateUtils.format(this.currentProgramV1.endDate, 'YYYY-MM-DD');

                            this.formData.startProgramYear = startYear;
                            this.formData.endProgramYear = endYear;
                        }

                        observer.next(this.formData);
                        observer.complete();
                    });
                }
            });
        });
    }

    getHealthFormSubmissionData(member: any): Observable<HealthFormDataModel> {
        return new Observable((observer) => {
            this.getHealthFormData(member.id).subscribe((resolve) => {
                this.healthFormData = resolve;

                this.getRewardPopulation().subscribe((rewardPopulation: any) => {
                    this.healthFormData.rewardPopulation = rewardPopulation.rewardsSegment;
                    this.healthFormData.rewardPopulationId = rewardPopulation.rewardPopulationId;

                    observer.next(this.healthFormData);
                    observer.complete();
                });
            });
        });
    }

    getBearerV2() {
        const keycloakClient = this.keycloakService.getClient();
        if (keycloakClient && keycloakClient.token) {
            return keycloakClient.token;
        }
        return null;
    }
}
