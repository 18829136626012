import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'characters' })
export class CharactersPipe implements PipeTransform {

    transform(input: string, chars: number, breakOnWord: boolean): string {
        if (isNaN(chars)) {
            return input;
        }

        if (chars <= 0) {
            return '';
        }

        if (input && input.length > chars) {
            return this.addEllipsis(input.substring(0, chars), breakOnWord);
        }

        return input;
    }

    addEllipsis(input: string, breakOnWord: boolean): string {
        let result = input;

        if (!breakOnWord) {
            const lastSpacePos = input.lastIndexOf(' ');
            if (lastSpacePos !== -1) {
                result = input.substring(0, lastSpacePos);
            }
        }

        return result.trim() + '...';
    }
}
