import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilestackService } from '@filestack/angular';
import { saveAs } from 'file-saver';
import { ClientOptions, PickerFileMetadata, PickerOptions, Security } from 'filestack-js';
import { PickerStoreOptions, PickerTransformationOptions } from 'filestack-js/build/main/lib/picker';
import { of } from 'rxjs';

import { ConfigurationService } from './configuration.service';
import { FeatureEnum } from '../models';
import { UserService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class SecuredFilePickerService {

    readonly constants = {
        NUM_OF_SECONDS: 30,
        NUM_OF_MS_IN_SECOND: 1000,
        LANGUAGE_CODE_STRING_LENGTH: 2
    };

    private uploadSecurityData = { policy: '', signature: '' };
    readSecurityData = { policy: '', signature: '' };
    private readExpiry = 0;
    private feature: FeatureEnum | undefined = undefined;
    private member: any;

    constructor(
        private fileStackService: FilestackService,
        private configurationService: ConfigurationService,
        private userService: UserService,
        private httpClient: HttpClient
    ) {
        this.userService.authenticate().subscribe(() => {
            this.member = this.userService.getCurrentUser();
        });
    }

    initFilePicker(feature?: FeatureEnum) {
        this.feature = feature;
        this.getImageSecurityData(feature).subscribe((security) => {
            this.initUploadSecurityData(security);
        });
    }

    initReadSecurityData(readSecurityData: Security) {
        this.readSecurityData = readSecurityData;
        this.readExpiry = this.parseJsonPolicy(readSecurityData);
    }

    getDefaultPickerOptions(
        language?: string,
        componentName?: FeatureEnum,
        acceptTypes: string[] = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp'],
        maxSize?: number,
        fromSources: string[] = ['local_file_system', 'url']
    ): PickerOptions {
        let lang = 'en';
        if (language) {
            lang = language.substring(0, this.constants.LANGUAGE_CODE_STRING_LENGTH);
        }

        const storageOptions: PickerStoreOptions = {
            location: 's3',
            access: 'private',
            region: 'us-east-1',
            path: componentName ? this.getBaseFileStorePath(componentName) : undefined,
            container: this.configurationService.getSecuredFilePickerContainer()
        };
        return {
            accept: acceptTypes,
            customSourcePath: this.getBaseFileStorePath(),
            lang: lang,
            storeTo: storageOptions,
            fromSources: fromSources,
            maxSize: maxSize,
            cleanupImageExif: {
                keepOrientation: true
            }
        };
    }

    getGuideFileUploadPickerOptions(language?: string, componentName?: FeatureEnum): PickerOptions {
        let lang = 'en';
        if (language) {
            lang = language.substring(0, this.constants.LANGUAGE_CODE_STRING_LENGTH);
        }

        const storageOptions: PickerStoreOptions = {
            location: 's3',
            access: 'private',
            region: 'us-east-1',
            path: componentName ? this.getBaseFileStorePath(componentName) : undefined,
            container: this.configurationService.getSecuredFilePickerContainer()
        };
        return {
            accept: ['.pdf', 'image/jpeg', 'image/png'],
            maxFiles: 20,
            maxSize: 10485760,
            customSourcePath: this.getBaseFileStorePath(),
            lang: lang,
            storeTo: storageOptions,
            fromSources: ['local_file_system'],
            cleanupImageExif: {
                keepOrientation: true
            }
        };
    }

    //methodToCallBackTo is optional, and will pass the array of url's to the function given.
    upload(options: PickerOptions, feature: FeatureEnum, methodToCallBackTo?: any) {
        this.feature = feature;
        this.getImageSecurityData(feature).subscribe((security) => {
            this.initUploadSecurityData(security);
            options.onUploadDone = (res) => {
                this.checkReadSecurityExpiryPromise().then((readSecurityData) => {
                    this.initReadSecurityData(readSecurityData);
                    const urls: PickerFileMetadata[] = [];
                    res.filesUploaded.forEach((metadata) => {
                        urls.push(SecuredFilePickerService.validateImageUrl(metadata));
                    });
                    res.filesFailed.forEach((metadata) => {
                        if (metadata.handle != null) {
                            this.delete(metadata.handle);
                        }
                    });
                    methodToCallBackTo(urls);
                });
            };
            options.storeTo = {
                workflows: [this.configurationService.getSecuredFilePickerVirusDetectWorkflowId()]
            };
            this.fileStackService.picker(options).open().then();
        });
    }

    download(urls: string[], fileNames: string[]): Promise<string[]> {
        return this.checkReadSecurityExpiryPromise().then((readSecurityData) => {
            const client = this.getFilestackDownloadClient(readSecurityData);
            return new Promise((resolve) => {
                const failedDownloads: string[] = [];
                urls.forEach((url: string, index: number) => {
                    client.download(
                        SecuredFilePickerService.parseFileHandleFromUrl(url),
                        readSecurityData
                    ).then((response: any) => {
                        saveAs(response.data, fileNames[index]);
                        if (urls.length <= index + 1) {
                            resolve(failedDownloads);
                        }
                    }).catch(() => {
                        failedDownloads.push(url);
                    });
                });
            });
        });
    }

    getImageSecurityData(feature?: FeatureEnum, handle?: string) {
        let params = new HttpParams();
        if (feature) {
            params = params.set('feature', feature);
        }
        if (handle) {
            params = params.set('handle', handle);
        }
        return this.httpClient.get<Security>(`/api/sponsors/${this.member.sponsorId}/members/${this.member.id}/filestack-security`, {
            params
        });
    }

    delete(handle: string) {
        this.getImageSecurityData(this.feature, handle).subscribe((deleteSecurityData) => {
            this.fileStackService.remove(handle, deleteSecurityData);
        });
    }

    getUploadSecurityData() {
        return `?policy=${this.uploadSecurityData.policy}&signature=${this.uploadSecurityData.signature}`;
    }

    getTransformationOptions(): PickerTransformationOptions {
        return {
            crop: true,
            rotate: true,
            force: true
        };
    }

    checkReadSecurityExpiry() {
        if ((this.readExpiry <= Date.now())) {
            this.getImageSecurityData().subscribe((securityPolicy) => {
                this.initReadSecurityData(securityPolicy);
            });
        }
    }

    checkReadSecurityExpiryPromise() {
        if ((this.readExpiry <= Date.now())) {
            return this.getImageSecurityData().toPromise();
        }
        return of(this.readSecurityData).toPromise();
    }

    static parseFileHandleFromUrl(url: string): string {
        return url.substring(url.lastIndexOf('/') + 1, url.includes('?') ? url.lastIndexOf('?') : url.length);
    }


    private initUploadSecurityData(uploadSecurityData: Security) {
        this.uploadSecurityData = uploadSecurityData;
        this.fileStackService.init(
            this.configurationService.getSecuredFilePickerAPI(),
            SecuredFilePickerService.getClientOptions(uploadSecurityData)
        );
    }

    private getFilestackDownloadClient(readSecurityData: Security) {
        this.initReadSecurityData(readSecurityData);
        return this.fileStackService.init(
            this.configurationService.getSecuredFilePickerAPI(),
            SecuredFilePickerService.getClientOptions(readSecurityData)
        );
    }

    // Taking 30 seconds less that the policy expiry is, so we avoid last second check: 15m - 30s = 14m and 30s expiry
    private parseJsonPolicy(securityData: Security) {

        let expiry = 0;
        try {
            expiry = JSON.parse(atob(securityData.policy)).expiry;
        } catch {
            expiry = 0;
        }

        return (expiry - this.constants.NUM_OF_SECONDS) * this.constants.NUM_OF_MS_IN_SECOND;
    }

    private getBaseFileStorePath(componentName?: FeatureEnum) {
        let path = `${this.configurationService.getS3()}/${this.member.sponsorId}/${this.member.id}/`;
        if (componentName) {
            path += componentName.toLocaleLowerCase() + '/';
        }
        return path;
    }

    private static getClientOptions(security: Security): ClientOptions {
        return { cname: undefined, forwardErrors: false, security: security, sessionCache: false };
    }

    private static validateImageUrl(image: PickerFileMetadata) {
        const pattern = 'cdn.filestackcontent.com';
        const url = 'file.personifyhealth.com/api/file';
        image.url = image.url.replace(pattern, url);
        return image;
    }
}
