import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';

import { ClientAgreementModalComponent } from './client-agreement-modal.component';
import { ClientAgreementModalService } from './client-agreement-modal.service';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ModalModule.forRoot()
    ],
    exports: [],
    declarations: [
        ClientAgreementModalComponent
    ],
    providers: [
        ClientAgreementModalService
    ]
})
export class ClientAgreementModalModule {
}
