import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators';

import { NumberUtils } from '../../helpers';
import { MediaDetail, MediaTopic, MemberMedia } from '../../models';

@Injectable({ providedIn: 'root' })
export class MediaService {
    constructor(
        private httpClient: HttpClient
    ) {
    }

    getMediaList(memberId: number, pageSize: number, page: number, sortBy: string, sortDirection: string, mediaTopicId?: number): any {
        const headers = new HttpHeaders();
        let params = new HttpParams({
            fromObject: {
                page: String(page),
                pageSize: String(pageSize),
                sortBy,
                sortDir: sortDirection
            }
        });
        if (mediaTopicId) {
            params = params.append('contentTopicId', mediaTopicId.toString());
        }

        return this.httpClient.get<any>(`/content-interaction-api/members/${memberId}/media`, {
            headers,
            observe: 'response',
            params
        }).pipe(map((responseWithHeaders) => {
            return {
                $httpHeaders: responseWithHeaders.headers,
                count: NumberUtils.parseInt(responseWithHeaders.headers.get('content-items') || ''),
                totalPages: responseWithHeaders.headers.get('total-pages'),
                data: responseWithHeaders.body
            };
        }));
    }

    getMediaDetail(mediaId: number, memberId: number): Observable<MediaDetail> {
        return this.httpClient.get<MediaDetail>(`/content-interaction-api/members/${memberId}/media/${mediaId}`);
    }

    getMediaTopics(page: number, pageSize: number): Observable<any> {
        const headers = new HttpHeaders();
        const params = new HttpParams({
            fromObject: {
                page: String(page),
                pageSize: String(pageSize)
            }
        });
        return this.httpClient.get<MediaTopic[]>('/content-interaction-api/media/content-topics', {
            headers,
            observe: 'response',
            params
        }).pipe(map((responseWithHeaders) => {
            return {
                $httpHeaders: responseWithHeaders.headers,
                count: NumberUtils.parseInt(responseWithHeaders.headers.get('content-items') || ''),
                totalPages: responseWithHeaders.headers.get('total-pages'),
                data: responseWithHeaders.body
            };
        }));
    }

    updateMemberMedia(memberId: number, mediaDetail: MemberMedia): Observable<MemberMedia> {
        return this.httpClient.put<MemberMedia>(`/content-interaction-api/members/${memberId}/media/${mediaDetail.mediaId}`, mediaDetail);
    }

    convertMillisecondsIntoMinutes(duration: number): string {
        const seconds = Math.floor((duration / 1000) % 60);
        const minutes = Math.floor((duration / (1000 * 60)) % 60);

        const minuteString = (minutes < 10) ? '0' + minutes.toString() : minutes.toString();
        const secondsString = (seconds < 10) ? '0' + seconds.toString() : seconds.toString();

        return minuteString + ':' + secondsString;
    }
}
