import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'lowercaseWithHyphens' })
export class LowercaseWithHyphensPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return '';
        }
        return value.replace(/\s+/g, '-').toLowerCase();
    }
}
