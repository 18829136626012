import { Injectable } from '@angular/core';

import { PalModalComponent } from './pal-modal.component';
import { DialogRef, DialogService } from '@engineering/vpgroove-angular';

@Injectable({
    providedIn: 'root'
})
export class PalModalService {
    constructor(
        private dialogService: DialogService
    ) { }

    openModal(): DialogRef {
        return this.dialogService.open(PalModalComponent);
    }
}
