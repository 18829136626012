import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { ApolloError } from '@apollo/client';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

import { ClaimsState } from '../models/claim-state.model';
import { InsuranceClaimVM } from '../models/insurance-claim-vm.model';
import { InsuranceClaim } from '../models/insurance-claim.model';
import { UIState } from '../models/ui-state.model';
import { InsuranceClaimsGQL, InsuranceClaimsQuery } from '@genesis-frontend/graphql';

@Injectable()
export class ClaimsCardService {
    constructor(
        private readonly insuranceClaimsQuery: InsuranceClaimsGQL,
        private datePipe: DatePipe
    ) {}

    fetch(): Observable<UIState<InsuranceClaimVM>> {
        return this.insuranceClaimsQuery.fetch().pipe(
            map((response) => ({
                loading: false,
                data: this.mapData(response.data),
                error: response.errors
            })),
            catchError((error: ApolloError) =>
                of({
                    loading: false,
                    error: error
                })
            ),
            startWith({
                loading: true
            })
        );
    }

    private mapData(response: InsuranceClaimsQuery): InsuranceClaimVM {
        const insuranceClaims = response.insuranceClaims?.edges;

        if (!insuranceClaims) {
            return {} as InsuranceClaimVM;
        }

        return {
            memberHasNoClaims: insuranceClaims?.length === 0,
            claimsState: this.setClaimsState(insuranceClaims?.length),
            insuranceClaims: insuranceClaims
                .map((edge) => {
                    const claim = edge?.node;
                    if (!claim) {
                        return null;
                    }
                    return {
                        id: claim.id,
                        claimNumber: claim.claimNumber,
                        serviceStartDate: this.transformAndSetClaimsDate(claim.serviceStartDate),
                        totalChargedAmount: claim.totalChargedAmount ? this.convertToCurrencyFormat(claim.totalChargedAmount) : 0,
                        patientResponsibilityAmount: claim.patientResponsibilityAmount ? this.convertToCurrencyFormat(claim.patientResponsibilityAmount) : 0,
                        patientName: `${claim.patientFirstName} ${claim.patientLastName}`,
                        providerName: claim.provider?.name || ''
                    };
                })
                .filter((claim) => claim !== null) as InsuranceClaim[]
        };
    }

    private transformAndSetClaimsDate(date: string) {
        return this.datePipe.transform(new Date(date), 'MM/dd/yy');
    }

    private convertToCurrencyFormat(number: number) {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(number);
    }

    private setClaimsState(claimsLength: number) {
        switch (claimsLength) {
        case 1:
            return ClaimsState.oneClaim;
        case 2:
            return ClaimsState.twoClaims;
        default:
            return ClaimsState.noClaims;
        }
    }
}
