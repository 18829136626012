import { Pipe, PipeTransform } from '@angular/core';

import { DropdownItem } from '../models/interfaces/dropdown-item.model';

@Pipe({ name: 'transformLanguageCode' })
export class TransformLanguageCodePipe implements PipeTransform {

    transform(language: string, languages: DropdownItem[]): string {
        let response = '';
        if (languages) {
            languages.forEach((lang) => {
                if (lang.id === language) {
                    response = lang.name;
                }
            });
        }
        return response ? response : language;
    }
}
