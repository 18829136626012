import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { VideoDataTypesEnum } from './video-data-types.enum';

@Component({
    selector: 'benefits-video-modal',
    templateUrl: './video-modal.component.html',
    styleUrls: ['./video-modal.component.scss']
})
export class VideoModalComponent implements OnInit {
    @Input() videoId = '';
    @Input() type = '';

    video_iframe_html = '';

    videoDataTypesEnum = VideoDataTypesEnum;

    constructor(
        private bsModalRef: BsModalRef,
        private sanitizer: DomSanitizer
    ) {}

    ngOnInit() {
        this.embedVideo();
    }

    closeModal() {
        this.bsModalRef.hide();
    }

    private embedVideo() {
        if (this.type === this.videoDataTypesEnum.YT) {
            this.video_iframe_html = this.sanitizeIframe(this.youtubeIframe(this.videoId));
        } else {
            this.video_iframe_html = this.sanitizeIframe(this.vimeoIframe(this.videoId));
        }
    }

    private sanitizeIframe(iframe: string): any {
        return this.sanitizer.bypassSecurityTrustHtml(iframe);
    }

    youtubeIframe(id: string) {
        return '<iframe src="https://www.youtube.com/embed/' + id + '" frameborder="0" allowfullscreen></iframe>';
    }

    vimeoIframe(id: string) {
        return '<iframe src="https://player.vimeo.com/video/' +
            id + '" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>';
    }
}
