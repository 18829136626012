import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    JourneySurvey,
    MemberJourneySurveyResponse
} from '@genesis-frontend/genesis-utilities';


@Injectable({
    providedIn: 'root'
})
export class JourneySurveyService {

    BASE_JOURNEY_SURVEYS_URI = '/journeys-api/surveys';

    constructor(private httpClient: HttpClient) { }

    getJourneySurvey(): Observable<JourneySurvey> {
        return this.httpClient.get<JourneySurvey>(this.BASE_JOURNEY_SURVEYS_URI);
    }

    saveMemberJourneySurveyAnswers(surveyResponse: MemberJourneySurveyResponse): Observable<MemberJourneySurveyResponse> {
        return this.httpClient.post<MemberJourneySurveyResponse>(this.BASE_JOURNEY_SURVEYS_URI, surveyResponse);
    }
}
