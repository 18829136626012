import { Inject } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { from, Observable, of } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';

import { getResourceUrl } from './resource-url';
import { TRANSLATIONS_ADMIN } from './tokens';
import { PersistentCache } from '../core';

export class TranslateLoaderService implements TranslateLoader {

    private sessionCache = new PersistentCache<JSON>('@app:translation-cache', 86400);

    constructor(
        @Inject(TRANSLATIONS_ADMIN) private isAdmin: boolean
    ) { }

    getTranslation(lang: string): Observable<JSON> {

        const path = this.isAdmin ? 'i18n/admin' : 'i18n';
        const key = this.isAdmin ? `admin:${lang}` : lang;

        const item = this.sessionCache.getItem(key);
        if (item && item.value && Object.keys(item.value).length > 0) {
            return of(item.value);
        }

        return from(getResourceUrl()).pipe(
            mergeMap((url) => fetch(`${url}/${path}/${lang}.json`).then((response) => response.json() as Promise<JSON>)),
            tap({
                next: (value) => this.sessionCache.setItem(key, value)
            }),
            catchError(() => of({} as JSON))
        );
    }
}
