import { KeyValue } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'keyValueTransformCustom' })
export class KeyValueTransformCustomPipe implements PipeTransform {
    transform<V>(
        input: [string, V][],
        compareFn?: (a: KeyValue<string, V>, b: Record<string, V>) => number
    ): KeyValue<string, V>[] {
        const keyValues: KeyValue<string, V>[] = [];
        input.forEach(([keyInput, valueInput]) => keyValues.push({ key: keyInput, value: valueInput }));
        if (!compareFn) {
            return keyValues;
        }
        // @ts-ignore
        return keyValues.sort(compareFn);
    }
}
