import { Component, Injector, Input, OnInit } from '@angular/core';

@Component({
    selector: 'base-card-carousel',
    template: ''
})
export class BaseCardCarouselComponent implements OnInit {
    @Input() cards!: any[];
    @Input() data: any;
    currentCard = 1;
    dataInjector!: Injector;

    FADED_AWAY = 2;
    FRONT_CARD = 1;
    ONE_AWAY = 0;
    TWO_AWAY = -1;
    THREE_AWAY = -2;
    QUEUED_CARD = -3;

    constructor(public injector: Injector) {}

    ngOnInit(): void {
        this.dataInjector = Injector.create({ providers: [{ provide: 'data', useValue: this.data }], parent: this.injector });
    }

    setState(index: number): string {
        const cardPlacement = this.currentCard - index;

        if (cardPlacement >= this.FADED_AWAY) {
            return 'stack-card-away';
        }

        switch (cardPlacement) {
        case this.FRONT_CARD:
            return 'stack-card-1';
        case this.ONE_AWAY:
            return 'stack-card-2';
        case this.TWO_AWAY:
            return 'stack-card-3';
        case this.THREE_AWAY:
            return 'stack-card-4';
        default:
            return 'stack-card-queued';
        }
    }

    isShown(index: number): boolean {
        return this.currentCard - index === 1;
    }

    prevCard(): void {
        if (this.currentCard > 1) {
            this.currentCard -= 1;
        } else {
            this.currentCard = this.cards.length;
        }
    }

    nextCard(): void {
        if (this.currentCard >= this.cards.length) {
            this.currentCard = 1;
        } else {
            this.currentCard += 1;
        }
    }

    goToCard(index: number): void {
        if (index && index <= this.cards.length) {
            this.currentCard = index;
        }
    }
}
