export enum ActionType {
    ActivateFinancialProgram = 'ActivateFinancialProgram',
    ActiveMinutes = 'ActiveMinutes',
    AddRecipeToGroceryList = 'AddRecipeToGroceryList',
    AddFinancialAccount = 'AddFinancialAccount',
    AreYouCurrent = 'AreYouCurrent',
    AreYouOrganized = 'AreYouOrganized',
    AreYouPresent = 'AreYouPresent',
    AvoidLookingAtPhone = 'AvoidLookingAtPhone',
    AvoidSweets = 'AvoidSweets',
    BeAppreciative = 'BeAppreciative',
    BeKind = 'BeKind',
    BioMetrics = 'BioMetrics',
    BringLunch = 'BringLunch',
    BrowseHealthyRecipes = 'BrowseHealthyRecipes',
    CaloriesBurned = 'CaloriesBurned',
    CaloriesConsumed = 'CaloriesConsumed',
    Card = 'Card',
    ChatMegaChallenge = 'ChatMegaChallenge',
    CheckDevices = 'CheckDevices',
    CheckedFinancialBudget = 'CheckedFinancialBudget',
    CheckOutHowToEarn = 'CheckOutHowToEarn',
    ChooseYourEatingType = 'ChooseYourEatingType',
    ChooseYourSleepProfile = 'ChooseYourSleepProfile',
    CoacheeEarned = 'CoacheeEarned',
    Coaching = 'Coaching',
    ColleagueInvited = 'ColleagueInvited',
    CompletedChecklist = 'CompletedChecklist',
    CompletedEnrollementForm = 'CompletedEnrollementForm',
    CompletedFinalSleepAssessment = 'CompletedFinalSleepAssessment',
    CompletedFinancialQuiz = 'CompletedFinancialQuiz',
    CompletedFinancialSurvey = 'CompletedFinancialSurvey',
    CompletedHRAForm = 'CompletedHRAForm',
    CompletedMindfulnessProgram = 'CompletedMindfulnessProgram',
    CompletedLeadershipSession = 'CompletedLeadershipSession',
    CompletedMeditationSession = 'CompletedMeditationSession',
    CompletedYogaSession = 'CompletedYogaSession',
    CompletedSleepAssessment = 'CompletedSleepAssessment',
    CompletedSleepDiary = 'CompletedSleepDiary',
    CompletedSleepImprovementSession = 'CompletedSleepImprovementSession',
    CompletedSleepioFourthSession = 'CompletedSleepioFourthSession',
    CompletedSleepioSixthSession = 'CompletedSleepioSixthSession',
    ConnectCalorieTracker = 'ConnectCalorieTracker',
    ConnectDevice = 'ConnectDevice',
    ConsumeWater = 'ConsumeWater',
    ContestScoringSteps = 'ContestScoringSteps',
    CreatePersonalChallenge = 'CreatePersonalChallenge',
    CurrencyRewardAdded = 'CurrencyRewardAdded',
    CustomAction = 'CustomAction',
    CustomSurvey = 'CustomSurvey',
    DeepBreathPractice = 'DeepBreathPractice',
    DestinationUnlock = 'DestinationUnlock',
    DeviceTracked = 'DeviceTracked',
    DurationActivity = 'DurationActivity',
    EmailDistraction = 'EmailDistraction',
    ExpandHorizons = 'ExpandHorizons',
    FavoriteRecipe = 'FavoriteRecipe',
    FinishOnboarding = 'FinishOnboarding',
    FirstLoginToMobileApp = 'FirstLoginToMobileApp',
    FriendsAdded = 'FriendsAdded',
    GoalChallengeProgress = 'GoalChallengeProgress',
    GoToBed = 'GoToBed',
    LifestyleSurvey = 'LifestyleSurvey',
    HealthCheckSurvey = 'HealthCheckSurvey',
    HeartAgeSurvey = 'HeartAgeSurvey',
    Interests = 'Interests',
    JoinCompanyChallenge = 'JoinCompanyChallenge',
    JoinedPersonalChallenge = 'JoinedPersonalChallenge',
    JoinTeam = 'JoinTeam',
    JourneyAct = 'JourneyAct',
    JourneyActBloodPressure = 'JourneyActBloodPressure',
    JourneyActCholesterol = 'JourneyActCholesterol',
    JourneyActDiabetes = 'JourneyActDiabetes',
    JourneyActEatingHealthy = 'JourneyActEatingHealthy',
    JourneyActGettingActive = 'JourneyActGettingActive',
    JourneyScene = 'JourneyScene',
    JourneyStage = 'JourneyStage',
    LevelReached = 'LevelReached',
    LinkFinancialAccount = 'LinkFinancialAccount',
    LiveAndBreatheCompanyValues = 'LiveAndBreatheCompanyValues',
    LogIn = 'LogIn',
    ManuallyEnteredDurationActivity = 'ManuallyEnteredDurationActivity',
    MegaChallengeFullTeamRecruited = 'MegaChallengeFullTeamRecruited',
    MindfulMinute = 'MindfulMinute',
    MindfulnessSessionCompletion = 'MindfulnessSessionCompletion',
    MinutesOfStretching = 'MinutesOfStretching',
    Mood = 'Mood',
    NicotineFreeAgreement = 'NicotineFreeAgreement',
    NSCAppointment = 'NSCAppointment',
    OnBudget = 'OnBudget',
    PinchPennies = 'PinchPennies',
    PreSleep = 'PreSleep',
    ProfilePhoto = 'ProfilePhoto',
    PromotedHealthyHabitChallengeScore = 'PromotedHealthyHabitChallengeScore',
    ReadyToGo = 'ReadyToGo',
    RecycleToday = 'RecycleToday',
    Registration = 'Registration',
    SafetyFirst = 'SafetyFirst',
    SelfEnteredDuration = 'SelfEnteredDuration',
    SetPriorities = 'SetPriorities',
    SetWellbeingGoal = 'SetWellbeingGoal',
    ShareKnowledge = 'ShareKnowledge',
    Sleep = 'Sleep',
    SponsorCreated = 'SponsorCreated',
    Stairs = 'Stairs',
    StateOfZen = 'StateOfZen',
    Steps = 'Steps',
    SupporterAdded = 'SupporterAdded',
    TrackedHeartRate = 'TrackedHeartRate',
    TrainTheBrain = 'TrainTheBrain',
    UpdatedFinancialSpending = 'UpdatedFinancialSpending',
    UndistractedDriving = 'UndistractedDriving',
    UpdatedFinancialBudget = 'UpdatedFinancialBudget',
    VegOut = 'VegOut',
    VolunteerToday = 'VolunteerToday',
    Weight = 'Weight',
    Yoga = 'Yoga'
}
