import { Pipe, PipeTransform } from '@angular/core';

import { FilePickerService } from '../services';

@Pipe({ name: 'compressFilePickerImage' })
export class CompressImagePipe implements PipeTransform {

    constructor(private filePickerService: FilePickerService) {
    }

    transform(value: string, resize: number): string {
        this.filePickerService.checkReadSecurityExpiry();
        return this.filePickerService.compressImageLoad(value, resize);
    }
}
