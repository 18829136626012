const details = {
    Completed: {
        title: 'LastSession',
        titleId: 'completed-session-title',
        content: 'StartToEnd',
        contentId: 'completed-session-content',
        buttonLabel: 'ScheduleSession',
        buttonType: 'primary',
        buttonId: 'completed-checkmark',
        hasButtonAriaLabel: true,
        icon: 'far fa-calendar-alt',
        Coaching_clicks: 'Schedule A Session',
        Coaching_state: 'No upcoming appointment, no message, last session complete',
        imageSource: ''
    },
    Missed: {
        title: 'MissedSession',
        titleId: 'missed-session-title',
        content: 'StartToEnd',
        contentId: 'missed-session-content',
        buttonType: 'primary',
        buttonLabel: 'ScheduleSession',
        buttonId: 'schedule-session-btn',
        hasButtonAriaLabel: true,
        icon: 'far fa-calendar-times',
        Coaching_clicks: 'Schedule A Session',
        Coaching_state: 'Last session missed, no new messages, no upcoming appointment',
        imageSource: ''
    },
    Processing: {
        title:'StatusPending',
        titleId: 'processing-session-title',
        content: 'StartToEnd',
        contentId: 'processing-session-content',
        buttonType: 'secondary',
        buttonLabel: 'GoToCoaching',
        buttonId: 'go-to-coaching-btn',
        hasButtonAriaLabel: false,
        icon: 'far fa-calendar-alt',
        Coaching_clicks: 'Go To Coaching',
        Coaching_state: 'Last appointment waiting for verification, no message, no upcoming session',
        imageSource: ''
    },
    Scheduled: {
        title: 'NextSession',
        titleId: 'next-session-title',
        content: 'StartToEnd',
        contentId: 'next-session-content',
        buttonLabel: 'GoToCoaching',
        buttonType: 'secondary',
        buttonId: 'go-to-coaching-btn',
        hasButtonAriaLabel: false,
        icon: 'far fa-calendar-alt',
        Coaching_clicks: 'Go To Coaching',
        Coaching_state: 'Upcoming appointment, no messages',
        imageSource: ''
    },
    Rescheduled: {
        title: 'NextSession',
        titleId: 'next-session-title',
        content: 'StartToEnd',
        contentId: 'next-session-content',
        buttonLabel: 'ScheduleSession',
        buttonType: 'secondary',
        buttonId: 'schedule-session-btn',
        hasButtonAriaLabel: true,
        icon: 'far fa-calendar-alt',
        Coaching_clicks: 'Schedule A Session',
        Coaching_state: 'Upcoming appointment, no messages',
        imageSource: ''
    },
    InProgress: {
        title: 'NextSession',
        titleId: 'next-session-title',
        content: 'StartToEnd',
        contentId: 'next-session-content',
        buttonLabel: 'ScheduleSession',
        buttonType: 'secondary',
        buttonId: 'schedule-session-btn',
        hasButtonAriaLabel: true,
        icon: 'far fa-calendar-alt',
        Coaching_clicks: 'Schedule A Session',
        Coaching_state: 'Upcoming appointment, no messages',
        imageSource: ''
    },
    CannotComplete: {
        title: 'NextSession',
        titleId: 'next-session-title',
        content: 'StartToEnd',
        contentId: 'next-session-content',
        buttonLabel: 'ScheduleSession',
        buttonType: 'secondary',
        buttonId: 'schedule-session-btn',
        hasButtonAriaLabel: true,
        icon: 'far fa-calendar-alt',
        Coaching_clicks: 'Schedule A Session',
        Coaching_state: '',
        imageSource: ''
    },
    Canceled: {
        title: 'KeepConversationGoing',
        titleId: 'canceled-session-title',
        content: 'ScheduleCoachingSession',
        contentId: 'canceled-session-content',
        buttonLabel: 'ScheduleSession',
        buttonType: 'primary',
        buttonId: 'schedule-session-btn',
        hasButtonAriaLabel: true,
        icon: 'far fa-calendar-alt',
        Coaching_clicks: 'Schedule A Session',
        Coaching_state: 'Appointment cancelled, no new messages, no upcoming appointment',
        imageSource: 'img/coaching/coaching-cancelled.svg'
    }
};

export {
    details
};
