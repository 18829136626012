import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { StatsCardsComponentService } from './stats-cards.component.service';
import {
    AuthenticatedMemberService,
    StatsModel,
    StatsTrackerEnums,
    StatsTrackerID,
    StatsTrackerModel,
    DateUtils
} from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'stats-cards',
    templateUrl: './stats-cards.component.html',
    styleUrls: ['./stats-cards.component.scss']
})
export class StatsCardsComponent implements OnInit {
    trackerModel: StatsTrackerModel[];
    statsData: StatsModel[] = [];
    allStatsURL = '/#/stats-page';

    constructor(
        private statsCardsComponentService: StatsCardsComponentService,
        private translateService: TranslateService,
        private authenticatedMemberService: AuthenticatedMemberService
    ) {
        this.trackerModel = [];
    }

    ngOnInit(): void {
        const authenticatedMember = this.authenticatedMemberService.getAuthenticatedMember();
        this.statsCardsComponentService.getStatisticTrackersForMember().subscribe((trackerModel) => {
            const filteredTrackerIDs = this.getStatsTrackerIds(trackerModel);
            from(filteredTrackerIDs)
                .pipe(mergeMap((filteredTrackerID) => {
                    return this.statsCardsComponentService.getStatisticsByTracker(
                        authenticatedMember.id,
                        filteredTrackerID.id,
                        DateUtils.toISOString().split('T')[0],
                        DateUtils.toISOString().split('T')[0]);
                })).subscribe((statsData) => {
                    this.statsData.push(
                        this.calcPercentage(
                            this.formatActivityData(
                                this.xFormApiResponseToStatsData(statsData)
                            )));
                });
        });
    }

    private xFormApiResponseToStatsData(stats: StatsTrackerModel): StatsModel {
        const statsData: StatsModel = {
            activityName: '',
            activityCount: 0,
            formattedActivityCount: '',
            activityGoal: 0,
            activityGoalCompletedPercentage: 0,
            formattedActivityGoalMsg: '',
            deviceName: '',
            iconPath: '',
            displayOrder: 1
        };

        statsData.activityName = stats.title;
        if (stats.title === StatsTrackerEnums.Steps) {
            statsData.activityName = 'Steps';
            statsData.activityCount = !stats.statistics.length ? 0 : stats.statistics[0].steps;
        }
        if (stats.title === StatsTrackerEnums.Sleep) {
            statsData.activityName = 'Sleep';
            statsData.activityCount = !stats.statistics.length ? 0 : stats.statistics[0].durationInSeconds;
        }
        if (stats.title === StatsTrackerEnums.ActiveMinutes) {
            statsData.activityName = 'Active Minutes';
            statsData.activityCount = !stats.statistics.length ? 0 : stats.statistics[0].activeMinutes;
        }
        statsData.formattedActivityCount = '';
        statsData.activityGoal = 0;
        statsData.activityGoalCompletedPercentage = 0;
        statsData.formattedActivityGoalMsg = '';
        statsData.deviceName = !stats.statistics.length ? '' : stats.statistics[0].deviceName;
        statsData.iconPath = stats.backgroundImage;
        statsData.displayOrder = 1;

        return statsData;
    }

    formatActivityData(statsItem: StatsModel) {
        let sleepHours = 0;
        let sleepMinutes = 0;
        let formattedActivityCount = '';
        let activityGoalHours = 0;
        let activityGoalMinutes = 0;

        if (statsItem.activityName === 'Steps') {
            if (!statsItem.formattedActivityGoalMsg.length) {
                this.translateService.instant('StepsDailyGoal', { stepGoal: statsItem.activityCount.toLocaleString() });
            }
        } else if (statsItem.activityName === 'Active Minutes') {
            if (!statsItem.formattedActivityGoalMsg.length) {
                this.translateService.instant('GoalDailyMinutes', { dailyMinutes: statsItem.activityCount.toLocaleString() });
            }
        }

        switch (statsItem.activityName) {
        case 'Steps':
        case 'Active Minutes':

            if (!statsItem.formattedActivityCount.length) {
                statsItem.formattedActivityCount = statsItem.activityCount.toLocaleString();
            }
            break;

        case 'Sleep':
            sleepHours = Math.floor(statsItem.activityCount / 3600);
            sleepMinutes = Math.round((statsItem.activityCount % 3600) / 60);
            formattedActivityCount = sleepHours + this.translateService.instant('HoursAbbreviation') + ' ' + sleepMinutes + this.translateService.instant('MinutesAbbreviation');
            if (!statsItem.formattedActivityCount.length) {
                statsItem.formattedActivityCount = formattedActivityCount;
            }

            activityGoalHours = Math.floor(statsItem.activityGoal / 3600);
            activityGoalMinutes = Math.round((statsItem.activityGoal % 3600) / 60);
            if (!statsItem.formattedActivityGoalMsg.length) {
                statsItem.formattedActivityGoalMsg = this.translateService.instant('DailyGoalHoursSleep', { numberOfHours: activityGoalHours, numberOfMinutes: activityGoalMinutes });
            }
            break;
        default:
            break;
        }
        return statsItem;
    }

    calcPercentage(statsItem: StatsModel) {
        statsItem.activityGoalCompletedPercentage = 100 * (statsItem.activityCount / statsItem.activityGoal);
        return statsItem;
    }

    private getStatsTrackerIds(trackerModel: StatsTrackerModel[]): StatsTrackerID[] {
        return trackerModel.filter((item) => {
            return item.title === StatsTrackerEnums.Steps || item.title === StatsTrackerEnums.Sleep || item.title === StatsTrackerEnums.ActiveMinutes;
        }).map((item) => {
            return { type: item.title, id: item.id };
        });
    }
}
