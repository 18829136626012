import { ContestStageData } from './contest-stage-data.model';
import { ContestTeam } from './contest-team.model';

export interface TeamStats {
    team?: ContestTeam;
    teamRank: number;
    teamScore: number;
    contestWithStages: boolean;
    nextStage?: ContestStageData;
}
