import { Component, Input } from '@angular/core';

import { GoalsVM } from '../../models/goals-vm.model';
import { RewardableActionType } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'genesis-frontend-goals',
    templateUrl: './goals.component.html',
    styleUrls: ['./goals.component.scss']
})
export class GoalsComponent {
    @Input() goal!: GoalsVM;
    @Input() content = '';
    @Input() screenreaderText?: string;

    readonly rewardEventCode = 'CG1';
    readonly rewardableActionType = RewardableActionType.CompleteACoachingGoal;
}
