import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { JourneyRetirementLabelComponent } from './journey-retirement-label.component';
import { JourneysRetirementService } from '@genesis-frontend/genesis-utilities';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [
        JourneyRetirementLabelComponent
    ],
    exports: [
        JourneyRetirementLabelComponent
    ],
    providers: [
        JourneysRetirementService
    ]
})
export class JourneyRetirementLabelModule {}
