<div class="global-challenge-join-a-team-container">
    <div class="card-body-wrapper-join-team">
        <div class="card-title text-clr-oxford-blue">
            <span
                class="challenge-title"
                [translate]="'JoinATeam'">
                Join a team
            </span>
        </div>
        <div
            class="card-description text-clr-oxford-blue"
            [translate]="'VpGoSelectTeamToCompete'">
            Select a team to compete in the challenge.
        </div>
        <div
            class="vp-padding-top-0"
            *ngIf="teamInvites.length && allAvailableTeams.length">
            <ul class="tabs-list">
                <li
                    aria-controls="find-by-name-panel"
                    role="tab"
                    class="vp-trim-info"
                    id="find-by-name-tab"
                    [ngClass]="{ 'active': selectedTab === TabTypeEnum.TeamInvites}"
                    (click)="selectTab(TabTypeEnum.TeamInvites)"
                    [translate]="'ChallengeJoinInvitesLabel'">
                    Team Invites
                </li>
                <li
                    aria-controls="invite-friends-panel"
                    role="tab"
                    class="vp-trim-info"
                    id="invite-friends-tab"
                    [ngClass]="{ 'active': selectedTab === TabTypeEnum.OpenTeams}"
                    (click)="selectTab(TabTypeEnum.OpenTeams)"
                    [translate]="'VpGoOpenTeams'">
                    Open Teams
                </li>
            </ul>
        </div>
        <hr class="border-style" />
    </div>
    <div
        class="card-input"
        *ngIf="selectedTab === TabTypeEnum.OpenTeams">
        <div
            class="input-field border-clr-bg background-clr-transparent"
            aria-hidden="false"
            tabindex="0">
            <input
                id="teamToSearch"
                class="background-clr-transparent"
                type="search"
                aria-hidden="true"
                tabindex="-1"
                maxlength="35"
                [placeholder]="'FindAFriendOrTeam' | translate"
                [(ngModel)]="searchText"
                (input)="searchTeams()" />
            <i
                class="far fa-times-circle text-clr-gray-chateau"
                *ngIf="searchText.length && isDataLoaded"
                (click)="clearSearch()"></i>
        </div>
        <div class="search-results">
            <p
                class="text-lowercase"
                *ngIf="isDataLoaded && numberOfResults && numberOfResults >= 0 && searchText.length > MIN_NUM_OF_LETTERS"
                [innerHTML]="'ResultsFound' | translate: {numberOfResults: numberOfResults || 0, searchParam: searchText}">
                0 Results for
                <b>searchText</b>
            </p>
            <p
                *ngIf="!searchText || searchText.length <= MIN_NUM_OF_LETTERS"
                [translate]="'PleaseEnterAtLeast3Characters'">
                Please enter at least 3 characters
            </p>
        </div>
    </div>
    <div class="card-middle-join-team">
        <div
            class="suggested-teams"
            [ngClass]="{'team-invites-height': selectedTab === TabTypeEnum.TeamInvites, 'open-teams-height': !teamInvites.length}"
            infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="150"
            [infiniteScrollDisabled]="shouldDisableInfiniteScroll"
            [infiniteScrollContainer]="'.suggested-teams'"
            [fromRoot]="true"
            (scrolled)="loadMoreTeams()"
            role="tabpanel"
            id="suggested-teams">
            <div class="team-loading-spinner">
                <i
                    class="fa fa-spinner fa-spin fa-3x"
                    *ngIf="!isDataLoaded"
                    aria-hidden="true"></i>
            </div>
            <team-card
                *ngIf="selectedTab === TabTypeEnum.TeamInvites"
                [maxTeamPlayers]="data.contest.maxPlayersAllowed"
                [teams]="teamInvites"
                [goToTeamDetails]="data.viewTeamDetails"
                [isFeelingLuckyOption]="false"></team-card>

            <team-card
                *ngIf="selectedTab === TabTypeEnum.OpenTeams"
                [maxTeamPlayers]="data.contest.maxPlayersAllowed"
                [teams]="allAvailableTeams"
                [goToTeamDetails]="data.viewTeamDetails"
                [isFeelingLuckyOption]="selectedTab === TabTypeEnum.OpenTeams && allAvailableTeams.length >= MIN_NUM_OF_TEAMS_FOR_FEELING_LUCKY_OPTION && !isSearching"></team-card>
            <div
                class="no-search-results text-clr-silver-sand"
                *ngIf="isDataLoaded && numberOfResults === 0 && searchText.length > MIN_NUM_OF_LETTERS">
                <img src="../img/vp-go/no-results.png" />
                <p [translate]="'TryAnotherSearch'">Try another search!</p>
            </div>
        </div>
    </div>
    <div class="card-footer-join-team">
        <hr class="border-clr-gainsboro" />
        <p
            #createYourOwnLinkParent
            innerHTML="{{ 'WantToCreateYourOwnTeam' | translate }}">
            Want to
            <a class="vp-text-link">create your own</a>
            team?
        </p>
    </div>
    <div
        class="next-card-btn background-clr-bg background-dark text-clr-white"
        *ngIf="showRightArrow"
        (click)="goToNextCard()"
        role="button"
        tabindex="0"
        [attr.aria-label]="'next' | translate">
        <i class="fa fa-angle-right"></i>
    </div>
</div>
