import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { TooManyHabitsModalComponent } from './too-many-habits-modal.component';
import { TooManyHabitsModalService } from './too-many-habits-modal.service';
import { VpgrooveModule } from '@engineering/vpgroove-angular';


@NgModule({
    imports: [
        VpgrooveModule,
        FormsModule,
        CommonModule,
        TranslateModule
    ],
    declarations: [
        TooManyHabitsModalComponent
    ],
    providers: [TooManyHabitsModalService],
    exports: [
        TooManyHabitsModalComponent
    ]
})
export class TooManyHabitsModalModule { }
