export enum StatType {
    Workout = 'Workout',
    ActiveMinutes = 'ActiveMinutes',
    MindfulMinutes = 'MindfulMinutes',
    Steps = 'Steps',
    Weight = 'Weight',
    BodyTemp = 'BodyTemp',
    BloodPressure = 'BloodPressure',
    Glucose = 'Glucose',
    A1C = 'A1C',
    WaistHipSize = 'WaistHipSize',
    Cholesterol = 'Cholesterol',
    CaloriesConsumed = 'CaloriesConsumed',
    CaloriesBurned = 'CaloriesBurned',
    Sleep = 'Sleep',
    Triglyceride = 'Triglyceride'
}
