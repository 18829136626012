import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ProfilePhoto } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class ProfilePhotosService {
    constructor(
        private httpClient: HttpClient
    ) { }

    getProfilePhotos(): Observable<ProfilePhoto[]> {
        return this.httpClient.get<ProfilePhoto[]>('/api/profile-photos');
    }
}
