<div>
    <div class="modal-header">
        <div class="close-btn">
            <button #personalChallengeCreateModalClose
                    class="close"
                    (click)="closeModal()"
                    [attr.aria-label]="'Close' | translate">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div [ngSwitch]="steps[step]">
            <span *ngSwitchCase="STEPS.Two">
                    <span aria-live="polite">
                        <h1 id="personalize-challenge-header"
                            [translate]="'PersonalizeYourChallenge'"
                            class="sr-only">
                        </h1>
                        <div class="personal-challenge-header personal-challenge-modal-title text-clr-tertiary">
                            <input type="text"
                                   autocapitalize="on"
                                   class="personal-challenge-name"
                                   [(ngModel)]="challengeData.stepChallengeData.name"
                                   required
                                   [maxLength]="MAX_TITLE_LENGTH"
                                   pattern="\S.*"
                                   [attr.aria-label]="'AddChallengeName' | translate:{ numChars : MAX_TITLE_LENGTH }"/>
                        </div>
                        <div class="name-character-count"
                             [ngClass]="{ 'warning': challengeData.stepChallengeData.name.length >= WARNING_TITLE_LENGTH }"
                             aria-hidden="true">
                            <span [translate]="'Limit'"></span>: {{ MAX_TITLE_LENGTH - challengeData.stepChallengeData.name.length }}
                        </div>
                        <div class="sr-only"
                             *ngIf="challengeData.stepChallengeData.name.length < MAX_TITLE_LENGTH"
                             [translate]="'RemainingChars'"
                             [translateParams]="{ numChars : (MAX_TITLE_LENGTH - challengeData.stepChallengeData.name.length)}">
                        </div>
                        <div class="sr-only"
                             *ngIf="challengeData.stepChallengeData.name.length >= MAX_TITLE_LENGTH"
                             [translate]="'NoRemainingChars'">
                        </div>
                    </span>
            </span>
            <span *ngSwitchCase="STEPS.Three">
                <h1 class="personal-challenge-modal-title">
                    <strong>
                        {{ challengeData.stepChallengeData.name }}
                    </strong>
                    <span [translate]="'ChallengeRules'"></span>
                </h1>
            </span>
        </div>
    </div>
    <div class="modal-body"
         [ngStyle]="{ 'background-image': MODAL_BACKGROUND | assetAsUrl }">
        <div class="create-challenge-wizard slide-frame"
             [ngSwitch]="steps[step]">
            <div *ngSwitchCase="STEPS.Two"
                 class="page">
                <genesis-features-personal-challenge-confirm
                    [challengeData]="challengeData.stepChallengeData"
                    [displaySingle]="isSingle"
                    (setStep)="setCurrentStep($event)"
                    (completionCallback)="closeModal()">
                </genesis-features-personal-challenge-confirm>
            </div>
            <div *ngSwitchCase="STEPS.Three"
                 class="page">
                <genesis-features-personal-challenge-rules-template
                    (setStep)="setCurrentStep($event)"
                    [challengeTemplate]="challengeData.stepChallengeData.template || challengeData.stepChallengeData">
                </genesis-features-personal-challenge-rules-template>
            </div>
        </div>
    </div>
</div>
