export enum MixpanelTrackingConstants {
    NotApplicable = 'not applicable',
    ViewEvent = 'view event',
    Yes = 'yes',
    SetInterests = 'set interests',
    CheckItOut = 'check it out',
    GoalChallenge = 'Goal Challenge',
    StartNow = 'start now',
    FinishUp = 'finish up',
    GotIt = 'got it',
    Available = 'Available',
    RecognitionBoard = 'Recognition board',
    GiveAShoutout = 'give a shoutout',
    TakeMeThere = 'take me there',
    Checklist = 'checklist',
    NoOldTab = 'no old tab',
    Like = 'like',
    CalendarEvents = 'calendar events',
    AddToCalendar = 'add to calendar',
    NeverAgain = 'never again',
    No = 'no',
    RedeemAVoucher = 'redeem a voucher',
    LearnHowToEarn = 'learn how to earn more points',
    Earn = 'earn',
    ShopTheStore = 'shop the store',
    Spend = 'spend',
    GetAGiftCard = 'get a gift card',
    DonateIt = 'donate',
    ProgressBar = 'progress bar',
    MyRewards = 'my rewards',
    Disconnected = 'disconnected',
    Connected = 'connected',
    SuggestedFriends = 'suggested friends',
    IntroPage = 'intro page',
    ResultsPage = 'results page',
    Search = 'search',
    Homepage = 'homepage',
    HabitsPage = 'habits page',
    TrackOnHealthyHabitsPage = 'track on healthy habits page',
    Cards = 'cards',
    HealthyHabits = 'healthy habits',
    WaysToEarn = 'ways to earn',
    Steps = 'steps',
    Challenges = 'challenges',
    ChallengesWidget = 'challenges widget',
    FriendsLeaderboard = 'friends leaderboard',
    FriendProfile = 'friend profile',
    Reply = 'reply',
    Flag = 'flag',
    NextStepConsult = 'next steps consult',
    GuidesHomepage = 'guides homepage'
}
