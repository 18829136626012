import { Pipe, PipeTransform } from '@angular/core';

import { assetUrl } from './asset-url';

@Pipe({ name: 'assetAsUrl' })
export class AssetAsUrlPipe implements PipeTransform {
    transform(value: string): string {
        const absoluteUrl = assetUrl(value);
        return `url(${absoluteUrl})`;
    }
}
