export enum LiveServicesEngagementStatus {
    Engaged = 'Engaged',
    UnableToReach = 'UnableToReach',
    NotEngaged = 'NotEngaged',
    NotEngagedNoLongerInterested = 'NotEngagedNoLongerInterested',
    NotEngagedMadeProgress = 'NotEngagedMadeProgress',
    NotEngagedGoalMet = 'NotEngagedGoalMet',
    NotEngagedNoLongerEligible = 'NotEngagedNoLongerEligible',
    PreEngaged = 'PreEngaged'
}
