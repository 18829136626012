import { Component, Input } from '@angular/core';

import {
    AnalyticsTrackingAction,
    AnalyticsTrackingSingleSpaService,
    MixpanelTrackingConstants,
    CardSecondaryInteractionModel,
    CalendarEvent,
    CalendarService
} from '@genesis-frontend/genesis-utilities';
@Component({
    selector: 'calendar-event-export',
    templateUrl: './calendar-event-export.component.html'
})
export class CalendarEventExportComponent {
    @Input() calendarEvent!: CalendarEvent;
    @Input() styleClass = '';
    @Input() boardView = false;

    readonly ADD_TO_CALENDAR = 'AddToCalendar';

    readonly CalendarType = {
        Google: 'Google',
        iCal: 'iCal',
        Outlook: 'Outlook'
    };

    readonly exportOptions = [
        {
            label: 'Outlook',
            imageSource: '/img/Icon_Outlook.png'
        },
        {
            label: 'iCal',
            imageSource: '/img/Icon_iCal.png'
        },
        {
            label: 'Google',
            imageSource: '/img/Icon_GoogleCal.png'
        }
    ];

    mixpanelTrackingConstants = MixpanelTrackingConstants;

    constructor(
        private analyticsTrackingService: AnalyticsTrackingSingleSpaService,
        private calendarService: CalendarService
    ) { }

    exportEvent(calendarType: string): void {
        if (calendarType === this.CalendarType.Google) {
            window.open(this.calendarService.convertEventToGoogleCalendarUrl(this.calendarEvent));
        } else {
            this.calendarService.exportEvent(this.calendarEvent);
        }

        this.trackCardSecondaryInteraction();
    }

    trackCardSecondaryInteraction(): void {
        const trackingData: CardSecondaryInteractionModel = {
            card_id: this.calendarEvent.id,
            card_global_english: this.calendarEvent.title,
            card_type: this.mixpanelTrackingConstants.CalendarEvents,
            card_topic: this.mixpanelTrackingConstants.NotApplicable,
            card_secondary_interaction: this.mixpanelTrackingConstants.AddToCalendar
        };
        this.analyticsTrackingService.sendData(AnalyticsTrackingAction.CardSecondaryInteraction, trackingData);
    }
}
