import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CommonData } from './common-data';
import { StaticDataService } from '../graphql/services/static-data.service';

@Injectable({ providedIn: 'root' })
export class CommonDataService {

    private staticData = inject(StaticDataService);
    private commonData = inject(CommonData);

    getCommonData(): Observable<CommonData> {
        /**
         * Parameter withNonBinary should be set from sponsor settings
         * once sponsor settings data are available trough graph ql
         */
        const withNonBinary = false;

        return new Observable((subscribe) => {
            this.staticData.getStaticData(withNonBinary).subscribe((result) => {
                this.commonData.countries = result.countries;
                this.commonData.genders = result.genders;
                this.commonData.langauges = result.languages;
                this.commonData.timezones = result.timezones;
                this.commonData.unitOfMeasurements = result.unitOfMeasurements;

                subscribe.next(this.commonData);
                subscribe.complete();
            });
        });
    }
}
