export const ADD_CONFIGURATION_FOR_CLIENT = 'ADD_CONFIGURATION_FOR_CLIENT';
export const AddConfigurationForClient = (configuration: any) => {
    return {
        type: ADD_CONFIGURATION_FOR_CLIENT,
        payload: { configuration }
    };
};

export const genesisClientAdminStateActions = [
    ADD_CONFIGURATION_FOR_CLIENT
];

export default {
    AddConfigurationForClient
};
