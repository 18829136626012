export enum EntityType {
    Contest = 'Contest',
    Distributor = 'Distributor',
    Member = 'Member',
    MemberDisability = 'MemberDisability',
    MemberPillarTopic = 'MemberPillarTopic',
    MemberSettings = 'MemberSettings',
    Pillar = 'Pillar',
    PillarConfiguration = 'PillarConfiguration',
    PillarConfigurationDefaultTopic = 'PillarConfigurationDefaultTopic',
    PillarTopic = 'PillarTopic',
    PillarTopicPersonalChallengeTemplate = 'PillarTopicPersonalChallengeTemplate',
    PillarTopicProgramPage = 'PillarTopicProgramPage',
    PillarTopicTag = 'PillarTopicTag',
    PillarTopicThriveCategory = 'PillarTopicThriveCategory',
    PillarTopicTracker = 'PillarTopicTracker',
    PillarTopicWidget = 'PillarTopicWidget',
    ProgramPageAttachment = 'ProgramPageAttachment',
    Segment = 'Segment',
    SegmentChecklist = 'SegmentChecklist',
    SegmentCriteria = 'SegmentCriteria',
    SegmentCriteriaGroup = 'SegmentCriteriaGroup',
    SegmentDevicePromo = 'SegmentDevicePromo',
    SegmentFeature = 'SegmentFeature',
    SegmentFeatureBundle = 'SegmentFeatureBundle',
    SegmentGuide = 'SegmentGuide',
    SegmentHRAProvider = 'SegmentHRAProvider',
    SegmentOnboardingBoard = 'SegmentOnboardingBoard',
    SegmentPillarType = 'SegmentPillarType',
    SegmentProgram = 'SegmentProgram',
    SegmentRedemptionBrand = 'SegmentRedemptionBrand',
    SegmentPartnerSubscription = 'SegmentPartnerSubscription',
    SegmentScheduledPromotion = 'SegmentScheduledPromotion',
    SegmentSyncApplication = 'SegmentSyncApplication',
    SegmentTag = 'SegmentTag',
    Sponsor = 'Sponsor',
    SponsorPillarSettings = 'SponsorPillarSettings',
    SponsorSecuritySettings = 'SponsorSecuritySettings',
    SponsorSettings = 'SponsorSettings',
    SponsorEmailSettings = 'SponsorEmailSettings',
    Theme = 'Theme',
    ThemeSetting = 'ThemeSetting',
    ThemeTemplateSetting = 'ThemeTemplateSetting',
    ThemeSettingProperty = 'ThemeSettingProperty',
    ThemeTemplateSettingProperty = 'ThemeTemplateSettingProperty',
    Tracker = 'Tracker',
    Widget = 'Widget'
}
