import { Injectable } from '@angular/core';

import { AuthenticatedMemberService } from '../authentication';
import { environment } from '../config/environments/environment';


const Cancelled = 'Cancelled';
const Deidentified = 'Deidentified';

@Injectable()
export class RewardsAndSupporterCheckerService {

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService
    ) { }

    checkStatus() {
        const authMember = this.authenticatedMemberService.getMember();
        const redirectUrl = environment.authServer.baseUrl;

        if (authMember.status === Cancelled || authMember.status === Deidentified) {
            window.location.replace(redirectUrl);
        }
    }
}
