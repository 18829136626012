import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'deletedMessage'
})

export class DeletedMessagePipe implements PipeTransform {
    transform(message: string, deleted: boolean) {
        const deletedMessage = 'This message has been deleted';
        if (deleted) {
            return `<em>${deletedMessage}</em>`;
        }
        return message;
    }
}
