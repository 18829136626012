import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { PersistentCache } from '../core';

@Injectable()
export class CommonApiInterceptor implements HttpInterceptor {

    private sessionCache = new PersistentCache<JSON>('@app:api-cache', 86400);

    commonApis = [
        '/api/languages',
        '/api/countries',
        '/api/states'
    ];

    constructor() {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.commonApis.includes(request.url)) {
            const cachedData = this.sessionCache.getItem(request.url);
            if (cachedData) {
                return of(new HttpResponse({ status: 200, body: cachedData.value }));
            }

            return next.handle(request).pipe(
                tap((event) => {
                    if (event instanceof HttpResponse) {
                        this.sessionCache.setItem(request.url, event.body);
                    }
                })
            );
        }
        return next.handle(request);
    }
}
