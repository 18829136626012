import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Survey } from '../../models/interfaces/survey.model';

@Injectable({
    providedIn: 'root'
})
export class SponsorSurveyService {
    constructor(private httpClient: HttpClient) {}

    bootstrapSurvey(object: any, sponsorId: number) {
        const surveyRewardableVM = {
            survey: object,
            rewardable: object.rewardable
        };
        return this.httpClient.post(`/api/client-admin/sponsors/${sponsorId}/scheduled-surveys/bootstrap`, surveyRewardableVM);
    }

    getSurveyCount(sponsorId: number) {
        return this.httpClient.get(`/api/client-admin/sponsors/${sponsorId}/scheduled-surveys/count`);
    }

    getScheduledSurvey(sponsorId: number, scheduledSurveyId: number) {
        return this.httpClient.get<Survey>(`/api/client-admin/sponsors/${sponsorId}/scheduled-surveys/${scheduledSurveyId}`);
    }

    editScheduledSurvey(scheduledSurvey: any, sponsorId: number) {
        return this.httpClient.put(`/api/client-admin/sponsors/${sponsorId}/scheduled-surveys/${scheduledSurvey.id}`, scheduledSurvey);
    }

    editScheduledSurveyWithoutRequiredCheck(scheduledSurvey: any, sponsorId: number) {
        return this.httpClient.put(`/api/client-admin/sponsors/${sponsorId}/scheduled-surveys/${scheduledSurvey.id}?checkRequiredQuestions=false`,
            scheduledSurvey);
    }

    getScheduledSurveysByStatus(sponsorId: number, status: any, page: number, pageSize: number, sort?: any) {
        let sortBy = sort;
        if (!sort) {
            sortBy = ['startDate', 'endDate'];
        }
        const params = new HttpParams({
            fromObject: { status, page, pageSize, sortBy }
        });

        return this.httpClient.get<Survey[]>(`/api/client-admin/sponsors/${sponsorId}/scheduled-surveys`, {
            params,
            observe: 'response'
        });
    }

    duplicateScheduledSurvey(sponsorId: number, scheduledSurveyId: number) {
        return this.httpClient.post(`/api/client-admin/sponsors/${sponsorId}/scheduled-surveys/${scheduledSurveyId}/duplicate`, {});
    }

    getRewardPopulationsListFromSelection(selectedRewardPopulations: any, survey: any) {
        return selectedRewardPopulations.map((population: any) => {
            return {
                rewardPopulationId: population.id ? population.id : population.rewardPopulationId,
                scheduledSurveyId: survey.id
            };
        });
    }

    getSurveySettingsById(sponsorId: number, scheduledSurveyId: number) {
        return this.httpClient.get(`/api/client-admin/sponsors/${sponsorId}/scheduled-surveys/${scheduledSurveyId}/settings`);
    }
}
