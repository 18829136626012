import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pascalCaseToSentence' })
export class PascalCaseToSentencePipe implements PipeTransform {
    transform(value: string): string {
        return this.parsePascalCaseIntoSentence(value);
    }

    private parsePascalCaseIntoSentence(value: string) {
        return value.replace(/(?!^)(?=[A-Z])/g, ' ');
    }
}
