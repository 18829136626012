import { Injectable } from '@angular/core';

import { AuthenticationProvider } from '../models';

@Injectable()
export class AuthenticationProviderHelperService {
    isAuthenticationProviderIamBased(authProvider: string) {
        return [
            AuthenticationProvider.IAM,
            AuthenticationProvider.IAM_EXTERNAL_IDP
        ].includes(authProvider as any);
    }
}
