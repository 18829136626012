import { Injectable } from '@angular/core';

import {
    Country,
    GenderIdentityDescriptor,
    LanguageDescriptor,
    TimezoneDescriptor,
    UnitOfMeasurementDescriptor
} from '../graphql';

@Injectable()
export class CommonData {

    static Token = 'CommonData';

    countries: Country[] = [];
    langauges: LanguageDescriptor[] = [];
    genders: GenderIdentityDescriptor[] = [];
    timezones: TimezoneDescriptor[] = [];
    unitOfMeasurements: UnitOfMeasurementDescriptor[] = [];
}
