import { Pipe, PipeTransform } from '@angular/core';

import { GameDetailsFilters } from '../models/interfaces/game-details-filters.model';

@Pipe({ name: 'populationRewardsFilter' })
export class PopulationRewardsFilterPipe implements PipeTransform {
    transform(items: any[], searchTerm: GameDetailsFilters) {
        if (!items?.length) {
            return [];
        }
        const populationIds = searchTerm.populationIds;
        if (!populationIds?.length) {
            return items;
        }
        return items.filter(
            (item) => this.populationFilter({ ...item }, populationIds)
        );
    }

    populationFilter(entity: any, selectedPopulations: (number | null)[]) {
        return selectedPopulations.includes(entity.rewardPopulationId);
    }
}
