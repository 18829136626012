import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


@Injectable()
export class BusinessUnitsService {

    constructor(private httpClient: HttpClient) {}

    PAGE_SIZE = 10;
    PAGE = 0;
    BUSINESS_UNITS_URL = '/api/client-admin/business-units';
    COUNT_MEMBERS = true;

    getBusinessUnitsByCompanyIds(companyIds: number[], action: string, context: string, searchBy?: string, page?: number): Observable<any> {
        const fromObject: any = {
            pageSize: this.PAGE_SIZE,
            page: page || this.PAGE,
            name: searchBy || '',
            companyIds,
            countMembers: this.COUNT_MEMBERS,
            action,
            context
        };

        let params = new HttpParams();
        Object.keys(fromObject).forEach((key) => {
            if (Array.isArray(fromObject[key])) {
                fromObject[key].forEach((value: any) => params = params.append(`${key}`, value));
            } else {
                params = params.set(key, fromObject[key]);
            }
        });

        return this.httpClient.get(this.BUSINESS_UNITS_URL, { params, observe: 'response' });
    }
}
