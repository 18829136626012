import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nullAsEmptyString' })
export class NullAsEmptyString implements PipeTransform {
    transform(value: string | null): string {
        if (value === null) {
            return '';
        }
        return value;
    }
}
