import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { authGuard } from './core/guards/auth.guard';
import { RouteOptions } from './core/resolvers/route-options';
import { mfMatchAny } from './core/routing/routing';
import { AuthLayoutComponent } from './layouts/auth-layout.component';
import { BasicLayoutComponent } from './layouts/basic-layout.component';
import { MobileAuthLayoutComponent } from './layouts/mobile-auth-layout.component';
import { EmptyRouteComponent } from '@genesis-frontend/genesis-features';

const authRoutes: Routes = [
    mfMatchAny('devices', RouteOptions.devicesAndAppsRouteOptions),
    mfMatchAny('mobile/devices', RouteOptions.devicesAndAppsRouteOptions),
    mfMatchAny('challenges', RouteOptions.challenges),
    mfMatchAny('featuredchallenge', RouteOptions.challenges),
    mfMatchAny('goal-challenges', RouteOptions.challenges),
    mfMatchAny('holistic-challenges', RouteOptions.challenges),
    mfMatchAny('stats-page', RouteOptions.statsPage),
    mfMatchAny('home', RouteOptions.homeRouteOptions),
    mfMatchAny('member-profile', RouteOptions.memberRouteOptions),
    mfMatchAny('benefits', RouteOptions.benefitsRouteOptions),
    mfMatchAny('my-rewards', RouteOptions.rewardsRouteOptions),
    mfMatchAny('partnerinvite', RouteOptions.socialRouteOptions),
    mfMatchAny('social', RouteOptions.socialRouteOptions),
    mfMatchAny('coaches-corner', RouteOptions.coachesCornerRouteOptions),
    mfMatchAny('guide', RouteOptions.guidesRouteOptions),
    mfMatchAny('mobile/guide', RouteOptions.guidesRouteOptions),
    mfMatchAny('health-ui', RouteOptions.healthRouteOptions),
    mfMatchAny('media', RouteOptions.mediaRouteOptions),
    mfMatchAny('live-services-coaching', RouteOptions.liveServiceRouteOptions),
    mfMatchAny('live-services-guides', RouteOptions.liveServiceRouteOptions),
    mfMatchAny('journeys', RouteOptions.journeysRouteOptions),
    mfMatchAny('friends', RouteOptions.socialRouteOptions),
    mfMatchAny('allfriends', RouteOptions.socialRouteOptions),
    mfMatchAny('addfriends', RouteOptions.socialRouteOptions),
    mfMatchAny('invitefriends', RouteOptions.socialRouteOptions),
    mfMatchAny('healthyhabits', RouteOptions.healthyHabitsRouteOptions),
    mfMatchAny('groups', RouteOptions.socialRouteOptions),
    mfMatchAny('recognition', RouteOptions.socialRouteOptions),
    mfMatchAny('calendar', RouteOptions.calendarRouteOptions),
    mfMatchAny('newsFlash', RouteOptions.newsFlashRouteOptions),
    mfMatchAny('surveys-ui', RouteOptions.surveysRouteOptions),
    mfMatchAny('pillars', RouteOptions.pillarsRouteOptions),
    mfMatchAny('steps-leaderboard', RouteOptions.socialRouteOptions),
    mfMatchAny('transform', RouteOptions.transformRouteOptions),
    mfMatchAny('tpa-resources', RouteOptions.tpaResourcesRouteOptions),
    mfMatchAny('message-center', RouteOptions.messageCenterRouteOptions),
    {
        path: 'rewards/creditRedemptionStore',
        redirectTo: '/my-rewards/creditRedemptionStore',
        pathMatch: 'full'
    },
    {
        path: 'download-sync',
        redirectTo: '/devices/download-sync',
        pathMatch: 'full'
    },
    {
        path: '',
        loadChildren: () => import('./components/components.module').then((m) => m.ComponentsModule)
    },
    {
        path: '**',
        component: EmptyRouteComponent
    }
];

const freeRoutes: Routes = [
    mfMatchAny('welcome.html', RouteOptions.newMiniAppsRouteOptions),
    mfMatchAny('logout.html', RouteOptions.newMiniAppsRouteOptions),
    mfMatchAny('enrollmentSearch.html', RouteOptions.newMiniAppsRouteOptions)
];

const mobileRoutes: Routes = [
    mfMatchAny('webchat', RouteOptions.webchatRouteOptions)
];

const routes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: 'state',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: '',
        canActivateChild: [authGuard],
        component: MobileAuthLayoutComponent,
        children: mobileRoutes
    },
    {
        path: '',
        component: BasicLayoutComponent,
        children: freeRoutes
    },
    {
        path: '',
        canActivateChild: [authGuard],
        component: AuthLayoutComponent,
        children: authRoutes
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: true,
            scrollPositionRestoration: 'enabled'
        })
    ],
    exports: [
        RouterModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppRoutingModule {}
