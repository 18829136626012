import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { RouteOptions } from '../routing/routing';
import { SingleSpaConfiguration } from '@genesis-frontend/genesis-utilities';

@Injectable({ providedIn: 'root' })
export class DefaultFrontendResolver implements Resolve<SingleSpaConfiguration> {
    resolve(route: ActivatedRouteSnapshot): any {
        const options: RouteOptions = route.data.options;
        return {
            appName: options.module,
            appRegex: options.matcher,
            isStandAloneApp: false,
            appImportPath: options.importModule || options.module
        } as SingleSpaConfiguration;
    }
}
