import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

import { KeycodeService } from '../services';

@Directive({
    selector: '[genesisFrontendOnlyOneDec]'
})
export class OnlyOneDecDirective {
    @Output() onlyNum = new EventEmitter<void>();

    keyCode: number[];

    constructor(private keycodeService: KeycodeService) {
        this.keyCode = this.keycodeService.keycodeArray(['backspace', 'tab', 'leftarrow', 'rightarrow', 'uparrow', 'downarrow', 'n0', 'n1', 'n2', 'n3', 'n4', 'n5', 'n6', 'n7', 'n8', 'n9', 'numpad0', 'numpad1', 'numpad2', 'numpad3', 'numpad4', 'numpad5', 'numpad6', 'numpad7', 'numpad8', 'numpad9', 'decimalpoint', 'period']);
    }

    @HostListener('keydown', ['$event', '$event.target'])
    public onKeydown(event: any, targetElement: HTMLElement): void {
        if (!targetElement) {
            return;
        }

        if (this.isDecimal(event) || this.isNotBackspaceOrHasOneDecimalDigit(event)) {
            this.onlyNum.emit();
            event.preventDefault();
        }
    }

    private isDecimal(event: any) {
        const decimalIndex = event.target.value.indexOf('.');
        return !this.keyCode.includes(event.keyCode) || (this.isDecimalPointOrPeriod(event) && decimalIndex > -1);
    }

    private isNotBackspaceOrHasOneDecimalDigit(event: any): boolean {
        const hasOneDecimalDigit = /^\d*\.\d{1,1}$/.test(event.target.value);
        return event.keyCode !== this.keycodeService.keycode.backspace && hasOneDecimalDigit;
    }

    private isDecimalPointOrPeriod(event: any): boolean {
        return event.keyCode === this.keycodeService.keycode.decimalpoint || event.keyCode === this.keycodeService.keycode.period;
    }
}
