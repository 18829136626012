import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AgentAgreement, Agreement } from '../interfaces';

@Injectable({ providedIn: 'root' })
export class AgreementService {
    constructor(private httpClient: HttpClient) {
    }

    getAgreementsToBeSigned(sponsorId: number, agentId: number): Observable<Agreement[]> {
        return this.httpClient.get<Agreement[]>(`/api/sponsors/${sponsorId}/agent-agreements/${agentId}`);
    }

    getCurrentSponsorAgreements(sponsorId: number): Observable<Agreement[]> {
        return this.httpClient.get<Agreement[]>(`/api/sponsors/${sponsorId}/agent-agreements`);
    }

    saveAgentAgreement(agentId: number, agreement: AgentAgreement): Observable<AgentAgreement> {
        const agentAgreement = {
            agentId,
            agreementId: agreement.id
        };
        return this.httpClient.post<AgentAgreement>(`/api/agents/${agentId}/agreements`, agentAgreement);
    }
}
