import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Timezone } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class TimezoneService {
    constructor(
        private httpClient: HttpClient
    ) { }

    getTimezoneList(language: string): Observable<Timezone[]> {
        const params = new HttpParams().set('language', language);
        return this.httpClient.get<Timezone[]>('/api/timezones', { params });
    }
}
