import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { fromEvent } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

import { AudienceDropdownItemsVM } from '../models/AudienceDropdownItemsVM';

@Injectable({
    providedIn: 'root'
})
export class GlobalHelperFunctionsService {
    private windowElement!: Window;
    private documentElement!: Document;
    private retries = 0;
    private observerTimeout: any;

    constructor(@Inject(DOCUMENT) document: any, @Inject(PLATFORM_ID) private platformId: string) {
        if (isPlatformBrowser(this.platformId)) {
            this.windowElement = window;
            this.documentElement = document;
        }
    }

    addScrollListenerForStickyWizardMenu(): void {
        if (isPlatformBrowser(this.platformId)) {
            fromEvent(this.windowElement, 'scroll')
                .pipe(throttleTime(10))
                .subscribe(() => {
                    const TOP_SCROLL_AMOUNT = 0;
                    const wizardMenuElement = this.documentElement.querySelector('.wizard-menu');

                    if (wizardMenuElement) {
                        if (this.windowElement.scrollY > TOP_SCROLL_AMOUNT) {
                            if (
                                this.windowElement.innerHeight <
                                this.documentElement.documentElement.scrollHeight - wizardMenuElement.scrollHeight
                            ) {
                                wizardMenuElement.classList.add('sticky-menu');
                            }
                        } else {
                            wizardMenuElement.classList.remove('sticky-menu');
                        }
                    }
                });
        }
    }

    scrollToElement(selector: string, isDomChangeExpected = false, fixedSelectors = '.wizard-menu, .sticky-menu', maxRetries = 3): void {
        this.retries = 0;
        const element = this.documentElement.querySelector(selector);
        if (element) {
            this.doScroll(element, selector, isDomChangeExpected, fixedSelectors, maxRetries);
        } else {
            this.waitForElementAndScroll(selector, isDomChangeExpected, fixedSelectors, maxRetries);
        }
    }

    private waitForElementAndScroll(selector: string, isDomChangeExpected: boolean, fixedSelectors: string, maxRetries: number): void {
        const observer = new MutationObserver((_mutations, observer) => {
            const element = this.documentElement.querySelector(selector);
            if (element) {
                observer.disconnect();
                clearTimeout(this.observerTimeout);
                this.doScroll(element, selector, isDomChangeExpected, fixedSelectors, maxRetries);
            }
        });

        observer.observe(this.documentElement, { childList: true, subtree: true });

        this.observerTimeout = setTimeout(() => {
            observer.disconnect();
        }, 5000);
    }

    private doScroll(element: Element, selector: string, isDomChangeExpected: boolean, fixedSelectors: string, maxRetries: number): void {
        const fixedOffset = this.getFixedOffset(selector, fixedSelectors);
        const elementPosition = this.getElementPosition(element, fixedOffset, selector);
        this.windowElement.scrollTo({
            top: elementPosition,
            behavior: 'smooth'
        });
        this.recheckAndScrollIfNeeded(element, fixedOffset, isDomChangeExpected, selector, fixedSelectors, maxRetries);
    }

    private recheckAndScrollIfNeeded(element: Element, fixedOffset: number, isDomChangeExpected: boolean, selector: string, fixedSelectors: string, maxRetries: number): void {
        const stickyChangeExpected = selector === '#step-one' || this.windowElement.scrollY === 0;
        if ((isDomChangeExpected || stickyChangeExpected) && this.retries < maxRetries) {
            this.retries++;
            setTimeout(() => this.recheckPosition(element, fixedOffset, isDomChangeExpected, selector, fixedSelectors, maxRetries), isDomChangeExpected ? 500 : 25);
        }
    }

    private recheckPosition(element: Element, fixedOffset: number, isDomChangeExpected: boolean, selector: string, fixedSelectors: string, maxRetries: number): void {
        const currentElementPosition = element.getBoundingClientRect().top + this.windowElement.scrollY - fixedOffset;
        if (Math.abs(this.windowElement.scrollY - currentElementPosition) > 1) {
            this.doScroll(element, selector, isDomChangeExpected, fixedSelectors, maxRetries);
        }
    }

    private getFixedOffset(selector: string, fixedSelectors: string): number {
        if (selector === '#step-one') {
            return 0;
        }
        const fixedElements = Array.from(this.documentElement.querySelectorAll(fixedSelectors));
        return fixedElements.reduce((totalOffset, elem) => {
            if (elem instanceof HTMLElement && window.getComputedStyle(elem).position === 'fixed') {
                return totalOffset + elem.offsetHeight;
            }
            return totalOffset;
        }, 0);
    }

    private getElementPosition(element: Element, fixedOffset: number, selector: string): number {
        return selector === '#step-one' ? 0 : element.getBoundingClientRect().top + this.windowElement.scrollY - fixedOffset;
    }

    markSelectedAudienceItems(selectedAudience: any[], allAudience: any[]): AudienceDropdownItemsVM[] {
        selectedAudience.forEach((selectedUnitValue) => {
            allAudience.forEach((allUnitValue) => {
                if (selectedUnitValue.id === allUnitValue.id) {
                    allUnitValue.selected = true;
                }
            });
        });
        return allAudience;
    }
}
