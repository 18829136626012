import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { StatType } from '../models';
import { ConversionService } from '../services';

@Pipe({ name: 'statUnit', pure: false })
export class ConversionPipe implements PipeTransform {
    readonly DECIMAL_POINTS = 2;

    constructor(
        private converterService: ConversionService,
        private translateService: TranslateService
    ) {}

    private readonly translations = {
        hours: this.translateService.instant('HoursAbbreviation'),
        minutes: this.translateService.instant('MinutesAbbreviation'),
        kg: this.translateService.instant('MeasureUnitkg'),
        lbs: this.translateService.instant('MeasureUnitPounds'),
        st: this.translateService.instant('MeasureUnitStone'),
        mmHg: this.translateService.instant('MeasureUnitmmHg')
    };

    // Value can be a number or a string containing multiple numbers. Ex: sleep 10:20
    transform(value: string, type: StatType, unitMeasure: string, isImperialValue = false, triglycerideLabel = false): string {
        if (value === undefined || value === null || value.toString() === '' || value === '0' || value.toString() === '0') {
            return '';
        }
        const numValue = Number(value);
        if (type === StatType.Weight) {
            return this.convertWeight(numValue, unitMeasure);
        } else if (type === StatType.BodyTemp) {
            return this.convertTemperature(numValue, unitMeasure);
        } else if (type === StatType.Sleep) {
            return this.convertSleep(value);
        } else if (type === StatType.CaloriesConsumed || type === StatType.CaloriesBurned) {
            return this.convertEnergy(numValue);
        } else if (type === StatType.Glucose) {
            return this.convertGlucose(numValue, unitMeasure);
        } else if (type === StatType.Cholesterol) {
            return this.convertCholesterol(numValue, unitMeasure, isImperialValue, triglycerideLabel);
        } else if (type === StatType.A1C) {
            return this.convertHemoglobin(numValue);
        } else if (type === StatType.WaistHipSize) {
            return this.convertWaistHip(value, unitMeasure);
        } else if (type === StatType.BloodPressure) {
            return this.convertBP(value);
        }
        return value.toString();
    }

    convertSleep(value: string): string {
        const arr = value.toString().split(':');
        return `${arr[0]}:${arr[1]}`;
    }

    convertWeight(value: number, unitMeasure: string): string {
        const symbol = {
            Metric: this.translations.kg,
            Imperial: this.translations.lbs,
            UK_Imperial: this.translations.st
        };
        if (unitMeasure === 'Metric') {
            return `${Number(value).toFixed(1)} ${symbol[unitMeasure]}`;
        }
        if (unitMeasure === 'Imperial') {
            return `${this.converterService.convert(value, 'kg', 'lbs').toFixed(1)} ${symbol[unitMeasure]}`;
        } else if (unitMeasure === 'UK_Imperial') {
            const pounds = Math.round(Number(this.converterService.convert(value, 'kg', 'lbs').toString()));
            const stones = Math.floor(Number(pounds) / 14);
            return `${stones.toFixed(0)} ${this.translations.st} ${Number(pounds) % 14} ${this.translations.lbs}`;
        }
        return '';
    }

    convertMins(value: number): string {
        const minutes = this.translations.minutes;
        return `${value} ${minutes}`;
    }

    convertEnergy(value: number): string {
        return value + ' ' + this.translateService.instant('CaloriesAbbreviation');
    }

    convertTemperature(value: number, unitMeasure: string): string {
        const symbol = {
            Metric: 'C',
            Imperial: 'F',
            UK_Imperial: 'C'
        };
        if (unitMeasure === 'Imperial') {
            return `${this.converterService.convertCelsiusToFahrenheit(value).toFixed(1).toString()}${symbol[unitMeasure]}`;
        } else if (unitMeasure === 'UK_Imperial' || unitMeasure === 'Metric') {
            return `${value.toString()}${symbol[unitMeasure]}`;
        }
        return '';
    }

    convertBP(value: string): string {
        const mmMercury = this.translations.mmHg;
        return `${value} ${mmMercury}`;
    }

    convertGlucose(value: number, unitMeasure: string): string {
        const symbol = {
            Metric: this.translateService.instant('CholesterolMetric'),
            Imperial: this.translateService.instant('CholesterolImperial'),
            UK_Imperial: this.translateService.instant('CholesterolMetric')
        };
        if (unitMeasure === 'Metric' || unitMeasure === 'UK_Imperial') {
            return `${value.toFixed(1).toString()} ${symbol[unitMeasure]}`;
        } else if (unitMeasure === 'Imperial') {
            return `${this.converterService.convert(value, 'mmol/L', 'mg/dL', 'BloodGlucose').toFixed(0).toString()} ${
                symbol[unitMeasure]
            }`;
        }
        return '';
    }

    convertCholesterol(value: number, unitMeasure: string, isImperialValue = false, triglycerideLabel = false): string {
        const symbol = {
            Metric: this.translateService.instant('CholesterolMetric'),
            Imperial: this.translateService.instant('CholesterolImperial'),
            UK_Imperial: this.translateService.instant('CholesterolMetric')
        };
        const context = triglycerideLabel ? 'Triglyceride' : 'Cholesterol';

        if (isImperialValue) {
            if (unitMeasure === 'Imperial') {
                return `${value.toFixed(this.DECIMAL_POINTS).toString()} ${symbol[unitMeasure]}`;
            } else if (unitMeasure === 'Metric' || unitMeasure === 'UK_Imperial') {
                return `${this.converterService.convert(value, 'mg/dL', 'mmol/L', context).toFixed(this.DECIMAL_POINTS).toString()} ${
                    symbol[unitMeasure]}`;
            }
        }

        if (unitMeasure === 'Metric' || unitMeasure === 'UK_Imperial') {
            return `${value.toFixed(this.DECIMAL_POINTS).toString()} ${symbol[unitMeasure]}`;
        } else if (unitMeasure === 'Imperial') {
            return `${this.converterService.convert(value, 'mmol/L', 'mg/dL', context).toFixed(this.DECIMAL_POINTS).toString()} ${
                symbol[unitMeasure]
            }`;
        }

        return '0';
    }

    convertHemoglobin(value: number): string {
        return `${value.toFixed(1)}%`;
    }

    convertWaistHip(value: string, unitMeasure: string): string {
        const symbol: Record<string, string> = {
            'Metric': this.translateService.instant('CentimetersAbbreviation'),
            'Imperial': this.translateService.instant('InchesAbbreviation'),
            'UK_Imperial': this.translateService.instant('InchesAbbreviation')
        };
        if (unitMeasure === 'Imperial' || unitMeasure === 'UK_Imperial') {
            return `${this.converterService.convert(Number(value), 'cm', 'in').toFixed(this.DECIMAL_POINTS)} ${symbol[unitMeasure]}`;
        }
        return `${Number(value).toFixed(this.DECIMAL_POINTS)} ${symbol[unitMeasure]}`;
    }
}
