export enum CoachingModuleState {
    Message = 'Message',
    Task = 'Task',
    EmptyTask = 'EmptyTask',
    Unengaged = 'Unengaged',
    Appointment = 'Appointment',
    PastAppointment = 'PastAppointment',
    PreEngaged = 'PreEngaged',
    Pending = 'Pending'
}
