import { Pipe, PipeTransform } from '@angular/core';

import { BloodPressure } from '../models/interfaces/blood-pressure.model';

@Pipe({ name: 'bloodPressureLabel' })
export class BloodPressureLabelPipe implements PipeTransform {
    transform(value: BloodPressure | null, precision: number): string | null {

        if (!value) {
            return null;
        }

        const systolicValue = this.formatNumberOrNull(value.systolicBloodPressure, precision);
        const diastolicValue = this.formatNumberOrNull(value.diastolicBloodPressure, precision);

        if (systolicValue && diastolicValue) {
            return systolicValue + '/' + diastolicValue + ' mmHg';
        } else if (systolicValue) {
            return systolicValue + ' mmHg';
        }

        return null;
    }

    private formatNumberOrNull(value: any, precision: number): string | null {
        const result = parseFloat(value);
        if (result) {
            return result.toFixed(precision);
        }
        return null;
    }
}
