import { Injectable } from '@angular/core';

import { merge, isEmpty, trimStart, isUndefined, has } from '../lib/lodash';

@Injectable({
    providedIn: 'root'
})
export class UrlHelperService {

    buildUrl(baseUrl: string, params: any) {
        const url = this.parseUrl(baseUrl);
        const existingParams = this.parseQueryString(url.getAttribute('search') || '');
        const queryParams = merge(existingParams, this.isPlainObject(params) ? params : {});
        url.setAttribute('search', this.buildQueryString(queryParams));
        return url;
    }

    parseQueryString(qs: string) {
        const object = {};
        const keyValuePairs = trimStart(qs, '?').split('&');
        if (keyValuePairs.length < 1 || isEmpty(qs) || qs.trim() === '') {
            return object;
        }
        keyValuePairs.forEach((keyValue) => {
            const pair = keyValue.split('=');
            const key = pair[0];
            const value = pair[1] || true;
            if (!isUndefined(key)) {
                //@ts-ignore
                if ((object[key].length)) {
                    //@ts-ignore
                    object[key].push(value);
                } else if (has(object, key)) {
                    //@ts-ignore
                    object[key] = [object[key], value];
                } else {
                    //@ts-ignore
                    object[key] = value;
                }
            }
        });
        return object;
    }

    buildQueryString(object: any) {
        const queryParams: string[] = [];
        Object.entries(object).forEach((value, key) => {
            if (value.length) {
                value.forEach((arrayValue) => {
                    queryParams.push(key + (arrayValue === true ? '' : '=' + arrayValue));
                });
            } else {
                //@ts-ignore
                queryParams.push(key + (value === true ? '' : '=' + value));
            }
        });
        return queryParams.length ? queryParams.join('&') : '';
    }

    parseUrl(url: string) {
        const element = document.querySelectorAll('a');
        element[0].setAttribute('href', url);
        return element[0];
    }

    isPlainObject(obj: any) {
        return Object.prototype.toString.call(obj) === '[object Object]';
    }
}
