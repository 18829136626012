import { Injectable } from '@angular/core';

import { AuthenticatedMemberService, NewRelicConstants } from '@genesis-frontend/genesis-utilities';

@Injectable()
export class NewRelicService {
    private NewRelic: any;

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService
    ) { }

    bootstrap() {
        // @ts-ignore
        this.NewRelic = window.newrelic;
    }

    // TODO: Add proper type when migrating the shared benefits-ui models. We should use BenefitProgramModel from benefits-ui.
    addProgramClickEvent(program: any, location: NewRelicConstants) {
        if (!this.NewRelic) {
            this.bootstrap();
        }

        const attributes: Record<string, string | number | NewRelicConstants> = {};
        const member = this.authenticatedMemberService.getAuthenticatedMember();

        attributes[NewRelicConstants.BenefitPrograms.ProgramName] = program.publicTitle;
        attributes[NewRelicConstants.BenefitPrograms.ProgramType] = program.programType;
        attributes[NewRelicConstants.BenefitPrograms.Location] = location;
        attributes[NewRelicConstants.BenefitPrograms.SponsorID] = member.sponsorId;
        attributes[NewRelicConstants.BenefitPrograms.BenefitProgramId] = program.id;

        this.setDefaultProgramCustomAttributes();
        if (this.NewRelic && this.NewRelic.addPageAction) {
            this.NewRelic.addPageAction(NewRelicConstants.BenefitPrograms.ProgramActionClicked, attributes);
        }
    }

    setDefaultProgramCustomAttributes() {
        const programCustomAttributes = [
            NewRelicConstants.BenefitPrograms.ProgramId,
            NewRelicConstants.BenefitPrograms.ProgramName,
            NewRelicConstants.BenefitPrograms.ProgramType,
            NewRelicConstants.BenefitPrograms.Location,
            NewRelicConstants.BenefitPrograms.SponsorID,
            NewRelicConstants.BenefitPrograms.BenefitProgramId
        ];

        if (this.NewRelic && this.NewRelic.setCustomAttribute) {
            programCustomAttributes.forEach((attr) => {
                this.NewRelic.setCustomAttribute(attr, null);
            });
        }
    }
}
