/* eslint-disable no-dupe-class-members */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
    transform(value: any[] | undefined, reverse?: ('asc'|'desc')[], expression?: string[] | string, isCaseInsensitive?: boolean): any[];
    transform(value: any | any[], reverse?: boolean, expression?: string[] | string, isCaseInsensitive?: boolean, comparator?: any): any[];

    transform(value: any | any[], reverse: boolean | ('asc'|'desc')[] = false, expression?: string[] | string, isCaseInsensitive: boolean = false, comparator?: any): any[] {
        if (!value) {
            return [];
        }

        let shouldReverse = false;

        if (Array.isArray(reverse)) {
            if (reverse[0] === 'asc') {
                shouldReverse = false;
            }
            if (reverse[0] === 'desc') {
                shouldReverse = true;
            }
        } else {
            shouldReverse = reverse;
        }

        if (Array.isArray(expression)) {
            return this.multiExpressionTransform(
                value,
                expression.slice(),
                shouldReverse,
                isCaseInsensitive,
                comparator
            );
        }

        if (Array.isArray(value)) {
            return this.sortArray(
                value.slice(),
                expression,
                shouldReverse,
                isCaseInsensitive,
                comparator
            );
        }

        if (typeof value === 'object') {
            return this.transformObject(
                Object.assign({}, value),
                expression,
                shouldReverse,
                isCaseInsensitive
            );
        }

        return value;
    }

    private multiExpressionTransform(value: any | any[], expressions: string[], reverse: boolean, isCaseInsensitive: boolean, comparator: any): any {
        expressions.reverse();
        return expressions.reduce((result: any, expression: string) => {
            return this.transform(
                result,
                reverse,
                expression,
                isCaseInsensitive,
                comparator
            );
        }, value);
    }

    private sortArray<Type>(array: Type[], expression?: string, reverse?: boolean, isCaseInsensitive?: boolean, comparator?: any): Type[] {
        const isDeepLink = expression && expression.indexOf('.') !== -1;
        let expressionsArray: string[] = [];
        if (expression && isDeepLink) {
            expressionsArray = this.parseExpression(expression);
        }

        let compareFn: any;

        if (comparator && typeof comparator === 'function') {
            compareFn = comparator;
        } else {
            compareFn = isCaseInsensitive
                ? this.caseInsensitiveSort
                : this.defaultCompare;
        }

        const sortedArray = array.sort((a: any, b: any): number => {
            return this.sortHelper(a, b, expressionsArray, isDeepLink, expression, compareFn);
        });

        return reverse ? sortedArray.reverse() : sortedArray;
    }

    private sortHelper(a: any, b: any, expressionsArray: string[], isDeepLink: boolean | string | undefined, expression?: string, compareFn?: any): number {
        if (!expression) {
            return compareFn(a, b);
        }

        if (!isDeepLink) {
            return (a && b) ? compareFn(a[expression], b[expression]) : compareFn(a, b);
        }

        return compareFn(this.getValue(a, expressionsArray), this.getValue(b, expressionsArray));
    }

    private parseExpression(expression: string): string[] {
        expression = expression.replace(/\[(\w+)\]/g, '.$1');
        expression = expression.replace(/^\./, '');
        return expression.split('.');
    }

    private caseInsensitiveSort(a: any, b: any) {
        if (this.isString(a) && this.isString(b)) {
            return a.localeCompare(b);
        }
        return this.defaultCompare(a, b);
    }

    private isString(value: any): boolean {
        return typeof value === 'string' || value instanceof String;
    }

    private defaultCompare(a: any, b: any) {
        if (a && a instanceof Date) {
            a = a.getTime();
        }
        if (b && b instanceof Date) {
            b = b.getTime();
        }

        if (a === b) {
            return 0;
        }
        if (a == null) {
            return 1;
        }
        if (b == null) {
            return -1;
        }
        return a > b ? 1 : -1;
    }

    private getValue(object: any, expressions: string[]): any {
        expressions.forEach((expression: string) => {
            if (!object) {
                return;
            }
            if (!(expression in object)) {
                return;
            }
            if (typeof object[expression] === 'function') {
                object = object[expression]();
            } else {
                object = object[expression];
            }
        });
        return object;
    }

    private transformObject(value: any | any[], expression?: any, reverse?: boolean, isCaseInsensitive?: boolean) {
        const parsedExpression = this.parseExpression(expression);
        let lastPredicate = parsedExpression.pop();
        let oldValue = this.getValue(value, parsedExpression);

        if (!Array.isArray(oldValue)) {
            if (lastPredicate) {
                parsedExpression.push(lastPredicate);
            }
            lastPredicate = undefined;
            oldValue = this.getValue(value, parsedExpression);
        }

        if (!oldValue) {
            return value;
        }

        this.setValue(value, this.transform(oldValue, reverse, lastPredicate, isCaseInsensitive), parsedExpression);
        return value;
    }

    private setValue(object: any, value: any, expressions: string[]) {
        expressions.forEach((expression) => {
            object = object[expression];
        });
        object[expressions[expressions.length]] = value;
    }
}
