import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PreviewToolService } from './preview-tool.service';

@Injectable({
    providedIn: 'root'
})
export class AchievementsService {
    constructor(
        private httpClient: HttpClient,
        private previewToolService: PreviewToolService
    ) { }

    setTrophiesAsSeen(memberId: number, sponsorId: number, achievements: any): Observable<any[]> {
        return this.httpClient.post<any[]>(`/api/genesis-rewards/sponsors/${sponsorId}/members/${memberId}/trophies/recent`, achievements);
    }

    getRecentAchievementsForMember(memberId: number, sponsorId: number) {
        return this.httpClient.get(this.previewToolService.getQueryParameterObject(`/api/genesis-rewards/sponsors/${sponsorId}/members/${memberId}/trophies/recent`));
    }

}
