import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BubbleCelebrationComponent } from './bubble-celebration.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        BubbleCelebrationComponent
    ],
    exports: [
        BubbleCelebrationComponent
    ]
})
export class BubbleCelebrationModule { }
