import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    templateUrl: './global-challenges-rules.template.html'
})
export class GlobalChallengeRulesModal {
    @Input() rulesHtml!: string;

    constructor(
        private bsModalRef: BsModalRef
    ) {}

    close() {
        this.bsModalRef.hide();
    }
}
