import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { EmptyRouteComponent } from './empty-route.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        EmptyRouteComponent
    ],
    exports: [
        EmptyRouteComponent
    ]
})
export class EmptyRouteModule { }
