import { Pipe, PipeTransform } from '@angular/core';

import { FilePickerService } from '../services';

@Pipe({ name: 'secureImage' })
export class SecureImagePipe implements PipeTransform {

    constructor(private filePickerService: FilePickerService) { }

    transform(value?: string): string {
        if (!value) {
            return '';
        }
        this.filePickerService.checkReadSecurityExpiry();
        return this.filePickerService.secureFilePath(value);
    }
}
