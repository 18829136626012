import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { interval, Subscription } from 'rxjs';

@Component({
    selector: 'member-logout-warning-modal',
    templateUrl: './logout-warning-modal.component.html'
})
export class LogoutWarningModalComponent implements OnInit {
    @ViewChild('buttonmodal') myModalButton!: ElementRef;
    @ViewChild('close') closeButton!: ElementRef;

    countdownTimer: Subscription;
    timer = environment.logoutWarningSeconds;
    ignoreReason = 'DIALOG_IGNORED';
    dismissReason = '';

    COUNTDOWN_STEP = 1000;

    constructor(
        private bsModalRef: BsModalRef,
        private renderer2: Renderer2
    ) {
        this.countdownTimer = interval(this.COUNTDOWN_STEP).subscribe(() => {
            if (this.timer < 1) {
                this.countdownTimer.unsubscribe();
                this.dismissReason = this.ignoreReason;
                this.bsModalRef.hide();
                return;
            }
            this.timer -= 1;
        });
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.myModalButton?.nativeElement.focus();
        }, 1);
    }

    stay(): void {
        this.countdownTimer.unsubscribe();
        this.bsModalRef.hide();
    }

    focus(event: any, element: ElementRef): void {
        if (event.key === 'Tab' || event.key === 'Tab' && event.key === 'Shift') {
            event.preventDefault();
            this.manageButtonVisibility();
            element.nativeElement.focus();
        }
    }

    manageButtonVisibility(): void {
        if (this.closeButton?.nativeElement.classList.contains('sr-only')) {
            this.renderer2.removeClass(this.closeButton.nativeElement, 'sr-only');
        } else {
            this.renderer2.addClass(this.closeButton?.nativeElement, 'sr-only');
        }
    }
}
