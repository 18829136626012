<img
    *ngIf="card.imageSource"
    [ngSrc]="card.imageSource"
    id="coaching-card-img"
    priority
    alt=""
    height="148"
    width="350"
    class="image"
    vpgFallbackImage
/>
<p
    [id]="card.titleId + '-heading'"
    class="body-regular mb-xs mt-lg header-text"
>
    {{ COACHING | translate }}
</p>
<h2 
    [id]="card.titleId"
    class="h4 text-neutral title"
>
    {{ card.title }}
</h2>
<ng-content></ng-content>
<a
    vpgButton
    (click)="sendAnalytics()"
    [href]="hrefPath"
    [id]="card.buttonId"
    [variant]="card.buttonType"
    size="medium"
    class="action mt-lg"
    [attr.aria-label]="card.buttonAriaLabel"
>
    {{ card.buttonLabel }}
</a>
