import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { AccordionClickEventModel } from '../../domain/accordion-click-event.model';
import { CheckboxSelectionModel, Pillar, PillarTopic } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'pillar-accordion',
    templateUrl: 'pillar-accordion.component.html',
    styleUrls: ['pillar-accordion.component.scss']
})
export class PillarAccordionComponent implements OnInit, OnChanges {
    @Input() active?: boolean;
    @Input() pillar: Pillar = {} as Pillar;
    @Input() value?: PillarTopic[];

    @Output() onAccordionClick = new EventEmitter<AccordionClickEventModel<Pillar>>();
    @Output() onPillarTopicSelection = new EventEmitter<PillarTopic[]>();

    topics: CheckboxSelectionModel<PillarTopic>[] = [];
    numberOfSelectedTopics?: number;
    thumbnailImageUrl = '';

    constructor() {}

    ngOnInit(): void {
        const { thumbnailImageUrl } = this.pillar;
        this.thumbnailImageUrl = thumbnailImageUrl ? thumbnailImageUrl : '';
        return;
    }

    ngOnChanges(): void {
        this.topics = this.asTopicSelectionList(this.pillar.topics);
        this.numberOfSelectedTopics = this.topics.filter((topic) => {
            return topic.selected;
        }).length;
    }

    onAccordionHeaderClick(event: Event): void {
        const newActiveState = !this.active;

        if (event.type === 'keyup' && (event as KeyboardEvent).key === 'Enter') {
            (event.target as HTMLInputElement).click();
            return;
        }

        this.onAccordionClick.emit({ active: newActiveState, data: this.pillar });
    }

    onTopicSelection(topic: CheckboxSelectionModel<PillarTopic>): void {
        const newSelectionListValue = this.asSelectionValue(topic);
        this.onPillarTopicSelection.emit(newSelectionListValue);
    }

    private asSelectionValue(pillarTopic: CheckboxSelectionModel<PillarTopic>): PillarTopic[] | undefined {
        const { selected, data } = pillarTopic;
        if (!selected) {
            return this.value?.filter((p) => p.id !== data.id);
        }

        if (!this.value || !this.value.length) {
            return [data];
        }

        if (this.value.some((topic) => topic.id === data.id)) {
            return [...this.value];
        }

        return [data, ...this.value];
    }

    private asTopicSelectionList(topics: PillarTopic[]): CheckboxSelectionModel<PillarTopic>[] {
        if (!topics || !topics.length) {
            return [];
        }

        const selectedTopics = this.value ? this.value : [];
        return topics.map((pillarTopic) => {
            return {
                selected: selectedTopics.some((selectedTopic) => {
                    return pillarTopic.id === selectedTopic.id;
                }),
                data: pillarTopic
            };
        });
    }
}
