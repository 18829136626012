<div
    *ngIf="isReady"
    class="flip-clock">
    <vp-flipclock-item [value]="dayTens"></vp-flipclock-item>
    <vp-flipclock-item [value]="dayOnes"></vp-flipclock-item>
    <span class="vp-flip-clock-divider">
        <span class="vp-flip-clock-dot vp-flip-clock-dot-top"></span>
        <span class="vp-flip-clock-dot vp-flip-clock-dot-bottom"></span>
    </span>
    <vp-flipclock-item [value]="hourTens"></vp-flipclock-item>
    <vp-flipclock-item [value]="hourOnes"></vp-flipclock-item>
    <span class="vp-flip-clock-divider">
        <span class="vp-flip-clock-dot vp-flip-clock-dot-top"></span>
        <span class="vp-flip-clock-dot vp-flip-clock-dot-bottom"></span>
    </span>
    <vp-flipclock-item [value]="minuteTens"></vp-flipclock-item>
    <vp-flipclock-item [value]="minuteOnes"></vp-flipclock-item>
    <span
        *ngIf="showSeconds"
        class="vp-flip-clock-divider">
        <span class="vp-flip-clock-dot vp-flip-clock-dot-top"></span>
        <span class="vp-flip-clock-dot vp-flip-clock-dot-bottom"></span>
    </span>
    <vp-flipclock-item
        *ngIf="showSeconds"
        [value]="secondTens"></vp-flipclock-item>
    <vp-flipclock-item
        *ngIf="showSeconds"
        [value]="secondOnes"></vp-flipclock-item>
</div>
