import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CalendarEventModalComponent } from './calendar-event-modal.component';
import { DialogRef, DialogService } from '@engineering/vpgroove-angular';
import {
    CalendarEvent,
    MemberEventRsvp
} from '@genesis-frontend/genesis-utilities';


@Injectable()
export class CalendarEventService {

    constructor(
        private httpClient: HttpClient,
        private dialogService: DialogService
    ) { }

    openCalendarModal(calendarEvent: any): DialogRef {
        return this.dialogService.open(CalendarEventModalComponent, {
            props: { calendarEvent: calendarEvent }
        });
    }

    getEvents(memberId: number, startDate: string, endDate: string): Observable<CalendarEvent[]> {
        return this.httpClient
            .get<CalendarEvent[]>(`/api/members/${memberId}/calendar-events?startDate=${startDate}&endDate=${endDate}`);
    }

    getMemberRsvp(eventId: number, memberId: number): Observable<MemberEventRsvp> {
        return this.httpClient.get<MemberEventRsvp>(`/api/members/${memberId}/calendar-events/${eventId}/rsvps`);
    }

    setMemberRsvp(eventRsvp: MemberEventRsvp): Observable<MemberEventRsvp> {
        return this.httpClient
            .post<MemberEventRsvp>(`/api/members/${eventRsvp.memberId}/calendar-events/${eventRsvp.eventId}/rsvps`, eventRsvp);
    }

    deleteMemberRsvp(eventId: number, memberId: number): Observable<MemberEventRsvp> {
        return this.httpClient.delete<MemberEventRsvp>(`/api/members/${memberId}/calendar-events/${eventId}/rsvps`);
    }

    getEvent(eventId: number): Observable<CalendarEvent> {
        return this.httpClient.get<CalendarEvent>(`/api/calendar-events/${eventId}`);
    }

}
