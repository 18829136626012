import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ClaimsState } from '../../models/claim-state.model';
import { InsuranceClaim } from '../../models/insurance-claim.model';
import { AnalyticsTrackingAction, AnalyticsTrackingSingleSpaService } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'genesis-frontend-claim',
    templateUrl: './claim.component.html',
    styleUrl: './claim.component.scss'
})
export class ClaimComponent {
    @Input() claim: InsuranceClaim = {} as InsuranceClaim;
    @Input() claimsState: ClaimsState = ClaimsState.noClaims;

    constructor(
        private readonly analyticsService: AnalyticsTrackingSingleSpaService,
        private readonly translateService: TranslateService
    ) {}

    readonly translations = {
        VIEW_DETAILS: this.translateService.instant('ViewDetails'),
        DATE_SERVICE: this.translateService.instant('DateService'),
        CLAIM_NUMBER: this.translateService.instant('ClaimNumber'),
        CHARGED_AMOUNT: this.translateService.instant('ChargedAmount'),
        MAY_OWE: this.translateService.instant('YouMayOwe')
    };

    protected readonly viewDetailsFormatted = this.translations.VIEW_DETAILS.charAt(0) + this.translations.VIEW_DETAILS.slice(1).toLowerCase();

    sendAnalytics() {
        this.analyticsService.sendData(
            AnalyticsTrackingAction.HomepageWidgetTable,
            {
                Widget_name: 'Claims Widget',
                Claims_state: this.claimsState,
                Claims_clicks: 'View details',
                Claims_ID: this.claim.id,
                Claims_navigation: 'Navigate to Claim Details Page',
                Claims_details: `${this.claim.totalChargedAmount}, ${this.claim.serviceStartDate}, ${this.claim.providerName}`
            }
        );
    }
}
