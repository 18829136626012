<img
    *ngIf="card.imageSource"
    [ngSrc]="card.imageSource"
    priority
    alt=""
    height="148"
    width="350"
    class="image"
    id="unengaged-image"
    vpgFallbackImage
/>
<h2 
    [id]="card.titleId"
    class="title h4 text-neutral"
>
    {{ card.title }}
</h2>
<div
    [id]="card.contentId"
    class="description body-regular text-neutral-90"
>
    {{ card.content }}
</div>
<a
    vpg-button
    [id]="card.buttonId"
    variant="primary"
    size="medium"
    class="action"
    [href]="hrefPath"
    (click)="sendAnalytics()"
>
    {{ card.buttonLabel }}
</a>
