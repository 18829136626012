import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'preferenceTranslate' })
export class CommunicationPreferenceTranslationPipe implements PipeTransform {
    transform(value: string, translations: any, property: string): string {
        if (!translations) {
            return value;
        }

        const translationsMap = new Map(Object.entries(translations));
        if (translationsMap.size >= 1 && translationsMap.has(property)) {
            return translationsMap.get(property) as string;
        }
        return value;
    }
}
