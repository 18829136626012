import { Component } from '@angular/core';

import { LogoutMemberService } from '@genesis-frontend/genesis-utilities';

@Component({
    template: `<div>
        <div class="no-product-modal">
            <img class="text-centered preserved-ratio-image" src="https://file.personifyhealth.com/api/file/WrXTQmMoRZqPzNsASHiy" alt="" />
            <div class="background-clr-white text-container">
                <div class="no-product-title" [translate]="'NotUsedToSee'"></div>
                <div class="no-product-text" [translate]="'OrganizationNotUsingProgram'"></div>
                <div class="no-product-btn-container">
                    <a (click)="logout()">
                        <button class="vp-button vp-button-primary" [translate]="'SignOut'"></button>
                    </a>
                </div>
            </div>
        </div>
    </div> `
})
export class NoProductComponent {
    constructor(private logoutService: LogoutMemberService) {}

    logout(): void {
        this.logoutService.logout(false);
    }
}
