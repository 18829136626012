import { Directive, ElementRef, AfterViewInit } from '@angular/core';

import { KeyCode } from '../models';


@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[modalTrapFocus]'
})
export class ModalTrapFocusDirective implements AfterViewInit {

    constructor(private el: ElementRef) { }

    ngAfterViewInit() {
        this.trapFocus(this.el.nativeElement);
    }

    trapFocus(element: any) {
        const focusableElements = element.querySelectorAll(
            'a[href], button, i, [tabindex]:not([tabindex="-1"])'
        );
        const focusableEls = Array.from(focusableElements)
            .filter((el: any) => !el.disabled);
        const firstFocusableElement: any = focusableEls[0];
        const lastFocusableElement: any = focusableEls[focusableEls.length - 1];

        element.addEventListener('keydown', function(event: any) {
            if (event.keyCode !== KeyCode.tab) {
                return;
            }

            if (event.shiftKey && event.keyCode === KeyCode.tab) {
                if (document.activeElement === firstFocusableElement) {
                    lastFocusableElement.focus();
                    event.preventDefault();
                }
            } else if (document.activeElement === lastFocusableElement) {
                firstFocusableElement.focus();
                event.preventDefault();
            }
        });
    }
}
