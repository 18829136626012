<h2
    [id]="errorTitleId"
    class="error-message-title text-transform-none h4">
    {{ errorTitle | translate }}
</h2>
<div [id]="errorMessageId"
     class="flex justify-center error-message-text-wrapper body-regular mt-xs"
>
    {{ errorMessage | translate }}
</div>
