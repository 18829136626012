import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'arrayJoin' })
export class ArrayJoinPipe implements PipeTransform {
    transform(value: any[] | null, property?: string, separator: string = ', '): any {
        if (!value || !value.length) {
            return '';
        }

        const firstValue = value[0];

        if (this.isPrimitive(firstValue)) {
            return value.join(separator);
        }

        if (!property) {
            return '';
        }

        if (!Object.keys(firstValue).includes(property)) {
            return '';
        }

        return value.map((x) => x[property]).join(separator);
    }

    private isPrimitive(value: any) {
        return value !== Object(value);
    }
}
