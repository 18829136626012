import { Pipe, PipeTransform } from '@angular/core';

import { DateUtils } from '../lib/date-utils';
import { DateFormats } from '../models/enums/date-formats.enum';

@Pipe({ name: 'quarterBoundaries' })
export class QuarterBoundariesPipe implements PipeTransform {
    transform(year: number, quarter: number): { start: string; end: string } {
        const start = DateUtils.startOf(
            DateUtils.dateWithQuarter(
                DateUtils.dateWithYear(new Date(), year),
                quarter
            ),
            'Q'
        );
        const end = DateUtils.endOf(
            new Date(start),
            'Q'
        );
        return {
            start: DateUtils.format(start, DateFormats.Date),
            end: DateUtils.format(end, DateFormats.Date)
        };
    }
}
