import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SupportSettings } from '../models';

@Injectable({
    providedIn: 'root'
})
export class SupportSettingsService {
    constructor(
        private httpClient: HttpClient
    ) { }

    getMemberSupportSettings(sponsorId: number, memberId:number) {
        return this.httpClient.get<SupportSettings>(`api/sponsors/${sponsorId}/members/${memberId}/support-settings`);
    }
}
