import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilestackService } from '@filestack/angular';
import { TranslateModule } from '@ngx-translate/core';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { GlobalChallengesAddPlayersCardComponent } from './blockers/cards/add-players/add-players.component';
import { GlobalChallengesMaxBuzzAddressCollectionCardComponent } from './blockers/cards/address-collection/address-collection.component';
import { GlobalChallengesMaxBuzzAddressConfirmationCardComponent } from './blockers/cards/address-confirmation/address-confirmation.component';
import { GlobalChallengesConfirmTeamCreationCardComponent } from './blockers/cards/confirm-team-creation/confirm-team-creation.component';
import { GlobalChallengesConnectDeviceCardComponent } from './blockers/cards/connect-device/connect-device.component';
import { GlobalChallengesCreateTeamCardComponent } from './blockers/cards/create-a-team/create-a-team.component';
import { GlobalChallengesMaxBuzzOrOwnDevice } from './blockers/cards/device-ordering/device-ordering.component';
import { GlobalChallengesJoinAChallengeCardComponent } from './blockers/cards/join-a-challenge/join-a-challenge.component';
import { GlobalChallengesJoinATeamCardComponent } from './blockers/cards/join-a-team/join-a-team.component';
import { TeamCardComponent } from './blockers/cards/join-a-team/team-card/team-card.component';
import { GlobalChallengesMaxBuzzOrderConfirmationCardComponent } from './blockers/cards/order-confirmation/order-confirmation.component';
import { GlobalChallengesTeamPreviewCardComponent } from './blockers/cards/team-preview/team-preview.component';
import { GlobalChallengesOnboardingBlockerModal } from './blockers/global-challenges-blocker/global-challenges-onboarding-blocker.component';
import { GlobalChallengesOnboardingBlockerModalService } from './blockers/global-challenges-blocker/global-challenges-onboarding-blocker.service';
import { MemberContestOnboardingBlockerService } from './blockers/member-contest-onboarding-blocker.service';
import { OnboardingFlipClockComponent } from './blockers/onboarding-flipclock/onboarding-flipclock.component';
import { VpGoService } from './blockers/vp-go.service';
import { GlobalChallengeRulesModal } from './global-challenge-rules-modal/global-challenges-rules.component';
import { GlobalChallengeRulesModalService } from './global-challenge-rules-modal/global-challenges-rules.service';
import { BubbleCelebrationModule } from '../../bubble-celebration/bubble-celebration.module';
import { CardCarouselModule } from '../../card-carousel/card-carousel.module';
import { FlipClockModule } from '../../flip-clock/flip-clock.module';
import { VpgrooveModule } from '@engineering/vpgroove-angular';
import { FilePickerService, GenesisDirectivesModule } from '@genesis-frontend/genesis-utilities';

@NgModule({
    imports: [
        CommonModule,
        FlipClockModule,
        CardCarouselModule,
        TranslateModule,
        FormsModule,
        InfiniteScrollModule,
        BubbleCelebrationModule,
        PopoverModule,
        SlickCarouselModule,
        ReactiveFormsModule,
        GenesisDirectivesModule,
        VpgrooveModule
    ],
    declarations: [
        OnboardingFlipClockComponent,
        GlobalChallengeRulesModal,
        GlobalChallengesOnboardingBlockerModal,
        GlobalChallengesJoinATeamCardComponent,
        TeamCardComponent,
        GlobalChallengesJoinAChallengeCardComponent,
        GlobalChallengesTeamPreviewCardComponent,
        GlobalChallengesCreateTeamCardComponent,
        GlobalChallengesAddPlayersCardComponent,
        GlobalChallengesMaxBuzzAddressCollectionCardComponent,
        GlobalChallengesMaxBuzzAddressConfirmationCardComponent,
        GlobalChallengesConfirmTeamCreationCardComponent,
        GlobalChallengesConnectDeviceCardComponent,
        GlobalChallengesMaxBuzzAddressConfirmationCardComponent,
        GlobalChallengesMaxBuzzOrderConfirmationCardComponent,
        GlobalChallengesMaxBuzzOrOwnDevice
    ],
    exports: [
        OnboardingFlipClockComponent
    ],
    providers: [
        MemberContestOnboardingBlockerService,
        GlobalChallengesOnboardingBlockerModalService,
        GlobalChallengeRulesModalService,
        FilePickerService,
        FilestackService,
        VpGoService
    ]
})
export class GlobalChallengesBlockerModule {}
