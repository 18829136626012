<div id='custom-notification'
     class="vp-background-info"
     [ngClass]="{'animateShow': notification.show, 'animateHide': !notification.show}">
    <div class='notification-message-container'
         aria-live="polite"
         aria-atomic="true">
        <div aria-live="polite"
             aria-atomic="true"
             *ngIf="notification.show"
             [innerHTML]="notification.message"></div>
        <button
                class="notification-close"
                (click)="closeCustomNotification()"
                aria-hidden="true"
                tabindex="-1">
            &times;
        </button>
    </div>
</div>
