<div class="challenge-onboarding-address-confirmation-container">
    <div
        class="address-confirmation-card-title text-clr-oxford-blue"
        [translate]="'ConfirmAddress'">
        Confirm your address
    </div>
    <div
        class="address-confirmation-subtitle text-clr-oxford-blue"
        [translate]="'DoubleCheckShipping'">
        Let's double check that we're shipping your activity tracker to the right place.
    </div>
</div>
<div class="card-middle-address-confirmation">
    <div class="address-confirmation-max-buzz-image border-clr-gainsboro">
        <img
            class="confirm-vp-go-player-image popover-trigger border-clr-bg"
            [src]="'/img/activity-tracker-device.svg'"
            alt="Activity Tracker"/>
    </div>
    <div class="line-between-divs">
        <div class="line-between-divs-inner background-clr-gainsboro"></div>
    </div>
    <div class="address-confirmation-details">
        <div class="address-confirmation-details-text">
            <span class="address-confirmation-details-label text-clr-oxford-blue">{{member?.firstname}} {{member?.lastname}}</span>
            <span
                *ngIf="data.orderDetails?.businessName"
                class="address-confirmation-details-label text-clr-oxford-blue">
                {{data.orderDetails?.businessName}}
            </span>
            <div class="address-info-div">
                <div class="truncate-long-text">
                    <span class="address-confirmation-details-label text-clr-oxford-blue">{{data.orderDetails?.address1}}</span>
                </div>
                <div class="truncate-long-text">
                    <span
                        *ngIf="data.orderDetails?.address2"
                        class="address-confirmation-details-label text-clr-oxford-blue">
                        {{data.orderDetails?.address2}}
                    </span>
                </div>
                <div class="truncate-long-text">
                    <span class="address-confirmation-details-label truncate-long-text text-clr-oxford-blue">
                        {{data.orderDetails?.city}}
                    </span>
                </div>
            </div>
            <span class="address-confirmation-details-label text-clr-oxford-blue">
                {{data.orderDetails?.state}}, {{data.orderDetails?.zip}}
            </span>
            <span class="address-confirmation-details-label text-clr-oxford-blue">{{data.orderDetails?.selectedCountryName}}</span>
            <span class="address-confirmation-details-label text-clr-oxford-blue">{{phoneNumber}}</span>
        </div>
        <div class="global-challenge-onboarding-button">
            <button
                class="vp-button vp-button-primary larger bordered"
                (click)="placeOrder()"
                [disabled]="savingOrderInProgress">
                <span [translate]="'PlaceOrder'">Place order</span>
            </button>
        </div>
    </div>
</div>
<div class="card-footer-address-confirmation">
    <span
        class="address-confirmation-note-text"
        [translate]="isPersonifyHealthTheme ? 'NotePH' : 'AddressDeliveryDisclaimer'">
    </span>
</div>
<div
    class="prev-card-btn background-clr-bg background-dark text-clr-white"
    *ngIf="showLeftArrow"
    (click)="this.data.prevCard()"
    role="button"
    tabindex="0"
    [attr.aria-label]="'previous' | translate">
    <i class="fa fa-angle-left"></i>
</div>
