import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, forkJoin } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

import { AuthenticatedMemberService, Friend, FriendStatus, KeyCode, SuggestedFriends } from '@genesis-frontend/genesis-utilities';

type ListOfFunctions = (() => void);
@Injectable({
    providedIn: 'root'
})
export class FriendsService {
    HH_CHALLENGE = 'HH_CHALLENGE';
    friendInviteActionTriggered = new ReplaySubject(1);
    friendsUpdateCallbacks: ListOfFunctions [] = [];

    constructor(private authenticatedMemberService: AuthenticatedMemberService,
                private httpClient: HttpClient) {
    }

    acceptFriendInvite(invite: any) {
        this.friendInviteActionTriggered.next(invite.id);
        const memberId = this.authenticatedMemberService.getAuthenticatedMember().id;
        return this.httpClient.put(`/api/members/${memberId}/friends/requests`, invite).pipe(finalize(() => this.runUpdateCallbacks));
    }

    declineFriendInvite(invite: any) {
        this.friendInviteActionTriggered.next(invite.id);
        const memberId = this.authenticatedMemberService.getAuthenticatedMember().id;
        return this.httpClient.delete(`/api/members/${memberId}/friends/requests/${invite.id}`)
            .pipe(finalize(() => this.runUpdateCallbacks));
    }

    getFriendsOfFriends(friendId: any, pageNumber: any, pageSize: any): Observable<any> {
        const authenticatedMember = this.authenticatedMemberService.getAuthenticatedMember();
        return this.httpClient.get(`/api/members/${authenticatedMember.id}/friends/${friendId}/friends`, {
            params: {
                page: String(pageNumber),
                pageSize: String(pageSize)
            }
        });
    }

    getMyFriend(friendId: any): Observable<any> {
        const authenticatedMember = this.authenticatedMemberService.getAuthenticatedMember();
        return this.httpClient.get(`/api/members/${authenticatedMember.id}/friends/${friendId}`);
    }

    getMyFriendInvites(): Observable<any> {
        const authenticatedMember = this.authenticatedMemberService.getAuthenticatedMember();
        return this.httpClient.get(`/api/members/${authenticatedMember.id}/friends/requests`);
    }

    getMySentInvites(): Observable<any> {
        const authenticatedMember = this.authenticatedMemberService.getAuthenticatedMember();
        return this.httpClient.get(`/api/members/${authenticatedMember.id}/friends/requests/sent`);
    }

    getMyFriendsByPage(memberId: number, pageNumber: any, pageSize: any): Observable<any> {
        return this.httpClient.get(`/api/members/${memberId}/friends`, {
            observe: 'response',
            params: {
                page: String(pageNumber),
                pageSize: String(pageSize)
            }
        }).pipe(map((response) => ({
            friends: response.body,
            pageSize: response.headers.get('page-size'),
            totalPages: response.headers.get('total-pages')
        })));
    }

    getMyFriendsByIds(friendIds: string[]) {
        const authenticatedMember = this.authenticatedMemberService.getAuthenticatedMember();
        return this.httpClient.get(`/api/members/${authenticatedMember.id}/friends`, {
            params: {
                friendIds
            }
        });
    }

    getMyFriendsAchievements(friendId: any): Observable<any> {
        const authenticatedMember = this.authenticatedMemberService.getAuthenticatedMember();
        return this.httpClient.get(`/api/members/${authenticatedMember.id}/friends/${friendId}/achievements`);
    }

    loadSuggestedFriends(memberId:number, pageNumber: number, pageSize: number, filterType?: string): Observable<SuggestedFriends> {
        const params: any = {
            page: String(pageNumber),
            pageSize: String(pageSize)
        };
        if (filterType) {
            params.filterType = filterType;
        }
        return this.httpClient.get(`/api/members/${memberId}/friends/suggested`, {
            observe: 'response',
            params
        }).pipe(
            map((responseWithHeaders: any) => {
                return {
                    suggestedFriends: this.getSuggestedFriendsWithStatus(responseWithHeaders.body),
                    totalCountOfSuggestedFriends: responseWithHeaders.headers.get('content-items')
                } as SuggestedFriends;
            }));
    }

    private getSuggestedFriendsWithStatus(response: Friend[]): Friend[] {
        const suggested = response;
        return suggested.map((friend: Friend) => {
            return {
                ...friend,
                friendStatus: friend.friendStatus || FriendStatus.NotFriends
            };
        });
    }

    registerUpdateCallback(callback: any) {
        this.friendsUpdateCallbacks.push(callback);
    }

    removeFriend(friendId: any) {
        const authenticatedMember = this.authenticatedMemberService.getAuthenticatedMember();
        return this.httpClient.delete(`/api/members/${authenticatedMember.id}/friends/${friendId}`);
    }

    runUpdateCallbacks() {
        this.friendsUpdateCallbacks.forEach((callback) => {
            callback();
        });
    }

    searchFriends(queryText: string, pageSize: number, contestId?: number) : Observable<Friend[]> {
        const authenticatedMember = this.authenticatedMemberService.getAuthenticatedMember();
        let params = new HttpParams();
        params = params.append('criteria', queryText);
        if (contestId) {
            params = params.append('contestId', contestId.toString());
        }
        if (pageSize) {
            params = params.append('resultsSize', pageSize.toString());
        }
        return this.httpClient.get<Friend[]>(`/api/sponsors/${authenticatedMember.sponsorId}/members/search`, {
            params
        });
    }

    searchChallengeFriends(queryText: any, contestId: any, contestType: any) {
        const authenticatedMember = this.authenticatedMemberService.getAuthenticatedMember();
        if (contestType === 'HH_CHALLENGE') {
            return this.httpClient.get(`/api/sponsors/${authenticatedMember.sponsorId}/members/tracker-challenge-search`, {
                params: {
                    criteria: queryText,
                    trackerChallengeId: contestId
                }
            });
        }

        return this.httpClient.get(`/api/sponsors/${authenticatedMember.sponsorId}/members/personal-challenge-search`, {
            params: {
                criteria: queryText,
                personalChallengeId: contestId
            }
        });
    }

    sendFriendInvite(externalId: any, memberId: any) {
        const authenticatedMember = this.authenticatedMemberService.getAuthenticatedMember();
        if (!externalId && memberId) {
            return this.httpClient.post(`/api/members/${authenticatedMember.id}/friends/requests`, memberId);
        }
        return this.httpClient.post(`/api/members/${authenticatedMember.id}/friends/requests/send`, externalId);
    }

    isPagingRulesPreventingDefaultExcludingTabNavigation(keyCode: number): boolean {
        const keycode = KeyCode;

        if (keyCode !== keycode.delete && keyCode !== keycode.backspace) {
            if (keyCode <= keycode.selectkey) {
                if ((keyCode < keycode.n0 || keyCode > keycode.n9) && keyCode !== keycode.tab) {
                    return true;
                }
            } else if (keyCode < keycode.numpad0 || keyCode > keycode.numpad9) {
                return true;
            }
        }
        return false;
    }

    searchChallengeGroups(queryText: any, contestId: any, contestType: any, pageNumber: any, pageSize: any) {
        const fromObject = {
            pageSize,
            page: pageNumber,
            searchQuery: queryText
        };
        const params = new HttpParams({ fromObject });
        if (contestType === this.HH_CHALLENGE) {
            return this.httpClient.get(`/api/tracker-challenges/${contestId}/groups`, { params });
        }
        return this.httpClient.get(`/api/personal-challenges/${contestId}/groups`, { params });
    }

    updateMemberDetailsWithFriendshipRequestsInfo(arrayOfMemberDetails: any, overallFeed: any) {
        forkJoin({
            receivedRequests: this.getMyFriendInvites(),
            sentRequests: this.getMySentInvites()
        }).subscribe((data) => {
            this.updateMemberInfo(arrayOfMemberDetails, data);
            if (overallFeed) {
                overallFeed.forEach((item: any) => {
                    this.updateMemberInfo(map(item.replies, 'memberInfo'), data);
                });
            }
        });
    }

    updateMemberInfo(arrayOfMemberInfo: any, data: any) {
        arrayOfMemberInfo.forEach((memberInfo: any) => {
            if (memberInfo) {
                memberInfo.requestSent = data.sentRequests.some((sendRequest: any) => sendRequest.memberId === memberInfo.id);
                memberInfo.requestReceived = data.receivedRequests.some((receivedRequest: any) => receivedRequest.memberId === memberInfo.id);

                if (memberInfo.requestSent) {
                    memberInfo.id = data.sentRequests.find((sendRequest: any) => sendRequest.memberId === memberInfo.id).id;
                }

                if (memberInfo.requestReceived) {
                    memberInfo.id = data.receivedRequests.find((receivedRequest: any) => receivedRequest.memberId === memberInfo.id).id;
                }
            }
        });
    }
}
