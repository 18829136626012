export class VpGoService {
    VpGoCards = {
        JOIN_A_CHALLENGE_CARD_INDEX: 1,
        JOIN_A_TEAM_CARD_INDEX: 2,
        TEAM_PREVIEW_CARD_INDEX: 3,
        CREATE_A_TEAM_CARD_INDEX: 4,
        ADD_PLAYERS_CARD_INDEX: 5,
        CONFIRM_TEAM_CREATION_CARD_INDEX: 6,
        DEVICE_ORDERING_INDEX: 7,
        MAX_BUZZ_ADDRESS_COLLECTION_CARD_INDEX: 8,
        MAX_BUZZ_ADDRESS_CONFIRMATION_CARD_INDEX: 9,
        MAX_BUZZ_ORDER_CONFIRMATION_CARD_INDEX: 10,
        CONNECT_DEVICE_CARD_INDEX: 11,
        HOW_TO_TRACK_CARD_INDEX: 12
    };

    FeaturedChallenges = {

        ChallengeTypes: {
            Organizational: 'Organizational'
        }

    };
}
