<vpg-container
    *ngIf="vm$ | async as vm"
    class="p-none"
>
    <genesis-frontend-loader
        *ngIf="vm.loading"
        class="loader"
    />

    <genesis-frontend-error *ngIf="vm.error"></genesis-frontend-error>

    <ng-container *ngIf="vm.data">
        <genesis-frontend-empty-state
            *ngIf="vm.data.state === coachingModuleState.Unengaged"
            [card]="vm.data"
        />

        <genesis-frontend-base-card
            *ngIf="vm.data.state !== coachingModuleState.Unengaged"
            [card]="vm.data"
        >
            <genesis-frontend-appointment
                *ngIf="vm.data.appointment && vm.data.appointment.hasAppointmentDetails"
                [appointment]="vm.data.appointment"
                [screenreaderText]="vm.data.screenreaderText"
            />
            <genesis-frontend-goals
                *ngIf="vm.data.state === coachingModuleState.Task && vm.data.goals && vm.data.content"
                [goal]="vm.data.goals"
                [content]="vm.data.content"
                [screenreaderText]="vm.data.screenreaderText"
            />
            <genesis-frontend-message
                *ngIf="(vm.data.state === coachingModuleState.Message ||
                    vm.data.state === coachingModuleState.PreEngaged) && vm.data.content"
                [numberOfMessages]="vm.data.message?.numberOfMessages"
                [content]="vm.data.content"
            />
            <genesis-frontend-multimodal-waiting-room
                *ngIf="vm.data.state === coachingModuleState.Pending && vm.data.multimodalWaitingRoom"
                [multimodalWaitingRoom]="vm.data.multimodalWaitingRoom"
                [screenreaderText]="vm.data.screenreaderText"
            />
            <div
                *ngIf="(vm.data.state === coachingModuleState.PastAppointment ||
                    vm.data.state === coachingModuleState.EmptyTask) &&
                    !vm.data.appointment?.hasAppointmentDetails"
                class="body-regular mt-xs"
            >{{ vm.data.content }}</div>
        </genesis-frontend-base-card>
    </ng-container>
</vpg-container>
