import { NgModule } from '@angular/core';

import { DeleteCancelModalComponent } from './delete-cancel-modal.component';
import { VpgrooveModule } from '@engineering/vpgroove-angular';

@NgModule({
    imports: [
        VpgrooveModule
    ],
    declarations: [
        DeleteCancelModalComponent
    ],
    providers: [],
    exports: [
        DeleteCancelModalComponent
    ]
})
export class DeleteCancelModalModule {}
