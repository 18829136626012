import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable, ReplaySubject, Subscription } from 'rxjs';

import { Sponsor } from '../../models';

@Injectable()
export class SponsorService {

    private sponsor: Sponsor = {} as Sponsor;
    static Token = 'SponsorClientService';
    private selectedSponsorReplaySubject: ReplaySubject<Sponsor> = new ReplaySubject<Sponsor>(1);

    constructor(
        private httpClient: HttpClient
    ) {}

    get sponsorReplaySubject(): Observable<Sponsor> {
        return this.selectedSponsorReplaySubject;
    }

    setSponsor(newSponsor: Sponsor): void {
        this.selectedSponsorReplaySubject.next(newSponsor);
        this.sponsor = newSponsor;
    }

    getSponsor(): Sponsor {
        return this.sponsor;
    }

    selectSponsor(sponsor: Sponsor) : Promise<Sponsor> {
        return new Promise<Sponsor>((resolve) => {
            this.getSponsorSettings(sponsor.id)
                .subscribe((sponsorSetting: any) => {
                    this.sponsor.settings = sponsorSetting;
                    this.setSponsor(sponsor);
                    resolve(sponsor);
                });
        });
    }

    getSponsorSettings(sponsorId: number): any {
        return this.httpClient.get(`/api/client-admin/sponsors/${sponsorId}/settings`);
    }

    getBenefitSponsorSettings(sponsorId: number): Observable<any> {
        return this.httpClient.get<boolean>(`/benefits-api/admin/sponsors/${sponsorId}/benefit-sponsor-settings`);
    }

}

@Injectable()
export class SponsorChangeDetection {
    constructor(private zone: NgZone, private sponsorService: SponsorService) {}

    onSponsorChange() {
        return new Observable<Sponsor>((subscriber) => {
            const subscription: Subscription = this.sponsorService.sponsorReplaySubject.subscribe({
                next: (value: Sponsor) => {
                    this.zone.run(() => {
                        subscriber.next(value);
                    });
                },
                complete: () => subscription.unsubscribe()
            });
        });
    }
}
