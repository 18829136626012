import { Injectable } from '@angular/core';
import { first, tap } from 'rxjs/operators';

import { AuthenticatedAgentService } from './authenticated-agent.service';
import { AuthenticatedMemberService } from './authenticated-member.service';
import { environment } from '../../../src/config/environments/environment';
import { Feature } from '../../models';
import { FeatureEnum } from '../../models/enums';
import { AddConfigurationForClient, GlobalReduxStore } from '../../state-management';
import { ClientRoles } from '../enums';

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedUserService {
    private authenticatedUser: any;
    private memberFeatures: Feature[] = [];
    private memberRoles: ClientRoles[] = [];

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private authenticatedAgentService: AuthenticatedAgentService,
        private globalReduxStore: GlobalReduxStore
    ) {
    }

    ready() {
        this.authenticatedMemberService.isUsingKeycloak();
        const isAgent = this.authenticatedAgentService.isUserAgent();
        this.globalReduxStore.dispatchAction(AddConfigurationForClient({ ...environment }));

        if (isAgent) {
            return this.authenticatedAgentService.ready().pipe(
                tap((agent) => {
                    this.authenticatedUser = agent;
                })
            );
        }

        this.authenticatedMemberService.authenticate().subscribe((member) => {
            this.authenticatedUser = member;
            this.memberFeatures = member.sponsor.features;
            this.memberRoles = member.roles;
            this.authenticatedUser.getMemberLanguageCode = this.getMemberLanguageCode();
        });
        return this.authenticatedMemberService.authenticate().pipe(first());
    }

    getAuthenticatedUser(): any {
        return this.authenticatedUser;
    }

    isUserAgent() {
        return this.authenticatedAgentService.isUserAgent();
    }

    isUserMember() {
        return this.authenticatedMemberService.isUserMember();
    }

    hasUser() {
        return !!this.authenticatedUser;
    }

    logout() {
        if (this.isUserAgent()) {
            this.authenticatedAgentService.logout();
            return;
        }

        this.authenticatedMemberService.logout();
        this.authenticatedUser = null;
    }

    hasFeature(feature: FeatureEnum): boolean {
        return this.memberFeatures.some((f) => f.name === feature);
    }

    hasRole(role: ClientRoles): boolean {
        return this.memberRoles.includes(role);
    }

    isCurrentUserRootAdmin() {
        return this.authenticatedUser.roles.some((role: any) => role.type === ClientRoles.RootAdmin);
    }

    getMemberLanguageCode = (): string => {
        let languageCode = 'en-US';

        if (this.authenticatedUser.language) {
            const languageNameArray = this.authenticatedUser.language.split('_');
            languageNameArray[1] = languageNameArray[1].toUpperCase();
            languageCode = languageNameArray.join('-');
        }

        return languageCode;
    };
}
