import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { StatGraphComponent } from './stat-graph.component';
import { VpgrooveModule } from '@engineering/vpgroove-angular';

@NgModule({
    imports: [
        CommonModule,
        VpgrooveModule,
        TranslateModule,
        FormsModule
    ],
    exports: [
        StatGraphComponent
    ],
    declarations: [
        StatGraphComponent
    ],
    providers: []
})
export class StatGraphModule { }
