import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

import { AuthenticatedMemberService, HealthFormDataModel, MemberClass, PcpFormData, HealthFormSubmissionsDataService } from '@genesis-frontend/genesis-utilities';


@Component({
    templateUrl: './health-form-submissions.template.html'
})
export class HealthFormSubmissionsComponent implements OnInit {
    @ViewChild('healthForm') healthForm!: ElementRef;

    member = {} as MemberClass;
    pcpFormData = {} as PcpFormData;
    healthFormData = {} as HealthFormDataModel;
    formCode = '';

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private changeDetector: ChangeDetectorRef,
        private healthFormSubmissionsService: HealthFormSubmissionsDataService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.formCode = this.route.snapshot.params.formCode;
        this.setFormDataAndSubmitForm();
    }

    setFormDataAndSubmitForm() {
        this.member = this.authenticatedMemberService.getMember();

        forkJoin({
            formData: this.healthFormSubmissionsService.getFormData(this.member, this.formCode),
            healthFormSubmissionData: this.healthFormSubmissionsService.getHealthFormSubmissionData(this.member)
        }).subscribe((response: any) => {
            this.pcpFormData = response.formData;
            this.healthFormData = response.healthFormSubmissionData;

            if (!this.pcpFormData) {
                return;
            }

            this.healthFormData.bearerV2 = this.pcpFormData.bearerV2;
            this.changeDetector.detectChanges();

            setTimeout(() => {
                this.healthForm.nativeElement.submit();
            }, this.healthFormSubmissionsService.TIMEOUT);
        });
    }
}
