import { Pipe, PipeTransform } from '@angular/core';

import { CurrencyCode } from '../models/enums/currency-code.enum';

@Pipe({ name: 'currencyAbbreviate' })
export class CurrencyAbbreviatePipe implements PipeTransform {

    transform(currencyCode: CurrencyCode | null) {
        if (!currencyCode) {
            return '';
        }
        switch (currencyCode) {
        case CurrencyCode.SGD :
            return 'SING$';
        case CurrencyCode.GBP :
            return '£';
        case CurrencyCode.EUR :
            return '€';
        case CurrencyCode.MYR :
            return 'RM';
        case CurrencyCode.TWD :
            return 'NT';
        case CurrencyCode.CNY :
        case CurrencyCode.YEN :
            return '¥';
        case CurrencyCode.AED :
            return 'AED';
        case CurrencyCode.ILS :
            return '₪';
        case CurrencyCode.RUP :
            return '₹';
        case CurrencyCode.CAD :
        case CurrencyCode.USD :
        default :
            return '$';
        }
    }
}
