import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { PersonalChallengeCreateModalComponent } from './personal-challenge-create-modal.component';
import { PersonalChallenge } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class PersonalChallengeCreateModalService {
    constructor(
        private bsModalService: BsModalService
    ) {}

    openModal(challengeType?: string, selected?: PersonalChallenge, isCreateMode?: boolean) {
        return this.bsModalService.show(PersonalChallengeCreateModalComponent, {
            class: 'create-edit-personal-challenge-modal modal-lg',
            animated: false,
            initialState: {
                challengeType,
                selected,
                isCreateMode
            }
        });
    }
}
