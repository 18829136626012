import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class SponsorDropdownService {
    constructor(private httpClient: HttpClient) {}

    getSponsors(queryParams: any) {
        const fromObject = {
            name: queryParams.name || '',
            page: queryParams.page,
            pageSize: queryParams.pageSize,
            sortBy: queryParams.sortBy,
            sortDir: queryParams.sortDir
        };

        const params = new HttpParams({ fromObject });
        return this.httpClient.get('/api/client-admin/sponsors', { params, observe: 'response' });
    }

    getDistributors(queryParams: any) {
        const fromObject = {
            name: queryParams.name,
            page: queryParams.page,
            pageSize: queryParams.pageSize,
            sortDir: queryParams.sortDir
        };
        const params = new HttpParams({ fromObject })
            .set('sortBy', queryParams.sortByName)
            .append('sortBy', queryParams.sortById);
        return this.httpClient.get('/api/client-admin/distributors', { params, observe: 'response' });

    }
}
