import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { AccordionClickEventModel } from '../domain/accordion-click-event.model';
import { MemberTopicsOfInterestsService } from '../service/member-topics-of-interests.service';
import { PrivacyProtectionModalService } from '../service/privacy-protection-modal.service';
import { PillarSummaryService, SuggestedPillarTopicService } from '@genesis-frontend/genesis-data-access';
import { AuthenticatedMemberService, Pillar, PillarTopic, WcagService } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'topics-of-interest-modal',
    templateUrl: 'pillar-topics-of-interest-modal.component.html',
    styleUrls: ['pillar-topics-of-interest-modal.component.scss']
})
export class PillarTopicsOfInterestModalComponent implements OnInit, AfterViewInit {
    TAB = 'Tab';

    selected: PillarTopic[] = [];
    pillars: Pillar[] = [];
    recommendedTopics: PillarTopic[] = [];
    activePillar?: Pillar;

    isValid = false;

    @ViewChild('privacyModalLink') privacyModalButton?: ElementRef<HTMLButtonElement>;

    constructor(
        private bsModalRef: BsModalRef,
        private interestStateService: MemberTopicsOfInterestsService,
        private pillarSummaryService: PillarSummaryService,
        private recommendedTopicsService: SuggestedPillarTopicService,
        private privacyProtectionModalService: PrivacyProtectionModalService,
        private authenticatedMemberService: AuthenticatedMemberService,
        private wcagService: WcagService,
        private elementRef: ElementRef
    ) {
        this.elementRef.nativeElement.addEventListener('keydown', (event: any) => {
            this.wcagService.trapFocusInsideElementsContainer(event, this.elementRef, this.TAB);
        });
    }

    ngOnInit(): void {
        this.interestStateService.get().subscribe((state) => {
            this.selected = state;
            this.isValid = this.isAnyTopicSelected();
        });

        const memberId = this.authenticatedMemberService.getAuthenticatedMember().id;
        this.pillarSummaryService.getAllPillarSummaries(memberId).subscribe((pillars) => {
            this.pillars = pillars;

            this.recommendedTopicsService
                .getSuggestedForMember(this.authenticatedMemberService.getAuthenticatedMember())
                .subscribe((recommendedTopics) => {
                    this.recommendedTopics = this.withProvidedTranslations(recommendedTopics, pillars);
                });
        });
    }

    ngAfterViewInit(): void {
        this.focusCloseButton();
    }

    onTopicSelect(topicSelectionList: PillarTopic[]): void {
        this.selected = topicSelectionList;
        this.isValid = this.isAnyTopicSelected();
    }

    onAccordionClick(acClickEvent: AccordionClickEventModel<Pillar>): void {
        const { active, data } = acClickEvent;
        if (active) {
            this.activePillar = data;
            this.scrollActiveAccordionToView(data as Pillar);
            return;
        }

        this.activePillar = undefined;
        return;
    }

    openPrivacyProtectionModal(event: Event): void {
        if (event.type === 'keyup' && (event as KeyboardEvent).key === 'Enter') {
            (event.target as HTMLInputElement).click();
            return;
        }

        this.privacyProtectionModalService.open().subscribe(() => {
            this.privacyModalButton?.nativeElement.focus();
        });
    }

    save(): void {
        this.interestStateService.save(this.selected)
            .subscribe((selected) => {
                this.selected = selected;
                this.bsModalRef.hide();
            });
    }

    close(): void {
        this.bsModalRef.hide();
    }

    private scrollActiveAccordionToView(pillar: Pillar) {
        setTimeout(() => {
            const accordionHeaderElement = document.getElementById(`pillar-accordion-header-${pillar.id}`);
            accordionHeaderElement?.scrollIntoView(true);
        });
    }

    private isAnyTopicSelected(): boolean {
        return Boolean(this.selected && this.selected.length);
    }

    private withProvidedTranslations(recommendedTopics: PillarTopic[], pillars: Pillar[]): PillarTopic[] {
        if (!recommendedTopics || !recommendedTopics.length) {
            return [];
        }

        const allPillarTopics = pillars
            .map((pillar) => pillar.topics)
            .reduce((previousValue, currentValue) => {
                return previousValue.concat(currentValue);
            });

        return recommendedTopics.map((recommendedTopic) => {
            const translatedTopic = allPillarTopics.find((topic) => topic.id === recommendedTopic.id);

            if (!translatedTopic) {
                return recommendedTopic;
            }

            const { name, description } = translatedTopic;
            return { ...recommendedTopic, name, description };
        });
    }

    focusCloseButton() {
        setTimeout(() => {
            document.getElementById('topics-of-interest-cancel-btn')?.focus();
        }, 10);
    }
}
