<div>
    <h3
        [id]="'translations'"
        class="margin-bottom-10"
    >Translations
    </h3>
    <p
        [id]="'translation-text'"
        class="margin-bottom-10"
    >
        {{ addTranslationText }}
    </p>
    <a
        vpg-link
        class="margin-bottom-20"
        [id]="'add-translation-button'"
        [size]="'large'"
        [bold]="true"
        (click)="addTranslationClickFunction.call()"
    >
        <vpg-icon-next
            [icon]="'fa fa-plus-circle'"
            [size]="'large'"
        ></vpg-icon-next>
        Add Translations
    </a>
</div>

<div *ngIf="languages.length">
    <p [id]="'custom-translations-text'"> Custom translations have been added for the following languages: </p>
    <table
        [id]="'custom-translations-table'"
        class="table table-striped table-border"
        aria-label="Translations"
    >
        <thead
            [id]="'custom-translations-table-header'"
            class="h4"
        >
        <tr class="h4">
            <th [id]="'custom-translations-table-header-language'">Language</th>
            <th [id]="'custom-translations-table-header-options'">Options</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let language of languages; index as i">
            <td [id]="'custom-translations-table-row-' + language.code">
                {{ language.englishNative }}
            </td>
            <td>
                <a
                    vpg-link
                    [id]="'custom-translations-table-row-edit-link' + language.code"
                    [size]="'medium'"
                    [bold]="false"
                    [tooltip]="'Edit'"
                    (click)="editTranslationOutput.emit(language)"
                >
                    <vpg-icon-next [icon]="'edit'"></vpg-icon-next>
                </a>
                <a
                    vpg-link
                    [id]="'custom-translations-table-row-delete-link-' + language.code"
                    [size]="'medium'"
                    [bold]="false"
                    [tooltip]="'Delete'"
                    (click)="openModal(language)"
                >
                    <vpg-icon-next [icon]="'far fa-trash-alt'"></vpg-icon-next>
                </a>
            </td>
        </tr>
        </tbody>
    </table>

    <delete-cancel-modal
        [modalTitle]="'Delete &quot;' + selectedLanguage?.englishNative + '&quot; custom translations?'"
        [modalText]="'This custom translation will be permanently removed from the list.'"
        [modalOpen]="modalOpen"
        (openChangeOutput)="modalOpen = $event"
        (deleteOutput)="deleteLanguage()"
    >
    </delete-cancel-modal>
</div>
