import { Injectable } from '@angular/core';

import {
    LocalizationProvider,
    Localization,
    LocalizationService
} from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class ShellLocalizationProvider extends LocalizationProvider {

    constructor(
        private localizationService: LocalizationService
    ) {
        super();
    }

    getLocalization(): Promise<Localization> {
        return new Promise<Localization>((resolve) => {
            const localization = this.localizationService.getDefaultLocalization();
            resolve(localization);
        });
    }
}
