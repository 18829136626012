import { Pipe, PipeTransform } from '@angular/core';

import { CholesterolUnit } from '../models/enums/cholesterol-unit.enum';
import { ConversionService } from '../services/conversion.service';

@Pipe({ name: 'triglyceridesLabel' })
export class TriglyceridesLabelPipe implements PipeTransform {

    constructor(
        private conversionService: ConversionService
    ) {}

    transform(value: any, isMetric: boolean, precision: number): string | null {
        const CONTEXT = 'Triglyceride';
        const convertedUnit = isMetric ? CholesterolUnit.Metric : CholesterolUnit.Imperial;

        if (!value) {
            return null;
        }

        const convertedValue = this.conversionService.convert(value, CholesterolUnit.Imperial, convertedUnit, CONTEXT);
        return convertedValue.toFixed(precision) + ' ' + convertedUnit;
    }
}
