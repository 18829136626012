export enum ChallengeTypeUppercase {
    Destination = 'DESTINATION',
    Basic = 'BASIC',
    Staged = 'STAGED',
    PromotedHealthyHabit = 'PHHC',
    PersonalSteps = 'PERSONAL_STEPS',
    PersonalHealthyHabit = 'PERSONAL_HH',
    Spotlight = 'SPOTLIGHT',
    Holistic = 'HOLISTIC'
}
