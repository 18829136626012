import { Component, EventEmitter, Input, Output } from '@angular/core';

import { PersonalChallengeTemplate } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'genesis-features-personal-challenge-rules-template',
    templateUrl: './personal-challenge-rules-template.component.html',
    styleUrls: ['./personal-challenge-rules-template.component.scss']
})
export class PersonalChallengeRulesTemplateComponent {
    @Input() challengeTemplate = {} as PersonalChallengeTemplate;
    @Input() displaySingle = false;
    @Output() setStep: EventEmitter<any> = new EventEmitter<any>();

    ONE = 1;

    setStepEmit(value: number) {
        this.setStep.emit(value);
    }
}
