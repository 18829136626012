import { Injectable } from '@angular/core';

import { BlockerChainService, BlockerResponseModel, MemberClass, Product, SharedServiceLocator, SponsorSetting } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class BlockerExecutionService {
    blockerChainResolved: boolean | undefined;
    hasBlockersActive: boolean | undefined;
    constructor(
        private sharedServiceLocator: SharedServiceLocator,
        private blockerChainService: BlockerChainService
    ) {}
    executeBlockerQueue(sponsorSettings: SponsorSetting, homeProduct: any, member: MemberClass) {
        return new Promise<boolean>((resolve) => {
            this.sharedServiceLocator.getAsync('dialogService').then(async(dialogService) => {
                await Promise.all(
                    [
                        this.executeBlockerWrapper(dialogService.checkAgreementsBlocker, homeProduct, false),
                        this.executeBlockerWrapper(dialogService.checkPasswordExpirationBlocker),
                        this.executeBlockerWrapper(dialogService.checkSecurityQuestionsBlocker),
                        this.executeBlockerWrapper(dialogService.checkMemberEmailOptInBlocker),
                        this.executeBlockerWrapper(dialogService.checkCellPhoneNumberBlocker),
                        this.executeBlockerWrapper(dialogService.checkPillarsSelectionBlocker),
                        this.executeBlockerWrapper(dialogService.checkSurveyInterruptBlocker, member.id),
                        this.executeBlockerWrapper(dialogService.checkRewardsSpouseConsentBlocker)
                    ]
                ).then((responses: BlockerResponseModel[]) => {
                    const [
                        agreements,
                        passwordExpiration,
                        securityQuestions,
                        memberEmailOptIn,
                        cellPhoneNumber,
                        pillarsSelection,
                        surveyInterrupt,
                        rewardsSpouseConsent
                    ] = responses;
                    this.hasBlockersActive = true;

                    if (this.hasBlockersActive) {
                        this.blockerChainService.addPromiseReturningFunction(dialogService.showAgreementsBlocker, [agreements, homeProduct]);
                        this.blockerChainService.addPromiseReturningFunction(dialogService.showPasswordExpirationBlocker, [passwordExpiration]);
                        this.blockerChainService.addPromiseReturningFunction(dialogService.showSecurityQuestionsBlocker, [securityQuestions]);
                        this.blockerChainService.addPromiseReturningFunction(dialogService.showMemberEmailOptInBlocker, [memberEmailOptIn]);
                        this.blockerChainService.addPromiseReturningFunction(dialogService.showCellPhoneNumberBlocker, [false, cellPhoneNumber]);
                        this.blockerChainService.addPromiseReturningFunction(dialogService.showPillarsSelectionBlocker, [pillarsSelection]);

                        if (homeProduct.product === Product.BasePlatform || homeProduct.product === Product.Wellness) {
                            this.blockerChainService.addPromiseReturningFunction(dialogService.showSurveyInterruptBlocker, [surveyInterrupt]);
                            this.blockerChainService.addPromiseReturningFunction(dialogService.showRewardsSpouseConsentBlocker, [rewardsSpouseConsent]);
                        }
                    }
                });

                this.blockerChainService.executeChain().then((result: boolean) => {
                    resolve(result);
                    this.blockerChainService.clearState();
                    this.clearModalState();
                    this.executeInterruptBlockers(dialogService, member, sponsorSettings);
                });
            });
        });
    }


    private executeInterruptBlockers(dialogService: any, member: MemberClass, sponsorSettings: SponsorSetting) {
        Promise.all([
            dialogService.checkGlobalChallengesBlocker(),
            dialogService.checkForChallengeInterruptBlocker(member.id, sponsorSettings?.onboardingChallengeBlocker)
        ]).then((responses: BlockerResponseModel[]) => {
            const [
                globalChallenges, challengeInterrupt
            ] = responses;
            if (globalChallenges?.showBlocker || challengeInterrupt?.showBlocker) {
                this.blockerChainService.addPromiseReturningFunction(dialogService.showGlobalChallengesBlocker, [globalChallenges]);
                this.blockerChainService.addPromiseReturningFunction(dialogService.redirectToContestInterruptPage, [challengeInterrupt]);
            }
            this.blockerChainService.executeChain();
        });
    }

    private clearModalState() {
        document.getElementById('loaded')?.remove();
        document.querySelector('body')?.classList.remove('modal-open');
        document.querySelector('body')?.removeAttribute('style');
        document.querySelector('.modal-backdrop')?.remove();
    }

    private executeBlockerWrapper(fn: any, ...args: any[]) {
        const promise = fn(...args);
        promise.then((response: BlockerResponseModel) => {
            if (response?.showBlocker) {
                this.hasBlockersActive = true;
            }
        });
        return promise;
    }
}
