import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UpdateLangAttributeService {
    currentLang = '';
    htmlElement = document.querySelector('html');

    updateLangAttr(languageName: string): void {
        const defaultLang = 'en';

        if (!languageName) {
            this.htmlElement?.setAttribute('lang', defaultLang);
            return;
        }
        switch (languageName) {
        case 'zh-CN':
            this.currentLang = 'zh-Hans';
            break;
        case 'zh-HANT':
            this.currentLang = 'zh-Hant';
            break;
        default:
            this.currentLang = languageName.split('-')[0];
        }
        this.htmlElement?.setAttribute('lang', this.currentLang);
    }
}
