export enum LiveServicesRoutes {
    Appointments = 'live-services-coaching/appointments',
    Booking = 'live-services-coaching/schedule-appointment',
    CoachBio = 'live-services-coaching/coach-bio',
    CoachingHub = 'live-services-coaching/coaching-hub',
    SingleTopicPage = 'live-services-coaching/landing-page',
    LegacyHome = 'live-services-coaching',
    MyPastSessions = 'live-services-coaching/my-past-sessions',
    Topics = 'live-services-coaching/topics',
    WhatToExpect = 'live-services-coaching/what-to-expect',
    WhyCoaching = 'live-services-coaching/why-coaching',
    MultiModalSelection = 'live-services-coaching/appointment-type'
}
