import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
    AddPillarSettings,
    GlobalReduxStore,
    PillarSettings,
    PillarSummaryVm,
    PillarTypes,
    sortBy,
    uniqBy
} from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class PillarsService {
    constructor(private httpClient: HttpClient, private globalReduxStore: GlobalReduxStore) {}

    mapPillarTypes(pillars: PillarSummaryVm[]) {
        return pillars.map((value: PillarSummaryVm) => ({
            type: value.type,
            display: value.typeDisplay
        }));
    }

    sortPillarTypes(pillarTypes: PillarTypes[]) {
        const types = uniqBy(pillarTypes, 'type');
        return sortBy(types, ['type']);
    }

    getPillarsSettings(memberId: number): Observable<PillarSettings> {
        const pillarSettings = this.globalReduxStore.getInitialState().pillarSettings;
        if (pillarSettings) {
            return of(pillarSettings);
        }
        return this.httpClient
            .get<PillarSettings>(this.pillarsSettingsResource(memberId))
            .pipe(tap((settings) => this.globalReduxStore.dispatchAction(AddPillarSettings(settings))));
    }

    getHabitsPillarsSummary(memberId: number) {
        return this.httpClient.get<PillarSummaryVm[]>(this.habitsPillarsSummaryResource(memberId));
    }

    habitsPillarsSummaryResource = (memberId: number) => {
        return `/api/members/${memberId}/pillars/habits-summary`;
    };

    pillarsSettingsResource = (memberId: number) => {
        return `/api/members/${memberId}/pillars/settings`;
    };
}
