import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PickerFileMetadata } from 'filestack-js';

import { GlobalChallengeCardCarouselDataModel } from '../../../interface/global-challenges-card-carousel-data.model';
import { VpGoService } from '../../vp-go.service';
import { BackgroundImagesService } from '@genesis-frontend/genesis-data-access';
import { Feature, FeatureEnum, FeatureService, FilePickerService } from '@genesis-frontend/genesis-utilities';
@Component({
    templateUrl: './create-a-team.template.html',
    styleUrls: ['./create-a-team.component.scss']
})
export class GlobalChallengesCreateTeamCardComponent {
    TEAM_NAME_MAX_LENGTH = 35;
    RALLY_CRY_MAX_LENGTH = 140;

    showLeftArrow = false;
    showRightArrow = false;
    photoUploadEnabledOnSponsor = false;
    showCarousel = true;

    photos: any[] | undefined;

    slickSettings = {
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3
    };

    imageUploadError = this.$translate.instant('ImageUploadError');

    constructor(
        private backgroundImagesService: BackgroundImagesService,
        private filePickerService: FilePickerService,
        private featureService: FeatureService,
        private changeDetector: ChangeDetectorRef,
        @Inject('data') public data: GlobalChallengeCardCarouselDataModel,
        public vpGoService: VpGoService,
        private $translate: TranslateService
    ) {
    }

    ngOnInit() {

        this.data.isCreateATeamCardVisited = true;
        this.showLeftArrow = this.data.teams && this.data.teams.length > 0 || this.data.teamInvites.length > 0;
        this.showRightArrow = this.data.isAddPlayersCardVisited;

        this.featureService.getMemberFeatures().subscribe((response: Feature[]) => {
            this.featureService.setMemberFeatures(response);
            this.photoUploadEnabledOnSponsor = this.featureService.hasFeature(FeatureEnum.EnablePhotoUpload);
        });

        this.getTeamPhotos();
    }

    togglePublicTeam() {
        this.data.isTeamPrivate = !this.data.isTeamPrivate;
    }

    goToPreviousCard() {
        this.data.goToCard(this.vpGoService.VpGoCards.JOIN_A_TEAM_CARD_INDEX);
    }

    initializeTeamImage() {
        if (!this.photoUploadEnabledOnSponsor && this.photos?.length) {
            this.data.teamImage = this.photos[0].imageUrl;
        }
    }

    getTeamPhotos() {
        this.backgroundImagesService.getTeamPhotos().subscribe((response) => {
            this.photos = response;
            this.initializeTeamImage();
        });
    }

    selectImageFromLibrary(selectedPhoto: string) {
        this.data.teamImage = selectedPhoto;
    }

    removeSelectedImage() {
        this.data.teamImage = '';
    }

    uploadImage() {

        const pickerOptions = this.filePickerService.getDefaultPickerOptions(undefined, 'challenges-team');
        pickerOptions.imageMin = [660, 270];
        pickerOptions.exposeOriginalFile = true;
        pickerOptions.onFileSelected = (file) => this.validateImageDimensions(file);

        const transformOptions = this.filePickerService.getTransformationOptions();
        const pickerUploadOptions = {
            ...pickerOptions,
            transformations: {
                ...transformOptions
            }
        };

        this.filePickerService.upload(pickerUploadOptions, (files: PickerFileMetadata[]) => {
            if (files) {
                this.data.teamImage = files[0].url;
            } else {
                throw new Error('uploadError');
            }
        });
    }

    validateImageDimensions(file: PickerFileMetadata) {
        const imageUploadError = this.imageUploadError;
        return new Promise(function(resolve, reject) {
            const minWidth = 660;
            const minHeight = 270;
            const blob = file.originalFile as any;
            // Get an object URL for the local file
            const url = URL.createObjectURL(blob);
            // Create an image and load the object URL
            const img = new Image();
            img.src = url;

            img.onload = function() {
                const that = this as unknown as {naturalWidth: any, naturalHeight: any};
                URL.revokeObjectURL(url);

                if (that.naturalWidth < minWidth || that.naturalHeight < minHeight) {
                    reject(imageUploadError);
                }
                resolve(true);
            };
        });
    }

    goToNextCard() {
        this.showCarousel = false;
        this.detectChanges();
        this.data.nextCard();
    }

    detectChanges() {
        this.changeDetector.reattach();
        this.changeDetector.detectChanges();
        setTimeout(() => {
            this.changeDetector.detach();
        });
    }
}
