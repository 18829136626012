import { DateErrors } from '../models/interfaces/date-errors.model';

export function validateDates(startDate: string, endDate: string): DateErrors {
    const errors: any = [];
    const date_format = 'MM-DD-YYYY';

    const momentStartDate = startDate ? new Date(startDate) : null;
    const momentEndDate = endDate ? new Date(endDate) : null;

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    if (datesAreSameDay(momentStartDate, momentEndDate)) {
        errors['startDate'] = 'Start Date must be at least one day before End Date';
        errors['endDate'] = 'End Date must be at least one day after Start Date';
    }

    if (startDate) {
        if (isDateValid(momentStartDate) && isDateBefore(momentEndDate, momentStartDate)) {
            errors['startDate'] = 'Start Date cannot be after End Date';
            errors['endDate'] = 'End Date cannot be before Start Date';
        }

        if (!isDateSameOrAfter(momentStartDate, today)) {
            errors['startDate'] = 'Start Date cannot be in the past';
        }
    }

    if (startDate && !isDateValid(momentStartDate)) {
        errors['startDate'] = `Please input start date in the following format: ${date_format}`;
    }

    if (endDate && !isDateValid(momentEndDate)) {
        errors['endDate'] = `Please input end date in following format: ${date_format}`;
    }

    return errors;
}

function isDateValid(date: Date | null): boolean {
    return date !== null && !isNaN(date.getTime());
}

function isDateBefore(startDate: Date | null, endDate: Date | null) {
    return startDate !== null && endDate !== null && startDate.getTime() < endDate.getTime();
}

function isDateSameOrAfter(startDate: Date | null, endDate: Date | null) {
    return startDate !== null && endDate !== null && startDate.getTime() >= endDate.getTime();
}

function datesAreSameDay(startDate: Date | null, endDate: Date | null) {
    return startDate !== null && endDate !== null && startDate.toISOString() === endDate.toISOString();
}
