import { Injectable } from '@angular/core';

import { ToastService } from '@engineering/vpgroove-angular';

@Injectable({
    providedIn: 'root'
})
export class ToasterService {
    legacyToasterVariant: { success: string; error: string; warning: string } = { success: 'success', error: 'error', warning: 'warning' };

    constructor(private readonly toast: ToastService) {}

    public pop(variant: string, message: string) {
        switch (variant) {
        case this.legacyToasterVariant.success:
            this.toast.positive({ id: variant, icon: 'check', text: message });
            break;
        case this.legacyToasterVariant.error:
            this.toast.negative({ id: variant, icon: 'exclamation-triangle', text: message });
            break;
        case this.legacyToasterVariant.warning:
            this.toast.warning({ id: variant, icon: 'exclamation-triangle', text: message });
            break;
        }
    }
}
