import { Component, Input, SimpleChanges } from '@angular/core';


@Component({
    selector: 'vp-flipclock-item',
    templateUrl: './flip-item.template.html',
    styleUrls: ['./flip-item.scss']
})
export class FlipItemComponent {

    @Input() value = 0;
    numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

    currentValue!: number;
    previousValue!: number;

    ngOnChanges(changes: SimpleChanges): void {
        this.currentValue = changes.value.currentValue >= 0 ? changes.value.currentValue : 9;
        this.previousValue = changes.value.previousValue >= 0 ? changes.value.previousValue : 9;
    }

    IsActive(number: number) {
        return this.currentValue === number;
    }

    IsBefore(number: number) {
        return this.previousValue !== this.currentValue && number === this.previousValue;
    }

    IsAnimated() {
        return this.previousValue !== this.currentValue;
    }
}
