import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NotificationPaneService {

    private readonly notificationsUiId = 'single-spa-application:notifications-ui';
    private readonly notificationWrapperId = 'notification-wrapper';
    private readonly backdropId = 'notification-pane-backdrop';
    private readonly newBackdrop = 'new-notification-pane-backdrop';
    private readonly siteBodyId = 'vp-site-body';
    private readonly siteBodyClass = 'vp-hide-scrollbar';
    private readonly backdropClass = 'show-notification-pane-backdrop';
    private readonly newBackdropClass = 'show-new-notification-pane-backdrop';
    private readonly notifPanelClass = 'show-notif-panel';
    private readonly notifPaneOpenedClass = 'notification-pane-opened';
    private readonly SUPPORT_BTN_ID = 'SupportBtn';
    private readonly CHAT_BTN_ID = 'ChatBtn';
    private readonly STYLE_VISIBLE = 'visible';
    private FOCUS = '0';
    private UNFOCUSED = '-1';
    private notificationsPaneVisible = false;
    private skipEvent = false;

    toggleNotificationPane(): void {
        const notificationsPaneBackdrop = document.getElementById(this.backdropId) as HTMLElement;
        if (notificationsPaneBackdrop) {
            notificationsPaneBackdrop.classList.toggle(this.backdropClass);
            if (!this.skipEvent) {
                notificationsPaneBackdrop.addEventListener('click', this.closeOnPaneBackdropClick.bind(this));
            }
        }
        const newNotificationsPaneBackdrop = document.getElementById(this.newBackdrop) as HTMLElement;
        if (newNotificationsPaneBackdrop) {
            newNotificationsPaneBackdrop.classList.toggle(this.newBackdropClass);
            newNotificationsPaneBackdrop.classList.toggle(this.newBackdrop);
            if (!this.skipEvent) {
                newNotificationsPaneBackdrop.addEventListener('click', this.closeOnPaneBackdropClick.bind(this));
            }
        }
        const notificationsPane = document.getElementById(this.notificationsUiId) as HTMLElement;
        if (notificationsPane) {
            if (!notificationsPane.classList.toggle(this.notifPanelClass)) {
                this.setNotificationsFocus(this.UNFOCUSED);
                this.turnOffAndOnScreenReader(this.UNFOCUSED);
                this.setNavigationFocus(this.FOCUS);
                this.turnOffAndOnScreenReaderForNavigation(this.UNFOCUSED);
            } else {
                this.setNotificationsFocus(this.FOCUS);
                this.turnOffAndOnScreenReader(this.FOCUS);
                this.setNavigationFocus(this.UNFOCUSED);
                this.turnOffAndOnScreenReaderForNavigation(this.FOCUS);
            }

        }
        const siteBody = document.getElementById(this.siteBodyId) as HTMLElement;
        if (siteBody) {
            siteBody.classList.toggle(this.siteBodyClass);
        }

        const notificationsPaneBackdropElement = document.getElementById('notification-pane-backdrop') as HTMLElement;
        const newNotificationsPaneBackdropElement = document.getElementById('new-notification-pane-backdrop') as HTMLElement;

        this.notificationsPaneVisible = notificationsPaneBackdropElement?.classList.contains('show-notification-pane-backdrop') || newNotificationsPaneBackdropElement?.classList.contains('show-new-notification-pane-backdrop');

        const supportButtonElement = document.getElementById(this.SUPPORT_BTN_ID) as HTMLElement;
        const chatButtonElement = document.getElementById(this.CHAT_BTN_ID) as HTMLElement;

        if (supportButtonElement) {
            if (this.notificationsPaneVisible) {
                supportButtonElement.setAttribute('tabindex', this.UNFOCUSED);
                supportButtonElement.setAttribute('aria-hidden', 'true');
            } else {
                supportButtonElement.setAttribute('tabindex', this.FOCUS);
                supportButtonElement.setAttribute('aria-hidden', 'false');
            }
        }

        if (chatButtonElement) {
            if (this.notificationsPaneVisible) {
                chatButtonElement.setAttribute('tabindex', this.UNFOCUSED);
                chatButtonElement.setAttribute('aria-hidden', 'true');
            } else {
                chatButtonElement.setAttribute('tabindex', this.FOCUS);
                chatButtonElement.setAttribute('aria-hidden', 'false');
            }
        }

    }

    turnOffNotificationPane(): void {
        const notificationsPaneBackdrop: HTMLElement = document.getElementById(this.backdropId) as HTMLElement;
        this.setNotificationsFocus(this.UNFOCUSED);
        this.turnOffAndOnScreenReader(this.UNFOCUSED);
        this.turnOffAndOnScreenReaderForNavigation(this.UNFOCUSED);
        this.setNavigationFocus(this.FOCUS);
        if (notificationsPaneBackdrop) {
            notificationsPaneBackdrop.classList.remove(this.backdropClass);
        }
        const newNotificationsPaneBackdrop: HTMLElement = document.getElementById(this.newBackdrop) as HTMLElement;
        if (newNotificationsPaneBackdrop) {
            newNotificationsPaneBackdrop.classList.remove(this.newBackdropClass);
            newNotificationsPaneBackdrop.classList.remove(this.newBackdrop);
        }
        const notificationsPane: HTMLElement = document.getElementById(this.notificationsUiId) as HTMLElement;
        const notificationsWrapper = document.getElementById(this.notificationWrapperId) as HTMLElement;
        if (notificationsPane) {
            notificationsPane.classList.remove(this.notifPanelClass);
            notificationsWrapper.classList.remove(this.notifPaneOpenedClass);
        }
        const siteBody: HTMLElement = document.getElementById(this.siteBodyId) as HTMLElement;
        if (siteBody) {
            siteBody.classList.remove(this.siteBodyClass);
        }
        this.notificationsPaneVisible = false;

        const supportButtonElement = document.getElementById(this.SUPPORT_BTN_ID);
        const chatButtonElement = document.getElementById(this.CHAT_BTN_ID);

        if (supportButtonElement) {
            supportButtonElement.style.visibility = this.STYLE_VISIBLE;
            supportButtonElement.setAttribute('tabindex', this.FOCUS);
            supportButtonElement.setAttribute('aria-hidden', 'false');
        }

        if (chatButtonElement) {
            chatButtonElement.style.visibility = this.STYLE_VISIBLE;
            chatButtonElement.setAttribute('tabindex', this.FOCUS);
            chatButtonElement.setAttribute('aria-hidden', 'false');
        }
    }

    setNotificationsFocus(tabindex: string): void {
        const focusElements: HTMLCollectionOf<Element> = document.getElementsByClassName('notification-element');
        // @ts-ignore
        for (const focusElement of focusElements) {
            const buttons = focusElement.getElementsByTagName('button');
            const links = focusElement.getElementsByTagName('a');
            if (buttons.length) {
                for (const button of buttons) {
                    button.setAttribute('tabindex', tabindex);
                }
            } else if (links.length) {
                for (const link of links) {
                    link.setAttribute('tabindex', tabindex);
                }
            } else {
                focusElement.setAttribute('tabindex', tabindex);
            }
        }
    }

    closeOnPaneBackdropClick(): void {
        this.skipEvent = true;
        this.toggleNotificationPane();
    }

    setModalProperty(tabindex: string): void {
        const mainNotificationContainer: HTMLCollectionOf<Element> = document.getElementsByClassName('main-notification-container');
        const arrayOfElements = Array.from(mainNotificationContainer);
        const mainDiv = arrayOfElements[0];

        if (tabindex === this.FOCUS) {
            mainDiv.setAttribute('aria-modal', 'true');
        }

        if (tabindex === this.UNFOCUSED) {
            mainDiv.setAttribute('aria-modal', 'false');
        }
    }

    setNavigationFocus(tabindex: string): void {
        const focusElements: HTMLCollectionOf<Element> = document.getElementsByClassName('navigation-element');
        // @ts-ignore
        for (const focusElement of focusElements) {
            const buttons = focusElement.getElementsByTagName('button');
            if (buttons.length) {
                for (const button of buttons) {
                    button.setAttribute('tabindex', tabindex);
                }
            } else {
                focusElement.setAttribute('tabindex', tabindex);
            }
        }
    }

    turnOffAndOnScreenReader(notificationsStatus: string): void {
        const focusElements: HTMLCollectionOf<Element> = document.getElementsByClassName('notifications-wcag');
        // @ts-ignore
        for (const focusElement of focusElements) {
            if (notificationsStatus === this.UNFOCUSED) {
                focusElement.setAttribute('aria-hidden', 'true');
            } else if (notificationsStatus === this.FOCUS) {
                focusElement.setAttribute('aria-hidden', 'false');
            }
        }
    }

    turnOffAndOnScreenReaderForNavigation(notificationsStatus: string): void {
        const focusElements: HTMLCollectionOf<Element> = document.getElementsByClassName('navigation-element');
        // @ts-ignore
        for (const focusElement of focusElements) {
            if (notificationsStatus === this.UNFOCUSED) {
                focusElement.setAttribute('aria-hidden', 'false');
            } else if (notificationsStatus === this.FOCUS) {
                focusElement.setAttribute('aria-hidden', 'true');
            }
        }
    }
}
