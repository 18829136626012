export enum EmailPreferenceType {
    GettingStarted = 'GettingStarted',
    FriendInvitations = 'FriendInvitations',
    GroupInvitations = 'GroupInvitations',
    PersonalChallenges = 'PersonalChallenges',
    SponsorChallenges = 'SponsorChallenges',
    SurveyReminders = 'SurveyReminders',
    CompanyUpdates = 'CompanyUpdates',
    ProgramUpdates = 'ProgramUpdates'
}
