import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CoachingCardComponent } from './coaching-card.component';
import { AppointmentComponent } from './components/appointment/appointment.component';
import { BaseCardComponent } from './components/base-card/base-card.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { ErrorComponent } from './components/error/error.component';
import { GoalsComponent } from './components/goals/goals.component';
import { LoaderComponent } from './components/loader/loader.component';
import { MessageComponent } from './components/message/message.component';
import { MultimodalWaitingRoomComponent } from './components/multimodal-waiting-room/multimodal-waiting-room.component';
import { VpgrooveModule } from '@engineering/vpgroove-angular';
import { EventBusModule, GenesisPipesModule } from '@genesis-frontend/genesis-utilities';

@NgModule({
    declarations: [
        CoachingCardComponent,
        BaseCardComponent,
        EmptyStateComponent,
        ErrorComponent,
        AppointmentComponent,
        GoalsComponent,
        MessageComponent,
        MultimodalWaitingRoomComponent,
        LoaderComponent
    ],
    exports: [
        CoachingCardComponent,
        BaseCardComponent,
        EmptyStateComponent,
        ErrorComponent,
        AppointmentComponent,
        GoalsComponent,
        MessageComponent,
        MultimodalWaitingRoomComponent,
        LoaderComponent
    ],
    imports: [
        CommonModule,
        GenesisPipesModule,
        VpgrooveModule,
        TranslateModule,
        EventBusModule,
        NgOptimizedImage
    ],
    providers: [

    ]
})
export class CoachingCardModule { }
