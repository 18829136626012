export enum ProgramMemberStatus {
    COMPLETED = 'COMPLETED',
    ENROLLED_PENDING_COACH_CALL = 'ENROLLED_PENDING_COACH_CALL',
    ELIGIBLE_READY_TO_ENROLL = 'ELIGIBLE_READY_TO_ENROLL',
    ENROLLED_NEED_COACH = 'ENROLLED_NEED_COACH',
    ENROLLED_PENDING_START_DATE = 'ENROLLED_PENDING_START_DATE',
    NOT_ELIGIBLE = 'NOT_ELIGIBLE',
    PARTICIPANT_ACTIVE = 'PARTICIPANT_ACTIVE',
    PARTICIPANT_DROPPED = 'PARTICIPANT_DROPPED',
    STARTED_SURVEY = 'STARTED_SURVEY',
    TAKEN_ELIGIBLE_SURVEY = 'TAKEN_ELIGIBLE_SURVEY'

}
