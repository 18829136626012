<link rel="preconnect" [href]="microfrontendUrl"/>
<spa-module [module]="moduleRegistry.modules.blocker"></spa-module>
<trustarc></trustarc>
<div class="nr-tracking-pixel" data-nr-id="member-shell"></div>
<div class="app-holder" *ngIf="blockerChainResolved">
    <div class="sticky-responsive-navbar">
        <div [hidden]="hideNavigation">

            <spa-module [module]="moduleRegistry.modules.navigationMenu"></spa-module>
            <genesis-frontend-notifications></genesis-frontend-notifications>


            <div *ngIf="notificationPaneEnabled">
                <div id="new-notification-pane-backdrop" [attr.aria-hidden]="true">
                </div>
                <div id="notification-wrapper"
                    class="responsive-nav-enabled new-notification-wrapper">
                    <spa-module [module]="moduleRegistry.modules.notifications" [delay]="5000" className="new-notif-panel"></spa-module>
                </div>
            </div> 
            <div *ngIf="messageCenterPaneEnabled">
                <div id="new-message-center-pane-backdrop" [attr.aria-hidden]="true">
                </div>
                <div id="message-center-wrapper"
                    class="responsive-message-center-enabled new-message-center-wrapper">
                    <spa-module [module]="moduleRegistry.modules.messageCenter" [delay]="5000" className="new-message-center-panel"></spa-module>
                </div>
            </div>
        </div>
    </div>

    <div
        id="page-wrapper"
        class="background-repeater collapsed"
        [ngClass]="{
            'with-gradient': isClaims,
            'with-navigation': !hideNavigation,
            'with-transform': isTransformUI
        }">
        <div class="page-content page-content-ease-in main-text-color">
            <router-outlet></router-outlet>

            <div id="single-spa-application:social-ui"></div>
            <div id="single-spa-application:member-profile"></div>
            <div id="single-spa-application:live-services-ui"></div>
            <div id="single-spa-application:rewards-ui"></div>
            <div id="single-spa-application:health-ui"></div>
            <div id="single-spa-application:stats-page"></div>
            <div id="single-spa-application:devices-and-apps"></div>
            <div class="pos-rel" id="single-spa-application:benefits"></div>
            <div id="single-spa-application:media-ui"></div>
            <div id="single-spa-application:journeys-ui"></div>
            <div id="single-spa-application:guides-ui"></div>
            <div id="single-spa-application:coaches-corner-ui"></div>
            <div id="single-spa-application:healthy-habits"></div>
            <div id="single-spa-application:calendar-ui"></div>
            <div id="single-spa-application:newsflash-ui"></div>
            <div id="single-spa-application:surveys-ui"></div>
            <div id="single-spa-application:pillars-and-topics-ui"></div>
            <div id="single-spa-application:transform-ui"></div>
            <div id="single-spa-application:challenges-ui"></div>
            <div id="single-spa-application:homepage-ui"></div>
            <div class="pos-rel" id="single-spa-application:tpa-resources"></div>
            <div id="single-spa-application:message-center"></div>
        </div>
    </div>

    <div class="footer-bottom-position">
        <spa-module [module]="moduleRegistry.modules.footer" [delay]="5000"></spa-module>
    </div>
    <trophy-popup></trophy-popup>
</div>
