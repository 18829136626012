import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { defaults } from '../../lib/lodash';
import { SponsorDepartment } from '../interfaces/sponsor-department.model';

@Injectable()
export class BusinessUnitService {
    DEFAULT_PAGE_SIZE = 10;

    constructor(
        private http: HttpClient
    ) {}

    getBusinessUnitsByCompanyId(companyId: number, options: any) {
        const fromObject = defaults(options || {}, {
            pageSize: this.DEFAULT_PAGE_SIZE,
            sortBy: 'name',
            sortDir: 'ASC'
        });

        const params = new HttpParams({ fromObject });

        return this.http.get(`/api/client-admin/companies/${companyId}/business-units`, {
            observe: 'response',
            params
        }).pipe(map((response: any) => ({
            companies: response,
            contentItems: response.headers.get('content-items'),
            currentPage: response.headers.get('current-page'),
            totalPages: response.headers.get('total-pages'),
            data: response.body
        })));
    }

    getAllBusinessUnits(options: any): Observable<any> {
        const PAGE_SIZE = 25;

        if (!options.pageSize) {
            options.pageSize = PAGE_SIZE;
        }

        const fromObject = {
            ...options,
            countMembers: true,
            companyIds: []
        };
        const params = new HttpParams({
            fromObject
        });

        return this.getBusinessUnits(params);
    }

    getBusinessUnitsByCompanyIds(companies: number[], options: any): Observable<any> {
        const PAGE_SIZE = 25;

        if (!options.pageSize) {
            options.pageSize = PAGE_SIZE;
        }

        const fromObject = {
            ...options,
            companyIds: companies,
            countMembers: true,
            unitIds: options.unitIds || []
        };
        const params = new HttpParams({
            fromObject
        });

        return this.getBusinessUnits(params);
    }

    private getBusinessUnits(params: HttpParams) {
        return this.http.get<SponsorDepartment>('/api/client-admin/business-units', {
            observe: 'response',
            params
        }).pipe(map((response) => ({
            units: response,
            contentItems: response.headers.get('content-items'),
            currentPage: response.headers.get('current-page'),
            totalPages: response.headers.get('total-pages'),
            data: response.body
        })));
    }
}
