import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'wcagInnerHTML'
})
export class WcagInnerHTMLPipe implements PipeTransform {
    private readonly lineBreakElement = '<div class="line-break"></div>';

    transform(value: string): string {
        const hasHTMLStructure = value.includes('<div') || value.includes('<p');

        if (hasHTMLStructure) {
            return value.replace(/<br \/>/gi, this.lineBreakElement);
        }

        const separator = '<br>';
        const elements = value.split(separator);

        if (!elements.length) {
            return value;
        }

        return elements.reduce((transformedValue, element) => {
            return element === ''
                ? `${transformedValue}${this.lineBreakElement}`
                : `${transformedValue}<div>${element}</div>`;
        }, '');
    }
}
