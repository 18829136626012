import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

import { UpdateLangAttributeService } from './update-lang-attribute.service';
import { DateUtils } from '../lib/date-utils';

@Injectable()
export class LanguageService {

    constructor(
        private updateLangAttributeService: UpdateLangAttributeService,
        private cookieService: CookieService,
        private translateService: TranslateService
    ) {}

    setLanguageCookie(memberLanguage: string): void {
        const languageCode = this.getMemberLanguageCode(memberLanguage);

        this.cookieService.set('NG_TRANSLATE_LANG_KEY', languageCode);
        this.translateService.use(languageCode);
        DateUtils.setGlobalLocale(languageCode);
        this.updateLangAttributeService.updateLangAttr(languageCode);
    }

    getMemberLanguageCode(memberLanguage: string): string {
        let languageCode = 'en-US';
        if (!memberLanguage) {
            return languageCode;
        }
        const languageNameArray = memberLanguage.split('_');
        languageNameArray[1] = languageNameArray[1].toUpperCase();
        languageCode = languageNameArray.join('-');
        return languageCode;
    }
}
