export enum AnalyticsTrackingAction {
    BenefitsViewTab = 'benefits view tab',
    BenefitsSaved = 'benefits saved',
    BenefitsResourceViewed = 'benefits resource viewed',
    BenefitsCallToActionClicked = 'benefits call to action clicked',
    BenefitsSearchBarClicked = 'benefits search bar clicked',
    BenefitsDetailClicked = 'benefits detail clicked',
    HomepageFindCareClicked = 'homepage find care clicked',
    HomepageViewWalletClicked = 'homepage view wallet clicked',
    HomepageTalkToGuide = 'homepage talk to a guide',
    TalkToGuideClicked = 'talk to guide clicked',
    ViewMedicalPlanClicked = 'view medical plan clicked',
    HomepageWidgetTable = 'Homepage - Widget Table',
    HomepageViewMyMedicalPlan = 'homepage view my medical plan',
    HomepageBenefitToolkitViewAllClicked = 'homepage benefit toolkit view all clicked',
    BenefitModuleViewAllClicked = 'homepage benefit view all clicked',
    HomepageBenefitProgramClicked = 'homepage benefit detail clicked',
    DomainNavClicked = 'domain nav clicked',
    FriendRequestSent = 'friend request sent',
    SubNavOptionClicked = 'subnav option clicked',
    GroupFavorited = 'group favorited',
    GroupsCreateABoard = 'groups create a board',
    CardSecondaryInteraction = 'card secondary interaction',
    ChallengeChatMessageSent = 'challenge chat message sent',
    ChallengeViewTab = 'challenge view tab',
    ChallengeStarted = 'challenge started',
    ChallengesCallToActionClicked = 'challenges call to action clicked',
    ChallengesModuleHeaderClicked = 'challenges module header clicked',
    CoachingAddToCalendarSelected = 'coaching add to calendar selected',
    CoachingAppointmentBookedViewed = 'coaching appointment booked viewed',
    CoachingAppointmentCanceled = 'coaching appointment canceled',
    CoachingAppointmentConfirmed = 'coaching appointment confirmed',
    CoachingAppointmentDetailViewed = 'coaching appointment detail viewed',
    CoachingAppointmentsViewed = 'coaching appointments viewed',
    CoachingCallNowClicked = 'coaching call now clicked',
    CoachingCancelAppointmentClicked = 'coaching cancel appointment clicked',
    CoachingModuleHeaderClicked = 'coaching module header clicked',
    CoachingReferralNavigation = 'coaching referral navigation',
    CoachingScheduleAppointmentViewed = 'coaching schedule appointment viewed',
    CoachingHubViewed = 'coaching hub viewed',
    CoachingTaskCompleted = 'coaching goal complete',
    CoachingTaskPageViewed = 'coaching goals page viewed',
    CoachingTopicSelected = 'coaching topic selected',
    CoachingTopicsViewed = 'coaching topics viewed',
    CreateAGroupSelected = 'create a group selected',
    SocialLandingPageSelection = 'social landing page selection',
    HealthDomainLandingPageInteraction = 'health domain landing page interaction',
    ViewDigitalWallet = 'view digital wallet',
    CoachingModuleInteraction = 'coaching module interaction',
    GuidesCallToActionClicked = 'guides call to action clicked',
    MfpAddFood = 'mfp add food link clicked',
    MfpCaloriesDateBack = 'mfp calories date back clicked',
    NotificationsPaneOpened = 'notifications pane opened',
    NotificationsCtaClicked = 'notifications cta clicked',
    NotificationsPaneClosed = 'notifications pane closed',
    YourSurveysPageLoaded = 'your surveys page loaded',
    SurveyDetailClicked = 'survey detail clicked',
    SurveyDetailsClicked = 'survey details clicked',
    SurveyMoreInfoClicked = 'survey more info clicked',
    HabitsModuleCallToActionClicked = 'habits module call to action clicked',
    HabitsModuleHeaderClicked = 'habits module header clicked',
    HealthCheckCompleted = 'health check completed',
    DeviceDetailClicked = 'device detail clicked',
    DeviceDisconnect = 'device disconnect',
    DeviceWebStoreLinkClicked = 'devices web store link clicked',
    JourneyInteractions = 'journey interactions',
    HealthyHabitAdded = 'healthy habit added',
    InterestSelectionSkipped = 'interest selection skipped',
    JourneyDetailClicked = 'journey detail clicked',
    JourneyModuleCallToActionClicked = 'journey module call to action clicked',
    JourneyModuleHeaderClicked = 'journey module header clicked',
    JourneySources = 'journey sources',
    JourneyRestarted = 'journey restarted',
    JourneyStarted = 'journey started',
    JourneyPageViewed = 'journey page viewed',
    JourneyVideoStarted = 'journey video started',
    MCCCoachingClicked = 'my care coaching cta clicked',
    MCCHealthSituationJourneyClicked = 'my care journeys cta clicked',
    MCCDisclosureScreenViewed = 'disclosures screen viewed',
    MCCManageHealthSituationDetailViewed = 'manage health situation detail viewed',
    MCCManageHealthSituationsViewed = 'manage health situations viewed',
    MCCPageViewed = 'my care checklist landed on page',
    MCCSignedUp = 'sign up for my care checklist',
    MCCTaskDetailViewed = 'task detail viewed',
    MCCTaskListEvents = 'my care task list viewed',
    MCCTaskUpdated = 'task updated',
    MCCWelcomeScreenViewed = 'welcome to my care checklist screen viewed',
    HealthCheckRiskSelected = 'health check risk selected',
    SurveyStarted = 'survey started',
    SurveyDownloadResults = 'survey download results',
    SurveyNextStepsConsultClicked = 'health check next step consult',
    SurveyGuidesPromoLinkClicked = 'health check guides promo',
    HealthCheckOpened = 'health check opened',
    GroupsTab = 'groups tab',
    HomepageProgressBarClicked = 'homepage progress bar clicked',
    HomepageGoToRewardsPageClicked = 'homepage go to rewards page clicked',
    NewsFlashClicked = 'newsflash clicked',
    MediaLandingPageViewed = 'media landing page viewed',
    MediaDetailClicked = 'media detail clicked',
    MediaDetailViewed = 'media detail viewed',
    MediaFilterApplied = 'media filter applied',
    MediaSortApplied = 'media sort applied',
    MediaVideoStarted = 'media video started',
    GroupDetailClicked = 'group detail clicked',
    GroupInteraction = 'group interaction',
    GroupJoined = 'group joined',
    ActionListItemClicked = 'action list item clicked',
    ActionListHeaderClicked = 'action list header clicked',
    GiveAShoutoutCompleted = 'give a shoutout completed',
    GiveAShoutoutClicked = 'give a shoutout clicked',
    GroupsPublishABoard = 'groups publish a board',
    CardInteraction = 'card interaction',
    GroupsSearchBarClick = 'groups search bar clicked',
    GroupsFilterSelected = 'groups filter selected',
    GroupCreated = 'group created',
    Guide = 'guide',
    GuidesNavigationClicked = 'guides navigation selection',
    RewardsWebNavigation = 'rewards web top navigation',
    ProgramsView = 'view programs page',
    GoalSetterInteraction = 'goal setter interaction',
    GoalUpdaterInteraction = 'goal updater interaction',
    DeviationInteraction = 'deviation interaction',
    BetaButtonClick = 'new stats page toggle',
    LandingPageDateChange = 'new stats page date toggle',
    DrilldownPageDateChange = 'new stats historical date usage',
    DevicesAndAppsButtonClicked = 'new stats connect button clicked',
    StatsDashboardLoaded = 'new stats page loaded',
    StatCardClicked = 'new stat detail clicked',
    StatManuallyEntered = 'new stats self reported',
    StatDurationTabSelected = 'new stats date toggled',
    LandingPageSourceFilterUpdated = 'new stats source updated',
    MissingStepsLinkClicked = 'new missing steps link clicked',
    ViewFindCareLandingPage = 'member viewed find care landing page',
    ViewFindDoctorPage = 'member viewed find a doctor page',
    ViewFindAProcedurePage = 'member viewed find a procedure page',
    ViewProviderDetailsPage = 'member viewed provider details page',
    ViewProcedureDetailsPage = 'member viewed procedure details page',
    ViewFacilityDetailsPage = 'member viewed facility details page',
    ViewOnlineDoctorPage = 'member viewed online doctor page',
    FindAProcedureButtonClicked = 'member clicked find a procedure button',
    FindADoctorButtonClicked = 'member clicked find a doctor button',
    OnlineDoctorButtonClicked = 'member clicked online doctor button',
    HealthyHabitTracked = 'healthy habit tracked',
    StatsSelfReported = 'stats self reported',
    FindDoctorSelectLocation = 'member selected location',
    FindDoctorSelectTypeahead = 'member selected typeahead',
    FindDoctorClickSearch = 'member clicked search',
    FindDoctorClickSortBy = 'member clicked sort by',
    FindDoctorPreferredGender = 'member clicked preferred gender',
    FindDoctorClickDistance = 'member clicked distance',
    FindDoctorClickSearchResult = 'member clicked search result',
    FindDoctorViewSearchResultPage = 'member viewed search result page',
    PillarsSelectionCompleted = 'pillars selection completed',
    PreviousButtonClickedAtInterestSelection = 'previous button clicked at interest selection',
    ProviderDetailsClickAbout = 'member clicked about',
    ProviderDetailsClickHospitals = 'member clicked hospitals',
    ProviderDetailsClickLocations = 'member clicked locations',
    ProviderDetailsClickWhyQualityMatters = 'member clicked why quality matters',
    ProcedureResultClicked = 'member clicked provider result',
    ProcedureSearchViewed = 'member viewed find procedure page',
    ProcedureResultsViewed = 'member viewed procedure results page',
    ProcedureDetailsViewed = 'member viewed procedure details page',
    FairPriceClicked = 'member clicked fair price breakdown',
    FacilityDetailsViewed = 'member viewed facility details page',
    MedicalPlanClicked = 'member clicked view my medical plan',
    LoadMoreClicked = 'member clicked load more',
    TopicsOfInterestSelectionCompleted = 'topics of interest selection completed',
    FindAProviderButtonClicked = 'member clicked find a provider button'
}
