import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CacheInterface, CacheItem } from './cache';
import { SessionCache } from './session-cache';
import { CurrentModuleService } from '../services';

export abstract class CustomCache implements CacheInterface<any> {
    protected abstract cache: SessionCache;

    constructor(private mf: CurrentModuleService) {}

    setItem(key: string, value: any): void {
        this.cache.setItem(key, value);
    }

    getItem(key: string): CacheItem<any> | null {
        return this.cache.getItem(key);
    }

    removeItem(key: string): void {
        this.cache.removeItem(key);
    }

    hasItem(key: string): boolean {
        return this.cache.hasItem(key);
    }

    getOrFetch<T>(key: string, action: () => Observable<T>): Observable<T> {
        const responseSubject = new Subject<any>();
        if (this.hasItem(key)) {
            setTimeout(() => {
                responseSubject.next(this.getItem(key)?.value);
            }, 0);
        } else {
            return action().pipe(tap((response) => {
                this.setItem(key, response);
            }));
        }

        return responseSubject.asObservable();
    }

    getCurrentModuleName(): string | undefined {
        return this.mf.name;
    }
}
