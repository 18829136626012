import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { VerifyMfaCodeModalComponent } from './verify-mfa-code-modal.component';

@Injectable({
    providedIn: 'root'
})
export class VerifyMfaCodeModalService {
    constructor(private modalService: BsModalService) {
    }

    open(memberId: number, verificationType: string, isMemberEditPage: boolean) {
        return this.modalService.show(VerifyMfaCodeModalComponent, {
            backdrop: true,
            animated: false,
            class: 'verify-mfa-code-modal modal-sm',
            initialState: {
                memberId,
                verificationType,
                isMemberEditPage
            }
        });
    }

    openFromCellPhoneBlocker(
        memberId: number,
        verificationType: string,
        resolveCellPhoneBlockerCallback: () => void
    ) {
        return new Promise<void>(((resolve) => {
            const closeSubscription = this.modalService.onHide.subscribe(() => {
                if (modal?.content?.dismissReason === VerifyMfaCodeModalComponent.CODE_VALID) {
                    resolveCellPhoneBlockerCallback();
                    resolve();
                }
                closeSubscription.unsubscribe();
            });

            const modal = this.modalService.show(VerifyMfaCodeModalComponent, {
                backdrop: false,
                keyboard: false,
                ignoreBackdropClick: true,
                show: true,
                animated: false,
                class: 'verify-mfa-code-modal modal-sm',
                initialState: {
                    memberId,
                    verificationType,
                    resolveCellPhoneBlockerCallback
                }
            });
        }));
    }
}
