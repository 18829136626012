import { Inject, Injectable } from '@angular/core';
import Keycloak, { KeycloakInitOptions, KeycloakInstance } from 'keycloak-js';
import { ReplaySubject } from 'rxjs';

import { KeycloakService } from '../../authentication/keycloak.service';
import { ENVIRONMENT, Environment } from '../../config/environment';
import { UrlUtil } from '../../services/url-util.service';

@Injectable({ providedIn: 'root' })
export class AuthSetup {
    config: any = {
        url: `${this.environment.authServer.baseUrl}/auth`,
        realm: `${this.environment.authServer.realm}`,
        clientId: `${this.environment.authServer.clientId}`,
        provider: 'keycloak'
    };

    isWebpackDevService = process.env.WEBPACK_DEV_SERVER;
    keycloak!: KeycloakInstance;
    subject = new ReplaySubject();

    constructor(
        @Inject(ENVIRONMENT) private environment: Environment,
        private urlUtil: UrlUtil,
        private authService: KeycloakService
    ) {
        this.config.keycloakIdentityProvider = this.urlUtil.getParameter('kc_idp_hint');
        this.keycloak = new Keycloak(this.config);
        this.authService.setClient(this.keycloak);
        (this.keycloak as any)['$vpProvider'] = this.config.provider;
    }

    keycloakBootstrap() {
        setTimeout(() => {
            if (this.urlUtil.getParameter('agents') === 'true' || sessionStorage.getItem('agents') === 'true') {
                sessionStorage.setItem('agents', 'true');
                const agentConfig = {
                    url: `${this.environment.authServer.baseUrl}/auth`,
                    realm: `${this.environment.authServer.agentRealm}`,
                    clientId: `${this.environment.authServer.clientId}`,
                    provider: 'keycloak'
                };
                this.keycloak = new Keycloak(agentConfig);
            }

            const initOptions: KeycloakInitOptions = {};

            if (this.isWebpackDevService) {
                initOptions.checkLoginIframe = false;
            }
            if (!this.config.keycloakIdentityProvider) {

                initOptions.onLoad = 'login-required';
                initOptions.enableLogging = true;
            }

            const keycloak = this.keycloak.init(initOptions);
            keycloak.then((authenticated: any) => {
                this.keycloakSuccess(authenticated);
            }).catch(() => {
                this.keycloakFail();
            });
        });

        return this.subject;
    }

    keycloakSuccess(authenticated: boolean) {
        if (authenticated) {
            this.authService.setClient(this.keycloak);
            this.subject.next();
            return;
        }
        if (this.config.keycloakIdentityProvider) {
            window.location.href = this.keycloak.createLoginUrl({ idpHint: this.config.keycloakIdentityProvider });
        } else {
            this.keycloak.login();
        }
    }

    keycloakFail() {
        this.keycloak.login();
    }
}
