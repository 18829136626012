import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { ConfigurationService } from './configuration.service';
import { MemberCountryService } from './member-country.service';
import { Links } from '../models';

@Injectable({
    providedIn: 'root'
})
export class StoreService {

    private storeLinkSubject = new ReplaySubject<string>(1);

    constructor(
        private memberCountryService: MemberCountryService,
        private configurationService: ConfigurationService
    ) {
        const DEFAULT_STORE_LINK = this.configurationService.getEcommerceBaseUrl() + Links.ShopDevices;
        const memberCountry = this.memberCountryService.memberCountry;
        if (!memberCountry) {
            this.storeLinkSubject.next(DEFAULT_STORE_LINK);
            return;
        }
        const storeUrl = memberCountry.storeUrl || DEFAULT_STORE_LINK;
        this.storeLinkSubject.next(storeUrl);
    }

    getStoreLink(): ReplaySubject<string> {
        return this.storeLinkSubject;
    }
}
