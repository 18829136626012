import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DateService } from './date.service';
import * as appointment from '../data/appointment-session';
import * as coaching from '../data/coaching-session';
import { CoachingModuleState } from '../enums/coaching-module-state.enum';
import { LiveServicesCoachingStatus } from '../enums/live-service-coaching-status.enum';
import { AnalyticsTrackingData } from '../models/analytics-tracking-data.model';
import { CoachingAppointmentModel } from '../models/coaching-appointment.model';
import { CoachingCardVM } from '../models/coaching-card-vm.model';
import { CoachingModuleProgramModel } from '../models/coaching-module-program.model';
import { CoachingPlansSummary } from '../models/coaching-plans-summary.model';
import { ButtonVariant } from '@engineering/genesis-frontend/domain/types';
import { AppointmentRequestMessagingStatus, AppointmentRequestMessagingVM, LiveServicesEngagementStatus } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class CoachingModuleService {
    Coaching_state = '';
    Coaching_appointment_status = '';
    analyticsTrackingData: AnalyticsTrackingData = {
        Type: 'Coaching Widget',
        Widget_name: 'Coaching',
        Coaching_clicks: '',
        Coaching_state: ''
    };
    readonly COACHING = 'Coaching';

    constructor(
        private dateService: DateService,
        private translateService: TranslateService
    ) {}

    getCoachingModuleState(program: CoachingModuleProgramModel, summary: CoachingPlansSummary): CoachingCardVM {
        if (program.messageCount && program.coachMessagingEnabled) {
            return this.getMessageInfo(program.messageCount);
        }

        if (program.latestMessagingRequest?.status === AppointmentRequestMessagingStatus.OPEN && program.latestMessagingRequest) {
            return this.getPendingInfo(program.latestMessagingRequest);
        }

        if (program.coachingEngagementStatus === LiveServicesEngagementStatus.PreEngaged && this.hasMissedCanceledOrNoAppointment(program.latestBookedAppointment)) {
            return this.getPreEngagedInfo();
        }

        if (!program.activeInCoaching || !program.latestBookedAppointment) {
            return this.getUnengagedInfo();
        }

        if (this.isStatusMissedCanceledCannotCompleteOrCompleted(program.latestBookedAppointment.status)) {
            return this.getAppointmentInfo(CoachingModuleState.PastAppointment, program.latestBookedAppointment);
        }

        if (this.dateService.isDateLessThen7DayAwayAndNotInPast(program.latestBookedAppointment.startTime)) {
            return this.getAppointmentInfo(CoachingModuleState.Appointment, program.latestBookedAppointment);
        }

        if (!program.eligibleForCoachingPlans || !summary.coachingPlans.count) {
            return this.getAppointmentInfo(CoachingModuleState.Appointment, program.latestBookedAppointment);
        }

        if (!program.coachMessagingEnabled) {
            return this.getAppointmentInfo(CoachingModuleState.Appointment, program.latestBookedAppointment);
        }

        if (summary.coachingTasks.count.inProgress) {
            return this.getTaskInfo(summary.coachingTasks.count.inProgress);
        }

        return this.getEmptyTaskInfo();
    }

    private isStatusMissedCanceledCannotCompleteOrCompleted(status: LiveServicesCoachingStatus): boolean {
        const pastAppointmentStatuses = [
            LiveServicesCoachingStatus.Missed,
            LiveServicesCoachingStatus.Canceled,
            LiveServicesCoachingStatus.CannotComplete,
            LiveServicesCoachingStatus.Completed
        ];

        return pastAppointmentStatuses.includes(status);
    }

    private hasMissedCanceledOrNoAppointment(appointment?: CoachingAppointmentModel): boolean {
        const missedCanceled = [
            LiveServicesCoachingStatus.Canceled,
            LiveServicesCoachingStatus.Missed
        ];

        return appointment ? missedCanceled.includes(appointment.status) : true;
    }

    getBasicContent(state: CoachingModuleState): CoachingCardVM {
        const { title, titleId, content, contentId, buttonType, buttonLabel, buttonId, hasButtonAriaLabel, Coaching_clicks, Coaching_state } = coaching.details[state];
        const translatedBtnLabel = this.translateService.instant(buttonLabel);

        return {
            state: state,
            title: this.translateService.instant(title),
            titleId,
            content: this.translateService.instant(content),
            contentId,
            buttonType: buttonType as ButtonVariant,
            buttonLabel: translatedBtnLabel,
            buttonAriaLabel: hasButtonAriaLabel ? `${this.translateService.instant(this.COACHING)}, ${translatedBtnLabel}` : null,
            buttonId,
            analyticsTrackingData:  {
                ...this.analyticsTrackingData,
                Coaching_clicks,
                Coaching_state
            }
        };
    }

    getUnengagedInfo(): CoachingCardVM {
        const coachingCard = this.getBasicContent(CoachingModuleState.Unengaged);
        return { ...coachingCard, imageSource: coaching.details.Unengaged.imageSource };
    }

    getPreEngagedInfo(): CoachingCardVM {
        return this.getBasicContent(CoachingModuleState.PreEngaged);
    }

    getEmptyTaskInfo(): CoachingCardVM {
        const coachingCard = this.getBasicContent(CoachingModuleState.EmptyTask);
        return { ...coachingCard, imageSource: coaching.details.EmptyTask.imageSource };
    }

    getPendingInfo(appointment: AppointmentRequestMessagingVM): CoachingCardVM {
        const { content, icon } = coaching.details.Pending;
        const coachingCard = this.getBasicContent(CoachingModuleState.Pending);
        const translatedTitle = this.dateService.convertToTitleFormat(appointment.createdDate);
        const translatedContent = this.translateService.instant(content);

        return {
            ...coachingCard,
            content: '',
            screenreaderText: `${translatedTitle}, ${translatedContent}`,
            multimodalWaitingRoom: {
                title: translatedTitle,
                content: translatedContent,
                icon
            }
        };
    }

    getTaskInfo(numberOfGoals: number): CoachingCardVM {
        const { headercontent, content, imageSource, contentTitle } = coaching.details.Task;
        const coachingCard = this.getBasicContent(CoachingModuleState.Task);
        const translatedTitle = this.translateService.instant(contentTitle, { numberOfGoals });
        const translatedContent = this.translateService.instant(content);

        return {
            ...coachingCard,
            content: this.translateService.instant(headercontent),
            screenreaderText: `${translatedTitle}, ${translatedContent}`,
            goals: {
                title: translatedTitle,
                content: translatedContent,
                numberOfGoals,
                icon: imageSource
            }
        };
    }

    getMessageInfo(messageCount: number): CoachingCardVM {
        const { title } = coaching.details.Message;
        const coachingCard = this.getBasicContent(CoachingModuleState.Message);

        return {
            ...coachingCard,
            title: `${this.translateService.instant(title)} ${messageCount}`,
            message: { numberOfMessages: messageCount || 0 }
        };
    }

    getAppointmentInfo(state: CoachingModuleState, coachingAppointment: CoachingAppointmentModel): CoachingCardVM {
        const sessionStatus = `Session${coachingAppointment.status}`;
        const { startTime, endTime } = coachingAppointment;
        const { title, titleId, buttonLabel, buttonType, buttonId, hasButtonAriaLabel, content, icon, Coaching_clicks, Coaching_state } = appointment.details[coachingAppointment.status];
        const formatedStartTime = this.dateService.getMemberTimezoneFormattedDate(startTime, 'hh:mm A');
        const formatedEndTime = this.dateService.getMemberTimezoneFormattedDate(endTime, 'hh:mm A');
        const dateTitle = this.dateService.getMemberTimezoneFormattedDate(startTime, 'ddd, MMM DD, YYYY');
        const memberTimezone = this.dateService.getMemberTimezone(coachingAppointment.startTime);
        const translatedBtnLabel = this.translateService.instant(buttonLabel);
        const timeInfo = `${this.translateService.instant(content, { startTime: formatedStartTime, endTime: formatedEndTime })} ${memberTimezone}`;
        const coachInfo = `${this.translateService.instant('Coach')} ${coachingAppointment.coachFirstName}`;
        const sessionStatusScreenreader = (coachingAppointment.status === LiveServicesCoachingStatus.Completed || coachingAppointment.status === LiveServicesCoachingStatus.Missed) ? `, ${this.translateService.instant(sessionStatus)}` : '';

        return {
            state,
            title: this.translateService.instant(title),
            content: coachingAppointment.status === LiveServicesCoachingStatus.Canceled ? this.translateService.instant(appointment.details[LiveServicesCoachingStatus.Canceled].content) : '',
            titleId,
            buttonLabel: translatedBtnLabel,
            buttonType: buttonType as ButtonVariant,
            buttonId,
            buttonAriaLabel: hasButtonAriaLabel ? `${this.translateService.instant(this.COACHING)}, ${translatedBtnLabel}` : null,
            appointment: {
                timezone: memberTimezone,
                completed: coachingAppointment.status === LiveServicesCoachingStatus.Completed,
                hasAppointmentDetails: coachingAppointment.status !== LiveServicesCoachingStatus.Canceled,
                title: dateTitle,
                content: timeInfo,
                coachFirstName: coachInfo,
                icon
            },
            imageSource: appointment.details[coachingAppointment.status].imageSource,
            analyticsTrackingData: {
                ...this.analyticsTrackingData,
                Coaching_clicks,
                Coaching_state,
                Coaching_appointment_status: coachingAppointment.status
            },
            screenreaderText: `${dateTitle}, ${timeInfo}, ${coachInfo} ${sessionStatusScreenreader}`
        };
    }
}
