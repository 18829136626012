<vpg-container class="logout-warning-wrapper"
               role="dialog">
    <div id="logout-warning-modal-title"
         class="logout-warning-title board-title">
          <p [translate]="'StillThere'"> Still There? </p>
    </div>

    <div class="logout-warning-content-wrap">
        <div id="logout-warning-timer"
             role="timer"
             class="logout-warning-time-counter">
             {{ timer }}
             <span class="sr-only"
                   [translate]="'TimerText'">
                   seconds remaining
            </span>
        </div>

        <div class="logout-warning-border" role="presentation"></div>
        <div id="logout-warning-modal-content"
             class="logout-warning-message-content">
          <p [translate]="'SessionEndsSoon'"> Your session will end soon due to inactivity.</p>
          <p [translate]="'StaySignedInDescription'"> Would you like to stay signed in? </p>
       </div>
    </div>

     <div class="still-there-button-wrapper">
        <button
               vpg-button
               #buttonmodal
               id="im-here-stay-logged-in-btn"
               class="im-still-here-button"
               variant="primary"
               size="medium"
               (click)="stay()"
               (keydown)="focus($event, closeButton)"> {{ 'StaySignedIn' | translate}}
        </button>
     </div>
</vpg-container>
