export enum Links {
    HealthZone = '/HZClickOnce/HealthZoneSync.application',
    Support = '/devices/support',
    ZendeskSupport = '/zendesk/support',
    MaxShop = '/max-30',
    ShopGiftCards = '/emporium/gift-cards',
    ACHDeposit = '/#/achdeposit',
    ShopDevices = '/shop/',
    Analytics = '/api/domo-keycloak-client/sso-url',
    Inactive = '{0}/register/#/inactive',
    VirginPulse = 'virginpulse.com',
    FoodSmart = 'pif-foodsmart&scope=events&response_type=code&redirect_uri=https://virginpulse.zipongo.com/login&state=https://virginpulse.zipongo.com/dashboard',
    OAuthSSO = '/auth/realms/virginpulse/protocol/openid-connect/auth?client_id='
}
