import { GenesisMemberState } from './state.model';
import {
    ADD_AUTHENTICATION,
    SEND_NOTIFICATION_AVAILABLE,
    UPDATE_AUTHENTICATED_MEMBER,
    UPDATE_MEMBER_REDEEMABLE_WALLETS,
    UPDATE_MEMBER_REWARDS_OVERVIEW,
    UPDATE_SPONSOR_VP_NAVIGATE_FEATURES,
    ADD_MIXPANEL_OBJECT,
    ADD_HISTORICAL_SURVEYS,
    ADD_MEMBER_FEATURES,
    ADD_MEMBER_FEATURE_TOGGLE,
    ADD_SPONSOR_FEATURE_TOGGLE,
    ADD_MEMBER_ALL_PROMOTED_REWARDS,
    UPDATE_CHALLENGES_DATA,
    ADD_CONFIGURATION,
    ADD_MEMBER_COUNTRY,
    ADD_MEMBER_PRODUCTS,
    ADD_REWARD_LABELS,
    ADD_READ_IMAGE_SECURITY_DATA,
    ADD_PILLAR_SETTINGS,
    UPDATE_REWARDS_STATEMENT_FILTER_INTERVAL,
    UPDATE_MEMBER_TOTAL_SUMMARY,
    UPDATE_REWARDS_STATEMENT_MEMBER_PROGRAMS,
    ADD_HOMEPAGE_MODULES_REWARDS
} from './store.action';

export const genesisMemberStateInitial: GenesisMemberState = {
    authentication: {},
    notificationAvailable: false,
    redeemableWallets: {},
    rewardsOverview: {},
    sponsorVpNavigateFeatures: {},
    mixpanelObject: {},
    historicalSurveys: {},
    memberFeatures: [],
    challengesData: {},
    rewardLabels: null,
    memberFeatureToggle: [],
    readSecurityData: {},
    rewardsStatementFilterInterval: 'Monthly',
    totalSummary: {},
    rewardsStatementMemberPrograms: [],
    homepageModulesRewards: []
};

export const genesisMemberStateReducer = (state: GenesisMemberState = genesisMemberStateInitial, action: any) => {
    switch (action.type) {
    case ADD_AUTHENTICATION: {
        const authenticatedMember = action.payload.member;
        const authClient = action.payload.authClient;
        const authentication = { authenticatedMember, authClient };
        return {
            ...state,
            authentication
        };
    }
    case UPDATE_AUTHENTICATED_MEMBER: {
        const authenticatedMember = action.payload;
        const authentication = { ...state.authentication, authenticatedMember };
        return {
            ...state,
            authentication
        };
    }
    case SEND_NOTIFICATION_AVAILABLE: {
        const notificationAvailable = action.payload;
        return {
            ...state,
            notificationAvailable
        };
    }
    case UPDATE_MEMBER_REDEEMABLE_WALLETS: {
        const redeemableWallets = action.payload;
        return {
            ...state,
            redeemableWallets
        };
    }
    case UPDATE_MEMBER_REWARDS_OVERVIEW: {
        const rewardsOverview = action.payload;
        return {
            ...state,
            rewardsOverview
        };
    }
    case UPDATE_SPONSOR_VP_NAVIGATE_FEATURES: {
        const sponsorVpNavigateFeatures = action.payload;
        return {
            ...state,
            sponsorVpNavigateFeatures
        };
    }
    case ADD_MIXPANEL_OBJECT: {
        const mixpanelObject = action.payload;
        return {
            ...state,
            mixpanelObject
        };
    }
    case ADD_HISTORICAL_SURVEYS: {
        const historicalSurveys = action.payload;
        return {
            ...state,
            historicalSurveys
        };
    }
    case ADD_MEMBER_FEATURES: {
        const memberFeatures = action.payload;
        return {
            ...state,
            memberFeatures
        };
    }
    case ADD_MEMBER_FEATURE_TOGGLE: {
        const memberFeatureToggle = action.payload;
        return {
            ...state,
            memberFeatureToggle
        };
    }
    case ADD_SPONSOR_FEATURE_TOGGLE: {
        const sponsorFeatureToggle = action.payload;
        return {
            ...state,
            sponsorFeatureToggle
        };
    }
    case ADD_MEMBER_ALL_PROMOTED_REWARDS: {
        const allPromotedRewards = action.payload;
        return {
            ...state,
            allPromotedRewards
        };
    }
    case UPDATE_CHALLENGES_DATA: {
        const challengesData = action.payload;
        return {
            ...state,
            challengesData
        };
    }

    case ADD_CONFIGURATION: {
        const configuration = action.payload.configuration;
        return {
            ...state,
            configuration
        };
    }
    case ADD_MEMBER_PRODUCTS: {
        const memberProducts = action.payload.memberProducts;
        return {
            ...state,
            memberProducts
        };
    }
    case ADD_MEMBER_COUNTRY: {
        const memberCountry = action.payload.memberCountry;
        return {
            ...state,
            memberCountry
        };
    }
    case ADD_REWARD_LABELS: {
        const rewardLabels = action.payload;
        return {
            ...state,
            rewardLabels
        };
    }
    case ADD_READ_IMAGE_SECURITY_DATA: {
        const readSecurityData = action.payload;
        return {
            ...state,
            readSecurityData
        };
    }
    case ADD_PILLAR_SETTINGS: {
        const pillarSettings = action.payload;
        return {
            ...state,
            pillarSettings
        };
    }
    case ADD_HOMEPAGE_MODULES_REWARDS: {
        const homepageModulesRewards = action.payload;
        return {
            ...state,
            homepageModulesRewards
        };
    }
    case UPDATE_REWARDS_STATEMENT_FILTER_INTERVAL: {
        const rewardsStatementFilterInterval = action.payload;
        return {
            ...state,
            rewardsStatementFilterInterval
        };
    }
    case UPDATE_MEMBER_TOTAL_SUMMARY: {
        const totalSummary = action.payload;
        return {
            ...state,
            totalSummary
        };
    }
    case UPDATE_REWARDS_STATEMENT_MEMBER_PROGRAMS: {
        const rewardsStatementMemberPrograms = action.payload;
        return {
            ...state,
            rewardsStatementMemberPrograms
        };
    }
    default: {
        return state;
    }
    }
};
