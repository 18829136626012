import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ standalone: true, name: 'rewardsTypeDescription' })
export class RewardsTypeDescription implements PipeTransform {
    transform(value: string): string {
        switch (value) {
        case 'PulseCash':
            return 'RewardsCash';
        case 'MaritzCredits':
            return 'Credits';
        default:
            return value;
        }
    }
}
