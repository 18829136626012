import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EnhancedAnalyticsService {

    spaStarted = false;

    constructor(private httpClient: HttpClient) {}

    getDomoUrl() {
        return this.httpClient.get('/api/domo-keycloak-client/soo-url', { observe: 'response' });
    }

    getDomoAccess(memberId: number) {
        return this.httpClient.get(`/api/members/${memberId}/domo-access`, { observe: 'response' });
    }

    getSpaStarted() {
        if (this.spaStarted) {
            return true;
        }
        this.spaStarted = true;
        return false;
    }
}
