import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Environment, ENVIRONMENT } from '../config';
import { ZendeskAuth } from '../models/interfaces/zendeskauth.model';

@Injectable({
    providedIn: 'root'
})
export class ZendeskService {
    bofaUatSponsorId = 5272;
    bofaSponsorId = 4574;
    faqSupportLink = 'https://personifyhealth.zendesk.com/hc/en-us';

    constructor(
        @Inject(ENVIRONMENT) private environment: Environment,
        private httpClient: HttpClient
    ) {}

    getSupportUrl(): Observable<ZendeskAuth> {
        return this.httpClient.get<ZendeskAuth>('/api/zendesk/support/auth');
    }

    getChatToken(): Observable<string> {
        return this.httpClient.get('/api/zendesk/support/chat-token', { responseType: 'text' });
    }

    isBofaUatMember(sponsorId: number): boolean {
        return this.environment.env.env === 'production' && (sponsorId === this.bofaUatSponsorId || sponsorId === this.bofaSponsorId);
    }

    openSupportPage(supportPageLink: string | undefined, sponsorId: number): void {
        if (supportPageLink && !this.isBofaUatMember(sponsorId)) {
            window.open(supportPageLink);
        } else {
            window.open(`https://${window.location.host}/#/zendesk/support`);
        }
    }
    openFaqSupportPage(supportPageLink: string, sponsorId: number): void {
        if (supportPageLink && !this.isBofaUatMember(sponsorId)) {
            window.open(supportPageLink);
        } else {
            window.open(this.faqSupportLink);
        }
    }
}
