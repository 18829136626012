import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import * as braze from '@braze/web-sdk';
import { FullScreenMessage, InAppMessageButton, ModalMessage } from '@braze/web-sdk';
import { Observable } from 'rxjs';

import { AuthenticatedMemberService } from '../authentication/authenticated-member.service';
import { environment } from '../config/environments/environment';
import { Member } from '../models';

type Braze = typeof braze;

interface AuthToken {
    accessToken: string;
    expiresInSeconds: number;
}

interface BrazeRegionConfig {
    apiKey: string;
    baseUrl: string;
}

enum ERROR_CODES {
    EXPIRED = 22
}

@Injectable({
    providedIn: 'root'
})
export class BrazeService {
    private readonly tokenUrl = '/api/braze/authentication/token';
    private readonly NONE = 'NONE';
    private readonly URI = 'URI';
    private readonly DEEP_LINK_URL = 'personifyhealth';
    private readonly appboy: Braze = braze;

    private readonly http: HttpClient = inject(HttpClient);
    private readonly member: Member = inject(AuthenticatedMemberService).getMember();

    initialize(): Braze {
        const { apiKey, baseUrl } = this.getConfigByRegion();

        this.appboy.initialize(apiKey, {
            baseUrl,
            enableLogging: !environment.production,
            enableSdkAuthentication: true,
            devicePropertyAllowlist: [
                'browser',
                'browserVersion',
                'os',
                'resolution',
                'userAgent'
            ]
        });

        this.authenticate().subscribe(({ accessToken }) => {
            this.changeUser(this.member.externalId, accessToken);
        });

        this.handleAuthenticationFailure();
        this.subscribeToInAppMessage();

        this.openSession();

        return this.appboy;
    }

    unsubscribe(): void {
        this.appboy.removeAllSubscriptions();
    }

    private authenticate(): Observable<AuthToken> {
        return this.http.get<AuthToken>(this.tokenUrl);
    }

    private handleAuthenticationFailure(): void {
        this.appboy.subscribeToSdkAuthenticationFailures((error) => {
            if (error.errorCode === ERROR_CODES.EXPIRED) {
                this.authenticate().subscribe(({ accessToken }) => {
                    this.appboy.setSdkAuthenticationSignature(accessToken);
                });
            } else {
                throw error;
            }
        });
    }

    private openSession(): void {
        this.appboy.openSession();
    }

    private changeUser(userId: number, token: string): void {
        this.appboy.changeUser(`${userId}`, token);
    }

    private getMemberRegion(): string {
        return {
            US1: 'us',
            EU1: 'eu'
        } [this.member.memberDataRegion] ?? 'us';
    }

    private getConfigByRegion(): BrazeRegionConfig {
        const region = this.getMemberRegion() as keyof typeof environment.braze;

        return {
            apiKey: environment.braze[region].apiKey,
            baseUrl: environment.braze[region].sdkEndpoint
        };
    }

    private subscribeToInAppMessage(): void {
        this.appboy.subscribeToInAppMessage((message) => {
            if (message instanceof ModalMessage || message instanceof FullScreenMessage) {
                message.buttons.forEach((button) => {
                    if (button.clickAction === this.URI) {
                        this.processClickedEvent(button);
                    }
                });
            }
            this.appboy.showInAppMessage(message);
        });
    }

    private processClickedEvent(button: InAppMessageButton) {
        button.subscribeToClickedEvent(() => {
            button.clickAction = this.NONE;
            if (button.uri) {
                this.handleLinkRedirection(button.uri);
            }
        });
    }

    private handleLinkRedirection(uri: string): void {
        if (uri.includes(this.DEEP_LINK_URL)) {
            this.appboy.handleBrazeAction(uri);
        } else {
            this.appboy.handleBrazeAction(uri, true);
        }
    }

}
