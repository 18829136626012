<vpg-container
    *ngIf="vm$ | async as vm"
    class="p-none"
>
    <genesis-frontend-loader *ngIf="vm.loading" class="loader"></genesis-frontend-loader>
    <genesis-frontend-error *ngIf="vm.error"></genesis-frontend-error>
    <ng-container *ngIf="vm.data">
        <genesis-frontend-opt-in-card
            *ngIf="vm.data.memberHasNoClaims"
            [claimsState]="claimsState"
        >
        </genesis-frontend-opt-in-card>

        <genesis-frontend-base-card
            [claimsState]="claimsState"
            *ngIf="!vm.data.memberHasNoClaims"
        >
            <genesis-frontend-claim
                *ngFor="let claim of vm.data.insuranceClaims"
                [claimsState]="claimsState"
                [claim]="claim"
            >
            </genesis-frontend-claim>
        </genesis-frontend-base-card>
    </ng-container>
</vpg-container>
