/**
 * @deprecated Class will be refactored/replaced
 */
export enum CustomSubjectEventType {
    RoutingEvent = 'RoutingEvent',
    NotificationEvent = 'NotificationsEvent',
    DataTransferEvent = 'DataTransferEvent',
    OpenInterestsModalEvent = 'OpenInterestsModalEvent',
    OpenSupportModalEvent = 'OpenSupportModalEvent',
    ModalEvent = 'ModalEvent',
    LogoutUserEvent = 'LogoutUserEvent',
    ViewCalendarEventModalEvent = 'ViewCalendarEventModalEvent',
    ShowNotification = 'ShowNotification',
    ToggleNotificationPaneEvent = 'ToggleNotificationPaneEvent',
    PromptAnswerSelectedEvent = 'PromptAnswerSelectedEvent',
    LessonSubsectionUpdatedEvent = 'LessonSubsectionUpdatedEvent',
    LessonSubsectionSelectedEvent = 'LessonSubsectionSelectedEvent'
  }
