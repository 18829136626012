import { Directive, ElementRef, OnInit } from '@angular/core';

@Directive({
    selector: '[anchorWcag]'
})
export class AnchorWcagDirective implements OnInit {

    constructor(private elementRef: ElementRef) {}

    ngOnInit(): void {
        this.addWcagProperties();
    }

    private addWcagProperties(): void {
        const anchorTags = this.elementRef.nativeElement.querySelectorAll('a');
        let addSRContent = false;

        anchorTags.forEach((anchorTag: HTMLElement) => {
            anchorTag.style.textDecoration = 'underline';
            anchorTag.style.outlineOffset = '1px';

            if (anchorTag.getAttribute('target') === '_blank') {
                anchorTag.setAttribute('aria-describedby', 'sr-new-window');
                addSRContent = true;
            }
        });

        if (addSRContent) {
            const SRContent = '<span id="sr-new-window" class="sr-only">Opens in a new window</span>';
            this.elementRef.nativeElement.innerHTML = this.elementRef.nativeElement.innerHTML.concat(SRContent);
        }
    }
}
