import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BaseCardCarouselComponent } from './base-card-carousel/base-card-carousel.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        BaseCardCarouselComponent
    ],
    exports: [
        BaseCardCarouselComponent
    ]
})
export class CardCarouselModule { }
