import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

import { JourneysCompletedModalService } from '../journeys-completed-modal/journeys-completed-modal.service';
import { ButtonType } from '@engineering/genesis-frontend';
import { JourneySurveyService } from '@genesis-frontend/genesis-data-access';
import { AuthenticatedMemberService, JourneySurvey, JourneySurveyQuestion, MemberJourneySurveyAnswer, MemberJourneySurveyResponse, WcagService } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'journey-survey-modal',
    templateUrl: './journey-survey-modal.component.html',
    styleUrls: ['./journey-survey-modal.component.scss']
})
export class JourneySurveyModalComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() journeyId!: number;
    @Input() journeyTitle!: string;

    survey: JourneySurvey | null = null;
    surveyQuestions: JourneySurveyQuestion[] = [];

    readonly LIKERT_SCALE_OPTIONS_SIZE = 5;
    readonly SHOW_COMPLETED_MODAL_DELAY = 500;
    readonly PRIMARY_BUTTON = ButtonType.Primary;
    readonly SECONDARY_BUTTON = ButtonType.Secondary;
    readonly TAB = 'Tab';

    form = this.fb.group({});

    @ViewChild('journeySurveyModal') modal?: ElementRef;

    private isCompleted = false;
    private onHideSubscription?: Subscription;

    constructor(
        private journeySurveyService: JourneySurveyService,
        private journeyCompletedModalService: JourneysCompletedModalService,
        private router: Router,
        private bsModalRef: BsModalRef,
        private fb: UntypedFormBuilder,
        private authenticatedMemberService: AuthenticatedMemberService,
        private wcagService: WcagService,
        private elementRef: ElementRef
    ) { }

    ngOnDestroy(): void {
        this.onHideSubscription?.unsubscribe();
    }

    ngOnInit(): void {
        this.journeySurveyService.getJourneySurvey()
            .subscribe((data: JourneySurvey) => {
                this.survey = data;
                this.surveyQuestions = data.surveyQuestions;
                this.buildQuestionsFormArray();
                this.trapFocusInsideModal();
            });
        this.onHideSubscription = this.bsModalRef.onHide?.subscribe((eventSrc: any) => {
            if (eventSrc === 'backdrop-click') {
                this.navigateToJourneyChecklist();
                return;
            }
            this.openJourneyCompletedModal(this.isCompleted);
        });
        this.isCompleted = false;
    }

    ngAfterViewInit(): void {
        this.modal?.nativeElement.focus();
    }

    saveSurveyResponse() {
        if (this.form.invalid || !this.survey) {
            return;
        }
        const surveyResponse = {
            memberId: this.authenticatedMemberService.getAuthenticatedMemberId(),
            surveyId: this.survey.id,
            journeyId: this.journeyId,
            memberSurveyAnswers: this.buildMemberSurveyAnswers()
        } as MemberJourneySurveyResponse;

        this.journeySurveyService.saveMemberJourneySurveyAnswers(surveyResponse).subscribe(() => this.close(true));
    }

    close(isSurveyCompleted: boolean) {
        this.isCompleted = isSurveyCompleted;
        this.bsModalRef.hide();
    }

    private openJourneyCompletedModal(isSurveyCompleted: boolean) {
        setTimeout(() => {
            const data = { journeyId: this.journeyId, journeyTitle: this.journeyTitle };
            this.journeyCompletedModalService.showJourneyCompletedModal(data, isSurveyCompleted);
        }, this.SHOW_COMPLETED_MODAL_DELAY);
    }

    private trapFocusInsideModal() {
        this.elementRef.nativeElement.addEventListener('keydown', (event: any) => {
            this.wcagService.trapFocusInsideElementsContainer(event, this.elementRef, this.TAB);
        });
    }

    private buildQuestionsFormArray() {
        Array(this.surveyQuestions.length).fill(1)
            .forEach((_, index) => {
                this.form.addControl(`question${index}`, new FormControl('', [Validators.required]));
            });
    }

    private buildMemberSurveyAnswers() {
        return Object.keys(this.form.controls).map((key, index) => {
            const surveyQuestionId = this.surveyQuestions[index].id;
            const surveyAnswerId = this.form.get(key)?.value;
            return {
                surveyQuestionId,
                surveyAnswerId: Number(surveyAnswerId),
                additionalDetails: null
            } as MemberJourneySurveyAnswer;
        });
    }

    private navigateToJourneyChecklist() {
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([currentUrl]);
        });
    }
}
