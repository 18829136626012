import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { PillarTopicsOfInterestModalComponent } from './components/pillar-topics-of-interest-modal.component';

@Injectable({
    providedIn: 'root'
})
export class InterestModalService {
    constructor(
        private modalService: BsModalService
    ) { }

    openModal(): BsModalRef<PillarTopicsOfInterestModalComponent> {
        return this.modalService.show(PillarTopicsOfInterestModalComponent, {
            class: 'modal-md',
            backdrop: true,
            keyboard: false,
            animated: false,
            ignoreBackdropClick: true
        });
    }
}
