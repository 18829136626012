import { Component, Output, EventEmitter, OnInit } from '@angular/core';

import { PalService } from './pal.service';
import {
    AuthenticatedMemberService,
    FeatureEnum,
    PalMemberPromotedAction,
    ShellRouter
} from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'genesis-frontend-pal-modal',
    templateUrl: './pal-modal.component.html',
    styleUrls: ['./pal-modal.component.scss']
})
export class PalModalComponent implements OnInit {
    readonly promotedActions$ = this.palService.memberPromotedActions$;
    private readonly howToEarnUrl = '/my-rewards/earn';
    readonly rewardFeatures = [FeatureEnum.RewardsAndProgram, FeatureEnum.GenesisRewards];
    readonly TRANSLATIONS = {
        MY_ACTION: 'MyActions',
        CLOSE: 'Close',
        VIEW_REWARDS:'ViewRewards'
    };

    @Output() closeDialog = new EventEmitter<string | boolean>();
    hasRewardsAndProgram = this.authenticatedMemberService.hasEveryFeature(this.rewardFeatures);
    @Output() cardClicked: EventEmitter<PalMemberPromotedAction[]> = new EventEmitter<PalMemberPromotedAction[]>();

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private palService: PalService,
        private shellRouter: ShellRouter
    ) {}

    ngOnInit(): void {
        this.palService.initPalData();
    }

    close(): void {
        this.closeDialog.emit('Closed');
    }

    viewRewards(): void {
        this.shellRouter.navigate(this.howToEarnUrl);
        this.close();
    }

    onCardClick(card: PalMemberPromotedAction, analyticsIndex: number): void {
        this.palService.handleCardClick(card, analyticsIndex);
        this.close();
    }
}
