import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ApplicationCache, MenuActionType, MenuItem, MenuItemVM, MenuType } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    private topMenuFullWidth:MenuItem[] = [];
    private topMenuResized:MenuItem[] = [];
    private selectedMenuIndex = 0;
    private readonly HomeMenuItem = {
        label: this.translateService.instant('Home'),
        type: MenuType.Home,
        url: 'home',
        analyticsTag: 'home',
        pageTitle: 'Home',
        iconClassName: 'home_icon.svg',
        action: MenuActionType.NavigateToUrl,
        submenu: [{
            type: MenuType.GoHome,
            label: this.translateService.instant('GoHome'),
            url: 'home',
            analyticsTag: 'home',
            action: MenuActionType.NavigateToUrl,
            webHamburgerMenuOnly: true
        }]
    } as MenuItem;
    private readonly MoreMenuItem = {
        label: this.translateService.instant('More'),
        type: MenuType.More,
        analyticsTag: 'more',
        iconClassName: 'more_icon.svg',
        action: MenuActionType.NavigateToUrl,
        submenu: [{
            type: MenuType.MyProfile,
            label: this.translateService.instant('MyProfile'),
            url: 'member-profile',
            analyticsTag: 'profile',
            action: MenuActionType.NavigateToUrl
        },
        {
            type: MenuType.SignOut,
            label: this.translateService.instant('SignOut'),
            url: 'logout',
            analyticsTag: 'sign out',
            webTopMenuOnly: true,
            action: MenuActionType.Logout
        }]
    } as MenuItem;
    constructor(
        private cache: ApplicationCache,
        private httpClient: HttpClient,
        private translateService: TranslateService
    ) {}
    getTopMenuFullWidth(): MenuItem[] {
        return this.topMenuFullWidth;
    }

    getTopMenuResized(): MenuItem[] {
        return this.topMenuResized;
    }

    setTopMenuFullWidth(menuItems: MenuItem[]): void {
        this.topMenuFullWidth = menuItems.filter((menuItem) => !menuItem.webHamburgerMenuOnly)
            .map((menuItem) => ({
                ...menuItem,
                submenu: menuItem.submenu?.filter((submenuItem) => !submenuItem.webHamburgerMenuOnly)
            }));
    }

    setTopMenuResized(): void {
        this.topMenuResized = JSON.parse(JSON.stringify(this.topMenuFullWidth));
        if (this.topMenuResized.length > 5) {
            this.topMenuResized = this.moveMenuItems(this.topMenuResized);
        }
    }

    getHamburgerMenu(menuItems: MenuItem[]): MenuItem[] {
        return menuItems.filter((menuItem) => !menuItem.webTopMenuOnly)
            .map((menuItem) => {
                menuItem.hasHamburgerMenuSubmenu =
              [MenuType.Home, MenuType.More].includes(menuItem.type) ||
              (menuItem.submenu && menuItem.submenu.length > 1);

                menuItem.submenu = menuItem.submenu?.filter((submenuItem) => !submenuItem.webTopMenuOnly);
                return menuItem;
            });
    }

    getNavigationMenuData(memberId: number): Observable<MenuItem[]> {
        return this.cache.getOrFetch('NavigationService.getNavigationMenuData', () => {
            return this.httpClient.get<MenuItemVM>(`api/members/${memberId}/navigation/web`).pipe(
                map((res: MenuItemVM) => {
                    return res.menuItems;
                }), catchError(() => {
                    return of(this.getMockMenuItems());
                })
            );
        });
    }

    getMenuItemLabel(menuType: MenuType, menuItems: MenuItem[]): string {
        const menuItem = menuItems.find((item) => item.type === menuType);
        return menuItem ? menuItem.label : '';
    }

    setSelectedMenuIndex(index: number): void {
        this.selectedMenuIndex = index;
    }

    focusOnSelectedMenuItem(): void {
        const selectedMenuItemId = 'hamburgerMenuItem' + this.selectedMenuIndex;
        const menuItem = document.getElementById(selectedMenuItemId);
        menuItem?.focus();
    }

    /**
     * Given an array of menu items, this method will move all the items past the fourth index
     * to the "More" menu item's submenu. The "More" menu item will be moved to the fourth index.
     * @param menuItems The menu items to reorder.
     * @returns The reordered menu items.
     */
    private moveMenuItems(menuItems: MenuItem[]): MenuItem[] {
        const moreItem = menuItems.find((item) => item.type === MenuType.More);
        const movedMenuItems = menuItems.slice(4).filter((item) => item.type !== MenuType.More);

        menuItems = menuItems.slice(0, 4).concat(moreItem ? [moreItem] : []);

        if (moreItem) {
            moreItem.submenu?.unshift(...movedMenuItems);
        }
        return menuItems;
    }

    private getMockMenuItems(): MenuItem[] {
        return Array.of(this.HomeMenuItem, this.MoreMenuItem);
    }
}
