import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    StaticDataQueryGQL,
    Country,
    TimezoneDescriptor,
    LanguageDescriptor,
    GenderIdentityDescriptor,
    UnitOfMeasurementDescriptor,
    GendersQueryGQL
} from '../generated/resolvers-types.generated';

export interface StaticData {
    readonly countries: Country[];
    readonly timezones: TimezoneDescriptor[];
    readonly languages: LanguageDescriptor[];
    readonly unitOfMeasurements: UnitOfMeasurementDescriptor[];
    readonly genders: GenderIdentityDescriptor[];
}

@Injectable({ providedIn: 'root' })
export class StaticDataService {

    constructor(
        private staticDataQuery: StaticDataQueryGQL,
        private gendersQuery: GendersQueryGQL
    ) {}

    getStaticData(withNonBinary: boolean = false): Observable<StaticData> {
        return this.staticDataQuery.fetch({ withNonBinary }).pipe(map((result) => {
            const data = result.data;
            const response: StaticData = {
                countries:  data.countries.edges.map((e) => e.node),
                timezones: data.timezones.edges.map((e) => e.node),
                languages: data.languages.edges.map((e) => e.node),
                unitOfMeasurements : data.unitOfMeasurements.edges.map((e) => e.node),
                genders: data.genders.edges.map((e) => e.node)
            };
            return response;
        }));
    }

    getGenders(withNonBinary: boolean = false): Observable<GenderIdentityDescriptor[]> {
        return this.gendersQuery.fetch({ withNonBinary }).pipe(map((result) => {
            return result.data.genders.edges.map((e) => e.node);
        }));
    }
}
