import { Component, Input } from '@angular/core';

import { ClaimsState } from '../../models/claim-state.model';
import { AnalyticsTrackingAction, AnalyticsTrackingSingleSpaService } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'genesis-frontend-base-card',
    templateUrl: './base-card.component.html'
})
export class BaseCardComponent {
    @Input() claimsState: ClaimsState = ClaimsState.noClaims;

    readonly translations = {
        VIEW_ALL_CLAIMS: 'ViewAllClaims',
        VIEW_ALL: 'ViewAll',
        RECENT_CLAIMS: 'RecentClaims'
    };

    constructor(
        private readonly analyticsService: AnalyticsTrackingSingleSpaService
    ) {}

    sendAnalytics() {
        this.analyticsService.sendData(AnalyticsTrackingAction.HomepageWidgetTable, {
            Widget_name: 'Claims Widget',
            Claims_state: this.claimsState,
            Claims_clicks: 'View All',
            Claims_navigation: 'Navigate to Claims Landing Page'
        });
    }
}
