import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

import { MemberMfaCodeVerificationService } from './member-mfa-code-verification.service';
import { ResendingMfaCodeWarningModalService } from './resending-mfa-code-warning-modal.service';

@Component({
    templateUrl: './verify-mfa-code-modal.component.html',
    styleUrls: ['./verify-mfa-code-modal.component.scss']
})
export class VerifyMfaCodeModalComponent implements OnInit {
    public static CODE_VALID = 'CODE_VALID';
    onClose: Subject<boolean> = new Subject<boolean>();

    @Input() memberId: number | undefined;
    @Input() verificationType: string | undefined;
    @Input() isMemberEditPage = true;
    @Input() resolveCellPhoneBlockerCallback: (() => void | undefined) | undefined;

    codeIsRequiredTranslation = '';
    codeCouldNotBeSentTranslation = '';
    validationErrorMessage = '';
    codeIsBeingSent = true;
    codeIsSent = false;
    codeSendingError = '';
    validationErrorMessageHappened = false;
    securityCode: number | undefined;
    securityCodeIsValidating = false;
    dismissReason = '';
    showSecurityCode = false;
    modalRef: BsModalRef = new BsModalRef();

    constructor(
        private bsModalRef: BsModalRef,
        private translateService: TranslateService,
        private memberCodeVerificationService: MemberMfaCodeVerificationService,
        private resendingMfaCodeWarningModalService: ResendingMfaCodeWarningModalService,
        private changeDetection: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.sendSecurityCode();
        this.codeIsRequiredTranslation = this.translateService.instant('EnterCode');
        this.codeCouldNotBeSentTranslation = this.translateService.instant('CodeCouldntBeSentRequestNew');
    }

    sendSecurityCode() {
        this.validationErrorMessage = '';
        this.codeIsBeingSent = true;
        this.codeIsSent = false;
        this.codeSendingError = '';
        this.validationErrorMessageHappened = false;
        if (this.memberId && this.verificationType) {
            this.memberCodeVerificationService.sendMfaCode(this.memberId, this.verificationType).subscribe(() => {
                this.codeIsBeingSent = false;
                this.codeIsSent = true;
                this.runChangeDetection();
            }, (response) => {
                this.codeIsBeingSent = false;
                this.extractProperErrorMessageFromResponse(response);
                this.runChangeDetection();
            });
        }
    }

    extractProperErrorMessageFromResponse(errorResponse: any) {
        if (errorResponse.error.message) {
            this.validationErrorMessage = errorResponse.error.message;
            this.validationErrorMessageHappened = true;
            this.runChangeDetection();
        } else {
            this.codeSendingError = this.codeCouldNotBeSentTranslation;
            this.runChangeDetection();
        }
    }

    verifySecurityCode(): string | void {
        if (!this.securityCode) {
            this.validationErrorMessage = this.codeIsRequiredTranslation;
            return this.validationErrorMessage;
        }

        this.securityCodeIsValidating = true;
        this.codeSendingError = '';
        if (this.memberId && this.verificationType) {
            this.memberCodeVerificationService.verifyMfaCode(this.memberId, this.verificationType, this.securityCode).subscribe(() => {
                this.dismissReason = VerifyMfaCodeModalComponent.CODE_VALID;
                this.closeModal(true);
            }, (response) => {
                this.securityCodeIsValidating = false;
                this.validationErrorMessageHappened = false;
                this.extractProperErrorMessageFromResponse(response);
                this.runChangeDetection();
            });
        }
    }

    showResendSecurityCodeModal() {
        this.modalRef = this.resendingMfaCodeWarningModalService.open();
        this.modalRef.content.closeCallback.subscribe((shouldResendCode: any) => {
            if (shouldResendCode) {
                this.sendSecurityCode();
            }
        });
    }

    toggleShowingOfSecurityCode() {
        this.showSecurityCode = !this.showSecurityCode;
        this.runChangeDetection();
    }

    closeModal(verified = false) {
        this.onClose.next(verified);
        this.bsModalRef.hide();
    }

    getBannerClasses() {
        if (this.codeSendingError) {
            return 'background-clr-light-red text-clr-error text-center';
        } else if ((this.codeIsBeingSent || this.codeIsSent) && !this.validationErrorMessage) {
            return 'background-clr-persian-green text-clr-white';
        }

        return 'not-shown';
    }

    numberOnly(event: any): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    private runChangeDetection() {
        this.changeDetection.detectChanges();
    }
}
