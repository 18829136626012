import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LanguageModel } from '@genesis-frontend/genesis-utilities';


@Injectable({
    providedIn: 'root'
})
export class LanguagesService {
    constructor(
        private httpClient: HttpClient
    ) { }

    getLanguageList(): Observable<LanguageModel[]> {
        return this.httpClient.get<LanguageModel[]>('/api/languages');
    }
}
