import { Injectable } from '@angular/core';

import { AuthenticatedMemberService } from '../authentication';
import { SupportSettings } from '../models';

@Injectable({
    providedIn: 'root'
})
export class ZopimService {
    private GENERAL_SUPPORT = 'General Support';
    private PREMIUM_SUPPORT = 'Premium Support';
    private windowObj = window as any;

    private LANGUAGE_CODES = {
        englishAmerican: 'en_us',
        englishUK: 'en_gb',
        spanishMexican: 'es_mx',
        spanishEuropean: 'es_es',
        frenchCanadian: 'fr_ca',
        frenchEuropean: 'fr_fr',
        german: 'de_de',
        portugueseBrazilian: 'pt_br',
        portuguese: 'pt_br',
        russian: 'ru_ru',
        vietnamese: 'vi_vn',
        japanese: 'ja_jp',
        chineseSimplified: 'zh_cn',
        chineseTraditional: 'zh_hant',
        swedish: 'sv_se',
        polish: 'pl_pl',
        malay: 'ms_my',
        italian: 'it_it'
    };

    private ZOPIM_LANGUAGE_CODES = {
        englishAmerican: 'en',
        englishUK: 'en',
        spanishMexican: 'es',
        spanishEuropean: 'es',
        frenchCanadian: 'fr',
        frenchEuropean: 'fr',
        german: 'de',
        portugueseBrazilian: 'pt_BR',
        portuguese: 'pt',
        russian: 'ru',
        vietnamese: 'vi',
        japanese: 'ja',
        chineseSimplified: 'zh_CN',
        chineseTraditional: 'zh_TW',
        swedish: 'sv',
        polish: 'pl',
        malay: 'ms',
        italian: 'it'
    };

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService
    ) { }

    getZopimLanguageCode(language: string): string {
        switch (language) {
        case this.LANGUAGE_CODES.englishAmerican:
            return this.ZOPIM_LANGUAGE_CODES.englishAmerican;

        case this.LANGUAGE_CODES.englishUK:
            return this.ZOPIM_LANGUAGE_CODES.englishUK;

        case this.LANGUAGE_CODES.spanishMexican:
            return this.ZOPIM_LANGUAGE_CODES.spanishMexican;

        case this.LANGUAGE_CODES.spanishEuropean:
            return this.ZOPIM_LANGUAGE_CODES.spanishEuropean;

        case this.LANGUAGE_CODES.frenchCanadian:
            return this.ZOPIM_LANGUAGE_CODES.frenchCanadian;

        case this.LANGUAGE_CODES.frenchEuropean:
            return this.ZOPIM_LANGUAGE_CODES.frenchEuropean;

        case this.LANGUAGE_CODES.german:
            return this.ZOPIM_LANGUAGE_CODES.german;

        case this.LANGUAGE_CODES.portugueseBrazilian:
            return this.ZOPIM_LANGUAGE_CODES.portugueseBrazilian;

        case this.LANGUAGE_CODES.portuguese:
            return this.ZOPIM_LANGUAGE_CODES.portuguese;

        case this.LANGUAGE_CODES.russian:
            return this.ZOPIM_LANGUAGE_CODES.russian;

        case this.LANGUAGE_CODES.vietnamese:
            return this.ZOPIM_LANGUAGE_CODES.vietnamese;

        case this.LANGUAGE_CODES.japanese:
            return this.ZOPIM_LANGUAGE_CODES.japanese;

        case this.LANGUAGE_CODES.chineseSimplified:
            return this.ZOPIM_LANGUAGE_CODES.chineseSimplified;

        case this.LANGUAGE_CODES.chineseTraditional:
            return this.ZOPIM_LANGUAGE_CODES.chineseTraditional;

        case this.LANGUAGE_CODES.swedish:
            return this.ZOPIM_LANGUAGE_CODES.swedish;

        case this.LANGUAGE_CODES.polish:
            return this.ZOPIM_LANGUAGE_CODES.polish;

        case this.LANGUAGE_CODES.malay:
            return this.ZOPIM_LANGUAGE_CODES.malay;

        case this.LANGUAGE_CODES.italian:
            return this.ZOPIM_LANGUAGE_CODES.italian;

        default:
            return this.ZOPIM_LANGUAGE_CODES.englishAmerican;
        }
    }

    hideChat(): void {
        this.windowObj.zE('webWidget', 'close');
    }

    closeChat(): void {
        this.windowObj.zE('webWidget', 'logout');
    }

    showChat(supportSettings: SupportSettings): any {
        const member = this.authenticatedMemberService.getAuthenticatedMember();

        const languageCode = this.getZopimLanguageCode(member.language);
        this.windowObj.zE('webWidget', 'setLocale', languageCode);

        const departmentName = this.getZopimDepartmentName(supportSettings.supportDepartment);
        this.windowObj.zE('webWidget', 'updateSettings', {
            webWidget: {
                chat: {
                    departments: {
                        enabled: [departmentName],
                        select: departmentName
                    }
                }
            }
        });

        this.windowObj.zE('webWidget', 'chat:addTags', `org_${ this.authenticatedMemberService.getSponsor().name }`);
        this.windowObj.zE('webWidget', 'show');
        this.windowObj.zE('webWidget', 'open');
    }

    getZopimDepartmentName(supportDepartment: string): string {
        if (supportDepartment === 'UsOnly') {
            return this.PREMIUM_SUPPORT;
        }
        return this.GENERAL_SUPPORT;
    }
}
