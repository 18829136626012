<vpg-container class="single-claim-container flex-column">
    <p class="provider-name h4"
       *ngIf="claim.providerName"
    >
        {{ claim.providerName }}
    </p>
    <p class="mb-auto body-regular">{{ claim.patientName }}</p>
    <div class="flex flex-wrap mt-lg gap-10">
        <div class="number-container flex-1 flex-column">
            <p
                [id]="'claim-number' + claim.id"
                class="margin-block-none text-no-wrap h5"
                aria-hidden="true"
            >
                {{ translations.CLAIM_NUMBER }}
                <br>
                <span
                    [id]="'claim-number-value' + claim.id"
                    class="body-regular"
                >
                    {{ claim.claimNumber }}
                </span>
            </p>
            <section class="sr-only">
                {{ (translations.CLAIM_NUMBER) + ' ' + claim.claimNumber }}
            </section>
        </div>
        <div class="number-container flex-1 flex-column">
            <p
                [id]="'date-of-service' + claim.id"
                class="margin-block-none h5 text-no-wrap"
                aria-hidden="true"
            >
                {{ translations.DATE_SERVICE }}
                <br>
                <span
                    [id]="'date-of-service-value' + claim.id"
                    class="body-regular"
                >
                {{ claim.serviceStartDate }}
                </span>
            </p>
            <section class="sr-only">
                {{ (translations.DATE_SERVICE) + ' ' + claim.serviceStartDate }}
            </section>
        </div>
    </div>
    <div class="flex gap-10 flex-wrap my-lg">
        <div class="charged-amount-container justify-center flex-1 flex-column min-w-200">
            <p
                [id]="'charged-amount' + claim.id" class="margin-none justify-center flex body-regular"
                aria-hidden="true"
            >
                {{ translations.CHARGED_AMOUNT }}
            </p>
            <span
                class="margin-none justify-center flex h3"
                aria-hidden="true">
                {{ claim.totalChargedAmount }}
            </span>
            <section class="sr-only">
                {{ (translations.CHARGED_AMOUNT) + ' ' + claim.totalChargedAmount }}
            </section>
        </div>
        <div class="charged-amount-container justify-center flex-1 flex-column min-w-200">
            <p
                [id]="'you-may-owe' + claim.id"
                class="margin-none justify-center flex body-regular"
                aria-hidden="true"
            >
                {{ translations.MAY_OWE }}
            </p>
            <span
                class="margin-none justify-center flex h3"
                aria-hidden="true">
                {{ claim.patientResponsibilityAmount }}
            </span>
            <section class="sr-only">
                {{ (translations.MAY_OWE) + ' ' + claim.patientResponsibilityAmount }}
            </section>
        </div>
    </div>
    <a vpg-link
       [attr.aria-label]="(translations.VIEW_DETAILS) + ' ' + (translations.CLAIM_NUMBER) + ' ' + claim.claimNumber"
       [id]="'view-details-button' + claim.id"
       class="block text-decoration-none"
       href="#/benefits/claims/{{claim.id}}"
       (click)="sendAnalytics()"
    >
        {{ viewDetailsFormatted }}
        <vpg-icon-next icon="chevron-right"></vpg-icon-next>
    </a>
</vpg-container>
