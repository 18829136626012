export enum VPGColors {
    PrimaryBase = '#0E7B8E',
    PrimaryDark = '#055963',
    PrimaryLight = '#E0F5FB',
    SecondaryBase = '#F28C00',
    NeutralGray8 = '#2D3E47',
    NeutralGray7 = '#7F898F',
    NeutralGray6 = '#A7AEB1',
    NeutralGray5 = '#BCC1C3',
    NeutralGray4 = '#D0D3D5',
    NeutralGray3 = '#E5E6E7',
    NeutralGray2 = '#F2F2F3',
    NeutralGray1 = '#F8F8F8',
    NeutralGrayWhite = '#FFFFFF',
    StatusGreenBase = '#5fa300',
    StatusGreenLight = '#E1EFD3',
    StatusRedLight = '#FFD6D1',
    StatusRedDark = '#A80D11',
    StatusRedDark1 = '#860a0e',
    StatusGoldBase = '#FFCC33',
    StatusGoldLight = '#FFF3CD',
    StatusGoldDark = '#E1A900'
}
