import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

import { AuthenticatedMemberService, FeatureEnum, SingleSpaConfiguration } from '@genesis-frontend/genesis-utilities';

@Injectable({ providedIn: 'root' })
export class DevicesMicroFrontendResolver implements Resolve<SingleSpaConfiguration> {
    constructor(private authenticatedMemberService: AuthenticatedMemberService) {}
    resolve(): any {
        const hasFeatureFlag = this.authenticatedMemberService.hasFeature(FeatureEnum.DevicesAndAppsV2);
        const appImportPath = hasFeatureFlag ? 'devices-and-apps' : 'genesis-ui';

        return {
            appName: 'devices-and-apps',
            appRegex: /^#(\/mobile)?\/devices/,
            isStandAloneApp: false,
            appImportPath
        } as SingleSpaConfiguration;
    }
}
