<div class="global-challenges-team-preview-container">
    <div class="team-confirmation-title">
        <span class="team-title">{{data.selectedTeamForPreview.teamName}}</span>
    </div>
    <div
        class="rally-cry-team-confirmation-screen"
        *ngIf="data.contest.contestType !== vpGoService.FeaturedChallenges.ChallengeTypes.Organizational">
        {{data.selectedTeamForPreview.teamDescription}}
    </div>
    <div
        class="team-image-wrapper-team-preview"
        *ngIf="data.contest.contestType !== vpGoService.FeaturedChallenges.ChallengeTypes.Organizational">
        <img
            class="image-wrapper"
            [src]="data.selectedTeamForPreview.teamLogoUrl" />
    </div>
    <div
        class="team-details-players-container"
        [ngClass]="{'organizational-team-preview' : data.contest.contestType === vpGoService.FeaturedChallenges.ChallengeTypes.Organizational, 
    'non-organizational-team-preview' : data.contest.contestType != vpGoService.FeaturedChallenges.ChallengeTypes.Organizational}">
        <div
            class="your-team-slot-style"
            *ngFor="let teamMember of data.selectedTeamForPreview.teamMembers">
            <img
                class="vp-go-player-image border-clr-bg"
                [src]="teamMember.profilePicture"
                popoverTitle="{{teamMember.displayName}}"
                popover="{{data.selectedTeamForPreview.teamAdminMemberId === teamMember.memberId ? ('TeamCaptain' | translate) : ' '}}"
                triggers="focus hover click"
                placement="top"
                role="tooltip" />
        </div>
        <div
            class="your-team-slot-style"
            *ngFor="let freeslot of freeSlotsArray">
            <img
                class="vp-go-player-image border-clr-bg"
                [src]="'/img/empty-player.png'" />
        </div>
    </div>

    <div
        class="others-team-members-number"
        *ngIf="notDisplayedTeamMembers > 0">
        +{{notDisplayedTeamMembers}} others
    </div>

    <div
        class="card-footer"
        [ngClass]="{'center-card-footer' : data.isFeelingLuckyOption}">
        <div class="global-challenge-onboarding-button vp-padding-15">
            <button
                class="vp-button larger vp-button-primary no-uppercase"
                (click)="joinThisTeam()"
                [translate]="data.isFeelingLuckyOption ? 'JoinThisTeam' : 'JoinIn'"></button>
        </div>
        <div
            class="global-challenge-onboarding-button vp-padding-15"
            *ngIf="data.isFeelingLuckyOption"
            [ngClass]="{'vp-margin-top-0 vp-margin-bottom-0' : isTeamLoading}">
            <button
                class="vp-button larger vp-button-primary no-uppercase"
                (click)="tryAnotherTeam()"
                *ngIf="!isTeamLoading"
                [translate]="'TryAgain'"></button>
            <img
                *ngIf="isTeamLoading"
                class="vp-margin-right-50 vp-margin-left-50 vp-width-55-px"
                [src]="'/img/loading.gif'"
                alt="" />
        </div>
    </div>
    <div
        class="prev-card-btn background-clr-bg background-dark text-clr-white"
        *ngIf="showLeftArrow"
        (click)="goToPreviousCard()"
        role="button"
        tabindex="0"
        [attr.aria-label]="'previous' | translate">
        <i class="fa fa-angle-left"></i>
    </div>
</div>
