import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { AuthenticatedUserService } from './authenticated-user.service';
import { SessionCache } from '../../core/session-cache';
import { AUTHENTICATED_USER_SERVICE, ShellApplication, SHELL_APPLICATION } from '../../core/shell-application';
import { Sponsor } from '../../models';

@Injectable({
    providedIn: 'root'
})
export class SelectedSponsorService {

    private sponsor: Sponsor = {} as Sponsor;
    private cacheKey = 'SelectedSponsorService';
    private sessionCache = new SessionCache<any>(this.cacheKey);

    constructor(
        private httpClient: HttpClient,
        @Inject(AUTHENTICATED_USER_SERVICE) private authenticatedUserService: AuthenticatedUserService,
        @Inject(SHELL_APPLICATION) private shellApplication: ShellApplication
    ) {
        let sponsor = this.authenticatedUserService.getAuthenticatedUser().sponsor;
        if (this.sessionCache.hasItem(this.cacheKey)) {
            sponsor = this.sessionCache.getItem(this.cacheKey)?.value;
        }
        this.setSponsor(sponsor);
    }

    get sponsorReplaySubject(): ReplaySubject<Sponsor> {
        return this.shellApplication.sponsorReplaySubject;
    }

    setSponsor(newSponsor: Sponsor): void {
        this.shellApplication.sponsorReplaySubject.next(newSponsor);
        this.sponsor = newSponsor;
        this.updateCachedInstance();
    }

    getSponsor(): Sponsor {
        return this.sponsor;
    }

    selectSponsor(sponsor: Sponsor) : Promise<Sponsor> {
        return new Promise<Sponsor>((resolve) => {
            this.getSponsorSettings(sponsor.id)
                .subscribe((sponsorSetting: any) => {
                    this.sponsor.settings = sponsorSetting;
                    this.setSponsor(sponsor);
                    resolve(sponsor);
                });
        });
    }

    getSponsorSettings(sponsorId: number): any {
        return this.httpClient.get(`/api/client-admin/sponsors/${sponsorId}/settings`);
    }

    getBenefitSponsorSettings(sponsorId: number): Observable<any> {
        return this.httpClient.get<boolean>(`/benefits-api/admin/sponsors/${sponsorId}/benefit-sponsor-settings`);
    }

    private updateCachedInstance() {
        this.sessionCache.setItem(this.cacheKey, this.sponsor);
    }
}
