import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot, RoutesRecognized, UrlTree } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';

import { AppHistoryService, AuthenticatedMemberService, MemberProduct, Product } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class MemberInitializationService {

    constructor(
        private router: Router,
        private authenticatedMemberService: AuthenticatedMemberService,
        private appHistoryService: AppHistoryService
    ) { }

    private get homeProduct() {
        return this.authenticatedMemberService.getHomeMemberProduct();
    }

    initialCall() {
        this.transitions();

        setTimeout(() => {
            const currentRoute = this.getCurrentPath();
            if (!this.router.routerState.snapshot.url && this.router.routerState.snapshot.url !== '/no-product') {
                if (!this.router.config.some((feature) => {
                    return currentRoute === feature.path;
                })) {
                    this.navigateToRoute();
                }
            }
        });
    }

    private getCurrentPath() {
        const parts = window.location.href.split('/#');
        if (parts.length > 1) {
            return parts[1];
        }
        return '';
    }

    private navigateToRoute(): void {
        this.router.navigate(['home']);
    }

    private shouldDisplayNoProductScreen(memberProducts: MemberProduct[]): boolean {
        return !this.homeProduct || !memberProducts.length || (memberProducts.length === 1 && this.authenticatedMemberService.hasProduct([Product.WorkplacePassport]));
    }

    checkAccess(state: RouterStateSnapshot): boolean | UrlTree {
        const skipRoutes = [
            '/logout',
            '/no-product'
        ];

        if (skipRoutes.includes(state.url)) {
            return true;
        }

        const memberProducts = this.authenticatedMemberService.getMemberProducts();
        if (this.shouldDisplayNoProductScreen(memberProducts)) {
            return this.router.createUrlTree(['/no-product']);
        }
        return true;
    }

    private getProductFromHomeProduct(): Product | undefined {
        if (this.homeProduct.product === Product.BasePlatform) {
            return Product.BasePlatform;
        }

        if (this.homeProduct.product === Product.Wellness) {
            return Product.Wellness;
        }

        const memberProducts = this.authenticatedMemberService.getMemberProducts();

        if (memberProducts.length === 1) {
            return Product.Wellness;
        }

        if (memberProducts.length > 0) {
            return memberProducts[0].product;
        }
    }

    private transitions() {
        const currentRoute = this.getCurrentPath();
        if (currentRoute === '/logout') {
            return;
        }

        this.router.events.pipe(filter((evt: any) => evt instanceof RoutesRecognized), pairwise())
            .subscribe((events: RoutesRecognized[]) => {
                const previousUrl = events[0].urlAfterRedirects;

                if (previousUrl === '/') {
                    if (!this.hasProduct()) {
                        return this.router.navigate(['/home']);
                    }
                } else if (previousUrl === '/surveys-ui') {
                    this.appHistoryService.addLocation('#' + previousUrl);
                }
            });
    }

    private hasProduct() {
        const productBeingNavigatedTo = this.getProductFromHomeProduct();
        if (productBeingNavigatedTo) {
            return this.authenticatedMemberService.hasProduct([productBeingNavigatedTo]);
        }
        return false;
    }
}
