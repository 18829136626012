/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AnalyticsTrackingSingleSpaService } from './analytics-tracking-single-spa.service';
import { MessageBusService } from './custom-message-bus.service';
import { AuthenticatedMemberService } from '../authentication';
import { EventBus } from '../core/event-bus';
import { Events } from '../core/events';
import {
    AnalyticsTrackingAction,
    BenefitsFeaturesModel,
    BenefitSponsorSettingModel,
    CustomBehaviorSubjectMessage,
    CustomSubjectEventType,
    Guide,
    Member,
    MemberSurvey, MicrofrontendNames
} from '../models';

@Injectable({
    providedIn: 'root'
})
export class DomainNavigationService {

    member: Member = this.authenticatedMemberService.getAuthenticatedMember();
    historicalSurveys: MemberSurvey[] = [];
    surveys: MemberSurvey[] = [];

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private httpClient: HttpClient,
        private messageBusService: MessageBusService,
        private analyticsTrackingService: AnalyticsTrackingSingleSpaService,
        private eventBus: EventBus<Events>
    ) {}

    getBenefitSponsorSettings(): Observable<BenefitSponsorSettingModel> {
        return this.httpClient.get<BenefitSponsorSettingModel>
        (`/benefits-api/sponsors/${this.member.sponsorId}/benefit-sponsor-settings`);
    }

    getHeader(): HttpHeaders {
        const header = new HttpHeaders();
        return header.append('Time-Zone-Id', this.member.timezone.javaTimezone.toString());
    }

    getBenefitsFeatures(): Observable<BenefitsFeaturesModel> {
        const headers = this.getHeader();

        return this.httpClient.get<BenefitsFeaturesModel>(`/benefits-api/sponsors/${this.member.sponsorId}/members/${this.member.id}/member-benefits-features`, {
            headers
        });
    }

    getAnalytics(): Observable<any> {
        return this.httpClient.get('/api/domo-keycloak-client/sso-url');
    }

    getMemberDomoAccess(memberId: number): Observable<any> {
        return this.httpClient.get(`/api/members/${memberId}/domo-access`);
    }

    getPillarTopic(memberId: number): Observable<any> {
        return this.httpClient.get(`/api/members/${memberId}/pillars/settings`);
    }

    trackBenefitsNavigateEvent(): void {
        this.httpClient.post<any>(
            `/benefits-api/sponsors/${this.member.sponsorId}/members/${this.member.id}/event-tracking`,
            null
        ).subscribe();
    }

    trackDigitalWalletVisitEvent(): void {
        this.httpClient.post<any>(
            `/benefits-api/sponsors/${this.member.sponsorId}/members/${this.member.id}/event-tracking/digital-wallet`,
            null
        ).subscribe();
    }

    getHistoricalSurveys(): MemberSurvey[] {
        return this.historicalSurveys;
    }

    getRolesForMember(memberId: number): Observable<any> {
        return this.httpClient.get(`/api/members/${memberId}/roles`);
    }

    getHistoricalSurveysForMember(memberId: number): Observable<MemberSurvey[]> {
        return this.httpClient.get(`/api/members/${memberId}/historical-surveys`).pipe(
            map((surveys: any) => {
                this.historicalSurveys = surveys;
                return surveys;
            })
        );
    }

    getSurveysForMember(memberId: number): Observable<MemberSurvey[]> {
        return this.httpClient.get(`/api/members/${memberId}/program-pages/surveys`).pipe(
            map((surveys: any) => {
                this.surveys = surveys;
                return surveys;
            })
        );
    }

    getSurveys(): MemberSurvey[] {
        return this.surveys;
    }

    getMemberGuides(memberId: number): Observable<Guide[]> {
        return this.httpClient.get(`/api/members/${memberId}/guides`, {
            observe: 'response'
        }
        ).pipe(map((response: any) => response.body));
    }

    getProviderInfo(memberId: number): Observable<any> {
        return this.httpClient.get(`/api/members/${memberId}/HRA/provider`);
    }

    goToRewardsPage(analyticsTrackingAction: AnalyticsTrackingAction): void {
        this.analyticsTrackingService.sendData(analyticsTrackingAction);
        const routingPath = 'rewards-microfrontend.earn';
        // genesis-ui (will be deleted after removing genesis-ui)
        this.messageBusService.dispatch({
            appName: MicrofrontendNames.NavigationMenu,
            eventType: CustomSubjectEventType.RoutingEvent,
            data: {
                state: routingPath,
                params: {}
            }
        } as CustomBehaviorSubjectMessage);
        // new shell-app
        this.eventBus.dispatch('navigate', { path: routingPath });

    }
}
