import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { WebsocketService } from '@app/core/services/websocket.service';
import { NotificationsMessengerService } from '@genesis-frontend/genesis-features';

interface MemberIdentity {
    id: number | string;
}

interface Notification {
    status?: string;
    [key: string]: any;
}

type NotificationObserver = (notification: Notification) => void;

@Injectable({ providedIn: 'root' })
export class SynchronizationNotificationService {
    private observers: NotificationObserver[] = [];

    constructor(
        private translateService: TranslateService,
        private notificationMessengerService: NotificationsMessengerService,
        private websocketService: WebsocketService
    ) {
    }

    private get notificationMessage() {
        return this.translateService.instant('DataSynced');
    }

    init(member: MemberIdentity) {
        const challengeChannel = this.websocketService.connect(
            'synchronization-notification-' + member.id
        );
        challengeChannel.on('notification', this.handleNotification);
    }

    subscribe(observer: NotificationObserver) {
        this.observers.push(observer);
    }

    unsubscribe(observer: NotificationObserver) {
        this.observers = this.observers.filter((e) => e !== observer);
    }

    private handleNotification = (notification: Notification) => {

        if (notification.status === 'Complete') {
            this.completeNotification();
        }

        for (const observer of this.observers) {
            observer(notification);
        }
    };

    private completeNotification() {
        this.notificationMessengerService.showNotification(this.notificationMessage);
    }
}
