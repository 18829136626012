import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ChoiceInteraction } from '../models/interfaces/choice-interaction.model';
import { IqDialogue } from '../models/interfaces/iq-dialogue.model';
import { IqNode } from '../models/interfaces/iq-node.model';
import { MemberDeviationPostResponse } from '../models/interfaces/member-deviation-post-response.model';

@Injectable({
    providedIn: 'root'
})
export class IqDialoguesService {
    private IQDIALOGUE_URL = '/api/iq-dialogues/';
    private IQDIALOGUECONV_URL = '/api/conversation/';

    constructor(
        private httpClient: HttpClient
    ) {
    }

    getMemberIqDialogue(): Observable<IqDialogue> {
        return this.httpClient.get<IqDialogue>(this.IQDIALOGUE_URL);
    }

    addMemberConversationInteraction(choiceInteraction: ChoiceInteraction): Observable<MemberDeviationPostResponse> {
        return this.httpClient.post<MemberDeviationPostResponse>(this.IQDIALOGUECONV_URL + 'choice/interaction', choiceInteraction);
    }

    getMemberIqDialogueNode(nodeId: number): Observable<IqNode> {
        return this.httpClient.get<IqNode>(this.IQDIALOGUECONV_URL + `node/${nodeId}`);
    }
}
