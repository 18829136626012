import { Component, OnDestroy, OnInit } from '@angular/core';

import { NotificationsMessengerService } from './notifications-messenger.service';
import { AuthenticatedMemberService, GenesisRewardsProgressService } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'genesis-frontend-notifications',
    styleUrls: ['./notifications.component.scss'],
    templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit, OnDestroy {

    TIME_TO_LIVE = 10000;

    notification = {
        message: '',
        show: false
    };
    notificationTimeout: any;

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private notificationMessengerService: NotificationsMessengerService,
        private genesisRewardsProgressService: GenesisRewardsProgressService
    ) {
        this.newNotification = this.newNotification.bind(this);
        this.updateNotification = this.updateNotification.bind(this);
    }

    ngOnInit(): void {
        this.notificationMessengerService.subscribe(this.getNotification.bind(this));
    }

    ngOnDestroy(): void {
        this.notificationMessengerService.unsubscribe(this.getNotification.bind(this));
    }

    closeCustomNotification() {
        this.resetNotificationTimer();
        this.notification.show = false;
    }

    getNotification(data: string) {
        let notificationFunction;
        const sponsorSettings = this.authenticatedMemberService.getSponsorSettings();
        setTimeout(() => {
            this.genesisRewardsProgressService.loadRewardsProgressBar(true, null);
        }, 1000);

        if (sponsorSettings.hasRewardFlyoutEnabled) {
            notificationFunction = this.notification.message ? this.updateNotification : this.newNotification;
            notificationFunction(data);
        }
    }

    newNotification(data: string) {
        const notification = this.notification;
        notification.message = data;
        notification.show = true;

        this.notificationTimeout = setTimeout(() => {
            this.notification.show = false;
        }, this.TIME_TO_LIVE);
    }

    updateNotification(data: string) {
        const SHORT_TIMEOUT = 500;
        this.resetNotificationTimer();

        setTimeout(() => {
            this.notification.show = true;
            this.notification.message = data;
        }, SHORT_TIMEOUT);

        this.notificationTimeout = setTimeout(() => {
            this.notification.show = false;
        }, this.TIME_TO_LIVE);
    }

    resetNotificationTimer() {
        this.notificationTimeout = 0;
    }
}
