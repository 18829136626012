<vpg-popover>
    <button
        vpg-button
        variant="primary"
        class="add-to-calendar-button"
        id="add-to-calendar-button"
        vpg-popover-menu-trigger
    >
        <vpg-icon-next icon="calendar-alt"></vpg-icon-next>
       {{ ADD_TO_CALENDAR | translate }}
    </button>
    <vpg-popover-menu>
            <vpg-popover-menu-item
                *ngFor="let option of exportOptions"
                (click)="exportEvent(option.label)"
                id="calendar-type-{{ option.label }}"
            >
                <img
                    *ngIf="option.imageSource"
                    [src]="option.imageSource"
                    alt="{{ option.label }}"
                />
                {{ option.label | translate }}
            </vpg-popover-menu-item>
    </vpg-popover-menu>
</vpg-popover>
