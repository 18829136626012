import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { CustomBehaviorSubjectMessage } from '../models';

export type MessageBusEventObserver = (value: CustomBehaviorSubjectMessage) => void;

/**
 * @deprecated Class will be refactored/replaced
 */
@Injectable({ providedIn: 'root' })
export class MessageBusService {

    private messageBus: Subject<any>;

    constructor() {
        const w: any = window as any;
        w.messageBus = w.messageBus || new Subject<any>();
        this.messageBus = w.messageBus;
    }

    subscribe(observer: MessageBusEventObserver): Subscription {
        return this.messageBus.subscribe(observer);
    }

    dispatch(message: CustomBehaviorSubjectMessage) {
        this.messageBus.next(message);
    }
}
