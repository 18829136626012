import { Component, Input } from '@angular/core';

import { DialogRef } from '@engineering/vpgroove-angular';

@Component({
    templateUrl: './global-challenges-rules.component.html'
})
export class GlobalChallengeRulesModal {
    @Input() rulesHtml!: string;
    readonlyclassName = 'global-challenge-rules-modal';

    constructor(
        private dialogRef: DialogRef
    ) {}

    close() {
        this.dialogRef.close();
    }
}
