import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';

import { JourneySurveyModalComponent } from './journey-survey-modal.component';
import { JourneySurveyModalService } from './journey-survey-modal.service';
import { VpgrooveModule } from '@engineering/vpgroove-angular';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ModalModule.forRoot(),
        VpgrooveModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [
        JourneySurveyModalComponent
    ],
    providers: [
        BsModalService,
        JourneySurveyModalService
    ],
    exports: []
})
export class JourneySurveyModalModule { }
