import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ColorsService {
    private ADDITIONAL_COLORS_LIST_CYCLES = 10;
    private graphColors = this.addCopies(
        [
            '#F8CC1C',
            '#FA9800',
            '#D1560F',
            '#6C2000',
            '#391000',
            '#F8B21C',
            '#FA8000',
            '#FF5A00',
            '#D1320F',
            '#FFA600',
            '#AE6A00',
            '#E57100'
        ],
        this.ADDITIONAL_COLORS_LIST_CYCLES
    );

    private blueCelebrationBubbleColors = [
        'rgba(124, 222, 226, 0.8)',
        'rgba(13, 183, 194, 0.8)',
        'rgba(0, 148, 165, 0.8)',
        'rgba(0, 128, 133, 0.8)',
        'rgba(0, 141, 156, 0.8)',
        'rgba(124, 222, 227, 0.8)'
    ];

    constructor() {}

    getGraphBarColors() {
        return this.graphColors;
    }

    getBlueCelebrationBubbleColors() {
        return this.blueCelebrationBubbleColors;
    }

    getColorLuminance(hex: string, lum: number) {
        return this.convertToDecimal(this.getHexString(hex), lum);
    }

    private addCopies(arr: string[], count: number) {
        let result: string[] = [];
        for (let i = 0; i < count; i++) {
            result = result.concat(arr);
        }
        return result;
    }

    private getHexString(hexadecimal: string) {
        const HEX_LENGTH = 6;
        const HEX_FIRST = 0;
        const HEX_SECOND = 1;
        const HEX_THIRD = 2;
        let hexString = hexadecimal.replace(/[^0-9a-f]/gi, '');
        if (hexString.length < HEX_LENGTH) {
            hexString =
                hexString[HEX_FIRST] +
                hexString[HEX_FIRST] +
                hexString[HEX_SECOND] +
                hexString[HEX_SECOND] +
                hexString[HEX_THIRD] +
                hexString[HEX_THIRD];
        }
        return hexString;
    }

    private convertToDecimal(hexadecimal: string, lumos: number) {
        const HEX_VALUE = 16;
        const MAX_BYTE = 255;
        const HEX_PAIR = 2;
        const HEX_PAIR_LENGTH = 3;
        const lumosity = lumos || 0;

        let rgb = '#';
        for (let i = 0; i < HEX_PAIR_LENGTH; i++) {
            const color = parseInt(hexadecimal.substring(i * HEX_PAIR, HEX_PAIR), HEX_VALUE);
            let colorStr = Math.round(Math.min(Math.max(0, color + color * lumosity), MAX_BYTE)).toString(HEX_VALUE);
            if (colorStr.length < HEX_PAIR) {
                colorStr = '0' + colorStr;
            }
            rgb += colorStr;
        }
        return rgb;
    }
}
