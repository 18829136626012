import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({ selector: '[scroller]' })
export class ScrollerDirective {
    @Input() shouldLoadMore = false;
    @Input() currentPage = 0;
    @Output() loadMoreItems: EventEmitter<number> = new EventEmitter<number>();
    @Input() resetDropdown = false;

    nativeEl: HTMLElement;
    lastScrollHeight = 0;

    constructor(elementRef: ElementRef<HTMLElement>) {
        this.nativeEl = elementRef.nativeElement;
    }

    @HostListener('scroll') onScroll() {
        if (this.nativeEl.scrollTop + this.nativeEl.offsetHeight >= this.nativeEl.scrollHeight) {
            if (this.loadMoreItems && this.shouldLoadMore && (this.nativeEl.scrollHeight > this.lastScrollHeight)) {
                if (this.resetDropdown) {
                    this.nativeEl.scrollTo(0, 0);
                } else {
                    this.lastScrollHeight = this.nativeEl.scrollHeight;
                }
                this.currentPage += 1;
                this.loadMoreItems.emit(this.currentPage);
            }
        }
    }
}
