import { Component, Input } from '@angular/core';

import { MultimodalWaitingRoomVM } from '../../models/multimodal-waiting-room-vm.model';

@Component({
    selector: 'genesis-frontend-multimodal-waiting-room',
    templateUrl: './multimodal-waiting-room.component.html',
    styleUrls: ['./multimodal-waiting-room.component.scss']
})
export class MultimodalWaitingRoomComponent {
    @Input() multimodalWaitingRoom!: MultimodalWaitingRoomVM;
    @Input() screenreaderText?: string;
}
