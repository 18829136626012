export enum MenuType {
    None = 'None',
    Home = 'Home',
    GoHome = 'GoHome',
    Benefits = 'Benefits',
    GoBenefits = 'GoBenefits',
    Stats = 'Stats',
    StatsDashboard = 'StatsDashboard',
    HealthyHabits = 'HealthyHabits',
    Rewards = 'Rewards',
    FindCare = 'FindCare',
    MyWallet = 'MyWallet',
    MyMedicalPlan = 'MyMedicalPlan',
    MyFinances = 'MyFinances',
    Media = 'Media',
    Support = 'Support',
    Health = 'Health',
    GoHealth = 'GoHealth',
    Journeys = 'Journeys',
    Transform = 'Transform',
    LiveServiceCoaching = 'LiveServiceCoaching',
    Coaching = 'Coaching',
    MyCareChecklist = 'MyCareChecklist',
    Challenges = 'Challenges',
    ChallengeRedesign = 'ChallengeRedesign',
    Social = 'Social',
    GoSocial = 'GoSocial',
    Friends = 'Friends',
    Groups = 'Groups',
    ShoutOuts = 'ShoutOuts',
    EventsCalendar = 'EventsCalendar',
    More = 'More',
    MyProfile = 'MyProfile',
    DevicesAndApps = 'DevicesAndApps',
    Store = 'Store',
    TopicsOfInterest = 'TopicsOfInterest',
    TrophyCase = 'TrophyCase',
    Pillars = 'Pillars',
    Analytics = 'Analytics',
    AdminPortal = 'AdminPortal',
    SignOut = 'SignOut',
    Settings = 'Settings',
    Survey = 'Survey',
    ProviderInfoCompleted = 'ProviderInfoCompleted',
    ProviderInfoUnCompleted = 'ProviderInfoUnCompleted',
    SleepGuide = 'SleepGuide',
    NutritionGuide = 'NutritionGuide'
}
