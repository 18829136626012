<div class="global-challenges-team-card-container">
    <div
        class="team-card-content col-xs-6 team-card-content light-blue-gradient"
        *ngIf="isFeelingLuckyOption">
        <div class="row">
            <div class="col-xs-4">
                <div class="team-image">
                    <i
                        class="fas fa-users"
                        aria-hidden="true"></i>
                </div>
            </div>
            <div class="col-xs-7 text-container">
                <div
                    class="row team-header"
                    [translate]="'SurpriseMe'">
                    Surprise me!
                </div>
                <div
                    class="row team-description"
                    [translate]="'HelpFindingTeam'">
                    We'll help you find a team that's looking for members.
                </div>
            </div>
        </div>
        <div class="random-team-button">
            <button
                class="details-button btn"
                (click)="viewRandomTeamDetails()"
                [translate]="'LetsGo'">
                Let’s go
            </button>
        </div>
    </div>

    <div
        class="team-card-content col-xs-6"
        *ngFor="let team of teams">
        <div class="row">
            <div class="col-xs-4">
                <img
                    [src]="team.teamLogoUrl | compressFilePickerImage: 400"
                    class="team-image" />
            </div>
            <div class="col-xs-7 text-container">
                <div class="row team-header vp-text-link">{{team.teamName}}</div>
                <div
                    class="row team-description italic"
                    [ngClass]="{ 'text-overflow-container': team.teamDescription.length > 50 }">
                    {{team.teamDescription}}
                </div>

                <div
                    *ngIf="maxTeamPlayers - team.teamMembers.length > 1"
                    class="row team-left-spots"
                    [innerHTML]="'VpGoSpotsAvailable' | translate:{ number: maxTeamPlayers - team.teamMembers.length }">
                    {{maxTeamPlayers - team.teamMembers.length}} spots available
                </div>

                <div
                    *ngIf="maxTeamPlayers - team.teamMembers.length === 1"
                    class="row team-left-spots"
                    [innerHTML]="'VpGoSpotAvailable' | translate:{ number: maxTeamPlayers - team.teamMembers.length }">
                    {{maxTeamPlayers - team.teamMembers.length}} spot available
                </div>
            </div>
        </div>
        <div
            class="invited-name vp-highlight-info"
            *ngIf="team.teamAdmin">
            <span
                class="text-overflow-container"
                [translate]="'VpGoFullNameInvitedYou' | translate:{ fullName: team.teamAdmin }">
                {{ team.teamAdmin }} invited you!
            </span>
        </div>
        <div class="details-button-position">
            <ng-container *ngIf="!team.private || team.teamAdmin">
                <button
                    *ngIf="(maxTeamPlayers - team.teamMembers.length) > 0"
                    class="details-button btn vp-button-primary"
                    (click)="viewTeamDetails(team)"
                    [translate]="'VpGoViewTeamDetailsCapitalized'">
                    View Team Details
                </button>
                <div
                    *ngIf="(maxTeamPlayers - team.teamMembers.length) < 1"
                    class="team-left-spots"
                    [translate]="'ThisTeamIsFull'">
                    This team is full.
                </div>
            </ng-container>
            <div
                *ngIf="team.private && !team.teamAdmin"
                class="team-left-spots"
                [translate]="'ThisTeamIsPrivate'">
                This team is private
            </div>
        </div>
    </div>
</div>
