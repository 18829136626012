import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/internal/Observable';
import { filter, concatAll, groupBy, map, mergeMap, reduce, toArray } from 'rxjs/operators';

import { StatsSettingsService } from './stats-settings.service';
import { AuthenticatedMemberService } from '../../authentication';
import { DateUtils } from '../../lib/date-utils';
import {
    ActionType,
    ActivityModel,
    ActivitySuggestionModel,
    StatModel,
    StatSettingsModel,
    StatsTrackerModel,
    StatType
} from '../../models';

@Injectable({
    providedIn: 'root'
})
export class StatsService {
    constructor(
        private httpClient: HttpClient,
        private authenticatedMemberService: AuthenticatedMemberService,
        private statSettingsService: StatsSettingsService,
        private translateService: TranslateService
    ) {}

    getStatsForDateRange(startOfRange: Date, endOfRange: Date): Observable<any> {
        return this.getStatisticTrackersForMember().pipe(
            mergeMap((memberTrackers: StatsTrackerModel[]) =>
                this.getStatTypeWithTracker(memberTrackers)),
            mergeMap((statTypeTracker: { statType: StatType, tracker: StatsTrackerModel }) =>
                this.getMemberStatisticsForDateRangeByTrackerAndStatType(startOfRange, endOfRange, statTypeTracker.tracker, statTypeTracker.statType)
            ),
            mergeMap((trackerWithStatType: { statType: StatType, tracker: StatsTrackerModel }) => trackerWithStatType.tracker.statistics.map((stat: ActivityModel) => {
                return { actionType: trackerWithStatType.tracker.action.actionType, ...stat, activityDate: DateUtils.format(stat.memberDate, 'YYYY-MM-DD'), statType: trackerWithStatType.statType };
            })),
            groupBy((obj: any) => obj.activityDate),
            mergeMap((group) => group.pipe(
                reduce((acc, cur) => {
                    acc.activities.push(cur);
                    return acc;
                }, { activityDate: group.key, activities: new Array<ActivityModel>() })
            )),
            toArray()
        );
    }

    getStatsForDateByStatType(date: Date, currentStat: string): Observable<StatModel[]> {
        return this.getStatisticTrackersForMember().pipe(mergeMap((memberTrackers) =>
            this.getStatTypeWithTracker(memberTrackers)
        ),
        filter((statTypeTracker: { statType: StatType, tracker: StatsTrackerModel }) => {
            return statTypeTracker.statType === currentStat;

        }),
        map((statTypeTracker: { statType: StatType, tracker: StatsTrackerModel }) => {
            return this.getMemberStatisticsForDateRangeByTrackerAndStatType(date, date, statTypeTracker.tracker, statTypeTracker.statType);
        }),
        concatAll(),
        map((trackerWithStatType: { statType: StatType, tracker: StatsTrackerModel }) => {
            return this.createStats(trackerWithStatType);
        }),
        reduce((accumulator, statList) => {
            return accumulator.concat(statList);
        }),
        map((statList) => [...statList].sort((first, second) => first.displayPosition - second.displayPosition)));
    }

    getStatsForDate(date: Date): Observable<StatModel[]> {
        return this.getStatisticTrackersForMember().pipe(mergeMap((memberTrackers) =>
            this.getStatTypeWithTracker(memberTrackers)
        ),
        map((statTypeTracker: { statType: StatType, tracker: StatsTrackerModel }) => {
            return this.getMemberStatisticsForDateRangeByTrackerAndStatType(date, date, statTypeTracker.tracker, statTypeTracker.statType);
        }),
        concatAll(),
        map((trackerWithStatType: { statType: StatType, tracker: StatsTrackerModel }) => {
            return this.createStats(trackerWithStatType);
        }),
        reduce((accumulator, statList) => {
            return accumulator.concat(statList);
        }),
        map((statList) => [...statList].sort((first, second) => first.displayPosition - second.displayPosition)));
    }

    saveActivity(activity: any): Observable<any> {
        return this.httpClient.post('api/diaries', activity);
    }

    getMemberStatisticsForDateRangeByTrackerId(startOfDateRange: Date, endOfDateRange: Date, trackerId: number, dailyHighest: boolean, mostRecentlyReceived: boolean, statType?: string): Observable<StatsTrackerModel> {
        const memberId = this.authenticatedMemberService.getAuthenticatedMember().id;

        let statisticsGetParams = new HttpParams()
            .set('date', DateUtils.format(startOfDateRange, 'YYYY-MM-DD'))
            .set('endDate', DateUtils.format(endOfDateRange, 'YYYY-MM-DD'))
            .set('dailyHighest', dailyHighest);

        if (statType) {
            statisticsGetParams = new HttpParams()
                .set('date', DateUtils.format(startOfDateRange, 'YYYY-MM-DD'))
                .set('endDate', DateUtils.format(endOfDateRange, 'YYYY-MM-DD'))
                .set('dailyHighest', dailyHighest)
                .set('mostRecentlyReceived', mostRecentlyReceived)
                .set('statType', statType);
        }

        return this.httpClient.get<StatsTrackerModel>(`/api/members/${memberId}/trackers/${trackerId}/statistics`, {
            params: statisticsGetParams
        });
    }

    getMemberStatisticsForDateRangeByTrackerAndStatType(startOfDateRange: Date, endOfDateRange: Date, tracker: StatsTrackerModel, statType: StatType): Observable<{ statType: StatType, tracker: StatsTrackerModel }> {
        const memberId = this.authenticatedMemberService.getAuthenticatedMember().id;
        let statisticsGetParams = new HttpParams()
            .set('date', DateUtils.format(startOfDateRange, 'YYYY-MM-DD'))
            .set('endDate', DateUtils.format(endOfDateRange, 'YYYY-MM-DD'))
            .set('dailyHighest', false);

        if ((tracker.action.actionType === ActionType.Weight || tracker.action.actionType === ActionType.CaloriesConsumed) && statType) {
            statisticsGetParams = new HttpParams()
                .set('date', DateUtils.format(startOfDateRange, 'YYYY-MM-DD'))
                .set('endDate', DateUtils.format(endOfDateRange, 'YYYY-MM-DD'))
                .set('dailyHighest', false)
                .set('mostRecentlyReceived', true)
                .set('statType', statType);
        }

        return this.httpClient.get<StatsTrackerModel>(`/api/members/${memberId}/trackers/${tracker.id}/statistics`, {
            params: statisticsGetParams
        }).pipe(
            map((memberTracker: StatsTrackerModel) => ({ statType: statType, tracker: memberTracker }))
        );
    }

    getStatTypeWithTracker(trackers: StatsTrackerModel[]): { statType: StatType, tracker: StatsTrackerModel }[] {
        const statTypeTracker: { statType: StatType, tracker: StatsTrackerModel }[] = [];

        trackers.forEach((tracker) => {
            switch (tracker.action.actionType) {
            case ActionType.Weight:
                statTypeTracker.push({ statType: StatType.Cholesterol, tracker: tracker });
                statTypeTracker.push({ statType: StatType.Glucose, tracker: tracker });
                statTypeTracker.push({ statType: StatType.WaistHipSize, tracker: tracker });
                statTypeTracker.push({ statType: StatType.Weight, tracker: tracker });
                statTypeTracker.push({ statType: StatType.BloodPressure, tracker: tracker });
                statTypeTracker.push({ statType: StatType.A1C, tracker: tracker });
                break;
            case ActionType.CaloriesConsumed:
                statTypeTracker.push({ statType: StatType.CaloriesConsumed, tracker: tracker });
                break;
            case ActionType.CaloriesBurned:
                statTypeTracker.push({ statType: StatType.CaloriesBurned, tracker: tracker });
                break;
            case ActionType.Sleep:
                statTypeTracker.push({ statType: StatType.Sleep, tracker: tracker });
                break;
            case ActionType.Steps:
                statTypeTracker.push({ statType: StatType.Steps, tracker: tracker });
                break;
            case ActionType.ManuallyEnteredDurationActivity:
            case ActionType.DurationActivity:
                statTypeTracker.push({ statType: StatType.Workout, tracker: tracker });
                break;
            case ActionType.ActiveMinutes:
                statTypeTracker.push({ statType: StatType.ActiveMinutes, tracker: tracker });
                break;
            case ActionType.StateOfZen:
                statTypeTracker.push({ statType: StatType.MindfulMinutes, tracker: tracker });
                break;
            }
        });

        return statTypeTracker;
    }

    getStatisticTrackersForMember(): Observable<StatsTrackerModel[]> {
        const trackerGetOptions = { params: new HttpParams().set('statisticsOnly', 'true') };
        return this.httpClient.get<StatsTrackerModel[]>('/api/trackers', trackerGetOptions);
    }

    getSuggestedActivities(): Observable<ActivitySuggestionModel[]> {
        return this.httpClient.get<ActivitySuggestionModel[]>('/api/diaries/suggestions');
    }

    createStatModelsFromActivities(activities: any[]) {
        const settings = this.statSettingsService.getDefaultStatSettings();

        return settings.map((setting: StatSettingsModel) => {
            let value: string | null;
            let percent: number | null;

            const possibleActivities = activities.filter((activity) => {
                return activity.statType === setting.statType;
            });

            if (possibleActivities.length > 0) {
                value = setting.valueFunction(possibleActivities);
                percent = value == null ? null : setting.valuePercent(value);
                const stat: StatModel = {
                    title: setting.title,
                    value: value === null ? '' : value,
                    percent: percent,
                    iconPath: setting.iconPath,
                    displayPosition: setting.defaultDisplayPosition,
                    id: setting.id,
                    unitOfMeasure: this.authenticatedMemberService.getAuthenticatedMember().unitOfMeasurement,
                    goalText: setting.goalText(value),
                    statType: setting.statType,
                    tooltip: setting.tooltip,
                    tooltipId: setting.tooltipId,
                    tooltipDescription: setting.tooltipDescription ? setting.tooltipDescription : ''
                };
                return stat;
            }
            value = setting.valueFunction([]);
            percent = value == null ? null : setting.valuePercent(value);
            const stat: StatModel = {
                title: setting.title,
                value: value === null ? '' : value,
                percent: percent,
                iconPath: setting.iconPath,
                displayPosition: setting.defaultDisplayPosition,
                id: setting.id,
                unitOfMeasure: this.authenticatedMemberService.getAuthenticatedMember().unitOfMeasurement,
                goalText: setting.goalText(value),
                statType: setting.statType,
                tooltip: setting.tooltip,
                tooltipId: setting.tooltipId,
                tooltipDescription: setting.tooltipDescription ? setting.tooltipDescription : ''
            };
            return stat;
        });
    }

    private createStats(trackerWithStatType: { statType: StatType, tracker: StatsTrackerModel }): StatModel[] {
        const settings = this.statSettingsService.getDefaultStatSettingsByStatType(trackerWithStatType.statType);

        if (settings == null) {
            return [];
        }

        const value = settings.valueFunction(trackerWithStatType.tracker.statistics);
        const percent = value == null ? null : settings.valuePercent(value);


        return [{
            title: settings.title,
            value: value === null ? '' : value,
            percent: percent,
            iconPath: settings.iconPath,
            displayPosition: settings.defaultDisplayPosition,
            id: settings.id,
            unitOfMeasure: this.authenticatedMemberService.getAuthenticatedMember().unitOfMeasurement.toString(),
            goalText: settings.goalText(value),
            statType: settings.statType,
            tooltip: settings.tooltip,
            tooltipId: settings.tooltipId,
            tooltipDescription: settings.tooltipDescription ? settings.tooltipDescription : ''
        }];
    }

    getDeviceNameByType(activityType: string, manuallyEntered: boolean, possibleDeviceName?: string): string {
        let deviceName = '';

        switch (activityType) {
        case 'BioMetrics':
            deviceName = manuallyEntered ? this.translateService.instant('SelfEntered') : this.translateService.instant('BiometricScreening');
            break;
        case 'Steps':
        case 'ManuallyEnteredDurationActivity':
        case 'StateOfZen':
        case 'MindfulMinutes':
        case 'Weight':
            deviceName = this.translateService.instant('SelfEntered');
            break;
        case 'DailyHighest':
            deviceName = this.translateService.instant('DailyHighest');
            break;
        case 'ConvertedSteps':
            deviceName = this.translateService.instant('ConvertedSteps');
            break;
        case 'Sleep':
            deviceName = this.translateService.instant('SelfEnteredSleep');
            break;
        case 'WhilMeditate':
            deviceName = 'Whil';
            break;
        case 'MaxBuzzSleep':
        case 'MaxBuzzSteps':
            deviceName = 'Max Buzz';
            break;
        case 'AppleHKSleep':
        case 'AppleHKSteps':
        case 'AppleHKSession':
        case 'AppleHKNutrition':
        case 'AppleHealth':
        case 'AppleHKMindfulMinutes':
        case 'AppleHKBiometrics':
            deviceName = 'Apple Health';
            break;
        case 'GoogleFit':
        case 'GoogleFitSteps':
        case 'GoogleFitSleep':
        case 'GoogleFitSession':
        case 'GoogleFitNutrition':
        case 'GoogleFitMindfulMinutes':
        case 'GoogleFitBiometrics':
            deviceName = 'Google Fit';
            break;
        case 'MyFitnessPalCaloriesConsumed':
            deviceName = 'My Fitness Pal';
            break;
        case 'GoogleFitManualBiometrics':
            deviceName = 'Google Fit ' + this.translateService.instant('SelfEntered');
            break;
        case 'AppleHKManualBiometrics':
            deviceName = 'Apple Health ' + this.translateService.instant('SelfEntered');
            break;
        case 'FitbitBiometrics':
        case 'FitbitSession':
            deviceName = 'Fitbit';
            break;
        case 'SalesforceBiometrics':
            deviceName = this.translateService.instant('HealthCoachValidated');
            break;
        case 'SalesforceManualBiometrics':
            deviceName = this.translateService.instant('HealthCoachNonValidated');
            break;
        default:
            if (possibleDeviceName !== 'Unknown') {
                deviceName = possibleDeviceName || '';
            }
            break;
        }

        return deviceName;
    }
}
