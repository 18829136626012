import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ButtonType, SearchResultItemInputs } from '@engineering/vpgroove-angular';
import {
    AnalyticsTrackingManuallyEnteredStatSource,
    ActivityModel,
    ActivitySuggestionModel,
    DatePickerSettings,
    LandingPageAnalyticsTrackingService,
    StatsPageEventService,
    StatsService,
    TrackerHelperService,
    AuthenticatedMemberService,
    UnitOfMeasurement,
    ConversionService,
    StatType,
    DateUtils
} from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'genesis-frontend-stats-modal',
    templateUrl: './stats-modal.component.html',
    styleUrls: ['./stats-modal.component.scss']
})
export class StatsModalComponent implements OnInit, AfterViewInit {
    public onClose: Subject<string> = new Subject<string>();
    member = this.authenticatedMemberService.getAuthenticatedMember();
    workoutDate: Date = this.authenticatedMemberService.getTimeForMemberNow();
    todaysDate: Date = this.authenticatedMemberService.getTimeForMemberNow();
    maxDateString = DateUtils.format(this.authenticatedMemberService.getTimeForMemberNow(), 'YYYY-MM-DD');
    source = AnalyticsTrackingManuallyEnteredStatSource.Other;
    statType = '';
    value = '';
    changedDate = '';
    deleteStatusMessage = '';
    updateStatusMessage = '';
    saveStatusMessage = '';
    activity: any = null;
    steps: number | null = null;
    sleep: number | null = null;
    mindfulMinutes: number | null = null;
    previousActiveElementParent: HTMLElement | null = null;
    elementToFocusOnId = '';
    weight = {
        stone: '',
        pounds: '',
        value: ''
    };
    unitsOfMeasurement = UnitOfMeasurement;
    isWorkoutDateSameAsSelectedDate = true;
    showError = false;
    showAdditionalError = false;
    showEditError = false;
    buttonType = ButtonType;
    dropDownItems: SearchResultItemInputs[] = [];
    mainMinutesEntry: number | null = null;
    editMinutesEntry: number | null = null;
    selectedActivity = '';
    activities: ActivityModel[] = [];
    totalNumberOfSteps = 0;
    stepConstant = 0;
    mainInputStepConstant = 0;
    updateActivityAction = false;
    bloodPressureSystolic: number | null = null;
    bloodPressureDiastolic: number | null = null;
    systolicChanged = false;
    diastolicChanged = false;
    stepLimit = 30000;
    workoutActivityLimit = 480;
    mindfulMinutesLimit = 1440;
    StatType = StatType;
    disableModalInput = false;
    readonly dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';
    readonly memberDateFormat = 'YYYY-MM-DD';
    readonly intRegex = new RegExp('^[0-9]+$');
    readonly floatRegex = new RegExp('^[0-9]+(\\.[0-9]+)?$');
    readonly HOURS_IN_DAY = 24;
    readonly translations = {
        valueOutOfRange: (min: number, max: number) => this.translateService.instant('WeightOutOfRange', { minWeight: min, maxWeight: max })
    };
    private activitySuggestionsMap: Map<string, number> = new Map<string, number>();
    private radix = 10;
    private stoneToPoundConversionFactor = 14;
    protected readonly DateUtils = DateUtils;

    @Input() selectedDateSettings: DatePickerSettings = {
        date: new Date(),
        dateLabel: '',
        dateLabelAria: '',
        backButtonVisible: false,
        forwardButtonVisible: false
    };
    @Input() title = '';
    @Input() closeButtonAriaLabel = '';
    @Input() label = '';
    @Input() size = '';
    @Input() align = '';
    @Input() icon = 'edit';
    @Output() closeDialog: EventEmitter<string | boolean> = new EventEmitter<string | boolean>();
    tabsLabels = ['Track Workout', 'View Activity'];
    selectedTabIndex = 0;
    activitySuggestions$: Observable<ActivitySuggestionModel[]> | undefined;

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private statsService: StatsService,
        private conversionService: ConversionService,
        private statsPageEventService: StatsPageEventService,
        private trackerHelperService: TrackerHelperService,
        private landingPageAnalyticsTrackingService: LandingPageAnalyticsTrackingService,
        private translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.workoutDate = DateUtils.set(this.selectedDateSettings.date, {
            hour: 12,
            minute: 0,
            second: 0,
            millisecond: 0
        });
        this.onClose = new Subject();
        this.activitySuggestions$ = this.statsService.getSuggestedActivities().pipe(
            tap((activitySuggestions) => {
                this.initActivitySuggestionsMap(activitySuggestions);
                this.initDropDownItems(activitySuggestions);
            })
        );
        this.statsPageEventService.dateChangeSelectEvent.subscribe((date: Date) => {
            this.updateDate(date);
        });
        this.previousActiveElementParent = document.activeElement?.parentElement as HTMLElement;
    }

    ngAfterViewInit(): void {
        this.toggleNextDayButton();
        this.refreshStats();
    }

    selectActivityFromDropdown(activityName: string) {
        this.selectedActivity = activityName;
        const stepConst = this.activitySuggestionsMap.get(this.selectedActivity);
        this.mainInputStepConstant = stepConst ? stepConst : 0;
        this.mainMinutesEntry = this.mainMinutesEntry ? this.mainMinutesEntry : 10;
        this.value = this.mainMinutesEntry.toString();
    }

    isUnitOfMeasurement(unit: UnitOfMeasurement) {
        return unit === this.member.unitOfMeasurement;
    }

    isStatType(statType: string): boolean {
        return this.statType === statType;
    }

    onTabIndexChange(index: number) {
        this.selectedTabIndex = index;
    }

    addActivity() {
        this.saveStatusMessage = 'SuccessfullySavedEntry';
        switch (this.statType) {
        case StatType.Steps:
            if (!this.steps || this.steps < 0 || this.steps > this.stepLimit) {
                this.showError = true;
                return;
            }
            this.activity = {
                steps: this.steps,
                memberId: this.member.id,
                activityDescription: 'Self-entered steps',
                activityType: 'Steps',
                memberDate: DateUtils.format(this.workoutDate, this.memberDateFormat),
                activityDate: DateUtils.format(this.workoutDate, this.dateFormat, '', 'UTC')
            };
            this.saveActivityAndCloseModal(this.steps ? this.steps.toString() : '');
            break;
        case StatType.MindfulMinutes:
            if (!this.mindfulMinutes || this.mindfulMinutes <= 0) {
                this.showError = true;
                return;
            }
            this.activity = {
                duration: this.mindfulMinutes,
                memberId: this.member.id,
                activityDescription: 'StateOfZen',
                activityType: 'MindfulMinutes',
                memberDate: DateUtils.format(this.workoutDate, this.memberDateFormat),
                activityDate: DateUtils.format(this.workoutDate, this.dateFormat, '', 'UTC')
            };
            this.saveActivityAndCloseModal(this.mindfulMinutes.toString());
            break;
        case StatType.Sleep:
            if (!this.sleep || this.sleep <= 0) {
                this.showError = true;
                return;
            }
            this.activity = {
                durationInSeconds: this.getSleepInSeconds(this.sleep),
                memberId: this.member.id,
                activityDescription: 'Self-entered Sleep',
                activityType: 'Sleep',
                memberDate: DateUtils.format(this.workoutDate, this.memberDateFormat),
                activityDate: DateUtils.format(this.workoutDate, this.dateFormat, '', 'UTC')
            };
            this.saveActivityAndCloseModal(this.sleep ? this.convertDecimalToHoursMinsSleep(this.sleep) : '');
            break;
        case StatType.Weight:
            if (!this.validateWeight()) {
                return;
            }
            this.activity = {
                weight: this.convertWeightToMetricForDiaries(),
                memberId: this.member.id,
                activityDescription: 'Self-entered weight',
                activityType: 'Weight',
                memberDate: DateUtils.format(this.workoutDate, this.memberDateFormat),
                activityDate: DateUtils.format(this.workoutDate, this.dateFormat, '', 'UTC')
            };
            this.saveActivityAndCloseModal(this.convertWeightToMetricForDiaries().toString());
            break;
        case StatType.BloodPressure:
            if (!this.validateBloodPressure()) {
                return;
            }

            this.activity = {
                activityDate: DateUtils.format(this.workoutDate, this.dateFormat, '', 'UTC'),
                activityDescription: 'ManuallyEntered',
                activityType: 'BioMetrics',
                bloodPressureDiastolic: this.bloodPressureDiastolic,
                bloodPressureSystolic: this.bloodPressureSystolic,
                manuallyEntered: true,
                memberDate: DateUtils.format(this.workoutDate, this.memberDateFormat),
                memberId: this.member.id
            };
            this.addActivityToDiaries().subscribe(() => {
                this.refreshStats();
                this.closeStatsWidget(this.bloodPressureSystolic + '/' + this.bloodPressureDiastolic);
            });
            break;
        case StatType.Workout:
            this.activity = {
                activityDate: DateUtils.format(this.workoutDate, this.dateFormat, '', 'UTC'),
                activityDescription: this.selectedActivity,
                activityType: 'ManuallyEnteredDurationActivity',
                distance: '',
                duration: this.mainMinutesEntry,
                manuallyEntered: true,
                memberId: this.member.id,
                stepConverterToolUpdatedDate: DateUtils.format(this.authenticatedMemberService.getTimeForMemberNow(), this.dateFormat),
                steps: !this.mainInputStepConstant ? 0 : this.mainMinutesEntry ? this.mainInputStepConstant * this.mainMinutesEntry : 0
            };
            this.addActivityToDiaries().subscribe(() => {
                const mainMinutesEntryVal = this.mainMinutesEntry ? this.mainMinutesEntry : 0;
                this.refreshStats();
                this.closeStatsWidget(this.getMaxActivitiesDurationAfterActivityUpdate(mainMinutesEntryVal).toString());
            });
            break;
        default:
            break;
        }
    }

    toggleNextDayButton() {
        this.isWorkoutDateSameAsSelectedDate = DateUtils.isSame(this.todaysDate, this.workoutDate, 'day');
    }

    closeStatsWidget(state: string) {
        this.elementToFocusOnId = this.previousActiveElementParent?.id + '-stat-card-button';

        if (state) {
            this.statsPageEventService.emitModalClose({
                value: state,
                statType: this.statType,
                elementToFocusOnId: this.elementToFocusOnId
            });
        }

        this.showError = false; // Clean up error booleans on stats widget closure.
        this.showAdditionalError = false;
        this.disableModalInput = false;

        this.closeDialog.emit();
    }

    setStepConversionConstantAndInput(activity: ActivityModel, i: number) {
        this.editMinutesEntry = activity.duration;
        const stepConst = this.activitySuggestionsMap.get(activity.activityDescription);
        this.stepConstant = stepConst ? stepConst : 0;
        const editMinutesElementId = `edit-minutes-${i}`;
        const editMinutesElement = document.getElementById(editMinutesElementId);
        if (editMinutesElement) {
            setTimeout(() => {
                editMinutesElement.focus();
            }, 200);
        }
    }

    updateActivity(activityDate: string, activityDescription: string, duration: number, steps: number, memberDate: string) {
        if (this.statType !== StatType.Workout) {
            return;
        }
        this.activity = {
            activityDate: activityDate,
            activityDescription: activityDescription,
            activityType: 'ManuallyEnteredDurationActivity',
            distance: '',
            duration: duration,
            manuallyEntered: true,
            memberId: this.member.id,
            stepConverterToolUpdatedDate: DateUtils.format(this.authenticatedMemberService.getTimeForMemberNow(), this.dateFormat),
            steps: steps,
            memberDate: memberDate
        };
        this.updateActivityAction = true;

        this.addActivityToDiaries().subscribe({
            next: () => {
                this.refreshStats();
            },
            complete: () => {
                this.updateStatusMessage = '';
                this.deleteStatusMessage = '';
            }
        });
    }

    deleteActivity(activity: ActivityModel) {
        this.updateActivity(activity.activityDate, activity.activityDescription, 0, 0, activity.memberDate);
        this.deleteStatusMessage = 'SuccessfullyDeletedEntry';
    }

    saveActivity(activity: ActivityModel) {
        const editMinutesEntryVal = this.editMinutesEntry ? this.editMinutesEntry : 0;
        this.updateActivity(activity.activityDate, activity.activityDescription, editMinutesEntryVal, editMinutesEntryVal * this.stepConstant, activity.memberDate);
        this.updateStatusMessage = 'SuccessfullyUpdatedEntry';
    }

    stringToInt(str: string) {
        return parseInt(str, 10);
    }

    handleError(error: any) {
        return throwError(error || 'Server error');
    }

    onChange(event: Event) {
        this.value = (event.target as HTMLInputElement).value;
    }

    onChangeDate(event: Event) {
        this.changedDate = (event.target as HTMLInputElement).value;
        if (DateUtils.isValid(this.changedDate) && DateUtils.isAfter(this.changedDate, '1900-01-01')) {
            this.statsPageEventService.emitDateChangeSelectEvent(DateUtils.convertStringToDate(this.changedDate));
        }
    }

    isOverMaxAllowable(value: number, max: number): boolean {
        return value ? value <= max : true;
    }

    get isUpdateDisabled() {
        if (this.editMinutesEntry && this.editMinutesEntry > this.workoutActivityLimit) {
            this.showEditError = true;
            return true;
        }
        if (!Number(this.editMinutesEntry)) {
            return true;
        }
        this.showEditError = false;
        return false;
    }

    get isSaveDisabled() {
        const today = DateUtils.startOf(new Date(), 'day');
        const selectedDate = DateUtils.startOf(this.workoutDate, 'day');
        if (DateUtils.isAfter(selectedDate, today)) {
            return true;
        }
        this.showError = false;
        switch (this.statType) {
        case StatType.Steps:
            if (this.steps && this.steps > this.stepLimit) {
                this.showError = true;
                return true;
            }
            if (!Number(this.steps)) {
                return true;
            }
            return !this.isValidNumberFormat;
        case StatType.Sleep:
            if (this.sleep && this.sleep > this.HOURS_IN_DAY) {
                this.showError = true;
                return true;
            }
            if (!Number(this.sleep)) {
                return true;
            }
            return !this.isValidDecimalNumberFormat;
        case StatType.Weight:
            return !this.validateWeight();
        case StatType.MindfulMinutes:
            if (this.mindfulMinutes && this.mindfulMinutes > this.mindfulMinutesLimit) {
                this.showError = true;
                return true;
            }
            if (!Number(this.mindfulMinutes)) {
                return true;
            }
            return !this.isValidNumberFormat;
        case StatType.BloodPressure:
            return !this.validateBloodPressure();
        case StatType.Workout:
            return !this.validateWorkout();
        default:
            return false;
        }
    }

    get isMainInputInvalid() {
        return !this.mainMinutesEntry || this.mainMinutesEntry <= 0 || !this.isOverMaxAllowable(this.mainMinutesEntry, this.workoutActivityLimit);
    }

    get isValidNumberFormat() {
        return this.intRegex.test(this.value);
    }

    get isValidDecimalNumberFormat() {
        return this.floatRegex.test(this.value);
    }

    private saveActivityAndCloseModal(value: string): void {
        this.landingPageAnalyticsTrackingService.trackStatManuallyEntered(this.getStatType(), this.source);
        this.addActivityToDiaries().subscribe(() => {
            this.closeStatsWidget(value);
        }, (err) => {
            this.handleError(err);
        });
    }

    refreshStats() {
        if (this.statType === StatType.Workout) {
            this.statsService.getStatisticTrackersForMember().subscribe((trackers) => {
                const workoutTracker = trackers.find((tracker) => tracker.title === 'Get a Workout');
                if (!workoutTracker) {
                    this.totalNumberOfSteps = 0;
                    return;
                }

                this.statsService.getMemberStatisticsForDateRangeByTrackerId(this.workoutDate, this.workoutDate, workoutTracker.id, false, false, '')
                    .subscribe((tracker) => {
                        this.activities = tracker.statistics;

                        this.totalNumberOfSteps = this.activities
                            .map((activity) => activity.steps)
                            .reduce(((prev, cur) => prev + cur), 0);

                        if (this.updateActivityAction) {
                            const maxDurationForDay = this.getMaxActivitiesDurationAfterActivityUpdate(0);
                            this.statsPageEventService
                                .emitModalClose({
                                    value: maxDurationForDay.toString(),
                                    statType: this.statType,
                                    elementToFocusOnId: ''
                                });
                            this.updateActivityAction = false;
                        }
                    });
            });
        } else {
            let arr;
            let statsValue;
            this.disableModalInput = true;
            this.statsService.getStatsForDateByStatType(this.workoutDate, this.statType).subscribe((statsForDay) => {
                switch (this.statType) {
                case StatType.Steps:
                    arr = statsForDay.filter((item) => item.statType === StatType.Steps);
                    statsValue = (arr[0].value.length === 0 || arr[0].value === null) ? '0' : arr[0].value;
                    this.steps = arr.length > 0 ? (this.stringToInt(statsValue) === 0 ? null : this.stringToInt(statsValue)) : null;
                    break;
                case StatType.Sleep:
                    arr = statsForDay.filter((item) => item.statType === StatType.Sleep);
                    statsValue = (arr[0].value.length === 0 || arr[0].value === null) ? '0' : arr[0].value;
                    this.sleep = null;
                    if (arr.length > 0) {
                        this.sleep = this.stringToInt(statsValue) === 0 ? null : this.stringToInt(statsValue);
                    }
                    break;
                case StatType.Weight:
                    arr = statsForDay.filter((item) => item.statType === StatType.Weight);
                    statsValue = (arr[0].value.length === 0 || arr[0].value === null) ? '' : arr[0].value;
                    this.setWeightFromMetricFromDiaries(statsValue);
                    break;
                case StatType.MindfulMinutes:
                    arr = statsForDay.filter((item) => item.statType === StatType.MindfulMinutes);
                    statsValue = (arr[0].value.length === 0 || arr[0].value === null) ? '0' : arr[0].value;
                    this.mindfulMinutes = arr.length > 0 ? (this.stringToInt(statsValue) === 0 ? null : this.stringToInt(statsValue)) : null;
                    break;
                case StatType.BloodPressure:
                    arr = statsForDay.filter((item) => item.statType === StatType.BloodPressure);
                    statsValue = arr[0].value.length === 0 || arr[0].value === null ? '0' : arr[0].value;
                    statsValue = statsValue.split('/');
                    if (statsValue.length === 2) {
                        this.bloodPressureSystolic = this.stringToInt(statsValue[0]);
                        this.bloodPressureDiastolic = this.stringToInt(statsValue[1]);
                    } else {
                        this.bloodPressureSystolic = null;
                        this.bloodPressureDiastolic = null;
                    }
                    break;
                default:
                }
                this.disableModalInput = false;
            });

        }
    }

    private addActivityToDiaries() {
        return this.statsService.saveActivity(this.activity);
    }

    private validateWeight(): boolean {
        this.showError = false;
        this.showAdditionalError = false;
        const { value, stone, pounds } = this.weight;
        const valueToCheck = parseFloat(value);
        const stoneToCheck = parseInt(stone, this.radix);
        const poundsToCheck = parseFloat(pounds);
        switch (this.member.unitOfMeasurement) {
        case UnitOfMeasurement.Imperial:
            if (!valueToCheck) {
                return false;
            }
            if (valueToCheck < 45 || valueToCheck > 999) {
                this.showError = true;
                return false;
            }
            break;
        case UnitOfMeasurement.Metric:
            if (!valueToCheck) {
                return false;
            }
            if (valueToCheck < 20 || valueToCheck > 453) {
                this.showError = true;
                return false;
            }
            break;
        case UnitOfMeasurement.UK_Imperial:
            this.showError = !!(stoneToCheck && (stoneToCheck < 4 || stoneToCheck > 71));
            this.showAdditionalError = !!(poundsToCheck && (poundsToCheck < 0 || poundsToCheck > 13.9));

            if (!stoneToCheck || !poundsToCheck ||
                this.showError || this.showAdditionalError) {
                return false;
            }
            break;
        default:
            break;
        }
        return this.isValidDecimalNumberFormat;
    }

    private validateBloodPressure() {
        this.showError = !!(this.bloodPressureSystolic &&
            (this.bloodPressureSystolic < 90 || this.bloodPressureSystolic > 225));
        this.showAdditionalError = !!(this.bloodPressureDiastolic &&
            (this.bloodPressureDiastolic < 40 || this.bloodPressureDiastolic > 140));

        if (!this.bloodPressureSystolic || !this.bloodPressureDiastolic ||
            this.showError || this.showAdditionalError) {
            return false;
        }
        return this.isValidNumberFormat;
    }

    private validateWorkout() {
        if (this.mainMinutesEntry && this.mainMinutesEntry > this.workoutActivityLimit) {
            this.showError = true;
            return false;
        }
        if (!this.selectedActivity || !Number(this.mainMinutesEntry)) {
            return false;
        }
        return this.isValidNumberFormat;
    }

    private convertWeightToMetricForDiaries() {
        switch (this.member.unitOfMeasurement) {
        case UnitOfMeasurement.Imperial:
            return this.conversionService.convert(parseFloat(this.weight.value), 'lbs', 'kg');
        case UnitOfMeasurement.Metric:
            return parseFloat(this.weight.value).toFixed(1);
        case UnitOfMeasurement.UK_Imperial:
            this.weight.value = (this.conversionService.convert(parseInt(this.weight.stone, this.radix), 'st', 'lbs') + parseFloat(this.weight.pounds)).toString();
            return this.conversionService.convert(parseFloat(this.weight.value), 'lbs', 'kg');
        default:
            return parseFloat(this.weight.value).toFixed(1);
        }
    }

    private setWeightFromMetricFromDiaries(value: string) {
        if (value.length < 1) {
            this.weight = {
                value: '',
                stone: '',
                pounds: ''
            };
            return;
        }
        switch (this.member.unitOfMeasurement) {
        case UnitOfMeasurement.Imperial:
            this.weight.value = this.conversionService.convert(parseFloat(value), 'kg', 'lbs').toFixed(1);
            break;
        case UnitOfMeasurement.Metric:
            this.weight.value = parseFloat(value).toFixed(1);
            break;
        case UnitOfMeasurement.UK_Imperial:
            // eslint-disable-next-line no-case-declarations
            const weightInPounds = this.conversionService.convert(parseFloat(value), 'kg', 'lbs');
            // eslint-disable-next-line no-case-declarations
            const weightInStone = Math.floor(this.conversionService.convert(weightInPounds, 'lbs', 'st'));
            // eslint-disable-next-line no-case-declarations
            const remainingPounds = weightInPounds % this.stoneToPoundConversionFactor;
            this.weight.stone = weightInStone.toFixed(1);
            this.weight.pounds = remainingPounds.toFixed(1);
            break;
        default:
            break;
        }
    }

    updateDate(date: Date, utcOffset?: number) {
        this.workoutDate = DateUtils.set(
            date,
            {
                hour: 12,
                minute: 0,
                second: 0,
                millisecond: 0
            },
            utcOffset
        );

        this.refreshStats();
    }

    private initActivitySuggestionsMap(activitySuggestions: ActivitySuggestionModel[]) {
        activitySuggestions.forEach((activitySuggestion) => {
            this.activitySuggestionsMap.set(activitySuggestion.activity, activitySuggestion.stepsPerMinute);
        });
    }

    private initDropDownItems(activitySuggestions: ActivitySuggestionModel[]) {
        activitySuggestions.forEach((activitySuggestion) => {
            this.dropDownItems.push({ text: activitySuggestion.activity });
        });
    }

    private getMaxActivitiesDurationAfterActivityUpdate(newDuration: number): number {
        const activityDurations = this.activities
            .map((activity) => activity.duration)
            .concat(newDuration);

        return Math.max(...activityDurations);
    }

    private getSleepInSeconds(sleep: number) {
        const hourAndMinArray = this.trackerHelperService.convertDecimalTimeToHoursMinsArray(sleep);
        return this.trackerHelperService.convertSleepToSeconds(hourAndMinArray[0], hourAndMinArray[1]);
    }

    private convertDecimalToHoursMinsSleep(sleep: number) {
        const hourAndMinArray = this.trackerHelperService.convertDecimalTimeToHoursMinsArray(sleep);
        const hours = hourAndMinArray[0];
        let minutes = hourAndMinArray[1].toString();
        if (minutes.length === 1) {
            minutes = '0' + minutes;
        }
        return `${hours}:${minutes}`;
    }

    private getStatType() {
        let returnVal = StatType.Steps;
        switch (this.statType) {
        case StatType.A1C:
            returnVal = StatType.A1C;
            break;
        case StatType.ActiveMinutes:
            returnVal = StatType.ActiveMinutes;
            break;
        case StatType.BloodPressure:
            returnVal = StatType.BloodPressure;
            break;
        case StatType.BodyTemp:
            returnVal = StatType.BodyTemp;
            break;
        case StatType.CaloriesBurned:
            returnVal = StatType.CaloriesBurned;
            break;
        case StatType.CaloriesConsumed:
            returnVal = StatType.CaloriesConsumed;
            break;
        case StatType.Cholesterol:
            returnVal = StatType.Cholesterol;
            break;
        case StatType.Glucose:
            returnVal = StatType.Glucose;
            break;
        case StatType.MindfulMinutes:
            returnVal = StatType.MindfulMinutes;
            break;
        case StatType.Sleep:
            returnVal = StatType.Sleep;
            break;
        case StatType.Steps:
            returnVal = StatType.Steps;
            break;
        case StatType.Weight:
            returnVal = StatType.Weight;
            break;
        case StatType.Workout:
            returnVal = StatType.Workout;
            break;
        }
        return returnVal;
    }
}
