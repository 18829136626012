    <ul class="vp-flip-clock-number" [ngClass]="{'vp-flip-clock-number-animated': IsAnimated()}">
        <li
         *ngFor="let number of numbers"
          class="vp-flip-clock-number-digit"
          [ngClass]="{'vp-flip-clock-number-digit-active': IsActive(number), 'vp-flip-clock-number-digit-before': IsBefore(number)}"
        >
          <div class="vp-flip-clock-card">
            <div class="vp-flip-clock-card-up">
              <div class="vp-flip-clock-card-shadow"></div>
              <div class="vp-flip-clock-card-value vp-flip-clock-card-value-up">{{number}}</div>
            </div>
            <div class="vp-flip-clock-card-down">
              <div class="vp-flip-clock-card-shadow"></div>
              <div class="vp-flip-clock-card-value vp-flip-clock-card-value-down">{{number}}</div>
            </div>
          </div>
        </li>
      </ul>