import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticatedMemberService } from '../authentication';
import { Feature, FeatureEnum } from '../models';


@Injectable({
    providedIn: 'root'
})
export class FeatureService {

    baseURL = '/api/members';
    private memberFeatures = new Map<string, Feature>();

    constructor(private authenticatedMemberService: AuthenticatedMemberService, private httpClient: HttpClient) { }

    getMemberFeatures(): Observable<Feature[]> {
        const member = this.authenticatedMemberService.getAuthenticatedMember();
        return this.httpClient.get<Feature[]>(`${this.baseURL}/${member.id}/features`);
    }

    getMemberFeaturesByMemberId(memberId: number): Observable<Feature[]> {
        return this.httpClient.get<Feature[]>(`${this.baseURL}/${memberId}/features`);
    }

    memberHasFeature(featureName: string): boolean {
        return this.memberFeatures.has(featureName);
    }

    setMemberFeatures(features: Feature[]) {
        this.memberFeatures = new Map(features.map((feature) => [feature.name, feature]));
    }

    getRewardableFeatures() {
        return Array.from(this.memberFeatures).filter(([, memberFeature]) =>
            memberFeature.rewarding === true
        ).map((mf: any) => mf.name);
    }

    hasFeature(feature: FeatureEnum): boolean {
        const matchedFeature = Array.from(this.memberFeatures).filter(([, memberFeature]) =>
            memberFeature.name === feature
        );
        return matchedFeature.length > 0;
    }

    hasLiveServicesCoaching(): boolean {
        return this.hasLiveServicesCoachingSponsor()
            && (this.memberHasFeature(FeatureEnum.ConditionManagement)
                || this.memberHasFeature(FeatureEnum.LifestyleManagement)
                || this.memberHasFeature(FeatureEnum.NextStepsConsult)
                || this.memberHasFeature(FeatureEnum.Pregnancy)
                || this.memberHasFeature(FeatureEnum.Tobacco)
                || this.memberHasFeature(FeatureEnum.TobaccoDedicated)
                || this.memberHasFeature(FeatureEnum.TotalPopulationHealth)
                || this.memberHasFeature(FeatureEnum.MentalHealthCoaching));
    }

    hasLiveServicesCoachingNoTobacco(): boolean {
        return this.hasLiveServicesCoachingSponsor()
            && (this.memberHasFeature(FeatureEnum.ConditionManagement)
                || this.memberHasFeature(FeatureEnum.LifestyleManagement)
                || this.memberHasFeature(FeatureEnum.NextStepsConsult)
                || this.memberHasFeature(FeatureEnum.Pregnancy)
                || this.memberHasFeature(FeatureEnum.TotalPopulationHealth)
                || this.memberHasFeature(FeatureEnum.MentalHealthCoaching));
    }

    hasLiveServicesCoachingNavigation(): boolean {
        return this.hasLiveServicesCoachingSponsor()
            && (this.memberHasFeature(FeatureEnum.ConditionManagement)
                || this.memberHasFeature(FeatureEnum.LifestyleManagement)
                || this.memberHasFeature(FeatureEnum.TotalPopulationHealth)
                || this.memberHasFeature(FeatureEnum.MentalHealthCoaching));
    }

    hasLiveServicesCoachingSingleTopic(): boolean {
        return this.memberHasFeature(FeatureEnum.Tobacco) || this.memberHasFeature(FeatureEnum.TobaccoDedicated);
    }

    hasCoachesCorner(): boolean {
        return (!this.hasLiveServicesCoaching() && this.memberHasFeature(FeatureEnum.Coaching));
    }

    careHasCoachingEnabled(): boolean {
        return (this.hasLiveServicesCoachingNoTobacco() || this.memberHasFeature(FeatureEnum.Coaching));
    }

    hasLiveServicesCoachingSponsor(): boolean {
        const sponsorSettings = this.authenticatedMemberService.getSponsorSettings();
        return sponsorSettings.hasLiveServicesCoaching;
    }

    hasLiveServicesCoachMessaging(): boolean {
        return this.memberHasFeature(FeatureEnum.LiveServicesCoachMessaging);
    }

    hasLiveServicesCoachingPlans(): boolean {
        return this.memberHasFeature(FeatureEnum.LiveServicesCoachingPlans);
    }

    hasStatsDashboardBetaFeature(): boolean {
        return this.memberHasFeature(FeatureEnum.StatsDashboardBeta);
    }

    hasSomeOfFeatures(features: FeatureEnum[]) : boolean {
        return features.some((feature) => this.hasFeature(feature));
    }

    hasEveryFeature(features: FeatureEnum[]) : boolean {
        return features.every((feature) => this.hasFeature(feature));
    }
}
