import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ShellApplication, SingleSpaConfiguration, SingleSpaConfigurationService } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'single-spa',
    template: '<div #mf></div>'
})
export class SingleSpaComponent implements AfterViewInit {
    private module!: SingleSpaConfiguration;

    @ViewChild('mf') container!: ElementRef;

    constructor(
        private singleSpa: SingleSpaConfigurationService,
        private route: ActivatedRoute,
        private shellApplication: ShellApplication
    ) {
        if (!this.module && this.route.snapshot.data?.appConfiguration) {
            this.module = this.route.snapshot.data.appConfiguration;
        }
    }

    ngAfterViewInit(): void {
        this.singleSpa.activateModule(this.module, this.shellApplication);
    }
}
