import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MemberEventRsvp } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class CalendarEventsApiService {

    constructor(private httpClient: HttpClient) { }

    getMemberRsvp(eventId: number, memberId: number): Observable<MemberEventRsvp> {
        return this.httpClient.get<MemberEventRsvp>(this.memberRsvpURI(memberId, eventId));
    }

    setMemberRsvp(eventRsvp: MemberEventRsvp): Observable<MemberEventRsvp> {
        const member = eventRsvp.memberId ?? 0;
        const eventId = eventRsvp.eventId ?? 0;
        return this.httpClient.post<MemberEventRsvp>(this.memberRsvpURI(member, eventId), eventRsvp);
    }

    deleteMemberRsvp(eventId: number, memberId: number): Observable<MemberEventRsvp> {
        return this.httpClient.delete<MemberEventRsvp>(this.memberRsvpURI(memberId, eventId));
    }

    private memberRsvpURI = (memberId: number, eventId: number) =>
        `/api/members/${ memberId }/calendar-events/${ eventId }/rsvps`;
}
