import { Injectable } from '@angular/core';

import { GlobalChallengesOnboardingBlockerModalService } from './global-challenges-blocker/global-challenges-onboarding-blocker.service';
import { MemberContestOnboardingBlockerService } from './member-contest-onboarding-blocker.service';
import { AuthenticatedMemberService, BlockerResponseModel, SponsorSetting } from '@genesis-frontend/genesis-utilities';

@Injectable()
export class GlobalChallengesBlockerService {
    private shouldShowOnboardingChallengeBlocker = false;
    private isMobileWebView = false;
    private sponsorSettings!: SponsorSetting;
    private contestId: any;

    constructor(
        private memberContestOnboardingBlockerService: MemberContestOnboardingBlockerService,
        private authenticatedMemberService: AuthenticatedMemberService,
        private globalChallengesOnboardingBlockerModalService: GlobalChallengesOnboardingBlockerModalService
    ) {
        this.checkGlobalChallengesBlocker = this.checkGlobalChallengesBlocker.bind(this);
    }

    checkGlobalChallengesBlocker() {
        this.sponsorSettings = this.authenticatedMemberService.getSponsorSettings();
        this.isMobileWebView = /VirginPulseWebView/.test(navigator.userAgent);
        this.shouldShowOnboardingChallengeBlocker = this.sponsorSettings.onboardingChallengeBlocker && !this.isMobileWebView;

        return new Promise<BlockerResponseModel>((resolve) => {

            if (this.shouldShowOnboardingChallengeBlocker && !this.hasOnboardingBlockerBeenShown()) {
                this.memberContestOnboardingBlockerService
                    .getOnboardingChallengeId(this.authenticatedMemberService.getAuthenticatedMember().id)
                    .subscribe((response: { contestId: any }) => {
                        if (response) {
                            this.contestId = response.contestId;

                            resolve({ showBlocker: true, response: { contestId: this.contestId } });
                        } else {
                            resolve({ showBlocker: false });
                        }
                    }, () => {
                        resolve({ showBlocker: false });
                    });
            } else {
                resolve({ showBlocker: false });
            }
        });
    }

    showGlobalChallengesBlocker(blocker: BlockerResponseModel) {
        return new Promise<void>((resolve) => {
            if (blocker.showBlocker && blocker.response?.contestId) {
                sessionStorage.onboardingBlockerShown = true;
                this.globalChallengesOnboardingBlockerModalService.open(
                    blocker.response.contestId,
                    this.authenticatedMemberService.getAuthenticatedMember().id
                );
            }
            resolve();
        });
    }

    hasOnboardingBlockerBeenShown() {
        return !!sessionStorage.onboardingBlockerShown;
    }

    getFreeSlotsNumberToDisplay(teamLength: number, itemsToDisplay: number) {
        return teamLength >= itemsToDisplay ? 0 : itemsToDisplay - teamLength;
    }

    getNumberOfNotDisplayedTeamMembers(teamLength: number, itemsToDisplay: number) {
        return teamLength >= itemsToDisplay ? teamLength - itemsToDisplay : 0;
    }

    generateArrayOfFreeSlots(freeSlotsToDisplay: number) {
        return Array(freeSlotsToDisplay);
    }
}
