import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class MemberMfaCodeVerificationService {
    constructor(
        private httpClient: HttpClient
    ) {
    }

    sendMfaCode(memberId: number, where: string): Observable<void> {
        return this.httpClient.get<void>(`/api/members/${ memberId }/${ where }/verify`);
    }

    verifyMfaCode(memberId: number, from: string, code: number): Observable<void> {
        return this.httpClient.post<void>(`/api/members/${ memberId }/${ from }/verify`, {
            code
        });
    }
}
