import Arabic from '@uppy/locales/lib/ar_SA';
import Bulgarian from '@uppy/locales/lib/bg_BG';
import Czech from '@uppy/locales/lib/cs_CZ';
import Danish from '@uppy/locales/lib/da_DK';
import German from '@uppy/locales/lib/de_DE';
import Greek from '@uppy/locales/lib/el_GR';
import English from '@uppy/locales/lib/en_US';
import SpanishSpain from '@uppy/locales/lib/es_ES';
import SpanishMexico from '@uppy/locales/lib/es_MX';
import Persian from '@uppy/locales/lib/fa_IR';
import Finnish from '@uppy/locales/lib/fi_FI';
import French from '@uppy/locales/lib/fr_FR';
import Galician from '@uppy/locales/lib/gl_ES';
import Hebrew from '@uppy/locales/lib/he_IL';
import Hindi from '@uppy/locales/lib/hi_IN';
import Croatian from '@uppy/locales/lib/hr_HR';
import Hungarian from '@uppy/locales/lib/hu_HU';
import Indonesian from '@uppy/locales/lib/id_ID';
import Icelandic from '@uppy/locales/lib/is_IS';
import Italian from '@uppy/locales/lib/it_IT';
import Japanese from '@uppy/locales/lib/ja_JP';
import Korean from '@uppy/locales/lib/ko_KR';
import Norwegian from '@uppy/locales/lib/nb_NO';
import Dutch from '@uppy/locales/lib/nl_NL';
import Polish from '@uppy/locales/lib/pl_PL';
import PortugueseBrazil from '@uppy/locales/lib/pt_BR';
import PortuguesePortugal from '@uppy/locales/lib/pt_PT';
import Romanian from '@uppy/locales/lib/ro_RO';
import Russian from '@uppy/locales/lib/ru_RU';
import Slovak from '@uppy/locales/lib/sk_SK';
import SerbianCyrillic from '@uppy/locales/lib/sr_RS_Cyrillic';
import SerbianLatin from '@uppy/locales/lib/sr_RS_Latin';
import Swedish from '@uppy/locales/lib/sv_SE';
import Thai from '@uppy/locales/lib/th_TH';
import Turkish from '@uppy/locales/lib/tr_TR';
import Ukrainian from '@uppy/locales/lib/uk_UA';
import Uzbek from '@uppy/locales/lib/uz_UZ';
import Vietnamese from '@uppy/locales/lib/vi_VN';
import ChineseChina from '@uppy/locales/lib/zh_CN';
import ChineseTaiwan from '@uppy/locales/lib/zh_TW';

// Mapping of language codes to Uppy locales
const uppyLocales: Record<string, any> = {
    ar: Arabic,
    bg: Bulgarian,
    zh: ChineseChina, // Default to Simplified Chinese
    zh_TW: ChineseTaiwan,
    hr: Croatian,
    cs: Czech,
    da: Danish,
    nl: Dutch,
    en: English,
    fi: Finnish,
    fr: French,
    gl: Galician,
    de: German,
    el: Greek,
    he: Hebrew,
    hi: Hindi,
    hu: Hungarian,
    is: Icelandic,
    id: Indonesian,
    it: Italian,
    ja: Japanese,
    ko: Korean,
    nb: Norwegian,
    fa: Persian,
    pl: Polish,
    pt: PortugueseBrazil, // Default to Brazilian Portuguese
    pt_PT: PortuguesePortugal,
    ro: Romanian,
    ru: Russian,
    sr: SerbianLatin, // Default to Latin Serbian
    sr_Cy: SerbianCyrillic,
    sk: Slovak,
    es: SpanishSpain, // Default to European Spanish
    es_MX: SpanishMexico,
    sv: Swedish,
    th: Thai,
    tr: Turkish,
    uk: Ukrainian,
    uz: Uzbek,
    vi: Vietnamese
};


/**
 * Get the Uppy locale object for a given language code.
 * @param langCode Two-letter or region-specific language code (e.g., 'en', 'de', 'fr', 'zh-TW')
 * @returns The corresponding Uppy locale object or English (`en_US`) as fallback
 */
export const getUppyLocale = (langCode: string) => {
    return uppyLocales[langCode] || English;
};

export default uppyLocales;
