import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';

import { MaximumTrackersAddedModalComponent } from './maximum-trackers-added.component';
import { GenesisDirectivesModule } from '@genesis-frontend/genesis-utilities';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        GenesisDirectivesModule,
        ModalModule.forRoot()
    ],
    declarations: [
        MaximumTrackersAddedModalComponent
    ],
    providers: [
        BsModalService
    ],
    exports: [
    ]
})
export class MaximumTrackersAddedModule { }
