import { Injectable } from '@angular/core';

import { SessionCache } from '../../core/session-cache';
import { PersonalChallengeTemplate } from '../../models/interfaces/personal-challenge-template.model';
import { PersonalChallengeTracker } from '../../models/interfaces/personal-challenge-tracker.model';
import { PersonalChallenge } from '../../models/interfaces/personal-challenge.model';


@Injectable({
    providedIn: 'root'
})
export class PersonalChallengeSharedDataService {
    stepChallengeData: PersonalChallenge | PersonalChallengeTemplate | any = {};
    trackerChallengeData: PersonalChallengeTracker | any = {};
    private _isTrackerChallenge = false;
    private _isReplayChallenge = false;
    leaderboardTotalPlayers = 0;
    private cacheKey = 'PersonalChallengeSharedDataService';
    private sessionCache = new SessionCache<any>(this.cacheKey);
    private instance = this;

    constructor() {
        if (this.sessionCache.hasItem(this.cacheKey)) {
            this.instance = this.sessionCache.getItem(this.cacheKey)?.value;
        }
    }

    setNewStepChallenge(challenge: PersonalChallenge | PersonalChallengeTemplate) {
        this.instance.stepChallengeData = challenge;
        this.saveState();
    }

    setNewTrackerChallenge(challenge: PersonalChallengeTracker) {
        this.instance.trackerChallengeData = challenge;
        this.saveState();
    }

    setLeaderboardTotalPlayers(players: number) {
        this.instance.leaderboardTotalPlayers = players;
        this.saveState();
    }

    get isTrackerChallenge() {
        return this.instance._isTrackerChallenge;
    }

    set isTrackerChallenge(value: boolean) {
        this.instance._isTrackerChallenge = value;
        this.saveState();
    }

    get isReplayChallenge() {
        return this.instance._isReplayChallenge;
    }

    set isReplayChallenge(value: boolean) {
        this.instance._isReplayChallenge = value;
        this.saveState();
    }

    private saveState() {
        this.sessionCache.setItem(this.cacheKey, this.instance);
    }

    clearState() {
        this.sessionCache.removeItem(this.cacheKey);
    }

}
