import { Component, Input } from '@angular/core';

@Component({
    selector: 'genesis-frontend-message',
    templateUrl: './message.component.html',
    styleUrls: ['./message.component.scss']
})
export class MessageComponent {
    @Input() numberOfMessages: number | undefined;
    @Input() content = '';

    icon = 'far fa-envelope';
}
