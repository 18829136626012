import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { DialogService } from '@engineering/vpgroove-angular';

@Injectable({
    providedIn: 'root'
})
export class BootstrapModalWrapperService {

    constructor(private dialogService: DialogService) { }

    show(modalComponent: any, options: any): Observable<any> {
        const responseSubject = new Subject<any>();
        this.dialogService.open(modalComponent, {
            props: { ...options?.initialState }
        }).subscribe((response) => {
            responseSubject.next(response);
            this.dialogService.confirm().close();
        });

        return responseSubject.asObservable();
    }
}
