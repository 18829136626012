import { PipeTransform, Pipe } from '@angular/core';

import { SecuredFilePickerService } from '../services';

@Pipe({ name: 'securityImageLoading', pure: false })
export class SecurityImageLoadingPipe implements PipeTransform {
    constructor(private securedFilePickerService: SecuredFilePickerService) { }

    transform(url: string | undefined) {
        if (!url) {
            return '';
        }
        return `${url}?policy=${this.securedFilePickerService.readSecurityData.policy}&signature=${this.securedFilePickerService.readSecurityData.signature}`;
    }
}
