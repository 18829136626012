import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';

import { BookAppointmentComponent } from './book-appointment.component';
import { VpgrooveModule } from '@engineering/vpgroove-angular';
import {
    AnalyticsTrackingSingleSpaService,
    GenesisPipesModule,
    LiveServicesCoachingService,
    FeatureService
} from '@genesis-frontend/genesis-utilities';


@NgModule({
    declarations: [
        BookAppointmentComponent
    ],
    imports: [
        VpgrooveModule,
        FormsModule,
        GenesisPipesModule,
        NgxIntlTelInputModule,
        BsDropdownModule,
        BsDatepickerModule.forRoot(),
        CommonModule,
        BrowserModule,
        ReactiveFormsModule
    ],
    exports: [
        BookAppointmentComponent
    ],
    providers: [
        BsModalService,
        BsModalRef,
        LiveServicesCoachingService,
        FeatureService,
        AnalyticsTrackingSingleSpaService
    ]
})
export class BookAppointmentModule {}
