import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

enum Month {
    JUNE = 5,
    JULY = 6
}

@Pipe({ name: 'outOfOfficeDateFormat' })
export class OutOfOfficeDatePipe extends DatePipe implements PipeTransform {
    transform(value: any): any {
        const month = new Date(value).getMonth();
        if (month === Month.JUNE || month === Month.JULY) {
            return super.transform(value, 'MMMM d');
        }

        return super.transform(value, 'MMM d');
    }
}
