<div class="challenge-onboarding-confirm-team-creation-container">
    <div class="team-confirmation-title">
        <p
            [ngClass]="[titleClass]"
            class="team-title text-clr-oxford-blue">
            {{data.teamName}}
        </p>
    </div>
    <div
        class="rally-cry-team-confirmation-screen text-clr-oxford-blue truncate-long-text"
        *ngIf="data.contest.contestType !== vpGoService.FeaturedChallenges.ChallengeTypes.Organizational">
        {{data.rallyingCry}}
    </div>
    <hr class="border-clr-gainsboro" />
    <div class="confirm-team-card-middle">
        <div class="team-image-confirmation-screen">
            <div class="team-image-wrapper">
                <img
                    class="team-confirmation-image"
                    [src]="data.teamImage | compressFilePickerImage: 400" />
            </div>
            <div *ngIf="!data.isTeamPrivate">
                <div
                    class="team-creation-description text-clr-oxford-blue text-uppercase"
                    [translate]="'Public'"></div>
                <div
                    class="team-creation-description-lower text-clr-oxford-blue"
                    [translate]="'ThisIsPublicTeam'"></div>
            </div>
            <div *ngIf="data.isTeamPrivate">
                <div
                    class="team-creation-description text-clr-oxford-blue text-uppercase"
                    [translate]="'Private'"></div>
                <div
                    class="team-creation-description-lower text-clr-oxford-blue"
                    [translate]="'ThisIsPrivateTeam'"></div>
            </div>
        </div>
        <div class="confirm-team-details-players-container">
            <div class="confirm-your-team-slot-style">
                <img
                    class="confirm-vp-go-player-image popover-trigger border-clr-bg"
                    [src]="member.profilepicture | compressFilePickerImage: 200"
                    triggers="hover focus"
                    placement="top"
                    role="tooltip" />
                <div class="team-member-name text-clr-oxford-blue">{{teamCaptain}}</div>
            </div>
            <div
                class="confirm-your-team-slot-style"
                *ngFor="let teamMember of data.invitedPlayers">
                <img
                    class="confirm-vp-go-player-image popover-trigger border-clr-bg"
                    [src]="teamMember.profilePicture ? (teamMember.profilePicture | compressFilePickerImage: 200) : '/img/navigation/DefaultAvatar.png'" />
                <div
                    class="team-member-name text-clr-oxford-blue"
                    *ngIf="!teamMember.emailAddress">
                    {{teamMember.firstName}} {{teamMember.lastName}}
                </div>
                <div
                    class="team-member-name text-clr-oxford-blue"
                    *ngIf="teamMember.emailAddress">
                    {{teamMember.emailAddress}}
                </div>
            </div>
            <div
                class="confirm-your-team-slot-style"
                *ngFor="let freeSlot of freeSlotsArray">
                <img
                    class="confirm-vp-go-player-image border-clr-bg"
                    src="/img/empty-player.png" />
                <div
                    class="team-member-name text-clr-oxford-blue"
                    [translate]="'AddAPlayer'">
                    Add a player
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <hr class="border-clr-gainsboro line-width" />
        <div class="global-challenge-onboarding-button">
            <button
                class="vp-button larger vp-button-primary text-uppercase create-team-button-width"
                (click)="createTeam()"
                [translate]="'CreateTeam'">
                Create Team
            </button>
        </div>
    </div>
    <div
        class="prev-card-btn background-clr-bg background-dark text-clr-white"
        *ngIf="showLeftArrow"
        (click)="this.data.prevCard()"
        role="button"
        tabindex="0"
        [attr.aria-label]="'previous' | translate">
        <i class="fa fa-angle-left"></i>
    </div>
</div>
