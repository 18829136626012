import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PillarSummaryService {

    constructor(private httpClient: HttpClient) { }

    getAllPillarSummaries(memberId: number): Observable<any[]> {
        return this.httpClient.get<any[]>(`/api/members/${memberId}/pillars/summary`);
    }

    getTopicInterests(memberId: number): Observable<any[]> {
        return this.httpClient.get<any[]>(`/api/members/${memberId}/topics/interests`);
    }
}
