import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { HomepageModuleRewardComponent } from './homepage-module-reward.component';
import { HomepageModuleRewardService } from './services/homepage-module-reward.service';
import {
    GenesisPipesModule, GlobalReduxStore
} from '@genesis-frontend/genesis-utilities';


@NgModule({
    declarations: [
        HomepageModuleRewardComponent
    ],
    imports: [
        GenesisPipesModule,
        CommonModule
    ],
    exports: [
        HomepageModuleRewardComponent
    ],
    providers: [
        GlobalReduxStore,
        HomepageModuleRewardService
    ]
})
export class HomepageModuleRewardModule {}
