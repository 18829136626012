import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { CollapsibleListState } from '../../domain/collapsible-list-state.enum';
import { CheckboxSelectionModel, PillarTopic } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'pillar-recommended-topics-list',
    templateUrl: 'pillar-recommended-topics-list.component.html',
    styleUrls: ['pillar-recommended-topics-list.component.scss']
})
export class PillarRecommendedTopicsListComponent implements OnChanges {
    @Input() value?: PillarTopic[];
    @Input() recommendedTopics: PillarTopic[] = [];
    @Output() recommendedTopicSelect = new EventEmitter<PillarTopic[]>();

    pillarTopicItems: CheckboxSelectionModel<PillarTopic>[] = [];

    collapsibleListState = CollapsibleListState.VOID;
    collapsibleListLabel: 'LoadMore' | 'CollapseButton' = 'LoadMore';

    readonly MIN_LIST_SIZE = 3;

    constructor() {}

    ngOnChanges(): void {
        this.collapsibleListState = this.getCollapsibleListState(this.recommendedTopics);
        this.pillarTopicItems = this.asRecommendationsList(this.recommendedTopics, this.collapsibleListState);
    }

    private getCollapsibleListState(topics: PillarTopic[]): CollapsibleListState {
        if (!topics || !topics.length || topics.length <= this.MIN_LIST_SIZE) {
            return CollapsibleListState.VOID;
        }

        if (topics.length > this.MIN_LIST_SIZE) {
            return CollapsibleListState.COLLAPSED;
        }

        return CollapsibleListState.OPEN;
    }

    asRecommendationsList(pillarTopics: PillarTopic[], listState: CollapsibleListState): CheckboxSelectionModel<PillarTopic>[] {
        const displayedValues = this.asFilteredIfExpanded(pillarTopics, listState);

        const selectedTopics = this.value ? this.value : [];
        return displayedValues.map((topic) => {
            return {
                selected: selectedTopics.some((selectedTopic) => selectedTopic.id === topic.id),
                data: topic
            };
        });
    }

    private asFilteredIfExpanded(pillarTopics: PillarTopic[], state: CollapsibleListState): PillarTopic[] {
        if (state !== CollapsibleListState.COLLAPSED) {
            return pillarTopics;
        }

        return pillarTopics.filter((_topic, idx) => {
            return idx < this.MIN_LIST_SIZE;
        });
    }

    onTopicSelection(topic: CheckboxSelectionModel<PillarTopic>): void {
        const newSelectionListValue = this.asSelectionValue(topic);
        this.recommendedTopicSelect.emit(newSelectionListValue);
    }

    private asSelectionValue(pillarTopic: CheckboxSelectionModel<PillarTopic>): PillarTopic[] | undefined {
        const { selected, data } = pillarTopic;
        if (!selected) {
            return this.value?.filter((p) => p.id !== data.id);
        }

        if (!this.value || !this.value.length) {
            return [data];
        }

        if (this.value.some((topic) => topic.id === data.id)) {
            return this.value;
        }
        return [...this.value, data];
    }

    expandRecommended(): void {
        const currentState = this.collapsibleListState;

        if (currentState === CollapsibleListState.COLLAPSED) {
            this.collapsibleListState = CollapsibleListState.OPEN;
            this.collapsibleListLabel = 'CollapseButton';
        }

        if (currentState === CollapsibleListState.OPEN) {
            this.collapsibleListState = CollapsibleListState.COLLAPSED;
            this.collapsibleListLabel = 'LoadMore';
        }

        this.pillarTopicItems = this.asRecommendationsList(this.recommendedTopics, this.collapsibleListState);
    }

    isLoadMoreRendered(): boolean {
        return this.collapsibleListState !== CollapsibleListState.VOID;
    }
}
