import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'rewardPopulationInfo' })
export class RewardPopulationInfoPipe implements PipeTransform {
    transform(value: any): string {
        if (!value) {
            return 'All';
        }
        const code = value.code ? '(' + value.code + ')' : '';
        return value.name + ' ' + code;
    }
}
