import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { AuthenticatedMemberService } from '../authentication';
import { MemberTheme } from '../models';
import { ThemeManagerService } from '@engineering/vpgroove-angular';

@Injectable({
    providedIn: 'root'
})
export class MemberThemeService {

    SLIM_STYLESHEET_URL = '../../../assets/legacy-slim-styles.css';

    private themeLoaded = new ReplaySubject<boolean>();
    themeLoaded$ = this.themeLoaded.asObservable();

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private themeManagerService: ThemeManagerService
    ) {}

    setMemberTheme() {
        const memberTheme = this.authenticatedMemberService.getMemberTheme();
        if (memberTheme?.name) {
            // @ts-ignore
            const CUSTOM_THEME = this.getCustomTheme(memberTheme);
            this.themeManagerService.registerTheme(CUSTOM_THEME);
        } else {
            this.themeManagerService.registerTheme();
        }
        this.loadTheme({
            name: 'Slim',
            stylesheet: this.SLIM_STYLESHEET_URL
        });
    }

    private loadTheme(theme: any) {
        document.querySelector('#theme-css')?.remove();

        const link = document.createElement('link');
        link.setAttribute('rel', 'stylesheet');
        link.setAttribute('type', 'text/css');
        link.setAttribute('id', 'theme-css');
        link.setAttribute('href', theme.stylesheet);
        link.onload = () => {
            this.themeLoaded.next(true);
        };
        document.head.appendChild(link);
    }

    private getCustomTheme(theme: MemberTheme) {
        const buttonBackgroundColor = theme.settings.ButtonPrimary.properties.BackgroundColor.value;

        const CUSTOM_THEME = {
            name: theme.name,
            themeProperties: {
                '--VPGrooveColor-ButtonBackgroundColor': buttonBackgroundColor,
                '--VPGrooveColor-ButtonTextColor': theme.settings.ButtonPrimary.properties.TextColor.value,
                '--VPGrooveColor-LinkColor': theme.settings.TextLink.value,
                '--VPGrooveColor-ButtonBackgroundColorDark': this.themeManagerService.changeColor(buttonBackgroundColor, -0.15),
                '--VPGrooveColor-ButtonBackgroundColorLight': this.themeManagerService.changeColor(buttonBackgroundColor, 0.15),
                '--VPGrooveColor-BackgroundPrimary': theme.settings.BackgroundPrimary.properties.BackgroundColor.value,
                '--VPGrooveColor-BackgroundPrimaryTextColor': theme.settings.BackgroundPrimary.properties.TextColor.value,
                '--VPGrooveColor-BackgroundInfo': theme.settings.BackgroundInfo.properties.BackgroundColor.value,
                '--VPGrooveColor-BackgroundInfoTextColor': theme.settings.BackgroundInfo.properties.TextColor.value,
                '--VPGrooveColor-ButtonInfo': theme.settings.ButtonInfo.properties.BackgroundColor.value,
                '--VPGrooveColor-ButtonInfoTextColor': theme.settings.ButtonInfo.properties.TextColor.value,
                '--VPGrooveColor-HighlightInfo': theme.settings.HighlightInfo.properties.BackgroundColor.value,
                '--VPGrooveColor-HighlightInfoTextColor': theme.settings.HighlightInfo.properties.TextColor.value,
                '--VPGrooveColor-ProgressBarInfo': theme.settings.ProgressBarInfo.properties.BackgroundColor.value,
                '--VPGrooveColor-ProgressBarInfoTextColor': theme.settings.ProgressBarInfo.properties.TextColor.value,
                '--VPGrooveColor-ProgressBarRewards': theme.settings.ProgressBarRewards.properties.BackgroundColor.value,
                '--VPGrooveColor-ProgressBarRewardsTextColor': theme.settings.ProgressBarRewards.properties.TextColor.value,
                '--VPGrooveColor-ProgressRingInfo': theme.settings.ProgressRingInfo.properties.BorderColor.value,
                '--VPGrooveColor-ProgressRingPrimary': theme.settings.ProgressRingPrimary.properties.BorderColor.value,
                '--VPGrooveColor-Ribbon': theme.settings.Ribbon.properties.BackgroundColor.value,
                '--VPGrooveColor-RibbonTextColor': theme.settings.Ribbon.properties.TextColor.value,
                '--VPGrooveColor-TrimInfo': theme.settings.TrimInfo.properties.BorderColor.value,
                '--VPGrooveColor-ProgressBarRewardsLight': this.themeManagerService.changeColor(theme.settings.ProgressBarRewards.properties.BackgroundColor.value, 0.15),
                '--VPGrooveColor-ProgressBarInfoLight': this.themeManagerService.changeColor(theme.settings.ProgressBarInfo.properties.BackgroundColor.value, 0.15),
                '--VPGrooveColor-BodyBackground': theme.settings.BodyBackground.properties.BackgroundColor.value,
                '--VPGrooveColor-RewardsBarSecondary':  theme.settings.RewardsBarSecondary.properties.BackgroundColor.value
            }
        };
        return CUSTOM_THEME;
    }
}
