import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import Plyr from 'plyr';

import { VideoType } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'external-link-video-player',
    templateUrl: './external-link-video-player.template.html',
    styleUrls: ['./external-link-video-player.component.scss']
})

export class ExternalLinkVideoPlayerComponent {
    @Input() videoUrl?: string;
    @Input() options?: Plyr.Options;
    @Output() onVideoInit = new EventEmitter();
    @Output() onVideoPlayed = new EventEmitter();
    @Output() onVideoError = new EventEmitter();

    readonly YOUTUBE_REGEX = /(http:|https:)\/\/(www\.)?(youtu(be\.com|\.be|be\.googleapis\.com))\/(watch\?v=|v\/)?([A-Za-z0-9._%-]*)(&\S+)?/;
    readonly VIMEO_REGEX = /(http:|https:)\/\/(player\.)?(vimeo)\.com\/(video\/)?([A-Za-z0-9._%-]*)(&\S+)?/;

    @ViewChild('videoplayer') videoPlayer: ElementRef | undefined;
    videoType?: VideoType;

    private playerRef?: Plyr;

    ngOnChanges() {
        this.videoType = this.determineVideoType();

        if (!this.videoType) {
            this.onVideoError.emit();
        }
    }

    ngAfterViewInit() {
        const plyr = this.playVideo();
        plyr.on('ready', () => {
            this.onVideoInit.emit(plyr);
        });

        plyr.on('playing', () => {
            this.onVideoPlayed.emit();
        });
    }

    ngOnDestroy() {
        if (this.playerRef) {
            this.playerRef.destroy();
        }
    }

    private determineVideoType(): VideoType | undefined {

        if (this.videoUrl && this.YOUTUBE_REGEX.test(this.videoUrl)) {
            return VideoType.youtube;
        }
        if (this.videoUrl && this.VIMEO_REGEX.test(this.videoUrl)) {
            return VideoType.vimeo;
        }

        return undefined;
    }

    private playVideo() {
        if (!this.playerRef) {
            this.playerRef = new Plyr(
                this.videoPlayer?.nativeElement,
                this.options || {
                    autoplay: false
                }
            );
            return this.playerRef;
        }

        this.playerRef.source = {
            type: 'video',
            sources: [
                {
                    src: this.videoUrl ? this.videoUrl : '',
                    provider: this.videoType as Plyr.Provider
                }
            ]
        };

        return this.playerRef;
    }
}
