export enum AnalyticsTrackingStatItems {
    Workout = 'workout',
    ActiveMinutes = 'active minutes',
    Steps = 'steps',
    Weight = 'weight',
    Sleep = 'sleep',
    ActivityMinutes = 'activity minutes',
    MindfulMinutes = 'mindful minutes',
    BodyTemp = 'body temp',
    BloodPressure = 'blood pressure',
    Glucose = 'glucose',
    A1C = 'a1c',
    WaistSize = 'waist',
    HipSize = 'hips',
    Cholesterol = 'cholesterol',
    CaloriesConsumed = 'calories consumed',
    CaloriesBurned = 'calories burned'
}
