import { Component, EventEmitter, Input, Output } from '@angular/core';

import { LanguageModel } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'translations-table',
    templateUrl: './translations-table.component.html',
    styleUrls: ['./translations-table.component.scss']
})
export class TranslationsTableComponent {

    @Input() languages: LanguageModel[] = [];

    @Input() addTranslationText = '';
    @Input() addTranslationClickFunction: any;

    @Output() editTranslationOutput: EventEmitter<LanguageModel> = new EventEmitter<LanguageModel>();
    @Output() deleteLanguageOutput: EventEmitter<LanguageModel> = new EventEmitter<LanguageModel>();

    selectedLanguage?: LanguageModel;
    modalOpen = false;

    openModal(language: LanguageModel) {
        this.modalOpen = true;
        this.selectedLanguage = language;
    }

    deleteLanguage() {
        this.modalOpen = false;
        this.deleteLanguageOutput.emit(this.selectedLanguage);
    }
}
