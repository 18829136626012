export enum ApplicationsUrls {
    DevicesAndApps = '/#/devices',
    DevicesAndAppsMobile = '/#/mobile/devices',
    FeaturedChallengeDetails = '/#/featuredchallenge',
    PersonalChallengeDashboard = '/#/challenges/dashboard',
    PersonalChallengeDetails = '/#/challenges/personal/details',
    PersonalTrackerChallengeDetails = '/#/challenges/healthyhabits',
    FriendProfile = '/#/member-profile/friend/{0}',
    MyFriends = '/#/myfriends',
    RewardsSpend = '/#/rewards/spend',
    ProgramList = '/#/programsList',
    PointsChallenge = '/#/pointschallenge/{0}',
    DeviceStoreLink = '/devices/GlobalMerchandiseStore'
}
