import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { CalendarEventModalComponent } from './calendar-event-modal.component';
import { CalendarEventService } from './calendar-event-modal.service';
import { CalendarEventExportComponent } from './calendar-events/calendar-event-export.component';
import { VpgrooveModule } from '@engineering/vpgroove-angular';
import {
    MemberTimezoneService,
    CalendarService,
    FormatHtmlContentService,
    IcalConverterService, GenesisPipesModule
} from '@genesis-frontend/genesis-utilities';


@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        VpgrooveModule,
        GenesisPipesModule,
        FormsModule
    ],
    declarations: [
        CalendarEventModalComponent,
        CalendarEventExportComponent
    ],
    exports: [
        CalendarEventModalComponent,
        CalendarEventExportComponent
    ],
    providers: [
        CalendarEventService,
        MemberTimezoneService,
        CalendarService,
        FormatHtmlContentService,
        IcalConverterService
    ]
})
export class CalendarModalModule { }
