export enum HttpResponse {
    NoContent = 204,
    BadRequest = 400,
    Unauthorised = 401,
    Forbidden = 403,
    NotFound = 404,
    NotAcceptable = 406,
    Conflict = 409,
    PreconditionFailed = 412,
    TooManyRequests = 429,
    InternalError = 500
}
