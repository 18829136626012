import { AfterViewInit, Component } from '@angular/core';

@Component({
    templateUrl: './basic-layout.component.html'
})
export class BasicLayoutComponent implements AfterViewInit {
    ngAfterViewInit() {
        document.getElementById('loaded')?.remove();
    }
}
