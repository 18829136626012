import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, EventEmitter, HostListener, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { CountryData, Options, Plugin } from 'intl-tel-input';

declare const window: any;
const defaultUtilScript = './assets/lib/utils.js';

type IntlTelInputOptions = Record<string, any>;

@Directive({
    selector: '[intlTelInput]'
})
export class IntlTelInputDirective implements OnInit {
    @Input() intlTelInputOptions: Options = {};
    @Output() hasError: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() intlTelOutput: EventEmitter<string> = new EventEmitter<string>();
    @Output() countryChange: EventEmitter<CountryData> = new EventEmitter<CountryData>();
    @Output() intlTelInputObject: EventEmitter<Plugin> = new EventEmitter<Plugin>();

    intlTelInput: Plugin = {} as Plugin;

    constructor(private el: ElementRef, @Inject(PLATFORM_ID) private platformId: string) {}

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.intlTelInputOptions = {
                ...this.intlTelInputOptions,
                utilsScript: this.getUtilsScript(this.intlTelInputOptions)
            };
            this.intlTelInput = window.intlTelInput(this.el.nativeElement, {
                ...this.intlTelInputOptions
            });
            this.intlTelInputObject.emit(this.intlTelInput);
        }
    }

    @HostListener('countrychange') onCountryChange() {
        this.countryChange.emit(this.intlTelInput.getSelectedCountryData());
    }

    @HostListener('blur') onBlur() {
        const isInputValid: boolean = this.isInputValid();
        if (isInputValid) {
            const telOutput = this.intlTelInput.getNumber();
            this.hasError.emit(isInputValid);
            this.intlTelOutput.emit(telOutput);
        } else {
            this.hasError.emit(isInputValid);
        }
    }

    isInputValid(): boolean {
        return this.intlTelInput.isValidNumber();
    }

    setCountry(country: any) {
        this.intlTelInput.setCountry(country);
    }

    private getUtilsScript(options: IntlTelInputOptions): string {
        return options.utilsScript || defaultUtilScript;
    }
}
