import { Injectable } from '@angular/core';

import { Environment } from '../config/environment';

@Injectable({
    providedIn: 'root'
})
export class ConfigurationService {

    private environment: Environment;
    constructor() {
        this.environment = Environment.fromGlobal();
    }

    get configuration() {
        return this.environment;
    }

    getS3() {
        return this.environment.s3.basePath;
    }

    getSecuredFilePickerAPI() {
        return this.environment.securedFilepicker.apiKey;
    }

    getSecuredFilePickerVirusDetectWorkflowId() {
        return this.environment.securedFilepicker.virusDetectWorkflowId;
    }

    getSecuredFilePickerContainer() {
        return this.environment.securedFilepicker.container;
    }

    getFilePickerAPI() {
        return this.environment.filepicker.apiKey;
    }

    getFilePickerVirusDetectWorkflowId() {
        return this.environment.filepicker.virusDetectWorkflowId;
    }

    getFilePickerContainer() {
        return this.environment.filepicker.container;
    }

    getEcommerceBaseUrl() {
        return this.environment.ecommerce.baseUrl;
    }

    getWolverineTrailBaseUrl() {
        return this.environment.wolverineTrail.baseUrl;
    }

    getPusher(): any {
        return this.environment.pusher;
    }

    getGenesisBaseUrl() {
        return this.environment.genesis.baseUrl;
    }

    getEnv() {
        return this.environment.env.env;
    }

    getZendeskUrl() {
        return this.environment.zendesk.url;
    }

    getCastlePublishableKey() {
        return this.environment.castle.publishableKey;
    }

    getAuthServerBaseUrl() {
        return this.environment.authServer.baseUrl;
    }
}
