import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';

import { MemberMfaCodeVerificationService } from './member-mfa-code-verification.service';
import { ResendingMfaCodeWarningModalComponent } from './resending-mfa-code-warning-modal.component';
import { ResendingMfaCodeWarningModalService } from './resending-mfa-code-warning-modal.service';
import { VerifyMfaCodeModalComponent } from './verify-mfa-code-modal.component';
import { VerifyMfaCodeModalService } from './verify-mfa-code-modal.service';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        ModalModule.forRoot()
    ],
    declarations: [
        ResendingMfaCodeWarningModalComponent,
        VerifyMfaCodeModalComponent
    ],
    providers: [
        BsModalService,
        VerifyMfaCodeModalService,
        MemberMfaCodeVerificationService,
        ResendingMfaCodeWarningModalService
    ],
    exports: [
        ResendingMfaCodeWarningModalComponent,
        VerifyMfaCodeModalComponent
    ]
})
export class VerifyMfaCodeModalModule { }
