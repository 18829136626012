import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { ComponentsRoutingModule } from './components-routing.module';
import { HealthFormSubmissionsComponent } from './forms-submission/health-form-submissions.component';
import { AttestationFormSubmissionsComponent } from './forms-submission-attestation/attestation-form-submissions.component';
import { PcpFormComponent } from './forms-submission-pcp/pcp-form.component';
import { HraStartComponent } from './hra-start/hra-start.component';
import { LogoutWarningModalComponent } from './logout-warning-modal/logout-warning-modal.component';
import { NoProductComponent } from './no-product/no-product.component';
import { SingleSpaModuleComponent } from './single-spa-module.component';
import { SingleSpaComponent } from './single-spa.component';
import { TrophyModalComponent } from './trophy-modal/trophy-modal.component';
import { TrophyPopupComponent } from './trophy-popup/trophy-popup.component';
import { ZendeskSupportComponent } from './zendesk-support/zendesk-support.component';
import { HraStartService } from '../core/services';
import { VpgrooveModule } from '@engineering/vpgroove-angular';
import { MemberHraService } from '@genesis-frontend/genesis-data-access';
import { PersonalChallengeCreateModalModule, BubbleCelebrationModule, LoaderModule } from '@genesis-frontend/genesis-features';
import { GenesisDirectivesModule, GenesisPipesModule, GlobalReduxStore, TranslateLoaderModule } from '@genesis-frontend/genesis-utilities';

@NgModule({
    declarations: [
        SingleSpaComponent,
        SingleSpaModuleComponent,
        HealthFormSubmissionsComponent,
        AttestationFormSubmissionsComponent,
        PcpFormComponent,
        LogoutWarningModalComponent,
        NoProductComponent,
        ZendeskSupportComponent,
        TrophyPopupComponent,
        TrophyModalComponent,
        NoProductComponent,
        HraStartComponent
    ],
    imports: [
        CommonModule,
        ComponentsRoutingModule,
        GenesisDirectivesModule,
        GenesisPipesModule,
        SlickCarouselModule,
        FormsModule,
        BubbleCelebrationModule,
        PersonalChallengeCreateModalModule,
        VpgrooveModule,
        LoaderModule,
        TranslateLoaderModule.forChild()
    ],
    providers: [
        GlobalReduxStore,
        BsModalRef,
        HraStartService,
        MemberHraService
    ],
    exports: [
        SingleSpaComponent,
        SingleSpaModuleComponent,
        ComponentsRoutingModule,
        TrophyPopupComponent,
        TrophyModalComponent,
        NoProductComponent
    ]
})
export class ComponentsModule {
}
