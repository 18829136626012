<button type="button"
        id="admin-portal-agreements-close-button"
        class="close"
        (click)="closeModal()"
        [attr.aria-label]="'Close' | translate">
    <span aria-hidden="true">&times;</span>
</button>
<h1 id="admin-agreement-modal-header-title"
    class="agreement-title text-clr-tertiary">
    {{ agreement.title }}
</h1>
<div class="agreement-content-container"
     id="admin-agreement-modal-header-content-container">
    <div *ngIf="agreement.summary"
         id="admin-agreement-modal-header-summary"
         class="agreement-summary"
         [innerHTML]="agreement.summary">
    </div>
    <div class="agreement-content background-clr-bg background-light"
         id="admin-agreement-modal-header-content"
         [innerHTML]="agreement.content"></div>
    <div class="last-updated">Last Updated: {{ agreement.startDate | date:'longDate' }}</div>
</div>
