import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime } from 'rxjs/operators';

import {
    AuthenticatedMemberService,
    PersonalChallenge,
    ReplayChallengeDataService,
    PersonalChallengeTemplate,
    PersonalChallengeService,
    SweetAlertService
} from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'genesis-features-personal-challenge-confirm',
    templateUrl: './personal-challenge-confirm.component.html',
    styleUrls: ['personal-challenge-confirm.component.scss']
})
export class PersonalChallengeConfirmComponent implements OnInit {
    @Input() challengeData: PersonalChallenge | PersonalChallengeTemplate | any = {};
    @Input() displaySingle = false;
    @Output() setStep: EventEmitter<any> = new EventEmitter<any>();
    @Output() completionCallback: EventEmitter<any> = new EventEmitter<any>();

    ZERO = 0;
    AREA_MAX_LENGTH = 250;
    WARNING_LENGTH = 200;
    STEP_TWO = 2;

    COMPLETION_TIMEOUT = 1000;
    counterVisibleSrOnly = false;
    disableSubmitButton = false;
    placeholderText = '';
    memberId = this.authenticatedMemberService.getAuthenticatedMemberId();

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private sweetAlertService: SweetAlertService,
        private replayChallengeData: ReplayChallengeDataService,
        private personalChallengeService: PersonalChallengeService,
        private $translate: TranslateService,
        private router: Router
    ) {}

    ngOnInit() {
        this.placeholderText = this.$translate.instant('AddAPersonalMessageForYourChallenge');
    }

    submitChallenge() {
        this.disableSubmitButton = true;
        const flag = !Object.keys(this.replayChallengeData.personalChallengeData).length;

        if (flag) {
            return;
        }

        this.replayPersonalChallenge(this.replayChallengeData.personalChallengeData);
    }

    editChallenge() {
        if (this.challengeData?.name.length && this.challengeData?.description.length) {
            this.personalChallengeService
                .editPersonalChallenge(this.memberId, this.challengeData)
                .subscribe(() => {
                    if (this.completionCallback) {
                        this.completionCallback.emit();
                    }
                });
        } else {
            this.sweetAlertService.showError(!this.challengeData?.name.length
                ? 'MustHaveAName'
                : 'AddAPersonalMessageForYourChallenge').then();
        }
    }

    replayPersonalChallenge(challenge: PersonalChallenge) {
        const replayChallenge = {
            description: challenge.description,
            name: challenge.name
        };
        this.personalChallengeService.replayPersonalChallenge(this.memberId, replayChallenge, challenge.id)
            .pipe(debounceTime(this.COMPLETION_TIMEOUT))
            .subscribe((response) => {
                const params: NavigationExtras = {
                    queryParams: {
                        id: response.id
                    }
                };
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                    this.router.navigate(['challenges', 'personal', 'details', response.id], params).then();
                });

                if (this.completionCallback) {
                    this.completionCallback.emit();
                }
            }, (error) => {
                this.sweetAlertService.showError(error.error.message).then();
            });
    }

    changeVisibility(condition: boolean) {
        this.counterVisibleSrOnly = condition;
    }

    setStepEmit(value: { step: number }) {
        this.setStep.emit(value);
    }
}
