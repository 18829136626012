import { inject, Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';

import { CommonData } from './common-data';
import { CommonDataService } from './common-data.service';
import { CurrentUser } from './current-user';
import { CurrentUserService } from './current-user.service';


interface InitilizationResult {
    commonData: CommonData;
    currentUser: CurrentUser;
}

@Injectable()
export class ShellInitializationService {

    private commonDataService = inject(CommonDataService);
    private userService = inject(CurrentUserService);

    initialize(): Observable<InitilizationResult> {

        const result = forkJoin({
            commonData: this.commonDataService.getCommonData(),
            currentUser: this.userService.getProfile()
        });

        return result;
    }
}
