import { Injectable } from '@angular/core';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import { COMPANION_URL, COMPANION_ALLOWED_HOSTS } from '@uppy/transloadit';
import XHR from '@uppy/xhr-upload';
import { v4 as uuidv4 } from 'uuid';

import { RetryService } from './retry.service';
import { AuthenticatedMemberService } from '../../src/authentication/authenticated-member.service';
import { getUppyLocale } from '../helpers/uppy-locales';
import { FileUploadResponseModel } from '../models/interfaces/file-upload-response.model';
import { FileUploadModel } from '../models/interfaces/file-upload.model';

@Injectable({
    providedIn: 'root'
})
export class FileUploadService {
    uppy: Uppy = new Uppy();

    private readonly CONSTANTS = {
        TEN_MEGABYTES: 10485760,
        ENDPOINT: 'http://localhost:4000/upload'
    };

    response: FileUploadResponseModel[] = [];

    constructor(
        private retryService: RetryService,
        private authenticatedMemberService: AuthenticatedMemberService
    ) { }

    init(options: FileUploadModel, callbackMethod?: (...args: any[]) => void, cancelCallbackMethod?: (...args: any[]) => void): void {
        if (options?.dashboard?.trigger) {
            this.retryService.retry(
                () => document.querySelector(options.dashboard.trigger),
                () => this.initializeUppy(options, callbackMethod),
                // eslint-disable-next-line no-console
                () => console.error('Failed to find element with selector')
            );
        } else {
            this.initializeUppy(options, callbackMethod, cancelCallbackMethod);
        }
    }

    initializeUppy(options: FileUploadModel, callbackMethod?: (...args: any[]) => void, cancelCallbackMethod?: (...args: any[]) => void): void {
        this.uppy = new Uppy(options.general);
        this.uppy
            .use(Dashboard, options.dashboard)
            .use(XHR, options.xhr);

        this.uppy.on('file-added', (file: any) => this.onFileAdded(file, options));
        this.uppy.on('upload-success', (file, response) => this.onUploadSuccess(file, response));
        this.uppy.on('complete', () => this.onUploadComplete(callbackMethod));
        this.uppy.on('error', (error) => this.onUploadFailed(error, cancelCallbackMethod));
    }

    onUploadFailed(error: any, cancelCallbackMethod?: (...args: any[]) => void): void {
        // eslint-disable-next-line no-console
        console.error(error.stack);
        if (cancelCallbackMethod) {
            cancelCallbackMethod();
        }

    }

    onFileAdded(file: any, options: FileUploadModel) {
        if (['Dashboard'].includes(file.source)) {
            const newFileName = `${uuidv4()}.${file.extension}`;
            const originalFilename = file.name;
            this.uppy.setFileState(file.id, { name: newFileName });
            this.uppy.setFileMeta(file.id, {
                name: newFileName,
                originalFilename: originalFilename,
                categoryId: options.requestPayload.categoryId,
                public: options.requestPayload.public,
                directory: options.requestPayload.directory
            });
        }
    }

    onUploadSuccess(file: any, response: any) {
        // eslint-disable-next-line no-console
        console.log('file: ', file);
        this.response.push(response.body as FileUploadResponseModel);
    }

    onUploadComplete(callbackMethod?: (...args: any[]) => void) {
        if (callbackMethod) {
            callbackMethod(this.response);
        }
        this.response = [];
    }

    private getDefaultDashboardOptions(target: string, trigger: string | null = null, maxSize: number = this.CONSTANTS.TEN_MEGABYTES, lang = 'en'): any {
        return {
            inline: false,
            note: `Images should be ${maxSize}kb or less`,
            target: target, // '#uppy-dashboard',
            trigger: trigger, // '.select-file-button',
            proudlyDisplayPoweredByUppy: false,
            showProgressDetails: true,
            closeModalOnClickOutside: false,
            locale: getUppyLocale(lang),
            closeAfterFinish: false
        };
    }

    getDefaultOptions(target: string, trigger?: string | null, maxSize?: number, lang?: string): FileUploadModel {
        const authClient = this.authenticatedMemberService.getAuthClient();
        return {
            general: {
                autoProceed: false,
                restrictions: {
                    maxFileSize: maxSize,
                    maxNumberOfFiles: 5,
                    allowedFileTypes: ['image/*', '.jpg', '.jpeg', '.png', '.gif', '.bmp']
                }
            },
            dashboard: { ...this.getDefaultDashboardOptions(target, trigger, maxSize, lang) },
            url: {
                companionUrl: COMPANION_URL,
                companionAllowedHosts: COMPANION_ALLOWED_HOSTS
            },
            xhr: {
                endpoint: this.CONSTANTS.ENDPOINT,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authClient?.token ?? false}`
                }
            },
            requestPayload: {}
        };
    }

}
