import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ProgramMember } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class TransformService {

    constructor(private httpClient: HttpClient) { }

    getProgramMembers(memberId: number): Observable<ProgramMember[]> {
        return this.httpClient.get<ProgramMember[]>(`/transform-api/members/${memberId}/program-member`);
    }

}
