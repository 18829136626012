<div class="layout-container">
    <div class="stats-header">Stats</div>
        <div class="card-container">
            <div class="card"
                 *ngFor="let item of statsData">
                <div class="card-content-large">
                    <div class="card-content-item">
                        <vpg-progress-ring
                            size="large"
                            [percentage]="item.activityGoalCompletedPercentage"
                            [image]="item.iconPath"
                        />
                    </div>
                    <div class="card-content-item"><div class="stat-value">{{item.formattedActivityCount}}</div></div>
                    <div class="card-content-item"><div class="stat-label">{{item.activityName}}</div></div>
                    <div class="card-content-item"><div class="stat-goalText">{{item.formattedActivityGoalMsg}}</div></div>
                    <div class="card-content-item"></div>
                </div>
                <div class="card-content-small">
                    <table class="table">
                        <tr>
                            <td rowspan=4 class="td-icon">
                                <vpg-progress-ring
                                    size="large"
                                    [percentage]="item.activityGoalCompletedPercentage"
                                    [image]="item.iconPath"
                                />
                            </td>
                        </tr>
                        <tr>
                            <td class="td td-h30">
                                <div class="card-content-item"><div class="stat-value">{{item.formattedActivityCount}}</div></div>
                            </td>
                        </tr>
                        <tr>
                            <td class="td-h30">
                                <div class="card-content-item"><div class="stat-label">{{item.activityName}}</div></div>
                            </td>
                        </tr>
                        <tr >
                            <td class="td-h30">
                                <div class="card-content-item"><div class="stat-goalText">{{item.formattedActivityGoalMsg}}</div></div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
    </div>
    <div class="stats-footer"><a vpg-link [size]="'small'"
                                 href={{allStatsURL}}>View All Stats</a>
    </div>
</div>
