import { Injectable, NgModule } from '@angular/core';

declare global {
    interface Window {
        sharedServiceRegistry: ISharedServiceRegistry;
    }
}

interface ISharedServiceRegistry {
    components: Record<string, Component>,
    instances: Record<string, any>
}

export interface Component {
    name: string;
    useValue?: any;
    useFactory?: any;
    useExisting?: string;
    shared?: boolean;
    deps?: any[];
}

export class Value {
    constructor(public readonly value: any) {}
    static of(value: any) {
        return new Value(value);
    }
}

@Injectable()
export class SharedServiceLocator {

    static default() : ISharedServiceRegistry {
        return {
            components: {},
            instances: {}
        };
    }

    static clear() {
        window.sharedServiceRegistry = SharedServiceLocator.default();
    }

    constructor() {
        window.sharedServiceRegistry = window.sharedServiceRegistry || SharedServiceLocator.default();
    }

    private get components() {
        return window.sharedServiceRegistry.components;
    }

    private get instances() {
        return window.sharedServiceRegistry.instances;
    }

    register(components: Component | Component[]): void {

        const items = Array.isArray(components) ? components : [components];

        for (const c of items) {
            if (!(c.name in this.components)) {
                this.components[c.name] = c;
            } else {
                // eslint-disable-next-line no-console
                console.warn(`SharedServiceLocator: Component [${c.name}] is alredy registered`);
            }
        }
    }

    get length() {
        return Object.keys(this.components).length;
    }

    has(name: string): boolean {
        return name in this.components;
    }

    get<T = any>(name: string): T | null {
        if (name in this.instances) {
            return this.instances[name] as T;
        }

        if (name in this.components) {
            const c = this.components[name];
            if (c.useValue !== undefined) {
                this.instances[name] = c.useValue;
                return c.useValue;
            } else if (c.useFactory !== undefined) {
                const args: any[] = [];

                if (c.deps) {
                    for (const dep of c.deps) {
                        if (dep instanceof Value) {
                            args.push(dep.value);
                        } else {
                            const service = this.get(dep);
                            args.push(service);
                        }
                    }
                }

                const instance = c.useFactory(...args);

                if (c.shared !== false) {
                    this.instances[c.name] = instance;
                }
                return instance;
            } else if (c.useExisting !== undefined) {
                return this.get(c.useExisting);
            }
        }

        return null;
    }

    getAsync<T = any>(name: string, timeOutInMs = 0): Promise<T | null> {
        return this.waitFor(name, timeOutInMs).then(() => this.get<T>(name));
    }

    waitFor(name: string, timeOutInMs = 0): Promise<void> {

        if (this.has(name)) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {

            let intervalID: any = null;
            let timeoutID: any = null;

            const clearTimers = () => {
                clearInterval(intervalID);
                clearTimeout(timeoutID);
            };
            const checkService = () => {
                if (this.has(name)) {
                    clearTimers();
                    resolve();
                }
            };
            const timeOut = () => {
                clearTimers();
                reject();
            };

            intervalID = setInterval(checkService, 100);

            if (timeOutInMs > 0) {
                timeoutID = setTimeout(timeOut, timeOutInMs);
            }
        });
    }
}

@NgModule({
    providers: [
        { provide: SharedServiceLocator, useClass: SharedServiceLocator }
    ]
})
export class SharedServiceLocatorModule {}
