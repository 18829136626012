import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { KeycloakService } from '../../authentication/keycloak.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedMemberService {

    authenticatedMember: any;
    isReady = false;
    userIsMember = false;
    Authenticated = false;
    readySubscribers = [];

    constructor(
        private httpClient: HttpClient,
        private keycloakService: KeycloakService
    ) {}

    isUsingKeycloak() {
        const isUsingKeycloak = this.keycloakService.getClient() && this.keycloakService.getClient().token;
        if (isUsingKeycloak) {
            if (this.keycloakService.getClient().hasRealmRole('agent')) {
                this.userIsMember = false;
                return;
            }
        }
    }

    authenticate(): Observable<any> {
        this.userIsMember = true;
        return this.httpClient.get<any>('/api/members/authenticated').pipe(
            mergeMap((authenticatedMember: any) => {
                this.authenticatedMember = authenticatedMember;

                return forkJoin({
                    memberSettings: this.getMemberSettings(authenticatedMember.id),
                    sponsor: this.getMemberSponsor(authenticatedMember.id),
                    sponsorSettings: this.getSponsorSettings(authenticatedMember.id, authenticatedMember.sponsorId),
                    memberFeatures: this.getMemberFeatures(authenticatedMember.id),
                    roles: this.getMemberRoles(authenticatedMember.id),
                    distributor: this.isDistributorAdmin(authenticatedMember.id)
                }).pipe(
                    map((results: any) => {
                        this.authenticatedMember.settings = results.memberSettings;
                        this.authenticatedMember.sponsor = results.sponsor;
                        this.authenticatedMember.sponsor.settings = results.sponsorSettings;
                        this.authenticatedMember.sponsor.features = results.memberFeatures;
                        this.authenticatedMember.roles = results.roles;
                        this.authenticatedMember.distributor = results.distributor.distributorId ? results.distributor.distributorId : null;
                        return this.authenticatedMember;

                    })
                );
            })
        );
    }

    isUserMember() {
        return this.userIsMember;
    }

    getId() {
        return this.authenticatedMember.id;
    }

    getAuthenticatedMember() {
        return this.authenticatedMember;
    }

    logout() {
        this.Authenticated = false;
        this.authenticatedMember = null;
        if (this.keycloakService.isUsingKeycloak()) {
            this.keycloakService.getClient().logout({
                redirectUri: window.location.origin
            });
        }
    }

    notLoggedIn() {
        this.authenticatedMember = null;
        this.Authenticated = false;

        if (this.keycloakService.isUsingKeycloak()) {
            this.keycloakService.getClient().login();
        }
    }

    ready(callback: any) {
        if (this.authenticatedMember && this.isReady) {
            callback(this.authenticatedMember);
        } else {
            this.readySubscribers.push();
        }
    }

    private getMemberSettings(memberId: number) {
        return this.httpClient.get<any>(`/api/members/${memberId}/settings`);
    }

    private getMemberSponsor(memberId: number) {
        return this.httpClient.get<any>(`/api/members/${memberId}/sponsor`);
    }

    private getSponsorSettings(memberId: number, sponsorId: number) {
        return this.httpClient.get<any>(`/api/members/${memberId}/sponsors/${sponsorId}/settings`);
    }

    private getMemberFeatures(memberId: number) {
        return this.httpClient.get<any[]>(`/api/members/${memberId}/features`);
    }

    private getMemberRoles(memberId: number) {
        return this.httpClient.get(`/api/client-admin/members/${memberId}/roles`);
    }

    private isDistributorAdmin(memberId: number) {
        return this.httpClient.get(`/api/client-admin/members/${memberId}/distributor-admin`);
    }
}
