import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

import { TrophyModalService } from '../trophy-modal/trophy-modal.service';
import {
    PusherConnectionService
} from '@app/core/services';
import {
    BrowserService,
    AuthenticatedMemberService,
    RewardRuleType,
    PlanType,
    Member,
    AchievementsService,
    FeatureEnum
} from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'trophy-popup',
    templateUrl: './trophy-popup.component.html'
})
export class TrophyPopupComponent implements OnInit {

    modalIsShowing = false;
    trophies = [];
    currentSlide = 1;
    hasTrophiesFeature = false;
    numberOfTrophies = 0;
    member: Member = {} as Member;

    constructor(
        private cookiesService: CookieService,
        private browserService: BrowserService,
        private authenticatedMemberService: AuthenticatedMemberService,
        private pusherConnectionService: PusherConnectionService,
        private achievementsService: AchievementsService,
        private trophyModalService: TrophyModalService
    ) { }

    ngOnInit() {
        this.member = this.authenticatedMemberService.getAuthenticatedMember();
        this.loadNotificationsService();
    }

    shouldShowNotification() {
        const shouldHideNotifications = this.cookiesService.get('hide_notifications');
        const detectMobile = this.browserService.detectMobile();
        return !shouldHideNotifications.length && !detectMobile;
    }

    loadNotificationsService() {
        if (!this.authenticatedMemberService.hasFeature(FeatureEnum.Trophies)) {
            return;
        }

        setTimeout(() => {
            if (this.shouldShowNotification()) {
                this.loadV2Trophies();
            }
        }, 6000);

        this.pusherConnectionService.getConnection()
            .then((pusher) => {
                // @ts-ignore
                pusher.on('notify', (notification) => {
                    this.showNotification(notification);
                });
            }
            );
    }

    showNotification(notification: any) {
        const notificationType = notification.type;
        const notificationMessage = notification.message;
        if (notificationType === RewardRuleType.AchievementEarned && this.shouldShowNotification()) {
            const newTrophies = [];
            const trophyData = JSON.parse(notificationMessage);
            if (!trophyData.memberEarnedDate) {
                trophyData.memberEarnedDate = trophyData.lastEarnedMemberDate;
            }
            newTrophies.push(trophyData);
            this.numberOfTrophies = newTrophies.length;
            if (this.isExpress() && trophyData.achievement.hiddenToExpress === true) {
                return false;
            }
            this.triggerPopupAnimation(newTrophies);
        }
    }

    isExpress() {
        const authMember = this.authenticatedMemberService.getAuthenticatedMember();
        return authMember.sponsor.planType === PlanType.Express;
    }

    loadV2Trophies() {
        this.achievementsService.getRecentAchievementsForMember(this.member.id, this.member.sponsorId).subscribe((achievements: any) => {
            if (achievements.length && !this.modalIsShowing) {
                this.modalIsShowing = true;
                this.trophyModalService.openModal(achievements);
                this.trophyModalService.closeSubscription.subscribe(() => {
                    this.setAllTrophiesDisplayed(achievements);
                    this.focusMainContent();
                });
            }
        });
    }

    setAllTrophiesDisplayed(trophies: any) {
        this.achievementsService.setTrophiesAsSeen(this.member.id, this.member.sponsorId, trophies).subscribe(() => {
            this.modalIsShowing = false;
        });
    }

    triggerPopupAnimation(trophies: any) {
        const isCoreMember = this.authenticatedMemberService.isCore();
        if (!this.checkActivePopups()) {
            if (!isCoreMember && !this.modalIsShowing) {
                this.modalIsShowing = true;
                this.trophyModalService.openModal(trophies);
                this.trophyModalService.closeSubscription.subscribe(() => {
                    this.focusMainContent();
                    this.setAllTrophiesDisplayed(trophies);
                });
            } else {
                this.setAllTrophiesDisplayed(trophies);
            }
        }
    }

    checkActivePopups(): boolean {
        const modalElements = document.querySelectorAll('.modal.fade');
        return modalElements.length > 0 ? modalElements[0].classList.contains('in') : false;
    }

    focusMainContent() {
        const element = document.querySelector('#main-content-begin');
        if (element) {
            (element as HTMLElement).focus();
        }
    }
}
