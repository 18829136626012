import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    HealthyHabitsMemberTracker,
    PromotedHealthyHabitChallenge,
    TrackerChallenge
} from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class TooManyHabitsModalService {
    constructor(private httpClient: HttpClient) { }

    getMemberTrackers(memberId: number): Observable<HealthyHabitsMemberTracker[]> {
        return this.httpClient.get<HealthyHabitsMemberTracker[]>(this.baseMemberTrackersURI(memberId), {
            params: {
                hideInChallenge: String(false),
                showHiddenTrackers: String(true)
            }
        });
    }

    deleteTracker(memberId: number, trackerId: number) {
        return this.httpClient.delete(`${this.baseMemberTrackersURI(memberId)}/${trackerId}`);
    }

    getTrackerChallenges(memberId: number) {
        return this.httpClient.get<TrackerChallenge[]>(`/api/members/${memberId}/tracker-challenges`);
    }

    getPromotedTrackerChallenges(memberId: number) {
        return this.httpClient.get<PromotedHealthyHabitChallenge[]>(`/api/members/${memberId}/promoted-tracker-challenges`);
    }

    addTracker(memberId: number, trackerId: number) {
        const params = new HttpParams()
            .set('memberId', memberId.toString())
            .set('trackerId', trackerId.toString());

        return this.httpClient.put(`${this.baseMemberTrackersURI(memberId)}/${trackerId}`, { params });
    }

    private baseMemberTrackersURI = (memberId: number) => `/api/members/${memberId}/trackers`;
}
