import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CalendarEventService } from './calendar-event-modal.service';
import {
    AuthenticatedMemberService,
    MemberTimezoneService,
    MemberEventRsvp, DateUtils
} from '@genesis-frontend/genesis-utilities';


@Component({
    selector: 'calendar-event-modal',
    templateUrl: './calendar-event-modal.component.html',
    styleUrls: ['calendar-event-modal.component.scss']
})
export class CalendarEventModalComponent implements OnInit {
    @Input() calendarEvent: any;
    date = '';
    memberId = 0;
    memberRsvp = false;
    rsvpInfoLoaded = false;

    readonly TRANSLATIONS = {
        ARE_YOU_GOING: 'AreYouGoing',
        I_AM_GOING: 'IamGoing',
        YES_I_AM_IN: 'YesIamIn'
    };

    @Output() closeDialog: EventEmitter<string | boolean> = new EventEmitter<string | boolean>();

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private calendarEventService: CalendarEventService,
        private memberTimezoneService: MemberTimezoneService
    ) { }

    ngOnInit(): void {
        this.memberId = this.authenticatedMemberService.getAuthenticatedMember().id;
        this.date = this.getDateDisplay(this.calendarEvent);
        if (this.calendarEvent.allowRsvp) {
            this.calendarEventService.getMemberRsvp(this.calendarEvent.id, this.memberId).subscribe(() => {
                this.memberRsvp = true;
                this.rsvpInfoLoaded = true;
            }, () => {
                this.memberRsvp = false;
                this.rsvpInfoLoaded = true;
            });
        }
    }
    setRsvp() {
        if (!this.memberRsvp) {
            const newEventRsvp: MemberEventRsvp = {
                id: null,
                eventId: this.calendarEvent.id,
                memberId: this.memberId,
                createdDate: null
            };
            return this.calendarEventService.setMemberRsvp(newEventRsvp).subscribe(() => {
                this.memberRsvp = true;
            });
        }
        return this.calendarEventService.deleteMemberRsvp(this.calendarEvent.id, this.memberId).subscribe(() => {
            this.memberRsvp = false;
        });

    }

    private getDateDisplay(value: any): string {
        const memberTimezone = this.authenticatedMemberService.getMemberTimezone();

        const startEventDate = value.allDayEvent
            ? DateUtils.format(DateUtils.toUtcDate(value.startDateTime), 'lll')
            : DateUtils.format(this.memberTimezoneService.getDateInMemberDate(value.startDateTime, memberTimezone), 'lll');
        const endEventDate = value.allDayEvent
            ? DateUtils.format(DateUtils.toUtcDate(value.endDateTime), 'lll')
            : DateUtils.format(this.memberTimezoneService.getDateInMemberDate(value.endDateTime, memberTimezone), 'lll');
        const startEventTime = DateUtils.format(
            this.memberTimezoneService.getDateTimeInMemberDateTime(value.startDateTime, memberTimezone),
            'h:mm a'
        );
        const endEventTime = DateUtils.format(
            this.memberTimezoneService.getDateTimeInMemberDateTime(value.endDateTime, memberTimezone),
            'h:mm a'
        );

        if (startEventDate === endEventDate) {
            if (value.allDayEvent) {
                return startEventDate;
            }

            return startEventDate + ', ' + startEventTime + ' - ' + endEventTime;
        }

        if (value.allDayEvent) {
            return startEventDate + ' - ' + endEventDate;
        }

        return startEventDate + ', ' + startEventTime + ' - ' + endEventDate + ', ' + endEventTime;
    }
}
