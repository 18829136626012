import { Inject, Injectable } from '@angular/core';
import Pusher from 'pusher-js';
import { Config } from 'pusher-js/types/src/core/config';

import { Environment, ENVIRONMENT, KeycloakService } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class WebsocketService {
    pusher: any;

    constructor(
        private keycloakService: KeycloakService,
        @Inject(ENVIRONMENT) private environment: Environment
    ) {

        let pusherKey = this.environment.pusher.key;
        let pusherCluster = this.environment.pusher.cluster;

        if (this.environment.pusher.wsHost === 'undefined') {
            if (this.keycloakService && this.keycloakService.getClient() && this.keycloakService.getClient().token) {
                if (this.keycloakService.getClient().tokenParsed && this.keycloakService.getClient().tokenParsed.region === 'EU1') {
                    // @ts-ignore
                    pusherKey = this.environment.pusher.keyEU;
                    pusherCluster = this.environment.pusher.clusterEU;
                }

                this.pusher = new Pusher(pusherKey, {
                    authEndpoint: '/api/notifications/auth',
                    cluster: pusherCluster,
                    auth: {
                        headers: {
                            Authorization: 'Bearer ' + this.keycloakService.getClient().token
                        }
                    }
                });
            } else {
                this.pusher = new Pusher(pusherKey, {
                    cluster: pusherCluster,
                    authEndpoint: '/api/notifications/auth'
                });
            }
        } else {
            this.pusher = new Pusher(pusherKey, this.environment.pusher as unknown as Config);
        }

        this.connect = this.connect.bind(this);
        this.bindConnectionState = this.bindConnectionState.bind(this);
        this.disconnect = this.disconnect.bind(this);
    }

    bindConnectionState() {
        return {
            stateChange: (connectionState: string, callback: any) => {
                this.pusher.connection.bind(connectionState, (data: any) => {
                    callback(data);
                });
            }
        };
    }

    disconnect() {
        this.pusher.disconnect();
    }

    connect(channelName: string): any {
        const channel = this.pusher.subscribe(channelName);
        let enabled = true;
        const queueOn: any[] = [];
        return {
            on: (eventName: string, callback: any) => {
                channel.bind(eventName, (data: any) => {
                    if (enabled) {
                        callback(data);
                    } else {
                        queueOn.push({ callback, notification: data });
                    }
                });
            },
            emit: (eventName: string, data: any, callback: any) => {
                channel.trigger(eventName, data);
                if (callback) {
                    callback();
                }
            },
            disable: () => {
                enabled = false;
            },
            enable: () => {
                enabled = true;
                while (queueOn.length) {
                    const delayedNotification = queueOn.pop();
                    if (!delayedNotification.callback) {
                        continue;
                    }
                    delayedNotification.callback(delayedNotification.notification);
                }
            },
            unsubscribe: () => {
                this.pusher.unsubscribe(channelName);
            }
        };
    }
}
