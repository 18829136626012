import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { KeycloakService } from '../../../authentication/keycloak.service';
import { AuthenticatedMemberService } from '../authenticated-member.service';

const FORBIDDEN_STATUS_CODE = 401;
const AGENT_ROLE = 'agent';

@Injectable({
    providedIn: 'root'
})
export class HttpResponseInterceptor implements HttpInterceptor {

    constructor(
        private keycloakService: KeycloakService,
        private authenticatedMemberService: AuthenticatedMemberService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse && error.status === FORBIDDEN_STATUS_CODE) {
                    const client = this.keycloakService.getClient();
                    if (client && client.hasRealmRole(AGENT_ROLE)) {
                        return throwError(() => error);
                    }
                    this.authenticatedMemberService.notLoggedIn();
                }
                return throwError(() => error);
            })
        );
    }
}
