import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { TrophyModalComponent } from './trophy-modal.component';

@Injectable({
    providedIn: 'root'
})
export class TrophyModalService {
    closeSubscription = this.bsModalService.onHide;

    constructor(private bsModalService: BsModalService) {}

    openModal(trophies: any) {
        return this.bsModalService.show(TrophyModalComponent, {
            class: 'trophy-modal',
            backdrop: true,
            animated: false,
            ignoreBackdropClick: true,
            initialState: {
                trophies
            }
        });
    }
}
