import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class MemberHraService {
    constructor(private httpClient: HttpClient) {}

    updateHRA(memberId: number): Observable<any> {
        return this.httpClient.put(`api/members/${memberId}/HRA`, null);
    }

    getProviderInfo(memberId: number): Observable<any> {
        return this.httpClient.get(`api/members/${memberId}/HRA/provider`);
    }
}
