import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'values' })
export class ValuesPipe implements PipeTransform {
    transform(input: Map<any, any>): any[] {
        if (!input) {
            return [];
        }
        return Array.from(input.values());
    }
}
