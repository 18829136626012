import { Pipe, PipeTransform } from '@angular/core';

import { GameDetailsActions } from '../models/enums/game-details-actions.enum';

@Pipe({ name: 'filterLevelActions' })
export class FilterLevelActionsPipe implements PipeTransform {
    transform(disabled: boolean, availableActions: GameDetailsActions[], hasGameStarted: boolean): GameDetailsActions[] {
        if (disabled && hasGameStarted) {
            return availableActions.filter((action) => action !== GameDetailsActions.Replace);
        }
        return availableActions;
    }
}
