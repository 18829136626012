import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FlipClockComponent } from './flip-clock.component';
import { FlipItemComponent } from './flip-item/flip-item.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        FlipClockComponent,
        FlipItemComponent
    ],
    exports: [
        FlipClockComponent
    ]
})
export class FlipClockModule {}
