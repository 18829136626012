import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environments/environment';
import * as singleSpa from 'single-spa';

import { AppModule } from '@app/app.module';

import './assets/lib/newrelic';

declare const window: any;

window.singleSpa = singleSpa;

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    // eslint-disable-next-line no-console
    .catch((err) => console.error(err));
