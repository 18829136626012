import { Type, enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { getSingleSpaExtraProviders, singleSpaAngular } from 'single-spa-angular';
import { setPublicPath } from 'systemjs-webpack-interop';

import { Environment, ENVIRONMENT } from './environment';
import { environment } from './environments/environment';
import {
    AUTHENTICATED_USER_SERVICE,
    ShellApplication,
    SHELL_APPLICATION,
    AUTHENTICATED_MEMBER_SERVICE,
    SELECTED_SPONSOR_SERVICE_REPLAY_SUBJECT
} from '../core';
import { singleSpaPropsSubject } from '../models';
import { StoreNames } from '../state-management/global-stores.enum';

interface BootstrapOptions {
    module: Type<any>;
    template: string;
    environment: any;
    publicPath: string;
    router?: Type<any>;
    navigationStart?: Type<any>;
}

function getBootstrapFunction(options: BootstrapOptions) {
    return (props: any) => {

        singleSpaPropsSubject.next(props);

        if (!props.shellApplication) {
            // eslint-disable-next-line no-console
            console.error('Property shellApplication is missing', options.publicPath);
        }

        if (!props.shellApplication) {
            props.shellApplication = ShellApplication.createFrom(
                props.mixpanel,
                Environment.fromGlobal(),
                props.authenticatedMemberService,
                props.authClient
            );

            props.shellApplication.sponsorReplaySubject = props.sponsorReplaySubject;
            props.shellApplication.isAuthenticatedUserAnAgent = props.isAuthenticatedUserAnAgent;
            props.shellApplication.authenticatedUser = props.authenticatedUser;
        }
        if (!props.shellApplication.mixpanel) {
            props.shellApplication.mixpanel = props.mixpanel;
        }

        const singleSpaProviders = getSingleSpaExtraProviders();
        const shellApplication = props.shellApplication as ShellApplication;
        const shellProviders = shellApplication.getProviders();
        const authenticatedMemberService = props.authenticatedUserService ? props.authenticatedUserService.authenticatedMemberService : null;

        const providers = [
            ...singleSpaProviders,
            { provide: ENVIRONMENT, useValue: Environment.fromGlobal() },
            { provide: SHELL_APPLICATION, useValue: props.shellApplication },
            { provide: AUTHENTICATED_USER_SERVICE, useValue: props.authenticatedUserService },
            { provide: AUTHENTICATED_MEMBER_SERVICE, useValue: authenticatedMemberService },
            { provide: SELECTED_SPONSOR_SERVICE_REPLAY_SUBJECT, useValue: props.sponsorReplaySubject },
            ...shellProviders
        ];


        if (props.authenticatedUserService?.getAuthenticatedUser()) {
            props.shellApplication.mixpanel = environment.mixpanel.token;
            props.shellApplication.shellAppName = StoreNames.GENESIS_CLIENT_ADMIN;
        }

        return platformBrowserDynamic(providers).bootstrapModule(options.module);
    };
}

export function bootstrapApplication(options: BootstrapOptions) {

    setPublicPath(options.publicPath);

    if (options.environment?.production) {
        enableProdMode();
    }

    return singleSpaAngular({
        bootstrapFunction: getBootstrapFunction(options),
        template: options.template,
        NgZone,
        Router: options.router,
        NavigationStart: options.navigationStart
    });
}
