<div class="modal-content resend-mfa-code-modal text-clr-tertiary">
    <div class="modal-header">
        <div class="h3 font-weight-bold modal-title"
             [translate]="'RequestNewCodeQuestion'">
        </div>
    </div>
    <div class="modal-body form-group">
        <div [innerHTML]="'TheCodeMayTake4MinutesToArrive' | translate"></div>
        <div [translate]="'ResendingWillInvalidatePreviousCode'"></div>
    </div>
    <div class="modal-footer">
        <button class="member-profile-button member-profile-button-primary-inverse bordered btn-orange-large"
                (click)="closeCallback.emit(false)"
                [translate]="'NoWaitForCode'">
        </button>
        <button class="member-profile-button member-profile-button-primary bordered btn-orange-large"
                (click)="closeCallback.emit(true)"
                [translate]="'YesSendNewCode'">
        </button>
    </div>
</div>
