import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { AuthSetup } from './auth-setup.service';
import { AuthenticatedMemberRoleService } from './authenticated-member-role.service';
import { AuthenticatedMemberService } from './authenticated-member.service';
import { AuthenticatedTimeoutService } from './authenticated-timeout.service';
import { AuthenticationProviderHelperService } from './authentication-provider-helper.service';
import { HttpEncodeParamsInterceptor } from './http-encode-params.interceptor';
import { HttpRequestInterceptor } from './http-request.interceptor';
import { KeycloakService } from './keycloak.service';
import { LoginService } from './login.service';
import { MemberAuthService, MicroFrontendAuthService, ShellAuthService } from './member-auth.service';
import { MemberResponseRef, MemberService } from './member.service';
import { AuthSetup as AuthClientSetup } from '../client/services/auth-setup.service';
import { AuthenticatedMemberService as AuthenticatedClientService } from '../client/services/authenticated-member.service';
import { AuthenticatedTimeoutService as AuthenticatedTimeoutClientService } from '../client/services/authenticated-timeout.service';
import { AuthenticatedUserService } from '../client/services/authenticated-user.service';
import { HttpRequestInterceptor as ClientHttpRequestInterceptor } from '../client/services/interceptors/http-request.interceptor';
import { HttpResponseInterceptor as ClientHttpResponseInterceptor } from '../client/services/interceptors/http-response.interceptor';
import { RoleAuthorizationService } from '../client/services/role-authorization.service';
import { ShellApplication, SHELL_APPLICATION, AUTHENTICATED_USER_SERVICE, AUTHENTICATED_MEMBER_SERVICE } from '../core';
import { GraphQLModule } from '../graphql/graphql.module';
import { AnalyticsTrackingSingleSpaService, CommonApiInterceptor, LanguageService, UrlUtil, UserService } from '../services';
import { ShellModule } from '../shell';
import { GlobalReduxStore } from '../state-management/redux.store';

@NgModule({
    imports: [
        CommonModule,
        GraphQLModule,
        ShellModule,
        HttpClientModule
    ],
    providers: [
        AuthSetup,
        MemberService,
        LoginService,
        UrlUtil,
        MemberResponseRef,
        AuthenticatedMemberService,
        AuthenticatedTimeoutService,
        AuthenticatedMemberRoleService,
        AuthenticationProviderHelperService,
        KeycloakService,
        AnalyticsTrackingSingleSpaService,
        LanguageService,
        GlobalReduxStore,
        UserService,
        ShellModule,
        GraphQLModule,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpRequestInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpEncodeParamsInterceptor,
            multi: true
        },
        {
            provide: MemberAuthService,
            useClass: MicroFrontendAuthService
        }
    ]
})
export class GenesisAuthenticationModule {

    public static forFrontend(): ModuleWithProviders<GenesisAuthenticationModule> {

        return {
            ngModule: GenesisAuthenticationModule,
            providers: [
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: CommonApiInterceptor,
                    multi: true
                },
                {
                    provide: MemberAuthService,
                    useClass: MicroFrontendAuthService
                },
                {
                    provide: ShellApplication,
                    useExisting: SHELL_APPLICATION
                }
            ]
        };
    }

    public static forShell(): ModuleWithProviders<GenesisAuthenticationModule> {
        return {
            ngModule: GenesisAuthenticationModule,
            providers: [
                {
                    provide: MemberAuthService,
                    useClass: ShellAuthService
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: CommonApiInterceptor,
                    multi: true
                }
            ]
        };
    }
}

@NgModule({
    imports: [
        CommonModule,
        GraphQLModule,
        HttpClientModule,
        ShellModule
    ],
    providers: [
        AuthClientSetup,
        UrlUtil,
        AuthenticatedTimeoutClientService,
        KeycloakService,
        GlobalReduxStore,
        AuthenticatedClientService,
        AuthenticatedUserService,
        RoleAuthorizationService,
        GraphQLModule,
        UserService,
        ShellModule
    ]
})
export class GenesisAuthenticationClientModule {

    public static forFrontend(): ModuleWithProviders<GenesisAuthenticationClientModule> {
        return {
            ngModule: GenesisAuthenticationClientModule,
            providers: [
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: ClientHttpRequestInterceptor,
                    multi: true
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: ClientHttpResponseInterceptor,
                    multi: true
                },
                {
                    provide: ShellApplication,
                    useExisting: SHELL_APPLICATION
                },
                {
                    provide: AuthenticatedUserService,
                    useExisting: AUTHENTICATED_USER_SERVICE
                },
                {
                    provide: AuthenticatedClientService,
                    useExisting: AUTHENTICATED_MEMBER_SERVICE
                }
            ]
        };
    }

    public static forShell(): ModuleWithProviders<GenesisAuthenticationClientModule> {
        return {
            ngModule: GenesisAuthenticationClientModule,
            providers: [
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: ClientHttpRequestInterceptor,
                    multi: true
                },
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: ClientHttpResponseInterceptor,
                    multi: true
                }
            ]
        };
    }

    public static forClientAdminMF(): ModuleWithProviders<GenesisAuthenticationClientModule> {
        return {
            ngModule: GenesisAuthenticationClientModule,
            providers: [
                {
                    provide: ShellApplication,
                    useExisting: SHELL_APPLICATION
                }
            ]
        };
    }
}
