import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class SweetAlertService {
    submissionModalDefaults = {
        title: this.$translate.instant('PublishForm'),
        text: this.$translate.instant('PublishFormConfirmation'),
        showCancelButton: true,
        cancelButtonText: this.$translate.instant('Cancel'),
        confirmButtonText: this.$translate.instant('Yes'),
        buttonsStyling: false,
        reverseButtons: true,
        customClass: {
            confirmButton: 'vp-button vp-button-primary',
            cancelButton: 'vp-button vp-button-primary-inverse bordered swal-confirm-spacer'
        }
    };

    constructor(
        private $translate: TranslateService
    ) { }

    showError(errorMessage: string, icon?: SweetAlertIcon): Promise<SweetAlertResult> {
        return Swal.fire({
            titleText: this.$translate.instant('Oops'),
            html: '<div aria-hidden="true">' + this.$translate.instant(errorMessage) + '</div>',
            icon: icon ? icon : 'error',
            buttonsStyling: false,
            confirmButtonAriaLabel: this.$translate.instant(errorMessage) + ' ' + this.$translate.instant('OkayButton'),
            customClass: {
                confirmButton: 'vp-button vp-button-primary'
            }
        });
    }

    showChallengeJoinMessage(challengeName: string): Promise<SweetAlertResult> {
        return Swal.fire({
            titleText: this.$translate.instant('Success'),
            text: this.$translate.instant('SuccessfullyJoinedChallenge', { challengeName }),
            icon: 'success',
            buttonsStyling: false,
            customClass: {
                confirmButton: 'vp-button vp-button-primary'
            }
        });
    }

    showSuccessMessage(message: string, options: any): Promise<SweetAlertResult> {
        return Swal.fire({
            titleText: this.$translate.instant('Success'),
            html: message,
            icon: 'success',
            buttonsStyling: false,
            customClass: {
                confirmButton: 'vp-button vp-button-primary'
            }, ...options
        });
    }

    showChangeTeamsConfirmation(oldTeamName: string, newTeamName: string): Promise<SweetAlertResult> {
        return Swal.fire({
            title: this.$translate.instant('ChangeTeamsQuestion'),
            text: this.$translate.instant('AreYouSureYouWantToLeaveOldAndJoinNewTeam', {
                oldTeamName,
                newTeamName
            }),
            icon: 'warning',
            showCancelButton: true,
            buttonsStyling: false,
            confirmButtonText: this.$translate.instant('YesChangeTeams'),
            cancelButtonText: this.$translate.instant('NoGoBack'),
            customClass: {
                confirmButton: 'vp-button vp-button-primary swal-confirm-spacer',
                cancelButton: 'vp-button vp-button-primary-inverse bordered'
            }
        });
    }

    replaceWithH1Tag() {
        const titleElement = document.querySelector('#swal2-title');
        const titleWithH1Tag = document.createElement('h1');
        titleWithH1Tag.setAttribute('class', titleElement?.getAttribute('class') ?? '');
        titleWithH1Tag.setAttribute('id', titleElement?.getAttribute('id') ?? '');
        titleWithH1Tag.innerText = titleElement?.innerHTML ?? '';
        return titleElement?.replaceWith(titleWithH1Tag);
    }

    showNotAbleToJoinChallengeModal(): Promise<SweetAlertResult> {
        return Swal.fire({
            title: this.$translate.instant('Whoops'),
            text: this.$translate.instant('NotEligibleToJoinChallenge'),
            icon: 'warning',
            confirmButtonText: this.$translate.instant('OK'),
            buttonsStyling: false,
            customClass: {
                confirmButton: 'vp-button vp-button-primary swal-confirm-spacer'
            }
        });
    }

    showLeaveChallengeConfirmation(canRejoin: boolean, deadlineDate: string): Promise<SweetAlertResult> {
        return Swal.fire({
            title: this.$translate.instant('LeaveThisChallengeQuestion'),
            text: canRejoin
                ? this.$translate.instant('IfTheChallengeHasAlreadyStartedWithRejoin', { deadlineDate })
                : this.$translate.instant('IfTheChallengeHasAlreadyStartedWithoutRejoin'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$translate.instant('YesLeaveChallenge'),
            cancelButtonText: this.$translate.instant('NoGoBack'),
            buttonsStyling: false,
            customClass: {
                confirmButton: 'vp-button vp-button-primary swal-confirm-spacer',
                cancelButton: 'vp-button vp-button-primary-inverse bordered'
            }
        });
    }

    showSendJoinTeamReminder(message: string): Promise<SweetAlertResult> {
        return Swal.fire({
            title: this.$translate.instant('ChallengePending'),
            text: message,
            showCancelButton: true,
            confirmButtonText: this.$translate.instant('SendReminder'),
            cancelButtonText: this.$translate.instant('Okay'),
            buttonsStyling: false,
            customClass: {
                confirmButton: 'vp-button vp-button-primary swal-confirm-spacer',
                cancelButton: 'vp-button vp-button-primary'
            }
        });
    }

    showAreYouSureWantToRemovePlayerModal(member: string): Promise<SweetAlertResult> {
        return Swal.fire({
            title: this.$translate.instant('RemovePlayer'),
            text: this.$translate.instant('AreYouSureYouWantToRemove', { member }),
            showCancelButton: true,
            cancelButtonText: this.$translate.instant('Cancel'),
            confirmButtonText: this.$translate.instant('YesRemove'),
            buttonsStyling: false,
            reverseButtons: true,
            customClass: {
                confirmButton: 'vp-button vp-button-primary swal-confirm-spacer swal-confirm-spacer-no-margin-right',
                cancelButton: 'vp-button vp-button-primary'
            }
        });
    }

    showRemovingPlayerInviteErrorModal(member: string): Promise<SweetAlertResult> {
        return Swal.fire({
            text: this.$translate.instant('MemberCanNotBeRemoved', { member }),
            showCancelButton: false,
            confirmButtonText: this.$translate.instant('Okay'),
            buttonsStyling: false,
            customClass: {
                confirmButton: 'vp-button vp-button-primary',
                cancelButton: 'vp-button vp-button-primary'
            }
        });
    }

    showSubmissionFormConfirmationModal(modalOptions: any): Promise<SweetAlertResult> {
        const modalParams = { ...this.submissionModalDefaults, ...modalOptions };
        return Swal.fire(modalParams);
    }

    show(params: any) {
        return Swal.fire(params);
    }

    confirm(title: string, text: string, icon?: SweetAlertIcon): Promise<SweetAlertResult> {
        return Swal.fire({
            title: this.$translate.instant(title),
            text: this.$translate.instant(text),
            icon,
            showCancelButton: true,
            cancelButtonText: this.$translate.instant('Cancel'),
            buttonsStyling: false,
            confirmButtonText: this.$translate.instant('.OK'),
            customClass: {
                confirmButton: 'vp-button vp-button-primary swal-confirm-spacer',
                cancelButton: 'vp-button vp-button-primary-inverse bordered'
            },
            onOpen: () => {
                this.replaceWithH1Tag();
            }
        });
    }
}
