<div *ngIf="pillarTopicItems && pillarTopicItems.length"
     class="recommended-list-container">

  <div class="recommended-list-header">
    <h4>{{ 'Recommended' | translate}}</h4>
  </div>

  <div id="recommended-topics-description"
       class="recommended-list-panel">
    <p>{{'RecommendedTopics' | translate}}</p>
  </div>

  <div class="recommended-pillar-topic-container">
    <div *ngFor="let topic of pillarTopicItems" class="recommended-list-panel">
      <pillar-topic-select-item [topic]="topic"
                                (onTopicSelect)="onTopicSelection($event)">
      </pillar-topic-select-item>
    </div>

    <div class="recommended-list-panel">
      <button
        *ngIf="isLoadMoreRendered()"
        id="recommended-topics-expand-btn"
        class="load-more-button"
        (click)="expandRecommended()"
        (keyup.enter)="expandRecommended()"
      >
        {{collapsibleListLabel | translate}}
      </button>
    </div>

  </div>
</div>