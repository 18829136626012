import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ResendingMfaCodeWarningModalComponent } from './resending-mfa-code-warning-modal.component';

@Injectable()
export class ResendingMfaCodeWarningModalService {
    constructor(
        private bsModalService: BsModalService
    ) {}

    open() {
        return this.bsModalService.show(ResendingMfaCodeWarningModalComponent, {
            backdrop: true,
            animated: false,
            class: 'modal-md'
        });
    }
}
