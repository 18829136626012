import { AfterViewInit, Component, OnInit } from '@angular/core';

import { AuthenticatedMemberService } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'trustarc',
    templateUrl: './trustarc.component.html'
})
export class TrustarcComponent implements OnInit, AfterViewInit {
    constants = {
        SLICE_NEGATIVE_NUM: -2,
        SLICE_POSITIVE_NUM: 2,
        ZERO: 0,
        THREE_HUNDRED: 300,
        ONE_THOUSAND: 1000
    };

    constructor(private authenticatedMemberService: AuthenticatedMemberService) {}

    ngOnInit(): void {
        const language = this.setLanguage(this.authenticatedMemberService.getAuthenticatedMember().language);
        this.loadScript(language);
    }

    loadScript(language: string): void {
        const head = document.getElementsByTagName('head')[0];
        const trustarcScript = document.createElement('script');
        trustarcScript.async = true;
        trustarcScript.crossOrigin = 'true';
        trustarcScript.src =
            '//consent.trustarc.com/notice?domain=personifyhealth.com&c=teconsent&js=nj&noticeType=bb&text=true&gtm=1&language=' + language;
        head.insertBefore(trustarcScript, head.firstChild);
    }

    setLanguage(language: string): string {
        const languageArr = [
            'es_mx',
            'pt_br',
            'vi_vn',
            'zh_cn',
            'ar_001',
            'ja_jp',
            'fr_ca',
            'zh_hant',
            'ms_my',
            'sv_se',
            'ko_kr',
            'es_mx',
            'th_th',
            'hi_in'
        ];

        return languageArr.find((lang) => lang === language)
            ? language.substr(this.constants.ZERO, this.constants.SLICE_POSITIVE_NUM)
            : language.substr(this.constants.SLICE_NEGATIVE_NUM);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            const acceptBtn = document.getElementById('truste-consent-button');
            const declineAllBtn = document.getElementById('truste-consent-required');
            const manageSettingsBtn = document.getElementById('truste-show-consent');

            if (acceptBtn) {
                acceptBtn.addEventListener('keyup', (event) => {
                    this.onKeyDown(event);
                });
            }
            if (declineAllBtn) {
                declineAllBtn.addEventListener('keyup', (event) => {
                    this.onKeyDown(event);
                });
            }
            if (manageSettingsBtn) {
                manageSettingsBtn.addEventListener('click', () => {
                    const closeIframeBtn = document.getElementsByClassName('truste-close-button')[0] as HTMLElement;

                    closeIframeBtn.addEventListener('click', () => {
                        manageSettingsBtn?.focus();
                    });
                });
            }
        }, this.constants.ONE_THOUSAND);
    }

    onKeyDown(event: KeyboardEvent): void {
        if (event.code === 'Space' || event.code === 'Enter') {
            setTimeout(() => {
                const mainContentBtn = document.querySelector<HTMLElement>('#skip-to-main-content-btn');
                if (mainContentBtn) {
                    mainContentBtn.focus();
                }
            }, this.constants.ONE_THOUSAND);
        }
    }
}
