<vpg-container>
    <vpg-card-next-header>
        <vpg-card-next-title>{{ translations.RECENT_CLAIMS | translate }}</vpg-card-next-title>
    </vpg-card-next-header>

    <div class="flex-wrap flex gap-20 mt-lg">
        <ng-content></ng-content>
    </div>
    <a
        vpg-button
        variant="secondary"
        id="view-all-claims"
        size="medium"
        href="#/benefits/claims"
        class="mt-lg"
        (click)="sendAnalytics()"
        [attr.aria-label]="(translations.VIEW_ALL_CLAIMS | translate)"
    >
        {{ translations.VIEW_ALL | translate }}
    </a>
</vpg-container>
