import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, ReplaySubject } from 'rxjs';
import { mergeMap, map, first, take } from 'rxjs/operators';

import { MemberClass, Feature, Member, MemberProduct, LanguageModel, Country, MemberSettings, Sponsor, SponsorSetting, Timezone } from '../models';

export interface IMemberResponse {
    member: Member,
    memberClass: MemberClass,
    memberCountry: Country,
    memberSettings: MemberSettings,
    sponsor: Sponsor,
    sponsorSettings: SponsorSetting,
    theme: any,
    timezones: Timezone[],
    languages: LanguageModel[],
    timezone: Timezone,
    memberProducts: MemberProduct[],
    memberFeatures: Feature[],
    memberFeaturesToggle: Feature[],
}


@Injectable({
    providedIn: 'root'
})
export class MemberResponseRef {
    private memberResponse = new ReplaySubject<IMemberResponse>();

    setMemberResponse(response: IMemberResponse) {
        this.memberResponse.next(response);
    }

    once() {
        return this.memberResponse.asObservable().pipe(first());
    }

    ready(handler: (memberResponse: IMemberResponse)=> void) {
        this.memberResponse.pipe(first()).subscribe(handler);
    }
}

@Injectable({
    providedIn: 'root'
})
export class MemberService {

    private memberCountrySubject = new ReplaySubject<Country | null>(1);

    constructor(
        private httpClient: HttpClient,
        private memberResponse: MemberResponseRef
    ) { }

    authenticate() {

        return this.httpClient.get<any>('/api/members/authenticated').pipe(
            mergeMap((authenticatedMember: any) => {

                const member = authenticatedMember;
                const memberClass = Object.assign(new MemberClass(), authenticatedMember);

                return forkJoin({
                    memberCountry: this.getMemberCountry(member.id).pipe(take(1)),
                    memberSettings: this.getMemberSettings(member.id),
                    sponsor: this.getMemberSponsor(member.id),
                    sponsorSettings: this.getSponsorSettings(member.sponsorId),
                    theme: this.getMemberTheme(member.id),
                    timezones: this.getTimezoneList(),
                    memberProducts: this.getMemberProducts(member.id),
                    memberFeatures: this.getMemberFeatures(member.id),
                    memberFeaturesToggle: this.callMemberFeatureToggles(member.id),
                    languages: this.getLanguageList()
                }).pipe(
                    map((results: any) => {

                        const timezone = results.timezones.find((timezone: any) => timezone.timezoneId === member.timezoneId);

                        const response: IMemberResponse = {
                            member: member,
                            memberClass: memberClass,
                            memberCountry: results.memberCountry,
                            memberSettings: results.memberSettings,
                            sponsor: results.sponsor,
                            sponsorSettings: results.sponsorSettings,
                            theme: results.theme,
                            timezones: results.timezones,
                            languages: results.languages,
                            timezone: timezone,
                            memberProducts: results.memberProducts,
                            memberFeatures: results.memberFeatures,
                            memberFeaturesToggle: results.memberFeaturesToggle
                        };

                        this.memberResponse.setMemberResponse(response);

                        return response;
                    })
                );
            })
        );
    }

    private getMemberCountry(memberId: number) {
        this.httpClient.get<any>(`/api/members/${memberId}/country`).subscribe((country) => {
            this.memberCountrySubject.next(country);
        }, () => {
            this.memberCountrySubject.next(null);
        });

        return this.memberCountrySubject;
    }

    private callMemberFeatureToggles(memberId: any) {
        return this.httpClient.get<any[]>(`/api/members/${memberId}/feature-toggles`);
    }

    private getMemberSettings(memberId: number) {
        return this.httpClient.get<any>(`/api/members/${memberId}/settings`);
    }

    private getMemberSponsor(memberId: number) {
        return this.httpClient.get<any>(`/api/members/${memberId}/sponsor`);
    }

    private getSponsorSettings(sponsorId: number) {
        return this.httpClient.get<any>(`/api/sponsors/${sponsorId}/settings`);
    }

    private getMemberTheme(memberId: number) {
        return this.httpClient.get<any>(`/api/members/${memberId}/theme`);
    }

    private getTimezoneList() {
        return this.httpClient.get<any[]>('/api/timezones');
    }

    private getMemberProducts(memberId: number) {
        return this.httpClient.get<any[]>(`/api/members/${memberId}/products`);
    }

    private getMemberFeatures(memberId: number) {
        return this.httpClient.get<Feature[]>(`/api/members/${memberId}/features`);
    }

    private getLanguageList() {
        return this.httpClient.get('/api/languages');
    }
}
