import { inject, Injectable } from '@angular/core';

import { EventBus, Events } from '../core';

@Injectable()
export class ShellRouter {

    static Token = 'ShellRouter';

    private eventBus: EventBus<Events> = inject(EventBus);

    navigate(path: string, params?: Record<string, any>) {

        this.eventBus.dispatch('navigate', { path, params });
    }
}
