import { Injectable } from '@angular/core';
import { GlobalStore, IGlobalStore } from 'redux-micro-frontend';
import { Subject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { genesisAdminStateActions } from './genesis-admin-state/store.action';
import { genesisAdminStateReducer } from './genesis-admin-state/store.reducer';
import { genesisClientAdminStateActions } from './genesis-client-admin-state/store.action';
import { genesisClientAdminStateReducer } from './genesis-client-admin-state/store.reducer';
import { genesisMemberStateActions } from './genesis-ui-state/store.action';
import { genesisMemberStateReducer } from './genesis-ui-state/store.reducer';
import { StoreNames } from './global-stores.enum';

/**
 * @deprecated Reduce use of redux store as the future for this class is not bright
 */
@Injectable()
export class GlobalReduxStore {
    // @ts-ignore
    globalStore: IGlobalStore;
    storeName = '';

    globalAppStore$: Observable<any>;
    private globalAppStoreSubject$ = new Subject();

    constructor() {
        this.globalAppStore$ = this.globalAppStoreSubject$.asObservable();
    }

    // Method used inside Shell apps (genesis-admin, genesis-ui) for creating GlobalStore
    initReduxStore(storeName: string, isDebugModeActive: boolean) {
        this.storeName = storeName;
        this.globalStore = GlobalStore.Get(isDebugModeActive);
        // @ts-ignore
        this.globalStore.CreateStore(storeName, this.getStateReducer(), []);
        // @ts-ignore
        this.globalStore.RegisterGlobalActions(storeName, this.getStateActions());
        this.subscribeToGlobalReduxStore();
    }

    // @ts-ignore
    getStateReducer() {
        switch (this.storeName) {
        case StoreNames.GENESIS_ADMIN: {
            return genesisAdminStateReducer;
        }

        case StoreNames.GENESIS_UI: {
            return genesisMemberStateReducer;
        }

        case StoreNames.GENESIS_CLIENT_ADMIN: {
            return genesisClientAdminStateReducer;
        }
        }
    }

    // @ts-ignore
    getStateActions() {
        switch (this.storeName) {
        case StoreNames.GENESIS_ADMIN: {
            return genesisAdminStateActions;
        }

        case StoreNames.GENESIS_UI: {
            return genesisMemberStateActions;
        }

        case StoreNames.GENESIS_CLIENT_ADMIN: {
            return genesisClientAdminStateActions;
        }
        }
    }

    // Method used inside micro-frontend apps (Homepage-ui, Rewards-ui, Benefits-ui...) for geting instance of GlobalStore
    initMFReduxStore(storeName: string, isDebugModeActive: boolean) {
        this.storeName = storeName;
        this.globalStore = GlobalStore.Get(isDebugModeActive);
        this.subscribeToGlobalReduxStore();
    }

    createSelector(stateProperty: string | number) {
        this.validateStateProperty(stateProperty);

        return this.globalAppStore$
            .pipe(
                distinctUntilChanged((prev, curr) => prev[stateProperty] === curr[stateProperty]),
                map((state) => state[stateProperty])
            );
    }

    dispatchAction(action: any) {
        this.globalStore.DispatchAction(this.storeName, action);
    }

    getInitialState(property = '') {
        const initialState = this.globalStore.GetGlobalState();
        const state = initialState[this.storeName];

        if (!!property && Object.prototype.hasOwnProperty.call(state, property)) {
            return state[property];
        }

        return initialState[this.storeName];
    }

    private subscribeToGlobalReduxStore() {
        this.globalStore.Subscribe(this.storeName, (event) => this.globalAppStoreSubscription(event));
    }

    // @ts-ignore
    private globalAppStoreSubscription(globalAppState) {
        this.globalAppStoreSubject$.next(globalAppState);
    }

    // @ts-ignore
    private validateStateProperty(stateProperty) {
        const state = this.getInitialState();
        const isPropertyValid = Object.prototype.hasOwnProperty.call(state, stateProperty);
        if (isPropertyValid) {
            return;
        }
        const errorMessage = `State property '${ stateProperty }' is not defined in '${ this.storeName }' GlobalState.`;
        throw new Error(errorMessage);
    }
}
