<h2
    id="coaching-error-title"
    aria-hidden="true"
    class="error-message-title h4">
    {{ errorTitle | translate }}
</h2>
<div
    id="coaching-error-message"
    aria-hidden="true"
    class="error-message-text-wrapper body-regular mt-xs"
>
    {{ errorMessage | translate }}
</div>
<div class="sr-only" aria-labelledby="coaching-error-title coaching-error-message"></div>