import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';

import { PersonalChallengeConfirmComponent } from './personal-challenge-confirm/personal-challenge-confirm.component';
import { PersonalChallengeCreateModalComponent } from './personal-challenge-create-modal.component';
import { PersonalChallengeRulesTemplateComponent } from './personal-challenge-rules-template/personal-challenge-rules-template.component';
import { GenesisPipesModule } from '@genesis-frontend/genesis-utilities';


@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        FormsModule,
        GenesisPipesModule,
        ModalModule.forRoot()
    ],
    declarations: [
        PersonalChallengeCreateModalComponent,
        PersonalChallengeRulesTemplateComponent,
        PersonalChallengeConfirmComponent
    ],
    providers: [
        BsModalService
    ],
    exports: [
        PersonalChallengeCreateModalComponent,
        PersonalChallengeRulesTemplateComponent,
        PersonalChallengeConfirmComponent
    ]
})
export class PersonalChallengeCreateModalModule { }
