import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';

import { BrightcoveMediaPlayer } from './brightcove-media-player.component';
import {
    AnalyticsTrackingCommandsService, MediaService

} from '@genesis-frontend/genesis-utilities';


@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        BrowserModule,
        ModalModule.forRoot()
    ],
    declarations: [
        BrightcoveMediaPlayer
    ],
    exports: [
        BrightcoveMediaPlayer

    ],
    providers: [
        MediaService,
        AnalyticsTrackingCommandsService
    ]
})
export class BrightcoveMediaPlayerModule { }
