export enum ThemeSettingType {
    BackgroundInfo = 'BackgroundInfo',
    BackgroundPrimary = 'BackgroundPrimary',
    Badge = 'Badge',
    ButtonInfo = 'ButtonInfo',
    ButtonPrimary = 'ButtonPrimary',
    HighlightInfo = 'HighlightInfo',
    HighlightPrimary = 'HighlightPrimary',
    MobileHeader = 'MobileHeader',
    NavHighlight = 'NavHighlight',
    ProgressBarInfo = 'ProgressBarInfo',
    ProgressBarRewards = 'ProgressBarRewards',
    ProgressRingPrimary = 'ProgressRingPrimary',
    ProgressRingInfo = 'ProgressRingInfo',
    Ribbon = 'Ribbon',
    TextLink = 'TextLink',
    TextPrimary = 'TextPrimary',
    TrimInfo = 'TrimInfo',
    TrimPrimary = 'TrimPrimary',
    BodyBackground = 'BodyBackground',
    RewardsBarSecondary = 'RewardsBarSecondary'
}
