import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    ApplicationCache,
    AuthenticatedMemberService,
    Member,
    MemberAgreement
} from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class MemberAgreementService {
    member: Member;

    constructor(
        private readonly cache: ApplicationCache,
        private httpClient: HttpClient,
        private authenticatedMemberService: AuthenticatedMemberService
    ) {
        this.member = this.authenticatedMemberService.getAuthenticatedMember();
    }

    getMemberAgreements(): Observable<MemberAgreement[]> {
        const memberId = this.member.id;
        const cacheKey = `MemberAgreementService.getMemberAgreements.${memberId}`;
        return this.cache.getOrFetch(cacheKey, () => {
            return this.httpClient.get<MemberAgreement[]>(`/agreements-api/members/${memberId}/agreements`);
        });
    }
}
