import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

// time in milliseconds
export enum IdleUserTimes {
    IdleTimeLoggedIn = 840000,
    IdleTimeLoggedOutOrMobile = 3540000,
    CountdownTime = 60000
}

@Injectable({
    providedIn: 'root'
})
export class IdleUserService {
    private timeoutId: any;
    private countdownId: any;
    private countdownValue: any;
    private readonly idleTime: any;

    userInactive: Subject<boolean> = new Subject();

    constructor() {
        this.idleTime = this.determineIdleTime();
        this.reset();
        this.initListener();
    }

    initListener() {
        window.addEventListener('mousemove', () => this.reset());
        window.addEventListener('click', () => this.reset());
        window.addEventListener('keypress', () => this.reset());
        window.addEventListener('DOMMouseScroll', () => this.reset());
        window.addEventListener('mousewheel', () => this.reset());
        window.addEventListener('touchmove', () => this.reset());
        window.addEventListener('MSPointerMove', () => this.reset());
    }

    reset() {
        clearTimeout(this.timeoutId);
        clearTimeout(this.countdownId);
        this.startIdleTimer();
    }

    startIdleTimer() {
        this.timeoutId = setTimeout(() => {
            this.startCountdown();
        }, this.idleTime);
    }

    startCountdown() {
        this.countdownValue = IdleUserTimes.CountdownTime / 1000;
        this.countdownId = setInterval(() => {
            this.countdownValue--;
            if (this.countdownValue <= 0) {
                clearInterval(this.countdownId);
                this.userInactive.next(true);
            }
        }, 1000);
    }

    private determineIdleTime(): number {
        const isMobile = navigator.userAgent.includes('Personify Health');

        let cognigyProfile;
        try {
            const storedProfile = localStorage.getItem('COGNIGY_PROFILE');
            cognigyProfile = storedProfile ? JSON.parse(storedProfile) : null;
        } catch (error) {
            cognigyProfile = null;
        }

        const isCognigyProfileMissingOrEmpty = !cognigyProfile || Object.keys(cognigyProfile).length === 0;
        return (isMobile || isCognigyProfileMissingOrEmpty)
            ? IdleUserTimes.IdleTimeLoggedOutOrMobile
            : IdleUserTimes.IdleTimeLoggedIn;
    }
}
