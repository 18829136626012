import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { AgreementModalComponent } from './agreement-modal.component';
import { MemberAgreement } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class AgreementModalService {

    constructor(private modalService: BsModalService) {}

    openModal(agreement: MemberAgreement): Observable<BsModalService> {
        this.modalService.show(AgreementModalComponent, {
            class: 'modal-md',
            backdrop: true,
            keyboard: false,
            animated: false,
            initialState: {
                agreement
            }
        });
        return this.modalService.onHide;
    }
}
