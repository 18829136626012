import { Directive, ElementRef } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Directive({
    selector: '[vpDir]'
})
export class RtlLanguagesDirective {
    private currentLanguage = '';
    private rtlLanguages = ['ar-001'];

    constructor(private element: ElementRef, private cookieService: CookieService) {
        this.currentLanguage = this.cookieService.get('NG_TRANSLATE_LANG_KEY');

        if (this.rtlLanguages.includes(this.currentLanguage)) {
            this.element.nativeElement.dir = 'rtl';
            return;
        }
        this.element.nativeElement.dir = 'ltr';
        return;
    }
}
