import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NextBestNudgeResponse } from '../models/interfaces/next-best-nudge-response.model';
import { RecommendationInteraction } from '../models/interfaces/recommendation-interaction.model';

@Injectable({
    providedIn: 'root'
})
export class RecommendationService {

    constructor(private httpClient: HttpClient) { }

    getRecommendations(memberId: number) {
        return this.httpClient.get<NextBestNudgeResponse>(`/api/members/${memberId}/recommendations`);
    }

    addRecommendationInteraction(
        memberId: number,
        interaction: RecommendationInteraction
    ): Observable<RecommendationInteraction> {
        const interactionsUrl = `/api/members/${memberId}/recommendations/interactions`;
        return this.httpClient.post<RecommendationInteraction>(interactionsUrl, interaction);
    }

}
