import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostBinding, Injectable, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DialogService, VpgrooveModule } from '@engineering/vpgroove-angular';

export interface SweetAlertOptions {
    title?: string;
    text?: string;
    icon?: string;
    showCancelButton?: boolean;
    showConfirmButton?: boolean;
    cancelButtonText?: string;
    confirmButtonText?: string;
    confirmButtonAriaLabel?: string
    customClass: {
        confirmButton?: string,
        cancelButton?: string
    },
    onOpen?: () => void;
}

@Component({
    template: `
        <vpg-dialog-header hidden closeButtonAriaLabel="Close modal" [attr.id]="'fs-dialog-header'">
        </vpg-dialog-header>
        <vpg-dialog-body class="align-text-center sweet-wrapper">
            <div class="icon-wrapper padding-40">
                <vpg-icon-next
                    *ngIf="options?.icon === 'warning'"
                    icon="fas fa-exclamation-circle"
                    class="warning-icon"
                    size="5x"
                    screenReaderText="Warning icon"
                ></vpg-icon-next>
                <vpg-icon-next
                    *ngIf="options?.icon === 'error'"
                    icon="far fa-times-circle"
                    class="error-icon"
                    size="5x"
                    screenReaderText="Error icon"
                ></vpg-icon-next>
                <vpg-icon-next
                    *ngIf="options?.icon === 'success'"
                    icon="far fa-check-circle"
                    class="success-icon"
                    size="5x"
                    screenReaderText="Error icon"
                ></vpg-icon-next>
            </div>
            <div class="title-wrapper padding-10">
                <h2 class="sweet-wrapper-title"> {{ options?.title }} </h2>
            </div>
            <p class="body-large m-none mb-md padding-10">
                {{ options?.text }}
            </p>
        </vpg-dialog-body>
        <vpg-dialog-actions>
            <button
                [class]="options?.customClass?.confirmButton"
                id="vpg-sweet-wrapper-confirm-button"
                vpg-button
                variant="primary"
                size="medium"
                (click)="confirm()"
            >
                {{ options?.confirmButtonText }}
            </button>
            <button
                [class]="options?.customClass?.cancelButton"
                *ngIf="options?.showCancelButton"
                vpg-button
                variant="secondary"
                size="medium"
                (click)="close()"
            >
               {{ options?.cancelButtonText }}
            </button>
        </vpg-dialog-actions>
    `,
    styles: [
        '.align-text-center { text-align: center !important; }',
        '.sweet-wrapper .sweet-wrapper-title { font-family: "Open Sans", sans-serif; color: #2d3e47; font-size: 1.875em; font-weight: 600; margin: 0 !important; }',
        '.padding-10 { padding: 10px;}',
        '.padding-40 { padding: 40px; }',
        '.warning-icon { border-color: #facea8; color: #f8bb86; }',
        '.error-icon { border-color: #facea8; color: #f27474; }',
        '.success-icon { border-color: #a5dc86; color: #a5dc86; }'
    ],
    imports: [VpgrooveModule, CommonModule],
    standalone: true
})
export class SweetAlertWrapperDialog {
    @Input() options: SweetAlertOptions | undefined;

    @Output() closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();

    randomId = Math.random().toString(36).substring(2, 15);

    @HostBinding('attr.class') class = 'vpg-wrapper-dialog-id-' + this.randomId;

    confirm() {
        this.closeDialog.emit(true);
    }

    close() {
        this.closeDialog.emit(false);
    }

    ngAfterViewInit() {
        const dialog = document.querySelector(`.vpg-wrapper-dialog-id-${this.randomId}`)?.parentElement as HTMLElement;
        if (dialog) {
            dialog.style.width = '500px';

            const confirm = document.getElementById('vpg-sweet-wrapper-confirm-button') as HTMLElement;
            setTimeout(() => {
                confirm.focus();
            });
        }

        if (this.options?.onOpen) {
            this.options?.onOpen();
        }
    }
}

@Injectable({
    providedIn: 'root'
})
export class SweetAlertWrapperService {

    constructor(private $translate: TranslateService, private dialogService: DialogService) { }

    showError(errorMessage: string, icon: string = 'error') {
        this.openDialog({
            title: this.$translate.instant('Oops'),
            text:  this.$translate.instant(errorMessage),
            icon: icon,
            confirmButtonText: this.$translate.instant('OkayButton'),
            customClass: {
                confirmButton: 'vp-button vp-button-primary'
            }
        });
    }

    showChallengeJoinMessage(challengeName: string) {
        this.openDialog({
            title: this.$translate.instant('Success'),
            text: this.$translate.instant('SuccessfullyJoinedChallenge', { challengeName }),
            icon: 'success',
            confirmButtonText: 'OK',
            customClass: {
                confirmButton: 'vp-button vp-button-primary'
            }
        });
    }

    replaceWithH1Tag() {
        const titleElement = document.querySelector('.sweet-wrapper-title');
        const titleWithH1Tag = document.createElement('h1');
        titleWithH1Tag.setAttribute('class', titleElement?.getAttribute('class') ?? '');
        titleWithH1Tag.innerText = titleElement?.innerHTML ?? '';
        titleWithH1Tag.style.margin = '0';
        return titleElement?.replaceWith(titleWithH1Tag);
    }

    showNotAbleToJoinChallengeModal() {
        this.openDialog({
            title: this.$translate.instant('Whoops'),
            text: this.$translate.instant('NotEligibleToJoinChallenge'),
            icon: 'warning',
            confirmButtonText: this.$translate.instant('OK'),
            customClass: {
                confirmButton: 'vp-button vp-button-primary swal-confirm-spacer'
            }
        });
    }

    show(options: any): Promise<{ value: boolean }> {
        options.dialogWidth = '500px';
        return this.openDialog(options);
    }

    openDialog(options: SweetAlertOptions): Promise<{ value: boolean }> {
        return new Promise((resolve) => {
            this.dialogService.open(SweetAlertWrapperDialog, {
                props: { options }
            }).subscribe((response) => {
                this.dialogService.confirm().close();
                resolve({ value: response });
            });
        });
    }

}
