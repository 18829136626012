import { Injectable } from '@angular/core';

interface PromiseQueueItem {
    function: any;
    arguments: any;
}

@Injectable({
    providedIn: 'root'
})
export class BlockerChainService {
    promiseQueue: PromiseQueueItem[] = [];

    addPromiseReturningFunction(promiseReturningFunction: any, promiseFunctionArguments: any = []) {
        this.promiseQueue.push({
            function: promiseReturningFunction,
            arguments: promiseFunctionArguments
        });
    }

    async executeChain() {
        for (const item of this.promiseQueue) {
            await item.function(...item.arguments);
        }
        return true;
    }
}
