import { Component, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'agreement-modal',
    templateUrl: './client-agreement-modal.component.html'
})

export class ClientAgreementModalComponent {

    @Input() agreement: any;
    constructor(private bsModalRef: BsModalRef) {}

    closeModal() {
        this.bsModalRef.hide();
    }
}
