import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as FileSaver from 'file-saver';

import { FormatHtmlContentService } from './format-html-content.service';
import { IcalConverterService } from './ical-converter.service';
import { DateUtils } from '../lib/date-utils';
import { SingleTopicType } from '../models/enums/single-topic-type.enum';
import { CalendarEvent } from '../models/interfaces/calendar-event.model';
import { CoachingAppointmentVM } from '../models/interfaces/coaching-appointment-vm.model';

@Injectable({
    providedIn: 'root'
})
export class CalendarService {
    iCalData: Blob | undefined;
    baseGoogleUrl = new URL('https://www.google.com/calendar/render');

    constructor(
        private translate: TranslateService,
        private iCalConverter: IcalConverterService,
        private formatHtmlContentService: FormatHtmlContentService
    ) {}

    buildCalendarEvent(appointment: CoachingAppointmentVM): CalendarEvent {
        const calendarEvent = {} as CalendarEvent;

        if (appointment.topicInternalName === SingleTopicType.NextStepsConsult) {
            calendarEvent.title = this.translate.instant('AppointmentCalendarNSCEventTitle');
            calendarEvent.description = this.translate.instant('AppointmentCalendarNSCEventDescription');
        } else {
            calendarEvent.title = this.translate.instant('AppointmentCalendarEventTitle');
            calendarEvent.description = this.translate.instant('AppointmentCalendarEventDescription');
        }

        calendarEvent.startDateTime = appointment.startTime.toString();
        calendarEvent.endDateTime = appointment.endTime.toString();

        return calendarEvent;
    }

    async exportEvent(event: CalendarEvent) {
        const cal = await this.iCalConverter.convertCalendarEventToIcal(event);
        this.iCalData = new Blob([cal], { type: 'text/calendar' });

        FileSaver.saveAs(this.iCalData, event.title + '.ics');
    }

    convertEventToGoogleCalendarUrl(calendarEvent: CalendarEvent): string {
        if (!calendarEvent) {
            return '';
        }

        const formattedDescription = this.formatHtmlContentService.separateLinks(calendarEvent.description);
        const tempParams: { action: string, text: string, dates: string, details: string, location?: string } = {
            action: 'TEMPLATE',
            text: calendarEvent.title,
            dates: this.extractGoogleCalendarFormatDates(calendarEvent),
            details: this.formatHtmlContentService.removeHtml(formattedDescription.innerHTML)
        };

        if (calendarEvent.location) {
            tempParams.location = calendarEvent.location;
        }

        (Object.keys(tempParams) as (keyof typeof tempParams)[]).forEach((key) => {
            this.baseGoogleUrl.searchParams.set(key, tempParams[key] ?? '');
        });

        return this.baseGoogleUrl.toString();
    }

    private extractGoogleCalendarFormatDates(event: CalendarEvent): string {
        return `${this.convertDateToGoogleCalendarFormat(event.startDateTime, event.allDayEvent)}
            /${this.convertDateToGoogleCalendarFormat(event.endDateTime, event.allDayEvent)}`;
    }

    private convertDateToGoogleCalendarFormat(date: string, isAllDayEvent: boolean): string {
        if (isAllDayEvent) {
            return DateUtils.format(date, 'YYYYMMDD');
        }
        return DateUtils.format(date, 'YYYYMMDDTHHmmss') + 'Z';
    }
}
