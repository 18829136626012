import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { defaults } from '../../lib/lodash';

@Injectable({
    providedIn: 'root'
})
export class SponsorRewardsService {
    DEFAULT_PAGE_SIZE = 25;

    constructor(
        private httpClient: HttpClient
    ) {}

    getSponsorRewardPopulations(sponsorId: number | string, options: any) {
        const params = defaults(options || {}, {
            pageSize: this.DEFAULT_PAGE_SIZE
        });

        params.sponsorId = sponsorId;
        return this.httpClient.get(`/api/client-admin/sponsors/${sponsorId}/rewards/populations`, {
            observe: 'response',
            params
        }).pipe(map((response: any) => ({
            populations: response.body,
            contentItems: response.headers.get('content-items'),
            currentPage: response.headers.get('current-page'),
            totalPage: response.headers.get('total-pages')
        })));
    }
}
