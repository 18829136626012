import { Inject, Injectable } from '@angular/core';

import {
    AuthenticatedMemberService,
    Environment,
    ENVIRONMENT,
    ZendeskService
} from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class ZendeskInitService {
    constructor(
        @Inject(ENVIRONMENT) private environment: Environment,
        private authenticatedMemberService: AuthenticatedMemberService,
        private zendeskService: ZendeskService
    ) { }

    init() {
        this.authenticateZendesk();
    }

    private setZendeskWidget() {
        const widgetSrc = this.environment.zendesk.widgetSrc;
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.id = 'ze-snippet';
        script.async = false;
        script.src = widgetSrc;
        document.getElementsByTagName('head')[0].appendChild(script);
        script.onload = function() {
            (window as any).zE('webWidget', 'hide');
            (window as any).zE('webWidget:on', 'close', function() {
                (window as any).zE('webWidget', 'hide');
            });
        };
    }

    private authenticateZendesk() {
        this.zendeskService.getChatToken().subscribe((response) => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.id = 'zendesk-auth';
            script.async = false;
            script.innerHTML = `window.zESettings = {
                  webWidget: {
                    authenticate: {
                      jwtFn: function(callback) {
                        callback('${response}');
                      }
                    }
                  }
                };`;
            document.getElementsByTagName('head')[0].appendChild(script);
            this.setZendeskWidget();
        });
    }
}
