import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticatedMemberService } from '../authentication';
import {
    Member,
    CareGeneralPreferencesModel,
    MedicalEventsSummaryModel
} from '../models';

@Injectable({
    providedIn: 'root'
})
export class HealthLandingPageService {
    member: Member;
    apiUrl = '/care-api';

    constructor(private authenticatedMemberService: AuthenticatedMemberService, private http: HttpClient) {
        this.member = this.authenticatedMemberService.getAuthenticatedMember();
    }

    getMemberFeatures(): Observable<any[]> {
        return this.http.get<any>(`/api/members/${this.member.id}/features`);
    }

    getJourneysData(): Observable<any> {
        return this.http
            .get<any>(`/journeys-api/sponsors/${this.member.sponsorId}/members/${this.member.id}/journeys`)
            .pipe(catchError(() => of(false)));
    }

    getAdditionalJourneys(): Observable<any> {
        return this.http.get<any>(`/journeys-api/sponsors/${this.member.sponsorId}/members/${this.member.id}/journeys/browse`);
    }

    getRestartableJourneys(): Observable<any> {
        return this.http.get<any>(`/journeys-api/sponsors/${this.member.sponsorId}/members/${this.member.id}/journeys/restartable`);
    }

    getSurveyData(): Observable<any> {
        return this.http
            .get<any>(`/api/members/${this.member.id}/program-pages/surveys`)
            .pipe(catchError(() => of([])));
    }

    getHraProvider(): Observable<any> {
        return this.http.get<any>(`/api/members/${this.member.id}/HRA/provider`).pipe(catchError(() => of({ sourceId: -1 })));
    }

    checkIfMemberHasHRA(): Observable<any> {
        return this.http
            .get<any>(`/api/members/${this.member.id}/HRA/`)
            .pipe(catchError(() => of({ enabled: false })));
    }

    checkHasPreferredNameSetting() {
        return this.member.sponsor.settings.hraPreferredName;
    }

    getMyCareChecklistGeneralPreferences(): Observable<CareGeneralPreferencesModel> {
        return this.http.get<CareGeneralPreferencesModel>(`${ this.apiUrl }/members/${ this.member.id }/general-preferences`).pipe(catchError(this.handleError()));
    }

    postMyCareChecklistGeneralPreferences(careGeneralPreferences: CareGeneralPreferencesModel): Observable<CareGeneralPreferencesModel> {
        return this.http.post<CareGeneralPreferencesModel>(`${ this.apiUrl }/members/${ this.member.id }/general-preferences`, careGeneralPreferences);
    }

    updateMyCareChecklistGeneralPreferences(careGeneralPreferences: CareGeneralPreferencesModel): Observable<CareGeneralPreferencesModel> {
        return this.http.put<CareGeneralPreferencesModel>(`${ this.apiUrl }/members/${ this.member.id }/general-preferences`, careGeneralPreferences);
    }

    getMyCareChecklistMedicalEventsSummary(): Observable<MedicalEventsSummaryModel> {
        return this.http.get<MedicalEventsSummaryModel>(`care-api/members/${this.member.id}/medical-events/summary?language=${this.member.language}`).pipe(catchError(this.handleError()));
    }

    getMemberCoachingProfile() : Observable<any> {
        return this.http.get<any>(`/api/live-services-coaching/members/${this.member.id}/coaching-profile`)
            .pipe(catchError(() => of({})));
    }

    getMemberCoachingAppointments() : Observable<any> {
        let params = new HttpParams();
        params = params.append('packageNameList', 'TotalPopulationHealth');
        params = params.append('packageNameList', 'LifestyleManagement');
        params = params.append('packageNameList', 'ConditionManagement');
        params = params.append('packageNameList', 'MentalHealthCoaching');
        return this.http.get<any>(`/live-services-api/live-services-coaching/members/${this.member.id}/all-appointments`, { params })
            .pipe(catchError(() => of(false)));
    }

    getEngagementStatusForMember(): Observable<any> {
        return this.http.get<any>(`/api/live-services-coaching/members/${this.member.id}/engagement`);
    }

    getMemberSurveyReadout(survey: any) : Observable<any> {
        let params = new HttpParams().set('surveyType', '');
        if (survey.surveyType) {
            params = params.set('surveyType', survey.surveyType);
        }
        return this.http.get<any>(`/api/members/${this.member.id}/surveys/${survey.scheduledSurveyId}/readout`, { params })
            .pipe(catchError(() => of({})));
    }

    hasLiveServicesCoachingSponsor(): boolean {
        const sponsorSettings = this.member.sponsor.settings;
        return sponsorSettings.hasLiveServicesCoaching;
    }

    getNextStepsConsultStepsAppointment(): Observable<any> {
        let params = new HttpParams();
        params = params.append('packageNameList', 'NextStepsConsult');
        return this.http.get<any>(`/live-services-api/live-services-coaching/members/${this.member.id}/all-appointments`, { params })
            .pipe(catchError(() => of(false)));
    }

    upsertMember(): Observable<any> {
        return this.http.put<any>(`/api/live-services-coaching/members/${this.member.id}/account`, null);
    }

    handleError() {
        return (err: any) => {
            return of(err);
        };
    }
}
