import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SystemContentService {

    isWidget: any;

    constructor(private httpClient: HttpClient) {}

    updateSystemContentStatus(sponsorId: number, systemContentId: number, systemContent: any) {
        if (this.isWidget) {
            return this.updatePillarTopicWidgetStatus(sponsorId, systemContentId, systemContent);
        }

        return this.updatePillarTopicTrackerStatus(sponsorId, systemContentId, systemContent);
    }

    getSponsorSystemContent(params: any) {
        const copiedParams = params;
        copiedParams.page = copiedParams.page - 1;
        return this.httpClient.get(`/api/client-admin/sponsors/${params.sponsorId}/system-content`, { params: copiedParams, observe: 'response' });
    }

    getSponsorTopics(sponsorId: number) {
        return this.httpClient.get(`/api/client-admin/sponsors/${sponsorId}/topics`);
    }

    updatePillarTopicWidgetStatus(sponsorId: number, widgetId: number, pillarTopicWidget: any) {
        return this.httpClient.put(`/api/client-admin/sponsors/${sponsorId}/topics/${pillarTopicWidget.topicId}/widgets/${widgetId}`, pillarTopicWidget);
    }

    updatePillarTopicTrackerStatus(sponsorId: number, trackerId: number, pillarTopicTracker: any) {
        return this.httpClient.put(`/api/client-admin/sponsors/${sponsorId}/topics/${pillarTopicTracker.topicId}/trackers/${trackerId}`, pillarTopicTracker);
    }

    getWidgetDetails(widgetId: number) {
        return this.httpClient.get(`/api/client-admin/widgets/${widgetId}`);
    }

    getWidgetTopics(widgetId: number, sponsorId: number) {
        return this.httpClient.get(`/api/client-admin/sponsors/${sponsorId}/widgets/${widgetId}/topics`);
    }

    getTrackerDetails(trackerId: number) {
        return this.httpClient.get(`/api/client-admin/trackers/${trackerId}`);
    }

    getTrackerTopics(trackerId: number, sponsorId: number) {
        return this.httpClient.get(`/api/client-admin/sponsors/${sponsorId}/trackers/${trackerId}/topics`);
    }

    getSponsorSystemContentCount(sponsorId: number) {
        return this.httpClient.get(`/api/client-admin/sponsors/${sponsorId}/system-content/count`);
    }

    init(sponsorId: number, systemContentId: number, isWidget: boolean) {
        this.isWidget = isWidget;
        forkJoin({
            systemContent: this.isWidget ? this.getWidgetDetails(systemContentId) : this.getTrackerDetails(systemContentId),
            topics: this.isWidget ? this.getWidgetTopics(systemContentId, sponsorId) : this.getTrackerTopics(systemContentId, sponsorId),
            isWidget: this.isWidget
        }).subscribe((response) => {
            return response;
        });
    }

    getSystemContentStatus() {
        return [{
            name: 'On',
            value: 'On'
        }, {
            name: 'Off',
            value: 'Off'
        }, {
            name: 'Needs Approval',
            value: 'NeedsApproval'
        }];
    }

    getSystemContentType() {
        return [{
            name: 'Habits',
            value: 'Habit'
        }, {
            name: 'Cards',
            value: 'Card'
        }];
    }

    initDefaultFilterParams(sponsorId: number) {
        return {
            sponsorId: sponsorId,
            page: 1,
            pageSize: 20,
            pillarTopicIds: [],
            statuses: [],
            contentTypes: [],
            search: ''
        };
    }

    deselectItems(items: any) {
        items.forEach((value: any) => {
            if (value.selected) {
                value.selected = false;
            }
        });
    }
}
