import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import {
    AuthenticatedMemberService,
    Language,
    UnitOfMeasurement,
    StatType,
    ActionType,
    LandingPageAnalyticsTrackingService,
    StatSettingsModel,
    StackedGraphStatDataModel,
    StatsSettingsService,
    GraphTemplates,
    MultiQuantityValues,
    StatGraphConfigService,
    StatsService,
    AmChartsService,
    ActivityModel,
    StatGoalSettings,
    StatSourceItem,
    StatFilterSource,
    TransformActivityTabId,
    DateUtils
} from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'stat-graph',
    templateUrl: './stat-graph.component.html',
    styleUrls: ['./stat-graph.component.scss']
})
export class StatGraphComponent implements OnInit, OnChanges {
    STATS_WITH_STACKED_GRAPH = [StatType.Workout as string];
    readonly DAILY_HIGHEST = this.translateService.instant('DailyHighest');

    @Input() statsStartDate: Date = DateUtils.sub(this.authenticatedMemberService.getTimeForMemberNow(), 7, 'days');
    @Input() statsEndDate: Date = this.authenticatedMemberService.getTimeForMemberNow();
    @Input() statTypeTrackerIdMap: Map<string, number> = new Map<string, number>();
    @Input() currentStat?: StatSettingsModel;
    @Input() isStatsPage = false;
    @Input() transformTabId = '';

    @Output() outputGoalValueEvent = new EventEmitter<StatGoalSettings>();

    memberLanguage: Language | undefined;
    routeType = '';
    statType?: string;
    dailyHighest = false;
    mostRecentlyReceived = false;
    isYearView = false;
    filterSource: StatFilterSource;
    defaultFilterSourceOption: StatSourceItem = { text: '' };

    chart: any = undefined;
    graphData: any = undefined;
    graphConfig: any = undefined;
    yAxisTitle = '';
    xAxisFormat = '';
    xAxisSpacing = 0;
    tooltipValue = '';
    graphTemplate = '';
    goalValue = 0;
    legendDisabled = true;
    isEmptyGraph = false;

    chartAdditional: any = undefined;
    graphDataAdditional: any = undefined;
    graphConfigAdditional: any = undefined;
    yAxisTitleAdditional = '';
    xAxisFormatAdditional = '';
    xAxisSpacingAdditional = 0;
    tooltipValueAdditional = '';
    graphTemplateAdditional = '';
    goalValueAdditional = 0;
    legendAdditionalDisabled = true;
    isAdditionalGraphEmpty = true;
    showAdditionalGraph = false;

    graphStatistics: ActivityModel[] = [];
    graphLoaded = false;
    graphInitiallyLoaded = false;
    graphLanguage = '';

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private amChartsService: AmChartsService,
        private statsService: StatsService,
        private route: ActivatedRoute,
        private statsSettingsService: StatsSettingsService,
        private statGraphConfigService: StatGraphConfigService,
        private landingPageAnalyticsTrackingService: LandingPageAnalyticsTrackingService,
        private translateService: TranslateService
    ) {
        this.filterSource = {
            enabled: false,
            options: [this.defaultFilterSourceOption],
            selected: this.defaultFilterSourceOption.text
        };
    }

    ngOnInit(): void {
        this.memberLanguage = this.authenticatedMemberService.getAuthenticatedMember().language;
        this.initRouteType();
        this.setGraphLabels();
        this.initGraph();
        this.graphInitiallyLoaded = true;
    }

    ngOnChanges() {
        if (this.graphInitiallyLoaded) {
            this.refreshGraph();
        }
    }

    refreshGraph(): void {
        this.graphLoaded = false;
        this.initRouteType();
        this.setGraphLabels();

        if (this.transformTabId) {
            this.initGraph();
            return;
        }

        this.initConfigSettings();
        this.initStatGraph();
    }

    private setGraphLabels() {
        if (DateUtils.diff(this.statsEndDate, this.statsStartDate, 'days') === 29) {
            this.xAxisFormat = 'M/D';
            this.xAxisSpacing = 60;
            this.xAxisFormatAdditional = 'M/D';
            this.xAxisSpacingAdditional = 60;
            this.isYearView = false;
        }
        if (DateUtils.diff(this.statsEndDate, this.statsStartDate, 'days') === 6) {
            this.xAxisFormat = 'dd';
            this.xAxisSpacing = 30;
            this.xAxisFormatAdditional = 'dd';
            this.xAxisSpacingAdditional = 30;
            this.isYearView = false;
        }
        if (DateUtils.diff(this.statsEndDate, this.statsStartDate, 'days') === 364) {
            this.xAxisFormat = 'M/D/Y';
            this.xAxisSpacing = 30;
            this.xAxisFormatAdditional = 'M/D/Y';
            this.xAxisSpacingAdditional = 30;
            this.isYearView = true;
        }
    }

    private checkShowAdditionalGraph(activities: ActivityModel[]) {
        if (this.routeType === StatType.WaistHipSize) {
            return true;
        }
        if (this.routeType === StatType.Weight && this.isStatsPage) {
            return activities.some((activity) => activity.bmi);
        }
        return false;
    }

    private initGraph() {
        this.statsService.getStatisticTrackersForMember().subscribe((trackers) => {
            trackers.forEach((tracker) => {
                const statType = this.getStatType(tracker.action.actionType);
                if (statType !== undefined) {
                    this.statTypeTrackerIdMap.set(statType, tracker.id);
                }
            });

            if (this.statTypeTrackerIdMap.has(StatType.Weight)) {
                this.addAdditionalStats();
            }
            this.initConfigSettings();
            this.initStatGraph();
        });
    }

    private getStatType(actionType: string) {
        let retStatType = actionType as StatType;
        switch (actionType) {
        case ActionType.StateOfZen:
            retStatType = StatType.MindfulMinutes;
            break;
        case ActionType.ManuallyEnteredDurationActivity:
        case ActionType.DurationActivity:
            retStatType = StatType.Workout;
            break;
        }
        return retStatType;
    }

    private addAdditionalStats() {
        const weightTrackerId = this.statTypeTrackerIdMap.get(StatType.Weight);

        if (!weightTrackerId) {
            return;
        }
        this.statTypeTrackerIdMap.set(StatType.WaistHipSize, weightTrackerId);
        this.statTypeTrackerIdMap.set(StatType.BloodPressure, weightTrackerId);
        this.statTypeTrackerIdMap.set(StatType.Cholesterol, weightTrackerId);
        this.statTypeTrackerIdMap.set(StatType.Glucose, weightTrackerId);
        this.statTypeTrackerIdMap.set(StatType.A1C, weightTrackerId);
    }

    private initRouteType() {
        const routeType = this.route.snapshot.paramMap.get('type');
        this.routeType = routeType ? routeType : this.getRouteTypeFromTransformTabId();
    }

    private getRouteTypeFromTransformTabId(): string {
        switch (this.transformTabId) {
        case TransformActivityTabId.WEIGHT:
            return StatType.Weight;
        case TransformActivityTabId.ACTIVITY:
            return StatType.ActiveMinutes;
        case StatType.Workout:
            return StatType.Workout;
        case TransformActivityTabId.BLOOD_PRESSURE:
            return StatType.BloodPressure;
        default:
            return '';
        }
    }

    private initStatGraph() {
        switch (this.routeType) {
        case StatType.Steps:
            this.statsSettingsService.getStepsGoal().subscribe((stepSetting) => {
                this.goalValue = stepSetting.stepsGoal;
                this.outputGoalValueEvent.emit({
                    value: this.goalValue,
                    unit: this.tooltipValue,
                    canEdit: true
                });
                this.initStatGraphData();
            });
            break;
        case StatType.Sleep:
            this.statsSettingsService.getSleepGoal().subscribe(
                (sleepSetting) => {
                    this.goalValue = sleepSetting.sleepGoalSeconds / 3600;
                    const sleepGoalHoursFormated = this.statGraphConfigService.getHHMMFormatFromHours(this.goalValue);
                    this.outputGoalValueEvent.emit({
                        value: sleepGoalHoursFormated,
                        unit: this.tooltipValue,
                        canEdit: true
                    });
                    this.initStatGraphData();
                },
                () => {
                    this.goalValue = 7;
                    const sleepGoalHoursFormated = this.statGraphConfigService.getHHMMFormatFromHours(this.goalValue);
                    this.outputGoalValueEvent.emit({
                        value: sleepGoalHoursFormated,
                        unit: this.tooltipValue,
                        canEdit: true
                    });
                    this.initStatGraphData();
                }
            );
            break;
        case StatType.ActiveMinutes:
            this.goalValue = 30;
            this.outputGoalValueEvent.emit({
                value: this.goalValue,
                unit: this.tooltipValue,
                canEdit: false
            });
            this.initStatGraphData();
            break;
        default:
            this.goalValue = 0;
            this.outputGoalValueEvent.emit({
                value: 0,
                unit: '',
                canEdit: false
            });
            this.initStatGraphData();
        }
    }

    private initStatGraphData() {
        this.graphLoaded = true;

        if (!this.routeType) {
            return;
        }

        const trackerId = this.statTypeTrackerIdMap.get(this.routeType);

        if (!trackerId) {
            return;
        }

        this.graphLanguage = this.statGraphConfigService.getGraphLanguage(this.memberLanguage);
        this.setDailyHighestAndMRRValues();

        this.statsService.getMemberStatisticsForDateRangeByTrackerId(this.statsStartDate, this.statsEndDate, trackerId, this.dailyHighest, this.mostRecentlyReceived, this.statType).subscribe((trackerModel) => {
            this.graphStatistics = trackerModel.statistics;
            this.initFilterSource(trackerModel.statistics);
        });
    }

    private generateGraphData(activities: ActivityModel[]) {
        const dateActivityMap = new Map<string, ActivityModel[]>();
        activities.forEach((activity) => {
            if (this.getValueForStatType(activity) || (this.routeType === StatType.WaistHipSize && (activity.hipCircumference || activity.waistCircumference))) {
                const activityDate = activity.memberDate;
                if (dateActivityMap.has(activityDate)) {
                    dateActivityMap.get(activityDate)?.push(activity);
                } else {
                    dateActivityMap.set(activityDate, [activity]);
                }
            }
        });

        if (this.isStacked()) {
            this.generateStackedGraphData(dateActivityMap);
        } else {
            this.generateNonStackedGraphData(dateActivityMap);
        }
    }

    groupActivitiesByDescription(activityList: ActivityModel[]): Record<string, ActivityModel[]> {
        const groupedActivities: Record<string, ActivityModel[]> = {};

        activityList.forEach((activity: ActivityModel) => {
            const groupedActivitiesWithThisDescription = groupedActivities[activity.activityDescription];

            if (groupedActivitiesWithThisDescription === undefined) {
                groupedActivities[activity.activityDescription] = [activity];
            } else {
                groupedActivities[activity.activityDescription].push(activity);
            }
        });

        return groupedActivities;
    }

    generateStackedGraphData(dateActivityMap: Map<string, ActivityModel[]>) {
        const data = [];
        this.isEmptyGraph = true;
        this.isAdditionalGraphEmpty = true;
        const iteratorThreshold = DateUtils.diff(this.statsEndDate, this.statsStartDate, 'days');
        for (let i = 0; i <= iteratorThreshold; i++) {
            const activityDate = DateUtils.format(
                DateUtils.sub(this.statsEndDate, i, 'days'),
                'YYYY-MM-DD'
            );
            const activityList = dateActivityMap.get(activityDate);
            const fullDate = DateUtils.format(activityDate, 'LL');
            const day = DateUtils.format(activityDate, this.xAxisFormat);
            if (activityList) {
                this.isEmptyGraph = false;
                const builder: StackedGraphStatDataModel = {
                    day: day,
                    fullDate: fullDate,
                    activities: []
                };

                const activitiesByDescription = this.groupActivitiesByDescription(activityList);
                Object.keys(activitiesByDescription).forEach((description) => {
                    const activities = activitiesByDescription[description];
                    const deviceName = activities[0].deviceName && activities[0].deviceName !== 'Unknown' ? activities[0].deviceName : this.translateService.instant('SelfEntered');
                    const totalValue = activities.map((a) => this.getValueForStatType(a) as number).reduce((a, b) => a + b);
                    const flooredValue = Math.floor(totalValue);
                    builder.activities.push({
                        value: flooredValue,
                        description,
                        deviceName
                    });
                });

                data.push(builder);
            } else {
                data.push({
                    fullDate: fullDate,
                    day: day,
                    value: null,
                    device: 'Unknown',
                    description: 'None'
                });
            }
        }
        this.graphData = data.reverse();
    }

    private generateNonStackedGraphData(dateActivityMap: Map<string, ActivityModel[]>) {
        const data = [];
        const dataAdditional = [];
        this.isEmptyGraph = true;
        this.isAdditionalGraphEmpty = true;
        const iteratorThreshold = DateUtils.diff(this.statsEndDate, this.statsStartDate, 'days');
        for (let i = 0; i <= iteratorThreshold; i++) {
            const activityDate = DateUtils.format(
                DateUtils.sub(this.statsEndDate, i, 'days'),
                'YYYY-MM-DD'
            );
            const activityList = dateActivityMap.get(activityDate);
            const [device, value, valueAdditional] = this.getDeviceNameAndValueForStatOfDay(activityList);
            const fullDate = DateUtils.format(activityDate, 'LL');
            const day = DateUtils.format(activityDate, this.xAxisFormat);
            const dayAdditional = DateUtils.format(activityDate, this.xAxisFormatAdditional);
            this.isEmptyGraph = this.isEmptyGraph && this.checkIfEmptyValue(value);
            this.isAdditionalGraphEmpty = this.isAdditionalGraphEmpty && this.checkIfEmptyValue(valueAdditional);
            const emptyGraphFlag = this.isEmptyGraph && (i === iteratorThreshold);

            data.push(this.composeDataItem(fullDate, day, value, device, emptyGraphFlag));

            dataAdditional.push({
                fullDate: fullDate,
                day: dayAdditional,
                value: valueAdditional === 0 ? null : valueAdditional,
                device: device
            });
        }

        this.graphData = data.reverse();
        this.graphDataAdditional = dataAdditional.reverse();
    }

    private composeDataItem(fullDate: string, day: string, value: number | MultiQuantityValues | null, device: string, isGraphEmpty: boolean): any {
        const emptyGraphValue = -1;
        switch (this.routeType) {
        case StatType.BloodPressure:
            return {
                fullDate: fullDate,
                day: day,
                device: device,
                systolic: isGraphEmpty ? emptyGraphValue : (value as MultiQuantityValues)?.systolic,
                diastolic: isGraphEmpty ? emptyGraphValue : (value as MultiQuantityValues)?.diastolic
            };
        case StatType.Glucose:
            return {
                fullDate: fullDate,
                day: day,
                device: device,
                fasting: isGraphEmpty ? emptyGraphValue : (value as MultiQuantityValues)?.fasting,
                nonFasting: isGraphEmpty ? emptyGraphValue : (value as MultiQuantityValues)?.nonFasting
            };
        case StatType.Cholesterol:
            return {
                fullDate: fullDate,
                day: day,
                device: device,
                totalCholesterol: isGraphEmpty ? emptyGraphValue : (value as MultiQuantityValues)?.totalCholesterol,
                ldl: isGraphEmpty ? emptyGraphValue : (value as MultiQuantityValues)?.ldl,
                hdl: isGraphEmpty ? emptyGraphValue : (value as MultiQuantityValues)?.hdl,
                triglycerides: isGraphEmpty ? emptyGraphValue : (value as MultiQuantityValues)?.triglycerides
            };
        default:
            return {
                fullDate: fullDate,
                day: day,
                value: isGraphEmpty ? emptyGraphValue : value,
                device: device
            };
        }
    }

    private getDeviceNameAndValueForStatOfDay(activityList: ActivityModel[] | undefined): [string, number | MultiQuantityValues | null, number | null] {
        let valueAdditional = 0;

        if (!activityList || activityList.length === 0) {
            return ['Unknown', null, 0];
        }

        let activity: ActivityModel | undefined;

        if (this.routeType === StatType.WaistHipSize) {
            activity = activityList.find((activity) => activity.waistCircumference || activity.hipCircumference);
        } else {
            activity = activityList.find((activity) => this.getValueForStatType(activity) > 0 || !this.checkIfEmptyValue(this.getValueForStatType(activity)));
        }

        if (activity) {
            const deviceName = this.statsService.getDeviceNameByType(activity.activityType, activity.manuallyEntered, activity.deviceName);

            const value = this.getValueForStatType(activity);

            if (this.routeType === StatType.Weight) {
                valueAdditional = activity.bmi ? activity.bmi : 0;
            }

            if (this.routeType === StatType.WaistHipSize) {
                const statSetting = this.statsSettingsService.getDefaultStatSettingsByStatType(this.routeType);
                valueAdditional = statSetting ? statSetting.getConvertedValue(activity.hipCircumference) : activity.hipCircumference;
            }

            return [deviceName, value, valueAdditional];
        }

        return ['Unknown', null, 0];
    }

    private checkIfEmptyValue(value: number | MultiQuantityValues | null): boolean {
        switch (this.routeType) {
        case StatType.BloodPressure:
            return !((value as MultiQuantityValues)?.systolic) && !((value as MultiQuantityValues)?.diastolic);
        case StatType.Glucose:
            return !((value as MultiQuantityValues)?.fasting) && !((value as MultiQuantityValues)?.nonFasting);
        case StatType.Cholesterol:
            return !((value as MultiQuantityValues)?.totalCholesterol)
                    && !((value as MultiQuantityValues)?.ldl)
                    && !((value as MultiQuantityValues)?.hdl)
                    && !((value as MultiQuantityValues)?.triglycerides);
        default:
            return !value;
        }
    }

    private getValueForStatType(activity: ActivityModel): any {
        const statSetting = this.statsSettingsService.getDefaultStatSettingsByStatType(this.routeType ? this.routeType : '');
        switch (this.routeType) {
        case StatType.Workout:
            return this.getDurationForWorkoutSession(statSetting, activity);
        case StatType.MindfulMinutes:
            return this.getDuration(statSetting, activity);
        case StatType.ActiveMinutes:
            return this.getActiveMinutes(statSetting, activity);
        case StatType.Sleep:
            return statSetting ?
                statSetting.getConvertedValue(activity.durationInSeconds / 3600) : activity.durationInSeconds / 3600;
        case StatType.Steps:
            return statSetting ? statSetting.getConvertedValue(activity.steps) : activity.steps;
        case StatType.Weight:
            return statSetting ? statSetting.getConvertedValue(activity.weight) : activity.weight;
        case StatType.BodyTemp:
            return statSetting ? statSetting.getConvertedValue(activity.bodyTemperature) : activity.bodyTemperature;
        case StatType.BloodPressure: {
            const systolic = statSetting ? statSetting.getConvertedValue(activity.bloodPressureSystolic) : activity.bloodPressureSystolic;
            const diastolic = statSetting ? statSetting.getConvertedValue(activity.bloodPressureDiastolic) : activity.bloodPressureDiastolic;
            return {
                systolic: systolic ? systolic : null,
                diastolic: diastolic ? diastolic : null
            };
        }
        case StatType.Glucose: {
            const fasting = statSetting ? statSetting.getConvertedValue(activity.glucoseFasting) : activity.glucoseFasting;
            const nonFasting = statSetting ? statSetting.getConvertedValue(activity.glucoseNonFasting) : activity.glucoseNonFasting;
            return {
                fasting: fasting ? fasting : null,
                nonFasting: nonFasting ? nonFasting : null
            };
        }
        case StatType.A1C:
            return statSetting ? statSetting.getConvertedValue(activity.a1c) : activity.a1c;
        case StatType.WaistHipSize:
            return statSetting ? statSetting.getConvertedValue(activity.waistCircumference) : activity.waistCircumference;
        case StatType.Cholesterol: {
            const totalCholesterol = statSetting ? statSetting.getConvertedValue(activity.cholesterolTotal, StatType.Cholesterol) : activity.cholesterolTotal;
            const ldl = statSetting ? statSetting.getConvertedValue(activity.cholesterolLDL, StatType.Cholesterol) : activity.cholesterolLDL;
            const hdl = statSetting ? statSetting.getConvertedValue(activity.cholesterolHDL, StatType.Cholesterol) : activity.cholesterolHDL;
            const triglycerides = statSetting ? statSetting.getConvertedValue(activity.cholesterolTriglyceride, StatType.Triglyceride) : activity.cholesterolTriglyceride;
            return {
                totalCholesterol: totalCholesterol ? totalCholesterol : null,
                ldl: ldl ? ldl : null,
                hdl: hdl ? hdl : null,
                triglycerides: triglycerides ? triglycerides : null
            };
        }
        case StatType.CaloriesConsumed:
            return statSetting ? statSetting.getConvertedValue(activity.calories) : activity.calories;
        case StatType.CaloriesBurned:
            return statSetting ? statSetting.getConvertedValue(activity.calories) : activity.calories;
        default:
            return 0;
        }
    }

    private getActiveMinutes(statSetting: StatSettingsModel | null, activity: ActivityModel): number {
        const activeMinutesValue = activity.activeMinutes ? activity.activeMinutes : 0;
        return statSetting ? statSetting.getConvertedValue(activeMinutesValue) : activeMinutesValue;
    }

    private getDuration(statSetting: StatSettingsModel | null, activity: ActivityModel): number {
        const durationValue = activity.duration ? activity.duration : activity.activeMinutes;
        return statSetting ? statSetting.getConvertedValue(durationValue) : durationValue;
    }

    private getDurationForWorkoutSession(statSetting: StatSettingsModel | null, activity: ActivityModel): number {
        const durationInSecondsValue = activity.durationInSeconds ? activity.durationInSeconds : activity.duration * 60;
        return statSetting ? statSetting.getConvertedValue(durationInSecondsValue) : durationInSecondsValue;
    }

    private initConfigSettings() {
        const unitOfMeasurement = this.authenticatedMemberService.getAuthenticatedMember().unitOfMeasurement;

        const cholesterolGlucoseUnit = (unitOfMeasurement === UnitOfMeasurement.Metric || unitOfMeasurement === UnitOfMeasurement.UK_Imperial) ?
            this.translateService.instant('CholesterolMetric') : this.translateService.instant('CholesterolImperial');

        const waistHipsLengthUnit = unitOfMeasurement === UnitOfMeasurement.Metric ?
            this.translateService.instant('CentimetersAbbreviation') : this.translateService.instant('InchesAbbreviation');

        switch (this.routeType) {
        case StatType.Steps:
            this.yAxisTitle = this.translateService.instant('NumberOfSteps');
            this.tooltipValue = this.translateService.instant('Steps');
            this.graphTemplate = GraphTemplates.CumulativeQuantities;
            break;
        case StatType.Sleep:
            this.yAxisTitle = this.translateService.instant('NumberOfHours');
            this.tooltipValue = this.translateService.instant('After');
            this.graphTemplate = GraphTemplates.CumulativeQuantities;
            break;
        case StatType.Workout:
        case StatType.MindfulMinutes:
        case StatType.ActiveMinutes:
            this.yAxisTitle = this.translateService.instant('NumberOfMinutes');
            this.tooltipValue = this.translateService.instant('Minutes');
            this.graphTemplate = GraphTemplates.CumulativeQuantities;
            break;
        case StatType.CaloriesConsumed:
            this.yAxisTitle = this.translateService.instant('NumberOfCaloriesConsumed');
            this.tooltipValue = this.translateService.instant('MyCalories');
            this.graphTemplate = GraphTemplates.CumulativeQuantities;
            break;
        case StatType.CaloriesBurned:
            this.yAxisTitle = this.translateService.instant('NumberOfCaloriesBurned');
            this.tooltipValue = this.translateService.instant('MyCalories');
            this.graphTemplate = GraphTemplates.CumulativeQuantities;
            break;
        case StatType.Weight:
            this.yAxisTitle = unitOfMeasurement === UnitOfMeasurement.Metric ?
                this.translateService.instant('NumberOfKilograms') : this.translateService.instant('NumberOfPounds');
            this.tooltipValue = unitOfMeasurement === UnitOfMeasurement.Metric ?
                this.translateService.instant('MeasureUnitkg') : this.translateService.instant('MeasureUnitPounds');
            this.graphTemplate = GraphTemplates.SingleQuantities;
            this.yAxisTitleAdditional = this.translateService.instant('BMI');
            this.tooltipValueAdditional = this.translateService.instant('BMI');
            this.graphTemplateAdditional = GraphTemplates.SingleQuantities;
            break;
        case StatType.A1C:
            this.yAxisTitle = this.translateService.instant('HemoglobinMeasurement');
            this.tooltipValue = this.translateService.instant('PercentageSymbol');
            this.graphTemplate = GraphTemplates.SingleQuantities;
            break;
        case StatType.BloodPressure:
            this.yAxisTitle = this.translateService.instant('BloodPressureMeasurement');
            this.tooltipValue = this.translateService.instant('MeasureUnitmmHg');
            this.graphTemplate = GraphTemplates.MultiQuantities;
            break;
        case StatType.Cholesterol:
            this.yAxisTitle = this.translateService.instant('CholesterolStat') + ` (${cholesterolGlucoseUnit})`;
            this.tooltipValue = `${cholesterolGlucoseUnit}`;
            this.graphTemplate = GraphTemplates.MultiQuantities;
            break;
        case StatType.Glucose:
            this.yAxisTitle = this.translateService.instant('GlucoseStat') + ` (${cholesterolGlucoseUnit})`;
            this.tooltipValue = `${cholesterolGlucoseUnit}`;
            this.graphTemplate = GraphTemplates.MultiQuantities;
            break;
        case StatType.WaistHipSize:
            this.yAxisTitle = `${this.translateService.instant('WaistMeasurement', { lengthUnit: waistHipsLengthUnit })}`;
            this.tooltipValue = this.translateService.instant('WaistLabel');
            this.graphTemplate = GraphTemplates.SingleQuantities;
            this.yAxisTitleAdditional = `${this.translateService.instant('HipsMeasurement', { lengthUnit: waistHipsLengthUnit })}`;
            this.tooltipValueAdditional = this.translateService.instant('HipLabel');
            this.graphTemplateAdditional = GraphTemplates.SingleQuantities;
        }
    }

    private initGraphConfig() {
        this.legendDisabled = this.isLegendDisabled(this.graphData, this.graphTemplate);
        this.graphConfig = this.statGraphConfigService.generateGraphConfig(
            this.xAxisSpacing,
            this.yAxisTitle,
            this.goalValue,
            this.graphTemplate,
            this.graphData,
            this.tooltipValue,
            this.routeType,
            this.isYearView,
            this.isEmptyGraph,
            this.isStacked(),
            this.legendDisabled
        );
        this.graphConfig.series.focusable = true;
        this.graphConfig.series.skipFocusThreshold = 50;

        if (this.routeType === StatType.Weight || this.routeType === StatType.WaistHipSize) {
            this.legendAdditionalDisabled = this.isLegendDisabled(this.graphDataAdditional, this.graphTemplateAdditional);
            this.graphConfigAdditional = this.statGraphConfigService.generateGraphConfig(
                this.xAxisSpacingAdditional,
                this.yAxisTitleAdditional,
                this.goalValueAdditional,
                this.graphTemplateAdditional,
                this.graphDataAdditional,
                this.tooltipValueAdditional,
                this.routeType,
                this.isYearView,
                this.isAdditionalGraphEmpty,
                this.isStacked(),
                this.legendAdditionalDisabled
            );
            this.graphConfigAdditional.series.focusable = true;
            this.graphConfigAdditional.series.skipFocusThreshold = 50;
        }
    }

    handleUpdatedFilterSource(sourceTitle: string) {
        const selectedOption = this.filterSource.options.find((option) => option.text === sourceTitle) || { text: sourceTitle };
        this.trackFilterSourceUpdate(sourceTitle, this.currentStat?.statType);
        this.filterSource.selected = selectedOption.text;
        this.filterStatsAndSetGraphData();
    }

    private initFilterSource(activities: ActivityModel[]) {
        this.generateFilterSourceOptions();
        this.trimFilterSourceOptions();

        if (this.filterSource.options.some((option) => {
            return option.text === this.DAILY_HIGHEST;
        })) {
            this.handleDailyHighestFilterSource(activities);
        } else if (this.filterSource.options.length > 0) {
            this.setSelectedFilterSource(this.getBiometricFilterSource(activities));
        }

        this.filterSource.enabled = this.isFilterSourceEnabled();
        this.filterStatsAndSetGraphData();
    }

    private setSelectedFilterSource(biometricSource: string) {
        if (biometricSource) {
            this.filterSource.selected = biometricSource;
            return;
        }

        this.filterSource.selected = this.filterSource.options[0].text ? this.filterSource.options[0].text : '';
    }

    private isFilterSourceEnabled() {
        return this.filterSource.options.length >= 1;
    }

    private getBiometricFilterSource(activities: ActivityModel[]): string {
        const sortedMRRActivities = [...activities].sort((a, b) => {
            return new Date(a.memberDate) < new Date(b.memberDate) ? 1 : -1;
        }).filter((activity) => activity.isMostRecentlyReceived);

        if (sortedMRRActivities[0]) {
            const latestFilterSource = this.filterSource.options.find((option) =>
                option.text === this.statsService.getDeviceNameByType(sortedMRRActivities[0].activityType, sortedMRRActivities[0].manuallyEntered, sortedMRRActivities[0].deviceName)
            );

            return latestFilterSource ? latestFilterSource.text : '';
        }

        return '';
    }

    private generateFilterSourceOptions() {
        this.filterSource.options.length = 0;
        this.graphStatistics.forEach((stat) => {
            if ((this.getValueForStatType(stat) > 0 || !this.checkIfEmptyValue(this.getValueForStatType(stat))) || (this.routeType === StatType.WaistHipSize && (stat.waistCircumference || stat.hipCircumference))) {
                const text = this.statsService.getDeviceNameByType(stat.activityType, stat.manuallyEntered, stat.deviceName);
                if (!this.filterSource.options.some((option) => option.text === text)) {
                    this.filterSource.options = [...this.filterSource.options, this.buildFilterSourceOption(stat, text)];
                }
            }
        });
    }

    private trimFilterSourceOptions() {
        if (this.filterSource.options.length <= 2 && this.filterSource.options.some((option) => option.text === this.DAILY_HIGHEST)) {
            this.filterSource.options = this.filterSource.options.filter((option) => option.text !== this.DAILY_HIGHEST);
        }
    }

    private buildFilterSourceOption(stat: ActivityModel, text: string): StatSourceItem {
        return {
            text: text,
            item: stat
        };
    }

    private trackFilterSourceUpdate(sourceTitle: string, statType?: StatType) {
        this.landingPageAnalyticsTrackingService.trackDrilldownPageStatSourceUpdated(statType, sourceTitle);
    }

    private isStacked() {
        return this.STATS_WITH_STACKED_GRAPH.includes(this.routeType);
    }

    private handleDailyHighestFilterSource(activities: ActivityModel[]) {
        if (this.routeType === StatType.Steps) {
            const endDateActivities = activities.filter((activity) => {
                const activityDate = new Date(activity.activityDate);
                return activityDate.getDate() === DateUtils.dateOf(this.statsEndDate);
            });

            if (endDateActivities && endDateActivities.length) {
                const highestValue = endDateActivities.reduce((prev, current) => {
                    if (prev.steps > current.steps) {
                        return prev;
                    }

                    return current;
                });

                const selectedFilterSource = this.filterSource.options.find((option) =>
                    this.statsService.getDeviceNameByType(highestValue.activityType, highestValue.manuallyEntered, highestValue.deviceName) === option.text
                );
                this.filterSource.selected = selectedFilterSource && selectedFilterSource.text ? selectedFilterSource.text : this.DAILY_HIGHEST;
                return;
            }

            const highestValue = this.filterSource.options.reduce((prev, current) => {
                if (prev.item && current.item) {
                    if (prev.item.steps > current.item.steps) {
                        return prev;
                    }

                    return current;
                }

                return {} as StatSourceItem;
            });

            this.filterSource.selected = highestValue.text ? highestValue.text : this.DAILY_HIGHEST;
            return;
        }

        this.filterSource.selected = this.DAILY_HIGHEST;
    }

    private async filterStatsAndSetGraphData() {
        const am4core = await this.amChartsService.getAm4CoreReference();
        const am4charts = await this.amChartsService.getAm4Charts();
        const am4themes_animated = await this.amChartsService.getAm4ThemeAnimated();

        const filteredStats = this.graphStatistics.filter((statistic) => this.statsService.getDeviceNameByType(statistic.activityType, statistic.manuallyEntered, statistic.deviceName) === this.filterSource.selected);
        this.generateGraphData(filteredStats);
        this.initGraphConfig();

        this.chart = await this.amChartsService.generateV4Chart(`${this.routeType}-stat-graph`, this.graphConfig, am4charts.XYChart);
        this.chart.language.locale = this.graphLanguage;
        this.statGraphConfigService.setGraphElementIds(this.chart, this.graphTemplate);

        if (!this.legendDisabled) {
            const legendContainer = am4core.create(`${this.routeType}-legend`, am4core.Container);
            legendContainer.width = am4core.percent(100);
            legendContainer.height = am4core.percent(100);

            this.chart.legend.parent = legendContainer;
            this.statGraphConfigService.setLegendHeightForChart(this.chart);
        }

        am4core.useTheme(am4themes_animated);

        this.showAdditionalGraph = this.checkShowAdditionalGraph(filteredStats);
        if (this.showAdditionalGraph) {
            this.chartAdditional = await this.amChartsService.generateV4Chart('stat-graph-addnl', this.graphConfigAdditional, am4charts.XYChart);
            this.chartAdditional.language.locale = this.graphLanguage;
            this.statGraphConfigService.setGraphElementIds(this.chartAdditional, this.graphTemplateAdditional);

            if (!this.legendAdditionalDisabled) {
                const legendContainer = am4core.create('legend-addnl', am4core.Container);
                legendContainer.width = am4core.percent(100);
                legendContainer.height = am4core.percent(100);

                this.chartAdditional.legend.parent = legendContainer;
                this.statGraphConfigService.setLegendHeightForChart(this.chartAdditional);
            }
        }
    }

    private setDailyHighestAndMRRValues() {
        switch (this.routeType) {
        case StatType.BloodPressure:
        case StatType.A1C:
        case StatType.Cholesterol:
        case StatType.Glucose:
        case StatType.Weight:
        case StatType.WaistHipSize:
        case StatType.CaloriesConsumed:
            this.dailyHighest = false;
            this.mostRecentlyReceived = true;
            this.statType = this.routeType;
            break;
        default:
            this.dailyHighest = true;
            this.mostRecentlyReceived = false;
            this.statType = undefined;
        }
    }

    private isLegendDisabled(graphData: any, graphTemplate: any): boolean {
        if (this.isStacked()) {
            return graphData.filter((item: any) => item.activities).length < 1;
        }

        if (graphTemplate === GraphTemplates.MultiQuantities) {
            return graphData.filter((item: any) => {
                if (this.routeType === StatType.BloodPressure) {
                    return item.diastolic > 0 || item.systolic > 0;
                }

                if (this.routeType === StatType.Cholesterol) {
                    return item.totalCholesterol > 0 || item.ldl > 0 || item.hdl > 0 || item.triglycerides > 0;
                }

                if (this.routeType === StatType.Glucose) {
                    return item.fasting > 0 || item.nonFasting > 0;
                }

                return;
            }).length < 1;
        }

        return true;
    }

    getChartElementAria(data: any): string {
        if (this.routeType === StatType.Workout && data.activities && data.activities.length > 0) {
            const chartElementAria: string[] = [];
            data.activities.forEach((activity: any) => {
                chartElementAria.push(`${activity.value} ${this.tooltipValue} ${data.fullDate} ${activity.deviceName} ${activity.description}`);
            });
            return chartElementAria.toString();
        }
        if (this.routeType === StatType.BloodPressure && data.diastolic > 0 && data.systolic > 0) {
            const systolic = `${this.translateService.instant('SystolicBloodPressure')} ${data.systolic} ${this.tooltipValue}`;
            const diastolic = `${this.translateService.instant('DiastolicBloodPressure')} ${data.diastolic} ${this.tooltipValue}`;

            return systolic + diastolic + `${data.fullDate} ${data.device}`;
        }
        if (this.routeType === StatType.Glucose && data.fasting > 0 && data.nonFasting > 0) {
            const fasting = `${this.translateService.instant('GlucoseStat')} ${this.translateService.instant('GlucoseFasting')} ${data.fasting} ${this.tooltipValue}`;
            const nonFasting = `${this.translateService.instant('GlucoseStat')} ${this.translateService.instant('GlucoseNonFasting')} ${data.nonFasting} ${this.tooltipValue}`;

            return fasting + nonFasting + `${data.fullDate} ${data.device}`;
        }
        if (this.routeType === StatType.Cholesterol && data.totalCholesterol > 0 && data.ldl > 0 && data.hdl > 0 && data.triglycerides > 0) {
            const totalCholesterol = `${this.translateService.instant('TotalCholesterol')} ${data.totalCholesterol} ${this.tooltipValue}`;
            const ldl = `${this.translateService.instant('LDLCholesterol')} ${data.ldl} ${this.tooltipValue}`;
            const hdl = `${this.translateService.instant('HDLCholesterol')} ${data.hdl} ${this.tooltipValue}`;
            const triglycerides = `${this.translateService.instant('Triglycerides')} ${data.triglycerides} ${this.tooltipValue}`;

            return totalCholesterol + ldl + hdl + triglycerides + `${data.fullDate} ${data.device}`;
        }
        if (data.value > 0) {
            return `${data.value} ${this.tooltipValue} ${data.fullDate} ${data.device}`;
        }
        return '';
    }
}
