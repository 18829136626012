import { Component, Inject } from '@angular/core';
import { find } from 'lodash';

import { GlobalChallengeCardCarouselDataModel } from '../../../interface/global-challenges-card-carousel-data.model';
import { GlobalChallengesBlockerService } from '../../global-challenges-blocker.service';
import { MemberContestOnboardingBlockerService } from '../../member-contest-onboarding-blocker.service';
import { VpGoService } from '../../vp-go.service';
import { AuthenticatedMemberService, SweetAlertService } from '@genesis-frontend/genesis-utilities';

@Component({
    templateUrl: './confirm-team-creation.template.html',
    styleUrls: ['./confirm-team-creation.component.scss']
})
export class GlobalChallengesConfirmTeamCreationCardComponent {
    SHORT_TEAM_NAME = 20;
    LONG_TEAM_NAME = 30;
    MAX_PLAYERS_IN_TEAM = this.data.contest.maxPlayersAllowed - 1;

    member: any;
    teamCaptain: string | undefined;
    freeSlotsToDisplay: number | undefined;
    freeSlotsNotDisplayed: number | undefined;
    freeSlotsNumber: number | undefined;
    freeSlotsArray: any[] | undefined;
    titleClass: string | undefined;
    showLeftArrow = true;
    invitedPlayersEmails: string[] = [];
    invitedPlayersIds: number[] = [];

    constructor(
        public vpGoService: VpGoService,
        private authenticatedMemberService: AuthenticatedMemberService,
        private memberContestOnboardingBlockerService: MemberContestOnboardingBlockerService,
        private globalChallengesOnboardingBlockerService: GlobalChallengesBlockerService,
        private sweetAlertService: SweetAlertService,
        @Inject('data') public data: GlobalChallengeCardCarouselDataModel
    ) {}

    ngOnInit() {
        this.member = this.authenticatedMemberService.getMember();
        this.initTeamData();
    }

    initTeamData() {
        this.chooseTeamNameClass(this.data.teamName);
        this.teamCaptain = this.member.firstname + ' ' + this.member.lastname;
        this.getFreeSlotsInfo();
        this.generateArrayOfFreeSlots();
        this.data.isConfirmTeamCardVisited = true;
    }

    createTeam() {
        this.splitMailsAndMembers();
        const team = {
            teamName: this.data.teamName,
            teamDescription: this.data.rallyingCry,
            isPrivate: this.data.isTeamPrivate,
            teamLogoUrl: this.data.teamImage,
            contestId: this.data.contest.contestId,
            createdDate: new Date(),
            teamType: 'Small',
            status: 'Active'
        };
        this.memberContestOnboardingBlockerService.saveTeamResource(this.data.contest.contestId, team).subscribe(
            (response: any) => {
                if (response && response?.contestTeamId) {
                    if (this.data.invitedPlayers.length) {
                        if (this.invitedPlayersEmails.length) {
                            this.memberContestOnboardingBlockerService
                                .sendInvitationEmails(this.data.contest.contestId, response.contestTeamId, this.invitedPlayersEmails)
                                .subscribe();
                        }

                        if (this.invitedPlayersIds.length) {
                            this.memberContestOnboardingBlockerService
                                .sendInvitationMembers(this.data.contest.contestId, response.contestTeamId, this.invitedPlayersIds)
                                .subscribe((data) => {
                                    const inviteFailed = find(data, ['wasSuccessfullyInvited', false]);
                                    if (inviteFailed) {
                                        this.sweetAlertService.showError(inviteFailed.message);
                                    }
                                });
                        }
                    }

                    this.goToNextCard();
                }
            },
            (error: { error: { message: string } }) => {
                if (error.error.message === 'Genesis.Challenges.TeamExistsError') {
                    this.sweetAlertService.showError('TeamExistsError');
                }
            }
        );
    }

    getFreeSlotsInfo() {
        if (this.data.invitedPlayers.length >= this.MAX_PLAYERS_IN_TEAM) {
            this.freeSlotsToDisplay = 0;
            this.freeSlotsNotDisplayed = 0;
        } else {
            this.freeSlotsNumber = this.MAX_PLAYERS_IN_TEAM - this.data.invitedPlayers.length;
            this.freeSlotsToDisplay = this.MAX_PLAYERS_IN_TEAM - this.data.invitedPlayers.length;
            this.freeSlotsNotDisplayed = this.MAX_PLAYERS_IN_TEAM - this.freeSlotsToDisplay - 1;
        }
    }

    generateArrayOfFreeSlots() {
        if (this.freeSlotsToDisplay) {
            this.freeSlotsArray = this.globalChallengesOnboardingBlockerService.generateArrayOfFreeSlots(this.freeSlotsToDisplay);
        }
    }

    goToNextCard() {
        this.data.goToCard(this.vpGoService.VpGoCards.DEVICE_ORDERING_INDEX);
    }

    chooseTeamNameClass(teamName: string) {
        if (teamName.length < this.SHORT_TEAM_NAME) {
            this.titleClass = '';
        } else if (teamName.length >= this.SHORT_TEAM_NAME && teamName.length <= this.LONG_TEAM_NAME) {
            this.titleClass = 'long-team-title';
        } else {
            this.titleClass = 'longer-team-title';
        }
    }

    splitMailsAndMembers() {
        this.invitedPlayersEmails = [];
        this.invitedPlayersIds = [];
        this.data.invitedPlayers.forEach((player) => {
            if (player.emailAddress) {
                this.invitedPlayersEmails.push(player.emailAddress);
            }
            if (player.memberId) {
                this.invitedPlayersIds.push(player.memberId);
            }
        });
    }
}
