import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Injector, OnDestroy } from '@angular/core';
import { Subscription, timer } from 'rxjs';

import { BaseCardCarouselComponent } from '../../../../card-carousel/base-card-carousel/base-card-carousel.component';
import { GlobalChallengeCardCarouselDataModel } from '../../interface/global-challenges-card-carousel-data.model';
import { GlobalChallengesAddPlayersCardComponent } from '../cards/add-players/add-players.component';
import { GlobalChallengesMaxBuzzAddressCollectionCardComponent } from '../cards/address-collection/address-collection.component';
import { GlobalChallengesMaxBuzzAddressConfirmationCardComponent } from '../cards/address-confirmation/address-confirmation.component';
import { GlobalChallengesConfirmTeamCreationCardComponent } from '../cards/confirm-team-creation/confirm-team-creation.component';
import { GlobalChallengesConnectDeviceCardComponent } from '../cards/connect-device/connect-device.component';
import { GlobalChallengesCreateTeamCardComponent } from '../cards/create-a-team/create-a-team.component';
import { GlobalChallengesMaxBuzzOrOwnDevice } from '../cards/device-ordering/device-ordering.component';
import { GlobalChallengesJoinAChallengeCardComponent } from '../cards/join-a-challenge/join-a-challenge.component';
import { GlobalChallengesJoinATeamCardComponent } from '../cards/join-a-team/join-a-team.component';
import { GlobalChallengesMaxBuzzOrderConfirmationCardComponent } from '../cards/order-confirmation/order-confirmation.component';
import { GlobalChallengesTeamPreviewCardComponent } from '../cards/team-preview/team-preview.component';
import { MemberContestOnboardingBlockerService } from '../member-contest-onboarding-blocker.service';
import { VpGoService } from '../vp-go.service';

@Component({
    selector: 'challenge-onboarding-blocker-modal',
    templateUrl: './global-challenges-onboarding-blocker.template.html',
    styleUrls: ['./global-challenges-onboarding-blocker.component.scss'],
    animations: [
        trigger('cardsParent', [transition(':enter', [])]),
        trigger('cardInOut', [
            state(
                'void',
                style({
                    top: '15px',
                    left: '-168px',
                    opacity: 0,
                    transform: 'rotate(-2deg) scale(0.95)'
                })
            ),
            transition('void <=> *', animate('400ms 100ms'))
        ])
    ]
})
export class GlobalChallengesOnboardingBlockerModal extends BaseCardCarouselComponent implements OnDestroy {
    showModal = false;
    contest: any;
    contestId: number | undefined;
    memberId: number | undefined;
    finishedCallback!: () => void;
    continueEvent = new EventEmitter<boolean>();

    cards: any[] = [
        GlobalChallengesJoinAChallengeCardComponent,
        GlobalChallengesJoinATeamCardComponent,
        GlobalChallengesTeamPreviewCardComponent,
        GlobalChallengesCreateTeamCardComponent,
        GlobalChallengesAddPlayersCardComponent,
        GlobalChallengesConfirmTeamCreationCardComponent,
        GlobalChallengesMaxBuzzOrOwnDevice,
        GlobalChallengesMaxBuzzAddressCollectionCardComponent,
        GlobalChallengesMaxBuzzAddressConfirmationCardComponent,
        GlobalChallengesMaxBuzzOrderConfirmationCardComponent,
        GlobalChallengesConnectDeviceCardComponent
    ];

    data: GlobalChallengeCardCarouselDataModel = {
        contest: null,
        alreadyTriedToJoinChallenge: false,
        nextCard: this.nextCard.bind(this),
        prevCard: this.prevCard.bind(this),
        teamName: '',
        teamImage: '',
        rallyingCry: '',
        isTeamPrivate: false,
        memberId: null,
        teams: null,
        isTeamNameCardFilled: false,
        isCreateATeamCardVisited: false,
        isAddPlayersCardVisited: false,
        isJoinATeamCardVisited: false,
        isMaxBuzzAddressConfirmationCardVisited: false,
        viewTeamDetails: this.viewTeamDetails.bind(this),
        selectedTeamForPreview: null,
        goToCard: this.goToCard.bind(this),
        contestMember: null,
        teamRivals: [],
        numberOfResults: null,
        suggestedTeams: [],
        finishedCallback: Function,
        invitedPlayers: [],
        getContestMember: this.getContestMember.bind(this),
        freeSlotsToDisplay: null,
        freeSlotsNotDisplayed: null,
        freeSlotsNumber: null,
        freeSlotsArray: [],
        teamInvites: [],
        orderDetails: undefined,
        getMaxBuzzModal: false,
        isMaxBuzzOrdered: false,
        suggestedTeamPage: 0,
        suggestedTeamPageSize: 10,
        lastPickedTeamPageTab: null,
        isFeelingLuckyOption: false,
        allTeamsLoaded: false
    };
    changeDetectionInterval: Subscription | undefined;

    readonly CLOSE_ICON = 'times';
    readonly TEAM_MEMBERS_TO_DISPLAY = '28';

    constructor(
        private vpGoService: VpGoService,
        public injector: Injector,
        private memberContestOnboardingBlockerService: MemberContestOnboardingBlockerService,
        private cd: ChangeDetectorRef
    ) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.memberId && this.contestId) {
            this.getOnboardingChallenge(this.memberId, this.contestId);
        }

        this.data.finishedCallback = this.finishedCallback;

        this.changeDetectionInterval = timer(0, 300).subscribe(() => {
            this.cd.detectChanges();
        });
    }

    getTeams(memberId: number, contestId: number) {
        if (this.contest.contestType === this.vpGoService.FeaturedChallenges.ChallengeTypes.Organizational) {
            this.memberContestOnboardingBlockerService
                .getSuggestedTeamsForOrgbasedContest(memberId, contestId, this.TEAM_MEMBERS_TO_DISPLAY)
                .subscribe((response) => {
                    this.data.teams = response;
                });
        } else {
            this.memberContestOnboardingBlockerService
                .getSuggestedTeamsForContest(memberId, contestId, this.data.suggestedTeamPage, this.data.suggestedTeamPageSize)
                .subscribe((response) => {
                    this.data.suggestedTeamPage++;
                    this.data.teams = response;
                });
        }
    }

    getOnboardingChallenge(memberId: number | undefined, contestId: number | undefined) {
        if (memberId && contestId) {
            this.memberContestOnboardingBlockerService.getOnboardingChallenge(memberId, contestId).subscribe((contest) => {
                if (contest) {
                    this.showModal = true;
                    this.contest = contest;
                    this.data.memberId = memberId ? memberId : null;
                    this.data.contest = contest;
                    this.getTeams(memberId, contestId);
                    this.getTeamInvitesForMember(contestId, memberId);
                }
            });
        }
    }

    viewTeamDetails(team: any) {
        this.data.selectedTeamForPreview = team;
        this.currentCard = this.vpGoService.VpGoCards.TEAM_PREVIEW_CARD_INDEX;
    }

    goToCard(index: number): void {
        this.currentCard = index;
    }

    getContestMember() {
        if (this.data?.memberId) {
            this.memberContestOnboardingBlockerService
                .getContestMember(this.data.contest.contestId, this.data?.memberId)
                .subscribe((contestMember) => {
                    this.data.contestMember = contestMember;
                });
        }
    }

    getTeamInvitesForMember(contestId: number, memberId: number) {
        this.memberContestOnboardingBlockerService.getTeamInvitesForMember(contestId, memberId).subscribe((response) => {
            this.data.teamInvites = response;
        });
    }

    closeModal() {
        this.continueEvent.emit(true);
        this.data.finishedCallback();
    }

    ngOnDestroy(): void {
        this.changeDetectionInterval?.unsubscribe();
    }
}
