import { Injectable } from '@angular/core';

import { AuthenticatedMemberService } from './authenticated-member.service';
import { AnalyticsTrackingSingleSpaService } from '../services/analytics-tracking-single-spa.service';
// import { ZopimService } from '../services/zopim.service';


@Injectable({
    providedIn: 'root'
})
export class LogoutService {
    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        // private zopimService: ZopimService,
        private analyticsTrackingSingleSpaService: AnalyticsTrackingSingleSpaService
    ) { }

    logout(isTimeout: boolean, customLogoutUrl?: string): void {
        sessionStorage.clear();
        this.analyticsTrackingSingleSpaService.clearMixpanel();
        // this.zopimService.closeChat();

        const authClient = this.authenticatedMemberService.getAuthClient();
        let logoutUrl = window.location.origin + '/logout.html';

        if (customLogoutUrl && (customLogoutUrl.length > 0 || isTimeout)) {
            logoutUrl = customLogoutUrl;
        }

        authClient.logout({
            redirectUri: logoutUrl
        });
    }
}
