<vpg-dialog-header [closeButtonAriaLabel]="TRANSLATIONS.CLOSE | translate">
    {{ TRANSLATIONS.MY_ACTION | translate }}
</vpg-dialog-header>
<vpg-dialog-body
    *ngIf="promotedActions$ | async; let promotedActions"
    [class.dialog-body-bottom-padding]="!hasRewardsAndProgram">
    <div class="flex flex-wrap justify-between">
        <genesis-frontend-pal-card
            *ngFor="let item of promotedActions; let index = index"
            #carouselItem
            [card]="item"
            (palCardClicked)="onCardClick($event, index)"
            [isHomepageCard]="false"
            class="action-card-sizing"
            [eventCode]="carouselItem.card.eventCode">
        </genesis-frontend-pal-card>
    </div>
</vpg-dialog-body>
<vpg-dialog-actions
    *ngIf="hasRewardsAndProgram">
    <button
        id="pal-view-rewards"
        vpg-button
        size="medium"
        (click)="viewRewards()"
    >
        {{ TRANSLATIONS.VIEW_REWARDS | translate }}
    </button>
</vpg-dialog-actions>
