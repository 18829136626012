import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { GlobalChallengeRulesModal } from './global-challenges-rules.component';


@Injectable()
export class GlobalChallengeRulesModalService {
    constructor(
        private modalService: BsModalService
    ) {}

    openModal(rulesHtml: string) {
        return this.modalService.show(GlobalChallengeRulesModal, {
            class: 'global-challenge-rules-modal',
            initialState: {
                rulesHtml
            },
            animated: false
        });
    }
}
