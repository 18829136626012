export enum MemberUpdateObjectType {
    AcceptedFriendship = 'AcceptedFriendship',
    RequestedFriendship = 'RequestedFriendship',
    FriendHasNewFriend = 'FriendHasNewFriend',
    FeaturedChallengeTeam = 'FeaturedChallengeTeam',
    GoalChallenge = 'GoalChallenge',
    PersonalChallenge = 'PersonalChallenge',
    FeaturedChallenge = 'FeaturedChallenge',
    TrackerChallenge = 'TrackerChallenge',
    FeaturedChallengeCreateTeamReminder = 'FeaturedChallengeCreateTeamReminder',
    CoachChat = 'CoachChat',
    CoachConnection = 'CoachConnection',
    Alert = 'Alert',
    FeatureNotification = 'FeatureNotification',
    PartnerReward = 'PartnerReward',
    SocialGroupChat = 'SocialGroupChat',
    SocialGroupInvite = 'SocialGroupInvite',
    SocialGroupSubmission = 'SocialGroupSubmission',
    Recognition = 'Recognition',
    CalendarEvent = 'CalendarEvent',
    LiveServicesChat = 'LiveServicesChat'
}
