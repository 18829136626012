export enum SurveyStatus {
    Available = 'Available',
    Started = 'Started',
    Scoreable = 'Scoreable',
    Completed = 'Completed',
    ExpiredNotScoreable = 'ExpiredNotScoreable',
    ExpiredScoreable = 'ExpiredScoreable',
    ExpiredRetake = 'ExpiredRetake',
    ExpiredCompleted = 'ExpiredCompleted',
    Retake = 'Retake'
}
