<button class="btn dropdown-toggle criteria-dropdown-btn"
        type="button"
        [disabled]="isDisabled"
        (click)="toggleMenu()">
    <span class="sponsor-text">{{ selectedSponsor || 'Select' }}</span>
    <span class="caret"></span>
</button>
<div class="dropdown-menu-1" *ngIf="isClicked">
    <div class="input-group">
        <span class="input-group-addon">
            <span class="glyphicon glyphicon-search"
                  aria-hidden="true"></span>
        </span>
        <input type="text"
               class="form-control"
               placeholder="Search"
               [(ngModel)]="itemSearchText"
               (ngModelChange)="delayedNgModelChange$.next(true)">
    </div>
    <ul #dropdownListContainer class="dropdown-list-container"
        aria-labelledby="dropdownMenu4">
        <li *ngFor=" let item of allItems"
            (click)="itemClicked(item)">
            <a>{{ item.name }}</a>
        </li>
    </ul>
</div>
