import { GenesisAdminState } from './state.model';

export const genesisAdminStateInitial: GenesisAdminState = {
    appLoaded: true
};
// @ts-ignore
export const genesisAdminStateReducer = (state = genesisAdminStateInitial, action) => {
    switch (action.type) {

    default: {
        return state;
    }
    }
};
