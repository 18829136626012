<div *ngIf="pcpFormData && member">
    <form #pcpForm
          name="pcpForm"
          method="POST"
          [action]="pcpFormData.formUrl"
          class="hidden">
        <input type="hidden"
               name="firstName"
               [value]="member.firstname" />
        <input type="hidden"
               name="lastName"
               [value]="member.lastname" />
        <input type="hidden"
               name="memberId"
               [value]="member.id" />
        <input type="hidden"
               name="vhm_legacy_id"
               [value]="member.vhm_legacy_id" />
        <input type="hidden"
               name="employeeId"
               [value]="member.employeeId" />
        <input type="hidden"
               name="langId"
               [value]="member.language" />
        <input type="hidden"
               name="sponsorId"
               [value]="member.sponsorId" />
        <input type="hidden"
               name="emailAddress"
               [value]="member.emailaddress" />
        <input type="hidden"
               name="isV2" [value]="pcpFormData.isV2" />
        <input type="hidden"
               name="startProgramYear"
               [value]="pcpFormData.startProgramYear" />
        <input type="hidden"
               name="endProgramYear"
               [value]="pcpFormData.endProgramYear" />
        <input type="hidden"
               name="rewardPopulation"
               [value]="rewardPopulation" />
        <input type="hidden"
               name="rewardPopulationId"
               [value]="rewardPopulationId" />
        <input type="hidden"
               name="dateOfBirth"
               [value]="member.dateofbirth" />
    </form>
</div>
