import { Component, Inject } from '@angular/core';

import { GlobalChallengeCardCarouselDataModel } from '../../../interface/global-challenges-card-carousel-data.model';
import { GlobalChallengesBlockerService } from '../../global-challenges-blocker.service';
import { MemberContestOnboardingBlockerService } from '../../member-contest-onboarding-blocker.service';
import { VpGoService } from '../../vp-go.service';
import { SweetAlertService } from '@genesis-frontend/genesis-utilities';

@Component({
    templateUrl: './team-preview.template.html',
    styleUrls: ['./team-preview.component.scss']
})
export class GlobalChallengesTeamPreviewCardComponent {
    INVITE_REVOKED_STATUS_CODE = 404;
    freeSlotsToDisplay: number | undefined;
    notDisplayedTeamMembers!: number;
    freeSlotsArray: any[] = [];
    showLeftArrow: boolean | null = false;
    isTeamLoading = false;

    ORGANIZATIONAL_CONTEST_DEFAULT_SLOTS = 28;
    NON_ORGANIZATIONAL_CONTEST_DEFAULT_SLOTS = 7;

    constructor(
        private memberContestOnboardingBlockerService: MemberContestOnboardingBlockerService,
        private globalChallengesOnboardingBlockerService: GlobalChallengesBlockerService,
        public vpGoService: VpGoService,
        private sweetAlertService: SweetAlertService,
        @Inject('data') public data: GlobalChallengeCardCarouselDataModel
    ) {}

    DEFAULT_ITEMS_TO_DISPLAY =
        this.data.contest.contestType !== this.vpGoService.FeaturedChallenges.ChallengeTypes.Organizational
            ? this.NON_ORGANIZATIONAL_CONTEST_DEFAULT_SLOTS
            : this.ORGANIZATIONAL_CONTEST_DEFAULT_SLOTS;

    MAX_PLAYER_IN_TEAM =
        this.data.contest.contestType !== this.vpGoService.FeaturedChallenges.ChallengeTypes.Organizational
            ? this.data.contest.maxPlayersAllowed
            : this.data.selectedTeamForPreview.totalMembersInOrgUnit;

    ITEMS_TO_DISPLAY = this.MAX_PLAYER_IN_TEAM < this.DEFAULT_ITEMS_TO_DISPLAY ? this.MAX_PLAYER_IN_TEAM : this.DEFAULT_ITEMS_TO_DISPLAY;

    ngOnInit() {
        this.getOrgBasedContestData();
        this.getFreeSlotsInfo();
        this.generateArrayOfFreeSlots();
        this.showLeftArrow =
            this.data.contest.contestType !== this.vpGoService.FeaturedChallenges.ChallengeTypes.Organizational &&
            (this.data.teamInvites.length > 0 || (this.data.teams && this.data.teams.length > 0));
    }

    getFreeSlotsInfo() {
        this.freeSlotsToDisplay = this.globalChallengesOnboardingBlockerService.getFreeSlotsNumberToDisplay(
            this.data.selectedTeamForPreview.teamMembers.length,
            this.ITEMS_TO_DISPLAY
        );

        this.notDisplayedTeamMembers = this.globalChallengesOnboardingBlockerService.getNumberOfNotDisplayedTeamMembers(
            this.data.selectedTeamForPreview.totalMembersJoinedContest,
            this.ITEMS_TO_DISPLAY
        );
    }

    getOrgBasedContestData() {
        if (this.data.contest.contestType === this.vpGoService.FeaturedChallenges.ChallengeTypes.Organizational) {
            this.data.selectedTeamForPreview.teamName = this.data.selectedTeamForPreview.orgUnitTeamName;
            this.data.selectedTeamForPreview.teamMembers = this.data.selectedTeamForPreview.joinedMembers;
        }
    }

    generateArrayOfFreeSlots() {
        if (this.freeSlotsToDisplay) {
            this.freeSlotsArray = this.globalChallengesOnboardingBlockerService.generateArrayOfFreeSlots(this.freeSlotsToDisplay);
        }
    }

    joinThisTeam() {
        if (this.data.contest.contestType === this.vpGoService.FeaturedChallenges.ChallengeTypes.Organizational) {
            this.joinOrgBasedContest();
        } else {
            this.joinTeamNonOrgBased();
        }
    }

    joinTeamNonOrgBased() {
        this.memberContestOnboardingBlockerService
            .joinTeam(this.data.contest.contestId, this.data.selectedTeamForPreview.teamId, this.data.memberId)
            .subscribe(
                (response) => {
                    if (response) {
                        this.goToNextCard();
                    }
                },
                (error) => {
                    if (error.data.statusCode === this.INVITE_REVOKED_STATUS_CODE) {
                        this.sweetAlertService.showError(error.data.message);
                    }
                }
            );
    }

    joinOrgBasedContest() {
        if (this.data.memberId) {
            this.memberContestOnboardingBlockerService
                .joinOrgBasedContest(this.data.contest.contestId, this.data.memberId)
                .subscribe(() => {
                    this.data.goToCard(this.vpGoService.VpGoCards.DEVICE_ORDERING_INDEX);
                });
        }
    }

    tryAnotherTeam() {
        this.isTeamLoading = true;
        if (this.data.memberId) {
            this.memberContestOnboardingBlockerService
                .getRandomTeamFromContest(this.data.memberId, this.data.contest.contestId)
                .subscribe((teams) => {
                    this.data.isFeelingLuckyOption = true;
                    this.isTeamLoading = false;
                    this.data.viewTeamDetails(teams[0]);
                    this.getFreeSlotsInfo();
                    this.generateArrayOfFreeSlots();
                });
        }
    }

    goToNextCard() {
        this.data.goToCard(this.vpGoService.VpGoCards.DEVICE_ORDERING_INDEX);
    }

    goToPreviousCard() {
        this.data.isFeelingLuckyOption = false;
        this.data.goToCard(this.vpGoService.VpGoCards.JOIN_A_TEAM_CARD_INDEX);
    }
}
