import { Component, Inject } from '@angular/core';

import { EnrolledTeamMember } from '../../../interface/enrollment-team-member.model';
import { GlobalChallengeCardCarouselDataModel } from '../../../interface/global-challenges-card-carousel-data.model';
import { GlobalChallengesBlockerService } from '../../global-challenges-blocker.service';
import { MemberContestOnboardingBlockerService } from '../../member-contest-onboarding-blocker.service';
import { AuthenticatedMemberService, TabType } from '@genesis-frontend/genesis-utilities';

@Component({
    templateUrl: './add-players.template.html',
    styleUrls: ['./add-players.component.scss']
})
export class GlobalChallengesAddPlayersCardComponent {
    MIN_LETTERS = 2;
    PAGE_SIZE = 20;
    MAX_LENGTH = 40;
    MAX_PLAYERS = 15;

    emailAddress = '';
    emailRegex = /^([\w!#$%&’'*+/=?_`]|["]|[~^-])+(?:\.([\w!#$%&’'*+/=?`_]|["]|[~^-])+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,24}$/;
    emailValid = false;
    member: any;
    displayedMembers: EnrolledTeamMember[] = [];
    freeSlotsToDisplay: number | undefined;
    freeSlotsNotDisplayed: number | undefined;
    freeSlotsNumber: number | undefined;
    maxPlayersAllowed = this.data.contest.maxPlayersAllowed;
    numberOfInvitedPlayers: number | undefined;
    freeSlotsArray: any[] | undefined;
    canInviteEmail = false;
    errorMessage = '';
    checkUnenrolledInvites = true;
    errorMemberEnrolledOrInTeam = 409;
    shouldDisableAddPlayerButton = true;
    showLeftArrow = true;
    showRightArrow = false;
    searchString = '';
    TabTypeEnum = TabType;
    selectedTab: TabType = TabType.FindByName;
    unenrolledInvite!: EnrolledTeamMember;
    isFocused = false;
    isButtonClicked = false;

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private memberContestOnboardingBlockerService: MemberContestOnboardingBlockerService,
        private globalChallengesOnboardingBlockerService: GlobalChallengesBlockerService,
        @Inject('data') public data: GlobalChallengeCardCarouselDataModel
    ) {}

    ngOnInit() {
        this.member = this.authenticatedMemberService.getMember();
        this.data.isAddPlayersCardVisited = true;
        this.showRightArrow = this.data.isConfirmTeamCardVisited ? true : false;
        this.getFreeSlotsInfo();
        this.generateArrayOfFreeSlots();
        this.checkUnenrolledInvites = this.data.contest.contestSettings.inviteUnenrolledUsers;
    }

    isEmailValid() {
        this.canInviteEmail = false;
        this.unenrolledInvite = {
            emailAddress: this.emailAddress
        };
        const isMemberAdded =
            this.data.invitedPlayers.filter((invitedPlayer) => invitedPlayer.emailAddress === this.unenrolledInvite.emailAddress).length ===
            0;
        this.emailValid = this.emailRegex.test(this.emailAddress) && isMemberAdded && !this.emailAddress.includes(' ');
        this.shouldDisableAddPlayerButton = this.shouldDisableButton();
    }

    clearEmailInput() {
        this.emailAddress = '';
        this.emailValid = false;
        this.shouldDisableAddPlayerButton = this.shouldDisableButton();
        this.canInviteEmail = false;
    }

    clearSearch() {
        this.searchString = '';
    }

    addEnrolledPlayer(member: EnrolledTeamMember) {
        this.data.invitedPlayers.push(member);
        this.getFreeSlotsInfo();
        this.generateArrayOfFreeSlots();
        this.checkAddButtons();
        this.isButtonClicked = true;
    }

    addUnenrolledPlayer() {
        this.shouldDisableAddPlayerButton = true;
        this.memberContestOnboardingBlockerService.validateInviteEmailAddress(this.emailAddress, this.data.contest.contestId).subscribe(
            () => {
                this.data.invitedPlayers.push(this.unenrolledInvite);
                this.getFreeSlotsInfo();
                this.generateArrayOfFreeSlots();
                this.clearEmailInput();
            },
            (errorResponse) => {
                if (errorResponse.error.statusCode === this.errorMemberEnrolledOrInTeam) {
                    this.canInviteEmail = true;
                    this.errorMessage = errorResponse.error.message;
                }
            }
        );
    }

    removePlayerInvite(emailAddress: EnrolledTeamMember) {
        this.data.invitedPlayers.splice(this.data.invitedPlayers.indexOf(emailAddress), 1);
        this.getFreeSlotsInfo();
        this.generateArrayOfFreeSlots();
        this.checkAddButtons();
    }

    getFreeSlotsInfo() {
        this.numberOfInvitedPlayers = this.data.invitedPlayers.length + 1;
        if (this.numberOfInvitedPlayers >= this.maxPlayersAllowed) {
            this.freeSlotsToDisplay = 0;
            this.freeSlotsNotDisplayed = 0;
        } else {
            this.freeSlotsNumber = this.maxPlayersAllowed - this.numberOfInvitedPlayers;
            this.freeSlotsToDisplay = this.maxPlayersAllowed - this.numberOfInvitedPlayers;
            this.freeSlotsNotDisplayed = this.maxPlayersAllowed - this.freeSlotsToDisplay;
        }
    }

    generateArrayOfFreeSlots() {
        if (this.freeSlotsToDisplay) {
            this.freeSlotsArray = this.globalChallengesOnboardingBlockerService.generateArrayOfFreeSlots(this.freeSlotsToDisplay);
        }
    }

    hideErrorMessage() {
        this.canInviteEmail = false;
        this.errorMessage = '';
    }

    shouldDisableButton() {
        return !this.emailValid || this.numberOfInvitedPlayers === this.data.contest.maxPlayersAllowed;
    }

    isAddButtonDisabled(player: EnrolledTeamMember): boolean {
        return player?.teamId ? true : false || this.numberOfInvitedPlayers === this.data.contest.maxPlayersAllowed;
    }

    isInvitedMember(player: EnrolledTeamMember) {
        return this.data.invitedPlayers.filter((invitedPlayer) => invitedPlayer.memberId === player.memberId).length > 0;
    }

    selectTab(selectedTab: TabType) {
        this.selectedTab = selectedTab;
    }

    checkAddButtons() {
        this.displayedMembers.forEach((member) => {
            member.disableButton = this.isAddButtonDisabled(member);
            member.isInvited = this.isInvitedMember(member);
        });
    }

    getPlayers() {
        if (this.searchString.length > this.MIN_LETTERS) {
            this.isFocused = true;
            this.memberContestOnboardingBlockerService
                .getMembersByName(this.member.sponsorId, this.data.contest.contestId, this.searchString, true, this.PAGE_SIZE)
                .subscribe((response) => {
                    this.displayedMembers = response;
                    this.checkAddButtons();
                });
        } else {
            this.displayedMembers = [];
        }
    }

    setSearchFocus() {
        this.isFocused = true;
    }

    removeSearchFocus() {
        if (!this.isButtonClicked) {
            this.isFocused = false;
        }
        this.isButtonClicked = false;
    }
}
