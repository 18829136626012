import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NotificationsMessengerService {

    subscriptions: any = [];

    showNotification(notification: any) {
        this.subscriptions.forEach((callback: any) => {
            callback(notification);
        });
    }

    subscribe(callback: any) {
        this.subscriptions.push(callback);
    }

    unsubscribe(callback: any) {
        this.subscriptions = this.subscriptions.filter((subscription: any) => subscription !== callback);
    }
}
