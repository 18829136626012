import { Component, HostBinding } from '@angular/core';

@Component({
    selector: 'genesis-frontend-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
    @HostBinding('class')
    get classNames(): string {
        return 'flex align-items-center justify-content-center';
    }
}
