export enum SurveyTypes {
    HeartAge = 'HeartAge',
    Lifestyle = 'Lifestyle',
    HeartAgeAndLifestyle = 'HeartAgeAndLifestyle',
    Custom = 'Custom',
    QuickSurvey = 'QuickSurvey',
    HealthCheck = 'MyHealthView',
    CultureCheck = 'CultureCheck',
    CultureCheckCustom = 'CultureCheckCustom',
    TransformDPP = 'TransformDPP',
    TransformDPPWM = 'TransformDPPWM',
    HeartAgeCustom = 'HeartAgeCustom',
    LifestyleCustom = 'LifestyleCustom',
    HeartAgeAndLifestyleCustom = 'HeartAgeAndLifestyleCustom',
    MentalWellbeing = 'MentalWellbeing',
    MentalWellbeingCustom = 'MentalWellbeingCustom',
    MyHealthView = 'MyHealthView',
    MyHealthViewCustom = 'MyHealthViewCustom',
    NPSSurvey = 'NPSSurvey',
    NPSSurveyCustom = 'NPSSurveyCustom'
}
