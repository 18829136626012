import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ClaimsState } from './models/claim-state.model';
import { InsuranceClaimVM } from './models/insurance-claim-vm.model';
import { UIState } from './models/ui-state.model';
import { ClaimsCardService } from './services/claims-card.service';

@Component({
    selector: 'genesis-frontend-claims-card',
    templateUrl: './claims-card.component.html'
})
export class ClaimsCardComponent {

    vm$: Observable<UIState<InsuranceClaimVM>>;
    claimsState: ClaimsState = ClaimsState.noClaims;

    constructor(
        private readonly claimsCardService: ClaimsCardService
    ) {
        this.vm$ = this.claimsCardService.fetch().pipe((tap((response) => {
            this.claimsState = response.data?.claimsState || ClaimsState.noClaims;
        })));
    }
}
