import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

import { AuthenticatedMemberService } from '@genesis-frontend/genesis-utilities';


@Injectable({
    providedIn: 'root'
})
export class WalkmeService {

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService) {
    }

    init() {
        const authMember = this.authenticatedMemberService.getMember();
        const memberSettings = this.authenticatedMemberService.getMemberSettings();
        const sponsor = this.authenticatedMemberService.getSponsor();
        const sponsorSettings = this.authenticatedMemberService.getSponsorSettings();
        const memberFeatures = this.authenticatedMemberService.getMemberfeatures();
        this.setupWalkmeVariable(authMember, memberSettings, sponsor, sponsorSettings, memberFeatures);
        this.loadWalkmeScript();
        this.getCurrentLangaugeCodeAndChangeLanguage(authMember?.language);
    }

    private setupWalkmeVariable(authMember: any, memberSettings: any, sponsor: any, sponsorSettings: any, memberFeatures: any) {
        (window as any).walkme_site_data = {
            memberId: authMember?.id,
            sponsorId: sponsor?.id,
            hasGoalSettingModule: memberFeatures.hasGoalSettingModule,
            friendsModule: memberFeatures.friendsModule,
            showPersonalChallenges: memberFeatures.showPersonalChallenges,
            hasTrackerChallenges: memberFeatures.hasTrackerChallenges,
            hasManualWorkoutRewards: sponsorSettings.hasManualWorkoutRewards,
            hasGroupsModule: memberFeatures.hasGroupsModule,
            hasSeenGoalSetting: memberSettings?.hasSeenGoalSetting,
            createdDate: authMember?.createdDate
        };
    }

    private getCurrentLangaugeCodeAndChangeLanguage(authMemberLanguage: any) {
        if (authMemberLanguage === 'en_us') {
            this.changeWalkMeLanguage('');
            return;
        }

        const languages = this.authenticatedMemberService.getLanguages();
        languages.forEach((language: { name: any; code: any; }) => {
            if (authMemberLanguage === language.name) {
                this.changeWalkMeLanguage(language.code);
            }
        });
    }

    private changeWalkMeLanguage(language: any) {
        (window as any).walkme_ready = function() {
            (window as any).WalkMeAPI.changeLanguage(language);
        };
    }

    private loadWalkmeScript() {
        const walkmeScript = document.createElement('script');

        walkmeScript.type = 'text/javascript';
        walkmeScript.async = true;
        walkmeScript.src = environment.walkme.sourceurl;

        const firstScript = document.getElementsByTagName('script')[0];
        firstScript.parentNode?.insertBefore(walkmeScript, firstScript);
        (window as any)._walkmeConfig = {
            smartLoad: true
        };
    }

}
