import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticatedMemberService, Member, MemberAgreement } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class MemberAgreementService {
    member: Member;

    constructor(
        private httpClient: HttpClient,
        private authenticatedMemberService: AuthenticatedMemberService
    ) {
        this.member = this.authenticatedMemberService.getAuthenticatedMember();
    }

    getMemberAgreements(): Observable<MemberAgreement[]> {
        return this.httpClient.get<MemberAgreement[]>(`/agreements-api/members/${this.member.id}/agreements`);
    }
}
