import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';

import { AgreementModalService } from '../../agreement-modal/agreement-modal.service';
import { MemberAgreementService } from '@genesis-frontend/genesis-data-access';
import { AgreementTypes, MemberAgreement } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class PrivacyProtectionModalService {
    private privacyAgreement: MemberAgreement = {} as MemberAgreement;
    constructor(
      private agreementModalService: AgreementModalService,
      private memberAgreementService: MemberAgreementService
    ) {
        this.memberAgreementService.getMemberAgreements().subscribe((agreements) => {
            this.privacyAgreement = agreements.find((agreement) => agreement.type === AgreementTypes.PrivacyPolicy) as MemberAgreement;
        });
    }

    open(): Observable<BsModalService> {
        return this.agreementModalService.openModal(this.privacyAgreement);
    }
}
