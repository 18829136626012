import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { BoardRewardVM } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class ChallengeInvitesApiService {

    constructor(private httpClient: HttpClient) { }

    getMemberChallengeRewardInfo(sponsorId: number, memberId: number, rewardableActionType: string): Observable<BoardRewardVM> {
        return this.httpClient.get<BoardRewardVM>(this.memberChallengeRewardDataResource(sponsorId, memberId, rewardableActionType));
    }

    private memberChallengeRewardDataResource = (sponsorId: number, memberId: number, rewardableActionType: string) =>
        `/api/genesis-rewards/sponsors/${sponsorId}/members/${memberId}/rewardable-action?rewardableActionType=${rewardableActionType}`;

}
