import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    templateUrl: './maximum-trackers-added.component.html'
})
export class MaximumTrackersAddedModalComponent {
    constructor(
        public bsModalRef: BsModalRef
    ) { }
}
