import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';

import { JourneyCompletedModalComponent } from './journey-completed-modal.component';
import { JourneysCompletedModalService } from './journeys-completed-modal.service';
import { VpgrooveModule } from '@engineering/genesis-frontend';
import { GenesisDirectivesModule, GenesisPipesModule } from '@genesis-frontend/genesis-utilities';

@NgModule({
    declarations: [
        JourneyCompletedModalComponent
    ],
    providers: [
        BsModalService,
        JourneysCompletedModalService
    ],
    exports: [],
    imports: [
        CommonModule,
        GenesisDirectivesModule,
        TranslateModule,
        ModalModule.forRoot(),
        GenesisPipesModule,
        VpgrooveModule
    ]
})
export class JourneysCompletedModalModule { }
