export class PreviewParameters {
    currentPage: string | null;
    gameId: number | null;
    isRewardsPreview: boolean | null;
    populationId: number | null;
    previewDate: string | null;
    sponsorId: number | null;

    constructor(
        currentPage: string | null,
        gameId: number | null,
        isRewardsPreview: boolean | null,
        populationId: number | null,
        previewDate: string | null,
        sponsorId: number | null
    ) {
        this.currentPage = currentPage;
        this.gameId = gameId;
        this.isRewardsPreview = isRewardsPreview;
        this.populationId = populationId;
        this.previewDate = previewDate;
        this.sponsorId = sponsorId;
    }
}
