import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';

import { AgreementModalComponent } from './agreement-modal.component';
import { AgreementModalService } from './agreement-modal.service';
import { GenesisDirectivesModule } from '@genesis-frontend/genesis-utilities';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        GenesisDirectivesModule,
        ModalModule.forRoot()
    ],
    declarations: [
        AgreementModalComponent
    ],
    providers: [
        BsModalService,
        AgreementModalService
    ],
    exports: [
    ]
})
export class AgreementModalModule { }
