import { Injectable } from '@angular/core';

import { KeyCode, KeyName } from '../models';

@Injectable({
    providedIn: 'root'
})
export class KeycodeService {
    keycode: any;
    keyName: any;

    constructor() {
        this.keycode = KeyCode;
        this.keyName = KeyName;
    }

    keycodeArray(stringArray: string[]) {
        const codeArray: any[] = [];
        stringArray.forEach((keycode: any) => {
            codeArray.push(KeyCode[keycode]);
        });
        return codeArray;
    }

    isKeyEventNumber(event: any) {
        const key = event.which;
        return (key >= KeyCode.numpad0 && key <= KeyCode.numpad9)
            || (key >= KeyCode.n0 && key <= KeyCode.n9);
    }

    isKeyEventControllingKey(event: any) {
        const key = event.which;
        return key === KeyCode.leftarrow
            || key === KeyCode.rightarrow
            || key === KeyCode.uparrow
            || key === KeyCode.downarrow
            || key === KeyCode.enter
            || key === KeyCode.backspace
            || key === KeyCode.delete;
    }
}
