import { Component, Inject } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

import { GlobalChallengeCardCarouselDataModel } from '../../../interface/global-challenges-card-carousel-data.model';
import { ContestType } from '@genesis-frontend/genesis-utilities';

@Component({
    templateUrl: './order-confirmation.template.html',
    styleUrls: ['./order-confirmation.component.scss']
})
export class GlobalChallengesMaxBuzzOrderConfirmationCardComponent {
    TEAM_TAB = 'myTeam';

    constructor(
        @Inject('data') public data: GlobalChallengeCardCarouselDataModel,
        private bsModalRef: BsModalRef
    ) {}

    goNext() {
        if (this.data.getMaxBuzzModal) {
            this.bsModalRef.hide();
        }

        if (this.data.contest.contestType === ContestType.Organizational) {
            this.bsModalRef.hide();
            window.location.href = `${window.location.origin}/#/featuredchallenge/${this.data.contest.contestId}`;
            this.data.finishedCallback();
        }

        if ((this.data.contest.contestType === ContestType.Team && !this.data.contest.destination) || this.data.contest.destination) {
            this.goToChallengePage(this.data.contest.contestId);
        }
    }

    goToChallengePage(contestId: number) {
        this.bsModalRef.hide();
        window.location.href = `${window.location.origin}/#/featuredchallenge/${contestId}`;
        this.data.finishedCallback();
    }
}
