import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    CoverPhoto,
    TeamPhoto
} from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class BackgroundImagesService {
    constructor(
        private httpClient: HttpClient
    ) { }

    getBackgroundImages(sponsorId: number): Observable<CoverPhoto[]> {
        return this.httpClient.get<CoverPhoto[]>(`/api/sponsors/${sponsorId}/cover-photos`);
    }

    getTeamPhotos(): Observable<TeamPhoto[]> {
        return this.httpClient.get<TeamPhoto[]>('/api/team-photos');
    }
}
