import { NgModule } from '@angular/core';

import { AnchorWcagDirective } from './anchor-wcag.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { IntlTelInputDirective } from './intl-tel-input.directive';
import { ModalTrapFocusDirective } from './modal-focus-trap.directive';
import { OnlyOneDecDirective } from './only-one-dec.directive';
import { RtlLanguagesDirective } from './rtl-languages.directive';
import { ScrollerDirective } from './scroller.directive';
import { WcagClickDirective } from './wcag-click.directive';
import { WcagCTADirective } from './wcagCTA.directive';

@NgModule({
    declarations: [
        WcagClickDirective,
        RtlLanguagesDirective,
        ClickOutsideDirective,
        WcagCTADirective,
        ModalTrapFocusDirective,
        OnlyOneDecDirective,
        AnchorWcagDirective,
        IntlTelInputDirective,
        ScrollerDirective
    ],
    exports: [
        WcagClickDirective,
        RtlLanguagesDirective,
        ClickOutsideDirective,
        WcagCTADirective,
        ModalTrapFocusDirective,
        OnlyOneDecDirective,
        AnchorWcagDirective,
        IntlTelInputDirective,
        ScrollerDirective
    ]
})
export class GenesisDirectivesModule {}
