import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';

import { DeviceProviderType } from '../../../enum/device-provider-type.enum';
import { DeviceApplication } from '../../../interface/device-application.model';
import { GlobalChallengeCardCarouselDataModel } from '../../../interface/global-challenges-card-carousel-data.model';
import { MemberDeviceApplication } from '../../../interface/member-device-application.model';
import { MemberContestOnboardingBlockerService } from '../../member-contest-onboarding-blocker.service';
import { VpGoService } from '../../vp-go.service';
import { ShellRouter } from '@genesis-frontend/genesis-utilities';

@Component({
    templateUrl: './connect-device.component.html',
    styleUrls: ['./connect-device.component.scss']
})
export class GlobalChallengesConnectDeviceCardComponent implements OnInit, AfterViewInit {
    allowedProviderTypes: string[] = [
        DeviceProviderType.Fitbit,
        DeviceProviderType.Strava,
        DeviceProviderType.Misfit,
        DeviceProviderType.Garmin
    ];
    providerTypesLogos: any = {
        [DeviceProviderType.Fitbit]: 'connect-device-fitbit.png',
        [DeviceProviderType.Strava]: 'connect-device-strava.png',
        [DeviceProviderType.Misfit]: 'connect-device-misfit.png',
        [DeviceProviderType.Garmin]: 'connect-device-garmin.png'
    };
    allowedApplications: any = {};

    showLeftArrow = false;

    @ViewChild('goToDevicesAndAppsParent') goToDevicesAndAppsParent!: ElementRef;

    constructor(
        @Inject('data') public data: GlobalChallengeCardCarouselDataModel,
        private memberContestOnboardingBlockerService: MemberContestOnboardingBlockerService,
        public vpGoService: VpGoService,
        private shellRouter: ShellRouter
    ) {}

    ngOnInit(): void {
        this.showLeftArrow = this.data.contest.contestSettings.freeMaxBuzz;
        this.setUpMemberApplications();
    }

    ngAfterViewInit(): void {
        this.goToDevicesAndAppsParent.nativeElement.querySelector('a').addEventListener('click', this.resolveBlocker.bind(this));
    }

    setUpMemberApplications() {
        if (this.data.memberId) {
            this.memberContestOnboardingBlockerService.getAvailableDevicesForMember(this.data.memberId).subscribe((memberApplications) => {
                if (memberApplications) {
                    memberApplications.forEach((memberApplication) => {
                        if (this.allowedProviderTypes.includes(memberApplication.application.providerType)) {
                            this.setSyncUrlForMemberApplication(memberApplication);
                            this.allowedApplications[memberApplication.application.providerType] = memberApplication.application;
                        }
                    });
                }
            });
        }
    }

    setSyncUrlForMemberApplication(app: MemberDeviceApplication) {
        if (this.data?.memberId && app && !app.connected && app.application && app.application.authType !== 'NOPAIR') {
            this.memberContestOnboardingBlockerService
                .getApplicationSyncURL(this.data.memberId, app.application.id)
                .subscribe((response) => {
                    app.application.syncURL = response;
                });
        }
    }

    redirectToConnectPage(application: DeviceApplication) {
        window.open(application.syncURL, '_blank');
    }

    resolveBlocker() {
        this.shellRouter.navigate('/devices');
        this.data.closeModal();
    }

    goToChallenges() {
        this.shellRouter.navigate(`/featuredchallenge/${this.data.contest.contestId}`);
        this.data.closeModal();
    }

    goToPreviousCard() {
        this.data.goToCard(this.vpGoService.VpGoCards.DEVICE_ORDERING_INDEX);
    }
}
