import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { JourneySurveyModalComponent } from './journey-survey-modal.component';


@Injectable({ providedIn: 'root' })
export class JourneySurveyModalService {

    constructor(private modalService: BsModalService) { }

    showJourneySurveyModal(journeyId: number, journeyTitle: string) {
        const initialState = { journeyId, journeyTitle };
        return this.modalService.show(JourneySurveyModalComponent, { initialState, class: 'journey-survey-modal', animated: false });
    }
}
