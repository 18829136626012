import { Injectable } from '@angular/core';
import Keycloak from 'keycloak-js';
import { ReplaySubject } from 'rxjs';

import { KeycloakService } from './keycloak.service';
import { LoginService } from './login.service';
import { environment } from '../config/environments/environment';

interface AuthSetupOptions {
    checkSso?: boolean;
    redirectUri?: string;
}

@Injectable({ providedIn: 'root' })
export class AuthSetup {
    config: any = {
        url: `${environment.authServer.baseUrl}/auth`,
        realm: `${environment.authServer.realm}`,
        clientId: `${environment.authServer.clientId}`,
        provider: 'keycloak'
    };

    initialized = false;
    subject = new ReplaySubject();

    constructor(
        private loginService: LoginService,
        private authService: KeycloakService
    ) {
        this.config.keycloakIdentityProvider = this.getQueryVariable('kc_idp_hint');
        this.config.keycloakLocalHint = this.getQueryVariable('kc_locale_hint');
    }

    keycloakBootstrap(options: AuthSetupOptions) {

        setTimeout(() => {
            const email = this.loginService ? this.loginService.getEmail() : null;

            if (email) {
                this.loginService.getMembersIdentity(email).toPromise().then((identity) => {
                    if (identity && identity['usernameOrEmail'] && email.toLowerCase() === identity['usernameOrEmail'].toLowerCase()) {
                        if (!this.loginService.removeEmailParam(email)) {
                            window.location.reload();
                        }
                        return;
                    }
                    if (!this.initialized) {
                        this.initialized = true;
                        this.subject.next();
                    }
                    return;
                });
                return;
            }

            // @ts-ignore
            const keycloakInstance: Keycloak.KeycloakInstance = new Keycloak(this.config);
            (keycloakInstance as any)['$vpProvider'] = this.config.provider;
            if (options.checkSso) {

                keycloakInstance.init({ onLoad: 'check-sso' }).then((authenticated: any) => {
                    this.keycloakCheckSsoSuccess(authenticated, keycloakInstance);
                });

            } else {
                const initOptions: Keycloak.KeycloakInitOptions = process.env.WEBPACK_DEV_SERVER ? { checkLoginIframe: false } : {};

                keycloakInstance.init(initOptions).then((authenticated: any) => {
                    this.keycloakSuccess(authenticated, keycloakInstance);
                }).catch(() => {
                    this.keycloakFail(keycloakInstance);
                });
            }
        });

        return this.subject;
    }

    private keycloakCheckSsoSuccess(
        authenticated: boolean,
        keycloakInstance: Keycloak.KeycloakInstance
    ) {
        if (authenticated) {
            this.authService.setClient(keycloakInstance);
        }

        if (!this.initialized) {
            this.initialized = true;
            this.subject.next();
        }
    }

    private keycloakSuccess(
        authenticated: boolean,
        keycloakInstance: Keycloak.KeycloakInstance
    ) {
        this.authService.setClient(keycloakInstance);
        if (authenticated) {
            if (!this.initialized) {
                this.initialized = true;
                this.subject.next();
            }
            return;
        }

        if (this.config.keycloakIdentityProvider) {
            window.location.href = keycloakInstance.createLoginUrl({ idpHint: this.config.keycloakIdentityProvider });
        } else {
            keycloakInstance.login({
                locale: this.config.keycloakLocalHint
            });
        }
    }

    private keycloakFail(keycloakInstance: Keycloak.KeycloakInstance) {
        keycloakInstance.login({
            locale: this.config.keycloakLocalHint
        });
    }

    private getQueryVariable(variableName: string) {
        const query = window.location.search.substring(1);
        const vars = query.split('&');

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < vars.length; i++) {
            const pair = vars[i].split('=');
            if (pair[0].toLowerCase() === variableName.toLowerCase()) {
                return pair[1];
            }
        }

        return this.getParameterByName(variableName);
    }

    private getParameterByName(name: string) {
        const url = window.location.href;
        const encodedName = name.replace(/[[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + encodedName + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);

        if (!results) {
            return null;
        }
        const thirdParameter = 2;
        if (!results[thirdParameter]) {
            return '';
        }
        return decodeURIComponent(results[thirdParameter].replace(/\+/g, ' '));
    }
}
