import { Injectable } from '@angular/core';

import { Profile } from '../graphql';

export enum UserType {
    Guest,
    Member,
    Admin
}

@Injectable()
export class CurrentUser {

    static Token = 'CurrentUser';

    userType: UserType = UserType.Guest;
    profile?: Profile;
    adminProfile?: unknown;

    assignProfile(profile: Profile) {
        this.userType = UserType.Member;
        this.profile = profile;
    }

    assignAdminProfile(profile: unknown) {
        this.userType = UserType.Admin;
        this.adminProfile = profile;
    }

    isAuthenticated(): boolean {
        return !this.isGuest();
    }

    isGuest(): boolean {
        return this.userType === UserType.Guest;
    }

    isMember(): boolean {
        return this.userType === UserType.Member;
    }

    isAdmin(): boolean {
        return this.userType === UserType.Admin;
    }
}
