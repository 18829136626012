import { Injectable } from '@angular/core';

import { MemberRole } from '../models';
import { GlobalReduxStore } from '../state-management';

@Injectable({
    providedIn: 'root'
})
export class AuthenticatedMemberRoleService {
    private memberRoles: MemberRole[] = [];

    constructor(private globalReduxStore: GlobalReduxStore) {
        this.initialize();
    }

    initialize() {
        const initialState = this.globalReduxStore.getInitialState();
        if (!initialState.memberRoles) {
            return;
        }
        this.memberRoles = initialState.memberRoles as MemberRole[];
    }

    hasRole(role: MemberRole) {
        return this.memberRoles.includes(role);
    }

    isAuthorizedForRewardsPreview() {
        return this.memberRoles.some((role) => role === MemberRole.RootAdmin || role === MemberRole.ReadOnlyRewards);
    }

    isAuthorizedForRewardsFileDownload() {
        return this.memberRoles.some((role) => role === MemberRole.RootAdmin || role === MemberRole.ReadOnlyRewards || role === MemberRole.ConfigurationReadOnly);
    }
}
