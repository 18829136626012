export enum ThriveCategoryType {
    GettingActive = 'GettingActive',
    ReducingStress = 'ReducingStress',
    SleepingWell = 'SleepingWell',
    LearningNewThings = 'LearningNewThings',
    BuildingRelationships = 'BuildingRelationships',
    ContributingToMyCommunity = 'ContributingToMyCommunity',
    EatingHealthy = 'EatingHealthy',
    BeingProductive = 'BeingProductive',
    ManagingMyFinances = 'ManagingMyFinances'
}
