<div class="global-challenges-add-players-container">
    <div class="challenge-onboarding-blocker-modal-content">
        <div
            class="add-players-card-title text-clr-oxford-blue"
            [translate]="'AddPlayers'">
            Add Players
        </div>
        <div
            class="add-players-subtitle text-clr-oxford-blue"
            [translate]="'InviteAFewFriendsOrSkip'">
            Invite a few friends to join your team (or skip this step)
        </div>
        <div
            class="add-friends-tabs"
            *ngIf="data.contest.contestSettings && data.contest.contestSettings.inviteUnenrolledUsers">
            <ul class="tabs-list">
                <li
                    aria-controls="find-by-name-panel"
                    role="tab"
                    class="vp-trim-info"
                    id="find-by-name-tab"
                    [translate]="'FindByName'"
                    [ngClass]="{ 'active': selectedTab === TabTypeEnum.FindByName}"
                    (click)="selectTab(TabTypeEnum.FindByName)">
                    Find by Name
                </li>
                <li
                    aria-controls="invite-friends-panel"
                    role="tab"
                    class="vp-trim-info"
                    id="invite-friends-tab"
                    class="vp-trim-info"
                    [translate]="'InviteByEmail'"
                    *ngIf="checkUnenrolledInvites"
                    [ngClass]="{ 'active': selectedTab === TabTypeEnum.InviteFriends }"
                    (click)="selectTab(TabTypeEnum.InviteFriends)">
                    Invite by Email
                </li>
            </ul>
        </div>
        <hr class="border-style" />
    </div>
    <div
        class="card-middle"
        [ngClass]="{'vp-margin-top-30': data.contest.contestSettings && !data.contest.contestSettings.inviteUnenrolledUsers}">
        <div
            class="search-list-container"
            tabindex="1"
            [ngClass]="{'search-background': displayedMembers.length  && selectedTab === TabTypeEnum.FindByName && isFocused}"
            (clickOutside)="removeSearchFocus()">
            <div
                class="add-players-form"
                *ngIf="selectedTab !== TabTypeEnum.InviteFriends">
                <div
                    [ngClass]="{'parent-shadow': canInviteEmail}"
                    class="parent background-clr-white">
                    <div
                        class="add-players-input border-clr-bg"
                        aria-hidden="false"
                        tabindex="0">
                        <input
                            class="background-clr-transparent"
                            type="search"
                            aria-hidden="true"
                            tabindex="-1"
                            [maxLength]="MAX_LENGTH"
                            (focus)="setSearchFocus()"
                            [placeholder]="'SearchByName' | translate"
                            [(ngModel)]="searchString"
                            (input)="getPlayers()"
                            (keydown.backspace)="hideErrorMessage()" />
                        <i
                            class="far fa-times-circle text-clr-gray-chateau"
                            *ngIf="searchString.length"
                            (click)="clearSearch()"></i>
                    </div>
                </div>
            </div>
            <div
                class="search-result-container"
                *ngIf="displayedMembers.length && selectedTab === TabTypeEnum.FindByName  && isFocused">
                <div
                    class="search-result"
                    *ngFor="let player of displayedMembers">
                    <img
                        class="border-clr-bg profile-image"
                        [src]="player.profilePicture ? (player.profilePicture | compressFilePickerImage: 200) : '/img/navigation/DefaultAvatar.png'" />
                    <div
                        class="player-name"
                        *ngIf="!player.teamId">
                        {{player.firstName}} {{player.lastName}}
                    </div>
                    <div
                        class="player-name text-center"
                        *ngIf="player.teamId"
                        [innerHTML]="'OnTeam' | translate: {name: player.firstName + ' ' + player.lastName}">
                        {{player.firstName}} {{player.lastName}} has already joined a team.
                    </div>
                    <button
                        class="btn add-player-btn"
                        type="button"
                        *ngIf="!player.isInvited"
                        (click)="addEnrolledPlayer(player)"
                        [disabled]="player.disableButton">
                        <span
                            [ngClass]="{'text-clr-gray-icons': player.disableButton, 'vp-button-primary-theme-tag': !player.disableButton}"
                            class="fa-stack fa-lg">
                            <i class="far fa-circle fa-stack-2x"></i>
                            <i class="fa fa-plus fa-stack-1x"></i>
                        </span>
                    </button>
                    <img
                        [src]="'/img/Green-Check-Mark.png'"
                        class="add-item-wrapper"
                        *ngIf="player.isInvited" />
                </div>
            </div>
        </div>
        <div
            class="add-email-form"
            *ngIf="selectedTab !== TabTypeEnum.FindByName">
            <div
                [ngClass]="{'parent-shadow': canInviteEmail}"
                class="parent background-clr-white">
                <div
                    class="add-players-input border-clr-bg"
                    aria-hidden="false"
                    tabindex="0">
                    <input
                        class="background-clr-transparent"
                        type="search"
                        aria-hidden="true"
                        tabindex="-1"
                        [placeholder]="'EnterWorkEmailAddress' | translate"
                        [(ngModel)]="emailAddress"
                        (input)="isEmailValid()"
                        (keydown.space)="$event.preventDefault()"
                        (keydown.backspace)="hideErrorMessage()" />
                    <i
                        class="far fa-times-circle text-clr-gray-chateau"
                        *ngIf="emailAddress.length"
                        (click)="clearEmailInput()"></i>
                </div>
                <hr
                    *ngIf="canInviteEmail"
                    class="border-clr-gainsboro" />
                <span
                    *ngIf="canInviteEmail"
                    class="email-address-error-message background-clr-white text-clr-mine-shaft">
                    {{errorMessage}}
                </span>
            </div>
            <button
                class="btn add-players-button"
                type="button"
                (click)="addUnenrolledPlayer()"
                [disabled]="shouldDisableAddPlayerButton">
                <span
                    [ngClass]="{'text-clr-gray-icons': shouldDisableAddPlayerButton, 'vp-button-primary-theme-tag': !shouldDisableAddPlayerButton}"
                    class="fa-stack fa-lg">
                    <i class="far fa-circle fa-stack-2x"></i>
                    <i class="fa fa-plus fa-stack-1x"></i>
                </span>
            </button>
        </div>
        <div class="add-players-teammates">
            <div [ngClass]="{'set-middle-icon': selectedTab === TabTypeEnum.FindByName}">
                <div
                    class="add-players-icons"
                    [ngClass]="{'add-players-icons-big-team': data.contest.maxPlayersAllowed>MAX_PLAYERS}">
                    <div class="teammates-slot-style">
                        <img
                            class="vp-go-teammate-image popover-trigger border-clr-bg"
                            [src]="member.profilepicture | compressFilePickerImage: 200"
                            [popoverTitle]="member.displayName"
                            [popover]="'TeamCaptain' | translate "
                            triggers="hover focus"
                            placement="top"
                            role="tooltip" />
                    </div>
                    <div
                        class="teammates-slot-style"
                        *ngFor="let teamMember of data.invitedPlayers">
                        <img
                            class="vp-go-teammate-image popover-trigger border-clr-bg"
                            [src]="teamMember.profilePicture ? (teamMember.profilePicture | compressFilePickerImage: 200) : '/img/vp-go/invited-player.png'"
                            [popoverTitle]="teamMember?.memberId ? teamMember?.firstName + ' ' + teamMember?.lastName : '' + teamMember?.emailAddress"
                            [popover]="'Pending' | translate "
                            triggers="hover focus"
                            placement="top"
                            role="tooltip" />
                            <i class="vp-go-teammate-remove far fa-times-circle"
                               (click)="removePlayerInvite(teamMember)">
                            </i>
                    </div>
                    <div
                        class="teammates-slot-style"
                        *ngFor="let freeslot of freeSlotsArray">
                        <img
                            class="vp-go-teammate-image border-clr-bg"
                            [src]="'/img/empty-player.png'" />
                    </div>
                </div>
            </div>
            <div
                *ngIf="numberOfInvitedPlayers !== data.contest.maxPlayersAllowed"
                class="teammates-added-copy">
                <div
                    class="text-clr-medium-gray"
                    [innerHTML]="'NumberOfPlayersAdded' | translate: {numberOfInvitedPlayers: numberOfInvitedPlayers, maxPlayersAllowed: maxPlayersAllowed}">
                    You’ve added 1 of 7 teammates. You can add more later, if needed.
                </div>
            </div>
            <div
                *ngIf="numberOfInvitedPlayers  === data.contest.maxPlayersAllowed"
                class="teammates-added-copy">
                <div
                    class="text-clr-medium-gray"
                    [innerHTML]="'MaximumPlayersAdded' | translate: {maxPlayersAllowed: maxPlayersAllowed}">
                    You’ve added 7 of 7 teammates. Great!
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer">
        <div class="global-challenge-onboarding-button">
            <button
                class="vp-button larger vp-button-primary"
                (click)="this.data.nextCard()"
                [translate]="'Next'"></button>
        </div>
    </div>
    <div
        class="prev-card-btn background-clr-bg background-dark text-clr-white"
        *ngIf="showLeftArrow"
        (click)="this.data.prevCard()"
        role="button"
        tabindex="0"
        [attr.aria-label]="'previous' | translate">
        <i class="fa fa-angle-left"></i>
    </div>
    <div
        class="next-card-btn background-clr-bg background-dark text-clr-white"
        *ngIf="showRightArrow"
        (click)="this.data.nextCard()"
        role="button"
        tabindex="0"
        [attr.aria-label]="'next' | translate">
        <i class="fa fa-angle-right"></i>
    </div>
</div>
