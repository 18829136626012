import chart_zh_cn from '@amcharts/amcharts4/lang/cs_CZ';
import chart_de_de from '@amcharts/amcharts4/lang/de_DE';
import chart_en_gb from '@amcharts/amcharts4/lang/en';
import chart_en_us from '@amcharts/amcharts4/lang/en_US';
import chart_es_mx from '@amcharts/amcharts4/lang/es_ES';
import chart_es_es from '@amcharts/amcharts4/lang/es_ES';
import chart_fr_fr from '@amcharts/amcharts4/lang/fr_FR';
import chart_fr_ca from '@amcharts/amcharts4/lang/fr_FR';
import chart_hi_in from '@amcharts/amcharts4/lang/hi_IN';
import chart_it_it from '@amcharts/amcharts4/lang/it_IT';
import chart_ja_jp from '@amcharts/amcharts4/lang/ja_JP';
import chart_ko_kr from '@amcharts/amcharts4/lang/ko_KR';
import chart_nl_nl from '@amcharts/amcharts4/lang/nl_NL';
import chart_pl_pl from '@amcharts/amcharts4/lang/pl_PL';
import chart_pt_br from '@amcharts/amcharts4/lang/pt_BR';
import chart_ru_ru from '@amcharts/amcharts4/lang/ru_RU';
import chart_sv_se from '@amcharts/amcharts4/lang/sv_SE';
import chart_th_th from '@amcharts/amcharts4/lang/th_TH';
import chart_vi_vn from '@amcharts/amcharts4/lang/vi_VN';
import chart_zh_hant from '@amcharts/amcharts4/lang/zh_Hant';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticatedMemberService } from '../../authentication';
import { UnitOfMeasurement,
    Language,
    StatType,
    GraphActivityModel,
    StackedGraphStatDataModel,
    GraphTemplates,
    VPGColors } from '../../models';
import { AmChartsService } from '../charts';

@Injectable({
    providedIn: 'root'
})
export class StatGraphConfigService {
    xAxisSpacing = 0;
    yAxisTitle = '';
    goalValue = 0;
    graphTemplate = '';
    statGraphData: any = undefined;
    tooltipValue = '';
    routeType: string | null = '';
    isYearView = false;
    isEmptyGraph = true;
    stacked = false;
    baseLineSeriesDataFields = {
        categoryX: 'day',
        dateX: 'day',
        valueY: 'value'
    };
    xAxis: any;
    yAxis: any;

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private translateService: TranslateService,
        private amChartsService: AmChartsService
    ) { }

    getGraphLanguage(memberLanguage: Language | undefined): any {
        switch (memberLanguage) {
        case Language.en_us:
            return chart_en_us;
        case Language.es_mx:
            return chart_es_mx;
        case Language.pt_br:
            return chart_pt_br;
        case Language.fr_fr:
            return chart_fr_fr;
        case Language.de_de:
            return chart_de_de;
        case Language.es_es:
            return chart_es_es;
        case Language.ru_ru:
            return chart_ru_ru;
        case Language.vi_vn:
            return chart_vi_vn;
        case Language.zh_cn:
            return chart_zh_cn;
        case Language.ja_jp:
            return chart_ja_jp;
        case Language.en_gb:
            return chart_en_gb;
        case Language.fr_ca:
            return chart_fr_ca;
        case Language.pl_pl:
            return chart_pl_pl;
        case Language.zh_hant:
            return chart_zh_hant;
        case Language.it_it:
            return chart_it_it;
        case Language.sv_se:
            return chart_sv_se;
        case Language.ko_kr:
            return chart_ko_kr;
        case Language.nl_nl:
            return chart_nl_nl;
        case Language.th_th:
            return chart_th_th;
        case Language.hi_in:
            return chart_hi_in;
        default:
            return chart_en_us;
        }
    }

    generateGraphConfig(
        xAxisSpacing: number,
        yAxisTitle: string,
        goalValue: number,
        graphTemplate: string,
        graphData: any,
        tooltipValue: string,
        routeType: string,
        isYearView: boolean,
        isEmptyGraph: boolean,
        stacked: boolean,
        legendDisabled: boolean
    ) {
        this.xAxisSpacing = xAxisSpacing;
        this.yAxisTitle = yAxisTitle;
        this.goalValue = goalValue;
        this.graphTemplate = graphTemplate;
        this.statGraphData = graphData;
        this.tooltipValue = tooltipValue;
        this.routeType = routeType;
        this.isYearView = isYearView;
        this.isEmptyGraph = isEmptyGraph;
        this.stacked = stacked;
        this.xAxis = {
            type: this.isYearView ? 'DateAxis' : 'CategoryAxis',
            renderer: {
                grid: {
                    template: {
                        location: 0,
                        disabled: true
                    }
                },
                labels: {
                    fontSize: 15
                },
                minGridDistance: this.xAxisSpacing
            },
            dataFields: {
                category: 'day'
            },
            dateFormats: {
                month: 'MMM'
            },
            periodChangeDateFormats: {
                month: 'MMM'
            }
        };
        this.yAxis = {
            type: 'ValueAxis',
            min: 0,
            max: this.isEmptyGraph ? 1 : undefined,
            renderer: {
                labels: {
                    fontSize: 15
                }
            },
            title: {
                text: this.yAxisTitle,
                fontSize: 15
            },
            axisRanges: [{
                value: this.goalValue,
                grid: {
                    disabled: !this.goalValue,
                    stroke: VPGColors.StatusRedDark,
                    strokeWidth: 1,
                    strokeOpacity: 1
                }
            }]
        };

        return {
            series: this.stacked ? this.initStackedGraphSeries() : this.initGraphSeries(),
            xAxes: [this.xAxis],
            yAxes: [this.yAxis],
            legend: {
                disabled: legendDisabled,
                useDefaultMarker: true,
                labels: {
                    text: '{name}',
                    fontWeight: 'bold'
                },
                markers: {
                    width: 14,
                    height: 14,
                    children: [{
                        cornerRadiusTopLeft: 12,
                        cornerRadiusTopRight: 12,
                        cornerRadiusBottomRight: 12,
                        cornerRadiusBottomLeft: 12
                    }]
                }
            },
            data: this.stacked ? this.parseStackedChartData(this.statGraphData) : this.statGraphData
        };
    }

    getHHMMFormatFromHours(hoursValue: number): string {
        const seconds = Math.ceil(hoursValue * 3600);
        const hoursInt = Math.floor(hoursValue);
        const minutes = Math.floor((seconds - (hoursInt * 3600)) / 60);
        return minutes < 10 ? (minutes === 0 ? `${hoursInt}` : `${hoursInt}:0${minutes}`) : `${hoursInt}:${minutes}`;
    }

    setLegendHeightForChart(chart: any) {
        if (chart) {
            chart.events.on('datavalidated', (event: any) => {
                this.setLegendHeight(event.target);
            });

            chart.events.on('maxsizechanged', (event: any) => {
                this.setLegendHeight(event.target);
            });
        }
    }

    setLegendHeight(chart: any) {
        if (chart) {
            setTimeout(() => {
                const element = document.getElementById(`${this.routeType}-legend`);

                if (element) {
                    element.style.height = `${chart.legend.contentHeight}px`;
                }
            });
        }
    }

    setGraphElementIds(chart: any, graphTemplate: string) {
        chart.yAxes._values[0].element.attr({ 'id': 'stat-graph-yaxis' });
        chart.series._values[0]._tooltip.element.attr({ 'id': 'stat-graph-tooltip' });

        if (this.routeType === StatType.Weight) {
            this.setWeightIds(chart);
        }

        if (graphTemplate === GraphTemplates.MultiQuantities) {
            chart.legend.element.attr({ 'id': 'stat-graph-legend' });
            chart.events.on('ready', () => {
                if (this.routeType === StatType.BloodPressure) {
                    this.setBloodPressureIds(chart);
                }
                if (this.routeType === StatType.Cholesterol) {
                    this.setCholesterolIds(chart);
                }
                if (this.routeType === StatType.Glucose) {
                    this.setGlucoseIds(chart);
                }
            });
        }
    }

    private initGraphSeries = () => {
        let singleQuantitiesTooltip = `${this.tooltipValue} {valueY}% \n{fullDate}\n{device}`;
        let singleQuantitiesAria = `{fullDate} ${this.tooltipValue} {valueY}%`;
        if (this.routeType === StatType.A1C) {
            singleQuantitiesTooltip = `${this.translateService.instant('A1CStat')} {valueY} ${this.tooltipValue} \n{fullDate}\n{device}`;
            singleQuantitiesAria = `{fullDate} ${this.translateService.instant('A1CStat')} {valueY} ${this.tooltipValue} {device}`;
        }
        if (this.routeType === StatType.Weight) {
            singleQuantitiesTooltip = `{valueY} ${this.tooltipValue} \n{fullDate}\n{device}`;
            singleQuantitiesAria = `{fullDate} ${this.tooltipValue} {valueY} {device}`;
        }
        if (this.routeType === StatType.WaistHipSize) {
            singleQuantitiesTooltip = `${this.tooltipValue} {valueY} ${this.authenticatedMemberService.getAuthenticatedMember().unitOfMeasurement === UnitOfMeasurement.Metric ?
                this.translateService.instant('CentimetersAbbreviation') : this.translateService.instant('InchesAbbreviation')} \n{fullDate}\n{device}`;
            singleQuantitiesAria = `{fullDate} ${this.tooltipValue} {valueY} ${this.authenticatedMemberService.getAuthenticatedMember().unitOfMeasurement === UnitOfMeasurement.Metric ?
                this.translateService.instant('CentimetersAbbreviation') : this.translateService.instant('InchesAbbreviation')} {device}`;
        }
        if (this.graphTemplate === GraphTemplates.SingleQuantities) {
            return [this.createLineSeries(this.baseLineSeriesDataFields, [this.createBullet('CircleBullet', singleQuantitiesTooltip, singleQuantitiesAria)], VPGColors.PrimaryDark, '#ffffff', undefined, 1, 3)];
        }
        let cumulativeQuantitiesTooltip = `{valueY} ${this.tooltipValue}\n{fullDate}\n{device}`;
        if (this.routeType === StatType.CaloriesBurned || this.routeType === StatType.CaloriesConsumed) {
            cumulativeQuantitiesTooltip = `{valueY} ${this.tooltipValue}\n{fullDate}`;
        }
        if (this.graphTemplate === GraphTemplates.CumulativeQuantities) {
            return [this.createColumnSeries(this.stacked, cumulativeQuantitiesTooltip, { categoryX: 'day', valueY: 'value' })];
        }
        if (this.graphTemplate === GraphTemplates.MultiQuantities) {
            if (this.routeType === StatType.BloodPressure) {
                return [
                    this.createLineSeries({ categoryX: 'day', dateX: 'day', valueY: 'systolic' }, [this.createBullet('CircleBullet', `${this.translateService.instant('SystolicBloodPressure')} {valueY} ${this.tooltipValue} \n{fullDate}\n{device}`, `{fullDate} ${this.translateService.instant('SystolicBloodPressure')} {valueY}`)], VPGColors.PrimaryBase, VPGColors.PrimaryBase, 'SystolicBloodPressure'),
                    this.createLineSeries({ categoryX: 'day', dateX: 'day', valueY: 'diastolic' }, [this.createBullet('CircleBullet', `${this.translateService.instant('DiastolicBloodPressure')} {valueY} ${this.tooltipValue} \n{fullDate}\n{device}`, `{fullDate} ${this.translateService.instant('DiastolicBloodPressure')} {valueY}`)], VPGColors.PrimaryDark, VPGColors.PrimaryDark, 'DiastolicBloodPressure')
                ];
            }
            if (this.routeType === StatType.Cholesterol) {
                return [
                    this.createLineSeries({ categoryX: 'day', dateX: 'day', valueY: 'totalCholesterol' }, [this.createBullet('CircleBullet', `${this.translateService.instant('TotalCholesterol')} {valueY} ${this.tooltipValue} \n{fullDate}\n{device}`, `{fullDate} ${this.translateService.instant('TotalCholesterol')} {valueY}`)], VPGColors.PrimaryBase, VPGColors.PrimaryBase, 'TotalCholesterol'),
                    this.createLineSeries({ categoryX: 'day', dateX: 'day', valueY: 'ldl' }, [this.createBullet('CircleBullet', `${this.translateService.instant('LDLCholesterol')} {valueY} ${this.tooltipValue} \n{fullDate}\n{device}`, `{fullDate} ${this.translateService.instant('LDLCholesterol')} {valueY}`)], VPGColors.SecondaryBase, VPGColors.SecondaryBase, 'LDLCholesterol'),
                    this.createLineSeries({ categoryX: 'day', dateX: 'day', valueY: 'hdl' }, [this.createBullet('CircleBullet', `${this.translateService.instant('HDLCholesterol')} {valueY} ${this.tooltipValue} \n{fullDate}\n{device}`, `{fullDate} ${this.translateService.instant('HDLCholesterol')} {valueY}`)], VPGColors.PrimaryDark, VPGColors.PrimaryDark, 'HDLCholesterol'),
                    this.createLineSeries({ categoryX: 'day', dateX: 'day', valueY: 'triglycerides' }, [this.createBullet('CircleBullet', `${this.translateService.instant('Triglycerides')} {valueY} ${this.tooltipValue} \n{fullDate}\n{device}`, `{fullDate} ${this.translateService.instant('Triglycerides')} {valueY}`)], VPGColors.StatusGoldDark, VPGColors.StatusGoldDark, 'Triglycerides')
                ];
            }
            if (this.routeType === StatType.Glucose) {
                return [
                    this.createLineSeries({ categoryX: 'day', dateX: 'day', valueY: 'fasting' }, [this.createBullet('CircleBullet', `${this.translateService.instant('GlucoseStat')} ${this.translateService.instant('GlucoseFasting')} {valueY} ${this.tooltipValue} \n{fullDate}\n{device}`, `{fullDate} ${this.translateService.instant('GlucoseFasting')} {valueY}`)], VPGColors.PrimaryBase, VPGColors.PrimaryBase, 'GlucoseFasting'),
                    this.createLineSeries({ categoryX: 'day', dateX: 'day', valueY: 'nonFasting' }, [this.createBullet('CircleBullet', `${this.translateService.instant('GlucoseStat')} ${this.translateService.instant('GlucoseNonFasting')} {valueY} ${this.tooltipValue} \n{fullDate}\n{device}`, `{fullDate} ${this.translateService.instant('GlucoseNonFasting')} {valueY}`)], VPGColors.PrimaryDark, VPGColors.PrimaryDark, 'GlucoseNonFasting')
                ];
            }
        }
        return;
    };

    private initStackedGraphSeries = () => {
        const workoutTypes: any[] = [];
        const series: any[] = [];
        this.statGraphData.forEach((item: StackedGraphStatDataModel) => {
            if (item.activities) {
                item.activities.forEach((item: GraphActivityModel) => {
                    if (!workoutTypes.includes(item.description)) {
                        const toolTip = `{valueY} ${this.tooltipValue}\n{fullDate}\n{${item.description}-device}\n{${item.description}-description}`;
                        series.push(this.createColumnSeries(true, toolTip, { categoryX: 'day', valueY: item.description }, item.description));
                        workoutTypes.push(item.description);
                    }
                });
            }
        });
        return series;
    };

    private setWeightIds(chart: any) {
        const weightBulletNumber = chart.series._values[0].bulletsContainer._children._values.length;
        for (let i = 0; i < weightBulletNumber; i++) {
            chart.series._values[0].bulletsContainer._children._values[i]
                .element.attr({ 'id': `weigh-in-date-button-${i + 1}` });
        }
    }
    private setBloodPressureIds(chart: any) {
        const systolicBulletNumber = chart.series._values[0].bulletsContainer._children._values.length;
        for (let i = 0; i < systolicBulletNumber; i++) {
            chart.series._values[0].bulletsContainer._children._values[i]
                .element.attr({ 'id': `stat-graph-bullet-systolic-${i + 1}` });
            chart.series._values[0]._tooltip.element.attr({ 'id': 'stat-graph-tooltip-systolic' });
        }

        const diastolicBulletNumber = chart.series._values[1].bulletsContainer._children._values.length;
        for (let i = 0; i < diastolicBulletNumber; i++) {
            chart.series._values[1].bulletsContainer._children._values[i]
                .element.attr({ 'id': `stat-graph-bullet-diastolic-${i + 1}` });
            chart.series._values[1]._tooltip.element.attr({ 'id': 'stat-graph-tooltip-diastolic' });
        }
    }

    private setCholesterolIds(chart: any) {
        const totalCholesterolBulletNumber = chart.series._values[0].bulletsContainer._children._values.length;
        for (let i = 0; i < totalCholesterolBulletNumber; i++) {
            chart.series._values[0].bulletsContainer._children._values[i]
                .element.attr({ 'id': `stat-graph-bullet-totalcholesterol-${i + 1}` });
        }

        const ldlBulletNumber = chart.series._values[1].bulletsContainer._children._values.length;
        for (let i = 0; i < ldlBulletNumber; i++) {
            chart.series._values[1].bulletsContainer._children._values[i]
                .element.attr({ 'id': `stat-graph-bullet-ldl-${i + 1}` });
        }

        const hdlBulletNumber = chart.series._values[2].bulletsContainer._children._values.length;
        for (let i = 0; i < hdlBulletNumber; i++) {
            chart.series._values[2].bulletsContainer._children._values[i]
                .element.attr({ 'id': `stat-graph-bullet-hdl-${i + 1}` });
        }

        const triglyceridesBulletNumber = chart.series._values[3].bulletsContainer._children._values.length;
        for (let i = 0; i < triglyceridesBulletNumber; i++) {
            chart.series._values[3].bulletsContainer._children._values[i]
                .element.attr({ 'id': `stat-graph-bullet-triglycerides-${i + 1}` });
        }
    }

    private setGlucoseIds(chart: any) {
        const fastingBulletNumber = chart.series._values[0].bulletsContainer._children._values.length;
        for (let i = 0; i < fastingBulletNumber; i++) {
            chart.series._values[0].bulletsContainer._children._values[i]
                .element.attr({ 'id': `stat-graph-bullet-fasting-${i + 1}` });
        }

        const nonfastingBulletNumber = chart.series._values[1].bulletsContainer._children._values.length;
        for (let i = 0; i < nonfastingBulletNumber; i++) {
            chart.series._values[1].bulletsContainer._children._values[i]
                .element.attr({ 'id': `stat-graph-bullet-nonfasting-${i + 1}` });
        }
    }

    private setColorIfGoalNotHit = (fill: any, target: any) => {
        if (!this.goalValue || (target.dataItem && target.dataItem.valueY < this.goalValue)) {
            return this.amChartsService.am4core?.color(VPGColors.PrimaryBase);
        }
        return fill;
    };

    private setTooltipTextFormatIfSleep = (text: any, target: any) => {
        if (this.routeType === StatType.Sleep && target.dataItem) {
            const hoursFormated = this.getHHMMFormatFromHours(target.dataItem.valueY);
            return `${hoursFormated} ${this.tooltipValue}\n{fullDate}\n{device}`;
        }
        return text;
    };

    private getTooltipConfig() {
        return {
            getFillFromObject: false,
            background: {
                fill: '#ffffff'
            },
            label: {
                fill: '#000000',
                fontSize: 17,
                textAlign: 'middle',
                width: 220
            },
            pointerOrientation: 'vertical'
        };
    }

    private parseStackedChartData(graphData: StackedGraphStatDataModel[]): any {
        return graphData.map((item: StackedGraphStatDataModel) => {
            const builder: any = {};
            builder.day = item.day;
            builder.fullDate = item.fullDate;
            if (item.activities) {
                item.activities.forEach(({ value, description, deviceName }: GraphActivityModel) => {
                    if (value && description && deviceName) {
                        builder[description] = value;
                        builder[`${description}-description`] = description;
                        builder[`${description}-device`] = deviceName;
                    }
                });
            } else {
                builder.value = null;
                builder.device = 'Unknown';
                builder.description = 'None';
            }
            return builder;
        });
    }

    private createLineSeries(dataFields: any, bullets: any, fill: string, stroke: string, name?: string, fillOpacity?: number, strokeWidth?: number) {
        return {
            name: name ? this.translateService.instant(name) : '',
            type: 'LineSeries',
            dataFields: dataFields,
            bullets: bullets,
            itemReaderText: bullets.tooltipText,
            fill: fill,
            stroke: stroke,
            fillOpacity: fillOpacity,
            strokeWidth: strokeWidth,
            segments: this.createSegments('LinearGradientModifier', [1, 0.6, 0.3, 0], [0, 0.3, 0.6, 1], 90),
            tooltip: this.getTooltipConfig(),
            skipFocusThreshold: 0
        };
    }

    private createColumnSeries(stacked: boolean, tooltipText: string, dataFields: any, name?: string) {
        return {
            name: name ? this.translateService.instant(name) : '',
            type: 'ColumnSeries',
            dataFields: dataFields,
            stacked: stacked,
            xAxis: this.xAxis,
            yAxis: this.yAxis,
            itemReaderText: tooltipText,
            columns: {
                tooltipText: tooltipText,
                fill: {
                    type: this.stacked ? '' : 'LinearGradient',
                    rotation: 90,
                    stops: [{
                        color: VPGColors.PrimaryDark
                    }, {
                        color: VPGColors.StatusGreenBase
                    }]
                },
                adapter: {
                    fill: this.setColorIfGoalNotHit,
                    tooltipText: this.setTooltipTextFormatIfSleep
                }
            },
            tooltip: this.getTooltipConfig(),
            skipFocusThreshold: 0
        };
    }

    private createSegments(modifierType: string, opacities: number[], offsets: number[], gradientRotation: number) {
        return {
            template: {
                fillModifier: {
                    type: modifierType,
                    opacities: opacities,
                    offsets: offsets,
                    gradient: {
                        rotation: gradientRotation
                    }
                }
            }
        };
    }

    private createBullet(type: string, tooltipText: string, ariaText: string) {
        return {
            type: type,
            tooltipText: tooltipText,
            readerTitle: ariaText,
            adapter: {
                disabled: function(_disabledState: boolean, item: any) {
                    return item?.dataItem?.values.valueY.value <= 0;
                }
            }
        };
    }
}
