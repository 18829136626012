import { Injectable } from '@angular/core';

import { GlobalReduxStore } from '../state-management';

@Injectable({
    providedIn: 'root'
})
export class MemberCountryService {
    private _memberCountry: any;

    constructor(globalReduxStore: GlobalReduxStore) {
        const initialState = globalReduxStore.getInitialState();
        this._memberCountry = initialState.memberCountry;
    }

    get memberCountry() {
        return this._memberCountry;
    }
}
