import {
    Component,
    HostBinding,
    Input,
    OnDestroy,
    OnInit
} from '@angular/core';
import { Subscription } from 'rxjs';

import { HomepageModuleRewardService } from './services/homepage-module-reward.service';
import {
    GlobalReduxStore,
    HomepageAvailableGameRewardVM,
    RewardableActionType
} from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'genesis-frontend-homepage-module-reward',
    templateUrl: './homepage-module-reward.component.html',
    styleUrls: ['./homepage-module-reward.component.scss'],
    exportAs: 'moduleReward'
})
export class HomepageModuleRewardComponent implements OnInit, OnDestroy {
    @Input() rewardableActionType: RewardableActionType | null = null;
    @Input() eventCode = '';


    @HostBinding('style.display') get display() {
        return this.showReward ? 'flex' : 'none';
    }

    rewardText = '';
    rewardIcon = '';
    showReward = false;

    private componentSub = new Subscription();

    constructor(
        private mfReduxStore: GlobalReduxStore,
        private homepageModuleRewardService: HomepageModuleRewardService
    ) {}

    ngOnInit(): void {
        const homepageModulesRewards = this.mfReduxStore.getInitialState().homepageModulesRewards;
        this.updateModuleReward(homepageModulesRewards);

        this.componentSub.add(
            this.mfReduxStore.createSelector('homepageModulesRewards')
                .subscribe((data: HomepageAvailableGameRewardVM[]) => {
                    this.updateModuleReward(data);
                })
        );
    }

    ngOnDestroy(): void {
        this.componentSub.unsubscribe();
    }

    private updateModuleReward(homepageModulesRewards: HomepageAvailableGameRewardVM[] = []): void {
        const reward = this.homepageModuleRewardService
            .setModuleReward(homepageModulesRewards, this.rewardableActionType, this.eventCode);

        if (!reward) {
            return;
        }

        this.setRewardData(reward);
        this.setShowReward();
    }

    private setRewardData(reward: HomepageAvailableGameRewardVM): void {
        this.rewardIcon = this.homepageModuleRewardService.setRewardIcon(reward);

        if (this.rewardIcon) {
            this.rewardText = this.homepageModuleRewardService.setRewardText(reward);
        }
    }

    private setShowReward(): void {
        this.showReward = Boolean(this.rewardText && this.rewardIcon);
    }
}
