<span class="sr-only">{{ errorTitle }}, {{ errorMessage }}</span>
<h2
    id="coaching-error-title"
    aria-hidden="true"
    class="error-message-title h4">
    {{ errorTitle }}
</h2>
<div
    id="coaching-error-message"
    aria-hidden="true"
    class="error-message-text-wrapper body-regular mt-xs"
>
    {{ errorMessage }}
</div>