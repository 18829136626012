import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TrackerHelperService {

    constants = {
        NUM_SIXTY: 60,
        NUM_FIFTEEN: 15,
        NUM_TWENTYFOUR: 24,
        NUM_TEN: 10,
        NUM_TWENTYNINE: 29
    };

    constructor() {}

    convertDecimalTimeToHoursMins(hoursInDecimal: number) {
        const hrsMinsArray = this.convertDecimalTimeToHoursMinsArray(hoursInDecimal);
        return hrsMinsArray[0] + 'h ' + hrsMinsArray[1] + 'm';
    }

    convertDecimalTimeToHoursMinsArray(hoursInDecimal: number) {
        const hour = Math.floor(Math.abs(hoursInDecimal));
        const min = Math.floor((Math.abs(hoursInDecimal) * this.constants.NUM_SIXTY) % this.constants.NUM_SIXTY);
        return [hour, min];
    }

    convertSleepToSeconds(hours: number, minutes: number) {
        const hoursToSeconds = hours * (this.constants.NUM_SIXTY * this.constants.NUM_SIXTY);
        const minutesToSeconds = minutes * this.constants.NUM_SIXTY;
        return Math.floor(hoursToSeconds + minutesToSeconds + this.constants.NUM_TWENTYNINE);
    }
}
