import { Pipe, PipeTransform } from '@angular/core';

import { EarningsAndSpending, RewardTransaction } from '../models';


@Pipe({
    name: 'statementTotalAmountForDay'
})
export class StatementTotalAmountForDayPipe implements PipeTransform {
    transform(date: string, earningsAndSpending: EarningsAndSpending | null): number {
        if (!earningsAndSpending || !earningsAndSpending.summary || !earningsAndSpending.earnedRewards) {
            return 0;
        }
        const statementRewardType = earningsAndSpending.summary.rewardType;
        const rewards = earningsAndSpending.earnedRewards.get(date)?.rewards;
        if (!rewards) {
            return 0;
        }
        let total = 0;
        rewards.forEach((reward: RewardTransaction) => {
            if (reward.reward.rewardType === statementRewardType) {
                total += reward.reward.value;
            }
        });

        return total;
    }
}
