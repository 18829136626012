import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AccessibleHTMLPipe } from './accessible-html.pipe';
import { ArrayJoinPipe } from './array-join.pipe';
import { ArrayTransformPipe } from './array-transform.pipe';
import { AssetAsUrlPipe } from './asset-as-url-pipe.pipe';
import { AssetUrlPipe } from './asset-url.pipe';
import { BloodPressureLabelPipe } from './blood-pressure-label.pipe';
import { CalculatePercentagePipe } from './calculate-percentage.pipe';
import { CharactersPipe } from './characters.pipe';
import { ChecklistAriaLabelPipe } from './checklist-aria-label.pipe';
import { CholesterolLabelPipe } from './cholesterol-label.pipe';
import { ClampNumberPipe } from './clamp-number.pipe';
import { CommunicationPreferenceTranslationPipe } from './communication-preference-translation.pipe';
import { CompressImagePipe } from './compress-image.pipe';
import { ConversionPipe } from './conversion.pipe';
import { CurrencyAbbreviatePipe } from './currency-abbreviate.pipe';
import { DateSuffixPipe } from './date-suffix.pipe';
import { DeletedMessagePipe } from './deleted-message.pipe';
import { DisableAddButtonPipe } from './disable-add-button.pipe';
import { FilterByPipe } from './filter-by.pipe';
import { FilterLevelActionsPipe } from './filter-level-actions.pipe';
import { FilterPipe } from './filter.pipe';
import { HighlightSearchPipe } from './highlight-characters.pipe';
import { HrefPipe } from './href.pipe';
import { KeyValueTransformCustomPipe } from './key-value-transform-custom-pipe.pipe';
import { LimitNumberPipe } from './limit-number.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { LinkyWithHTMLPipe } from './linky-with-html.pipe';
import { LocalizeNumberPipe } from './localize-number.pipe';
import { LowercaseWithHyphensPipe } from './lowercase-with-hyphens.pipe';
import { AbsPipe } from './math-abs.pipe';
import { MomentFormatPipe } from './moment-format.pipe';
import { NullAsEmptyString } from './null-as-empty-string.pipe';
import { NumberCounterAnimationPipe } from './number-counter-animation.pipe';
import { OrderByPipe } from './order-by.pipe';
import { OutOfOfficeDatePipe } from './out-of-office-date.pipe';
import { PaginationPipe } from './pagination.pipe';
import { PascalCaseToSentencePipe } from './pascal-case-to-sentence.pipe';
import { QuarterBoundariesPipe } from './quarter-boundaries.pipe';
import { ConvertTimestampToDate } from './redemption-transaction-date.pipe';
import { TranslateRedemptionAction } from './redemption-transaction-redemption-action.pipe';
import { RewardIntervalPipe } from './reward-interval.pipe';
import { PopulationRewardsFilterPipe } from './reward-population-filter.pipe';
import { RewardPopulationInfoPipe } from './reward-population-info.pipe';
import { RewardTypesSameForDayPipe } from './reward-types-same-for-day.pipe';
import { RowPipe } from './row.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SearchFilterPipe } from './search-filter.pipe';
import { SecureImagePipe } from './secure-image.pipe';
import { SecurityImageLoadingPipe } from './security-image-loading.pipe';
import { ShowRewardDisplayInfoPipe } from './show-reward-display-info.pipe';
import { StatementTotalAmountForDayPipe } from './statement-total-amount-for-day.pipe';
import { StripHtmlPipe } from './strip-html.pipe';
import { NgSwitchMultiCasePipe } from './switch-multicase.pipe';
import { TransformLanguageCodePipe } from './transform-language-code.pipe';
import { TranslateBoldArgsPipe } from './translate-bold-args.pipe';
import { TriglyceridesLabelPipe } from './triglycerides-label.pipe';
import { TruncateHtmlPipe } from './truncate-html.pipe';
import { TruncatePipe } from './truncate.pipe';
import { UppercasePipe } from './uppercase.pipe';
import { ValuesPipe } from './values.pipe';
import { WcagInnerHTMLPipe } from './wcag-inner-html.pipe';
import { WeightLabelPipe } from './weight-label.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        AccessibleHTMLPipe,
        ArrayJoinPipe,
        ArrayTransformPipe,
        AssetAsUrlPipe,
        AssetUrlPipe,
        BloodPressureLabelPipe,
        CalculatePercentagePipe,
        CharactersPipe,
        ChecklistAriaLabelPipe,
        CholesterolLabelPipe,
        CommunicationPreferenceTranslationPipe,
        CompressImagePipe,
        ConversionPipe,
        CurrencyAbbreviatePipe,
        DisableAddButtonPipe,
        FilterLevelActionsPipe,
        SearchFilterPipe,
        HighlightSearchPipe,
        HrefPipe,
        KeyValueTransformCustomPipe,
        LimitNumberPipe,
        LinkifyPipe,
        LinkyWithHTMLPipe,
        LowercaseWithHyphensPipe,
        AbsPipe,
        MomentFormatPipe,
        NullAsEmptyString,
        NumberCounterAnimationPipe,
        OrderByPipe,
        OutOfOfficeDatePipe,
        PaginationPipe,
        PascalCaseToSentencePipe,
        QuarterBoundariesPipe,
        RewardIntervalPipe,
        PopulationRewardsFilterPipe,
        RowPipe,
        SafeHtmlPipe,
        SecureImagePipe,
        StripHtmlPipe,
        TransformLanguageCodePipe,
        TranslateBoldArgsPipe,
        TriglyceridesLabelPipe,
        TruncateHtmlPipe,
        TruncatePipe,
        UppercasePipe,
        ValuesPipe,
        WeightLabelPipe,
        FilterPipe,
        FilterByPipe,
        SecurityImageLoadingPipe,
        RewardTypesSameForDayPipe,
        ShowRewardDisplayInfoPipe,
        ConvertTimestampToDate,
        TranslateRedemptionAction,
        WcagInnerHTMLPipe,
        RewardPopulationInfoPipe,
        DeletedMessagePipe,
        DateSuffixPipe,
        NgSwitchMultiCasePipe,
        ClampNumberPipe,
        LocalizeNumberPipe,
        StatementTotalAmountForDayPipe
    ],
    exports: [
        AccessibleHTMLPipe,
        ArrayJoinPipe,
        ArrayTransformPipe,
        AssetAsUrlPipe,
        AssetUrlPipe,
        BloodPressureLabelPipe,
        CalculatePercentagePipe,
        CharactersPipe,
        ChecklistAriaLabelPipe,
        CholesterolLabelPipe,
        CommunicationPreferenceTranslationPipe,
        CompressImagePipe,
        ConversionPipe,
        CurrencyAbbreviatePipe,
        DisableAddButtonPipe,
        FilterLevelActionsPipe,
        SearchFilterPipe,
        HighlightSearchPipe,
        HrefPipe,
        KeyValueTransformCustomPipe,
        LimitNumberPipe,
        LinkifyPipe,
        LinkyWithHTMLPipe,
        LowercaseWithHyphensPipe,
        AbsPipe,
        MomentFormatPipe,
        NullAsEmptyString,
        NumberCounterAnimationPipe,
        OrderByPipe,
        OutOfOfficeDatePipe,
        PaginationPipe,
        PascalCaseToSentencePipe,
        QuarterBoundariesPipe,
        RewardIntervalPipe,
        PopulationRewardsFilterPipe,
        RowPipe,
        SafeHtmlPipe,
        SecureImagePipe,
        StripHtmlPipe,
        TransformLanguageCodePipe,
        TranslateBoldArgsPipe,
        TriglyceridesLabelPipe,
        TruncateHtmlPipe,
        TruncatePipe,
        UppercasePipe,
        ValuesPipe,
        WeightLabelPipe,
        FilterPipe,
        FilterByPipe,
        SecurityImageLoadingPipe,
        RewardTypesSameForDayPipe,
        ShowRewardDisplayInfoPipe,
        ConvertTimestampToDate,
        TranslateRedemptionAction,
        WcagInnerHTMLPipe,
        RewardPopulationInfoPipe,
        DeletedMessagePipe,
        DateSuffixPipe,
        NgSwitchMultiCasePipe,
        ClampNumberPipe,
        LocalizeNumberPipe,
        StatementTotalAmountForDayPipe
    ],
    providers: [
        AssetUrlPipe,
        OrderByPipe,
        WeightLabelPipe,
        StripHtmlPipe,
        SafeHtmlPipe
    ]
})
export class GenesisPipesModule { }
