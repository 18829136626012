import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

import { GlobalChallengesOnboardingBlockerModal } from './global-challenges-onboarding-blocker.component';

@Injectable()
export class GlobalChallengesOnboardingBlockerModalService {
    constructor(
        private modalService: BsModalService
    ) { }

    open(finishedCallback: () => void, contestId: number, memberId: number) {
        const modalRef = this.modalService.show(GlobalChallengesOnboardingBlockerModal, {
            backdrop: false,
            keyboard: false,
            ignoreBackdropClick: true,
            show: true,
            animated: false,
            class: 'challenge-onboarding-blocker-modal modal-md',
            initialState: {
                finishedCallback,
                contestId,
                memberId
            }
        });
        modalRef?.content?.continueEvent.subscribe(() => {
            modalRef.hide();
        });
    }
}
