import { Pipe, PipeTransform } from '@angular/core';

import { DateUtils } from '../lib/date-utils';

@Pipe({ name: 'momentFormat' })
export class MomentFormatPipe implements PipeTransform {

    transform(
        value: string | Date | number | undefined | null,
        format = 'long',
        timezone?: string
    ) {
        if (!value) {
            return null;
        }

        if (timezone) {
            return DateUtils.format(
                DateUtils.dateInUtc(value),
                format,
                '',
                timezone
            );
        }
        return DateUtils.format(value, format);
    }
}
