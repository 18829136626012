import { DateUtils } from '../../lib/date-utils';
import { Timezone } from '../interfaces';

export class MemberTimezone {
    timezone!: Timezone;

    getTimeForMemberNow(): Date {
        return DateUtils.tz(DateUtils.toUtcDate(), this.timezone.javaTimezone);
    }

    getDateTimeInMemberDateTime(dateToConvert: any): Date {
        return DateUtils.tz(dateToConvert, this.timezone.javaTimezone);
    }

    getMemberDate(): Date {
        return DateUtils.startOf(
            DateUtils.tz(DateUtils.toUtcDate(), this.timezone.javaTimezone),
            'day'
        );
    }

    getDateInMemberDate(dateToConvert: any): Date {
        return DateUtils.startOf(
            DateUtils.tz(dateToConvert, this.timezone.javaTimezone),
            'day'
        );
    }

    getTimezoneString(): string {
        return this.timezone.javaTimezone;
    }
}
