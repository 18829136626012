<div class="modal-content global-challenges-onboarding-blocker-container">
    <div
        class="modal-content challenge-onboarding-blocker-carousel-wrapper image-fit-screen"
        *ngIf="contest"
        [ngStyle]="{'background-image': 'url(' + ((data.contest.featuredSplashImageUrl || '') | compressFilePickerImage : 1200) + ')', 'background-size': 'cover', 'background-position': 'center', 'background-repeat': 'no-repeat'}">
        <div class="card-stack">
            <div
                *ngFor="let card of cards; index as i"
                [@cardsParent]>
                <div
                    class="card background-clr-white gray-shadow"
                    *ngIf="currentCard-i < FADED_AWAY"
                    [@cardInOut]
                    [ngClass]="setState(i)">
                    <div class="close-btn">
                        <button
                            vpg-button-icon
                            class="close-button vp-margin-top-25"
                            id="onboarding-blocker-close-btn"
                            [variant]="'action'"
                            [size]="'medium'"
                            [attr.aria-label]="'Close' | translate"
                            (click)="closeModal()">
                            <vpg-icon-next [icon]="CLOSE_ICON"></vpg-icon-next>
                        </button>
                    <div *ngIf="currentCard-i === FRONT_CARD">
                        <ng-container *ngComponentOutlet="card; injector: dataInjector"></ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
