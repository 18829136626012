import { Component, Input } from '@angular/core';

import { AppointmentVM } from '../../models/appointment-vm.model';

@Component({
    selector: 'genesis-frontend-appointment',
    templateUrl: './appointment.component.html',
    styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent {
    @Input() appointment!: AppointmentVM;
}
