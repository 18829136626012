<div id="pillar-accordion-header-{{pillar.id}}"
     class="pillar-accordion-header-container"
     role="button"
     tabindex="0"
     (click)="onAccordionHeaderClick($event)"
     (keyup.enter)="onAccordionHeaderClick($event)">
  <div class="pillar-thumbnail-image-container flex-centered-content">
    <img [src]="thumbnailImageUrl | compressFilePickerImage: 100"
         class="pillar-thumbnail-image"
         alt="">
  </div>

  <div id="pillar-accordion-title-{{pillar.id}}"
       class="pillar-accordion-header-text">
    <div class="pillar-name-container flex-centered-content">
      <h4>{{pillar.name}}</h4>
    </div>

    <div class="selected-counter-container flex-centered-content">
    <span *ngIf="numberOfSelectedTopics">
      {{ 'NumberSelectedTopics' | translate:{ numberSelectedTopics: numberOfSelectedTopics } }}
    </span>
    </div>
  </div>

  <div class="pillar-accordion-chevron flex-centered-content">
    <vpg-icon-next [icon]="active ? 'chevron-up' : 'chevron-down'"
                   [size]="'fa-2x'"
                   [prefix]="'fas'">
    </vpg-icon-next>
  </div>

</div>

<div *ngIf="active"
     id="pillar-accordion-content-{{pillar.id}}"
     class="pillar-accordion-content-container">

  <div class="pillar-description">
    {{pillar.description}}
  </div>

  <div class="" *ngFor="let topic of topics">
    <pillar-topic-select-item [topic]="topic"
                              (onTopicSelect)="onTopicSelection($event)">
    </pillar-topic-select-item>
  </div>

</div>