import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class RetryService {
    retry<T>(action: () => T, onSuccess: () => void, onFailure: () => void, maxRetries = 5, retryDelay = 20
    ): void {
        let attemptCount = 0;

        const attempt = () => {
            const result = action();
            if (result) {
                onSuccess();
                return;
            }

            if (attemptCount < maxRetries) {
                attemptCount++;
                setTimeout(attempt, retryDelay);
            } else {
                onFailure();
            }
        };

        attempt();
    }
}
