import { Component, Inject } from '@angular/core';

import { GlobalChallengeCardCarouselDataModel } from '../../../interface/global-challenges-card-carousel-data.model';
import { ContestType, ShellRouter } from '@genesis-frontend/genesis-utilities';

@Component({
    templateUrl: './order-confirmation.component.html',
    styleUrls: ['./order-confirmation.component.scss']
})
export class GlobalChallengesMaxBuzzOrderConfirmationCardComponent {
    TEAM_TAB = 'myTeam';

    constructor(
        @Inject('data') public data: GlobalChallengeCardCarouselDataModel,
        private shellRouter: ShellRouter
    ) {}

    goNext() {
        if (this.data.getMaxBuzzModal) {
            this.data.closeModal();
        }

        if (this.data.contest.contestType === ContestType.Organizational) {
            this.shellRouter.navigate(`/featuredchallenge/${this.data.contest.contestId}`);
            this.data.closeModal();
        }

        if ((this.data.contest.contestType === ContestType.Team && !this.data.contest.destination) || this.data.contest.destination) {
            this.goToChallengePage(this.data.contest.contestId);
        }
    }

    goToChallengePage(contestId: number) {
        this.shellRouter.navigate(`/featuredchallenge/${contestId}`);
        this.data.closeModal();
    }
}
