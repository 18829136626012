import { Injectable } from '@angular/core';

import { PersonalChallengeTemplate } from '../../models/interfaces/personal-challenge-template.model';
import { PersonalChallenge } from '../../models/interfaces/personal-challenge.model';

@Injectable({
    providedIn: 'root'
})
export class PersonalChallengeDetailsDataService {
    personalChallengeData: PersonalChallenge | PersonalChallengeTemplate | any = {};
    newChallenge: PersonalChallenge | PersonalChallengeTemplate | any = {};
}
