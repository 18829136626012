import { Injectable } from '@angular/core';

import { DateUtils } from '../../index';
import { AuthenticatedMemberService, MemberJourneyStatus } from '../../index';

@Injectable()
export class JourneysRetirementService {

    private readonly JOURNEY_STARTED_STATUS = [MemberJourneyStatus.InProgress, MemberJourneyStatus.Restarted, MemberJourneyStatus.Completed];
    private readonly HOURS_IN_DAY = 24;

    constructor(private authenticatedMemberService: AuthenticatedMemberService) {}

    getActiveNotRetiringJourneys(journeys: any[]) {
        return journeys.filter((journey: any) => {
            const status = journey.status || (journey.memberJourney && journey.memberJourney.status);
            const unpublishedDate = journey.unpublishedDate || (journey.memberJourney && journey.memberJourney.unpublishedDate) || (journey.journey && journey.journey.unpublishedDate);

            if (!unpublishedDate || !this.isUnpublishedWithin30Days(unpublishedDate)) {
                return true;
            }

            if (!status || !this.isJourneyStarted(status)) {
                return !this.isUnpublishedWithin30Days(unpublishedDate);
            }

            return true;
        });
    }

    calculateDaysToRetirement(unpublishedDate?: string): number|undefined {
        if (!unpublishedDate) {
            return undefined;
        }

        const memberTimeZone = this.authenticatedMemberService.getCurrentTimezone().getTimezoneString();
        const zonedUnpublishedDate = DateUtils.startOf(
            DateUtils.tz(unpublishedDate, memberTimeZone),
            'hours'
        );
        const zonedCurrentDate = DateUtils.startOf(
            DateUtils.tz(new Date(), memberTimeZone),
            'hours'
        );

        const hoursDifference = DateUtils.diff(zonedUnpublishedDate, zonedCurrentDate, 'hours');

        // Round up to the equal or greater number of days if there are remaining hours.
        return Math.ceil(hoursDifference / this.HOURS_IN_DAY);
    }

    isUnpublishedWithin30Days(unpublishedDate?: string) {
        if (!unpublishedDate) {
            return false;
        }

        const memberTimezone = this.authenticatedMemberService.getCurrentTimezone().getTimezoneString();
        const minUnpublishedDateLimit = DateUtils.startOf(
            DateUtils.add(DateUtils.tz(new Date(), memberTimezone), 30, 'days'),
            'hours'
        );
        return DateUtils.isSameOrBefore(unpublishedDate, minUnpublishedDateLimit);
    }

    isJourneyStarted(status: MemberJourneyStatus) {
        return this.JOURNEY_STARTED_STATUS.some((p) => p === status);
    }
}
