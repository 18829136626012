export enum TransactionType {
    ACH = 'ACH',
    ECO = 'ECO',
    EGC = 'EGC',
    EGD = 'EGD',
    HAC = 'HAC',
    CEC = 'CEC',
    CED = 'CED',
    CHC = 'CHC',
    ECommerce = 'ECommerce',
    RFD = 'RFD',
    Maritz = 'Maritz',
    MSC = 'MSC',
    FIX = 'FIX',
    CNV = 'CNV',
    CRD = 'CRD',
    FIXP = 'FIXP',
    RMV = 'RMV',
    RMVV1 = 'RMVV1',
    UNK = 'UNK',
    FLX = 'FLX',
    CHL = 'CHL',
    POI = 'POI',
    ONL = 'ONL',
    EXP = 'EXP',
    SHM = 'SHM',
    CLO = 'CLO',
    CHK = 'CHK',
    VOU = 'VOU',
    HSA = 'HSA',
    HRA = 'HRA',
    HFD = 'HFD',
    HLR = 'HLR',
    BIM = 'BIM',
    HSC = 'HSC',
    BLD = 'BLD',
    HCO = 'HCO',
    NUT = 'NUT',
    WMT = 'WMT',
    CHE = 'CHE',
    NSM = 'NSM',
    DMT = 'DMT',
    REF = 'REF',
    HFR = 'HFR',
    WEL = 'WEL',
    ATG = 'ATG',
    HTG = 'HTG',
    ECH = 'ECH',
    ECC = 'ECC',
    RET = 'RET',
    TSK = 'TSK',
    CHA = 'CHA',
    PRL = 'PRL',
    SUB = 'SUB'
}
