import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { CheckboxSelectionModel, PillarTopic } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'pillar-topic-select-item',
    templateUrl: 'pillar-topic-select-item.component.html',
    styleUrls: ['pillar-topic-select-item.component.scss']
})
export class PillarTopicSelectItemComponent implements OnInit {
    @Input() topic: CheckboxSelectionModel<PillarTopic> = {} as CheckboxSelectionModel<PillarTopic>;
    @Output() onTopicSelect: EventEmitter<CheckboxSelectionModel<PillarTopic>> = new EventEmitter<CheckboxSelectionModel<PillarTopic>>();

    topicUrl = '';

    ngOnInit(): void {
        const { data } = this.topic;
        this.topicUrl = `/#/pillars/${data.pillarId}/topic/${data.id}`;
    }

    onTopicSelection(event: Event, topic: CheckboxSelectionModel<PillarTopic>): void {
        if (event.type === 'keyup' && (event as KeyboardEvent).key === 'Enter') {
            (event.target as HTMLInputElement).click();
            return;
        }

        this.onTopicSelect.emit(this.asSelectionValue(event, topic.data));
    }

    private asSelectionValue(event: Event, pillarTopic: PillarTopic): CheckboxSelectionModel<PillarTopic> {
        const inputElement: HTMLInputElement = event.target as HTMLInputElement;
        return {
            selected: inputElement.checked,
            data: pillarTopic
        };
    }
}
