<span class="sr-only">{{ screenreaderText }}</span>
<div
    id="waiting-room-title"
    class="title h5"
    aria-hidden="true"
>{{ multimodalWaitingRoom.title }}</div>
<div
    id="waiting-room-content"
    class="content body-regular"
    aria-hidden="true"
>{{ multimodalWaitingRoom.content }}</div>
<vpg-icon-next class="icon" [icon]="multimodalWaitingRoom.icon" size="3x"></vpg-icon-next>
