import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SponsorDropdownComponent } from './sponsor-dropdown.component';
import { SponsorDropdownService } from './sponsor-dropdown.service';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    exports: [
        SponsorDropdownComponent
    ],
    declarations: [
        SponsorDropdownComponent
    ],
    providers: [
        SponsorDropdownService
    ]
})
export class SponsorDropdownModule {
}
