import { Component, Inject, Input } from '@angular/core';

import { ContestTeam } from '../../../../interface/contest-team.model';
import { GlobalChallengeCardCarouselDataModel } from '../../../../interface/global-challenges-card-carousel-data.model';
import { MemberContestOnboardingBlockerService } from '../../../member-contest-onboarding-blocker.service';

@Component({
    templateUrl: './team-card.component.html',
    styleUrls: ['./team-card.component.scss'],
    selector: 'team-card'
})
export class TeamCardComponent {
    @Input() goToTeamDetails: any;
    @Input() teams: ContestTeam[] = [];
    @Input() maxTeamPlayers!: number;
    @Input() isFeelingLuckyOption!: boolean;

    constructor(
        private memberContestOnboardingBlockerService: MemberContestOnboardingBlockerService,
        @Inject('data') public data: GlobalChallengeCardCarouselDataModel
    ) {}

    viewTeamDetails(team: any) {
        this.goToTeamDetails(team);
    }

    viewRandomTeamDetails() {
        if (this.data.memberId) {
            this.memberContestOnboardingBlockerService
                .getRandomTeamFromContest(this.data.memberId, this.data.contest.contestId)
                .subscribe((teams) => {
                    this.data.isFeelingLuckyOption = true;
                    this.data.viewTeamDetails(teams[0]);
                });
        }
    }
}
