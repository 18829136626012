import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import {
    LiveServicesEngagementStatus,
    CoachingProfile,
    EngagementVM,
    NestedTopic,
    OnsiteLocation,
    Topic,
    UpsertMemberResponseVM,
    ClinicalTeamMemberVM } from '../../models';

@Injectable()
export class LiveServicesCoachingService {
    BASE_LIVE_SERVICES_COACHING_URI = '/live-services-api/live-services-coaching';
    cachedEngagement: EngagementVM | undefined;
    engagementLastCalledInMS = NaN;

    NOT_ENGAGED_STATUSES = [
        LiveServicesEngagementStatus.NotEngaged,
        LiveServicesEngagementStatus.NotEngagedGoalMet,
        LiveServicesEngagementStatus.NotEngagedMadeProgress,
        LiveServicesEngagementStatus.NotEngagedNoLongerEligible,
        LiveServicesEngagementStatus.NotEngagedNoLongerInterested
    ];

    constructor(
        private httpClient: HttpClient
    ) { }

    getTopicsForMember(memberId: number): Observable<NestedTopic[]> {
        return this.httpClient.get<NestedTopic[]>(`${this.BASE_LIVE_SERVICES_COACHING_URI}/members/${memberId}/topics`);
    }

    getTopicForMember(memberId: number, topicInternalName: string): Observable<Topic> {
        return this.httpClient.get<Topic>(`${this.BASE_LIVE_SERVICES_COACHING_URI}/members/${memberId}/topics/${topicInternalName}`);
    }

    getLocationsForMember(memberId: number): Observable<OnsiteLocation[]> {
        return this.httpClient.get<OnsiteLocation[]>(`${this.BASE_LIVE_SERVICES_COACHING_URI}/members/${memberId}/locations`);
    }

    isMemberFirstInitialCoachingAppointment(memberId: number): Observable<boolean> {
        return this.httpClient.get<boolean>(`${this.BASE_LIVE_SERVICES_COACHING_URI}/members/${memberId}/is-first-initial`);
    }

    getEngagementStatusForMember(memberId: number): Observable<EngagementVM> {
        return this.httpClient.get<EngagementVM>(`${this.BASE_LIVE_SERVICES_COACHING_URI}/members/${memberId}/engagement`).pipe(
            tap((engagment) => this.cachedEngagement = engagment)
        );
    }

    upsertMember(memberId: number): Observable<UpsertMemberResponseVM> {
        return this.httpClient.
            put<UpsertMemberResponseVM>(`${this.BASE_LIVE_SERVICES_COACHING_URI}/members/${memberId}/account`, null);
    }

    getCoachingProfile(memberId: number): Observable<CoachingProfile> {
        return this.httpClient.get<CoachingProfile>(`${this.BASE_LIVE_SERVICES_COACHING_URI}/members/${memberId}/coaching-profile`);
    }

    getCoachingPhoneNumberForMember(memberId: number): Observable<string> {
        return this.httpClient.get(`${this.BASE_LIVE_SERVICES_COACHING_URI}/members/${memberId}/coaching-phone-number`, { responseType: 'text' });
    }

    isNotEngagedStatus(memberEngagement: EngagementVM | CoachingProfile): boolean {
        return memberEngagement.engagementStatus === '' || this.NOT_ENGAGED_STATUSES.includes((memberEngagement.engagementStatus as LiveServicesEngagementStatus));
    }

    isEngaged(memberEngagement: EngagementVM | CoachingProfile): boolean {
        return memberEngagement.engagementStatus === LiveServicesEngagementStatus.Engaged ||
            memberEngagement.engagementStatus === LiveServicesEngagementStatus.UnableToReach;
    }

    isPreEngaged(memberEngagement: EngagementVM | CoachingProfile): boolean {
        return memberEngagement.engagementStatus === LiveServicesEngagementStatus.PreEngaged;
    }

    isFirstAppointment(memberEngagement: EngagementVM | CoachingProfile): boolean {
        return memberEngagement.engagementStatus === '' && memberEngagement.isActiveInCoaching;
    }

    isFirstInitial(memberEngagement: EngagementVM | CoachingProfile): boolean {
        const hasTobaccoCoaching = memberEngagement.allLiveServicesPackageNames.includes('Tobacco');

        return memberEngagement.engagementStatus === '' &&
            !memberEngagement.isActiveInCoaching &&
            !hasTobaccoCoaching;
    }

    wasPreviouslyEngaged(memberEngagement: EngagementVM | CoachingProfile): boolean {
        return this.NOT_ENGAGED_STATUSES.includes((memberEngagement.engagementStatus as LiveServicesEngagementStatus)) &&
            !memberEngagement.isActiveInCoaching;
    }

    flattenTopics(topics: NestedTopic[]): NestedTopic[] {
        return topics.reduce<NestedTopic[]>((prev, value) => {
            let result: NestedTopic[] = [];
            if (Array.isArray(value.childTopics) && value.childTopics.length) {
                result = result.concat(value.childTopics);
            }
            result = result.concat([value]);
            return prev.concat(result);
        }, []);
    }

    invalidateEngagementCache() {
        this.engagementLastCalledInMS = NaN;
    }

    getCoachIdFromSFUserId(sfUserId: string): Observable<ClinicalTeamMemberVM> {
        return this.httpClient.get<ClinicalTeamMemberVM>(`${this.BASE_LIVE_SERVICES_COACHING_URI}/clinical-team-member/${sfUserId}`);
    }
}
