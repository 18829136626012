<h3
    class="h3"
    id="add-translations-title"
> {{ title }}</h3>
<p id="translations-helper-text">{{ text }}</p>
<vpg-dropdown-next
    [id]="'translation-dropdown'"
    class="translation-dropdown"
    [options]="languages"
    [optionLabel]="'englishNative'"
    [ngModel]="selectedLanguage?.englishNative"
    [placeholder]="'Select Language'"
    (valueChange)="setLanguage($event)"
>
</vpg-dropdown-next>
