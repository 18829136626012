
import { Inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

import {
    AddAuthenticatedMember,
    AddConfiguration,
    AddMemberCountry,
    AddMemberFeatures,
    AddMemberFeatureToggle,
    AddMemberProducts,
    GlobalReduxStore,
    UpdateSponsorVpNavigateFeatures,
    FeatureEnum,
    intersection,
    MemberResponseRef,
    KeycloakService,
    AddMixpanelObject
} from '@genesis-frontend/genesis-utilities';

/**
 * @deprecated Used to temporary provide compatability for shared state
 */
@Injectable()
export class SharedStateService {

    vpNavigateFeatures = [
        FeatureEnum.DigitalWallet,
        FeatureEnum.MedicalPlan,
        FeatureEnum.FindCare
    ];

    constructor(
        private keycloakService: KeycloakService,
        private store: GlobalReduxStore,
        private memberResponse: MemberResponseRef,
        @Inject('mixpanel') private mixpanel: any
    ) { }

    updateStore() {
        return this.memberResponse.once().pipe(map((response) => {

            const features = response.memberFeatures;
            const vpNavigateFeatures = intersection(features.map((memberFeature) => memberFeature.name), this.vpNavigateFeatures.concat(FeatureEnum.BetaTesting));

            this.store.dispatchAction(AddMemberProducts(response.memberProducts));
            this.store.dispatchAction(AddMemberCountry(response.memberCountry));
            this.store.dispatchAction(AddAuthenticatedMember(response.member, this.keycloakService.getClient()));
            this.store.dispatchAction(AddConfiguration({ ...environment }));
            this.store.dispatchAction(UpdateSponsorVpNavigateFeatures(vpNavigateFeatures));
            this.store.dispatchAction(AddMemberFeatures(response.memberFeatures));
            this.store.dispatchAction(AddMemberFeatureToggle(response.memberFeaturesToggle));

            this.store.dispatchAction(AddMixpanelObject(this.mixpanel));
        }));
    }
}
