export enum Language {
    en_us = 'en_us',
    es_mx = 'es_mx',
    pt_br = 'pt_br',
    fr_fr = 'fr_fr',
    de_de = 'de_de',
    es_es = 'es_es',
    ru_ru = 'ru_ru',
    vi_vn = 'vi_vn',
    zh_cn = 'zh_cn',
    ja_jp = 'ja_jp',
    en_gb = 'en_gb',
    fr_ca = 'fr_ca',
    pl_pl = 'pl_pl',
    zh_hant = 'zh_hant',
    ms_my = 'ms_my',
    it_it = 'it_it',
    sv_se = 'sv_se',
    ko_kr = 'ko_kr',
    nl_nl = 'nl_nl',
    th_th = 'th_th',
    ar_001 = 'ar_001',
    hi_in = 'hi_in',
    tr_tr = 'tr_tr',
    ro_ro = 'ro_ro'
}
