export enum MemberRole {
    BenefitsViewer = 'ROLE_BenefitsViewer',
    CalendarAdmin = 'ROLE_CalendarAdmin',
    CalendarSponsorAdmin = 'ROLE_CalendarSponsorAdmin',
    ChallengeAdmin = 'ROLE_ChallengeAdmin',
    ChallengeAdminWithoutPII = 'ROLE_ChallengeAdminWithoutPII',
    ChallengeViewer = 'ROLE_ChallengeViewer',
    Champion = 'ROLE_Champion',
    ClientSupportAgent = 'ROLE_ClientSupportAgent',
    Coach = 'ROLE_Coach',
    CommunicationsAdmin = 'ROLE_CommunicationsAdmin',
    EligibilityAdmin = 'ROLE_EligibilityAdmin',
    EUVisibility = 'ROLE_EUVisibility',
    FileIngestionViewer = 'ROLE_FileIngestionViewer',
    HabitsAdmin = 'ROLE_HabitsAdmin',
    HabitsViewer = 'ROLE_HabitsViewer',
    Impersonator = 'ROLE_Impersonator',
    MemberServices = 'ROLE_MemberServices',
    PlatformAnalyticsAdministrator = 'ROLE_PlatformAnalyticsAdministrator',
    ClaimsDashboardAdmin = 'ROLE_ClaimsDashboardAdmin',
    NavigateDashboardAdmin = 'ROLE_NavigateDashboardAdmin',
    ExtractCatalogAdmin = 'ROLE_ExtractCatalogAdmin',
    ExtractCatalogNoDownload = 'ROLE_ExtractCatalogNoDownload',
    PepsiCoChallengeDashboardAdmin = 'ROLE_PepsiCoChallengeDashboardAdmin',
    TransformDashboardAdmin = 'ROLE_TransformDashboardAdmin',
    PopulationRiskDashboardAdmin = 'ROLE_PopulationRiskDashboardAdmin',
    PartnerReportingAdministrator = 'ROLE_PartnerReportingAdministrator',
    ReportingAdmin = 'ROLE_ReportingAdmin',
    ReportingSponsorAdmin = 'ROLE_ReportingSponsorAdmin',
    ReportingViewer = 'ROLE_ReportingViewer',
    RoleManager = 'ROLE_RoleManager',
    RootAdmin = 'ROLE_RootAdmin',
    Security = 'ROLE_Security',
    SponsorAdministrator = 'ROLE_SponsorAdministrator',
    SponsorCalendarViewer = 'ROLE_SponsorCalendarViewer',
    SponsorSuperAdministrator = 'ROLE_SponsorSuperAdministrator',
    SponsorViewer = 'ROLE_SponsorViewer',
    SubmitMembersRewards = 'ROLE_SubmitMembersRewards',
    SubmitRedemptionTransaction = 'ROLE_SubmitRedemptionTransaction',
    SurveyAdmin = 'ROLE_SurveyAdmin',
    SurveyViewer = 'ROLE_SurveyViewer',
    TechnicalSupport = 'ROLE_TechnicalSupport',
    TranslationAdmin = 'ROLE_TranslationAdmin',
    UserExperienceAdmin = 'ROLE_UserExperienceAdmin',
    CommsLayoutViewer = 'ROLE_Comms_LayoutViewer',
    CommsLayoutEditor = 'ROLE_Comms_LayoutEditor',
    CommsLayoutSuperEditor = 'ROLE_Comms_LayoutSuperEditor',
    CommsTemplateViewer = 'ROLE_Comms_TemplateViewer',
    CommsTemplateEditor = 'ROLE_Comms_TemplateEditor',
    CommsTemplatePublisher = 'ROLE_Comms_TemplatePublisher',
    PartnerDarkMode = 'ROLE_PartnerDarkMode',
    IcrReadWrite = 'ROLE_ICRReadWrite',
    IcrReadOnly = 'ROLE_ICRReadOnly',
    ConfigurationReadOnly = 'ROLE_ConfigurationReadOnly',
    AgentAdmin_BasicInfoPage = 'ROLE_AgentAdmin_BasicInfoPage',
    AgentAdmin_MemberSearchPage = 'ROLE_AgentAdmin_MemberSearchPage',
    AgentAdmin_RedemptionPage = 'ROLE_AgentAdmin_RedemptionPage',
    AgentAdmin_RewardableActionPage = 'ROLE_AgentAdmin_RewardableActionPage',
    AgentAdmin_RewardsPage = 'ROLE_AgentAdmin_RewardsPage',
    AgentAdmin_MemberEligible = 'ROLE_AgentAdmin_MemberEligible',
    AgentAdmin_SubmitRedemptionTransaction = 'ROLE_AgentAdmin_SubmitRedemptionTransaction',
    AgentAdmin_TransactionCredentialsViewer = 'ROLE_AgentAdmin_TransactionCredentialsViewer',
    AgentAdmin_RewardsViewOnly = 'ROLE_AgentAdmin_RewardsViewOnly',
    AgentAdmin_ViewPHI = 'ROLE_AgentAdmin_ViewPHI',
    AgentManager = 'ROLE_AgentManager',
    CardAdmin = 'ROLE_CardAdmin',
    CardViewer = 'ROLE_CardViewer',
    ChallengeAdminWithRewarding = 'ROLE_ChallengeAdminWithRewarding',
    CoachAdmin = 'ROLE_CoachAdmin',
    EmailCampaignAdmin = 'ROLE_EmailCampaignAdmin',
    Email_LayoutEditor = 'ROLE_Email_LayoutEditor',
    Email_LayoutSuperEditor = 'ROLE_Email_LayoutSuperEditor',
    Email_LayoutViewer = 'ROLE_Email_LayoutViewer',
    Email_TemplateEditor = 'ROLE_Email_TemplateEditor',
    Email_TemplatePublisher = 'ROLE_Email_TemplatePublisher',
    Email_TemplateViewer = 'ROLE_Email_TemplateViewer',
    GroupSubmissionFormAdmin = 'ROLE_GroupSubmissionFormAdmin',
    GroupBoardAdmin = 'ROLE_GroupBoardAdmin',
    HealthStationAdmin = 'ROLE_HealthStationAdmin',
    MemberRewardsRedeem = 'member_rewards_redeem',
    Public = 'public',
    ReadOnlyRewards = 'ROLE_ReadOnlyRewards',
    ReportArchiveViewer = 'ROLE_ReportArchiveViewer',
    SalesforceHealthCloud = 'ROLE_SalesforceHealthCloud',
    ServiceAccount = 'service_account',
    TransactionCredentialsViewer = 'ROLE_TransactionCredentialsViewer',
    BrandingLogoAdmin = 'ROLE_BrandingLogoAdmin',
    TPAAdminGroupEnrollment = 'ROLE_TPAAdminGroupEnrollment',
    TPASuperAdmin = 'ROLE_TPASuperAdmin',
    TPAAdminClaimsEob = 'ROLE_TPAAdminClaimsEOB',
    TPABrokerAdminPHI = 'ROLE_TPABrokerAdminPHI',
    TPABrokerAdminNoPHI = 'ROLE_TPABrokerAdminNoPHI',
    TPABrokerSuperAdmin = 'ROLE_TPABrokerSuperAdmin',
    ROLE_PHIAdmin = 'ROLE_PHIAdmin'
}
