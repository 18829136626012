import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {
    AuthenticatedMemberService,
    Member,
    MyCareChecklistRewards,
    MyCareChecklistRewardsMethods
} from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class MyCareChecklistRewardsService {

    apiUrl = '/care-api';
    apiUrlRewards: string;
    apiUrlRewardsMethod: string;
    member: Member;

    constructor(
        private httpClient: HttpClient,
        private authenticatedMemberService: AuthenticatedMemberService
    ) {
        this.member = this.authenticatedMemberService.getAuthenticatedMember();
        this.apiUrlRewards = `/api/genesis-rewards/sponsors/${ this.member.sponsorId }/members/${ this.member.id }/rewardable-action-type-summary?` +
            `rewardable-action-type=MCCPreventiveServices&memberFeatures=${this.authenticatedMemberService.getRewardableFeatures()}`;
        this.apiUrlRewardsMethod = `${ this.apiUrl }/members/${ this.member.id }/care-how-to-earn`;
    }

    getRewards(): Observable<MyCareChecklistRewards> {
        return this.httpClient
            .get<MyCareChecklistRewards>(this.apiUrlRewards)
            .pipe(
                catchError(this.handleError())
            );
    }

    getRewardsMethod(): Observable<MyCareChecklistRewardsMethods> {
        return this.httpClient
            .get<MyCareChecklistRewardsMethods>(this.apiUrlRewardsMethod)
            .pipe(
                catchError(this.handleError())
            );
    }

    handleError() {
        return (err: any) => {
            return throwError(err);
        };
    }
}
