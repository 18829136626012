import { ElementRef, Injectable } from '@angular/core';

import { KeycodeService } from './keycode.service';

@Injectable({
    providedIn: 'root'
})
export class WcagService {

    keyName = this.keyCodeService.keyName;

    constructor(
        private keyCodeService: KeycodeService
    ) { }

    focusItem(event: KeyboardEvent | null, listItems: HTMLCollectionOf<HTMLElement>, currentFocusedIndex: number, increment: number) {
        if (!listItems || !listItems.length) {
            return 0;
        }
        const nextIndexToFocus = currentFocusedIndex + increment;
        const newFocusedIndex = this.focusItemAndGetFocusedIndex(listItems, nextIndexToFocus);
        event?.preventDefault();
        event?.stopPropagation();
        return newFocusedIndex;
    }

    private focusItemAndGetFocusedIndex(listItems: HTMLCollectionOf<HTMLElement>, nextIndexToFocus: number) {
        const newFocusedIndex = this.getFocusIndexValidValue(listItems, nextIndexToFocus);
        listItems[newFocusedIndex].focus();
        return newFocusedIndex;
    }

    private getFocusIndexValidValue(listItems: HTMLCollectionOf<HTMLElement>, nextIndexToFocus: number) {
        if (nextIndexToFocus < 0) {
            return (nextIndexToFocus = listItems.length - 1);
        }

        if (nextIndexToFocus > listItems.length - 1) {
            return (nextIndexToFocus = 0);
        }

        return nextIndexToFocus;
    }

    trapFocusInsideElementsContainer(event: KeyboardEvent, elementRef: ElementRef, tab?: string): void {
        tab = tab ? tab : this.keyName.Tab;
        if (event.key !== tab) {
            return;
        }

        const focusableElements = this.getFocusableElements(elementRef);
        const activeElement = elementRef.nativeElement.ownerDocument.activeElement;
        const lastFocusableElement = focusableElements[focusableElements?.length - 1] as HTMLElement | null;
        const firstFocusableElement = focusableElements[0] as HTMLElement | null;

        if (!event.shiftKey) {
            if (activeElement === lastFocusableElement) {
                if (!firstFocusableElement) {
                    return;
                }
                firstFocusableElement.focus();
                event.preventDefault();
            }
            return;
        }

        if (activeElement === firstFocusableElement) {
            if (!lastFocusableElement) {
                return;
            }
            lastFocusableElement.focus();
            event.preventDefault();
        }
    }

    private getFocusableElements(elementRef: ElementRef): Element [] {
        return [...elementRef.nativeElement.querySelectorAll('button, ' +
            '[href], [tabindex]:not([tabindex="-1"])')]
            .filter((element: any) => !element.disabled);
    }
}
