import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { CoachingModuleState } from './enums/coaching-module-state.enum';
import { CoachingCardVM } from './models/coaching-card-vm.model';
import { UIState } from './models/ui-state.model';
import { CoachingService } from './services/coaching.service';

@Component({
    selector: 'genesis-frontend-coaching-module',
    templateUrl: './coaching-card.component.html'
})
export class CoachingCardComponent {
    vm$: Observable<UIState<CoachingCardVM>>;
    coachingModuleState = CoachingModuleState;

    constructor(
        private coachingService: CoachingService
    ) {
        this.vm$ = this.coachingService.fetch();
    }
}
