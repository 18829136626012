<vpg-dialog
    [open]="modalOpen"
    (openChange)="openChangeOutput.emit($event)"
>
    <vpg-dialog-header
        [id]="'modal-header'"
        [closeIcon]="true"
        [closeButtonAriaLabel]="'modal-header-close-icon'"
    >
        {{ modalTitle }}
    </vpg-dialog-header>
    <vpg-dialog-body
        [id]="'modal-header'"
    >
        {{ modalText }}
    </vpg-dialog-body>
    <vpg-dialog-actions>
        <button
            vpg-button
            [id]="'cancel-button'"
            [variant]="'secondary'"
            [size]="'medium'"
            (click)="openChangeOutput.emit(false)"
        >
            {{ 'Cancel' }}
        </button>
        <button
            vpg-button
            [id]="'delete-button'"
            [variant]="'primary'"
            [size]="'medium'"
            (click)="deleteOutput.emit(true)"
        >
            {{ 'Delete' }}
        </button>
    </vpg-dialog-actions>
</vpg-dialog>
