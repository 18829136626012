import { Injectable } from '@angular/core';

import { AuthenticatedMemberService, Member, DateUtils } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class DateService {
    member: Member;
    today = '';
    sevenDaysFarAwayDate = '';
    timezone = '';
    locale = DateUtils.getGlobalLocale();
    timeZoneName = DateUtils.guessTimeZone(true);


    constructor(
        private authenticatedMemberService: AuthenticatedMemberService
    ) {
        this.member = this.authenticatedMemberService.getAuthenticatedMember();
        this.today = DateUtils.format(DateUtils.tz(new Date(), this.member.timezone.javaTimezone), 'YYYY-MM-DD');
        this.sevenDaysFarAwayDate = DateUtils.format(DateUtils.add(DateUtils.tz(new Date(), this.member.timezone.javaTimezone), 7, 'days'), 'YYYY-MM-DD');

        this.timezone = this.member.timezone.javaTimezone;
    }

    convertToFormattedDate(date: Date | string): string {
        return DateUtils.format(date, 'YYYY-MM-DD');
    }

    isDateInProgress(startDate: Date | string, endDate: Date | string): boolean {
        return DateUtils.isSameOrBefore(startDate, this.today, 'day') && DateUtils.isSameOrAfter(endDate, this.today, 'day');
    }

    isDateInFuture(date: Date | string): boolean {
        return DateUtils.isAfter(date, this.today, 'day');
    }

    isDateInPast(date: Date | string): boolean {
        return DateUtils.isAfter(this.today, date, 'day');
    }

    isStarted(startDate: Date | string): boolean {
        return DateUtils.isSameOrAfter(this.today, startDate, 'day');
    }

    isDateLessThen7DayAwayAndNotInPast(date: Date | string): boolean {
        return DateUtils.isSameOrBefore(date, this.sevenDaysFarAwayDate, 'day') && DateUtils.isSameOrAfter(date, this.today, 'day');
    }

    convertToTitleFormat(date: Date | string): string {
        return DateUtils.format(date, 'ddd, MMM DD, YYYY');
    }

    getBrowserTimezoneFormattedDate(date: Date, format: string): string {
        return DateUtils.format(date, format, this.locale, this.timeZoneName) || '';
    }

    getBrowserTimezone(date: Date): string {
        return DateUtils.format(date, 'z', this.locale, this.timeZoneName);
    }
}
