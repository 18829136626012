<bubble-celebration
    #bubbleCelebrationComponent
    [width]="celebrationWidth"
    [height]="celebrationHeight"
    [particleCount]="celebrationParticleCount"
    [colors]="celebrationColors"
    [animationDuration]="timeLapse"
    [containerHeight]="containerHeight"
    [containerWidth]="containerWidth">
    <div class="trophy-popup-wrapper">
        <div class="trophy-container">
            <div
                id="trophy-modal-close-btn"
                [attr.aria-describedby]="'trophy-modal-header'"
                class="close-trophy-popup-wrapper"
                [wcagClick]="closeModal.bind(this)"
                [attr.translate]="{ 'aria-label': 'Close' }"
                role="button">
                &times;
            </div>
            <h1
                id="trophy-modal-header"
                class="sr-only"
                [translate]="'EarnedTrophies'"></h1>
            <ngx-slick-carousel
                [config]="slickSettings"
                class="custom-slick-carousel trophy-popup-slider">
                <div
                    ngxSlickItem
                    *ngFor="let trophy of trophies">
                    <div
                        class="trophy-flip"
                        tabindex="0"
                        [ngClass]="{ hover: hover }"
                        (mouseenter)="hover = true"
                        (mouseleave)="hover = false">
                        <div class="trophy-slider-item">
                            <div class="face front">
                                <img
                                    [src]="trophy.achievement.imageRef"
                                    [attr.alt]="trophy.achievement.name"
                                    class="mobile-z-index trophy-image" />
                                <div
                                    class="pulsing-circle"
                                    style="animation-delay: 0s"></div>
                                <div
                                    class="pulsing-circle"
                                    style="animation-delay: 0.8s"></div>
                                <div
                                    class="pulsing-circle"
                                    style="animation-delay: 1.6s"></div>
                                <div
                                    class="pulsing-circle"
                                    style="animation-delay: 2.4s"></div>
                                <div
                                    class="pulsing-circle"
                                    style="animation-delay: 3.2s"></div>
                                <div
                                    class="pulsing-circle"
                                    style="animation-delay: 4s"></div>
                            </div>
                            <div class="face back">
                                <div class="trophy-desc-container">
                                    {{ trophy.achievement.catchPhrase }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="trophy-popup-name"
                        aria-hidden="true">
                        {{ trophy.achievement.name }}
                    </div>
                    <div class="trophy-earned">
                        <span
                            *ngIf="trophy.memberEarnedDate"
                            [translate]="'TrophyPopupEarned'"
                            [translateParams]="{ dateEarned: trophy.memberEarnedDate | momentFormat: 'LL' }"></span>
                    </div>
                </div>
            </ngx-slick-carousel>
        </div>
    </div>
</bubble-celebration>
