import { Injectable } from '@angular/core';

import { DateUtils } from '../lib/date-utils';

@Injectable({
    providedIn: 'root'
})
export class TimeZoneUtil {

    public timeZoneName = DateUtils.guessTimeZone(true);

    now(): Date {
        return DateUtils.tz(new Date(), this.timeZoneName);
    }

    format(_date: Date, _string: string): string {
        return DateUtils.format(DateUtils.tz(_date, this.timeZoneName), _string);
    }

    formatEnglish(_date: Date, _string: string): string {
        return DateUtils.format(
            DateUtils.tz(_date, this.timeZoneName),
            _string,
            'en'
        );
    }

    isNowOrAfter(_date: Date): boolean {
        return DateUtils.isSameOrAfter(DateUtils.tz(_date, this.timeZoneName), new Date());
    }

    isBefore(_date: Date): boolean {
        return DateUtils.isBefore(DateUtils.tz(_date, this.timeZoneName), new Date());
    }

    dateToTime(_date: Date): Date {
        return DateUtils.tz(_date, this.timeZoneName);
    }

    stringToDate(_date: string, _memberTimezone: string): Date {
        return DateUtils.tz(_date, _memberTimezone);
    }
}
