import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { defaults } from '../../lib/lodash';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    DEFAULT_PAGE_SIZE = 25;

    constructor(private httpClient: HttpClient) {
    }

    getCompaniesForSponsor(sponsorId: number, options: any) {
        const params = defaults(options || {}, {
            pageSize: this.DEFAULT_PAGE_SIZE
        });

        return this.httpClient.get(`/api/client-admin/sponsors/${sponsorId}/companies`, {
            observe: 'response',
            params
        }).pipe(map((response: any) => ({
            companies: response,
            contentItems: response.headers.get('content-items'),
            currentPage: response.headers.get('current-page'),
            totalPages: response.headers.get('total-pages'),
            data: response.body
        })));
    }
}
