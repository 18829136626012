import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { AuthenticatedMemberService } from '../../authentication';
import {
    ActionType,
    SleepSetting,
    StatSettingsModel,
    StepSetting,
    UnitOfMeasurement,
    StatType,
    ActivityModel
} from '../../models';
import { ConversionService } from '../../services';

@Injectable({
    providedIn: 'root'
})
export class StatsSettingsService {
    readonly DECIMAL_POINTS = 2;

    private stepPercent = 0;
    private stepMax: any;
    private sleepPercent = 0;
    private sleepMax = 25200;
    private activePercent = 0;
    private totalSleepSec = 0;
    private ActiveMax = 1800;
    private defaultStatSettingsByActionType = new Map<string, StatSettingsModel[]>();
    private defaultStatSettingsByStatType = new Map<string, StatSettingsModel>();
    private unitOfMeasurement = '';

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private httpClient: HttpClient,
        private translateService: TranslateService,
        private conversionService: ConversionService
    ) {
        this.getStepSettingMax();
        this.getSleepMax();
        this.buildDefaultStatSettingByActionType();
        this.buildDefaultStatSettingByStatType();
        this.unitOfMeasurement = this.authenticatedMemberService.getAuthenticatedMember().unitOfMeasurement;
    }

    getStepSettingMax() {
        const memberId = this.authenticatedMemberService.getAuthenticatedMember().id;
        this.httpClient.get<StepSetting>(`/api/members/${memberId}/settings`).subscribe((response) => {
            this.stepMax = response.stepsGoal;
        });
    }

    getSleepMax() {
        this.getSleepGoal().subscribe(
            (response) => {
                this.sleepMax = response.sleepGoalSeconds;
            }
        );
    }

    getStepsGoal(): Observable<StepSetting> {
        const memberId = this.authenticatedMemberService.getAuthenticatedMember().id;
        return this.httpClient.get<StepSetting>(`/api/members/${memberId}/settings`);
    }

    getSleepGoal(): Observable<SleepSetting> {
        const memberId = this.authenticatedMemberService.getAuthenticatedMember().id;
        return this.httpClient.get<SleepSetting>(`/api/members/${memberId}/sleep/settings`);
    }

    getDefaultStatSettingsByActionType(actionType: string): StatSettingsModel[] | null {
        return this.defaultStatSettingsByActionType.get(actionType) ?? null;
    }

    getDefaultStatSettings() {
        return this.defaultStatSettings;
    }

    getDefaultStatSettingsByStatType(statType: string): StatSettingsModel | null {
        return this.defaultStatSettingsByStatType.get(statType) ?? null;
    }

    saveStepSettings(stepsSettings: StepSetting): Observable<StepSetting> {
        const memberId = this.authenticatedMemberService.getAuthenticatedMember().id;
        return this.httpClient.put<StepSetting>(`/api/members/${memberId}/settings`, stepsSettings);
    }

    saveSleepSettings(sleepSettings: SleepSetting): Observable<SleepSetting> {
        const memberId = this.authenticatedMemberService.getAuthenticatedMember().id;
        const memberSleepSettingId = sleepSettings.id;
        return this.httpClient.put<SleepSetting>(`/api/members/${memberId}/sleep/settings/${memberSleepSettingId}`, sleepSettings);
    }

    createSleepSettings(sleepSettings: SleepSetting): Observable<SleepSetting> {
        const memberId = this.authenticatedMemberService.getAuthenticatedMember().id;
        sleepSettings.memberId = memberId;
        return this.httpClient.post<SleepSetting>(`/api/members/${memberId}/sleep/settings`, sleepSettings);
    }

    getStatSettingTitles(): string[] {
        const titles = this.getDefaultStatSettings().map((setting) => setting.title);
        const titlesWithWaistHip: string[] = [];
        titles.forEach((title) => {
            if (title === 'WaistLabel' || title === 'HipLabel') {
                if (!titlesWithWaistHip.find((value) => value === 'Waist & Hips')) {
                    titlesWithWaistHip.push('Waist & Hips');
                }
            } else {
                titlesWithWaistHip.push(title);
            }
        });
        return titlesWithWaistHip;
    }

    public readonly defaultStatSettings: StatSettingsModel[] = [
        {
            actionType: ActionType.Steps,
            title: 'StepsStat',
            titleTranslated: () => {
                return this.translateService.instant('StepsStat');
            },
            defaultDisplayPosition: 1,
            valueFunction: (activities) => {
                if (activities.length === 0) {
                    return null;
                }
                const sortedActivities = activities.sort((first, second) => second.steps - first.steps);
                return sortedActivities[0].steps.toString();
            },
            valuePercent: (value) => {
                this.stepPercent = Math.floor((Number(value) / this.stepMax) * 100);
                return this.stepPercent;
            },
            getConvertedValue: (value) => {
                if (!value) {
                    return 0;
                }
                return value;
            },
            iconPath: 'svgs/statIcons/steps_icon.svg',
            id: 'steps-card',
            goalText: (value) => {
                return value == null || value < this.stepMax
                    ? this.translateService.instant('StepsDailyGoal', { stepGoal: this.stepMax })
                    : this.translateService.instant('StepsDailyGoalMet', { stepGoal: this.stepMax });
            },
            statType: StatType.Steps,
            tooltip: null,
            tooltipId: null,
            tooltipDescription: ''
        },
        {
            actionType: ActionType.Sleep,
            title: 'SleepStat',
            titleTranslated: () => {
                return this.translateService.instant('SleepStat');
            },
            defaultDisplayPosition: 2,
            valueFunction: (activities) => {
                if (activities.length === 0) {
                    return null;
                }
                const sortedActivities = activities.sort((first, second) => second.durationInSeconds - first.durationInSeconds);
                const hours = Math.floor(sortedActivities[0].durationInSeconds / 3600).toString();
                let minutes = Math.floor((sortedActivities[0].durationInSeconds % 3600) / 60).toString();
                if (minutes.length === 1) {
                    minutes = '0' + minutes;
                }
                return hours + ':' + minutes;
            },
            valuePercent: (value) => {
                this.totalSleepSec = this.getTotalSleepSec(value);
                this.sleepPercent = Math.floor((this.totalSleepSec / this.sleepMax) * 100);
                return this.sleepPercent;
            },
            getConvertedValue: (value) => {
                if (!value) {
                    return 0;
                }
                return value;
            },
            iconPath: 'svgs/statIcons/sleep_icon.svg',
            id: 'sleep-card',
            goalText: (value) => {
                this.totalSleepSec = this.getTotalSleepSec(value);
                const d = Number(this.sleepMax);
                const hours = Math.round((d / 3600) * 10) / 10;

                return this.totalSleepSec >= this.sleepMax ?
                    this.translateService.instant('SleepDailyGoalMet', { sleepGoal: hours }) :
                    this.translateService.instant('SleepDailyGoal', { sleepGoal: hours });
            },
            statType: StatType.Sleep,
            tooltip: null,
            tooltipId: null,
            tooltipDescription: ''
        },
        {
            actionType: ActionType.ActiveMinutes,
            title: 'ActiveMinutesStat',
            titleTranslated: () => {
                return this.translateService.instant('ActiveMinutesStat');
            },
            defaultDisplayPosition: 3,
            valueFunction: (activities) => {
                if (activities.length === 0) {
                    return null;
                }
                const sortedActivities = activities.sort((first, second) => second.activeMinutes - first.activeMinutes);
                return sortedActivities[0].activeMinutes.toFixed(0).toString();
            },
            valuePercent: (value) => {
                this.activePercent = Math.floor(((Number(value) * 60) / this.ActiveMax) * 100);
                return this.activePercent;
            },
            getConvertedValue: (value) => {
                if (!value) {
                    return 0;
                }
                return value;
            },
            iconPath: 'svgs/statIcons/active_mins_icon.svg',
            id: 'active-mins-card',
            goalText: (value) => {
                return value == null || Number(value) < this.ActiveMax / 60
                    ? this.translateService.instant('ActiveMinutesDailyGoal', { activeGoal: this.ActiveMax / 60 })
                    : this.translateService.instant('ActiveMinutesDailyGoalMet', { activeGoal: this.ActiveMax / 60 });
            },
            statType: StatType.ActiveMinutes,
            tooltip: null,
            tooltipId: null,
            tooltipDescription: ''
        },
        {
            actionType: ActionType.StateOfZen,
            title: 'MindfulMinutes',
            titleTranslated: () => {
                return this.translateService.instant('MindfulMinutes');
            },
            defaultDisplayPosition: 5,
            valueFunction: (activities) => {
                if (activities.length === 0) {
                    return null;
                }
                const sortedActivities = activities.sort((first, second) => second.duration - first.duration);
                return sortedActivities[0].duration.toFixed(0).toString();
            },
            valuePercent: () => {
                return null;
            },
            getConvertedValue: (value) => {
                if (!value) {
                    return 0;
                }
                return value;
            },
            iconPath: 'svgs/statIcons/mindful_mins_icon.svg',
            id: 'mindful-mins-card',
            goalText: () => {
                return null;
            },
            statType: StatType.MindfulMinutes,
            tooltip: null,
            tooltipId: null,
            tooltipDescription: ''
        },
        {
            actionType: ActionType.ManuallyEnteredDurationActivity,
            title: 'WorkoutsStat',
            titleTranslated: () => {
                return this.translateService.instant('WorkoutsStat');
            },
            defaultDisplayPosition: 6,
            valueFunction: (activities) => {
                if (activities.length === 0) {
                    return null;
                }

                const totalValues : number[] = [];
                const activitiesByType = this.groupActivitiesByType(activities);

                Object.keys(activitiesByType).forEach((type) => {
                    const activities = activitiesByType[type];
                    let totalValue = 0;
                    activities.forEach((activity) => {
                        totalValue += activity.duration;
                    });
                    totalValues.push(totalValue);
                });

                totalValues.sort((first, second) => second - first);
                return totalValues[0].toFixed().toString();
            },
            valuePercent: () => {
                return null;
            },
            getConvertedValue: (value) => {
                if (!value) {
                    return 0;
                }
                return value / 60;
            },
            iconPath: 'svgs/statIcons/workouts_icon.svg',
            id: 'workout-card',
            goalText: () => {
                return null;
            },
            statType: StatType.Workout,
            tooltip: null,
            tooltipId: null,
            tooltipDescription: ''
        },
        {
            actionType: ActionType.CaloriesConsumed,
            title: 'CaloriesConsumed',
            titleTranslated: () => {
                return this.translateService.instant('CaloriesConsumed');
            },
            defaultDisplayPosition: 7,
            valueFunction: (activities) => {
                const activity = activities.find((activity) => activity.isMostRecentlyReceived && activity.calories);

                return activity ? activity.calories.toString() : null;
            },
            valuePercent: () => {
                return null;
            },
            getConvertedValue: (value) => {
                if (!value) {
                    return 0;
                }
                return value;
            },
            iconPath: 'svgs/statIcons/energy_in_icon.svg',
            id: 'energy-in-card',
            goalText: () => {
                return null;
            },
            statType: StatType.CaloriesConsumed,
            tooltip: null,
            tooltipId: null,
            tooltipDescription: ''
        },
        {
            actionType: ActionType.CaloriesBurned,
            title: 'CaloriesBurned',
            titleTranslated: () => {
                return this.translateService.instant('CaloriesBurned');
            },
            defaultDisplayPosition: 8,
            valueFunction: (activities) => {
                if (activities.length === 0) {
                    return null;
                }
                const sortedActivities = activities.sort((first, second) => second.calories - first.calories);
                return sortedActivities[0].calories.toString();
            },
            valuePercent: () => {
                return null;
            },
            getConvertedValue: (value) => {
                if (!value) {
                    return 0;
                }
                return value;
            },
            iconPath: 'svgs/statIcons/energy_out_icon.svg',
            id: 'energy-out-card',
            goalText: () => {
                return null;
            },
            statType: StatType.CaloriesBurned,
            tooltip: null,
            tooltipId: null,
            tooltipDescription: ''
        },
        {
            actionType: ActionType.Weight,
            title: 'Weight',
            titleTranslated: () => {
                return this.translateService.instant('Weight');
            },
            defaultDisplayPosition: 9,
            valueFunction: (activities) => {
                const weightActivity = activities.find((activity) => activity.isMostRecentlyReceived && activity.weight);

                return weightActivity ? weightActivity.weight.toString() : null;
            },
            valuePercent: () => {
                return null;
            },
            getConvertedValue: (value: number | null) => {
                if (!value) {
                    return 0;
                }
                let tempValue: number | null = 0;
                switch (this.unitOfMeasurement) {
                case UnitOfMeasurement.Metric:
                    return value;
                case UnitOfMeasurement.Imperial:
                    tempValue = this.conversionService.convert(value, 'kg', 'lbs');
                    break;
                case UnitOfMeasurement.UK_Imperial:
                    tempValue = this.conversionService.convert(value, 'kg', 'st');
                    break;
                default:
                    return 0;
                }
                return tempValue ? parseFloat(tempValue.toFixed(1)) : 0;
            },
            iconPath: 'svgs/statIcons/weight_icon.svg',
            id: 'weight-card',
            goalText: () => {
                return null;
            },
            statType: StatType.Weight,
            tooltip: null,
            tooltipId: null,
            tooltipDescription: ''
        },
        {
            actionType: ActionType.Weight,
            title: 'WaistLabel',
            titleTranslated: () => {
                return this.translateService.instant('WaistHipSize');
            },
            defaultDisplayPosition: 10,
            valueFunction: (activities) => {
                const waistSizeActivity = activities.find((activity) => activity.isMostRecentlyReceived && activity.waistCircumference);

                return waistSizeActivity ? waistSizeActivity.waistCircumference.toString() : null;
            },
            valuePercent: () => {
                return null;
            },
            getConvertedValue: (value) => {
                if (!value) {
                    return 0;
                }
                let tempValue: number | null = 0;
                switch (this.unitOfMeasurement) {
                case UnitOfMeasurement.Metric:
                    return value;
                case UnitOfMeasurement.Imperial:
                case UnitOfMeasurement.UK_Imperial:
                    tempValue = this.conversionService.convert(value, 'cm', 'in');
                    break;
                default:
                    return 0;
                }
                return tempValue ? parseFloat(tempValue.toFixed(this.DECIMAL_POINTS)) : 0;
            },
            iconPath: 'svgs/statIcons/waist_hip_size_icon.svg',
            id: 'waist-size-card',
            goalText: () => {
                return null;
            },
            statType: StatType.WaistHipSize,
            tooltip: null,
            tooltipId: null,
            tooltipDescription: ''
        },
        {
            actionType: ActionType.Weight,
            title: 'HipLabel',
            titleTranslated: () => {
                return this.translateService.instant('WaistHipSize');
            },
            defaultDisplayPosition: 11,
            valueFunction: (activities) => {
                const hipSizeActivity = activities.find((activity) => activity.isMostRecentlyReceived && activity.hipCircumference);

                return hipSizeActivity ? hipSizeActivity.hipCircumference.toString() : null;
            },
            valuePercent: () => {
                return null;
            },
            getConvertedValue: (value) => {
                if (!value) {
                    return 0;
                }
                let tempValue: number | null = 0;
                switch (this.unitOfMeasurement) {
                case UnitOfMeasurement.Metric:
                    return value;
                case UnitOfMeasurement.Imperial:
                case UnitOfMeasurement.UK_Imperial:
                    tempValue = this.conversionService.convert(value, 'cm', 'in');
                    break;
                default:
                    return 0;
                }
                return tempValue ? parseFloat(tempValue.toFixed(this.DECIMAL_POINTS)) : 0;
            },
            iconPath: 'svgs/statIcons/waist_hip_size_icon.svg',
            id: 'hip-size-card',
            goalText: () => {
                return null;
            },
            statType: StatType.WaistHipSize,
            tooltip: null,
            tooltipId: null,
            tooltipDescription: ''
        },
        {
            actionType: ActionType.Weight,
            title: 'BloodPressure',
            titleTranslated: () => {
                return this.translateService.instant('BloodPressure');
            },
            defaultDisplayPosition: 12,
            valueFunction: (activities) => {
                const bloodPressureActivity = activities.find((activity) => activity.isMostRecentlyReceived && activity.bloodPressureDiastolic && activity.bloodPressureSystolic);

                return bloodPressureActivity ? (
                    bloodPressureActivity.bloodPressureSystolic.toFixed(0).toString() +
                    '/' +
                    bloodPressureActivity.bloodPressureDiastolic.toFixed(0).toString()
                ) : null;
            },
            valuePercent: () => {
                return null;
            },
            getConvertedValue: (value) => {
                if (!value) {
                    return 0;
                }
                return value;
            },
            iconPath: 'svgs/statIcons/blood_pressure_icon.svg',
            id: 'blood-pressure-card',
            goalText: () => {
                return null;
            },
            statType: StatType.BloodPressure,
            tooltip: null,
            tooltipId: null,
            tooltipDescription: ''
        },
        {
            actionType: ActionType.Weight,
            title: 'CholesterolStat',
            titleTranslated: () => {
                return this.translateService.instant('CholesterolStat');
            },
            defaultDisplayPosition: 13,
            valueFunction: (activities) => {
                const cholesterolActivity = activities.find((activity) => activity.isMostRecentlyReceived && (activity.cholesterolTotal || activity.cholesterolTriglyceride));

                if (!cholesterolActivity) {
                    return null;
                }

                return cholesterolActivity.cholesterolTotal ? cholesterolActivity.cholesterolTotal.toString() : cholesterolActivity.cholesterolTriglyceride.toString();
            },
            valuePercent: () => {
                return null;
            },
            getConvertedValue: (value, context) => {
                if (!value) {
                    return 0;
                }
                switch (this.unitOfMeasurement) {
                case UnitOfMeasurement.Metric:
                case UnitOfMeasurement.UK_Imperial:
                    return Number(value.toFixed(this.DECIMAL_POINTS));
                case UnitOfMeasurement.Imperial:
                    return Number(this.conversionService.convert(value, 'mmol/L', 'mg/dL', context).toFixed(this.DECIMAL_POINTS));
                default:
                    return 0;
                }
            },
            iconPath: 'svgs/statIcons/cholesterol_icon.svg',
            id: 'cholesterol-card',
            goalText: () => {
                return null;
            },
            statType: StatType.Cholesterol,
            tooltip: null,
            tooltipId: null,
            tooltipDescription: ''
        },
        {
            actionType: ActionType.Weight,
            title: 'GlucoseStat',
            titleTranslated: () => {
                return this.translateService.instant('GlucoseStat');
            },
            defaultDisplayPosition: 14,
            valueFunction: (activities) => {
                const glucoseActivity = activities.find((activity) => activity.isMostRecentlyReceived && (activity.glucoseFasting || activity.glucoseNonFasting));

                if (!glucoseActivity) {
                    return null;
                }

                return glucoseActivity.glucoseFasting ? glucoseActivity.glucoseFasting.toFixed(1).toString() : glucoseActivity.glucoseNonFasting.toFixed(1).toString();
            },
            valuePercent: () => {
                return null;
            },
            getConvertedValue: (value) => {
                if (!value) {
                    return 0;
                }
                switch (this.unitOfMeasurement) {
                case UnitOfMeasurement.Metric:
                case UnitOfMeasurement.UK_Imperial:
                    return Math.round(value * 10) / 10;
                case UnitOfMeasurement.Imperial:
                    return Math.round(this.conversionService.convert(value, 'mmol/L', 'mg/dL', 'BloodGlucose'));
                default:
                    return 0;
                }
            },
            iconPath: 'svgs/statIcons/glucose_icon.svg',
            id: 'glucose-card',
            goalText: () => {
                return null;
            },
            statType: StatType.Glucose,
            tooltip: null,
            tooltipId: null,
            tooltipDescription: ''
        },
        {
            actionType: ActionType.Weight,
            title: 'A1CStat',
            titleTranslated: () => {
                return this.translateService.instant('A1CStat');
            },
            defaultDisplayPosition: 15,
            valueFunction: (activities) => {
                const a1cActivity = activities.find((activity) => activity.isMostRecentlyReceived && activity.a1c);

                return a1cActivity ? Number(a1cActivity.a1c.toFixed(1)).toString() : null;
            },
            valuePercent: () => {
                return null;
            },
            getConvertedValue: (value) => {
                if (!value) {
                    return 0;
                }
                return value;
            },
            iconPath: 'svgs/statIcons/hemoglobin_a1c_icon.svg',
            id: 'a1c-card',
            goalText: () => {
                return null;
            },
            statType: StatType.A1C,
            tooltip: null,
            tooltipId: null,
            tooltipDescription: ''
        }
    ];

    private groupActivitiesByType(activityList : ActivityModel[]) : Record<string, ActivityModel[]> {
        const groupedActivities:Record<string, ActivityModel[]> = {};

        activityList.forEach((activity : ActivityModel) => {
            const groupedActivitiesWithThisType = groupedActivities[activity.activityType];

            if (groupedActivitiesWithThisType === undefined) {
                groupedActivities[activity.activityType] = [activity];
            } else {
                groupedActivities[activity.activityType].push(activity);
            }
        });

        return groupedActivities;
    }

    private buildDefaultStatSettingByActionType() {
        this.getDefaultStatSettings().forEach((setting) => {
            if (!this.defaultStatSettingsByActionType.has(setting.actionType)) {
                this.defaultStatSettingsByActionType.set(setting.actionType, new Array<StatSettingsModel>());
            }
            this.defaultStatSettingsByActionType.get(setting.actionType)?.push(setting);
        });
    }

    private buildDefaultStatSettingByStatType() {
        this.getDefaultStatSettings().forEach((setting: StatSettingsModel) => {
            if (!this.defaultStatSettingsByStatType.has(setting.statType)) {
                this.defaultStatSettingsByStatType.set(setting.statType.toString(), setting);
            }
        });
    }

    private getTotalSleepSec(value: string | null) {
        if (value === null) {
            return 0;
        }
        const [hours, minutes] = value.split(':');
        return +hours * 3600 + +minutes * 60;

    }
}
