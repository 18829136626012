import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ConversionService {

    /* Temperature variables */
    private FAHRENHEIT_ZERO_OFFSET = 32;
    private CELSIUS_TO_FAHRENHEIT = 1.8;
    private FAHRENHEIT_TO_CELSIUS = 0.5555555556;

    private conversionTable: Record<string, any> = {
        kg: {
            lbs: 2.20462,
            st: 0.157473
        },
        lbs: {
            kg: 0.453592,
            st: 0.0714286
        },
        st: {
            kg: 6.35029,
            lbs: 14.0
        },
        'mmol/L': {
            BloodGlucose: {
                'mg/dL': 18.0
            },
            Cholesterol: {
                'mg/dL': 38.67
            },
            Triglyceride: {
                'mg/dL': 88.57
            }
        },
        'mg/dL': {
            BloodGlucose: {
                'mmol/L': 0.05555556
            },
            Cholesterol: {
                'mmol/L': 0.02585984
            },
            Triglyceride: {
                'mmol/L': 0.011290505
            }
        },
        ft: {
            in: 12,
            cm: 30.48
        },
        cm: {
            in: 0.393701,
            ft: 0.0328084
        },
        in: {
            ft: 0.0833333,
            cm: 2.54
        }
    };

    convert(value: number, from: string, to: string, context?: string | null): number {
        if (from === to) {
            return value;
        }

        if (context && this.conversionTable[from] && this.conversionTable[from][context] && this.conversionTable[from][context][to]) {
            return value * this.conversionTable[from][context][to];
        } else if (this.conversionTable[from] && this.conversionTable[from][to]) {
            return value * this.conversionTable[from][to];
        }

        throw new Error('Missing conversion factor');
    }

    convertCelsiusToFahrenheit(value: number) {
        return value * this.CELSIUS_TO_FAHRENHEIT + this.FAHRENHEIT_ZERO_OFFSET;
    }

    convertFahrenheitToCelsius(value: number) {
        return (value - this.FAHRENHEIT_ZERO_OFFSET) * this.FAHRENHEIT_TO_CELSIUS;
    }
}
