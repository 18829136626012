import { Injectable } from '@angular/core';

import {
    HomepageAvailableGameRewardVM,
    RewardableActionType,
    RewardType
} from '@genesis-frontend/genesis-utilities';

@Injectable()
export class HomepageModuleRewardService {

    private readonly REWARDS_STAR_ICON = '/img/rewards-star-icon.svg';
    private readonly REWARDS_CASH_ICON = '/img/rewards-cash-icon.svg';

    setModuleReward(
        homepageModulesRewards: HomepageAvailableGameRewardVM[] = [],
        rewardableActionType: RewardableActionType | null,
        eventCode: string
    ): HomepageAvailableGameRewardVM | undefined {
        return homepageModulesRewards.find(
            (reward: HomepageAvailableGameRewardVM) =>
                this.matchesByRewardableActionType(reward, rewardableActionType) &&
                this.matchesByEventCode(reward, eventCode) &&
                this.isVisible(reward)
        );
    }

    setRewardText(reward: HomepageAvailableGameRewardVM): string {
        return `${reward.value} ${reward.rewardTypeDisplay}`;
    }

    setRewardIcon(reward: HomepageAvailableGameRewardVM): string {
        if (reward.rewardType === RewardType.Points) {
            return this.REWARDS_STAR_ICON;
        }
        if (reward.rewardType === RewardType.PulseCash) {
            return this.REWARDS_CASH_ICON;
        }
        return '';
    }

    private matchesByRewardableActionType(reward: HomepageAvailableGameRewardVM, rewardableActionType: RewardableActionType | null): boolean {
        return reward.rewardableActionType === rewardableActionType;
    }

    private matchesByEventCode(reward: HomepageAvailableGameRewardVM, eventCode: string): boolean {
        return !eventCode || reward.eventCode === eventCode;
    }

    private isVisible(reward: HomepageAvailableGameRewardVM): boolean {
        const { rewardableActionType, completionCondition } = reward;

        const actionTypesToHide: Set<RewardableActionType> = new Set([
            RewardableActionType.JoinPersonalChallenge,
            RewardableActionType.JoinCompetition,
            RewardableActionType.CreatePersonalChallenge
        ]);

        if (actionTypesToHide.has(rewardableActionType) && completionCondition?.completed) {
            return false;
        }

        return true;
    }

}
