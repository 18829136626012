import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilestackService } from '@filestack/angular';
import { TranslateModule } from '@ngx-translate/core';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';

import { PillarAccordionComponent } from './components/pillar-accordion/pillar-accordion.component';
import {
    PillarRecommendedTopicsListComponent
} from './components/pillar-recommended-topics-list/pillar-recommended-topics-list.component';
import {
    PillarTopicSelectItemComponent
} from './components/pillar-topic-select-item/pillar-topic-select-item.component';
import { PillarTopicsOfInterestModalComponent } from './components/pillar-topics-of-interest-modal.component';
import { InterestModalService } from './interest-modal.service';
import { MemberTopicsOfInterestsService } from './service/member-topics-of-interests.service';
import { PrivacyProtectionModalService } from './service/privacy-protection-modal.service';
import { AgreementModalModule } from '../agreement-modal/agreement-modal.module';
import { AgreementModalService } from '../agreement-modal/agreement-modal.service';
import { VpgrooveModule } from '@engineering/vpgroove-angular';
import { MemberAgreementService, PillarSummaryService } from '@genesis-frontend/genesis-data-access';
import { FilePickerService, GenesisDirectivesModule, GenesisPipesModule } from '@genesis-frontend/genesis-utilities';

@NgModule({
    imports: [
        CommonModule,
        VpgrooveModule,
        GenesisDirectivesModule,
        TranslateModule,
        AgreementModalModule,
        ModalModule.forRoot(),
        GenesisPipesModule
    ],
    declarations: [
        PillarTopicsOfInterestModalComponent,
        PillarRecommendedTopicsListComponent,
        PillarAccordionComponent,
        PillarTopicSelectItemComponent
    ],
    providers: [
        BsModalService,
        InterestModalService,
        FilestackService,
        FilePickerService,
        MemberTopicsOfInterestsService,
        MemberAgreementService,
        AgreementModalService,
        PrivacyProtectionModalService,
        PillarSummaryService
    ],
    exports: []
})
export class InterestModalModule {}
