import { Inject, Injectable } from '@angular/core';
import { lowerCase, startCase } from 'lodash';
import { OverridedMixpanel } from 'mixpanel-browser';

import { AuthenticatedMemberService } from '../authentication';
import { environment } from '../config/environments/environment';
import { DateUtils } from '../lib/date-utils';

const PLATFORM = 'web';
const PROPERTY_BLACKLIST = ['$city', '$region', 'mp_country_code', '$country_code'];

@Injectable({
    providedIn: 'root'
})
export class AnalyticsTrackingService {
    private excludeFromTracking: boolean | undefined;
    private mixpanelObject: any;

    constructor(
        @Inject('mixpanel') private mixpanel: OverridedMixpanel,
        private authenticatedMemberService: AuthenticatedMemberService
    ) { }

    init(member?: any): void {
        // opt_out_tracking_by_default has to be set because Mixpanel doesn't let us call its opt in or out functions without failing
        this.mixpanel.init(environment.mixpanel.token, {
            ip: false,
            property_blacklist: PROPERTY_BLACKLIST,
            opt_out_tracking_by_default: true,
            ignore_dnt: true,
            cookie_expiration: 1,
            loaded: () => {
                this.campaignParams();
            }
        });

        this.refreshMixpanelOptInStatus();
        this.mixpanel.identify(this.getExternalMemberId().toString());
        this.mixpanelObject = this.mixpanel;

        if (member) {
            const properties = this.getUserAndSuperProperties(member);
            this.mixpanel.register(properties);
        }
    }

    getQueryParam(url: any, param: any) {
        // Expects a raw URL
        const param1 = new RegExp(/[[]/);
        const param2 = new RegExp('/[]]/');

        param = param.replace(param1, '[').replace(param2, ']');
        const regexS = '[?&]' + param + '=([^&#]*)';
        const regex = new RegExp(regexS);
        const results = regex.exec(url);
        // @ts-ignore
        if (results === null || (results && typeof results[1] !== 'string' && results[1].length)) {
            return '';
        }
        return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
    }

    campaignParams() {
        const campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ');
        let keyword = '';
        const params = {};
        const first_params = {};
        let index = 0;
        for (index = 0; index < campaign_keywords.length; ++index) {
            keyword = this.getQueryParam(document.URL, campaign_keywords[index]);
            if (keyword.length) {
                // @ts-ignore
                params[campaign_keywords[index] + ' [last touch]'] = keyword;
            }
        }
        for (index = 0; index < campaign_keywords.length; ++index) {
            keyword = this.getQueryParam(document.URL, campaign_keywords[index]);
            if (keyword.length) {
                // @ts-ignore
                first_params[campaign_keywords[index] + ' [first touch]'] = keyword;
            }
        }
        this.mixpanel.register(params);
    }

    sendData(actionName: string, trackingData: any = {}): void {
        const data = {
            platform: PLATFORM,
            member_timestamp: this.getCurrentDateTime()
        };

        this.track(actionName, { ...data, ...trackingData });
    }

    getStringAsSentence(someString: any) {
        return startCase(someString).toLowerCase();
    }

    getMixpanelObject() {
        return this.mixpanelObject;
    }

    getUserAndSuperProperties(member: any) {
        const memberAge = new Date().getFullYear() - new Date(member.dateofbirth).getFullYear();
        const memberCountry: any = this.authenticatedMemberService.getMemberCountry();
        const listOfLanguages: any = this.authenticatedMemberService.getLanguages();
        const memberLanguage = listOfLanguages.find((language: any) => language.name === member.language).englishNative;

        const properties = {
            external_id: member.externalId,
            sponsor: lowerCase(member.sponsor.name),
            sponsor_id: member.sponsorId,
            member_type: lowerCase(member.memberType),
            country: lowerCase(memberCountry),
            country_id: member.countryId,
            language: lowerCase(memberLanguage),
            language_code: member.language,
            created_date: member.createdDate,
            gender: lowerCase(member.genderIdentity),
            age: memberAge
        };
        this.mixpanel.people.set(properties);
        return properties;
    }

    private getCurrentDateTime() {
        return DateUtils.format(
            this.authenticatedMemberService.getCurrentTimezone().getTimeForMemberNow(),
            'YYYY-MM-DDTHH:MM:SS'
        );
    }

    private getExternalMemberId(): number {
        return this.authenticatedMemberService.getMember().getExternalId();
    }

    /*
     * If sponsor settings aren't found (e.g. if the member hasn't logged in and we can't identify them) we default to not tracking.
     * This status may need to be refreshed depending on whether excludeFromTracking changes from undefined to false when the member logs in
     */
    private refreshMixpanelOptInStatus() {
        this.excludeFromTracking = this.authenticatedMemberService.getSponsorSettings().excludeMixpanelTracking;
        if (this.excludeFromTracking === false) {
            this.mixpanel.opt_in_tracking();
        } else {
            this.mixpanel.opt_out_tracking();
        }
    }

    private track(actionName: string, trackingData: any = {}): void {
        if (this.excludeFromTracking === false) {
            this.mixpanel.track(actionName, trackingData);
        }
    }
}
