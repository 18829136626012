import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'highlight' })
export class HighlightSearchPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(value: string, keywords: string, highlightFromStartOnly: boolean = false): SafeHtml {

        if (!keywords || !value) {
            return value;
        }

        const pattern = keywords.replace(/\[-\[\]\/\\\{\}\(\)*\+\?.^$|]/g, '\\$&')
            .split(' ').filter((t) => t.length > 0)
            .join('|');

        let regexPattern = pattern;
        if (highlightFromStartOnly) {
            regexPattern = `\\b${pattern}`;
        }

        const highlightedValue = value.replace(new RegExp(regexPattern, 'gi'), (match) => `<span style="color: #0E7B8E">${match}</span>`);
        return this.sanitizer.bypassSecurityTrustHtml(highlightedValue);
    }
}
