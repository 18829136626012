import { Pipe, PipeTransform, Injectable } from '@angular/core';

@Pipe({ name: 'filter' })
@Injectable()
export class FilterPipe implements PipeTransform {

    transform<T = any>(items: T[] | null | undefined, term: string | (number | null)[] | string[], filterMatched: boolean = true): T[] {

        if (!items) {
            return [];
        }

        if (!term) {
            return items;
        }

        if (typeof term === 'string') {
            const filter = term.toLowerCase();
            return this.filter(items, filter, filterMatched);
        }

        if (Array.isArray(term) && term.length) {
            let filteredArray = [] as T[];
            term.forEach((id) => {
                if (id !== null) {
                    const result = this.filter(items, id.toString(), filterMatched);
                    filteredArray = [...filteredArray, ...result];
                }
            });
            return filteredArray;
        }

        return [];
    }

    private filter<T>(items: T[], term: string, filterMatched: boolean): T[] {
        return items.filter((item) => {
            return filterMatched ? this.checkInside(item, term) : !this.checkInside(item, term);
        });
    }

    private contains(value: any, term: string): boolean {
        return String(value).toLowerCase().includes(term.toLowerCase());
    }

    private checkInside(item: any, term: string): boolean {

        if (!item) {
            return false;
        }

        if (typeof item === 'string' || typeof item === 'number') {
            const stringItem = item.toString();
            return this.contains(stringItem, term);
        }

        const keys = Object.keys(item);

        for (const key of keys) {
            const value = item[key];
            if (this.checkInside(value, term)) {
                return true;
            }
        }

        return false;
    }
}
