import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, ViewChild } from '@angular/core';
import { debounce } from 'lodash';
import { finalize } from 'rxjs/operators';

import { ContestTeam } from '../../../interface/contest-team.model';
import { GlobalChallengeCardCarouselDataModel } from '../../../interface/global-challenges-card-carousel-data.model';
import { MemberContestOnboardingBlockerService } from '../../member-contest-onboarding-blocker.service';
import { VpGoService } from '../../vp-go.service';
import { Member, TabType, find, concat } from '@genesis-frontend/genesis-utilities';

@Component({
    templateUrl: './join-a-team.template.html',
    styleUrls: ['./join-a-team.component.scss']
})
export class GlobalChallengesJoinATeamCardComponent implements AfterViewInit, OnDestroy {
    MIN_NUM_OF_LETTERS = 2;
    MIN_NUM_OF_TEAMS_FOR_FEELING_LUCKY_OPTION = 6;
    SEARCH_DEBOUNCE = 500;
    allAvailableTeams: ContestTeam[] = [];
    teamInvites: ContestTeam[] = [];
    searchText = '';
    numberOfResults: number | null = null;
    memberNameToDisplay!: string | null;
    isDataLoaded = true;
    showLeftArrow = false;
    showRightArrow = false;
    TabTypeEnum = TabType;
    selectedTab: TabType = TabType.OpenTeams;
    shouldDisableInfiniteScroll = false;
    isSearching = false;

    @ViewChild('createYourOwnLinkParent') createYourOwnLinkParent!: ElementRef;

    constructor(
        private vpGoService: VpGoService,
        @Inject('data') public data: GlobalChallengeCardCarouselDataModel,
        private memberContestOnboardingBlockerService: MemberContestOnboardingBlockerService
    ) {
        this.searchTeams = debounce(this.searchTeams, this.SEARCH_DEBOUNCE);
    }

    ngOnInit() {
        this.setCardData();
    }

    ngAfterViewInit() {
        this.createYourOwnLinkParent.nativeElement.querySelector('a').addEventListener('click', this.goToCreateTeamCard.bind(this));
    }

    ngOnDestroy(): void {
        this.createYourOwnLinkParent.nativeElement.querySelector('a').removeEventListener('click', this.goToCreateTeamCard.bind(this));
    }

    setCardData() {
        this.allAvailableTeams = this.data.teams ? this.data.teams : [];
        this.checkIfAllTeamsAreLoaded();
        this.shouldDisableInfiniteScroll = this.data.allTeamsLoaded;
        this.teamInvites = this.data.teamInvites;
        if (this.data.lastPickedTeamPageTab) {
            this.selectedTab = this.data.lastPickedTeamPageTab;
        } else {
            this.selectedTab = this.teamInvites.length ? this.TabTypeEnum.TeamInvites : this.TabTypeEnum.OpenTeams;
        }
        if (this.selectedTab === this.TabTypeEnum.TeamInvites) {
            this.shouldDisableInfiniteScroll = true;
        }
        this.data.lastPickedTeamPageTab = this.selectedTab;
        this.getTeamAdmins();
        this.showRightArrow = this.data.isCreateATeamCardVisited;
        this.data.isJoinATeamCardVisited = true;
    }

    goToCreateTeamCard() {
        this.data.goToCard(this.vpGoService.VpGoCards.CREATE_A_TEAM_CARD_INDEX);
    }

    searchTeams() {
        this.isDataLoaded = false;
        this.numberOfResults = null;
        if (this.searchText.length > this.MIN_NUM_OF_LETTERS) {
            this.isSearching = true;
            this.shouldDisableInfiniteScroll = true;
            this.memberContestOnboardingBlockerService
                .searchForTeamsAndFriends(this.data.contest.contestId, this.searchText)
                .pipe(finalize(() => (this.isDataLoaded = true)))
                .subscribe((response) => {
                    this.allAvailableTeams = response;
                    this.numberOfResults = this.allAvailableTeams.length;
                    this.setMemberNameToDisplay(this.allAvailableTeams);
                    this.isDataLoaded = true;
                });
        } else {
            this.resetSearch();
        }
    }

    getFirstAndLastName(member: Member): string {
        return member.firstname + ' ' + member.lastname;
    }

    isSearchParamContainedInFirstAndLastName(member: any) {
        return this.getFirstAndLastName(member).toLowerCase().includes(this.searchText.toLowerCase());
    }

    setMemberNameToDisplay(teams: any[]) {
        teams.forEach((team) => {
            const filteredTeamMember = team.teamMembers.find((member: Member) => this.isSearchParamContainedInFirstAndLastName(member));

            if (filteredTeamMember) {
                team.memberNameToDisplay = this.getFirstAndLastName(filteredTeamMember);
                this.memberNameToDisplay = team.memberNameToDisplay;
            }
        });
    }

    goToNextCard() {
        this.data.goToCard(this.vpGoService.VpGoCards.CREATE_A_TEAM_CARD_INDEX);
    }

    resetSearch() {
        this.allAvailableTeams = this.data.teams ? this.data.teams : [];
        this.shouldDisableInfiniteScroll = this.data.allTeamsLoaded;
        this.isDataLoaded = true;
        this.memberNameToDisplay = null;
        this.isSearching = false;
    }

    clearSearch() {
        this.searchText = '';
        this.resetSearch();
    }

    selectTab(selectedTab: any) {
        this.selectedTab = selectedTab;
        this.data.lastPickedTeamPageTab = this.selectedTab;
        if (this.selectedTab === this.TabTypeEnum.OpenTeams && !this.data.allTeamsLoaded) {
            this.shouldDisableInfiniteScroll = false;
        }
    }

    getTeamAdmins() {
        this.data.teamInvites.forEach((team) => {
            team.teamAdmin = find(team.teamMembers, { memberId: team.teamAdminMemberId })?.displayName;
        });
    }

    loadMoreTeams() {
        if (this.data.memberId && this.searchText.length <= this.MIN_NUM_OF_LETTERS) {
            this.isDataLoaded = false;
            this.memberContestOnboardingBlockerService
                .getSuggestedTeamsForContest(
                    this.data.memberId,
                    this.data.contest.contestId,
                    this.data.suggestedTeamPage,
                    this.data.suggestedTeamPageSize
                )
                .pipe(finalize(() => (this.isDataLoaded = true)))
                .subscribe((response) => {
                    this.data.teams = concat(this.data.teams, response);
                    this.allAvailableTeams = concat(this.allAvailableTeams, response);
                    if (response.length < this.data.suggestedTeamPageSize) {
                        this.shouldDisableInfiniteScroll = true;
                        this.data.allTeamsLoaded = true;
                    }
                    this.data.suggestedTeamPage++;
                });
        }
    }

    checkIfAllTeamsAreLoaded() {
        if (this.data.teams && this.data.teams.length < this.data.suggestedTeamPageSize) {
            this.shouldDisableInfiniteScroll = true;
            this.data.allTeamsLoaded = true;
        }
    }
}
