import { CoachingModuleState } from '../enums/coaching-module-state.enum';

const details = {
    Message: {
        state: CoachingModuleState.Message,
        title: 'NewMessagesColon',
        titleId: 'new-messages',
        content: 'YourCoachMessagedYou',
        contentId: 'message-content',
        buttonType: 'primary',
        buttonLabel:'ViewMessages',
        hasButtonAriaLabel: true,
        buttonId: 'view-messages-btn',
        Coaching_clicks: 'View Messages',
        Coaching_state: 'New messages'
    },
    Task: {
        state: CoachingModuleState.Task,
        title:'MyProgressLower',
        titleId: 'my-progress',
        headercontent: 'SetGoals',
        contentTitle:'NumberOfGoals',
        content:'LeftToComplete',
        contentId: 'task-content',
        buttonType: 'secondary',
        buttonLabel:'ViewGoals',
        hasButtonAriaLabel: true,
        buttonId: 'view-goals-btn',
        icon: '',
        imageSource: 'img/coaching/coaching-icon.svg',
        Coaching_clicks: 'View Goals',
        Coaching_state: 'To Do tasks > 0'
    },
    EmptyTask: {
        title: 'FeelingStuck',
        titleId: 'feeling-stuck',
        content: 'UseMessaging',
        contentId: 'empty-task-content',
        buttonType: 'primary',
        buttonLabel: 'MessageMyCoach',
        buttonId: 'use-messaging-btn',
        hasButtonAriaLabel: false,
        Coaching_clicks: 'Message My Coach',
        Coaching_state: 'To Do tasks = 0',
        imageSource: 'img/coaching/coaching-zero-to-do.svg'
    },
    Unengaged: {
        title: 'Coaching',
        titleId: 'coaching',
        content: 'PartnerWithCoach',
        contentId: 'unengaged-content',
        buttonType: 'primary',
        buttonLabel: 'ConnectWithCoach',
        buttonId: 'coach-connect-btn',
        hasButtonAriaLabel: false,
        imageSource: 'img/coaching/empty-state.svg',
        Coaching_clicks: 'Connect With A Coach',
        Coaching_state: 'Empty/unengaged'
    },
    Appointment: {
        title: '',
        titleId: '',
        buttonType: '',
        buttonLabel: '',
        buttonId: '',
        hasButtonAriaLabel: true,
        content: '',
        contentId: '',
        Coaching_clicks: '',
        Coaching_state: ''
    },
    PastAppointment: {
        title: '',
        titleId: '',
        buttonType: '',
        buttonLabel: '',
        buttonId: '',
        hasButtonAriaLabel: true,
        content: '',
        contentId: '',
        Coaching_clicks: '',
        Coaching_state: ''
    },
    PreEngaged: {
        state: CoachingModuleState.PreEngaged,
        title:'StartMessagingToday',
        titleId: 'start-messaging',
        content:'MatchedWithCoach',
        contentId: 'preengaged-content',
        buttonType: 'primary',
        buttonLabel:'SendAMessage',
        hasButtonAriaLabel: true,
        buttonId: 'view-messages-btn',
        Coaching_clicks: 'Send A Message',
        Coaching_state: 'Multimodal default entry point'
    },
    Pending: {
        state: CoachingModuleState.Pending,
        title: 'StatusPending',
        titleId: 'status-pending',
        content:'MatchingYouWithCoach',
        contentId: 'pending-content',
        buttonType: 'secondary',
        buttonLabel:'GoToCoaching',
        buttonId: 'go-to-coaching-btn',
        hasButtonAriaLabel: false,
        icon: 'fas fa-spinner',
        Coaching_clicks: 'Go To Coaching',
        Coaching_state: 'Multimodal waiting room'
    }
};

export {
    details
};
