import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FormatHtmlContentService {

    formatLinkContent(content: string): HTMLDivElement {
        const textWrapperDiv: HTMLDivElement = document.createElement('div');
        textWrapperDiv.innerHTML = content;
        const anchorLinks: HTMLCollectionOf<HTMLAnchorElement> = textWrapperDiv.getElementsByTagName('a');
        Array.from(anchorLinks).forEach((anchors: HTMLAnchorElement) => {
            anchors.classList.add('vp-text-link');
            anchors.setAttribute('target', '_blank');
        });

        return textWrapperDiv;
    }

    removeHtml(text: string): string {
        return text
            .replace(/<[^>]+>/gm, '')
            .replace(/&nbsp;/gm, ' ');
    }

    separateLinks(content: string): HTMLDivElement {
        const textWrapperDiv: HTMLDivElement = document.createElement('div');
        textWrapperDiv.innerHTML = content;
        const anchorLinks: HTMLCollectionOf<HTMLAnchorElement> = textWrapperDiv.getElementsByTagName('a');
        Array.from(anchorLinks).forEach(this.replaceTagWithNewDiv);

        return textWrapperDiv;
    }

    private replaceTagWithNewDiv(anchor: HTMLAnchorElement) {
        const newDiv: HTMLDivElement = document.createElement('div');
        const text = anchor.innerText;
        const href = anchor.getAttribute('href');
        const textP = document.createElement('p');
        const pipeA = document.createElement('a');
        const hrefA = document.createElement('a');
        if (text !== href) {
            textP.innerText = text;
            pipeA.innerHTML = ' | ';
            hrefA.innerHTML = href ?? '';
            newDiv.appendChild(textP);
            newDiv.appendChild(pipeA);
            newDiv.appendChild(hrefA);
            anchor.replaceWith(newDiv);
        }
    }
}
