export enum ChatType {
    PersonalChallenge = 'PersonalChallenge',
    Challenge = 'Challenge',
    TeamChallenge = 'TeamChallenge',
    TrackerChallenge = 'TrackerChallenge',
    Coaching = 'Coaching',
    GoalChallenge = 'GoalChallenge',
    CrossSponsorChallenge = 'CrossSponsorChallenge',
    Info = 'Info'
}
