import { BehaviorSubject, Observable } from 'rxjs';

export class AsyncSessionStore<T> {
    private readonly storeSubject: BehaviorSubject<T>;

    constructor(initValue: T) {
        this.storeSubject = new BehaviorSubject<T>(initValue);
    }

    set(observable: Observable<T>) {
        observable.subscribe((producedValue: T) => {
            this.storeSubject.next(producedValue);
        });
    }

    get(): Observable<T> {
        return this.storeSubject.asObservable();
    }
}
