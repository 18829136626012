import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ResourceCenterService {

    constructor(private httpClient: HttpClient) {}

    getResourceCenterAvailability(distributorId: any) {
        return this.httpClient.get(`/api/client-admin/distributors/${distributorId}/resource-center`);
    }
}
