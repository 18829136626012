<div id="media-player-container" class="video-container" #videoContainerElement [style.height.px]="aspectRatioHeight">
    <div *ngIf="!hasError"
         [hidden]="loading"
         #brightcove
         class="brightcove">
    </div>
    <div *ngIf="hasError && !loading"
         class="video-error-message">
        <div>
            <i class="fa fa-exclamation-triangle error-icon"
               [attr.aria-hidden]="true">
            </i>
            <p class="error-header">
                {{ errorHeader | translate }}
            </p>
            <p class="error-description">
                {{ errorDescription | translate }}
            </p>
        </div>
    </div>
</div>

