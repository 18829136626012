import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticatedMemberService, ClaimsData, Member } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class ClaimsService {

    member: Member;

    constructor(
        private httpClient: HttpClient,
        private authenticatedMemberService: AuthenticatedMemberService
    ) {
        this.member = this.authenticatedMemberService.getAuthenticatedMember();
    }

    getClaimantInformation(): Observable<ClaimsData> {
        return this.httpClient.get<ClaimsData>(`/claims-api/claimant/member-info/${ this.member.id }`)
            .pipe(
                catchError(this.handleError())
            );
    }

    getClaimantOptOutData(): Observable<any> {
        return this.httpClient.get<any>(`/claims-api/claims-member-opt-out/${ this.member.id }`)
            .pipe(
                catchError(this.handleError())
            );
    }

    optOutMemberForClaimsData() {
        return this.httpClient.delete<any>(`/claims-api/claimant/member-info/${ this.member.id }`)
            .pipe(
                catchError(this.handleError())
            );
    }

    handleError() {
        return (err: any) => {
            return of(err);
        };
    }
}
