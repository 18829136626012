<div *ngIf="display"
     class="card"
     [attr.role]="role"
     [attr.tabindex]="tabIndex"
     [attr.aria-label]="title + (reward?.text ? ',' + reward?.text : '')"
     [ngClass]="classNames"
     (vpg-interactive-element)="emitCardClicked()"
     [role]="role"
     [tabindex]="tabIndex"
     [id]="'card-with-action-' + eventCode">
    <div class="card-info"
         [id]="'card-info-' + eventCode">
        <h5 class="card-title h5 text-transform-none"
            [id]="'card-title-' + eventCode">
            {{ title }}
        </h5>
        <div *ngIf="reward"
             class="card-reward"
             [id]="'card-reward-' + eventCode">
            <vpg-image
                [imageType]="iconImageType"
                [imageSource]="reward.iconSource">
            </vpg-image>
            <span class="card-reward-value"
                  [id]="'card-reward-value-' + eventCode">
                {{ reward.text }}
            </span>
        </div>
    </div>
    <div *ngIf="completed;then completedGraphic else linkGraphic" class="card-complete"></div>
    <ng-template #completedGraphic>
        <vpg-image
            [id]="'completed-checkmark' + eventCode"
            [imageType]="iconImageType"
            [imageSource]="completedCheckmark | assetUrl">
        </vpg-image>
    </ng-template>
    <ng-template #linkGraphic>
        <vpg-icon-next
            *ngIf="card.url"
            icon="chevron-right"
            size="2x"
            aria-hidden="true"
        ></vpg-icon-next>
    </ng-template>
</div>
