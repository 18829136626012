import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

import { Member, MemberOrgCount, CastleService } from '@genesis-frontend/genesis-utilities';

@Injectable({
    providedIn: 'root'
})
export class MemberService {
    constructor(
        private httpClient: HttpClient,
        private castleService: CastleService
    ) { }

    saveAuthenticatedMember(member: Member): Observable<Member> {
        return this.httpClient.put<Member>(`/api/members/${ member.id }`, member);
    }

    saveAuthenticatedMemberWithCastleHeader(member: Member): Observable<Member> {
        return from(new Promise<Member>((resolve) => {
            return this.castleService.getCastleHeader().then((headers) => {
                return this.httpClient.put<Member>(`/api/members/${ member.id }`, member, { headers })
                    .subscribe((response) => resolve(response), (error) => resolve(error));
            });
        }));
    }

    getMemberOrgHierarchyData(memberId: number): Observable<MemberOrgCount> {
        return this.httpClient.get<MemberOrgCount>(`/api/members/${ memberId }/org-hierarchy-count`);
    }
}
