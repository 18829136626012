import { Injectable } from '@angular/core';

import {
    AnalyticsTrackingAction,
    CoachingModuleInteractionEventModel,
    JourneyMediaEvent,
    MediaDetailEvent,
    MediaFilterEvent,
    MediaSortEvent
} from '../../models';
import { AnalyticsTrackingSingleSpaService } from '../analytics-tracking-single-spa.service';

@Injectable()
export class AnalyticsTrackingCommandsService {
    constructor(
        private analyticsTrackingService: AnalyticsTrackingSingleSpaService
    ) { }

    trackMediaEvent(mediaEventType: AnalyticsTrackingAction, trackingData: MediaDetailEvent) {
        this.analyticsTrackingService.sendData(mediaEventType, trackingData);
    }

    trackJourneyStepVideo(trackingData: JourneyMediaEvent) {
        this.analyticsTrackingService.sendData(AnalyticsTrackingAction.JourneyVideoStarted, trackingData);
    }

    trackMediaLandingPageViewed() {
        this.analyticsTrackingService.sendData(AnalyticsTrackingAction.MediaLandingPageViewed);
    }

    trackMediaFilterApplied(mediaTopicId?: number) {
        const trackingData: MediaFilterEvent = {
            media_topic_id: mediaTopicId
        };
        this.analyticsTrackingService.sendData(AnalyticsTrackingAction.MediaFilterApplied, trackingData);
    }

    trackMediaSortApplied(sortOption?: string) {
        const trackingData: MediaSortEvent = {
            sort_option: sortOption
        };
        this.analyticsTrackingService.sendData(AnalyticsTrackingAction.MediaSortApplied, trackingData);
    }

    trackCoachingModuleActionClick(module_cta_value: string, coaching_appt_status: string): void {
        const trackingData: CoachingModuleInteractionEventModel = {
            module_cta_value,
            coaching_appt_status
        };
        this.analyticsTrackingService.sendData(AnalyticsTrackingAction.CoachingModuleInteraction, trackingData);
    }

    trackCoachingModuleHeaderClicked(): void {
        this.analyticsTrackingService.sendData(AnalyticsTrackingAction.CoachingModuleHeaderClicked);
    }
}
