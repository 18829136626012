import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-bootstrap/modal';

import { PalCardComponent } from './pal-card/pal-card.component';
import { PalModalComponent } from './pal-modal.component';
import { VpgrooveModule } from '@engineering/vpgroove-angular';
import { GenesisDirectivesModule, GenesisPipesModule } from '@genesis-frontend/genesis-utilities';

@NgModule({
    declarations: [
        PalModalComponent,
        PalCardComponent
    ],
    exports: [],
    imports: [
        CommonModule,
        TranslateModule,
        ModalModule.forRoot(),
        GenesisPipesModule,
        VpgrooveModule,
        GenesisDirectivesModule
    ]
})
export class PalModalModule { }
