export class NewRelicConstants {
    public static BenefitPrograms = {
        FromHomepage: 'Homepage Board',
        FromBenefitProgramSeeAll: 'Benefit Programs Page - SeeAll - See All Tab',
        FromBenefitProgramSavedSaved: 'Benefit Programs Page - Saved - Saved Tab',
        FromBenefitProgramSearched: 'Benefit Programs Page - Searched',
        FromExploreRecentlyViewed: 'recently viewed',
        FromExploreRecommended: 'recommended',
        FromExploreSaved: 'saved',
        FromExploreYourBenefits: 'your benefits',
        Location: 'Location',
        ProgramActionClicked: 'Program Clicked',
        ProgramId: 'Program ID',
        ProgramName: 'Program Name',
        ProgramType: 'Program Type',
        SponsorID: 'sponsorId',
        BenefitProgramId: 'benefitProgramId',
        FromPillars: 'Benefit Program - Pillars CTA'
    };
}
