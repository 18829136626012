import { Inject, Injectable } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ShellApplication, SHELL_APPLICATION } from '../core/shell-application';
import { singleSpaPropsSubject } from '../models/single-spa-props';
import { StoreNames } from '../state-management/global-stores.enum';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    GENESIS_CLIENT_ADMIN_UI = 'genesis-client-admin-ui';

    private authenticatedUser: any;
    private shellAppName = '';
    private authClient: any;

    constructor(
        @Inject(SHELL_APPLICATION) private shellApplication: ShellApplication
    ) {}

    authenticate() {
        if (this.isClientOrMemberShellApplication()) {
            this.authenticatedUser = this.shellApplication.authenticatedMember || this.shellApplication.authenticatedUser;
            this.shellAppName = this.shellApplication.shellAppName;
            this.authClient = this.shellApplication.authClient;
            return of(this.shellApplication as any);
        }

        return singleSpaPropsSubject.pipe(map((response) => {
            this.authenticatedUser = response.authenticatedMember || response.authenticatedUser;
            this.shellAppName = response.shellAppName;
            this.authClient = response.authClient;
            return response;
        }));
    }

    getCurrentUser() {
        if (!this.authenticatedUser) {
            this.authenticate();
        }
        return this.authenticatedUser;
    }

    isCurrentUserMember() {
        if (this.isClientOrMemberShellApplication()) {
            return this.shellAppName === StoreNames.GENESIS_UI;
        }

        return false;
    }

    isCurrentUserAdmin() {
        if (this.isClientOrMemberShellApplication()) {
            return this.shellAppName === StoreNames.GENESIS_CLIENT_ADMIN;
        }

        return this.shellAppName === this.GENESIS_CLIENT_ADMIN_UI;
    }

    isClientOrMemberShellApplication() {
        return this.shellApplication.authenticatedMember || this.shellApplication.authenticatedUser;
    }

    getAuthClient(): any {
        return this.authClient;
    }
}
