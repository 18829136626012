export function groupByCustom<K, V>(list: V[], keyGetter: (input: V) => K): Map<K, V[]> {
    const map = new Map<K, V[]>();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}

export function isEmptyCustom(val: any[]) {
    return val.length !== 0;
}

export function zip(a: any[], b: any[]) {
    return a.map(function(e, i) {
        return [e, b[i]];
    });
}

export function getSecondsFromTimestampToNow(a: number) {
    return Math.floor((Date.now() - a) / 1000);
}

export function stringToLocalDate(dateString: string): Date {
    const MS = 60000;

    const date = new Date(dateString);
    const userTimezoneOffset = date.getTimezoneOffset() * MS;
    const newDate = new Date(date.getTime() + userTimezoneOffset * Math.sign(userTimezoneOffset));
    return newDate;
}
