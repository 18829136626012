/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PreviewToolStateService } from './preview-tool-state.service';
import { AuthenticatedMemberService } from '../authentication';
import { DateUtils } from '../lib/date-utils';
import { FeatureEnum } from '../models';
import { PreviewPage } from '../models';
import { PreviewParameters } from '../models';
import { PublicSponsorInfo } from '../models';

@Injectable({
    providedIn: 'root'
})
export class PreviewToolService {
    private isGameDatesLoaded = false;
    private pages: PreviewPage[] = [];
    private params: PreviewParameters;

    constructor(
        private previewToolStateService: PreviewToolStateService,
        private authenticatedMemberService: AuthenticatedMemberService,
        private httpClient: HttpClient,
        private route: ActivatedRoute
    ) {
        this.params = new PreviewParameters(null, null, null, null, null, null);
        this.route.queryParams.subscribe((params) => {
            this.updateParametersFromUrl(params);
        });
        this.previewToolStateService.getPages().subscribe((pages) => this.pages = pages);
        this.previewToolStateService.getPreviewParameters().subscribe((params) => {
            this.params = params;
            if (this.params.isRewardsPreview && this.isGameDatesLoaded === false) {
                this.loadGameDates();
            }
        });
    }

    updateParametersFromUrl(params: any) {
        const newParams = new PreviewParameters(
            params['currentPage'] || this.params.currentPage,
            params['gameId'] || this.params.gameId,
            params['isRewardsPreview'] || this.params.isRewardsPreview,
            params['populationId'] || this.params.populationId,
            params['previewDate'] || this.params.previewDate,
            params['sponsorId'] || this.params.sponsorId
        );

        this.previewToolStateService.updateParameters(newParams);
    }
    getPublicSponsorInfo() {
        return this.httpClient.get<PublicSponsorInfo>(`/api/sponsors/${ this.params.sponsorId }`);
    }

    getGameDatesObservable() {
        return this.previewToolStateService.getGameDates();
    }

    getPages() {
        return this.pages;
    }

    getPagesObservable() {
        return this.previewToolStateService.getPages();
    }

    getParameters() {
        return this.params;
    }

    getParametersObservable() {
        return this.previewToolStateService.getPreviewParameters();
    }

    getQueryParameterObject(query: any) {
        query = query || {};
        if (this.params.isRewardsPreview) {
            if (this.params.gameId !== null) {
                query.gameId = this.params.gameId;
            }
            if (this.params.populationId !== null) {
                query.rewardPopulationId = this.params.populationId;
            }
            if (this.params.previewDate !== null) {
                query.previewDate = this.params.previewDate;
            }
            if (this.params.sponsorId !== null) {
                query.sponsorId = this.params.sponsorId;
            }
        }

        return query;
    }

    getPreviewParams() {
        let params = new HttpParams();
        if (this.params.gameId !== null) {
            params = params.append('gameId', `${this.params.gameId}`);
        }

        if (this.params.sponsorId !== null) {
            params = params.append('sponsorId', `${this.params.sponsorId}`);
        }

        if (this.params.populationId !== null) {
            params = params.append('rewardPopulationId', `${this.params.populationId}`);
        }

        if (this.params.previewDate !== null) {
            params = params.append('previewDate', `${this.params.previewDate}`);
        }

        return params;
    }

    loadGameDates() {
        const member = this.authenticatedMemberService.getAuthenticatedMember();
        this.httpClient.get(`/api/genesis-rewards/sponsors/${this.params.sponsorId}/members/${member.id}/overview`)
            .toPromise().then((data: any) => {
                this.isGameDatesLoaded = true;
                if (Date.parse(data.game.startDate) && Date.parse(data.game.endDate)) {
                    this.previewToolStateService.updateGameDates({
                        startDate: DateUtils.format(data.game.startDate, 'YYYY-MM-DD'),
                        endDate: DateUtils.format(data.game.endDate, 'YYYY-MM-DD')
                    });
                }
            });
    }

    updateGameDates(gameStartDate: string, gameEndDate: string) {
        if (Date.parse(gameStartDate) && Date.parse(gameEndDate)) {
            this.previewToolStateService.updateGameDates({
                startDate: DateUtils.format(gameStartDate, 'YYYY-MM-DD'),
                endDate: DateUtils.format(gameEndDate, 'YYYY-MM-DD')
            });
        }
    }

    updateParameters(params: any) {
        const newParams = new PreviewParameters(
            params.currentPage || this.params.currentPage,
            params.gameId || this.params.gameId,
            params.isRewardsPreview !== null && params.isRewardsPreview !== undefined
                ? params.isRewardsPreview : this.params.isRewardsPreview,
            params.populationId || this.params.populationId,
            params.previewDate || this.params.previewDate,
            params.sponsorId || this.params.sponsorId
        );

        this.previewToolStateService.updateParameters(newParams);
    }

    updatePagesFromFeatures(features: FeatureEnum[]) {
        if (!features.includes(FeatureEnum.PointsRewards)) {
            this.previewToolStateService.updatePageDisplay('How To Earn', false);
        }
    }
}
