import { Pipe, PipeTransform } from '@angular/core';

import { RewardableActionGameAdminListVM } from '../models/interfaces/rewardable-action-game-admin.model';

@Pipe({ name: 'disableAddButton' })
export class DisableAddButtonPipe implements PipeTransform {

    winConditionSupportingMccActionEventCodes = [ 'MCCPS1', 'MCCPS2', 'MCCPS3' ];

    transform(value: RewardableActionGameAdminListVM, winCount: number, hasPrerequisites: boolean): boolean {
        const isMCCPreventiveServicesType = value.rewardableActionType === 'MCCPreventiveServices';

        if (hasPrerequisites) {
            return isMCCPreventiveServicesType;
        }
        if (!winCount) {
            return false;
        }
        return isMCCPreventiveServicesType && !this.winConditionSupportingMccActionEventCodes.includes(value.eventCode);
    }

}
