<div class="global-challenges-create-a-team-container">
    <div class="challenge-onboarding-blocker-modal-title-create-team">
        <div
            class="create-team-card-title text-clr-oxford-blue"
            [translate]="'CreateATeam'">
            Create a Team
        </div>
    </div>
    <div
        class="card-middle"
        *ngIf="showCarousel">
        <hr class="create-team-horizontal-line border-clr-gainsboro" />

        <div class="card-content-container">
            <div class="team-name-div">
                <label
                    class="team-name-label-blocker-style text-clr-mine-shaft"
                    [translate]="'TeamNameLabel'">
                    Team Name
                </label>
                <input
                    aria-required="true"
                    class="team-name-input text-clr-mine-shaft"
                    [(ngModel)]="data.teamName"
                    maxlength="35"
                    placeholder="Add a Team Name"
                    type="text"
                    aria-labelledby="team-name-label"
                    [placeholder]="'AddATeamName' | translate"
                    aria-invalid="false" />
            </div>
            <div
                *ngIf="data.teamName.length"
                class="chars-limit-vp-go"
                [ngClass]="{'text-clr-error' : data.teamName.length >= TEAM_NAME_MAX_LENGTH - 5}"
                [innerHTML]="'CharacterCount' | translate: { charCount: TEAM_NAME_MAX_LENGTH - data.teamName.length }">
                Characters left: 35
            </div>

            <div class="rallying-cry-div">
                <label
                    class="rally-cry-label-blocker text-clr-mine-shaft"
                    [translate]="'RallyingCry'">
                    Rallying Cry
                </label>
                <textarea
                    name="rally-cry"
                    [required]="true"
                    [placeholder]="'AddRallyingCry' | translate"
                    [(ngModel)]="data.rallyingCry"
                    maxlength="140"
                    class="rallying-cry-input text-clr-mine-shaft"></textarea>
            </div>
            <div
                *ngIf="data.rallyingCry.length"
                class="chars-limit-vp-go"
                [ngClass]="{'text-clr-error' : data.rallyingCry.length >= RALLY_CRY_MAX_LENGTH - 5}"
                [innerHTML]="'CharacterCount'| translate: { charCount: RALLY_CRY_MAX_LENGTH - data.rallyingCry.length }">
                Characters left: 140
            </div>

            <div class="team-image-label">
                <label
                    class="team-image-label-blocker text-clr-mine-shaft"
                    [translate]="'TeamImageLabel'">
                    Team Image
                </label>
                <label
                    class="team-image-text"
                    [translate]="'UploadTeamImageOrChooseOurs'">
                    Upload a team image—or choose one of ours.
                </label>
            </div>

            <div class="team-image-section">
                <div
                    class="upload-team-image-block background-clr-mercury border-clr-bg text-clr-gray"
                    [ngStyle]="{ 'background-image': 'url(' + data.teamImage+ ')'}"
                    [ngClass]="{'selected-image-block text-clr-gray' : data.teamImage}">
                    <button vpg-button
                            *ngIf="photoUploadEnabledOnSponsor && !data.teamImage"
                            id="vp-go-upload-image-button"
                            (click)="uploadImage()">
                        {{ 'UploadAnImage' | translate }}
                    </button>
                    <button vpg-button
                            *ngIf="data.teamImage && photoUploadEnabledOnSponsor"
                            class="remove-team-image-button"
                            id="vp-go-remove-image-button"
                            (click)="removeSelectedImage()">
                        {{ 'RemoveImage' | translate }}
                    </button>
                    <div
                        class="team-image-description"
                        *ngIf="photoUploadEnabledOnSponsor && !data.teamImage"
                        [translate]="'AddPhotoTypeAndSize'">
                        Add a png or jpg image at least 660x270px
                    </div>
                </div>
            </div>
            <ngx-slick-carousel
                class="custom-slick-carousel"
                [config]="slickSettings">
                <div
                    ngxSlickItem
                    *ngFor="let teamPhoto of photos; index as i">
                    <img
                        [src]="teamPhoto.thumbnailUrl"
                        [alt]="teamPhoto.accessibilityText"
                        class="image-thumbnail"
                        (click)="selectImageFromLibrary(teamPhoto.imageUrl)"
                        [ngClass]="{ 'selected-thumbnail': teamPhoto.imageUrl === data.teamImage }" />
                </div>
            </ngx-slick-carousel>
            <div class="public-team-section">
                <label
                    class="public-team-label text-clr-mine-shaft"
                    [translate]="'PublicTeam'">
                    Public team
                </label>
                <div class="public-team-button-wrapper">
                    <div
                        *ngIf="!data.isTeamPrivate"
                        class="public-team-logo vp-button-primary box-shadow-button-unlocked">
                        <img
                            translate-attr="{'alt': 'ToggleToLock'}"
                            class="unlocked-team-img"
                            [src]="'/img/toggle_unlock_neutral.png'"
                            (click)="togglePublicTeam()" />
                    </div>
                    <div
                        *ngIf="data.isTeamPrivate"
                        class="public-team-logo private background-clr-white-locked box-shadow-button-locked">
                        <img
                            translate-attr="{'alt': 'ToggleToLock'}"
                            class="locked-team-img"
                            [src]="'/img/Toggle_Lock.png'"
                            (click)="togglePublicTeam()" />
                    </div>
                    <label
                        *ngIf="!data.isTeamPrivate"
                        class="public-team-desc text-clr-mine-shaft"
                        [translate]="'ThisIsPublicTeam'"></label>
                    <label
                        *ngIf="data.isTeamPrivate"
                        class="public-team-desc text-clr-mine-shaft"
                        [translate]="'ThisIsPrivateTeam'"></label>
                </div>
            </div>
        </div>
        <hr class="create-team-horizontal-line border-clr-gainsboro" />
    </div>

    <div class="card-footer">
        <div class="global-challenge-onboarding-button">
            <button
                class="vp-button larger vp-button-primary"
                (click)="goToNextCard()"
                [translate]="'Next'"
                [disabled]="!data.teamName || !data.rallyingCry || !data.teamImage">
                <span [translate]="'Next'">NEXT</span>
            </button>
        </div>
    </div>
    <div
        class="prev-card-btn background-clr-bg background-dark text-clr-white"
        *ngIf="showLeftArrow"
        (click)="goToPreviousCard()"
        role="button"
        tabindex="0"
        [attr.aria-label]="'previous' | translate">
        <i class="fa fa-angle-left"></i>
    </div>
    <div
        class="next-card-btn background-clr-bg background-dark text-clr-white"
        *ngIf="showRightArrow && !(!data.teamName || !data.rallyingCry)"
        (click)="data.nextCard()"
        role="button"
        tabindex="0"
        [attr.aria-label]="'next' | translate">
        <i class="fa fa-angle-right"></i>
    </div>
</div>
