import { RedeemableTransactionSummary } from './reedemable-transaction-summary.model';
import { RewardStatement } from './reward-statement.model';

export class EarningsAndSpending {
    earnedRewards: Map<string, RewardStatement> | null;
    summary: RedeemableTransactionSummary | null;
    rewards: Map<string, RewardStatement> | null;

    constructor(
        earnedRewards: Map<string, RewardStatement> | null,
        summary: RedeemableTransactionSummary | null,
        rewards: Map<string, RewardStatement> | null
    ) {
        this.earnedRewards = earnedRewards;
        this.summary = summary;
        this.rewards = rewards;
    }
}
