import { RewardTransaction } from './reward-transaction.model';
import { CurrencyCode } from '../enums/currency-code.enum';


export class RewardStatement {
    date = '';
    currency: CurrencyCode | null = null;
    total: number;
    rewards: RewardTransaction[];

    constructor(total: number, rewards: RewardTransaction[]) {
        this.total = total;
        this.rewards = rewards;
    }
}
