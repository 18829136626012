import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';

import { ShellApplication, SingleSpaConfiguration, SingleSpaConfigurationService } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'spa-module',
    template: '<div #mf id="single-spa-application:" [ngClass]="className" ></div>'
})
export class SingleSpaModuleComponent implements OnDestroy, AfterViewInit {
    @Input() module!: SingleSpaConfiguration;
    @Input() className = '';
    @Input() delay = 0;

    @ViewChild('mf') container!: ElementRef;

    private timeout?: any;

    constructor(
        private singleSpa: SingleSpaConfigurationService,
        private shellApplication: ShellApplication
    ) { }

    ngAfterViewInit(): void {
        this.container.nativeElement.id = 'single-spa-application:' + this.module.appName;
        if (this.delay) {
            this.timeout = setTimeout(() => {
                this.singleSpa.activateModule(this.module, this.shellApplication);
            }, this.delay);
            return;
        }
        this.singleSpa.activateModule(this.module, this.shellApplication);
    }

    ngOnDestroy(): void {
        this.singleSpa.deactivateModule(this.module);
        clearTimeout(this.timeout);
    }
}
