import { Injectable } from '@angular/core';

import { DateUtils } from '../lib/date-utils';
import { GlobalReduxStore } from '../state-management';

const PLATFORM = 'web';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsTrackingSingleSpaService {

    private mixpanel: any;
    private excludeFromTracking = true;
    private timezone = {
        timezoneId: NaN,
        javaTimezone: '',
        longDescription: ''
    };

    constructor(private globalReduxStore: GlobalReduxStore) { }

    init(): void {
        const globalState = this.globalReduxStore.getInitialState();
        this.mixpanel = globalState.mixpanelObject;
        this.excludeFromTracking = globalState.authentication.authenticatedMember.sponsor.settings.excludeMixpanelTracking;
        this.timezone = globalState.authentication.authenticatedMember.timezone;
    }

    sendData(actionName: string, trackingData: any = {}): void {
        const data = {
            platform: PLATFORM,
            member_timestamp: DateUtils.format(
                DateUtils.toUtcDate(),
                'YYYY-MM-DDTHH:mm:ss',
                '',
                this.timezone.javaTimezone
            )
        };

        this.track(actionName, { ...data, ...trackingData });
    }

    getStringAsSentence(someString: string): string {
        return someString.replace(/([A-Z])/g, ' $1').toLowerCase().trim();
    }

    clearMixpanel(): void {
        this.mixpanel.reset();
    }

    registerMixpanel(userProps: { current_points: string, current_level: string, next_level: string, next_level_secret: boolean }): void {
        this.mixpanel.register(userProps);
    }

    setMixpanelPeople(userProfileProperties: any): void {
        this.mixpanel.people.set(userProfileProperties);
    }

    private track(actionName: string, trackingData: unknown = {}): void {

        if (this.excludeFromTracking === false) {
            this.mixpanel.track(actionName, trackingData, { send_immediately: true });
        }
    }
}
