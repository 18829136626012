import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { PhoneNumber, PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { CountryISO } from 'ngx-intl-tel-input-gg';

import { GlobalChallengeCardCarouselDataModel } from '../../../interface/global-challenges-card-carousel-data.model';
import { MemberContestOnboardingBlockerService } from '../../member-contest-onboarding-blocker.service';
import { AuthenticatedMemberService, Country, CountryState, Member, OrderDetails, find, sortBy } from '@genesis-frontend/genesis-utilities';

@Component({
    templateUrl: './address-collection.component.html',
    styleUrls: ['./address-collection.component.scss']
})
export class GlobalChallengesMaxBuzzAddressCollectionCardComponent {
    AUSTRALIA_COUNTRY_CODE = 'AU';
    USA_COUNTRY_CODE = 'US';
    USA_COUNTRY_ID = 1;
    CANADA_COUNTRY_CODE = 'CA';
    PHONE_NUMBER_MIN_DIGIT = 2;

    countries: Country[] = [];
    country: Country | undefined;
    city: string | undefined;
    streetAddress: string | undefined;
    streetAddressOptional: string | undefined;
    state: string | undefined;
    postalCode: string | undefined;
    orderDetails: OrderDetails | undefined;
    countryStates: CountryState[] | undefined;
    countryHasStates = false;
    memberCountry: Country | undefined;
    countryCode: string | undefined;
    selectedPhoneCountryCode: string | undefined;
    defaultSelectedCountry: number | undefined;
    selectedCountry: Country | undefined;
    member: Member | undefined;
    memberPhoneNumberCountryCode!: string | undefined;
    isPhoneNumberValid = false;
    phoneNumber = {
        number: '',
        internationalNumber: '',
        nationalNumber: '',
        countryCode: '',
        dialCode: ''
    };
    telInputTelValidator: any;
    phoneUtil = PhoneNumberUtil.getInstance();
    intlTelInputOptions: any = {
        initialCountry: null,
        separateDialCode: true
    };

    businessAddressOptionChecked = false;
    defaultCountryDialNumberChanged = false;

    stateType: string | null | CountryState | undefined = null;
    countryType: string | null | undefined = '';
    businesNameType: string | null = null;

    addressCollectionForm = new FormGroup({
        country: new FormControl(this.countryType, [Validators.required]),
        streetAddress: new FormControl('', [Validators.required]),
        streetAddressOptional: new FormControl(),
        city: new FormControl('', [Validators.required]),
        state: new FormControl(this.stateType, [Validators.required]),
        postalCode: new FormControl('', [Validators.required]),
        phoneNumber: new FormControl('', [Validators.required]),
        businessName: new FormControl(this.businesNameType, [this.businessNameValidator.bind(this)])
    });

    showLeftArrow = true;
    showRightArrow = false;

    constructor(
        @Inject('data') public data: GlobalChallengeCardCarouselDataModel,
        private memberContestOnboardingBlockerService: MemberContestOnboardingBlockerService,
        private authenticatedMemberService: AuthenticatedMemberService
    ) {}

    ngOnInit() {
        this.showRightArrow = this.data.isMaxBuzzAddressConfirmationCardVisited;
        this.memberCountry = this.authenticatedMemberService.getCountry();
        this.getMemberDetails();
        this.getCountries();
        this.addressCollectionForm.patchValue({
            phoneNumber: this.member ? this.member.phoneNumber : ''
        });
        this.selectedPhoneCountryCode = this.memberCountry?.phoneCountryCode;
        this.intlTelInputOptions.initialCountry = this.countryCode;
        this.setInputValues();
    }

    setSelectedCountry() {
        const selectedCountry = this.data.orderDetails
            ? find(this.countries, { id: this.data.orderDetails.countryId })
            : this.memberCountry;
        if (selectedCountry) {
            this.selectedCountry = selectedCountry;
        }
    }

    isCountryWithStates(country: Country): boolean {
        return (
            country.countryCode === this.AUSTRALIA_COUNTRY_CODE ||
            country.countryCode === this.USA_COUNTRY_CODE ||
            country.countryCode === this.CANADA_COUNTRY_CODE
        );
    }

    lastSelectedCountryHadStates(): boolean {
        if (!this.data.orderDetails) {
            return false;
        }
        const lastSelectedCountry = find(this.countries, { id: this.data.orderDetails.countryId });
        return lastSelectedCountry ? this.isCountryWithStates(lastSelectedCountry) : false;
    }

    getOrderDetails() {
        const number =
            this.phoneNumber.number.length && this.isPhoneNumberValid
                ? this.phoneUtil.parseAndKeepRawInput(this.phoneNumber.dialCode + this.phoneNumber.number, this.phoneNumber.countryCode)
                : this.addressCollectionForm.get('phoneNumber')?.value;
        this.orderDetails = this.setOrderDetails(number);
        this.data.orderDetails = this.orderDetails;
    }

    private setOrderDetails(number: string | PhoneNumber | null | undefined): OrderDetails {
        const orderDetails: OrderDetails = {} as OrderDetails;

        orderDetails.contestId = this.data.contest?.contestId;

        if (this.data.memberId) {
            orderDetails.memberId = this.data.memberId;
        }

        if (this.addressCollectionForm.get('country')?.value) {
            const country: string | null | undefined = this.addressCollectionForm.get('country')?.value;
            if (country) {
                orderDetails.countryId = parseInt(country, 10);
            }
        }

        const city: string | null | undefined = this.addressCollectionForm.get('city')?.value;
        if (city) {
            orderDetails.city = city;
        }

        const address1: string | null | undefined = this.addressCollectionForm.get('streetAddress')?.value;
        if (address1) {
            orderDetails.address1 = address1;
        }

        const address2: string | null | undefined = this.addressCollectionForm.get('streetAddressOptional')?.value;
        if (address2) {
            orderDetails.address2 = address2;
        }

        if (this.countryHasStates && this.addressCollectionForm.get('state')?.value) {
            const value: any = this.addressCollectionForm.get('state')?.value;
            orderDetails.state = value['abbreviation'];
        } else {
            orderDetails.state = this.addressCollectionForm.get('state')?.value + '';
        }

        orderDetails.zip = this.addressCollectionForm.get('postalCode')?.value + '';

        if (number && typeof number == typeof PhoneNumber && this.isPhoneNumberValid) {
            const phoneNumber = number as PhoneNumber;
            if (phoneNumber) {
                orderDetails.zip = this.phoneUtil.format(phoneNumber, PhoneNumberFormat.E164);
            }
        }

        if (this.selectedCountry?.name) {
            orderDetails.selectedCountryName = this.selectedCountry?.name;
        }

        orderDetails.phoneNumberData = this.phoneNumber;

        const businessName: string | null | undefined = this.addressCollectionForm.get('businessName')?.value;
        if (businessName) {
            orderDetails.businessName = businessName;
        }

        return orderDetails;
    }

    telInputObject(inputValidator: any) {
        this.telInputTelValidator = inputValidator;
    }

    onCountryChange(countryId: number) {
        const selectedCountry = find(this.countries, { id: countryId });
        if (selectedCountry) {
            this.selectedCountry = selectedCountry;
        }

        let phoneNumberInputCountry: string | undefined;
        if (!this.memberPhoneNumberCountryCode) {
            const countryCodeFromOrder: string | undefined = this.data.orderDetails?.phoneNumberData.countryCode;
            const selectedCountryCode: string | undefined = this.selectedCountry?.countryCode;
            if (countryCodeFromOrder) {
                phoneNumberInputCountry = countryCodeFromOrder;
            } else if (!countryCodeFromOrder && selectedCountryCode) {
                phoneNumberInputCountry = selectedCountryCode;
            }
        } else {
            phoneNumberInputCountry = this.memberPhoneNumberCountryCode;
        }

        if (phoneNumberInputCountry) {
            this.telInputTelValidator.setCountry(phoneNumberInputCountry);
        }

        if (this.selectedCountry) {
            this.getCountryStates(this.selectedCountry);
            this.getPhoneNumberData();
            if (!this.isCountryWithStates(this.selectedCountry) && this.lastSelectedCountryHadStates()) {
                this.state = '';
                if (this.data?.orderDetails?.state) {
                    this.data.orderDetails.state = '';
                }

                this.addressCollectionForm.patchValue({
                    state: null
                });
            }
        }
    }

    countryChange(country: any) {
        this.selectedPhoneCountryCode = country.dialCode;
        this.defaultCountryDialNumberChanged = true;
        this.getPhoneNumberData();
    }

    getCountries() {
        this.memberContestOnboardingBlockerService.getCountriesEligibleForDeviceOrder().subscribe((response) => {
            this.countries = sortBy(response, 'name');
            this.setSelectedCountry();
            if (this.selectedCountry) {
                this.getCountryStates(this.selectedCountry);
            }
            this.checkMemberCountryEligibleForDeviceOrder();
            this.addressCollectionForm.patchValue({
                country: this.data.orderDetails ? this.data.orderDetails.countryId.toString() : this.defaultSelectedCountry?.toString()
            });
        });
    }

    getCountryStates(country: Country) {
        if (this.isCountryWithStates(country)) {
            this.memberContestOnboardingBlockerService.getCountryStates(country.id).subscribe((response) => {
                this.countryStates = response;
                this.countryHasStates = true;
                if (this.data.orderDetails) {
                    const countryState: CountryState | undefined = find(this.countryStates, {
                        abbreviation: this.data.orderDetails.state
                    });
                    this.addressCollectionForm.patchValue({
                        state: countryState ? countryState : null
                    });
                } else {
                    this.addressCollectionForm.patchValue({
                        state: null
                    });
                }
            });
        } else {
            this.countryHasStates = false;
            this.addressCollectionForm.patchValue({
                state: this.data.orderDetails ? this.data.orderDetails.state : ''
            });
        }
    }

    getPhoneNumberData() {
        const countryData: any = this.telInputTelValidator.getSelectedCountryData();
        const phoneNumber = this.addressCollectionForm.get('phoneNumber')?.value;
        if (phoneNumber) {
            this.phoneNumber.number = phoneNumber;
        }
        this.phoneNumber.countryCode = countryData.iso2;
        this.phoneNumber.dialCode = '+' + countryData.dialCode;
        this.isPhoneNumberValid = this.telInputTelValidator.isValidNumber();
    }

    setInputValues() {
        if (this.data.orderDetails) {
            this.businessAddressOptionChecked = !!this.data.orderDetails.businessName;
            const number =
                this.data.orderDetails.phoneNumberData.number.length >= this.PHONE_NUMBER_MIN_DIGIT
                    ? this.phoneUtil.parseAndKeepRawInput(
                        this.data.orderDetails.phoneNumberData.dialCode + this.data.orderDetails.phoneNumberData.number,
                        this.data.orderDetails.phoneNumberData.countryCode
                    )
                    : this.data.orderDetails.phoneNumberData.number;
            this.addressCollectionForm.patchValue({
                country: this.data.orderDetails.countryId.toString(),
                streetAddress: this.data.orderDetails.address1,
                streetAddressOptional: this.data.orderDetails.address2,
                city: this.data.orderDetails.city,
                state: this.data.orderDetails ? this.data.orderDetails.state : '',
                postalCode: this.data.orderDetails.zip,
                phoneNumber:
                    number.length >= this.PHONE_NUMBER_MIN_DIGIT
                        ? number.getNationalNumber()
                        : this.data.orderDetails.phoneNumberData.number,
                businessName: this.data.orderDetails.businessName
            });

            this.phoneNumber = this.data.orderDetails.phoneNumberData;
        }
    }

    checkMemberCountryEligibleForDeviceOrder() {
        const country: Country | undefined = find(this.countries, { id: this.memberCountry?.id });
        this.defaultSelectedCountry = country ? country.id : this.USA_COUNTRY_ID;
    }

    getMemberDetails() {
        this.authenticatedMemberService.getAuthenticatedMemberPromise().then((authenticatedMember) => {
            this.member = authenticatedMember;
            if (this.member.phoneNumber && !this.data.orderDetails) {
                try {
                    const number = this.phoneUtil.parse(this.member.phoneNumber, '');
                    this.addressCollectionForm.patchValue({
                        phoneNumber: number.getNationalNumber()?.toString()
                    });
                    this.memberPhoneNumberCountryCode = this.phoneUtil.getRegionCodeForNumber(number);
                } catch (e) {
                    this.setCountryCode();
                    return;
                }
            }

            if (this.memberPhoneNumberCountryCode) {
                this.countryCode = this.memberPhoneNumberCountryCode;
            } else {
                this.setCountryCode();
            }
        });
    }

    setCountryCode() {
        this.memberCountry = this.authenticatedMemberService.getCountry();
        this.countryCode = this.memberCountry ? this.memberCountry.countryCode : CountryISO.UnitedStates;
    }

    goToPreviousCard() {
        this.getOrderDetails();
        this.data.prevCard();
    }

    goToNextCard() {
        this.getOrderDetails();
        this.data.nextCard();
    }

    toggleBusinessName() {
        this.businessAddressOptionChecked = !this.businessAddressOptionChecked;

        if (!this.businessAddressOptionChecked) {
            this.addressCollectionForm.get('businessName')?.patchValue(null);
        }

        this.addressCollectionForm.get('businessName')?.updateValueAndValidity();
    }

    private businessNameValidator(control: AbstractControl): Record<string, boolean> | null {
        const businessNameValid =
            !this.businessAddressOptionChecked || (this.businessAddressOptionChecked && control.value && control.value.length);

        return !businessNameValid ? { businessName: true } : null;
    }
}
