import {
    Directive,
    Input,
    OnInit,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';

import { UnleashFlag, OperatorType, Operator } from '../models';
import { UnleashFlagsService } from '../services';


/**
 * A structural directive that shows or hides its content based on the unleash flags.
 *
 * The unleash directive takes a single input `unleash` which can be either a single unleash flag or an array of unleash flags.
 * If the unleash flag is an array, it checks if all the flags in the array are enabled based on the `unleashOperator` which can be either 'or' or 'and' (default).
 *
 * If the unleash flags are enabled, the directive renders the template, otherwise it renders the `unleashElse` template if provided, otherwise it clears the view container.
 *
 * @example
 * <div *unleash="'flag1'">Content</div>
 * <!-- Shows the content if flag1 is enabled -->
 *
 * <div *unleash="['flag1', 'flag2']">Content</div>
 * <!-- Shows the content if both flag1 and flag2 are enabled -->
 *
 * <div *unleash="['flag1', 'flag2']" [unleashOperator]="'or'">Content</div>
 * <!-- Shows the content if either flag1 or flag2 is enabled -->
 *
 * <div *unleash="['flag1', 'flag2']"; operator: 'or'">Content</div>
 * <!-- Shorter version of the above - Recommended -->
 *
 * <div *unleash="'flag1'" [unleashElse]="fallback">Content</div>
 * <ng-template #fallback>Else content</ng-template>
 * <!-- Shows the else content if flag1 is not enabled -->
 *
 * <div *unleash="'flag1'; else fallback">Content</div>
 * <ng-template #fallback>Else content</ng-template>
 * <!-- Shorter version of the above - Recommended -->
 *
 * <div *unleash="['flag1', 'flag2']; operator: 'or'; else fallback">Content</div>
 * <ng-template #fallback>Else content</ng-template>
 * <!-- Version with all inputs provided -->
 *
 * @param unleash - A single unleash flag or an array of unleash flags.
 * @param unleashOperator - The operator to check if all the flags in the array are enabled, can be either 'or' or 'and'.
 * @param unleashElse - An optional template to render if the unleash flags are not enabled.
 */
@Directive({
    selector: '[unleash]'
})
export class UnleashFlagDirective implements OnInit {
    @Input() unleash: UnleashFlag | UnleashFlag[] = [];
    @Input() unleashElse?: TemplateRef<HTMLElement> = undefined;
    @Input() unleashOperator: OperatorType = Operator.AND;

    constructor(
        private templateRef: TemplateRef<HTMLElement>,
        private viewContainerRef: ViewContainerRef,
        private unleashService: UnleashFlagsService
    ) {}

    ngOnInit(): void {
        this.renderTemplate();
    }

    private renderTemplate(): void {
        const flags = Array.isArray(this.unleash)
            ? this.unleash
            : [this.unleash];

        if (this.hasFlags(flags)) {
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        } else if (this.unleashElse) {
            this.viewContainerRef.createEmbeddedView(this.unleashElse);
        } else {
            this.viewContainerRef.clear();
        }
    }

    private hasFlags(flags: UnleashFlag[]): boolean {
        return this.unleashOperator === Operator.OR
            ? this.unleashService.hasSomeUnleashFlags(flags)
            : this.unleashService.hasAllUnleashFlags(flags);
    }
}
