import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

import { PreviewToolService } from './preview-tool.service';
import { AuthenticatedMemberService } from '../authentication';
import { GlobalReduxStore, UpdateMemberTotalSummary } from '../state-management';


@Injectable({
    providedIn: 'root'
})
export class RewardsTotalSummaryService {
    totalSummary: any;
    totalSummaryLoaded = false;
    membersTotalSummarySubject = new ReplaySubject<any>();

    constructor(
        private httpClient: HttpClient,
        private globalReduxStore: GlobalReduxStore,
        private authenticatedMemberService: AuthenticatedMemberService,
        private previewToolService: PreviewToolService
    ) {}

    getTotalSummaryUrl(sponsorId: number, memberId: number) {
        return `api/genesis-rewards/sponsors/${sponsorId}/members/${memberId}/statements/total-summary`;
    }

    loadMemberTotalSummary(shouldReload: boolean) {
        if (this.totalSummaryLoaded && !shouldReload) {
            return this.membersTotalSummarySubject;
        }
        const member = this.authenticatedMemberService.getMember();

        const previewToolServiceQuery = this.previewToolService.getQueryParameterObject({
            sponsorId: member.sponsorId,
            memberId: member.id,
            memberFeatures: this.authenticatedMemberService.getRewardableFeatures()
        });
        const params = { memberFeatures : previewToolServiceQuery.memberFeatures };

        this.httpClient.get(this.getTotalSummaryUrl(previewToolServiceQuery.sponsorId, previewToolServiceQuery.memberId), { params }).subscribe(
            (data: any) => {
                this.totalSummaryLoaded = true;
                this.totalSummary = data;
                this.globalReduxStore.dispatchAction(UpdateMemberTotalSummary(this.totalSummary));
                this.membersTotalSummarySubject.next(this.totalSummary);
            },
            (error) => {
                this.membersTotalSummarySubject.error(error);
            }
        );

        return this.membersTotalSummarySubject;
    }
}
