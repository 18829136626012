import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { MemberSettings } from '@genesis-frontend/genesis-utilities';


@Injectable({
    providedIn: 'root'
})
export class MemberSettingsService {
    constructor(
        private httpClient: HttpClient
    ) { }

    getMemberSettings(memberId: number): Observable<MemberSettings> {
        return this.httpClient.get<MemberSettings>(`/api/members/${ memberId }/settings`);
    }

    saveMemberSettings(memberId: number, memberSettings: MemberSettings): Observable<MemberSettings> {
        return this.httpClient.put<MemberSettings>(`/api/members/${ memberId }/settings`, memberSettings);
    }
}
