import { Component, Input, OnInit } from '@angular/core';

import { CoachingCardVM } from '../../models/coaching-card-vm.model';
import { CoachingService } from '../../services/coaching.service';
import { AnalyticsTrackingAction, AnalyticsTrackingSingleSpaService } from '@genesis-frontend/genesis-utilities';

@Component({
    selector: 'genesis-frontend-base-card',
    templateUrl: './base-card.component.html',
    styleUrls: ['./base-card.component.scss']
})
export class BaseCardComponent implements OnInit {
    @Input() card!: CoachingCardVM;
    hrefPath = '';

    readonly COACHING = 'Coaching';

    readonly LIVE_SERVICES_URL = {
        BASE: '#/live-services-coaching/coaching-hub',
        COACHING_HUB_TAB_BASE: '#/live-services-coaching/coaching-hub?activeTab='
    };

    constructor(
        private coachingService: CoachingService,
        private readonly analyticsTrackingService: AnalyticsTrackingSingleSpaService
    ) {}
    ngOnInit(): void {
        this.hrefPath = this.coachingService.getHrefPath(this.card.state);
    }

    sendAnalytics(): void {
        this.analyticsTrackingService.sendData(AnalyticsTrackingAction.HomepageWidgetTable, this.card.analyticsTrackingData);
    }
}
