import { Component, OnInit } from '@angular/core';

import { MemberInitializationService } from '@app/core/services';
import { AuthenticatedMemberService } from '@genesis-frontend/genesis-utilities';

@Component({
    templateUrl: './mobile-auth-layout.component.html'
})
export class MobileAuthLayoutComponent implements OnInit {
    member = this.authenticatedMemberService.getMember();

    constructor(
        private authenticatedMemberService: AuthenticatedMemberService,
        private memberInitializationService: MemberInitializationService
    ) {
    }

    ngOnInit() {
        window.newShellApp = true;
        this.memberInitializationService.initialCall();
        this.setNewRelicMemberId();
        document.getElementById('loaded')?.remove();
    }

    setNewRelicMemberId() {
        const newRelic = (window as any).newrelic;
        if (newRelic && newRelic.setCustomAttribute) {
            newRelic.setCustomAttribute('genesisMemberId', this.member.id);
        }
    }
}
