import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { DateUtils } from '../lib/date-utils';
import { SurveyStatus } from '../models/enums/survey-status.enum';
import { BlockerResponseModel } from '../models/interfaces/blocker-response.model';
import { ScheduledSurvey } from '../models/interfaces/scheduled-survey.model';

@Injectable({ providedIn: 'root' })
export class SurveyService {

    private readonly isMobileDevice = (/Mobi|iPhone|Android/).test(navigator.userAgent);

    constructor(private httpClient: HttpClient, private router: Router) {}

    showSurveyInterruptBlocker(blocker: BlockerResponseModel & { response: { surveys: ScheduledSurvey[], surveyKey: string } }) {
        return new Promise<void>((resolve) => {
            if (blocker.showBlocker) {
                blocker?.response?.surveys?.forEach((survey: ScheduledSurvey) => {
                    sessionStorage[blocker?.response?.surveyKey] = true;
                    this.goToScheduledSurvey(survey);
                });
            }
            resolve();
        });
    }

    goToScheduledSurvey(survey: ScheduledSurvey): void {
        setTimeout(() => {
            this.router.navigateByUrl(`surveys${survey.uiType === 'V2' ? `-ui/surveys/${survey.scheduledSurveyId}/intro` : `/${survey.scheduledSurveyId}`}`);
        }, 0);
    }

    checkIfShouldRedirectToSurvey(resolve: any, memberId: string): void {
        if (!location.search) {
            this.httpClient.get(`/api/members/${ memberId }/surveys`).subscribe((surveys: any) => {
                const currDate = DateUtils.format(new Date(), 'MM/DD/YYYY');
                const key = `${memberId}_${currDate}`;
                const filteredSurveys = surveys?.filter((survey: ScheduledSurvey) =>
                    survey.interrupt === true
                    && (!sessionStorage.getItem(key)
                    && (survey.status === SurveyStatus.Available
                    || survey.status === SurveyStatus.Started
                    || survey.status === SurveyStatus.Scoreable)));
                if (filteredSurveys?.length) {
                    resolve({ showBlocker: true, response: { surveys: filteredSurveys, surveyKey: key } });
                    return;
                }
                resolve({ showBlocker: false });
            }, () => {
                resolve({ showBlocker: false });
            });
        }
    }

    checkSurveyInterruptBlocker = (memberId: string) => {
        return new Promise<BlockerResponseModel>((resolve) => {
            if (!this.isMobileDevice) {
                this.checkIfShouldRedirectToSurvey(resolve, memberId);
            } else {
                resolve({ showBlocker: false });
            }
        });
    };

}
