import { Injectable } from '@angular/core';

import { SessionCache } from '../../core/session-cache';
import { ReplayChallenge } from '../../models/interfaces/replay-challenge.model';

@Injectable({
    providedIn: 'root'
})
export class ReplayChallengeDataService {
    private _personalChallengeData: ReplayChallenge | any = {};
    private _trackerChallengeData: ReplayChallenge | any = {};
    private cacheKey = 'ReplayChallengeDataService';
    private sessionCache = new SessionCache<any>(this.cacheKey);
    private instance = this;

    constructor() {
        if (this.sessionCache.hasItem(this.cacheKey)) {
            this.instance = this.sessionCache.getItem(this.cacheKey)?.value;
        }
    }

    get personalChallengeData() {
        return this.instance._personalChallengeData;
    }

    set personalChallengeData(value: ReplayChallenge | any) {
        this.instance._personalChallengeData = value;
        this.saveState();
    }

    get trackerChallengeData() {
        return this.instance._trackerChallengeData;
    }

    set trackerChallengeData(value: ReplayChallenge | any) {
        this.instance._trackerChallengeData = value;
        this.saveState();
    }

    private saveState() {
        this.sessionCache.setItem(this.cacheKey, this.instance);
    }

    clearState() {
        this.sessionCache.removeItem(this.cacheKey);
    }

}
