import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'arrayTransform' })
export class ArrayTransformPipe implements PipeTransform {
    transform(value: any[], transformCallback: (val: any) => any): any {
        if (!value || !value.length) {
            return [];
        }

        return value.map((x) => transformCallback(x));
    }
}
