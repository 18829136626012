<div class="modal-content verify-mfa-code-modal-content text-clr-tertiary">
    <div class="modal-header">
        <div class="modal-title"
             [translate]="'VerifyYourNumber'">Verify Your Number</div>
    </div>
    <div class="modal-body form-group">
        <div class="modal-body-content"
             [translate]="'YouNumberSaved'">
        </div>
    </div>
    <div class="form-group" [ngClass]="{'has-error': validationErrorMessage}">
        <div class="data-input-label">
            <label for="securityCodeInput"
                   class="text-clr-flint"
                   [translate]="'Code'">Code
            </label>
            <span class="eye-icon-verify-code"
                  (click)="toggleShowingOfSecurityCode()">
                <i class="fa fa-eye"
                   aria-hidden="true"
                   *ngIf="showSecurityCode"></i>
                <i class="fa fa-eye-slash"
                   aria-hidden="true"
                   *ngIf="!showSecurityCode"></i>
            </span>
        </div>
        <input id="securityCodeInput"
               class="form-control"
               name="securityCode"
               (keypress)="numberOnly($event)"
               [type]="showSecurityCode ? 'text' : 'password'"
               autocomplete="off"
               [placeholder]="'Code' | translate"
               required
               [(ngModel)]="securityCode"
               (ngModelChange)="validationErrorMessage = ''"
               [disabled]="!codeIsSent || securityCodeIsValidating"/>
        <div class="alert-container"
             *ngIf="validationErrorMessage">
            <div class="alert text-clr-red background-clr-light-red color-error">{{ validationErrorMessage }}</div>
        </div>
    </div>
    <div class="form-group text-center">
        <button class="member-profile-button member-profile-button-primary btn-orange-large vp-margin-bottom-10"
                type="button"
                (click)="verifySecurityCode()">
            <span [translate]="'Submit'">Submit</span>
        </button>
    </div>
    <div class="modal-footer">
        <div [innerHTML]="'TheCodeMayTake4MinutesToArrive' | translate">The code may take up to 4 minutes to arrive.</div>
        <a [translate]="'RequestNewCode'"
           (click)="showResendSecurityCodeModal()"
           role="button">Request New Code</a>
    </div>
</div>
<div class="verify-mfa-code-modal banner"
     [hidden]="validationErrorMessageHappened"
     [ngClass]="getBannerClasses()">
    <span *ngIf="codeSendingError">{{ codeSendingError }}</span>
    <span *ngIf="codeIsBeingSent">
        <span [translate]="'YourCodeIsBeingSent'">Your code is being sent...</span>
    </span>
    <span *ngIf="codeIsSent">
        <span> &#10003; </span>
        <span [translate]="'YourCodeWasSent'">Your code was sent!</span>
    </span>
</div>
