import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BrowserService {
    private userAgent = window.navigator.userAgent;
    private browserNames: any = {
        chrome: /chrome/i,
        safari: /safari/i,
        firefox: /firefox/i,
        ie: /internet explorer/i, // eslint-disable-line id-length
        modernie: /Trident/i
    };
    private mobileDevices = ['Android', 'iPhone'];

    constructor() {}

    detectBrowser(): string {
        for (const key in this.browserNames) {
            if (this.browserNames[key].test(this.userAgent)) {
                if (key === 'modernie') {
                    return 'ie';
                }
                return key;
            }
        }
        return 'unknown';
    }

    detectMobile(): boolean {
        return Object.values(this.mobileDevices).some((device) => this.userAgent.includes(device));
    }
}
