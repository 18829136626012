import { Pipe, PipeTransform } from '@angular/core';

import { RewardType } from '../models/enums/reward-type.enum';
import { GroupingActionRewardVM } from '../models/interfaces/grouping-action-reward-vm.model';


@Pipe({
    name: 'showRewardDisplayInfo'
})
export class ShowRewardDisplayInfoPipe implements PipeTransform {

    transform(item: GroupingActionRewardVM): boolean {
        return item.rewardType !== RewardType.NoReward &&
            (!item.rewardCapState || item.rewardCapState.shouldDisplayRewardInfo);
    }
}
